import {v4 as uuid} from "uuid"; 
import {action, computed, observable,makeObservable} from 'mobx';

export class FieldModel<DataType> {
  private _error?: string;
  private _focused = false;
  private _initialValue: DataType | null;
  private _touched = false;
  private _value: DataType | null;
  private connected = false;
  private validators: Array<(value: any) => string | undefined> = [];
  readonly id = uuid();

  constructor(initialValue: DataType | null = null) {
    makeObservable<FieldModel<DataType>, "_error" | "_focused" | "_initialValue" | "_touched" | "_value">(this, {
      _error: observable,
      _focused: observable,
      _initialValue: observable,
      _touched: observable,
      _value: observable,
      dirty: computed,
      error: computed,
      focused: computed,
      initialValue: computed,
      invalid: computed,
      pristine: computed,
      touched: computed,
      valid: computed,
      value: computed,
      changeValue: action.bound,
      clearError: action.bound,
      changeFocused: action.bound,
      connect: action.bound,
      disconnect: action.bound,
      setError: action.bound,
      validate: action.bound
    });

    this._initialValue = initialValue;
    this._value = initialValue;
  }

  get dirty() {
    return !this.pristine;
  }

  get error() {
    return this._error;
  }

  get focused() {
    return this._focused;
  }

  get initialValue() {
    return this._initialValue;
  }

  get invalid() {
    return !this.valid;
  }

  get pristine() {
    return this._value === this._initialValue;
  }

  get touched() {
    return this._touched;
  }

  get valid() {
    return this._error === undefined;
  }

  get value() {
    return this._value;
  }

  changeValue(newValue) {
    this._touched = true;
    this._value = newValue;

    this.validate();
  }

  clearError() {
    this._error = undefined;
  }

  changeFocused(newValue: boolean) {
    this._focused = newValue;

    if (!newValue) {
      this._touched = true;
      this.validate();
    }
  }

  connect(validators?: Array<(value: any) => string | undefined>) {
    if (this.connected) {
      throw new Error('Field model already connected');
    }

    this.connected = true;

    if (validators) {
      this.validators = validators;
    }
  }

  disconnect() {
    this.validators = [];
    this.connected = false;
  }

  setError(newError: string) {
    this._error = newError;
  }

  validate() {
    this._touched = true;

    this.clearError();

    for (const validator of this.validators) {
      const result = validator(this._value);
  
      if (result !== undefined) {
        this.setError(result);
        return;
      }
    }
  }
}
