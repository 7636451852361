import cx from 'classnames';
import React from 'react';
import {action, observable,makeObservable} from 'mobx';
import {debounce} from 'lodash';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {observer} from 'mobx-react';
import {ISkillBasic} from '@u4i/parsers/SkillParserBasic';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import {ContainerTooSmallError, IColumnRepresentation, runColumnSizingAlgorithm} from './columnSizingAlgorithm';
import {CurriculumDesktop} from './components/CurriculumDesktop';
import {CurriculumMobile} from './components/CurriculumMobile';
import {CurriculumSection} from './CurriculumSection';
import {NoItemsMessage} from '../common/NoItemsMessage';
import {ObservedContainer} from './components/ObservedContainer';
import {RecalculationOverlay} from './components/RecalculationOverlay';
import intlMessages from './intlMessages';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';

interface IPropTypes extends WrappedComponentProps {
  openDetailsModal: (skill: ISkillBasic) => void
  sectionModel: CurriculumSection
}

const VisualComponent = observer(class VisualComponent extends React.Component<IPropTypes> {
  private debouncedRecalculate: (containerWidth: number) => void;
  private columnRepresentations: Array<IColumnRepresentation>;
  private isReady = false;
  private leftoverWidth = 0;
  private recalculatingInProgress = true;
  private showMobileSection = false;
  public courseData: any;

  constructor(props) {
    super(props);

    makeObservable<VisualComponent, "columnRepresentations" | "courseData" | "isReady" | "leftoverWidth" | "recalculatingInProgress" | "showMobileSection">(this, {
      columnRepresentations: observable,
      courseData: observable,
      isReady: observable,
      leftoverWidth: observable,
      recalculatingInProgress: observable,
      showMobileSection: observable,
      handleResize: action.bound,
      recalculate: action.bound
    });

    this.debouncedRecalculate = debounce(this.recalculate, 1000);
  }

  handleResize(newContainerWidth: number) {
    this.recalculatingInProgress = true;

    this.debouncedRecalculate(newContainerWidth);
  }

  handleSkillCardClick = (skill: ISkillBasic) => {
    AppNavigator.push(appRoutes.courseDetailPage, {
      skillSlug: skill.slug,
    });
  };

  recalculate(containerWidth: number) {
    try {
      const algorithmResult = runColumnSizingAlgorithm(this.props.sectionModel.curriculum, containerWidth);

      this.columnRepresentations = algorithmResult.columnRepresentations;
      this.isReady = true;
      this.leftoverWidth = algorithmResult.leftoverWidth;
      this.recalculatingInProgress = false;
      this.showMobileSection = false;
    } catch (error) {
      if (error.name === ContainerTooSmallError.NAME) {
        this.isReady = true;
        this.recalculatingInProgress = false;
        this.showMobileSection = true;
      }
    }
  }

  render() {
    const {sectionModel} = this.props;
    const {curriculum} = sectionModel;

    const itemsCountMessage: string = sectionModel.fetching ? '' : this.props.intl.formatMessage(
      intlMessages.completedSkillsMessage,
      {completedItems: curriculum.completedItems, totalItems: curriculum.totalItems}
    );

    return (
      <BasicInfoWrapper
        isLoading={sectionModel.fetching}
        itemsCountLabel={itemsCountMessage}
        sectionModel={sectionModel}
      >
        <div className="container">
          {(curriculum && curriculum.totalItems > 0) ?
            <ObservedContainer
              className={cx({
                'landing-page__curriculum': true,
                'landing-page__curriculum--mobile': this.showMobileSection,
              })}
              onResize={this.handleResize}
            >
              <RecalculationOverlay isVisible={this.recalculatingInProgress} />

              {this.isReady ?
                (this.showMobileSection ?
                  <CurriculumMobile
                    curriculumData={sectionModel.curriculum}
                    onSkillCardClick={this.handleSkillCardClick}
                  />
                  :
                  <CurriculumDesktop
                    columnRepresentations={this.columnRepresentations}
                    curriculumData={sectionModel.curriculum}
                    leftoverWidth={this.leftoverWidth}
                    onSkillCardClick={this.handleSkillCardClick}
                  />
                )
                :
                null
              }
            </ObservedContainer>
            :
            <NoItemsMessage />
          }
        </div>
      </BasicInfoWrapper>
    );
  }
});

const WrappedComponent = injectIntl(VisualComponent);

export {WrappedComponent as VisualComponent};
