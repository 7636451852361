import DiscussPageStore from '@u4i/modules/DiscussPage/state/DiscussPageStore';
import NotificationsPageStore from '@u4i/modules/NotificationsPage/state/NotificationsPageStore';
import {DigitalLabStore} from '@u4i/modules/DigitalLab/state/DigitalLabStore';
import {DigitalLabBookingStore} from '@u4i/modules/DigitalLab/state/DigitalLabBookingStore';
import {ExercisesStore} from '@u4i/modules/Exercises/state/ExercisesStore';
import {GradingEvaluationStore} from '@u4i/modules/CustomerGrading/AttemptEvaluation/state/GradingEvaluationStore';
import {JupyterLabStore} from '@u4i/modules/JupyterLab/state/JupyterLabStore';
import {LandingPageStore} from '@u4i/modules/LandingPage/state/LandingPageStore';
import ApiService from './services/ApiService';
import createApiClient from './services/apiClient';
import LoggerFactory from './services/LoggerFactory';
import NotificationsStore from './NotificationsStore';
import SettingsStore from './SettingsStore';
import UserStore from './UserStore';
import {AdminStore} from './AdminStore';
import {IApi, IStorage} from './ServicesInterfaces';
import {LanguageStore} from './LanguageStore';
import {OnboardingStore} from './OnboardingStore';
import {ScormProxyService} from './services/ScormProxyService';
import {StorageService} from './services/StorageService';
import {configure} from "mobx";
import { GradingEditStore } from '@u4i/modules/CustomerGrading/AttemptEdit/state/GradingEditStore';
import { ProgramsLearnerViewStore } from '@u4i/modules/ProgramsLearnerView/state/ProgramsLearnerViewStore';
import { RedeemPageStore } from '@u4i/modules/RedeemPage/state/RedeemPageStore';
import { CourseDetailPageStore } from '@u4i/modules/CourseDetailPage/state/CourseDetailPageStore';
import { CheckoutPurchaseStore } from '@u4i/modules/Checkout/state/CheckoutStore';
import { RegisterConfirmPageStore } from '@u4i/modules/RegisterConfirmPage/state/RegisterConfirmPageStore';

configure({useProxies: "never", enforceActions: "never"})

export interface IRootStore {
  adminStore: AdminStore;
  apiService: IApi;
  courseDetailPageStore: CourseDetailPageStore;
  checkoutPurchaseStore: CheckoutPurchaseStore;
  digitalLabStore: DigitalLabStore;
  digitalLabBookingStore: DigitalLabBookingStore;
  discussPageStore: DiscussPageStore;
  exercisesStore: ExercisesStore;
  gradingEditStore: GradingEditStore;
  gradingEvaluationStore: GradingEvaluationStore;
  jupyterLabStore: JupyterLabStore;
  landingPageStore: LandingPageStore;
  languageStore: LanguageStore;
  loggerFactory: LoggerFactory;
  notificationsStore: NotificationsStore;
  notificationsPageStore: NotificationsPageStore;
  onboardingStore: OnboardingStore;
  programsLearnerViewStore: ProgramsLearnerViewStore;
  redeemPageStore: RedeemPageStore;
  registerConfirmPageStore: RegisterConfirmPageStore;
  scormProxyService: ScormProxyService;
  settingsStore: SettingsStore;
  storageService: IStorage;
  userStore: UserStore;
}

class RootStore implements IRootStore {
  adminStore: AdminStore;
  apiService: IApi;
  courseDetailPageStore: CourseDetailPageStore;
  checkoutPurchaseStore: CheckoutPurchaseStore;
  digitalLabStore: DigitalLabStore;
  digitalLabBookingStore: DigitalLabBookingStore;
  discussPageStore: DiscussPageStore;
  exercisesStore: ExercisesStore;
  gradingEvaluationStore: GradingEvaluationStore;
  gradingEditStore: GradingEditStore;
  jupyterLabStore: JupyterLabStore;
  landingPageStore: LandingPageStore;
  languageStore: LanguageStore;
  loggerFactory: LoggerFactory;
  notificationsStore: NotificationsStore;
  notificationsPageStore: NotificationsPageStore;
  onboardingStore: OnboardingStore;
  programsLearnerViewStore: ProgramsLearnerViewStore;
  redeemPageStore: RedeemPageStore;
  registerConfirmPageStore: RegisterConfirmPageStore;
  scormProxyService: ScormProxyService;
  settingsStore: SettingsStore;
  storageService: IStorage;
  userStore: UserStore;

  constructor() {
    // Services
    this.loggerFactory = new LoggerFactory();
    this.storageService = new StorageService();
    this.apiService = new ApiService(createApiClient(this));
    this.scormProxyService = new ScormProxyService(this);

    // UI stores
    this.courseDetailPageStore = new CourseDetailPageStore(this);
    this.checkoutPurchaseStore = new CheckoutPurchaseStore(this);
    this.settingsStore = new SettingsStore(this);
    this.languageStore = new LanguageStore(this);
    this.userStore = new UserStore(this);
    this.userStore.checkSavedAuthenticationData();// depends: languageStore
    this.notificationsStore = new NotificationsStore(this); // depends: userStore
    this.onboardingStore = new OnboardingStore(this);
    this.redeemPageStore = new RedeemPageStore(this);
    this.registerConfirmPageStore = new RegisterConfirmPageStore(this);
    this.settingsStore = new SettingsStore(this);

    // Domain stores
    this.adminStore = new AdminStore(this);
    this.digitalLabStore = new DigitalLabStore(this);
    this.digitalLabBookingStore = new DigitalLabBookingStore(this);
    this.discussPageStore = new DiscussPageStore(this);
    this.exercisesStore = new ExercisesStore(this);
    this.gradingEditStore = new GradingEditStore(this);
    this.gradingEvaluationStore = new GradingEvaluationStore(this);
    this.jupyterLabStore = new JupyterLabStore(this);
    this.landingPageStore = new LandingPageStore(this);
    this.notificationsPageStore = new NotificationsPageStore(this);
    this.programsLearnerViewStore = new ProgramsLearnerViewStore(this);
  }
}

export default RootStore;
