import Shepherd from 'shepherd.js'
import {action, computed, observable, reaction, runInAction, makeObservable} from 'mobx';
import {IOnboardingProgress} from '@u4i/parsers/OnboardingProgressParser';
import {ONBOARDING_ENABLE_FOR_GUESTS} from '@u4i/constantSettings';
import {OnboardingSequenceEnum} from '@u4i/common/enums/OnboardingSequenceEnum';
import UserStore from '../UserStore';
import {configurePlayerTour} from './tours/playerTour';
import {configureSkillDetailTour} from './tours/skillDetailTour';
import {IApi, ILogger, IStorage} from '../ServicesInterfaces';
import {IRootStore} from '../RootStore';
import {LanguageStore} from '../LanguageStore';
import {TourCreator} from './TourCreator';

export class OnboardingStore {
  private apiService: IApi;
  private languageStore: LanguageStore;
  private logger: ILogger;
  public onboardingProgress: IOnboardingProgress = { 
    player: true, 
    showOnboarding: false, 
    skillDetail: true 
  };
  private storageService: IStorage;
  private tourCreator = new TourCreator();
  public tours: Map<OnboardingSequenceEnum, Shepherd.Tour>;
  private userStore: UserStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      onboardingProgress: observable,
      shouldOnboardingRender: computed,
      recreateTours: action.bound,
      reloadOnboardingData: action.bound,
      setCompletedOnboarding: action.bound
    });

    const {apiService, languageStore, loggerFactory, storageService, userStore} = rootStore;
    this.apiService = apiService;
    this.languageStore = languageStore;
    this.logger = loggerFactory.createLogger('OnboardingStore');
    this.storageService = storageService;
    this.userStore = userStore;

    this.recreateTours();
    reaction(() => this.languageStore.currentIntl, this.recreateTours);

    this.reloadOnboardingData();
    reaction(() => this.userStore.isAuthorized, this.reloadOnboardingData);
  }

  get shouldOnboardingRender(): boolean {
    const {isLoading, isAuthorized, isPurchaseCourseModalVisible, isLoginModalVisible, isPrivacyPolicyModalOpen, isGeneralModalOpen} = this.userStore;
    let shouldShowOnboardingTour: boolean = !isLoading && this.onboardingProgress.showOnboarding && (isAuthorized || ONBOARDING_ENABLE_FOR_GUESTS) && ((!isPurchaseCourseModalVisible && !isLoginModalVisible) && !isPrivacyPolicyModalOpen && !isGeneralModalOpen);

    return shouldShowOnboardingTour;
  }

  recreateTours() {
    const isInitialCall: boolean = !this.tours;
    
    this.tourCreator.setIntl(this.languageStore.currentIntl);

    if (this.tours) {
      this.tours.forEach((tour) => {
        tour.cancel();
      });
    }

    this.tours = new Map([
      [OnboardingSequenceEnum.PLAYER, this.tourCreator.createTour(
        configurePlayerTour,
        () => this.setCompletedOnboarding(OnboardingSequenceEnum.PLAYER),
      )],
      [OnboardingSequenceEnum.SKILL_DETAIL, this.tourCreator.createTour(
        configureSkillDetailTour,
        () => this.setCompletedOnboarding(OnboardingSequenceEnum.SKILL_DETAIL),
      )],
    ]);

    this.logger.info(`recreated onboarding tours ${isInitialCall ? '(initial call)' : '(language change trigger)'}`);
  }

  async reloadOnboardingData() {
    if (this.userStore.isAuthorized) {
      try {
        const onboardingProgress: IOnboardingProgress = await this.apiService.onboarding.fetchOnboardingProgress();

        runInAction(() => {
          if(onboardingProgress) {
            this.storageService.onboarding.set(onboardingProgress);
            this.onboardingProgress = onboardingProgress;
          }
        });
      } catch(error) {
        this.logger.error(error);
      }
    } else {
      const onboardingProgress: IOnboardingProgress | null = this.storageService.onboarding.get();

      if (onboardingProgress) {
        this.onboardingProgress = onboardingProgress;
      }
    }

    this.logger.info('reloaded onboarding data');
  }

  async setCompletedOnboarding(onboardingComponent: OnboardingSequenceEnum) {
    this.onboardingProgress[onboardingComponent] = true;

    try {
      if (this.userStore.isAuthorized) {
        const onboardingProgress: IOnboardingProgress = await this.apiService.onboarding.updateOnboardingProgress(this.onboardingProgress);

        runInAction(() => {
          if(onboardingProgress) {
            this.onboardingProgress.player = onboardingProgress.player;
            this.onboardingProgress.skillDetail = onboardingProgress.skillDetail;
            this.storageService.onboarding.set(this.onboardingProgress);
          }
        });
      } else {
        this.storageService.onboarding.set(this.onboardingProgress);
      }

      this.logger.info(`onboarding "${onboardingComponent}" marked as completed`);
    } catch(error) {
      this.onboardingProgress[onboardingComponent] = false;

      this.logger.error(error);
    }
  }
}
