import VisibilityPercentageContext from "@u4i/modules/VirtualExperience/components/VisibilityPercentage/VisibilityPercentageContext";
import { fullPageApi } from "@u4i/modules/VirtualExperience/config";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { postStatement } from "@u4i/common/TraxLrs";
import { XAPIVerbs } from "@u4i/common/enums/XApiEnum";
import "./_interactive-element.scss";

class InteractiveElement extends Component<any> {
  iframeRef: any = React.createRef();
  scrollOverload = 1;

  componentDidMount() {
    if (fullPageApi.getFullPageReference()) {
      fullPageApi.setAllowScrolling(false);
    }
    postStatement(XAPIVerbs.experienced, 'element', this.props.elementId, this.props?.title);

    this.iframeRef.current.addEventListener("load", this.attachListeners);
    setTimeout(() => {
      this.scrollOverload = 0;
    }, 1000);
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this.iframeRef.current.removeEventListener("load", this.attachListeners);
  }

  get iframeWindow() {
    return this.iframeRef.current.contentWindow;
  }

  scrollSection = (context, arg) => {
    try {
      if (this.scrollOverload == 0) {
        fullPageApi.setAllowScrolling(true);
        this.scrollOverload = 1;
        if (context.deltaY > 0) {
          fullPageApi.moveSectionDown();
          return;
        }
        if (context.deltaY < 0) {
          fullPageApi.moveSectionUp();
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  debouncer(func, timeout) {
    var timeoutID,
      timeout = timeout || 100;
    return function () {
      var scope = this,
        args = arguments;
      clearTimeout(timeoutID);
      timeoutID = setTimeout(function () {
        func.apply(scope, Array.prototype.slice.call(args));
      }, timeout);
    };
  }

  attachListeners = () => {
    this.iframeWindow.document.addEventListener(
      "wheel",
      this.debouncer(this.scrollSection, 200)
    );
  };

  render() {
    return (
      <div className="player__interactive-element mytest">
        <iframe
          className="player__interactive-element__frame"
          ref={this.iframeRef}
          src={this.props.url}
          title={this.props.title}
          scrolling="no"
        />
      </div>
    );
  }
}

const WrappedInteractiveElement = withRouter(InteractiveElement);

export default (props) => (
  <VisibilityPercentageContext.Consumer>
    {(visibilityPercentage) => (
      <WrappedInteractiveElement
        {...props}
        visibilityPercentage={visibilityPercentage}
      />
    )}
  </VisibilityPercentageContext.Consumer>
);
