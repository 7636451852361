import Parser from '@u4i/utils/Parser';

export interface IAdminCustomPage {
  idLive: string;
  name: string;
  idStage:string;
}

export const AdminCustomPageParser = new Parser<IAdminCustomPage>(customPage => ({
  name: customPage.url,
  idStage: customPage.idStage,
  idLive: customPage.idLive,
}));
