import axios from 'axios';

class RequestBucket {
  requestsCancelSources = {};

  cancel(id) {
    if (this.requestsCancelSources[id]) {
      this.requestsCancelSources[id].cancel();
      delete this.requestsCancelSources[id];
    }
  }

  cancelAll() {
    Object.values(this.requestsCancelSources).map((source: any) => source.cancel());
    this.requestsCancelSources = {};
  }

  generateToken(id) {
    const cancelTokenSource = axios.CancelToken.source();

    this.requestsCancelSources[id] = cancelTokenSource;

    return Object.assign(cancelTokenSource.token);
  }
}

export default RequestBucket;
