import { TraxLrsUrl } from '@u4i/modules/Admin/modules/Programs/interfaces/constants';
import { XAPIStatementResponse, XAPIStatementPost } from '@u4i/utils/interfaces/xapi.interfaces';
import axios, {AxiosInstance} from 'axios';

export class XAPIApi {
  private apiClient: AxiosInstance;
  public credentials = {
    username: 'traxlrs',
    password: 'traxlrs'
  };

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  postStatement = async (statement: XAPIStatementPost): Promise<XAPIStatementResponse> => {
    const axiosInstance = axios.create({
      baseURL: TraxLrsUrl,
      auth: this.credentials,
    });

    const response = await axiosInstance.post<XAPIStatementResponse>(`/statements`, statement, {       
      headers: {
        'X-Experience-API-Version': "1.0.3"
      }
    });

    return response.data;
  }

}
