import React from 'react';

import './_switch-button.scss';

interface IPropTypes {
  id: string;
  label: string;
  onItemClick: (itemId: string) => void;
}

export class SwitchButton extends React.Component<IPropTypes> {
  handleButtonClick = () => {
    this.props.onItemClick(this.props.id);
  };

  render() {
    return (
      <button
        className="landing-page__switch-button"
        onClick={this.handleButtonClick}
        type="button"
      >
        {this.props.label}
      </button>
    );
  }
}
