import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_challenges-status.scss';

export const ChallengesStatus: FC = () => (
  <div className="container">
    <div className="course-detail-page__challenges-status">
      <FontAwesome
        className="course-detail-page__challenges-status__icon"
        name="tasks"
      />

      <FormattedMessage {...intlMessages.message} values={{ br: <br /> }} />
    </div>
  </div>
);
