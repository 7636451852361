import Parser from '@u4i/utils/Parser';
import {CalendarEvent, ILabs, IUserBookedLabsSlots } from '@u4i/modules/DigitalLab/components/Booking/interface';

export interface IDigitalLab {
  challengeAttemptId: string;
  id: string;
  streamingUrl: {
    expires: number;
    url: string;
  };
  title: string;
  maintenanceDuration: number;
}

export const DigitalLabParser = new Parser<IDigitalLab>(digitalLab => ({
  challengeAttemptId: digitalLab.challenge_attempt_id,
  id: digitalLab.id,
  streamingUrl: {
    expires: digitalLab.streaming_url_dto.expires,
    url: digitalLab.streaming_url_dto.streaming_url,
  },
  title: digitalLab.title,
  maintenanceDuration: digitalLab.maintenanceDuration != null ? Math.round(digitalLab.maintenanceDuration/60) : 30
}));

export const DigitalLabUserBookingParser = new Parser<CalendarEvent>(slot => ({
  id: slot.slotId,
  resourceId: slot.resourceId,
  title: slot.slotTitle,
  start: new Date(slot.slotStart),
  end: new Date(slot.slotEnd),
  editable: slot.editable,
  color: slot.editable,
  isMine: slot.isMine,
  extendedProps: {
    isMine: slot.isMine
  },
  errorMessage: slot.errorMessage
}));

export const Labs = new Parser<ILabs>(lab => ({
  id: lab.id,
  title: lab.title,
  disable: !lab.isEnabled,
  restTimeDuration: lab.maintenanceDuration != null ? Math.round(lab.maintenanceDuration/60) : 0,
  userSessionDuration: Math.round(lab.userSessionDuration/60),
  userSlotsPerDay: lab.userSlotsPerDay,
  hasScheduler: lab.hasScheduler
}));

export const UserLabSlots = new Parser<IUserBookedLabsSlots>(userlabSlot => ({
  isMine: userlabSlot.isMine,
  slotEnd: userlabSlot.slotEnd,
  slotStart: userlabSlot.slotStart
}));
