import React, { FC } from 'react';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import { DiscussAnswer } from './DiscussAnswer/DiscussAnswer';
import './_discuss-answers-list.scss';

interface IProps {
  answersToDisplay: Array<DiscussAnswerEntity>
  isAnonymityChecked: boolean
  showAnonymitySettingsSwitch: boolean
  teaserMode: boolean
}

export const DiscussAnswersList: FC<IProps> = (props: IProps) => {
  const {
    answersToDisplay,
    isAnonymityChecked,
    showAnonymitySettingsSwitch,
    teaserMode
  } = props;

  return (
    <ul className="list-unstyled">
      {answersToDisplay.map(entry => (
        <li className="discuss__answer-entry" key={entry.id}>
          <DiscussAnswer
            answerId={entry.id}
            entity={entry}
            isAnonymityChecked={isAnonymityChecked}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            teaserMode={teaserMode}
          />
        </li>
      ))}
    </ul>
  )
};
