import cx from "classnames";
import React, { FC } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "@reach/router";
import IconHoverable from "@u4i/common/IconHoverable";
import bellIconHovered from "@u4i/assets/svg/bellIconHovered.svg";
import bellIconNonHovered from "@u4i/assets/svg/bellIconNonHovered.svg";

import { Popover } from "antd";
import { NotificationContent } from "./NotificationContent";
import "./_notification-indicator.scss";

interface IPropTypes extends RouteComponentProps {
  unreadCount: number;
}

const NotificationIndicator: FC<IPropTypes | any> = (props: IPropTypes) => {
  const {
    location,
    unreadCount    
  } = props;

  const onNotificationPage = (): boolean => {
    return location?.pathname === "/notifications";
  }

  return(
    <Popover
      placement="bottomRight"
      content={<NotificationContent />}
      destroyTooltipOnHide
      trigger="click"
      getPopupContainer={(trigger): any => trigger.parentNode}
      overlayClassName="notification-tab"
    >
      <span
        className={cx({
          "main-layout__notification-indicator": true,
          "main-layout__notification-indicator--active": onNotificationPage
        })}
      >
        <IconHoverable
          alt="Notifications"
          classNames="main-layout__notification-indicator__icon"
          iconHovered={bellIconHovered}
          iconNonHovered={bellIconNonHovered}
          isActive={onNotificationPage()}
        />

        {unreadCount > 0 && (
          <div className="main-layout__notification-indicator__messages-count">
            {unreadCount}
          </div>
        )}
      </span>
    </Popover>
  )
}

export default withRouter(NotificationIndicator);