import Parser from '@u4i/utils/Parser';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {UserChangeActionEnum} from '@u4i/modules/Admin/common/enums/UserChangeActionEnum';
import {UserChangeStatusEnum} from '@u4i/modules/Admin/common/enums/UserChangeStatusEnum';
import {UserRolesEnum} from '@u4i/common/enums/UserRolesEnum';
import {UserStatusEnum} from '@u4i/common/enums/UserStatusEnum';

export interface NewUserData {
  company: string;
  email: string;
  firstName: string;
  groups: Array<{
    id: string;
    isMain: boolean;
  }>;
  language: LanguageEnum;
  lastName: string;
  role: UserRolesEnum;
  sendPasswordCreateEmail: boolean;
  status: UserStatusEnum;
  tokenValidityDays: string;
}

export interface UserCreateFormValues {
  company: string;
  email: string;
  firstName: string;
  groups: Array<string>;
  id: string;
  language: any;
  lastName: string;
  mainGroup: string;
  role: {
    value: number;
    label: UserRolesEnum;
    key: number;
  };
  sendPasswordCreateEmail: boolean;
  status: {
    value: number;
    label: UserStatusEnum;
    key: number;
  }
  tokenValidityDays: string;
}

export interface IActiveUserGroup {
  id: string;
  isGrader?: boolean;
  isMain: boolean;
  name?: string;
}

export interface IAdminStatus {
  id: UserStatusEnum;
  name: string;
}

export interface IAdminRole {
  id: UserRolesEnum;
  name: string;
}

export interface IChangeRequest {
  action: UserChangeActionEnum;
  createdAt?: string;
  id?: string;
  newValue?: string | UserRolesEnum | UserStatusEnum;
  requestedBy: string;
  status?: UserChangeStatusEnum | string;
  verifiedBy?: string;
}

export interface IAdminUser {
  company: string;
  email: string;
  firstName: string;
  groups: Array<IActiveUserGroup>;
  id: string;
  language: LanguageEnum;
  lastLogin: string;
  lastName: string;
  role: IAdminRole;
  status: IAdminStatus;
  username: string;
  changeRequests?: Array<IChangeRequest> | null,
}

export const AdminUsersParser = new Parser<IAdminUser>(adminUser => ({
  company: adminUser.company,
  email: adminUser.email,
  firstName: adminUser.firstName,
  groups: adminUser.groups.map((group) => ({
    id: group.id,
    isGrader: group.isGrader,
    isMain: group.isMain,
    name: group.name,
  })),
  id: adminUser.id,
  language: adminUser.language,
  lastLogin: adminUser.lastLogin,
  lastName: adminUser.lastName,
  role: {
    id: adminUser.role.id,
    name: adminUser.role.name,
  },
  status: {
    id: adminUser.status.id,
    name: adminUser.status.name,
  },
  username: adminUser.username,
  changeRequests: adminUser.changeRequests && adminUser.changeRequests.map((changeRequest) => ({
    action: changeRequest.action,
    createdAt: changeRequest.createdAt,
    id: changeRequest.id,
    newValue: changeRequest.newValue,
    requestedBy: changeRequest.requestedBy,
    status: changeRequest.status,
  })),
}));
