import {defineMessages} from 'react-intl';

export default defineMessages({
  codeLabel: {
    defaultMessage: 'Code',
    id: 'Forms.CouponRedeem.Code.Label',
  },
  codePresenceError: {
    defaultMessage: 'Code cannot be blank',
    id: 'Forms.CouponRedeem.Code.Errors.Presence',
  },
  submitTitle: {
    defaultMessage: 'Redeem',
    id: 'Forms.CouponRedeem.SubmitTitle',
  },
});
