import { ICustomerIntegration } from "@u4i/modules/Admin/modules/CustomerIntegration/interfaces";
import Parser from "@u4i/utils/Parser";

export const AdminCustomerIntegrationParser = new Parser<ICustomerIntegration>(ci => ({
  createdAt: ci.created_at,
  id: ci.id,
  integrationId: ci.integration_id,
  ltiIntegrationDto: null || {
    ltiConsumerDto: null || {
      name: ci.lti_integration_dto?.lti_consumer_dto?.name,
      consumerKey: ci.lti_integration_dto?.lti_consumer_dto?.consumer_key,
      secret: ci.lti_integration_dto?.lti_consumer_dto?.secret
    },
    ltiIdentityProviderDto: null || {
      consumerKey: ci.lti_integration_dto?.lti_identity_provider_dto?.consumer_key,
      emailDomain: ci.lti_integration_dto?.lti_identity_provider_dto?.email_domain,
      groupId: ci.lti_integration_dto?.lti_identity_provider_dto?.group_id,
      authMode: ci.lti_integration_dto?.lti_identity_provider_dto?.auth_mode
    }
  },
  name: ci.name,
  scormIntegrationDto: null || {

  },
  type: ci.type,
  updatedAt: ci.updated_at
}));
