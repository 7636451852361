import {defineMessages} from 'react-intl';

export default defineMessages({
  forgotPassword: {
    defaultMessage: 'Forgot password?',
    id: 'LandingPage.AccessBlock.ForgotPassword',
  },
  unauthorized: {
    defaultMessage: 'Unfortunately you do not have access to the content on this page.',
    id: 'LandingPage.AccessBlock.Unauthorized',
  },
});
