import {defineMessages} from 'react-intl';

export default defineMessages({
  name: {
    defaultMessage: 'Feedback Survey',
    id: 'Player.ElementsDisplay.FeedbackLinkElement.Name',
  },
  title: {
    defaultMessage: 'Tell us what you think',
    id: 'Player.ElementsDisplay.FeedbackLinkElement.Title',
  },
});
