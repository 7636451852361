import { action, observable, runInAction, makeObservable } from "mobx";

import NotificationsStore from "@u4i/state/NotificationsStore";
import { NOTIFICATIONS_PAGE_ITEMS_PER_PAGE } from "@u4i/constantSettings";
import { IApi, ILogger } from "@u4i/state/ServicesInterfaces";
import { INotification } from "@u4i/parsers/NotificationParser";
import { IRootStore } from "@u4i/state/RootStore";

class NotificationsPageStore {
  private apiService: IApi;
  private logger: ILogger;
  private markingInfoTimeoutId: number;
  private notificationsStore: NotificationsStore;
  notifications: Array<INotification> = [];
  fetching = true;
  page = 1;
  totalPages = 0;
  markingInfoVisibility = false;
  markingInProgress = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      notifications: observable,
      fetching: observable,
      page: observable,
      totalPages: observable,
      markingInfoVisibility: observable,
      markingInProgress: observable,
      changePage: action.bound,
      hideMarkingInfo: action.bound,
      loadNotifications: action.bound,
      markAllAsSeen: action.bound,
    });

    const { apiService, loggerFactory, notificationsStore } = rootStore;

    this.apiService = apiService;
    this.logger = loggerFactory.createLogger("NotificationsPageStore");
    this.notificationsStore = notificationsStore;
  }

  changePage(newPage) {
    this.page = newPage;

    this.loadNotifications();
  }

  hideMarkingInfo() {
    this.markingInfoVisibility = false;
  }

  async loadNotifications() {
    this.fetching = true;

    const { items, totalItems } =
      await this.apiService.notifications.fetchNotifications(
        NOTIFICATIONS_PAGE_ITEMS_PER_PAGE,
        (this.page - 1) * NOTIFICATIONS_PAGE_ITEMS_PER_PAGE
      );

    const totalPages = Math.ceil(
      totalItems / NOTIFICATIONS_PAGE_ITEMS_PER_PAGE
    );

    runInAction(() => {
      this.notifications = items;
      this.totalPages = totalPages;
      this.fetching = false;
    });

    this.logger.info("notifications loaded");
  }

  async markAllAsSeen() {
    window.clearTimeout(this.markingInfoTimeoutId);

    this.markingInProgress = true;

    await this.notificationsStore.markAllAsSeen();

    runInAction(() => {
      this.markingInProgress = false;
      this.markingInfoVisibility = true;

      this.notifications.forEach((notification) => {
        notification.isNew = false;
      });
    });

    this.markingInfoTimeoutId = window.setTimeout(this.hideMarkingInfo, 3000);
  }

  async markNotificationAsSeen(id) {
    await this.notificationsStore.markNotificationAsSeen(id).then(() => {
      this.loadNotifications();
      this.notificationsStore.updateUnreadCount();
    });
  }
}

export default NotificationsPageStore;
