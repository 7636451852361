import ArrowLeft from '@material-ui/icons/FirstPage';
import ArrowRight from '@material-ui/icons/LastPage';
import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {range} from 'lodash';
import { steelGray } from '@u4i/styles/styles-variables';

import intlMessages from './intlMessages';
import './_pagination.scss';

interface IPropTypes {
  isBlocked: boolean
  onPageChange: (newPage: number) => void;
  page: number
  totalPages: number
}

export const Pagination: FC<IPropTypes> = (props: IPropTypes) => {
  const firstPageBoundaryOffset: number = props.page >= props.totalPages - 1 ? 2 - (props.totalPages - props.page) : 0;
  const lastPageBoundaryOffset: number = props.page <= 2 ? 3 - props.page : 0;
  const firstPageVisible: number = Math.max(props.page - 2 - firstPageBoundaryOffset, 1);
  const lastPageVisible: number = Math.min(props.page + 2 + lastPageBoundaryOffset, props.totalPages);

  return (
    <div className="data-table__pagination">
      <span className="data-table__pagination__text">
        <FormattedMessage {...intlMessages.page} />
      </span>

      <div className="data-table__pagination__controls-wrapper">
        {firstPageVisible > 1 &&
          <button
            className="data-table__pagination__arrow"
            disabled={props.isBlocked}
            onClick={() => props.onPageChange(1)}
          >
            <ArrowLeft
              className="data-table__pagination__arrow-icon"
              style={{color: steelGray, height: 30, width: 30}}
            />
          </button>
        }

        <ul className="data-table__pagination__list list-unstyled">
          {range(firstPageVisible, lastPageVisible + 1).map(page => (
            <li key={page}>
              <button
                className={cx({
                  'data-table__pagination__list-entry': true,
                  'data-table__pagination__list-entry--active': page === props.page,
                })}
                disabled={props.isBlocked}
                onClick={() => props.onPageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
        </ul>

        {lastPageVisible < props.totalPages &&
          <button
            className="data-table__pagination__arrow"
            disabled={props.isBlocked}
            onClick={() => props.onPageChange(props.totalPages)}
          >
            <ArrowRight
              className="data-table__pagination__arrow-icon"
              style={{color: steelGray, height: 30, width: 30}}
            />
          </button>
        }
      </div>
    </div>
  );
};
