import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {SectionTypeEnum} from '@u4i/common/enums/SectionTypeEnum';
import {BaseSection} from './sections/BaseSection';
import {ChapterSection} from './sections/ChapterSection';
import {CompareMeSection} from './sections/CompareMeSection';
import {CurriculumSection} from './sections/CurriculumSection';
import {LoginSection} from './sections/LoginSection';
import {MySkillsSection} from './sections/MySkillsSection';
import {PartnerInfoSection} from './sections/PartnerInfoSection';
import {RegisterSection} from './sections/RegisterSection';
import {SkillSection} from './sections/SkillSection';
import {TrailerSection} from './sections/TrailerSection';
import { ProgramSection } from './sections/ProgramSection/ProgramSection';

export class SectionFactory {
  static build(rootStore: IRootStore, section: ISection): BaseSection {
    switch (section.type) {
      case SectionTypeEnum.CHAPTER:
        return new ChapterSection(rootStore, section);

      case SectionTypeEnum.COMPARE_ME:
        return new CompareMeSection(rootStore, section);

      case SectionTypeEnum.CURRICULUM:
        return new CurriculumSection(rootStore, section);

      case SectionTypeEnum.LOGIN:
        return new LoginSection(rootStore, section);

      case SectionTypeEnum.MYSKILLS:
        return new MySkillsSection(rootStore, section);

      case SectionTypeEnum.PARTNER_INFO:
        return new PartnerInfoSection(rootStore, section);

      case SectionTypeEnum.PROGRAM:
        return new ProgramSection(rootStore, section);

      case SectionTypeEnum.REGISTER:
        return new RegisterSection(rootStore, section);

      case SectionTypeEnum.SKILL:
        return new SkillSection(rootStore, section);

      case SectionTypeEnum.TRAILER:
        return new TrailerSection(rootStore, section);
    }
  }
}
