import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface IPropTypes {
  children: Array<React.ReactElement | null>;
  className: string;
  onResize: (containerWidth: number, containerHeight: number) => void;
}

export class ObservedContainer extends React.Component<IPropTypes> {
  private containerRef = React.createRef<HTMLDivElement>();
  private resizeObserver: ResizeObserver;

  constructor(props) {
    super(props);

    this.resizeObserver = new ResizeObserver(this.handleResize);
  }

  componentDidMount() {
    if (this.containerNode) {
      this.resizeObserver.observe(this.containerNode);
    }

    this.handleResize();
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  get containerNode() {
    return this.containerRef.current;
  }

  handleResize = () => {
    if (this.containerNode) {
      this.props.onResize(this.containerNode.clientWidth, this.containerNode.clientHeight);
    }
  };

  render() {
    return (
      <div className={this.props.className} ref={this.containerRef}>
        {this.props.children}
      </div>
    );
  }
}
