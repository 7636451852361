import {action, observable, runInAction,makeObservable} from 'mobx';

import {ISettings} from '@u4i/parsers/SettingsParser';
import {IApi} from './ServicesInterfaces'
import {IRootStore} from './RootStore';

class SettingsStore {
  private apiService: IApi;
  fetching = false;
  settings: ISettings;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      fetching: observable,
      settings: observable,
      loadSettings: action.bound
    });

    const {apiService} = rootStore;
    this.apiService = apiService;

    this.loadSettings();
  }

  async loadSettings() {
    this.fetching = true;

    const settings = await this.apiService.settings.fetchSettings();

    runInAction(() => {
      this.fetching = false;
      this.settings = settings;
    });
  }
}

export default SettingsStore;
