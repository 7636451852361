import { AxiosInstance } from "axios";
import {AntDTableFilters} from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import {IPaginatedItems} from "@u4i/state/ServicesInterfaces";
import qs from "qs";
import {
  IChatbotKB,
  IKBFileInfo,
  IKBFileUpload
} from "@u4i/modules/Admin/modules/ChatbotFileManager/interfaces/ChatbotKBInterfaces";
import { IFileInfo } from "@u4i/parsers/admin/AdminFileManagmentParser";
import {
  IFileUpload,
  IUploadedFile
} from "@u4i/modules/Admin/modules/ChatbotFileManager/components/ChatbotFileManagement/interface";
export class AdminChatbotApi {
    private apiClient: AxiosInstance;

    constructor(apiClient: AxiosInstance) {
      this.apiClient = apiClient;
    }

    async submitNewKnowledgeBase(data: any): Promise<boolean> {
      const response = await this.apiClient.post(
        `/admin/chatbot/knowledge-base`,
        data,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return response.data;
    }

    async submitUpdateKnowledgeBase(data: any): Promise<boolean> {
      const response = await this.apiClient.put(
        `/admin/chatbot/knowledge-base/${data.id}`,
        data,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return response.data;
    }

    async fetchChatbotKnowledgeBasesData(config: AntDTableFilters<IChatbotKB>): Promise<IPaginatedItems<IChatbotKB>> {
      const response = await this.apiClient.get("/admin/chatbot/knowledge-base", {
        params: {
          filters: config.filters,
          limit: config.limit,
          offset: config.offset,
          orderBy: config.orderBy,
        },
        paramsSerializer: (params) => qs.stringify(params)
      });
      const items = response.data;
      const totalItems: number = parseInt(response.headers["x-total-count"], 10);

      return { items, totalItems };
    }

    async fetchChatbotKnowledgeBaseData(chatbotKBId: string): Promise<IChatbotKB> {
      const response = await this.apiClient.get("/admin/chatbot/knowledge-base/"+chatbotKBId);
      return response.data;
    }

    async getFileInfo(args: any): Promise<any> {
        return (await this.apiClient.post(`/admin/chatbot/knowledge-base/files/file-info`, args));
    }

    async deleteFile(id: string): Promise<any> {
        return await this.apiClient.delete(`/admin/chatbot/knowledge-base/file/${id}`);
    }

    async getUploadedFiles(queryUrl: string): Promise<any> {
      return (await this.apiClient.get(`/admin/chatbot/knowledge-base/downloads/list` + queryUrl));
    }

    async updateFile(id: string, updatedName: string): Promise<any> {
      return await this.apiClient.put(`/admin/chatbot/knowledge-base/downloads/${id}/info`, {
        data: {
          display_filename: updatedName,
        },
      });
    }

    /**
     * Response: {
     *    "status":"success",
     *    "id":"1a0008af-58a2-11ef-9d3e-0242ac120006",
     *    "display_filename":"0048fa02-3ab9-11e9-bacf-0000b2370e64/farfaraway.txt",
     *    "absoluteUrl":"https://s3.eu-central-1.amazonaws.com/u4i-chatbot-kb/Training Data/0048fa02-3ab9-11e9-bacf-0000b2370e64/farfaraway.txt"
     * }
     */
    async upload(args: IKBFileUpload): Promise<IUploadedFile> {
      const formData = new FormData();
      formData.append("file", args.file);
      formData.append("courseId", args.courseId);
      formData.append("groupId", args.groupId);

      if (args.canOverwrite != undefined) {
        formData.append("canOverwrite", args.canOverwrite?'true':'false');
      }
      return (await this.apiClient.post(`/admin/chatbot/knowledge-base/files/upload`, formData)).data;
    }

    /**
     * Response: {
     *    "status":"success",
     *    "id":"1a0008af-58a2-11ef-9d3e-0242ac120006",
     *    "display_filename":"0048fa02-3ab9-11e9-bacf-0000b2370e64/farfaraway.txt",
     *    "absoluteUrl":"https://s3.eu-central-1.amazonaws.com/u4i-chatbot-kb/Training Data/0048fa02-3ab9-11e9-bacf-0000b2370e64/farfaraway.txt"
     * }
     */
    async generatePDFFiles(args: any): Promise<IUploadedFile> {
      const formData = new FormData();
      formData.append("id", args.id);
      formData.append("courseId", args.courseId);
      formData.append("groupId", args.groupId);

      if (args.canOverwrite != undefined) {
        formData.append("canOverwrite", args.canOverwrite?'true':'false');
      }
      return (await this.apiClient.post(`/admin/chatbot/knowledge-base/files/generate`, formData)).data;
    }

    async transcribeVideos(args: any): Promise<IUploadedFile> {
      const formData = new FormData();
      formData.append("id", args.id);
      formData.append("courseId", args.courseId);
      formData.append("groupId", args.groupId);

      if (args.canOverwrite != undefined) {
        formData.append("canOverwrite", args.canOverwrite?'true':'false');
      }
      return (await this.apiClient.post(`/admin/chatbot/knowledge-base/files/transcribe`, formData)).data;
    }
    async syncFiles(): Promise<boolean> {
      return (await this.apiClient.get(`/admin/chatbot/knowledge-base/sync`)).data;
    }

    async downloadFile(filepath: string): Promise<any> {
        return await this.apiClient.request({
          url: `/admin/chatbot/knowledge-base/file/${filepath}`, //your url
          method: 'GET',
          responseType: 'blob', // important
        })
    }
    async deleteKnowledgeBase(chatbotKBId: string): Promise<any> {
      return await this.apiClient.delete(`/admin/chatbot/knowledge-base/${chatbotKBId}`);
    }

}
