import {defineMessages} from 'react-intl';

export default defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'CustomerGrading.AttemptEdit.AttemptEditEvaluationConfirmOverlay.Close'
  },
  confirmationMessage: {
    defaultMessage: 'Attention! This task has already been assessed. If you click on "Ok", the status and feedback will be updated according to the changes you made.',
    id: 'CustomerGrading.AttemptEdit.AttemptEditEvaluationConfirmOverlay.ConfirmationMessage'
  },
  ok: {
    defaultMessage: 'OK',
    id: 'CustomerGrading.AttemptEdit.AttemptEditEvaluationConfirmOverlay.OK'
  },
});
