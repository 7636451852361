import {defineMessages} from 'react-intl';

export default defineMessages({
  allContent: {
    defaultMessage: "All Content",
    id: "ProgramLearnersViewMain.AllContent"
  },
  durationMins: {
    defaultMessage: "min",
    id: "ProgramLearnersViewMain.DurationMins"
  }
});
