import cx from "classnames";
import React from "react";
import ResizeObserver from "resize-observer-polyfill";
import { action, observable, makeObservable } from "mobx";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import { Select } from "antd";
import getTextsMeasurements from "@u4i/utils/getTextsMeasurements";

import "./_menu.scss";

interface Item {
  id: string;
  name: string;
  sortOrder: number;
}

interface IPropTypes {
  activeItemId: number | string;
  items: Array<Item>;
  menuDropdownLabel: JSX.Element | React.ReactNode;
  onMenuItemClick: (sectionId: string) => void;
}

interface MeasureItem {
  height: number;
  id: number | string;
  name: string;
  sortOrder: number;
  width: number;
}

const measureItems = (items: Array<Item>): Array<MeasureItem> => {
  const labels = items.map((entry) => entry.name);

  const measurements = getTextsMeasurements(
    labels,
    "subnavigation__menu__button"
  );

  return measurements.map((entry: MeasureItem, index: number) =>
    Object.assign({}, items[index], entry)
  );
};

export const Menu = observer(
  class Menu extends React.Component<IPropTypes> {
    private dropdownVersionEnabled = false;
    private isOpen = false;
    private resizeObserver: ResizeObserver;
    private containerRef = React.createRef<HTMLDivElement>();

    constructor(props: IPropTypes) {
      super(props);

      makeObservable<Menu, "dropdownVersionEnabled" | "isOpen">(this, {
        dropdownVersionEnabled: observable,
        isOpen: observable,
        checkAvailableSpace: action.bound,
        toggleOpen: action.bound,
      });
    }

    componentDidMount() {
      this.checkAvailableSpace();

      this.resizeObserver = new ResizeObserver(this.checkAvailableSpace);

      if (this.containerRef.current) {
        this.resizeObserver.observe(this.containerRef.current);
      }
    }

    componentDidUpdate(prevProps) {
      if (!isEqual(this.props.items, prevProps.items)) {
        this.checkAvailableSpace();
      }
    }

    componentWillUnmount() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    }

    checkAvailableSpace() {
      const measuredItems = measureItems(this.props.items);
      const containerNode = this.containerRef.current;

      if (containerNode) {
        const availableSpace: number = containerNode.clientWidth;
        const itemsWidth: number = measuredItems.reduce((accumulator, item) => accumulator + item.width, 0);

        if (itemsWidth > availableSpace) {
          this.dropdownVersionEnabled = true;
        } else {
          this.dropdownVersionEnabled = false;
        }
      }
    }

    handleMenuItemClick = (entry: Item) => {
      this.toggleOpen();
      this.props.onMenuItemClick(entry.id);
    };

    toggleOpen() {
      this.isOpen = !this.isOpen;
    }

    render() {
      return (
        <div className="subnavigation__menu" ref={this.containerRef}>
          {this.dropdownVersionEnabled && (
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              style={{ width: 300 }}
              onChange={(key) => { this.props.onMenuItemClick(key)}}
              defaultValue={this.props.activeItemId.toString()}
            >
              {this.props.items.map((entry: Item) => {
                return (
                  <Select.Option key={entry.id} value={entry.id}>{entry.name}</Select.Option>
                );
              })}
            </Select>
          )}

          {!this.dropdownVersionEnabled && (
            <ul
              className={cx({
                "list-unstyled": true,
                subnavigation__menu__list: true,
                "subnavigation__menu__list--dropdown":
                  this.dropdownVersionEnabled,
                "subnavigation__menu__list--is-open": this.isOpen,
                "disable-invert":true
              })}
            >
              {this.props.items.map((entry) => (
                <li key={entry.id}>
                  <button
                    className={cx({
                      subnavigation__menu__button: true,
                      "subnavigation__menu__button--active":
                        String(entry.id) === String(this.props.activeItemId),
                    })}
                    onClick={() => this.handleMenuItemClick(entry)}
                    type="button"
                  >
                    {entry.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }
  }
);
