import React, { FC } from "react";
import { ChallengeLabel } from "./ChallengeLabel/ChallengeLabel";
import { ViewedLabel } from "./ViewedLabel/ViewedLabel";
import { Row, Typography, Popover } from "antd";
import { InfoCircleFilled, ClockCircleFilled } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { ProviderSlider } from "./ProviderSlider/ProviderSlider";
import intlMessages from "./intlMessages";

interface IProps {
  challengesCompleted: boolean;
  challengesSubmitted: boolean;
  hasChallenges: boolean;
  hasMandatoryChallenges: boolean;
  isSkillBought: boolean;
  skillHasChallenges?: boolean;
  title: string;
  viewed: boolean;
  durationInMinutes: any;
  providers?: any;
  description?: string;
  showButtonResume?: boolean
}

const ChapterInfo: FC<IProps> = (props: IProps) => {

  const {
    challengesCompleted,
    challengesSubmitted,
    hasChallenges,
    hasMandatoryChallenges,
    isSkillBought,
    skillHasChallenges,
    title,
    viewed,
    durationInMinutes,
    providers,
    description,
    showButtonResume
  } = props;

  const content = () => (
    <div style={{
      maxWidth: 700
    }}>
      <div className="pt-8 pb-8 pl-8 pr-8" style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        {providers.length > 0 && (
          <div className="course-detail-page__chapter-card__providers">
            <ProviderSlider items={providers} />
          </div>
        )}
        <Typography.Text type="secondary" className="ml-8 disable-invert">
          {description}
        </Typography.Text>
      </div>
    </div>

  );

  return (
    <div className="chapter-info-wrapper">
      <Typography.Title level={4} className="mb-8" style={{
        marginTop:0
      }}>
        {title}
      </Typography.Title>

      <div className="mt-16 mb-16">
        <Row align="middle">
          <ClockCircleFilled style={{ color: "#E3E3E3", fontSize: 25 }} />
          <Typography.Text type="secondary" className="ml-8">
            <FormattedMessage
              {...intlMessages.duration}
              values={{ minutes: durationInMinutes }}
            />
          </Typography.Text>

          {!showButtonResume &&
            <Popover
              content={content}
              trigger="click"
              overlayClassName="info-card"
              placement="bottomLeft">
              <Row
                align="middle"
                style={{
                  cursor: "pointer",
                }}>
                <InfoCircleFilled
                  className="ml-16"
                  style={{ color: "#E3E3E3", fontSize: 25 }}
                />{" "}
                <Typography.Text className="ml-8" type="secondary">
                  {" "}
                  Info
                </Typography.Text>
              </Row>
            </Popover>
          }

        </Row>
      </div>

      <span>
        {hasChallenges ?
          hasMandatoryChallenges &&
          isSkillBought &&
          (<ChallengeLabel challengesCompleted={challengesCompleted} challengesSubmitted={challengesSubmitted} />)
          : isSkillBought &&
          !skillHasChallenges && <ViewedLabel viewed={viewed} />}
      </span>
    </div>
  );
};

export default ChapterInfo;
