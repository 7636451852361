import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {RouteComponentProps, withRouter} from 'react-router';
import StickyElement from '@u4i/common/StickyElement';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {Subnavigation} from '@u4i/common/Subnavigation';
import {BaseSection} from '../../state/sections/BaseSection';
import intlMessages from './intlMessages';
import Scroll from 'react-scroll';

const { scroller } = Scroll;

interface IPropTypes extends RouteComponentProps {
  displayLanguageFilter: boolean
  itemsLanguage: LanguageEnum | null
  sections: Array<BaseSection>
  onLanguageChange: (newItemsLanguage: LanguageEnum | null) => void
}

const Navigation = observer(class Navigation extends React.Component<IPropTypes> {
  handleEntryClick = (sectionId: string) => {
    window.location.hash = sectionId;

    scroller.scrollTo(sectionId, {
      duration: 1600,
      delay: 100,
      smooth: true,
      offset: -60
    })
  }

  render() {
    const sections = this.props.sections.filter(section => section.isVisible).map(section => ({
      id: section.id,
      name: section.title,
      sortOrder: section.positionIndex,
    }));

    // TODO: replace with full, accurate scrollspy that activates the most visible section
    const hash = this.props.location.hash && this.props.location.hash.replace('#', '');

    return (
      <StickyElement stickyTopOffset={64}>
        <Subnavigation
          activeItemId={hash || sections[0].id}
          activeItemsLanguage={this.props.itemsLanguage}
          displayLanguageFilter={this.props.displayLanguageFilter}
          items={sections}
          menuDropdownLabel={<FormattedMessage {...intlMessages.menuDropdownLabel} />}
          onLanguageItemClick={this.props.onLanguageChange}
          onMenuItemClick={this.handleEntryClick}
        />
      </StickyElement>
    );
  }
});

const WrappedComponent = withRouter(Navigation);

export {WrappedComponent as Navigation};
