import React, { FunctionComponent } from "react";
import { Modal, Button } from "antd";
import moment, { Moment } from "moment";
import { FormattedMessage } from 'react-intl';
import intlMessages from "./intlMessages";

interface IProps {
  eventStartTime: Moment | undefined
  eventEndTime: Moment | undefined
  isBookingConfirmationVisible: boolean
  isBookingLoader: boolean
  selectedLabRestTime: number
  bookSlot: () => void
  hideBookingConformationModal: () => void
}

//TODO: Important:- This component need to be refactor to make a re-usable modal component

export const BookingConfirmation: FunctionComponent<IProps> = (props: IProps) => {
  const {
    isBookingConfirmationVisible,
    eventStartTime,
    eventEndTime,
    isBookingLoader,
    selectedLabRestTime,
    bookSlot,
    hideBookingConformationModal
  } = props;

  const bookingDate: string = moment(eventStartTime).format('DD MMMM YYYY');
  const startTime: string = moment(eventStartTime).format('HH:mm');
  const endTime: string = moment(eventEndTime).subtract(selectedLabRestTime, 'minutes').format('HH:mm');

  return (
    <Modal
      footer={[
        <Button key="cancel" onClick={hideBookingConformationModal}>
          <FormattedMessage {...intlMessages.Cancel} />
        </Button>,
        <Button key="submit" type="primary" loading={isBookingLoader} onClick={bookSlot}>
          <FormattedMessage {...intlMessages.Book} />
        </Button>
      ]}
      onCancel={hideBookingConformationModal}
      open={isBookingConfirmationVisible}
      title={<FormattedMessage {...intlMessages.BookASlot} />}
    >
      <h2>{bookingDate}</h2>
      <p><FormattedMessage {...intlMessages.StartTime} /> - {startTime}</p>
      <p><FormattedMessage {...intlMessages.EndTime} /> - {endTime}</p>
    </Modal>
  );
};
