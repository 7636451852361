import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import {
  PLAYER_ELEMENT_ID_GUID_LENGTH,
  PLAYER_DEFAULT_MINIMUM_DURATION,
} from "@u4i/constantSettings";
import LoginModal from "@u4i/modules/Modals/LoginModal/LoginModal";
import PasswordRecoveryModal from "@u4i/modules/Modals/PasswordRecoveryModal/PasswordRecoveryModal";
import RegistrationModal from "@u4i/modules/Modals/RegistrationModal/RegistrationModal";
import RegistrationSuccessModal from "@u4i/modules/Modals/RegistrationSuccessModal/RegistrationSuccessModal";
import Header from "./Header";
import Footer from "./Footer";
import PlayerSlider from "./PlayerSlider";
import { inject, observer } from "mobx-react";
import { PlayerContext } from "../PlayerState/playerContext";
import { injectIntl, RawIntlProvider } from "react-intl";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import { XAPIVerbs } from "@u4i/common/enums/XApiEnum";
import { PlayerElementTypesEnum } from "@u4i/common/enums/PlayerElementTypesEnum";
import { postStatement } from "@u4i/common/TraxLrs";
import "./_index.scss";
import Chatbot from "@u4i/common/ChatBot/ChatBot";

const PlayerContent = (props) => {
  const [pageLocation, setPageLocation] = useState({
    firstPage: true,
    lastPage: false,
  });

  const [isChatbotHidden, setIsChatbotHidden] = useState(false);

  // TODO: clean layout process
  const queryParams = new URLSearchParams(window.location.search);
  const layouts = ["full", "basic"];
  const _layout =
    queryParams.has("layout") && layouts.includes(queryParams.get("layout"))
      ? queryParams.get("layout")
      : "full";
  const [layout] = useState(_layout);

  const [pageWidth, setPageWidth] = useState(
    props.isMenuActive ? "page_width_open" : "page_width_close"
  );
  const { groupId } = props.rootStore.userStore.userData;
  const _groupId = (groupId === undefined) ? '' : groupId[0];
  const playerState: any = useContext(PlayerContext);

  const allowedProviders = () => {
    return playerState.allowedProviders.filter(
      (provider) =>
        provider.id !==
        props.rootStore.settingsStore.settings.player
          .tableOfContentsExcludedProviderId
    );
  };

  const showProvidersSlide = () => {
    return (
      playerState.activeChapterData.showProviders &&
      allowedProviders().length > 0
    );
  };

  const handleElementView = (elementID) => {
    playerState.changeSavedScrollPosition(elementID);

    if (elementID.length == PLAYER_ELEMENT_ID_GUID_LENGTH) {
      playerState.recordElementView(
        elementID,
        PLAYER_DEFAULT_MINIMUM_DURATION,
        props.rootStore.programsLearnerViewStore.activeModuleIdInVE !=
          undefined || ""
          ? props.rootStore.programsLearnerViewStore.activeModuleIdInVE
          : undefined
      );

      const currentElement: any = props.elements.find(
        (el) => el.id === elementID
      );

      if (
        currentElement &&
        currentElement.type === PlayerElementTypesEnum.TEXT_IMAGE
      ) {
        const { groupId, groupName, email } =
          props.rootStore.userStore.userData;
        const { id } = playerState.activeChapterData;
        let context = {
          groupId: groupId[0],
          groupName: groupName,
          email: email,
          chapterId: id,
        };
        postStatement(
          XAPIVerbs.read,
          "element",
          elementID,
          currentElement.title,
          context
        );
      }
    }
  };

  useEffect(() => {
    if (props.isMenuActive) {
      setPageWidth("page_width_open");
    } else {
      setPageWidth("page_width_close");
    }
  }, [props.isMenuActive]);

  const handleOnNextChapterButton = () => {
    props.onChapterNavigation(playerState.nextChapterSlug);
    playerState.updateActiveElementId("intro", "intro");
  };

  const navigationLanguage = () => {
    const courseLanguage =
      playerState.navigationStructure.skillLanguage ||
      playerState.activeChapterData.language;

    if (props.rootStore.languageStore.currentLocale != courseLanguage) {
      return LanguageEnum.EN_US;
    }

    return courseLanguage;
  };

  useEffect(() => {
    const classList = document.body.classList;
    const classNamesToCheck = [
      "fp-viewing-tableOfContents",
      "fp-viewing-providers",
      "fp-viewing-intro",
      "fp-viewing-summary",
    ];

    const checkCurrentSlide = () => {
      const allClasses = Array.from(classList);
      const currentClass = allClasses.find((cls) =>
        classNamesToCheck.includes(cls)
      );

      if (currentClass && isChatbotHidden) {
        setIsChatbotHidden(false);
      }
    };

    checkCurrentSlide();

    const observer = new MutationObserver(() => {
      checkCurrentSlide();
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, [setIsChatbotHidden, isChatbotHidden]);

  return (
    <div
      className={cx({
        menu_transition_player: true,
        menu_transition_player_open: props.isMenuActive,
        menu_transition_player_close: !props.isMenuActive,
      })}
      style={{
        height: "100vh",
        transition: "transform 0.3s ease-in-out",
        transformOrigin: "right",
      }}
    >
      <RawIntlProvider
        value={props.rootStore.languageStore.intlMap.get(
          navigationLanguage() == LanguageEnum.EN_US ||
            navigationLanguage() == LanguageEnum.DE_DE
            ? navigationLanguage()
            : LanguageEnum.EN_US
        )}
      >
        <Header
          activeTopicName={playerState.activeTopicName}
          firstTopicName={playerState.firstTopicName}
          setMenuActive={props.setMenuActive}
          isMenuActive={props.isMenuActive}
          onPlayerExit={() =>
            props.exitPlayer(
              props.rootStore.programsLearnerViewStore.programClpUrl
            )
          }
          layout={layout}
        />
        <PlayerSlider
          contentsPaginated={playerState.contentsPaginated}
          elements={props.elements}
          isActiveChapterLast={playerState.isActiveChapterLast}
          summariesPaginated={
            props.rootStore.userStore.isAuthorized
              ? playerState.summariesPaginated
              : []
          }
          showDownloadPdfButton={
            playerState.activeChapterData.showDownloadPdfButton
          }
          activeChapterData={playerState.activeChapterData}
          userStore={props.rootStore.userStore}
          showProvidersSlide={showProvidersSlide}
          hasMandatoryChapters={
            playerState.navigationStructure.hasMandatoryChapters
          }
          hasQuickFeedback={playerState.navigationStructure.hasQuickFeedback}
          onMarkCurrentlyViewed={playerState.changeCurrentlyViewedTopic}
          handleElementView={handleElementView}
          setPageLocation={setPageLocation}
          pageWidth={pageWidth}
          activeTopicId={playerState.activeTopicId}
          firstScrollPosition={playerState.firstScrollPosition}
          isMenuActive={props.isMenuActive}
          feedbackSurvey={playerState.navigationStructure.feedbackSurvey}
          pageLocation={pageLocation}
          startFeedbackSurvey={playerState.startFeedbackSurvey}
          topics={playerState.topics}
          activeElementId={playerState.activeElementId}
          showDisclaimer={playerState.playerDisclaimer}
          fetching={playerState.fetching}
          isUserAuth={props.rootStore.userStore.isAuthorized}
          rateCourseByChapter={playerState.rateCourseByChapter}
          skillID={playerState.navigationStructure.skillId}
          setIsChatbotHidden={setIsChatbotHidden}
        />
        {!isChatbotHidden && (
          <Chatbot
            showChatbot={
              playerState.navigationStructure?.chapters[0]?.showChatbot
            }
            skillId={playerState.navigationStructure.id}
            isSideMenuExpanded={props.isMenuActive}
            groupId={_groupId}
            userId={props.rootStore.userStore.userData.id}
          />
        )}
        <Footer
          isActiveChapterLast={playerState.isActiveChapterLast}
          isLastElement={pageLocation.lastPage}
          isFirstElement={pageLocation.firstPage}
          hasMandatoryChapters={
            playerState.navigationStructure.hasMandatoryChapters
          }
          activeChapterData={playerState.activeChapterData}
          chapters={playerState.navigationStructure.chapters}
          onNextChapterClick={handleOnNextChapterButton}
          isMenuActive={props.isMenuActive}
          feedbackSurvey={playerState.navigationStructure.feedbackSurvey}
        />
      </RawIntlProvider>

      {props.rootStore?.userStore.isLoginModalVisible && (
        <LoginModal userStore={props.rootStore?.userStore}></LoginModal>
      )}

      {props.rootStore?.userStore.isRegistrationModalVisible && (
        <RegistrationModal rootStore={props.rootStore}></RegistrationModal>
      )}

      {props.rootStore?.userStore.isPasswordRecoveryModalVisible && (
        <PasswordRecoveryModal
          userStore={props.rootStore?.userStore}
        ></PasswordRecoveryModal>
      )}

      {props.rootStore?.userStore?.isRegistrationSuccessModalVisible && (
        <RegistrationSuccessModal
          userStore={props.rootStore?.userStore}
        ></RegistrationSuccessModal>
      )}
    </div>
  );
};

export default inject("rootStore")(injectIntl(observer(PlayerContent)));
