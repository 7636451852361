import {defineMessages} from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Forms.EditComment.Comment.CommentActionBar.Cancel',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'Forms.EditComment.Comment.CommentActionBar.Edit',
  },
  save: {
    defaultMessage: 'Save',
    id: 'Forms.EditComment.Comment.CommentActionBar.Save',
  },
});
