import DOMPurify from 'dompurify';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_attempt-edit-feedback.scss';

interface IPropTypes {
  feedback: string
}

export class AttemptEditFeedback extends React.Component <IPropTypes> {
  get sanitizedFeedback() {
    return DOMPurify.sanitize(this.props.feedback);
  }

  render() {
    return (
      <div className="customer-grading__attempt-edit-feedback">
        <FontAwesome className="far customer-grading__attempt-edit-feedback__icon" name="comment-dots" />

        <div>
          <h4 className="customer-grading__attempt-edit-feedback__heading">
            <FormattedMessage {...intlMessages.feedback} />
          </h4>

          <div
            className="customer-grading__attempt-edit-feedback__text"
            dangerouslySetInnerHTML={{__html: this.sanitizedFeedback}}
          />
        </div>
      </div>
    );
  }
}
