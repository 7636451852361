import { darkGray, gallery, white } from '@u4i/styles/styles-variables';
import React, { FC } from 'react';
import { fontFamilySans } from '../../constants/Constants';

interface IProps {
  isHovering?: boolean
  title: string
  x: number
  y: number
}

export const ModuleInfoLabel: FC<IProps> = (props: IProps) => {
  const {
    isHovering,
    title,
    x,
    y
  } = props;

  const truncate = (str: string): string => {
    return str?.length > 44 ? str?.substring(0, 42) + ".." : str;
  }

  return (
    <svg>
      <g style={{cursor: 'default', userSelect: 'none'}}>
        <path
          style={{
            fill: isHovering ? gallery : white
          }}
          d={`m ${x+3},${y - 13} c 0,-4.7202 1.6625,-6.5801 6.3449,-6.652 h 250 c 5.584,-0.019 8.664,2.3553 8.664,8.3511 v 21.0441 c 0,6.0339 -2.818,7.9569 -8.664,8.0145 h -250 c -4.6252,-0.049 -6.0939,-2.017 -6.3449,-7.3033 0.027,-12.1538 -0.016,-13.6369 0,-23.4544 z`}
        />
        <text
          style={{
            fontWeight: 600,
            fontSize: 11,
            fill: darkGray,
            lineHeight: 1,
            fontFamily: fontFamilySans
          }}
          x={x + 8}
          y={y + 3}
        >
          {truncate(title)}
        </text>
      </g>
    </svg>
  )
}

