import cx from 'classnames';
import React, {Component} from 'react';
import Progressbar from './components/Progressbar';
import Toolbar from './components/Toolbar';

import './_controls.scss';

interface IProps {
  availablePlaybackRates: number[]
  currentTime: number
  duration: number
  isFullscreen: boolean
  isMuted: boolean
  isPlaying: boolean
  onFullscreenClick: () => any
  onPlaybackRateSet: () => any
  onPlaybackToggle: () => any
  onSeekTo: (value: any) => any
  onSoundToggle: () => any
  onVolumePercentageChange: () => any
  playbackRate: number
  visible: boolean
  volumePercentage: number
}

class Controls extends Component<IProps> {
  public state: any = {
    playbackRateDropdownOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (!this.props.visible && prevProps.visible) {
      this.closeDropdowns();
    }
  }

  closeDropdowns = () => this.setState({playbackRateDropdownOpen: false});

  handlePlaybackRateDropdownToggle = () => {
    this.setState({playbackRateDropdownOpen: !this.state.playbackRateDropdownOpen});
  };

  handleSeekTo = (timeFraction: any) => {
    this.props.onSeekTo(timeFraction * this.props.duration);
  };

  render() {
    const {
      availablePlaybackRates, 
      currentTime, 
      duration, 
      isFullscreen, 
      isMuted, 
      isPlaying, 
      playbackRate, 
      visible, 
      volumePercentage,
      onFullscreenClick, 
      onPlaybackRateSet, 
      onPlaybackToggle,
      onSoundToggle, 
      onVolumePercentageChange 
    } = this.props;
    return (
      <div
        className={cx({
          'youtube-player__controls': true,
          'youtube-player__controls--active': visible,
        })}
      >
        <button
          className="youtube-player__controls__playback-toggle-area"
          onClick={onPlaybackToggle}
          onDoubleClick={onFullscreenClick}
        />

        <div
          className={cx({
            'youtube-player__controls__upper-layer': true,
            'youtube-player__controls__upper-layer--visible': visible || isFullscreen,
          })}
        >
          <Progressbar
            duration={duration}
            progressPercentage={(currentTime / duration) * 100}
            onSeekTo={this.handleSeekTo}
          />

          <Toolbar
            availablePlaybackRates={availablePlaybackRates}
            currentTime={currentTime}
            duration={duration}
            isFullscreen={isFullscreen}
            isMuted={isMuted}
            isPlaybackRateDropdownOpen={this.state.playbackRateDropdownOpen}
            isPlaying={isPlaying}
            onFullscreenClick={onFullscreenClick}
            onPlaybackRateDropdownToggle={this.handlePlaybackRateDropdownToggle}
            onPlaybackRateSet={onPlaybackRateSet}
            onPlaybackToggle={onPlaybackToggle}
            onVolumePercentageChange={onVolumePercentageChange}
            onVolumeToggle={onSoundToggle}
            playbackRate={playbackRate}
            volumePercentage={volumePercentage}
          />
        </div>
      </div>
    );
  }
}

export default Controls;
