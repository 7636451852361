import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import "../_terms-of-service-modal.scss";
import intlMessages from "../intlMessages";

interface IProps {
  sanitizedContent: any;
  sendAnswer?: any;
  currentModal?:any,
  isLastModal?:boolean
}

const ModalContent: FC<IProps> = (props: IProps) => {
  const { sanitizedContent, sendAnswer ,currentModal,isLastModal} = props;

  return (
    <>
      <div
        className="terms-of-service-modal__content"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />

      <div className="terms-of-service-modal__buttons">
        <button
          className="terms-of-service-modal__button"
          onClick={() => {
            currentModal ? sendAnswer(currentModal,isLastModal) : sendAnswer(true);
          }}
          type="button"
        >
          <FormattedMessage {...intlMessages.accept} />
        </button>
      </div>
    </>
  );
};

export default ModalContent;
