import React from 'react';
import FormatDate from '@u4i/common/FormatDate';
import './_answer-header.scss';

interface IPropTypes {
  authorFirstname: string
  authorLastname: string
  creationDate: string
}

export const AnswerHeader: React.FC<IPropTypes> = (props) => (
  <header>
    <h4 className="discuss__answer-header__author-name">
      {props.authorFirstname}
      &nbsp;
      {props.authorLastname}
    </h4>

    <p className="discuss__answer-header__creation-date">
      <FormatDate source={props.creationDate} longMonth={true}/>
    </p>
  </header>
);
