import { darkGray } from '@u4i/styles/styles-variables'
import React, { FC } from 'react'
import { fontFamilySans, fontSize14 } from '../../constants/Constants'

interface IProps {
  x: number
  y: number
  text: JSX.Element | string
}

export const LabelSvg: FC<IProps> = (props: IProps) => {
  const {
    x,
    y,
    text
  } = props;

  return (
    <svg>
      <g style={{ cursor: 'default', userSelect: 'none' }}>
        <text
          fill={darkGray}
          fontFamily={fontFamilySans}
          fontSize={fontSize14}
          fontWeight={600}
          x={x}
          y={y}
        >
          <tspan>{text}</tspan>
        </text>
      </g>
    </svg>
  )
}
