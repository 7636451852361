import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_buy-button.scss';

interface IProps {
  onClick: () => void
};

export const BuyButton: FC<IProps> = (props: IProps) =>(
  <button className="course-detail-page__buy-button" onClick={props.onClick}>
    <FormattedMessage {...intlMessages.startNow} />
  </button>
);
