import {defineMessages} from 'react-intl';

export default defineMessages({
  certificateHeading: {
    defaultMessage: 'Qualification certificate',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificateHeading',
  },
  challengesHeading: {
    defaultMessage: 'Challenges',
    id: 'CourseDetailPage.Header.CertificationStatusBox.ChallengesHeading',
  },
  contentViewedHeading: {
    defaultMessage: 'Content viewed',
    id: 'CourseDetailPage.Header.CertificationStatusBox.ContentViewedHeading',
  },
});
