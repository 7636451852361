import {defineMessages} from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Overview',
    id: 'CourseDetailPage.Overview.Heading',
  },
  digitalLabBookingMessage: {
    defaultMessage: 'This course contains a Digital Lab',
    id: 'CourseDetailPage.Overview.DigitalLabBookingMessage',
  },
  digitalLabDescription: {
    defaultMessage: 'Please book your slot in advance to ensure the lab will be available at your preferred time. {br} A digital lab is a cloud-based environment that provides you with remote access to all the tutorials, hardware, software and other assets you need to get started on a certain topic',
    id: 'CourseDetailPage.Overview.DigitalLabDescription',
  },
});
