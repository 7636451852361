import Parser from '@u4i/utils/Parser';
import {AdminUserCourse} from '@u4i/modules/Admin/modules/UserCourses/interfaces';


export const AdminUserCourseParser = new Parser<AdminUserCourse>(adminUserCourse => ({
  firstName: adminUserCourse.firstName,
  lastName: adminUserCourse.lastName,
  email: adminUserCourse.email,
  skillTitle: adminUserCourse.skillTitle,
  skillInternalId: adminUserCourse.skillInternalId,
  purchasePrice: adminUserCourse.purchasePrice,
  discount: adminUserCourse.discount,
  purchaseDate: adminUserCourse.purchaseDate,
  id: adminUserCourse.id,
  username: adminUserCourse.username,
  paymentMethod: adminUserCourse.paymentMethod,
  paymentStatus: adminUserCourse.paymentStatus,
  proofOfQUalificationUrl: adminUserCourse.proofOfQUalificationUrl,
  transcriptUrl: adminUserCourse.transcriptUrl,
  hasFinishedSkill: adminUserCourse.hasFinishedSkill,
  canDownloadCertificate: adminUserCourse.canDownloadCertificate,
  paymentMethodCaption: adminUserCourse.paymentMethodCaption
}));
