import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PauseIcon from '@material-ui/icons/Pause';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import StartIcon from '@material-ui/icons/PlayArrow';

import PlaybackRateDropdown from './components/PlaybackRateDropdown';
import TimeDisplay from './components/TimeDisplay';
import VolumeControls from './components/VolumeControls';
import { white } from '@u4i/styles/styles-variables';
import './_toolbar.scss';

// Toolbar.propTypes = {
//   availablePlaybackRates: PropTypes.arrayOf(PropTypes.number).isRequired,
//   currentTime: PropTypes.number.isRequired,
//   duration: PropTypes.number.isRequired,
//   isFullscreen: PropTypes.bool.isRequired,
//   isMuted: PropTypes.bool.isRequired,
//   isPlaybackRateDropdownOpen: PropTypes.bool.isRequired,
//   isPlaying: PropTypes.bool.isRequired,
//   onFullscreenClick: PropTypes.func.isRequired,
//   onPlaybackRateDropdownToggle: PropTypes.func.isRequired,
//   onPlaybackRateSet: PropTypes.func.isRequired,
//   onPlaybackToggle: PropTypes.func.isRequired,
//   onVolumePercentageChange: PropTypes.func.isRequired,
//   onVolumeToggle: PropTypes.func.isRequired,
//   playbackRate: PropTypes.number.isRequired,
//   volumePercentage: PropTypes.number.isRequired,
// };

interface IProps {
  availablePlaybackRates: number[]
  currentTime: number
  duration: number
  isFullscreen: boolean
  isMuted: boolean
  isPlaybackRateDropdownOpen: boolean
  isPlaying: boolean
  playbackRate: number
  volumePercentage: number
  onFullscreenClick: () => any
  onPlaybackRateDropdownToggle: () => any
  onPlaybackRateSet: () => any
  onPlaybackToggle: () => any
  onVolumePercentageChange: () => any
  onVolumeToggle: () => any
}

const Toolbar: FC<IProps> = (props: IProps) => {
  const {
    availablePlaybackRates,
    currentTime,
    duration,
    isFullscreen,
    isMuted,
    isPlaybackRateDropdownOpen,
    isPlaying,
    playbackRate,
    volumePercentage,
    onFullscreenClick,
    onPlaybackRateDropdownToggle,
    onPlaybackRateSet,
    onPlaybackToggle,
    onVolumePercentageChange,
    onVolumeToggle
  } = props;
  
  return (
    <div className="youtube-player__toolbar">
      <button
        className="youtube-player__toolbar__play-pause"
        onClick={onPlaybackToggle}
      >
        {isPlaying ?
          <PauseIcon style={{color: white}} />
          :
          <StartIcon style={{color: white}} />
        }
      </button>

      <VolumeControls
        isMuted={isMuted}
        onVolumePercentageChange={onVolumePercentageChange}
        onVolumeToggle={onVolumeToggle}
        volumePercentage={volumePercentage}
      />

      <div className="youtube-player__toolbar__time-display" >
        <TimeDisplay currentTime={currentTime} duration={duration} />
      </div>

      <PlaybackRateDropdown
        availablePlaybackRates={availablePlaybackRates}
        isOpen={isPlaybackRateDropdownOpen}
        onDropdownToggle={onPlaybackRateDropdownToggle}
        onPlaybackRateSet={onPlaybackRateSet}
        playbackRate={playbackRate}
      />

      <button className="youtube-player__toolbar__fullscreen" onClick={onFullscreenClick}>
        {isFullscreen ?
          <FullscreenExitIcon style={{color: white}} />
          :
          <FullscreenIcon style={{color: white}} />
        }
      </button>
    </div>
  );
}

export default Toolbar;
