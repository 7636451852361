import { white } from "@u4i/styles/styles-variables";
import { IModuleOptions, ModuleType } from ".";

export const phaseStyling = {
  backgroundColor: white,
  border: '3px solid',
  borderColor: '#f5f5f5',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.30)',
  marginLeft: 10,
  marginTop: 10,
  width: 350
};

export const moduleStyling = {
  background: "white",
  borderRadius: 7,
  borderWidth: 2,
  borderStyle: "outset",
  padding: 15
};

export const ModuleTypesList: IModuleOptions[] = [
  { name: 'Digital Jupiter lab', value: ModuleType.LABS },
  { name: 'Group discussion', value: ModuleType.GROUP_DISCUSSION },
  { name: 'Interactive task', value: ModuleType.INTERACTIVE_TASK },
  { name: 'Live event', value: ModuleType.LIVE_EVENT },
  { name: 'Online course', value: ModuleType.ONLINE_COURSE },
  { name: 'Online discussion', value: ModuleType.ONLINE_DISCUSSION },
  { name: 'Practice exercise', value: ModuleType.PRACTICE_EXERCISE },
  { name: 'Self reflection', value: ModuleType.SELF_REFLECTION },
  { name: 'Use cases', value: ModuleType.USE_CASES },
  { name: 'Karl', value: ModuleType.KARL }
];

export const TraxLrsUrl = 'https://prod-aws-traxlrs.university4industry.com/trax/api/a43328e0-ea7a-4185-bf2f-d3f4930917bb/xapi/std';

export const TraxLrsCredentials = {
    username: 'traxlrs',
    password: 'c9gsthsqjUaPAanxvVH9'
  };
