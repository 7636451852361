import {defineMessages} from 'react-intl';

export default defineMessages({
  estimatedHours: {
    defaultMessage: '{timeHours} hours',
    id: 'SkillDuration.DurationInHours.EstimatedHours',
  },
  estimatedHourAndMinute: {
    defaultMessage: '{timeHours} hour {timeMinutes} minute',
    id: 'SkillDuration.DurationInHours.EstimatedHourAndMinute',
  },
  estimatedHourAndMinutes: {
    defaultMessage: '{timeHours} hour {timeMinutes} minutes',
    id: 'SkillDuration.DurationInHours.EstimatedHourAndMinutes',
  },
  estimatedHoursAndMinute: {
    defaultMessage: '{timeHours} hours {timeMinutes} minute',
    id: 'SkillDuration.DurationInHours.EstimatedHoursAndMinute',
  },
  estimatedHoursAndMinutes: {
    defaultMessage: '{timeHours} hours {timeMinutes} minutes',
    id: 'SkillDuration.DurationInHours.EstimatedHoursAndMinutes',
  },
});
