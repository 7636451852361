import React from 'react';
import {FormattedMessage} from 'react-intl';
import {EXTERNAL_BASE_URL} from '@u4i/constantSettings';
import discoverIcon from '@u4i/assets/svg/discoverIcon.svg';
import intlMessages from './intlMessages';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';

export interface ITab {
  hideOnMobile: boolean;
  icon?: JSX.Element;
  id: string;
  label: React.ReactElement;
  link?: {
    [LanguageEnum.EN_US]: string;
    [LanguageEnum.DE_DE]: string;
  };
  route?: string;
}

export const tabsList: Array<ITab> = [{
  hideOnMobile: false,
  icon: <img alt="discover" src={discoverIcon} />,
  id: 'discover',
  label: <FormattedMessage {...intlMessages.discover} />,
  route: 'discover',
},
{
  hideOnMobile: true,
  id: 'about',
  label: <FormattedMessage {...intlMessages.aboutUs} />,
  link: {
    [LanguageEnum.EN_US]: `${EXTERNAL_BASE_URL}/en`,
    [LanguageEnum.DE_DE]: `${EXTERNAL_BASE_URL}`,
  },
}];
