import cx from "classnames";
import CodeIcon from "@material-ui/icons/Code";
import DOMPurfy from "dompurify";
import FontAwesome from "react-fontawesome";
import GetAppIcon from "@material-ui/icons/GetApp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import React, { Component } from "react";
import { RouteComponentProps } from '@reach/router';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import CircularProgress from "@u4i/common/CircularProgress";
import { AttemptGradingForm } from "@u4i/reactiveForms/forms/AttemptGradingForm";
import { ISolutionFile } from "@u4i/parsers/ChallengeAttemptParser";
import { IRootStore } from "@u4i/state/RootStore";
import { AttemptEditErrorMessage } from "./components/AttemptEditErrorMessage";
import { GradingEvaluationStore } from "../AttemptEvaluation/state/GradingEvaluationStore";
import { AttemptEditEvaluationConfirmOverlay } from "./components/AttemptEditEvaluationConfirmOverlay";
import { action, makeObservable, observable } from "mobx";
import { AttemptEditSubmissionOverlay } from "./components/AttemptEditSubmissionOverlay";
import { AttemptEditFeedback } from "./components/AttemptEditFeedback";
import intlMessages from "./intlMessages";
import { AttemptDetailData } from "./components/AttemptDetailData";
import "../AttemptEvaluation/_attempt-evaluation.scss";
import "./_attempt-edit.scss";

interface IMatchParams extends RouteComponentProps{
  match: any;
}

interface IPropTypes extends IMatchParams {
  rootStore: IRootStore;
}

@inject("rootStore")
@observer
export class AttemptEdit extends Component<IPropTypes> {
  private gradingEvaluationStore: GradingEvaluationStore;
  public showConfirmationPopup: boolean = false;
  public triggerSubmitAttempt: boolean = false;
  graderEvaluationStatus: string = "";

  constructor(props: IPropTypes) {
    super(props);
    this.gradingEvaluationStore = this.props.rootStore.gradingEvaluationStore;

    makeObservable<AttemptEdit>(this, {
      graderEvaluationStatus: observable,
      showConfirmationPopup: observable,
      triggerSubmitAttempt: observable,
      handleCloseConfirmationPopup: action.bound,
      handlePassedFailedClick: action.bound,
      handleSubmitConfirmationPopup: action.bound,
    });
  }

  componentDidMount() {
    this.gradingEvaluationStore.init(this.attemptId);
  }

  componentWillUnmount() {
    this.gradingEvaluationStore.reset();
  }

  get attemptId() {
    return this.props.match.params.attemptId;
  }

  handleCloseConfirmationPopup() {
    this.showConfirmationPopup = false;
  }

  handleSubmitConfirmationPopup() {
    this.showConfirmationPopup = false;
    this.triggerSubmitAttempt = true;
  }

  handlePassedFailedClick(graderEvaluationStatus: 'passed' | 'failed') {
    this.graderEvaluationStatus = graderEvaluationStatus;
    this.showConfirmationPopup = true;
  }

  get sanitizedContextInfo() {
    if (!this.gradingEvaluationStore.attemptData) {
      return "";
    }

    return DOMPurfy.sanitize(this.gradingEvaluationStore.attemptData.contextInfo,{ ADD_ATTR: ["target"] });
  }

  get sanitizedStudentInstructions() {
    if (!this.gradingEvaluationStore.attemptData) {
      return "";
    }

    return DOMPurfy.sanitize(this.gradingEvaluationStore.attemptData.studentInstructions,{ ADD_ATTR: ["target"] });
  }

  get solutionFilesList() {
    if (!this.gradingEvaluationStore.attemptData || !this.gradingEvaluationStore.attemptData.solutionFiles) {
      return [];
    }

    return this.gradingEvaluationStore.attemptData.solutionFiles;
  }

  render() {
    if (this.gradingEvaluationStore.fetching) {
      return <CircularProgress />;
    }

    const { gradingEvaluationStore } = this;
    const { attemptData } = gradingEvaluationStore;

    if (this.gradingEvaluationStore.errorLoadingAttemptData) {
      return (
        <AttemptEditErrorMessage
          icon={<FontAwesome name="exclamation-triangle" />}
          message={<FormattedMessage {...intlMessages.incorrectId} />}
        />
      );
    }

    if (!attemptData) {
      return null;
    }

    return (
      <div className="customer-grading__attempt-edit">
        <div
          className={cx({
            "customer-grading__attempt-evaluation__dimmer": true,
            "customer-grading__attempt-evaluation__dimmer--visible": gradingEvaluationStore.submitting || gradingEvaluationStore.submissionSuccess || this.showConfirmationPopup,
          })}
        />

        <div className="container">
          <div className="customer-grading__attempt-edit__header">
            <h3 className="customer-grading__attempt-edit__challenge-name">
              {attemptData.challengeName}
            </h3>

            <AttemptDetailData
              date={attemptData.date}
              graderName={attemptData.grader}
              groupName={attemptData.groupName}
              status={attemptData.status}
              isMCQTest={attemptData?.challengeDetails}
              userName={attemptData.userName}
            />
          </div>

          {attemptData.graderFeedback &&
            <AttemptEditFeedback feedback={attemptData.graderFeedback} />
          }

          {attemptData.exampleSolutionFile && (
            <a
              className="customer-grading__attempt-evaluation__example-solution-button"
              href={attemptData.exampleSolutionFile.url}
              download={attemptData.exampleSolutionFile.fileName}
              title={attemptData.exampleSolutionFile.fileName}
            >
              <span className="customer-grading__attempt-evaluation__example-solution-icon">
                <FontAwesome name="download" />
              </span>

              <FormattedMessage {...intlMessages.downloadSolution} />
            </a>
          )}

          {this.sanitizedContextInfo && (
            <div className="customer-grading__attempt-edit__context-wrapper">
              <h4 className="customer-grading__attempt-edit__context-heading">
                <FormattedMessage {...intlMessages.context} />
              </h4>

              <div
                className="customer-grading__attempt-edit__context"
                dangerouslySetInnerHTML={{ __html: this.sanitizedContextInfo }}
              />
            </div>
          )}

          {this.sanitizedStudentInstructions && (
            <div className="customer-grading__attempt-edit__instructions-wrapper">
              <h4 className="customer-grading__attempt-edit__instructions-heading">
                <FormattedMessage {...intlMessages.instructions} />
              </h4>

              <div
                className="customer-grading__attempt-edit__instructions"
                dangerouslySetInnerHTML={{
                  __html: this.sanitizedStudentInstructions,
                }}
              />
            </div>
          )}

          {attemptData?.challengeDetails?.passmark ?
            <></>
            :
            <div className="customer-grading__attempt-evaluation__solution-files-wrapper">
              <h4 className="customer-grading__attempt-evaluation__solution-files-wrapper-heading">
                <FormattedMessage {...intlMessages.solutionFiles} />
              </h4>

              <div className="customer-grading__attempt-evaluation__solution-files-wrapper">
                {this.solutionFilesList.length > 0 && (
                  <List component="nav" aria-label="main mailbox folders">
                    {this.solutionFilesList.map(
                      (file: ISolutionFile, index: number) => (
                        <ListItem
                          className="customer-grading__attempt-evaluation__solution-file"
                          key={index}
                        >
                          <ListItemIcon className="customer-grading__attempt-evaluation__list-icon">
                            <CodeIcon />
                          </ListItemIcon>

                          <span className="customer-grading__attempt-evaluation__file-name">
                            {file.name}
                          </span>

                          <ListItemSecondaryAction>
                            <div className="customer-grading__attempt-evaluation__file-wrapper">
                              {file.previewUrl !== null && (
                                <a
                                  className="customer-grading__attempt-evaluation__view-file"
                                  href={file.previewUrl}
                                  target="_blank"
                                >
                                  <VisibilityIcon />

                                  <FormattedMessage {...intlMessages.view} />
                                </a>
                              )}

                              <a
                                className="customer-grading__attempt-evaluation__download-file"
                                download={file.name}
                                href={file.downloadUrl}
                              >
                                <GetAppIcon />

                                <FormattedMessage {...intlMessages.download} />
                              </a>
                            </div>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )}
                  </List>
                )}
              </div>
            </div>
          }

          <div className="customer-grading__attempt-edit__form-wrapper">
            <AttemptGradingForm
              editMode={true}
              graderFeedback={attemptData.graderFeedback}
              handlePassedFailedClick={this.handlePassedFailedClick}
              onSubmit={gradingEvaluationStore.updateGrading}
              questions={attemptData.questions}
              triggerSubmitAttempt={this.triggerSubmitAttempt}
              graderEvaluationStatus={this.graderEvaluationStatus}
            />

            <AttemptEditSubmissionOverlay
              submissionSuccess={gradingEvaluationStore.submissionSuccess}
              submitting={gradingEvaluationStore.submitting}
            />

            <AttemptEditEvaluationConfirmOverlay
              handleClosePopup={this.handleCloseConfirmationPopup}
              handleSubmitPopup={this.handleSubmitConfirmationPopup}
              showConfirmationPopup={this.showConfirmationPopup}
            />
          </div>
        </div>
      </div>
    );
  }
}
