import withUser from "@u4i/modules/UserManager/withUser";
import React, { Component } from "react";
import { PlayerContext } from "./playerContext";

function withPlayerDataFetcher(WrappedComponent) {
  const resultingComponent = class extends Component<any> {
    static contextType = PlayerContext;

    constructor(props) {
      super(props);
    }

    componentDidMount() {
      this.reloadNavigation()
        .then(this.reloadElements)
        .then(this.restoreScrollPosition);
    }

    componentDidUpdate(prevProps) {
      if (this.props.match.params.skillSlug !== prevProps.match.params.skillSlug) {
        this.props.resetState();
        this.reloadNavigation()
          .then(this.reloadElements)
          .then(this.restoreScrollPosition);
      } else if (this.props.match.params.chapterSlug !== prevProps.match.params.chapterSlug) {
        this.reloadElements().then(this.restoreScrollPosition);
      } else if (this.props.isAuthorized !== prevProps.isAuthorized) {
        this.reloadNavigation()
          .then(this.reloadElements)
          .then(this.restoreScrollPosition);
      }
    }

    componentWillUnmount() {
      this.context.resetState();
    }

    reloadElements = () => {
      return this.context.loadElements(this.props.match.params.chapterSlug);
    };

    reloadNavigation = () => {
      const { params } = this.props.match;

      return this.context.loadNavigation(params.skillSlug, params.chapterSlug);
    };

    restoreScrollPosition = () => {
      if (!this.props.isAuthorized) {
        this.context.toggleFetching(false);
        return Promise.resolve();
      };

      const { params } = this.props.match;
      return this.context.restoreScrollPosition(params.skillSlug, params.chapterSlug);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          elements={this.context.elements}
          fetching={this.context.fetching}
          topics={this.context.topics}
        />
      );
    }
  };

  return withUser(resultingComponent);
}

export default withPlayerDataFetcher;
