import cx from 'classnames';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import { green } from '@u4i/styles/styles-variables';
import intlMessages from './intlMessages';
import '../NotificationWindow/_notification-window.scss';

interface IProps {
  isVisible: boolean
  onCloseWindow: () => void
}

export const EndSessionNotification: FC<IProps> = (props: IProps) => (
  <div
    className={cx({
      'digital-lab__notification-window': true,
      'digital-lab__notification-window--visible': props.isVisible,
    })}
  >
    <div className="digital-lab__notification-window__box">
      <div className="digital-lab__notification-window__content">
        <ErrorOutlineIcon style={{color: green, height: 52, width: 52}} />

        <p className="digital-lab__notification-window__message">
          <FormattedMessage {...intlMessages.message} />
        </p>

        <button
          className="digital-lab__notification-window__button"
          onClick={props.onCloseWindow}
          type="button"
        >
          <FormattedMessage {...intlMessages.closeWindow} />
        </button>
      </div>
    </div>
  </div>
);
