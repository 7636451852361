import React, { FC } from 'react';
import {observer} from 'mobx-react';
import YoutubePlayer from '@u4i/common/YoutubePlayer';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import {HorizontalSlider} from '../common/HorizontalSlider';
import {NoItemsMessage} from '../common/NoItemsMessage';
import {TrailerSection} from './TrailerSection';
import { ITrailer } from '@u4i/parsers/TrailerParser';

interface IPropTypes {
  sectionModel: TrailerSection;
}

const VisualComponent: FC<IPropTypes> = (props) => {
  const {sectionModel} = props;

  return (
    <BasicInfoWrapper
      isLoading={sectionModel.fetching}
      itemsCountLabel={sectionModel.items.length}
      sectionModel={sectionModel}
    >
      <div className="container">
        {sectionModel.items.length > 0 ?
          <HorizontalSlider forceArrowsNavigation>
            {props.sectionModel.items.map((entry: ITrailer) => (
              <YoutubePlayer height={180} key={entry.id} videoId={entry.videoId} />
            ))}
          </HorizontalSlider>
          :
          <NoItemsMessage />
        }
      </div>
    </BasicInfoWrapper>
  );
};

const WrappedComponent = observer(VisualComponent);

export {WrappedComponent as VisualComponent};
