import React from "react";
import cx from "classnames";
import { Row, Typography } from "antd";
import { MenuFoldOutlined, CloseCircleFilled, MenuUnfoldOutlined } from "@ant-design/icons";
import { injectIntl } from "react-intl";
import intlMessages from "../intlMessages";

const Header = (props) => {
  const headerTitle: string = props.activeTopicName
    ? props.activeTopicName
    : props.firstTopicName;
  const summaryTitle: string = props.intl.formatMessage(
    intlMessages.summaryLabel
  );
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        background: "black",
        padding: 10,
        height: "70px",
      }}
    >
      {props.layout == 'full' ? (
          props.isMenuActive ? (
              <>
                <MenuFoldOutlined
                  onClick={() => props.setMenuActive((prev) => !prev)}
                  style={{
                    color: "white",
                    fontSize: 25,
                    cursor: "pointer",
                  }}
                  className="player__toggle-button icon_font_calcualator"
                />
              </>
            ) : (
              <>
                <MenuUnfoldOutlined
                  onClick={() => props.setMenuActive((prev) => !prev)}
                  style={{
                    color: "white",
                    fontSize: 25,
                    cursor: "pointer",
                  }}
                  className="player__toggle-button icon_font_calcualator"
                />
              </>
            )
      ) : (
        <>
          <MenuFoldOutlined />
        </>
      )}
      <Typography.Title
        level={4}
        style={{
          color: "white",
          margin: 0,
          transition: "transform 0.3s ease-in-out",
        }}
        className={cx({
          header_transition_title: true,
          header_transition_title_open: props.isMenuActive,
          header_transition_title_close: !props.isMenuActive,
        })}
      >
        {headerTitle === "summary" ? summaryTitle : headerTitle}
      </Typography.Title>
      {props.layout == 'full' ? (
        <CloseCircleFilled
          className={cx({
            icon_font_calcualator: true,
            "player__exit-button": true,
            header_transition_open: props.isMenuActive,
            header_transition_close: !props.isMenuActive,
          })}
          style={{
            color: "white",
            fontSize: 25,
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
          }}
          onClick={props.onPlayerExit}
        />
      ) : (
        <CloseCircleFilled/>
      )}
    </Row>
  );
};

export default injectIntl(Header);
