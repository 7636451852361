import Parser from '@u4i/utils/Parser';

export interface Answer {
  answer: string;
  files: Array<File>;
  graderFeedback?: string;
}

export interface Answers {
  [questionId: string]: Answer;
}

export interface IAttempt {
  answers?: Answers | null;
  attemptId: string;
  status: number;
}
export interface IExercise {
  createdAt: string;
  exampleSolutionFilename: string;
  id: string;
  instructions: string;
  questions: Array<{
    content: string;
    criteria?: string;
    id: string;
    possibleScore: number;
    requiredFileUpload: boolean;
    requiredTextAnswer: boolean;
    hideFileUpload: boolean;
    sortOrder: number;
  }>;
  title: string;
}

export const ExerciseParser = new Parser<IExercise>(exercise => ({
  createdAt: exercise.created_at,
  exampleSolutionFilename: exercise.example_solution_filename,
  id: exercise.id,
  instructions: exercise.instructions,
  questions: exercise.questions.map(question => ({
    content: question.content,
    criteria: question.criteria,
    id: question.id,
    possibleScore: parseFloat(question.possibleScore),
    requiredFileUpload: question.required_file_upload === 1,
    requiredTextAnswer: question.required_text_answer === 1,
    hideFileUpload: question.hide_file_upload === 1,
    sortOrder: question.sort_order,
  }),
  ).sort((questionA, questionB) => {
    if (questionA.sortOrder > questionB.sortOrder) {
      return 1;
    }

    if (questionA.sortOrder < questionB.sortOrder) {
      return -1;
    }

    return 0;
  }),
  title: exercise.title
}));
