import {defineMessages} from 'react-intl';

export default defineMessages({
  challengeCompleted: {
    defaultMessage: 'Completed',
    id: 'Player.Navigation.ChallengeList.ChallengeListItem.ChallengeCompleted',
  },
  challengeInGrading: {
    defaultMessage: 'Grading in progress',
    id: 'Player.Navigation.ChallengeList.ChallengeListItem.ChallengeInGrading',
  },
  challengeOpen: {
    defaultMessage: 'Open',
    id: 'Player.Navigation.ChallengeList.ChallengeListItem.ChallengeOpen',
  },
  challengeOpenTryAgain: {
    defaultMessage: 'Open - please try again',
    id: 'Player.Navigation.ChallengeList.ChallengeListItem.ChallengeOpenTryAgain',
  },
  status: {
    defaultMessage: 'Status:',
    id: 'Player.Navigation.ChallengeList.ChallengeListItem.Status',
  },
  warningMessage: {
    id: "CourseDetailPage.Overview.UncompletedChapter",
    defaultMessage: "In order to view the following chapters, all elements of the previous chapters need to be completed"
  },
});
