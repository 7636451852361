import React, { FC, useState } from 'react';
import { mainBlue, white } from '@u4i/styles/styles-variables';
import { opacity8, opacity9 } from '../../constants/Constants';

interface IProps {
  x: number
  y: number
  onPreviousPhaseClick: () => void
}

export const LeftArrow: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    x,
    y,
    onPreviousPhaseClick
  } = props;

  const [opacity, setOpacity] = useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  return (
    <svg>
    	<g
        onClick={onPreviousPhaseClick}
        onMouseEnter={handleOpacity}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          ...(opacity && { opacity: opacity8 })
        }}
      >
    		<path
          d={`m ${x-10},${y+4} -19.057658,19.522477 c -1.363694,1.826305 -1.458822,3.205028 0.309882,4.958092 l 19.073174,17.8801 c 3.543744,0.04055 7.508782,1.684209 6.677934,-6.104633 l -14.905291,-14.564393 14.874272,-16.377199 c -0.467053,-2.686812 0.367075,-6.430458 -6.972313,-5.314444 z`}
          style={{
            fill:mainBlue,
            opacity: opacity9
          }}
        />
    		<rect
          height="50"
          style={{
            fill: white,
            fillOpacity: 0
          }}
          width="33"
          x={x-33}
          y={y}
        >
          <title>Previous Phase</title>
        </rect>
    	</g>
    </svg>
  )
}
