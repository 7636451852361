import FormatDate from '@u4i/common/FormatDate';
import React, { FC } from 'react';
import './_answer-info.scss';

interface IProps {
  createdAt: string;
  index: number;
}

export const AnswerInfo: FC<IProps> = (props: IProps) => {
  return (
    <div className="discuss-page__answer-info">
      <span className="discuss-page__answer-info__index">
        #{props.index + 1}
      </span>

      <span className="discuss-page__answer-info__date">
        <FormatDate source={props.createdAt} onlyDate={true}/>
      </span>
    </div>
  );
};
