import cx from 'classnames';
import React from 'react';
import {Link} from 'react-router-dom';
import { ButtonTypeEnum } from '../enums/ButtonTypeEnum';
import {LinkTargetEnum} from '../enums/LinkTargetEnum';
import './_u4i-button.scss';

interface IProps {
  children: any
  className: string
  disabled?: boolean
  filled?: boolean
  href?: string
  inline?: boolean
  muted?: boolean
  onClick: () => void
  primary?: boolean
  route?: string
  target?: LinkTargetEnum.BLANK | LinkTargetEnum.PARENT | LinkTargetEnum.SELF | LinkTargetEnum.TOP
  type: ButtonTypeEnum.ACTION | ButtonTypeEnum.LINK | ButtonTypeEnum.NAVIGATION | ButtonTypeEnum.SUBMIT
}

const U4IButton = (props: IProps) => {
  switch (props.type) {
    case ButtonTypeEnum.ACTION:
      if (!props.onClick) {
        throw new Error('Missing onClick handler on action button');
      }

      return (
        <button
          className={cx({
            [props.className]: props.className !== null,
            'u4i-button': true,
            'u4i-button--disabled': props.disabled,
            'u4i-button--filled': props.filled,
            'u4i-button--inline': props.inline,
            'u4i-button--muted': props.muted,
            'u4i-button--primary': props.primary
          })}
          disabled={props.disabled}
          onClick={props.onClick}
          type="button"
        >
          {props.children}
        </button>
      );

    case ButtonTypeEnum.LINK:
      if (!props.href) {
        throw new Error('Missing href on link button');
      }

      return (
        <a
          className={cx({
            [props.className]: props.className !== null,
            button: true,
            'u4i-button--disabled': props.disabled,
            'u4i-button--filled': props.filled,
            'u4i-button--inline': props.inline,
            'u4i-button--muted': props.muted,
            'u4i-button--primary': props.primary,
          })}
          href={props.href}
          target={props.target}
        >
          {props.children}
        </a>
      );

    case ButtonTypeEnum.NAVIGATION:
      if (!props.route) {
        throw new Error('Missing route on navigation button');
      }

      return (
        <Link
          className={cx({
            [props.className]: props.className !== null,
            button: true,
            'u4i-button--disabled': props.disabled,
            'u4i-button--filled': props.filled,
            'u4i-button--inline': props.inline,
            'u4i-button--muted': props.muted,
            'u4i-button--primary': props.primary,
          })}
          to={props.route}
        >
          {props.children}
        </Link>
      );

    case ButtonTypeEnum.SUBMIT:
      return (
        <button
          className={cx({
            [props.className]: props.className !== null,
            button: true,
            'u4i-button--disabled': props.disabled,
            'u4i-button--filled': props.filled,
            'u4i-button--inline': props.inline,
            'u4i-button--muted': props.muted,
            'u4i-button--primary': true,
          })}
          disabled={props.disabled}
          type="submit"
        >
          {props.children}
        </button>
      );

    default:
      return <></>
  }
}

export default U4IButton;
