class HacksManager {
   // @ts-ignore
  enableLogging = __DEV__;
  rubberbandBlockInitialY;

  blockRubberbandScroll = () => {
    const rootElement:any = document.getElementById('root');

    rootElement.addEventListener('touchstart', (event) => {
      if (event.targetTouches.length === 1) {
        this.rubberbandBlockInitialY = event.targetTouches[0].clientY;
      }
    }, false);

    rootElement.addEventListener('touchmove', (event) => {
      if (event.targetTouches.length === 1) {
        this._handleRubberbandBlock(event);
      }
    }, false);

    if (this.enableLogging) {
      console.info('[iOS rubberband scrolling] >> prevention enabled');
    }
  };

  _handleRubberbandBlock = (event) => {
    const {body, documentElement} = document;

    const scrollTop = Math.max(window.pageYOffset, documentElement.scrollTop, body.scrollTop);
    const deltaY = event.targetTouches[0].clientY - this.rubberbandBlockInitialY;
    const didScrollReachElementEnd = scrollTop + window.innerHeight === document.body.scrollHeight;

    if ((scrollTop === 0 && deltaY > 0) || (didScrollReachElementEnd && deltaY < 0)) {
      event.preventDefault();
    }
  };

  hideMobileAddressBar = () => {
    return new Promise((resolve:any) => {
      const {marginTop, overflow} = document.body.style;

      document.body.style.overflow = 'hidden';
      document.body.style.marginTop = '1px';

      setTimeout(() => {
        window.scrollTo(0, 1);

        setTimeout(() => {
          document.body.style.marginTop = marginTop;
          document.body.style.overflow = overflow;

          window.scrollTo(0, 0);

          if (this.enableLogging) {
            console.info('[Mobile address bar] >> hidden');
          }

          resolve();
        }, 500);
      });
    });
  };
}

const singleton = new HacksManager();

export default singleton;
