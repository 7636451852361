import cx from 'classnames';
import React, { FC } from 'react';
import {CompareMeTabIdEnum} from '../../../../CompareMeSection';
import './_tab-button.scss';

interface IProps {
  children: string
  isActive: boolean
  tabId: CompareMeTabIdEnum
  onClick: (tabId: CompareMeTabIdEnum) => void
}

export const TabButton: FC<IProps> = (props: IProps) => {
  const {
    children,
    isActive,
    tabId,
    onClick
  } = props;

  return (
    <button
      className={cx({
        'landing-page__tab-button': true,
        'landing-page__tab-button--active': isActive,
      })}
      onClick={() => onClick(tabId)}
      type="button"
    >
      {children}
    </button>
  );
}
