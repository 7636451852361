import {defineMessages} from 'react-intl';

export default defineMessages({
  cityErrorsPresence: {
    defaultMessage: 'City cannot be blank',
    id: 'Forms.CoursePurchase.City.Errors.Presence',
  },
  cityLabel: {
    defaultMessage: 'City',
    id: 'Forms.CoursePurchase.City.Label',
  },
  countryErrorsPresence: {
    defaultMessage: 'Country cannot be blank',
    id: 'Forms.CoursePurchase.Country.Errors.Presence',
  },
  countryLabel: {
    defaultMessage: 'Country',
    id: 'Forms.CoursePurchase.Country.Label',
  },
  postalCodeErrorsPresence: {
    defaultMessage: 'Postal code cannot be blank',
    id: 'Forms.CoursePurchase.PostalCode.Errors.Presence',
  },
  postalCodeLabel: {
    defaultMessage: 'Postal code',
    id: 'Forms.CoursePurchase.PostalCode.Label',
  },
  postalCodeErrorsMaxLength: {
    defaultMessage: 'Maximum {limit} characters',
    id: 'Forms.CoursePurchase.PostalCode.Errors.MaxLength',
  },
  purchaseCourseLabel: {
    defaultMessage: 'Purchase course via invoice',
    id: 'Forms.CoursePurchase.PurchaseCourse.Label',
  },
  purchaseCourseButton: {
    defaultMessage: 'Purchase course',
    id: 'Forms.CoursePurchase.PurchaseCourseButton.Button',
  },
  streetLabel: {
    defaultMessage: 'Street',
    id: 'Forms.CoursePurchase.Street.Label',
  },
  streetErrorsPresence: {
    defaultMessage: 'Street cannot be blank',
    id: 'Forms.CoursePurchase.Street.Errors.Presence',
  }
});
