import {action, observable, runInAction,makeObservable} from 'mobx';

import {IApi} from '@u4i/state/ServicesInterfaces';
import {IJupyterLab} from '@u4i/parsers/JupyterLabParser';
import {IRootStore} from '@u4i/state/RootStore';
import { postStatement } from '@u4i/common/TraxLrs';
import { XAPIVerbs } from '@u4i/common/enums/XApiEnum';

export class JupyterLabStore {
  private apiService: IApi;
  private labId?: string;
  closingLab = false;
  fetching = true;
  isFullscreen = false;
  isLabClosed = false;
  isLabFinishedLoading = false;
  isLabSubmitted = false;
  labData?: IJupyterLab;
  labEnabledError = false;
  labError = false;
  labSubmitError = false;
  labSubmitted = false;
  logoutUrl?: string;
  submittingLab = false;
  showSubmissionStatusWindow = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      closingLab: observable,
      fetching: observable,
      isFullscreen: observable,
      isLabClosed: observable,
      isLabFinishedLoading: observable,
      isLabSubmitted: observable,
      labData: observable,
      labEnabledError: observable,
      labError: observable,
      labSubmitError: observable,
      labSubmitted: observable,
      logoutUrl: observable,
      submittingLab: observable,
      showSubmissionStatusWindow: observable,
      changeFullscreenStatus: action.bound,
      closeLab: action.bound,
      init: action.bound,
      loadLabData: action.bound,
      reset: action.bound,
      setFetching: action.bound,
      submitLab: action.bound
    });

    const {apiService} = rootStore;

    this.apiService = apiService;
  }

  changeFullscreenStatus(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
  }

  async closeLab() {
    if (this.labId && this.labData) {
      this.closingLab = true;

      try {
        const jupyterLabCloseResponse = await this.apiService.jupyterLab.closeSession(this.labId);

        runInAction(() => {
          this.logoutUrl = jupyterLabCloseResponse.logoutUrl;
        });
      } catch(error) {
      } finally {
        runInAction(() => {
          this.closingLab = false;
          this.isLabClosed = true;
        });
      }
    }
  }

  async init(labId: string) {
    this.labId = labId;

    await this.loadLabData();
  }

  async loadLabData() {
    if (this.labId) {
      this.fetching = true;
      this.labSubmitted = false;

      try {
        const labDataResponse = await this.apiService.jupyterLab.fetchStreamingUrl(this.labId);

        runInAction(() => {
          this.labData = labDataResponse;
          postStatement(XAPIVerbs.launched, 'challenge-attempt', this.labData.chalengeAttemptId, this.labData.title);
        });
      } catch (error) {
        runInAction(() => {
          if (error.message === 'Request failed with status code 400') {
            this.labEnabledError = true;
          } else {
            this.labError = true;
          }
        });
      } finally {
        runInAction(() => {
          this.fetching = false;
          this.isLabFinishedLoading = true;
        });
      }
    }
  }

  reset() {
    this.fetching = true;
    this.isLabClosed = false;
    this.isLabFinishedLoading = false;
    this.isLabSubmitted = false;
    this.labData = undefined;
    this.labEnabledError = false;
    this.labError = false;
    this.labId = undefined;
    this.labSubmitError = false;
    this.labSubmitted = false;
    this.logoutUrl = undefined;
    this.showSubmissionStatusWindow = false;
    this.submittingLab = false;
  }

  setFetching() {
    this.fetching = true;
  }

  async submitLab() {
    if (this.labId && this.labData) {
      this.submittingLab = true;
      this.showSubmissionStatusWindow = true;

      try {
        const jupyterLabSubmitResponse = await this.apiService.jupyterLab.submitSession(this.labId, this.labData.chalengeAttemptId);
  
        runInAction(() => {
          postStatement(XAPIVerbs.experienced, 'challenge-attempt', this.labData?.chalengeAttemptId, this.labData?.title);

          postStatement(XAPIVerbs.submitted, 'challenge-atempt', this.labData?.chalengeAttemptId, this.labData?.title);
          this.labSubmitted = true;
          this.logoutUrl = jupyterLabSubmitResponse.logoutUrl;
        });
      } catch(error) {
        runInAction(() => {
          this.labSubmitError = true;
        });
      } finally {
        runInAction(() => {
          this.submittingLab = false;
          this.isLabSubmitted = true;
        });
      }
    }
  }
}
