import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import intlMessages from "./intlMessages";
import { useSpring, animated } from "react-spring";
import "./_chatbot.scss";
import { Button, FloatButton, Form, Input, Popover } from "antd";
import {
  CloseCircleOutlined,
  ExpandAltOutlined,
  SendOutlined,
  ShrinkOutlined,
  UserOutlined,
  DislikeOutlined,DislikeFilled,
  LikeOutlined,LikeFilled
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { marked } from "marked";
import { observer } from 'mobx-react';
import {EXTERNAL_CHATBOT_BASE_URL} from "@u4i/constantSettings";
import { XAPIVerbs } from '../enums/XApiEnum';
import { postStatement } from '../TraxLrs';

enum MessageType {
  RECEIVED = "received",
  SENT = "sent",
  STREAMING = "streaming",
}

interface Message {
  id: number;
  text: string;
  type: MessageType;
  timestamp: Date;
}

interface ChatbotProps extends WrappedComponentProps {
  isSideMenuExpanded: boolean,
  groupId: string,
  userId: string,
  showChatbot: boolean,
  skillId: string | undefined,
}

const Chatbot: React.FC<ChatbotProps> = (props: ChatbotProps) => {
  const {formatMessage} = props.intl;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [messages, setMessages] = useState<Message[]>([
    {
      id: Date.now(),
      text: formatMessage(intlMessages.hello),
      type: MessageType.RECEIVED,
      timestamp: new Date(),
    },
  ]);
  const [inputText, setInputText] = useState("");
  const [disliked, setDisliked] = useState<Message[]>([]);
  const [liked, setLiked] = useState<Message[]>([]);
  const [dislikedFeedback, setDislikedFeedback] = useState("");
  const [likedFeedback, setLikedFeedback] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [streamingMessageId, setStreamingMessageId] = useState<number | null>(
    null
  );

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const chatContainerRef = useRef<null | HTMLDivElement>(null);

  const chatbotTitle: string = formatMessage(intlMessages.title);
  const chatbotTypeMessagePlaceholder: string = formatMessage(intlMessages.placeholder);

  const sendMessage = () => {
    const trimmedInput = inputText.trim();
    if (trimmedInput) {
      
      postStatement(XAPIVerbs.asked, 'karl', props.userId, props.skillId);

      const newMessage: Message = {
        id: Date.now(),
        text: trimmedInput,
        type: MessageType.SENT,
        timestamp: new Date()
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputText("");
      setIsLoading(true);

      const streamMessageId = Date.now() + 1; // Ensure unique ID for streaming message
      setStreamingMessageId(streamMessageId);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: streamMessageId,
          text: "",
          type: MessageType.STREAMING,
          timestamp: new Date(),
        },
      ]);

      fetchEventSource(
        EXTERNAL_CHATBOT_BASE_URL+"/streamqa",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: trimmedInput,
            student_id: props.userId,
            filter_name: "course",
            filter_value: props.skillId ? props.skillId : null,
            user_group: props.groupId,
          }),
          onmessage: (event) => {
            setMessages((prevMessages) =>
              prevMessages.map((message) =>
                message.id === streamMessageId
                  ? {
                      ...message,
                      text: formatText(message.text + event.data),
                    }
                  : message
              )
            );
          },
          onerror: (event) => {
            setErrorMessage("Failed to connect. Please try again later.");
            setIsLoading(false);
          },
          onclose: () => {
            setMessages((prevMessages) =>
              prevMessages.map((message) =>
                message.id === streamMessageId
                  ? { ...message, type: MessageType.RECEIVED }
                  : message
              )
            );
            setStreamingMessageId(null);
            setIsLoading(false);
          },
        }
      );
    }
  };

  const chatStyle = useSpring({
    to: {
      transform: isChatOpen ? `scale(1)` : `scale(0)`,
      opacity: isChatOpen ? 1 : 0,
      width: isChatExpanded ? "600px" : "400px",
      height: isChatExpanded ? "800px" : "600px",
      bottom: isChatOpen ? "80px" : "40px",
      right: isChatOpen ? "45px" : "30px",
    },
    from: {
      transform: "scale(0)",
      opacity: 0,
      width: "0px",
      height: "0px",
      bottom: "40px",
      right: "30px",
    },
    config: { mass: 1, tension: 210, friction: 26 },
  });

  const openChat = () => {
    setIsChatOpen(true);
    document.body.classList.add("no-scroll"); // Disable body scrolling
    postStatement(XAPIVerbs.opened, 'karl', props.userId, props.skillId);
    setErrorMessage("");
  };

  const closeChat = () => {
    if (isChatOpen) {
      setIsChatOpen(false);
      document.body.classList.remove("no-scroll"); // Re-enable body scrolling
      postStatement(XAPIVerbs.closed, 'karl', props.userId, props.skillId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetParentNode:HTMLElement = ((event.target as HTMLElement).parentNode as HTMLElement);
      if (
        !(targetParentNode.className == "ant-popover-inner-content" 
        || targetParentNode.className == "ant-popover-content" 
        || targetParentNode.className == "feedback-input"
        || targetParentNode.className == "anticon anticon-dislike"
        || targetParentNode.className == "anticon anticon-like")
      ) {
        if (
          chatContainerRef.current &&
          !chatContainerRef.current.contains(event.target as Node)
        ) {
          closeChat();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const chatMessagesContainer = messagesEndRef.current;

    if (chatMessagesContainer) {
      setTimeout(() => {
        chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
      }, 100);
    }
  }, [messages]);

  const likedHandler = (message: Message, i: number) => {
    if (!liked.find((like) => {return like === message})) {
      const trimmedFeedback = likedFeedback.trim();
      setLiked([...liked, message]);
      postStatement(XAPIVerbs.liked, 'karl', props.userId, props.skillId, {"message": {"sent": messages[i-1].text, "received": formatText(message.text), "additional": trimmedFeedback}});
    } else {
      let i = liked.indexOf(message)
      liked.splice(i,1);
      setLiked(liked);
    }
  }
  const dislikedHandler = (message: Message, i: number) => {
    if (!disliked.find((dislike) => {return dislike === message})) {
      const trimmedFeedback = dislikedFeedback.trim();
      setDisliked([...disliked, message]);
      postStatement(XAPIVerbs.disliked, 'karl', props.userId, props.skillId, {"message": {"sent": messages[i-1].text, "received": formatText(message.text), "additional": trimmedFeedback}});
    } else {
      let i = disliked.indexOf(message)
      disliked.splice(i,1);
      setDisliked(disliked);
    }
  }

  const isLiked = (message) => {
    if (liked.length > 0 && liked.find((like) => {return like === message}))
    {
      return <LikeFilled
        style={{float: "right"}} 
      />
    }
    return <LikeOutlined 
      style={{float: "right"}}
    />
  }
  const isDisliked = (message) => {
    if (disliked.length > 0 && disliked.find((dislike) => {return dislike === message}))
    {
      return <DislikeFilled
      style={{float: "right", marginLeft: "5px"}} 
    />
    }
    return <DislikeOutlined 
      style={{float: "right", marginLeft: "5px"}}
    />
  }

  return (
    <div
      className={isChatOpen ? "chatbot-container" : "chatbot-container-hidden"}
      style={{
        display: !props.showChatbot ? "none" : "",
        marginRight: props.isSideMenuExpanded ? "350px" : "0px",
      }}
    >
      <FloatButton
        style={{
          display: !props.showChatbot ? "none" : "",
          borderColor: "#005a95",
          transition: props.isSideMenuExpanded
            ? "right 0.3s ease-in-out"
            : "right 0.3s ease-in-out",
          position: "absolute",
          right: props.isSideMenuExpanded ? "calc(350px + 45px)" : "45px",
        }}
        className={`chatbot-icon ${
          isChatOpen ? "hide" : ""
        } float-button-hover`}
        icon={<UserOutlined />}
        onClick={openChat}
      />
      <animated.div
        className={`chat-area ${isChatOpen ? "active" : ""}`}
        style={chatStyle}
        ref={chatContainerRef}
      >
        <div className="chat-header">
          <span>{chatbotTitle}</span>
          {!isChatExpanded ? (
            <ExpandAltOutlined
              className="expand-icon"
              onClick={() => setIsChatExpanded(!isChatExpanded)}
            />
          ) : (
            <ShrinkOutlined
              className="expand-icon"
              onClick={() => setIsChatExpanded(!isChatExpanded)}
            />
          )}
          <CloseCircleOutlined className="close-icon" onClick={closeChat} />
        </div>
        <div className="chat-messages" ref={messagesEndRef}>
          {messages.map((message, i) => (
            <div
              key={message.id}
              className={`${
                message.type === MessageType.SENT ? "sent-postion" : ""
              }`}
            >
              <div className={`timestamp ${message.type}`}>
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>

              <div
                className={`message ${message.type} ${
                  message.type === MessageType.STREAMING ? "streaming" : ""
                }`}
                style={{ margin: "0px" }}
                dangerouslySetInnerHTML={{
                  //@ts-ignore
                  //disabled as it thinks it returns promeis but it's string
                  __html: marked(formatText(message.text)),
                }}
              />
              {(i > 0 && (message.type == "received")) && 
              <div 
                className={`message feedback`}
              >
                <Popover 
                  content={
                    <Form.Item name="popover-disliked" noStyle>
                      <p>{formatMessage(intlMessages.disliked)}</p>
                      <div className="feedback-input">
                        <Input 
                          onChange={(e) => setDislikedFeedback(e.target.value)}
                          className="chat-input-area"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              dislikedHandler(message, i);
                            }
                          }}
                        />
                        <Button 
                          type="primary"
                          shape="circle"
                          size="large"
                          className="white-icon-button"
                          icon={<DislikeOutlined style={{ color: "#fff" }} />}
                          onClick={() => dislikedHandler(message, i)}
                        />
                      </div>
                    </Form.Item>
                  }
                  placement="bottomRight"
                >
                  {isDisliked(message)}
                </Popover>
                <Popover 
                  content={
                    <Form.Item name="popover-liked" noStyle>
                      <p>{formatMessage(intlMessages.liked)}</p>
                      <div className="feedback-input">
                        <Input 
                          onChange={(e) => setLikedFeedback(e.target.value)}
                          className="chat-input-area"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              likedHandler(message, i);
                            }
                          }}
                        />
                        <Button 
                          type="primary"
                          shape="circle"
                          size="large"
                          className="white-icon-button"
                          icon={<LikeOutlined style={{ color: "#fff" }} />}
                          onClick={() => likedHandler(message, i)}
                        />
                      </div>
                    </Form.Item>
                  }
                  placement="bottomRight"
                >
                  {isLiked(message)}
                </Popover>
              </div>
              }
            </div>
          ))}
          {errorMessage && <div className="message error">{errorMessage}</div>}
        </div>
        <div className="chat-input">
          <TextArea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder={chatbotTypeMessagePlaceholder}
            autoSize={{ minRows: 1, maxRows: 1 }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
            className="chat-input-area"
          />
          <Button
            type="primary"
            shape="circle"
            size="large"
            className="white-icon-button"
            onClick={() => sendMessage()}
            icon={<SendOutlined style={{ color: "#fff" }} />}
          />
        </div>
      </animated.div>
    </div>
  );
};

export default injectIntl(observer(Chatbot));

const formatText = (input: string): string => {
  let formatted = input
    .replace(/data:\s*/g, "") // Remove "data: " prefix
    .replace(/\\n/g, "\n") // Replace escaped new lines
    .replace(/\n\n/g, "<br><br>") // Replace double new lines with HTML line breaks
    .replace(/-\s+/g, "- ") // Ensure list items are properly formatted
    .replace(/\*\*/g, "**") // Ensure bold text is properly formatted
    .replace(/<br><br>\*\*/g, "<br><br>**") // Ensure new lines before bold text are properly formatted
    .replace(/\\xe4/g, "ä") // fix umlaute
    .replace(/\\xc4/g, "Ä")
    .replace(/\\xfc/g, "ü")
    .replace(/\\xdc/g, "Ü")
    .replace(/\\xf6/g, "ö")
    .replace(/\\xd6/g, "Ö")
    .replace(/\\xdf/g, "ß");

  return formatted;
};
