import React from 'react';

export interface FunctionalColumnConfig<RowDataShape> {
  centerContent?: boolean;
  content: (rowData: RowDataShape) => React.ReactNode;
  label?: React.ReactNode;
  order: number;
  width: number;
}

export class FunctionalColumn<RowDataShape> {
  centerContent: boolean;
  content: (rowData: RowDataShape) => React.ReactNode;
  id: string;
  label: React.ReactNode;
  order: number;
  width: number;

  constructor(config: FunctionalColumnConfig<RowDataShape> & { id: string }) {
    this.centerContent = config.centerContent === undefined ? false : config.centerContent;
    this.content = config.content;
    this.id = config.id;
    this.label = config.label !== undefined ? config.label : '';
    this.order = config.order;
    this.width = config.width;
  }
}
