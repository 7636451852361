import React from 'react';

import './_error-message.scss';

interface IPropTypes {
  children: React.ReactNode;
}

export const ErrorMessage: React.FunctionComponent<IPropTypes> = (props) => (
  <div className="reactive-forms__error-message">
    {props.children}
  </div>
);
