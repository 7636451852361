import DOMPurify from 'dompurify';
import React from 'react';
import {computed,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

import intlMessages from './intlMessages';
import './_grading-guidelines.scss';

interface IPropTypes {
  criterions: string;
  modelAnswer?: string;
}

export const GradingGuidelines = observer(class GradingGuidelines extends React.Component<IPropTypes> {
  constructor(props: IPropTypes) {
    super(props);

    makeObservable(this, {
      criterions: computed,
      modelAnswer: computed
    });
  }

  get criterions() {
    return DOMPurify.sanitize(this.props.criterions, {ADD_ATTR: ['target']});
  }

  get modelAnswer() {
    if (!this.props.modelAnswer) {
      return;
    }

    return DOMPurify.sanitize(this.props.modelAnswer, {ADD_ATTR: ['target']});
  }

  render() {
    return (
      <div className="reactive-forms__attempt-grading-form__grading-guidelines">
        <h5 className="reactive-forms__attempt-grading-form__grading-guidelines__header">
          <FormattedMessage {...intlMessages.criterions} />
        </h5>

        <p dangerouslySetInnerHTML={{__html: this.criterions}} />

        {this.modelAnswer &&
          <React.Fragment>
            <h5 className="reactive-forms__attempt-grading-form__grading-guidelines__header">
              <FormattedMessage {...intlMessages.modelAnswer} />
            </h5>

            <p dangerouslySetInnerHTML={{__html: this.modelAnswer}} />
          </React.Fragment>
        }
      </div>
    );
  }
});
