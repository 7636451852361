import cx from 'classnames';
import React from 'react';

import './_circular-progress.scss';

const CircularProgress = (props) => {
  return (
    <div
      className={cx({
        'circular-progress': true,
        'circular-progress--no-margin': props.noMargin,
      })}
    >
      <svg className="circular-progress__bar" viewBox="25 25 50 50">
        <circle
          className="circular-progress__path"
          cx="50"
          cy="50"
          fill="none"
          r="20"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}
export default CircularProgress;
