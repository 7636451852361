import moment from "moment";
import PropTypes from "prop-types";

export const anyChildType = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string])
  ),
  PropTypes.element,
  PropTypes.node,
  PropTypes.string,
]);

const YOUTUBE_VIDEO_ID_REGEXP =
  /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

export function customPropTypeFactory(checkerFunction) {
  const targetFunction = checkerFunction;

  targetFunction.isRequired = (props, propName, componentName) => {
    const value = props[propName];

    if (value === null || value === undefined) {
      return new Error(
        `The prop \`${propName}\` is marked as` +
          ` required in \`${componentName}\`, but its value is \`${value}\`.`
      );
    }

    return targetFunction(props, propName, componentName);
  };

  return targetFunction;
}

export const downloadAutomatically = (data, fileName, type) => {
  let blob = new Blob([data], { type });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  return true;
};

export function extractYoutubeVideoId(youtubeUrl) {
  const match = youtubeUrl.match(YOUTUBE_VIDEO_ID_REGEXP);

  if (match && match[2].length === 11) {
    return match[2];
  }

  return "";
}

export function getRandomInt(min, max) {
  const targetMin = Math.ceil(min);
  const targetMax = Math.floor(max) + 1;
  return Math.floor(Math.random() * (targetMax - targetMin)) + targetMin;
}

export function getNavigatorLanguage() {
  // @ts-ignore
  const navigatorLanguage = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

  switch (navigatorLanguage) {
    case "de":
    case "de-DE":
      return "de-DE";

    case "en":
    case "en-US":
      return "en-US";

    default:
      return "en-US";
  }
}

export function isInternetExplorer() {
  // @ts-ignore
  return ((!!window.MSInputMethodContext && !!document.documentMode) || window.navigator.userAgent.includes("MSIE "));
}

export function noop() {}

// 00:00 - 00:45 = 0.5 hours
// 00:46 – 01:15 = 1 hours
// 01:16 – 01:45 = 1,5 hours
// 01:46 – 02:15 = 2 hours
// 02:16 – 02:45 = 2,5 hours
// 02:46 – 03:15 = 3 hours
// TODO: write unit tests for this
export function roundSecondsToHours(timeInSeconds) {
  if (!Number.isInteger(timeInSeconds)) {
    throw new Error("Rounding only duration given in seconds (integer)");
  }

  const duration = moment.duration(timeInSeconds, "seconds");

  const durationTotalMinutes = Math.floor(duration.asMinutes());

  if (durationTotalMinutes <= 45) {
    return 0.5;
  }

  const durationParsedHours = Math.floor(duration.asHours());
  const durationParsedMinutes = duration.get("minutes");

  if (durationParsedMinutes < 16) {
    return durationParsedHours;
  }

  if (durationParsedMinutes >= 46) {
    return durationParsedHours + 1;
  }

  return durationParsedHours + 0.5;
}

export const SECONDS_IN_HOUR = 3600;
export function roundSecondsToMinutes(timeInSeconds) {
  const roundedNumb = 5;
  const secInMinute = 60;
  const minutesNumber = timeInSeconds / secInMinute;
  const addtionalMinutes = minutesNumber < 30 ? 0 : minutesNumber % roundedNumb;
  const additionalMinutesRounded =
    addtionalMinutes === 0 ? 0 : roundedNumb - addtionalMinutes;
  const roundedTime = timeInSeconds + additionalMinutesRounded * secInMinute;

  return roundedTime;
}

const TRANSITION_EVENTS_MAP = {
  transition: "transitionend",
  OTransition: "oTransitionEnd",
  MozTransition: "transitionend",
  WebkitTransition: "webkitTransitionEnd",
};

export function whichTransitionEvent() {
  const element = document.createElement("fakeelement");

  const transitionEvent: any = Object.keys(TRANSITION_EVENTS_MAP).find(
    (entry) => element.style[entry] !== undefined
  );

  return TRANSITION_EVENTS_MAP[transitionEvent];
}
