import Parser from '@u4i/utils/Parser';
import {SectionTypeEnum} from '@u4i/common/enums/SectionTypeEnum';

export interface ISection {
  description: string;
  id: string;
  positionIndex: number;
  title: string;
  type: SectionTypeEnum;
}

export interface ILandingPage {
  description: string;
  headerBackgroundUrl: string;
  isLanguageFilterVisible: boolean;
  logoUrl: string;
  mobileImageUrl: string;
  privateMode: boolean;
  sections: Array<ISection>;
  subtitle: string;
  title: string;
}

export const LandingPageParser = new Parser<ILandingPage>(landingPage => ({
  description: landingPage.description,
  headerBackgroundUrl: landingPage.headerBackgroundUrl,
  isLanguageFilterVisible: landingPage.sectionLanguageFilter,
  logoUrl: landingPage.logoUrl,
  mobileImageUrl: landingPage.mobileImageUrl,
  privateMode: landingPage.privateMode,
  sections: landingPage.sections.map((section, index) => ({
    description: section.description,
    id: section.id,
    positionIndex: index,
    title: section.title,
    type: section.type,
  })),
  subtitle: landingPage.subtitle,
  title: landingPage.title,
}));
