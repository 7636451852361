import { AntDTableFilters } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import { ICategory, ICategoryPost, IChapterSimplified, IQuestionEntity, IQuestionPost, IQuestionSimplified, IQuestionTopic, ITestEntity, ITestPost } from "@u4i/modules/Admin/modules/AssesmentHub/state/AssessmentHubInterfaces";
import { IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { AxiosInstance } from "axios";
import qs from 'qs';

export class AdminAssessmentHubApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  // Add test
  createTest = async (model: ITestPost): Promise<any> => {
    const response = await this.apiClient.post(`/admin/mcq/tests`, model);

    return response.data;
  }

  // Update test
  updateTest = async (id: string, model: ITestPost): Promise<ITestPost> => {
    const response = await this.apiClient.put<ITestPost>(`/admin/mcq/tests/${id}`, model);

    return response.data;
  }

  // Delete test
  deleteTest = async (id: string): Promise<boolean> => {
    const response = await this.apiClient.delete<boolean>(`/admin/mcq/tests/${id}`);

    return response.data;
  }

  // Get test by id 
  fetchTestById = async (id: string): Promise<ITestEntity> => {
    const response = await this.apiClient.get<ITestEntity>(`/admin/mcq/tests/${id}`);

    return response.data;
  }

  // Returns tests list
  fetchTestsData = async (config: AntDTableFilters<ITestEntity>): Promise<IPaginatedItems<ITestEntity>> => {
    const response = await this.apiClient.get("/admin/mcq/tests", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.orderBy,
      },
      paramsSerializer: (params) => qs.stringify(params)
    });

    const items = response.data;
    const totalItems: number = parseInt(response.headers["x-total-count"], 10);

    return { items, totalItems };
  }

  // Returns a list of all chapters
  fetchChaptersList = async (): Promise<IChapterSimplified[]> => {
    const response = await this.apiClient.get<IChapterSimplified[]>('/admin/chapters');

    return response.data;
  }

  // Returns a list of all questions that are related to given chapters
  fetchQuestionsByChapters = async(chapters: string[]): Promise<IQuestionSimplified[]> => {
    const params = new URLSearchParams();

    chapters.forEach((chapter: string) => {
      params.append(`chapters[]`, chapter)
    })

    const response = await this.apiClient.get<IQuestionSimplified[]>(`/admin/chapters/questions?`, { params: params });

    return response.data
  }

  // Returns questions list
  fetchQuestionsBankData = async (config: AntDTableFilters<IQuestionEntity>): Promise<IPaginatedItems<IQuestionEntity>> => {
    const response = await this.apiClient.get("/admin/mcq/questions", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.orderBy,
      },
      paramsSerializer: (params) => qs.stringify(params)
    });

    const items = response.data;
    const totalItems: number = parseInt(response.headers["x-total-count"], 10);

    return { items, totalItems };
  }

  // Get question by id
  fetchQuestionById = async (id: string): Promise<IQuestionEntity> => {
    const response = await this.apiClient.get<IQuestionEntity>(`/admin/mcq/questions/${id}`);

    return response.data;
  }

  // Add question
  createQuestion = async (model: IQuestionPost): Promise<any> => {
    const response = await this.apiClient.post(`/admin/mcq/questions`, model);

    return response.data;
  }

  // Update question
  updateQuestion = async (id: string, model: IQuestionPost): Promise<any> => {
    const response = await this.apiClient.put(`/admin/mcq/questions/${id}`, model);

    return response.data;
  }

  // Delete question
  deleteQuestion = async (id: string): Promise<any> => {
    const response = await this.apiClient.delete<any>(`/admin/mcq/questions/${id}`);

    return response.data;
  }

  // Returns a list of all existing topics
  getTopicsList = async (): Promise<IQuestionTopic[]> => {
    const response = await this.apiClient.get<IQuestionTopic[]>('/admin/topics');
    
    return response.data;
  }

  // Returns categories list
  fetchCategoriesData = async (config: AntDTableFilters<ICategory>): Promise<IPaginatedItems<ICategory>> => {
    const response = await this.apiClient.get("/admin/categories", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.orderBy
      },
      paramsSerializer: (params) => qs.stringify(params),
    });

    const items = response.data;
    const totalItems: number = parseInt(response.headers["x-total-count"], 10);

    return { items, totalItems };
  }

  // Add category
  createCategory = async (model: ICategoryPost): Promise<ICategory> => {
    const response = await this.apiClient.post<ICategory>(`/admin/categories`, model);

    return response.data;
  }

  // Update category
  updateCategory = async (id: string, model: ICategoryPost): Promise<ICategory> => {
    const response = await this.apiClient.put<ICategory>(`/admin/categories/${id}`, model);

    return response.data;
  }

  // Delete category
  deleteCategory = async (id: string): Promise<boolean> => {
    const response = await this.apiClient.delete<boolean>(`/admin/categories/${id}`);

    return response.data;
  }

  getCategoriesList = async (): Promise<ICategory[]> => {
    const response = await this.apiClient.get<ICategory[]>('/admin/categories/list');

    return response.data;
  }
}
