import InfiniteScroll from 'react-infinite-scroller';
import React, { FC } from 'react';
import {observer} from 'mobx-react';
import CircularProgress from '@u4i/common/CircularProgress';
import {DiscussAnswersList} from './DiscussAnswersList';
import {SortingButtons} from './SortingButtons';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import { DiscussStore } from './state/DiscussStore';

interface IProps {
  isAnonymityChecked: boolean
  showAnonymitySettingsSwitch: boolean
  discussStore: DiscussStore
  teaserMode?: boolean
}

const defaultProps: Partial<IProps> = {
  teaserMode: false,
}

const Discuss: FC<IProps> = (props: IProps) => {
  const {
    isAnonymityChecked,
    showAnonymitySettingsSwitch,
    discussStore,
    teaserMode
  } = props;

  const {
    answers,
    currentSortDirection,
    currentSortedField,
    fetching,
    page,
    totalPages,
    handleSortAnswers,
    loadPage
  } = discussStore;

  const answersToDisplay: DiscussAnswerEntity[] = teaserMode ? answers.slice(0, 2) : answers;

  if (teaserMode) {
    return (
      <DiscussAnswersList
        answersToDisplay={answersToDisplay}
        isAnonymityChecked={isAnonymityChecked}
        showAnonymitySettingsSwitch={false}
        teaserMode={teaserMode}
      />
    );
  }

  return (
    <>
      {!teaserMode &&
        <SortingButtons
          currentSortDirection={currentSortDirection}
          currentSortedField={currentSortedField}
          onSortAnswers={handleSortAnswers}
        />
      }

      {fetching ?
        <CircularProgress />
        :
        <InfiniteScroll
          hasMore={page < totalPages}
          loader={<CircularProgress key='circularProgress'/>}
          loadMore={loadPage}
          pageStart={0}
        >
          <DiscussAnswersList
            answersToDisplay={answersToDisplay}
            isAnonymityChecked={isAnonymityChecked}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            teaserMode={teaserMode!}
          />
        </InfiniteScroll>
      }
    </>
  );
};

Discuss.defaultProps = defaultProps;

const WrappedComponent = observer(Discuss);

export {WrappedComponent as Discuss}
