import { extractYoutubeVideoId } from '@u4i/common/utils';
import YoutubePlayer from '@u4i/common/YoutubePlayer';
import React, { FC } from 'react';

import './_trailer.scss';

interface IProps {
  description: string
  videoUrl: string
}

export const Trailer: FC<IProps> = (props: IProps) => {
  const {
    description, 
    videoUrl
  } = props;
  const videoId: string = extractYoutubeVideoId(videoUrl);

  return (
    <div className="container">
      <div className="course-detail-page__trailer">
        <div className="course-detail-page__trailer__video">
          <YoutubePlayer height={360} videoId={videoId} />
        </div>

        <p className="course-detail-page__trailer__description">
          {description}
        </p>
      </div>
    </div>
  )
}
