import cx from 'classnames';
import React, { FC } from 'react';
import SuccessIcon from '@material-ui/icons/Done';
import {FormattedMessage} from 'react-intl';
import AppNavigator from '@u4i/common/AppNavigator';
import {SubmissionProgress} from './SubmissionProgress';

import intlMessages from './intlMessages';
import { green } from '@u4i/styles/styles-variables';
import './_submission-overlay.scss';

interface IPropTypes {
  submissionProgress: number
  submissionProgressTotal: number
  submissionSuccess: boolean
  submitting: boolean
}

export const SubmissionOverlay: FC<IPropTypes> = (props: IPropTypes) => (
  <div
    className={cx({
      'exercises__submission-overlay': true,
      'exercises__submission-overlay--visible': props.submitting || props.submissionSuccess,
    })}
  >
    <div
      className={cx({
        'exercises__submission-overlay__progress': true,
        'exercises__submission-overlay__progress--visible': !props.submissionSuccess,
      })}
    >
      <p className="exercises__submission-overlay__progress-label">
        <FormattedMessage {...intlMessages.submitting} />
      </p>

      <SubmissionProgress
        currentPercentage={props.submissionProgress / props.submissionProgressTotal}
      />
    </div>

    <div
      className={cx({
        'exercises__submission-overlay__success': true,
        'exercises__submission-overlay__success--visible': props.submissionSuccess,
      })}
    >
      <SuccessIcon style={{color: green, height: 64, width: 64}} />

      <p className="exercises__submission-overlay__message">
        <FormattedMessage {...intlMessages.submitted} />
      </p>

      <button
        className="exercises__submission-overlay__button"
        onClick={AppNavigator.goBack}
        type="button"
      >
        <FormattedMessage {...intlMessages.goBack} />
      </button>
    </div>
  </div>
);
