import cx from "classnames";
import React, { FC } from "react";
import { Badge, Skeleton, Typography } from "antd";
import { BaseSection } from "../../BaseSection";
import { mainBlue } from "@u4i/styles/styles-variables";
import Scroll from 'react-scroll';
import DOMPurify from 'dompurify';
import "./_basic-info-wrapper.scss";
import {kebabCase} from 'lodash';

const { Element } = Scroll;
const { Title, Paragraph } = Typography;

interface IPropTypes {
  centered?: boolean;
  children: React.ReactElement;
  isLoading: boolean;
  itemsCountLabel?: string | number;
  sectionModel: BaseSection;
}

export const BasicInfoWrapper: FC<IPropTypes> = (props: IPropTypes) => {
  const sanitizedContent: string = DOMPurify.sanitize(props.sectionModel.description);
  const slug: string = kebabCase(props.sectionModel.title);

  return (
      <Element name={props.sectionModel.id} key={props.sectionModel.id}>
          <div id={slug}  style={{height:"55px"}}></div>
          <section className="landing-page__basic-info-wrapper">
              <div className="container">
                  <header
                      className={cx({
                          "landing-page__basic-info-wrapper__header": true,
                          "landing-page__basic-info-wrapper__header--centered": props.centered,
                      })}
                  >
                      <div>
                          <Badge
                              color={mainBlue}
                              count={props.itemsCountLabel && props.itemsCountLabel !== "0" && props.itemsCountLabel !== 0 ? props.itemsCountLabel : null}
                              style={{fontSize: 16}}
                          >
                              <Title
                                  style={{display: "inline-block"}}
                                  className="mt-8"
                              >
                                  {props.sectionModel.title}
                              </Title>
                          </Badge>
                      </div>

                      {props.sectionModel.description && (
                          <div dangerouslySetInnerHTML={{__html: sanitizedContent}}/>
                      )}
                  </header>
              </div>

              <main>{props.isLoading ? <div className="container"><Skeleton active/></div> : props.children}</main>
          </section>
      </Element>
)
};
