import {action, computed, observable, runInAction,makeObservable} from 'mobx';

import {IApi} from '@u4i/state/ServicesInterfaces';
import {IChapter} from '@u4i/parsers/ChapterParserNew';
import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {BaseSection} from '../BaseSection';
import {LandingPageStore} from '../../LandingPageStore';
import {VisualComponent} from './VisualComponent';

export class ChapterSection extends BaseSection {
  private apiService: IApi;
  private landingPageStore: LandingPageStore;
  fetching = true;
  items: Array<IChapter> = [];

  constructor(rootStore: IRootStore, sectionData: ISection) {
    super(sectionData, VisualComponent);

    makeObservable(this, {
      fetching: observable,
      items: observable,
      isVisible: computed,
      reloadContent: action.bound
    });

    const {apiService, landingPageStore} = rootStore;
    this.apiService = apiService;
    this.landingPageStore = landingPageStore;

    this.reloadContent();
  }

  get isVisible(): boolean {
    return !this.landingPageStore.isPrivateModeEnabled;
  }

  async reloadContent(): Promise<void> {
    if (this.landingPageStore.isPrivateModeEnabled) {
      return;
    }

    this.fetching = true;

    const items = await this.apiService.landingPages.fetchSectionItems<IChapter>(
      this.id,
      this.type,
      this.landingPageStore.previewModeEnabled,
      this.landingPageStore.itemsLanguage,
    );

    runInAction(() => {
      this.fetching = false;
      this.items = items;
    });
  }
}
