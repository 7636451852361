import React, { FC } from 'react';
import SuccessIcon from '@material-ui/icons/Redeem';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_success-info.scss';

interface IPropTypes {
  message: string
  onGoToHomeClick: () => void
  onRedeemAnotherClick: () => void
}

export const SuccessInfo: FC<IPropTypes> = (props) => {
  return (
    <div className="redeem-page__success-info">
      <SuccessIcon style={{height: 72, width: 74}} />

      <h4 className="redeem-page__success-info__title">
        <FormattedMessage {...intlMessages.title} />
      </h4>

      <p className="redeem-page__success-info__content">
        {props.message}
      </p>

      <button className="redeem-page__success-info__button" onClick={props.onGoToHomeClick}>
        <FormattedMessage {...intlMessages.goToHome} />
      </button>

      <button className="redeem-page__success-info__button" onClick={props.onRedeemAnotherClick}>
        <FormattedMessage {...intlMessages.redeemAnotherCoupon} />
      </button>
    </div>
  );
}
