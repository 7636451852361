import qs from 'qs';
import {v4 as uuid} from "uuid";
import {action, computed, observable, makeObservable} from 'mobx';
import AppNavigator from '@u4i/common/AppNavigator';
import {IApi, ILogger, IStorage} from '../ServicesInterfaces';
import {IRootStore} from '../RootStore';
import { IScormProgress } from '@u4i/parsers/ScormProgressParser';

export class ScormProxyService {
  private _isEnabled = false;
  private apiService: IApi;
  private enablingListener: Function;
  private logger: ILogger;
  private messageWatchers: { [watcherId: string]: Function } = {};
  private targetSkillId: string;
  private serviceEnabled = false;
  private storageService: IStorage;
  public requestId: any = uuid();
  public didSendMessage: boolean = false;
  private origin: string;
  private startDate: Date;

  constructor(rootStore: IRootStore) {
    makeObservable<ScormProxyService, "_isEnabled" | "didSendMessage" | "requestId" | "setup">(this, {
      _isEnabled: observable,
      isEnabled: computed,
      didSendMessage: observable,
      requestId: observable,
      setup: action.bound
    });

    this.startDate = new Date();

    const {apiService, loggerFactory, storageService} = rootStore;

    this.apiService = apiService;
    this.logger = loggerFactory.createLogger('ScormProxyService', true);
    this.storageService = storageService;

    this.enablingListener = AppNavigator.listen(this.checkForEnablingScorm);
    this.checkForEnablingScorm();

    let syncProgress = this.syncProgress
    document.body.addEventListener('mouseleave', syncProgress, false);
  }

  get isEnabled() {
    return this._isEnabled;
  }

  private checkForEnablingScorm = async () => {
    let {scormAuthProvider, scormSkillId} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    const scormSessionData = this.storageService.scormSessionData.get();
    if (scormSessionData) {
      if (scormSessionData.authProvider) { scormAuthProvider = scormSessionData.authProvider; }
      if (scormSessionData.skillId) { scormSkillId = scormSessionData.skillId; }
    }

    if (scormSkillId !== undefined || (scormSessionData && scormSessionData.scormEnabled)) {
      this.storageService.scormSessionData.set({
        authProvider: scormAuthProvider,
        scormEnabled: true,
        skillId: scormSkillId,
      });

      this.enablingListener();

      await this.setup(scormSkillId);

      if (scormAuthProvider !== undefined && AppNavigator.currentLocation.pathname === '/scorm-authentication') {

        let studentId: string = await this.readScormValue('cmi.ext.learner_uuid') as string;

        if(!studentId || studentId == ''){
          studentId = await this.readScormValue('cmi.core.student_id') as string;
        }

        const jwtSecret: string = await this.readScormValue('JWT_SECRET') as string;

        const redirectUrl: string = await this.apiService.user.scormStudentLogin(scormAuthProvider, scormSkillId, studentId, jwtSecret);

        AppNavigator.directReplace(redirectUrl);
      }
    }
  };

  private handleMessageEvent = (event: MessageEvent) => {
    this.origin = event.origin;
    if (event.data && event.data.action !== 'Heartbeat') {
      this.logger.info(`new message from proxy (${JSON.stringify(event.data)})`);
    }

    if(event && event.origin && event.data && this.didSendMessage == false) {
      let learnerModel = {
        action: "LMSGetValue",
        element: "cmi.ext.learner_uuid",
        identificationMeta: {
          requestId: this.requestId
        }
      }

      let studentModel = {
        action: "LMSGetValue",
        element: "cmi.core.student_id",
        identificationMeta: {
          requestId: this.requestId
        }
      }

      let jwtModel = {
        action: "LMSGetValue",
        element: "JWT_SECRET",
        identificationMeta: {
          requestId: this.requestId
        }
      }

      this.sendMessageToProxy(learnerModel, event.origin);
      this.sendMessageToProxy(studentModel, event.origin);
      this.sendMessageToProxy(jwtModel, event.origin);

      this.didSendMessage = true;
    }

    const {identificationMeta} = event.data;

    if (identificationMeta && identificationMeta.requestId) {
      const watcher = this.messageWatchers[identificationMeta.requestId];

      if (watcher) {
        watcher(event.data);
      }
    }

    switch (event.data.action) {
      case 'FinishSession':
        window.close();
        break;
    }
  };

  private readScormValue = async (dataModelElementPath: string) => {
    const readPromise = new Promise(async (resolve) => {
      const eventData = await this.registerMessageWatcher(this.requestId);
      resolve(eventData.value);
    })

    this.sendMessageToProxy({
      action: 'LMSGetValue',
      identificationMeta: {requestId: this.requestId},
      element: dataModelElementPath,
    });

    return readPromise;
  };

  private async registerMessageWatcher(requestId: string): Promise<any> {
    const watcherPromise = new Promise<any>((resolve) => {
      this.messageWatchers[requestId] = resolve;
    })

    return watcherPromise;
  }

  private sendMessageToProxy(data: Object, origin?: any) {
    this.logger.info(`sending message to proxy (${JSON.stringify(data)})`);
    origin = origin || this.origin;

    if(origin) {
      window.opener.postMessage(data, origin);
    }
  }

  private setScormValue(element, value) {
    this.logger.info(`setting element "${element}" value to "${value}"`);

    this.sendMessageToProxy({action: 'LMSSetValue', element, value});
  }

  private async setup(scormSkillId: string | any) {
    this.targetSkillId = scormSkillId;

    window.addEventListener('message', this.handleMessageEvent);
    this.logger.info('listening for proxy messages');

    this.serviceEnabled = true;

    this.syncProgress();
    AppNavigator.listen(this.syncProgress);

    this.logger.info(`enabled (${scormSkillId})`);
  }

  private millisecondsToHuman(ms) {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms  / 1000 / 3600 ) % 24)

    // Format so it shows a leading zero if needed
    let hoursStr = ("00" + hours).slice(-2);
    let minutesStr = ("00" + minutes).slice(-2);
    let secondsStr = ("00" + seconds).slice(-2);

    return hoursStr + ":" + minutesStr + ":" + secondsStr
  }

  syncProgress = async () => {
    if (!this.serviceEnabled || AppNavigator.currentLocation.pathname === '/scorm-authentication') {
      return;
    }

    this.logger.info('progress synchronization');

    const challengesProgress: IScormProgress = await this.apiService.skills.fetchScormProgress(this.targetSkillId);

    const endDate = new Date();
    const spentTime = endDate.getTime() - this.startDate.getTime();

    this.setScormValue('cmi.core.score.min', 0);
    this.setScormValue('cmi.core.score.max', challengesProgress.maxScore);
    this.setScormValue('cmi.core.score.raw', challengesProgress.rawScore);
    this.setScormValue('cmi.core.session_time', this.millisecondsToHuman(spentTime));

    if (challengesProgress.maxScore === 0) {
      this.setScormValue('cmi.core.lesson_status', 'completed');
    } else if (challengesProgress.rawScore === challengesProgress.maxScore) {
      this.setScormValue('cmi.core.lesson_status', 'passed');
    } else {
      this.setScormValue('cmi.core.lesson_status', 'incomplete');
    }

    this.sendMessageToProxy({action: 'LMSCommit', value: ''});
  };
}
