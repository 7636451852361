/**
* @dev_date May 2022
* @desc This component handles store for Redeem Page
* @author Denis Shtupa denis.shtupa@u4i.io
*/

import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';
import { IRootStore } from '@u4i/state/RootStore';
import { IApi } from '@u4i/state/ServicesInterfaces';
import { action, makeObservable, observable } from 'mobx';

export class RedeemPageStore {
  private apiService: IApi;
  public additionalTerms: any[];
  public couponCode: string = '';
  public couponSubmittedSuccessfully: boolean = false;
  public couponCodeError: any;
  public showInvalidCourseWarning: boolean = false;
  public successCouponCode: boolean = false;
  public successMessage: string;
  public validatingCoupon: boolean = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      additionalTerms: observable,
      couponCode: observable,
      couponSubmittedSuccessfully: observable,
      couponCodeError: observable,
      showInvalidCourseWarning: observable,
      successCouponCode: observable,
      successMessage: observable,
      validatingCoupon: observable,
      checkCouponCode: action.bound,
      joinUserGroupByCode: action.bound,
      resetState: action.bound,
      resetCouponCode:action.bound
    });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  updateCouponCodeError = (val: any) => {
    this.couponCodeError = val;
  }

  checkCouponCode = async (collaborationCode: string) => {
    this.couponCode = collaborationCode;
    this.validatingCoupon = true;
    try {
      const response = await this.apiService.redeemPage.checkCouponCode(collaborationCode);

      if(response) {
        this.successCouponCode = true;
        if(response.required_terms.length > 0) {
          this.additionalTerms = response.required_terms;
        }
      }
    }
    catch (error) {
      this.successCouponCode = false;
      this.additionalTerms = [];

      if (error.response.data.errorMessage === 'INVALID_SKILL_FOR_CODE') {
        this.showInvalidCourseWarning = true;
      }
    }
    finally {
      this.validatingCoupon = false;
    }
  }

  joinUserGroupByCode = async (collaborationCode: any) => {
    try {
      const response = await this.apiService.redeemPage.joinUserGroupByCode(collaborationCode.couponCode);
      this.successMessage = response?.successMessage;
      this.couponSubmittedSuccessfully = true;
    }
    catch(error) {
      Notification(NotificationEnum.Error, 'Invalid', 'Invalid coupon code!');
    }
    finally {

    }
  }

  resetCouponCode=()=>{
    this.couponCode = '';
  }

  resetState = () => {
    this.additionalTerms = [];
    this.couponCode = '';
    this.couponSubmittedSuccessfully = false;
    this.showInvalidCourseWarning = false;
    this.successCouponCode = false;
    this.successMessage = '';
  }
}
