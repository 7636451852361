import cx from 'classnames';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {ClassicSubmitButton} from '../../../../formsSystem/ClassicSubmitButton';

import intlMessages from './intlMessages';
import './_question-navigation.scss';

interface IPropTypes {
  currentQuestion: number;
  formInvalid: boolean;
  onBackClick: () => void;
  onNextClick: () => void;
  submitting: boolean;
  submitSucceeded: boolean;
  totalQuestions: number;
  isNotSubmitabble: boolean;
}

export const QuestionNavigation: React.FunctionComponent<IPropTypes> = (props) => (
  <div className="exercise-form__question-navigation">
    <div
      className={cx({
        'exercise-form__question-navigation__button-wrapper': true,
        'exercise-form__question-navigation__button-wrapper--visible': props.currentQuestion > 1,
      })}
    >
      <button
        className="exercise-form__question-navigation__button"
        disabled={props.submitting || props.submitSucceeded}
        onClick={props.onBackClick}
        type="button"
      >
        <FormattedMessage {...intlMessages.back} />
      </button>
    </div>

    <div className="exercise-form__question-navigation__forward-controls">
      <div
        className={cx({
          'exercise-form__question-navigation__button-wrapper': true,
          'exercise-form__question-navigation__button-wrapper--absolute': true,
          'exercise-form__question-navigation__button-wrapper--visible': props.currentQuestion < props.totalQuestions,
        })}
      >
        <button
          className="exercise-form__question-navigation__button"
          disabled={props.submitting || props.submitSucceeded}
          onClick={props.onNextClick}
          type="button"
        >
          <FormattedMessage {...intlMessages.next} />
        </button>
      </div>

      <div
        className={cx({
          'exercise-form__question-navigation__button-wrapper': true,
          'exercise-form__question-navigation__button-wrapper--absolute': true,
          'exercise-form__question-navigation__button-wrapper--visible': props.currentQuestion === props.totalQuestions,
        })}
      >
        <ClassicSubmitButton
          disabled={props.submitting || props.submitSucceeded || props.formInvalid || props.isNotSubmitabble}
          submitting={props.submitting}
        >
          <FormattedMessage {...intlMessages.submit} />
        </ClassicSubmitButton>
      </div>
    </div>
  </div>
);
