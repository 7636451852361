import {action, observable, runInAction,makeObservable} from 'mobx';
import {AdminUserEntity} from '@u4i/state/entities/admin/AdminUserEntity';
import {IAdminUser, IChangeRequest, NewUserData} from '@u4i/parsers/admin/AdminUsersParser';
import {IApi, IPaginatedItems, IStorage} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';
import { AntDTableFilters, DEFAULT_PAGE_SIZE } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';

export class AdminUsersStore {
  private apiService: IApi;
  private rootStore: IRootStore;
  private storageService: IStorage;
  activatingUserError?: string;
  config: AntDTableFilters<IAdminUser>;
  currentOffset = 0;
  deleting = false;
  deactivatingUserError?: string;
  deletingUserError?: string;
  fetching = true;
  fetchingNewUser = false;
  fetchingNewUserError = false;
  isActivateModalVisible = false;
  message = '';
  newUserPasswordLink = '';
  totalUsers = 0;
  usersData: Array<AdminUserEntity> = [];
  validationErrors?: {
    company?: string;
    email?: string;
    firstName?: string;
    groups?: string;
    lastName?: string;
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      activatingUserError: observable,
      config: observable,
      currentOffset: observable,
      deleting: observable,
      deactivatingUserError: observable,
      deletingUserError: observable,
      fetching: observable,
      fetchingNewUser: observable,
      fetchingNewUserError: observable,
      isActivateModalVisible: observable,
      message: observable,
      newUserPasswordLink: observable,
      totalUsers: observable,
      usersData: observable,
      validationErrors: observable,
      closeActivateModal: action.bound,
      createUser: action.bound,
      deleteUser: action.bound,
      reloadUsersData: action.bound,
      resetData: action.bound
    });

    const {apiService, storageService} = rootStore;

    this.apiService = apiService;
    this.rootStore = rootStore;
    this.storageService = storageService;
  }

  closeActivateModal() {
    this.isActivateModalVisible = false;
  }

  async createUser(userData: NewUserData) {
    this.fetchingNewUser = true;
    this.validationErrors = undefined;

    try {
      const userInfo = await this.apiService.admin.users.submitNewUser(userData);

      runInAction(() => {
        const {messages, user} = userInfo;
        const newAdminUserEntity: AdminUserEntity = new AdminUserEntity(user, this.rootStore);

        this.message = messages[0];
        this.usersData = [newAdminUserEntity, ...this.usersData];

        this.storageService.newUserPasswordLink.set(messages[0]);

        Notification(NotificationEnum.Success, "User create", `User \"${userData.email}\" created.`);
      });
    } catch(error) {
      this.fetchingNewUserError = true;

      const createUserError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "User create", createUserError);

      if (error.response.data && error.response.data.validationErrors) {
        this.validationErrors = error.response.data.validationErrors;
      }
    } finally {
      this.fetchingNewUser = false;
    }
  }

  async deleteUser(
    userId: string,
    email: string,
    isOnOverviewPage: boolean,
    changeRequests?: Array<IChangeRequest>
  ) {
    this.deleting = true;

    const isOneItemOnPage: boolean = this.usersData.length === 1;
    let activeChangeRequests: IChangeRequest[] = changeRequests?.slice() || [];

    try {
      await this.apiService.admin.users.deleteUser(userId, activeChangeRequests);

      if (isOnOverviewPage && isOneItemOnPage) {
        this.config = {
          ...this.config,
          current: this.config.current === 1? 1: this.config.current - 1,
          offset: this.config.offset - DEFAULT_PAGE_SIZE,
        }
      }

      const requestConfig = {
        ...this.config,
        filters: {
          ...this.config.filters,
          groups: this.config.filters.groups? this.config.filters.groups.slice() : undefined,
        }
      }

      this.reloadUsersData(requestConfig);

      Notification(NotificationEnum.Success, "User delete", `User \"${email}\" deleted.`);
    } catch(error) {
      if (error.response) {
        this.deletingUserError = error.response.data.errorMessage || error.response.data.message;

        Notification(NotificationEnum.Error, "User delete", this.deletingUserError);
      }
    } finally {
      this.deleting = false;
    }
  }

  reloadUsersData = async(config: AntDTableFilters<IAdminUser> | any) => {
    this.fetching = true;

    try {
      const currentOffset: number = config.current ? config.current - 1 : 0;

      this.config = {
        ...config,
        offset: currentOffset * DEFAULT_PAGE_SIZE,
        limit: config.limit? config.limit : DEFAULT_PAGE_SIZE,
      }

      this.newUserPasswordLink = '';

      const requestConfig = {
        ...this.config,
        filters: {
          ...this.config.filters,
          groups: this.config.filters.groups? this.config.filters.groups.slice() : undefined,
        }
      }

      const usersCount: number = await this.apiService.admin.users.fetchUsersCount();
      const usersInfo: IPaginatedItems<IAdminUser> = await this.apiService.admin.users.fetchUsersData(requestConfig);

      runInAction(() => {
        const {items, totalItems} = usersInfo;
        const newUserPasswordLink: string | null = this.storageService.newUserPasswordLink.get();

        if (newUserPasswordLink) {
          this.newUserPasswordLink = newUserPasswordLink;

          this.storageService.newUserPasswordLink.empty();
        }

        const adminUserEntities: AdminUserEntity[] = items.map((user: IAdminUser) => new AdminUserEntity(user, this.rootStore));

        this.currentOffset = currentOffset;
        this.fetching = false;
        this.totalUsers = config.limit ? totalItems : usersCount;
        this.usersData = adminUserEntities;
      });
    } catch(error) {
      runInAction(() => {
        this.fetching = false;
        throw(error);
      });
    }
  }

  resetData() {
    this.activatingUserError = undefined;
    this.currentOffset = 0;
    this.deactivatingUserError = undefined;
    this.deletingUserError = undefined;
    this.fetching = true;
    this.fetchingNewUser = false;
    this.fetchingNewUserError = false;
    this.isActivateModalVisible = false;
    this.message = '';
    this.newUserPasswordLink = '';
    this.totalUsers = 0;
    this.usersData = [];

    this.storageService.newUserPasswordLink.empty();
  }
}
