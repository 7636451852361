import FontAwesome from 'react-fontawesome';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import CircularProgress from '@u4i/common/CircularProgress';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import ExpandableText from '@u4i/common/ExpandableText';
import {DISCUSS_ANSWER_TRIMMING_THRESHOLD} from '@u4i/constantSettings';
import { AnswerStatistics } from './components/AnswerStatistics/AnswerStatistics';
import {UserAnswerStore} from './state/UserAnswerStore';
import intlMessages from './intlMessages';
import { AnswerStatus } from './components/AnswerStatus/AnswerStatus';
import { AnswerInfo } from './components/AnswerInfo/AnswerInfo';
import { AnswerEditableContent } from './components/AnswerEditableContent/AnswerEditableContent';
import './_user-answer.scss';

interface IPropTypes {
  entity: DiscussAnswerEntity
  formId: string
  index: number
  language: string
  showAnonymitySettingsSwitch: boolean
}

const UserAnswer = observer(class UserAnswer extends React.Component<IPropTypes> {
  uiStore: UserAnswerStore;

  constructor(props: IPropTypes) {
    super(props);

    this.uiStore = new UserAnswerStore(props.entity);
  }

  handleCommentsClick = async (event) => {
    await this.uiStore.loadComments();
  };

  handleLikesClick = async (event) => {
    await this.uiStore.loadLikes();
  };

  render() {
    const { entity, formId, index, showAnonymitySettingsSwitch } = this.props;
    const { editionModeEnabled, fetchingComments, fetchingLikes, updatingContent, updatingContentError, commitEdition, toggleEditionMode } = this.uiStore;
    return (
      <div className="discuss-page__user-answer">
        {(fetchingLikes || fetchingComments) &&
          <div className="discuss-page__user-answer__fetching-data-wrapper">
            <div className="discuss-page__user-answer__fetching-data">
              <CircularProgress />
            </div>
          </div>
        }
    
        <div className="discuss-page__user-answer__upper-section">
          {entity.status &&
            <AnswerStatus status={entity.status} />
          }

          <AnswerInfo createdAt={entity.createdAt} index={index} />
    
          <AnswerStatistics
            entity={entity}
            onCommentsClick={this.handleCommentsClick}
            onLikesClick={this.handleLikesClick}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
          />
    
          <button
            className="discuss-page__user-answer__edit-button"
            data-for="edit-tooltip"
            data-tip
            onClick={toggleEditionMode}
            type="button"
          >
            <FontAwesome name="pencil-alt" />
          </button>
    
          <ReactTooltip effect="solid" id="edit-tooltip" place="top" type="dark">
            <FormattedMessage {...intlMessages.editTooltipMessage} />
          </ReactTooltip>
        </div>
    
        {editionModeEnabled ?
          <AnswerEditableContent
            anonymity={entity.anonymously}
            content={entity.content}
            editModeEnabled={editionModeEnabled}
            formId={formId}
            onEditionCancel={toggleEditionMode}
            onEditionCommit={commitEdition}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            updatingContent={updatingContent}
            updatingContentError={updatingContentError}
          />
          :
          <ExpandableText
            animationDurationSeconds={1}
            className="discuss-page__user-answer__content-text"
            content={entity.content}
            trimmingThreshold={DISCUSS_ANSWER_TRIMMING_THRESHOLD}
          />
        }
      </div>
    );
  }
});

const WrappedComponent = UserAnswer;

export {WrappedComponent as UserAnswer};
