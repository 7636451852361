import {defineMessages} from 'react-intl';

export default defineMessages({
  summaryLabel: {
    defaultMessage: 'Summary',
    id: 'Player.Header.SummaryLabel',
  },
  notificationContent: {
    defaultMessage: 'Welcome back! This is the page that you viewed most recently.',
    id: 'Player.ScrollRestorationNotification.NotificationContent',
  },
  quickNotificationContentHeader: {
    defaultMessage: 'How likely are you to recommend this learning content to a colleague?',
    id: 'Player.ScrollRestorationNotification.NotificationContentHeader',
  },
  likely: {
    defaultMessage: 'Very likely',
    id: 'Player.ScrollRestorationNotification.likely',
  },
  unlikely: {
    defaultMessage: 'Very unlikely',
    id: 'Player.ScrollRestorationNotification.unlikely',
  },
  thankYouFeedBack: {
    defaultMessage: 'Great, thank you for the feedback!',
    id: 'Player.ScrollRestorationNotification.thankYouFeedBack',
  },
  clickLink:{
    defaultMessage:'If you would like to tell us more, please click on the',
    id: 'Player.ScrollRestorationNotification.clickLink',
  }
});
