import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import DiscussCommentEntity from '@u4i/state/entities/DiscussCommentEntity';
import intlMessages from './intlMessages';
import { Modal } from 'antd';
import { DiscussComment } from '@u4i/modules/DiscussPage/components/Discuss/DiscussAnswersList/DiscussAnswer/components/DiscussComment/DiscussComment';

interface IProps {
  children?: any
  comments: Array<DiscussCommentEntity>
  formId: string
  isVisible: boolean
  showAnonymitySettingsSwitch: boolean
  closeCurrentModal: () => void
};

const AnswerCommentsModal: FC<IProps> = (props: IProps) => {
  const {
    comments,
    formId,
    isVisible,
    showAnonymitySettingsSwitch,
    closeCurrentModal
  } = props;

  return (
    <Modal
      centered
      mask={true}
      maskClosable={true}
      footer={null}
      onCancel={closeCurrentModal}
      open={isVisible}
      title={<h3 style={{textAlign:'center'}}><FormattedMessage {...intlMessages.modalTitle} /></h3>}
      style={{minWidth: '50%'}}
    >
      <ul className="list-unstyled">
        {comments?.map((commentEntity: DiscussCommentEntity) => (
          <li key={commentEntity.id}>
            <DiscussComment
              entity={commentEntity}
              formId={formId}
              isAvatarMinimized
              showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            />
          </li>
        ))}
      </ul>
    </Modal>
  )
};

export default AnswerCommentsModal;