import {defineMessages} from 'react-intl';

export default defineMessages({
  itemsPerPage: {
    defaultMessage: 'Items per page:',
    id: 'DataTable.Toolbar.ItemsPerPage',
  },
  toggleFilters: {
    defaultMessage: 'Toggle filters',
    id: 'DataTable.Toolbar.ToggleFilters',
  },
  totalItems: {
    defaultMessage: 'Total items: {count}',
    id: 'DataTable.Toolbar.TotalItems',
  },
});
