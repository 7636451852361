import {action, computed, observable, makeObservable} from 'mobx';
import UserStore from '@u4i/state/UserStore';
import {IAdminUser, IChangeRequest} from '@u4i/parsers/admin/AdminUsersParser';
import {IApi} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {UserRolesEnum} from '@u4i/common/enums/UserRolesEnum';
import {UserStatusEnum} from '@u4i/common/enums/UserStatusEnum';
import IEntity from '../IEntity';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';

export class AdminUserEntity implements IEntity<IAdminUser> {
  private apiService: IApi;
  private rootStore: IRootStore;
  private userStore: UserStore;
  changeRequests?: Array<IChangeRequest>;
  company: string;
  email: string;
  fetchingEditedUser = false;
  fetchingEditedUserError = false;
  firstName: string;
  groups: Array<{
    id: string;
    isGrader: boolean;
    isMain: boolean;
    name: string;
  }> = [];
  id: string;
  language: LanguageEnum;
  lastLogin: string;
  lastName: string;
  role: {
    id: UserRolesEnum;
    name: string;
  };
  status: {
    id: UserStatusEnum;
    name: string;
  };
  username: string;
  validationErrors?: {
    company?: string;
    email?: string;
    firstName?: string;
    groups?: string;
    lastName?: string;
  };

  constructor(adminUserData: IAdminUser, rootStore: IRootStore) {
    makeObservable(this, {
      changeRequests: observable,
      company: observable,
      email: observable,
      fetchingEditedUser: observable,
      fetchingEditedUserError: observable,
      firstName: observable,
      groups: observable,
      id: observable,
      language: observable,
      lastLogin: observable,
      lastName: observable,
      role: observable,
      status: observable,
      username: observable,
      validationErrors: observable,
      serializedData: computed,
      applyDataChanges: action.bound,
      updateUser: action.bound
    });

    const {apiService} = rootStore;

    this.apiService = apiService;
    this.rootStore = rootStore;
    this.userStore = new UserStore(this.rootStore);

    this.applyDataChanges(adminUserData);
  }

  get serializedData(): IAdminUser {
    const {changeRequests, company, email, firstName, groups, id, language, lastLogin, lastName, role, status, username} = this;
    return {changeRequests, company, email, firstName, groups, id, language, lastLogin, lastName, role, status, username};
  }

  applyDataChanges(dataChanges: Partial<IAdminUser>) {
    Object.assign(this, dataChanges);
  }

  async updateUser(partialDataChanges: Partial<IAdminUser>) {
    this.validationErrors = undefined;
    this.fetchingEditedUserError = false;

    if (!this.userStore.userData.role) {
      await this.userStore.fetchCurrentUserData();
    }

    const currentUserRole: UserRolesEnum = this.userStore.userData.role;
    const initialRoleOfEditedUser: UserRolesEnum = this.role.id;
    const isCurrentUserSuperAdmin: boolean = currentUserRole === UserRolesEnum.SUPERADMIN;
    const isCurrentUserU4IAdmin: boolean = currentUserRole === UserRolesEnum.U4I_ADMIN;
    const isCurrentUserU4IFaculty: boolean = currentUserRole === UserRolesEnum.U4I_FACULTY;
    const canUpdateUserRole: boolean = isCurrentUserSuperAdmin ||
    (isCurrentUserU4IAdmin && initialRoleOfEditedUser !== UserRolesEnum.SUPERADMIN) ||
    (isCurrentUserU4IFaculty && initialRoleOfEditedUser !== UserRolesEnum.SUPERADMIN && initialRoleOfEditedUser !== UserRolesEnum.U4I_ADMIN);
    const snapshot: IAdminUser = this.serializedData;

    this.applyDataChanges(partialDataChanges);

    const updatedUserData = {
      ...partialDataChanges,
      role: canUpdateUserRole ? partialDataChanges.role?.id : undefined,
      status: partialDataChanges.status?.id,
    };

    delete updatedUserData.lastLogin;

    this.fetchingEditedUser = true;

    try {
      const updatedAdminUser: IAdminUser = await this.apiService.admin.users.updateUser(updatedUserData);

      this.applyDataChanges(updatedAdminUser);

      Notification(NotificationEnum.Success, "User update", `User ${updatedUserData.email} updated.`);
    } catch(error) {
      if (error.response.data && error.response.data.validationErrors) {
        this.validationErrors = error.response.data.validationErrors;
      }

      this.applyDataChanges(snapshot);

      this.fetchingEditedUserError = true;
      const editUserError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "User update", editUserError);
    } finally {
      this.fetchingEditedUser = false;
    }
  }
}
