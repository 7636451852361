import qs from "qs";
import { AxiosInstance } from "axios";

import {
  ChallengeAttemptParser,
  IChallengeAttempt,
} from "@u4i/parsers/ChallengeAttemptParser";
import {
  GroupProgressParser,
  IGroupProgress,
} from "@u4i/parsers/GroupProgressParser";
import {
  IUserAttemptProgress,
  UserAttemptProgressParser,
} from "@u4i/parsers/UserAttemptProgressParser";
import {
  IUserProgress,
  UserProgressParser,
} from "@u4i/parsers/UserProgressParser";
import { IPaginatedItems } from "../../ServicesInterfaces";
import { IGraderEvaluation } from "@u4i/modules/CustomerGrading/InterfacesCustomerGrading/ICustomerGrading";

export class GradingApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchAttempt(attemptId: string): Promise<IChallengeAttempt> {
    const response = await this.apiClient.get(
      `/v1/grading/attempts/${attemptId}`
    );
    return ChallengeAttemptParser.parse(response.data);
  }

  async fetchAttemptsList(config: {
    filters: { [columnName in keyof IChallengeAttempt]?: any };
    limit: number;
    offset: number;
    sortDirection: "ASC" | "DESC";
    sortedColumnId: string | undefined;
  }): Promise<IPaginatedItems<IChallengeAttempt>> {
    const response = await this.apiClient.get("/v1/grading/attempts", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.sortedColumnId
          ? {
              [config.sortedColumnId]: config.sortDirection,
            }
          : undefined,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });

    const attempts = ChallengeAttemptParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items: attempts, totalItems };
  }

  async fetchGroupsProgressList(config: {
    filters: { [columnName in keyof IGroupProgress]?: any };
    limit: number;
    offset: number;
    sortDirection: "ASC" | "DESC";
    sortedColumnId: string | undefined;
  }): Promise<IPaginatedItems<IGroupProgress>> {
    const response = await this.apiClient.get("/v1/grading/progress/groups", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.sortedColumnId
          ? {
              [config.sortedColumnId]: config.sortDirection,
            }
          : undefined,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });

    const attempts = GroupProgressParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items: attempts, totalItems };
  }

  async fetchUserAttemptsList(
    userId: string,
    config: {
      filters: { [columnName in keyof IUserAttemptProgress]?: any };
      limit: number;
      offset: number;
      sortDirection: "ASC" | "DESC";
      sortedColumnId: string | undefined;
    }
  ): Promise<IPaginatedItems<IUserAttemptProgress>> {
    const response = await this.apiClient.get(
      `/v1/grading/progress/users/${userId}/attempts`,
      {
        params: {
          filters: config.filters,
          limit: config.limit,
          offset: config.offset,
          orderBy: config.sortedColumnId
            ? {
                [config.sortedColumnId]: config.sortDirection,
              }
            : undefined,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }
    );

    const attempts = UserAttemptProgressParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items: attempts, totalItems };
  }

  async fetchUsersProgressList(
    groupId: string,
    config: {
      filters: { [columnName in keyof IUserProgress]?: any };
      limit: number;
      offset: number;
      sortDirection: "ASC" | "DESC";
      sortedColumnId: string | undefined;
    }
  ): Promise<IPaginatedItems<IUserProgress>> {
    const response = await this.apiClient.get(
      `/v1/grading/progress/groups/${groupId}/users`,
      {
        params: {
          filters: config.filters,
          limit: config.limit,
          offset: config.offset,
          orderBy: config.sortedColumnId
            ? {
                [config.sortedColumnId]: config.sortDirection,
              }
            : undefined,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }
    );

    const users = UserProgressParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items: users, totalItems };
  }

  async submitAttempt(
    attemptId: string,
    feedback: string,
    status: string,
    evaluations: { [questionId: string]: { feedback: string; points: number } }
  ): Promise<void> {
    const convertedEvaluations = {};

    Object.entries(evaluations).forEach(([questionId, evaluation]) => {
      convertedEvaluations[questionId] = {
        graderFeedback: evaluation.feedback,
        userScore: evaluation.points,
      };
    });

    await this.apiClient.post(`/v1/grading/attempts/${attemptId}`, {
      evaluations: convertedEvaluations,
      feedback,
      newStatus: status,
    });
  }

  async updateAttempt(
    attemptId: string,
    feedback: string,
    status: string,
    evaluations: { [questionId: string]: { feedback: string; points: number } }
  ): Promise<void> {
    const convertedEvaluations = {};

    Object.entries(evaluations).forEach(([questionId, evaluation]) => {
      convertedEvaluations[questionId] = {
        graderFeedback: evaluation.feedback,
        userScore: evaluation.points,
      };
    });

    await this.apiClient.put(`/v1/grading/attempts/${attemptId}`, {
      evaluations: convertedEvaluations,
      feedback,
      newStatus: status,
    });
  }
}
