import { mainBlue } from '@u4i/styles/styles-variables';
import React from 'react';
import { fontFamilySans } from "../../constants/Constants";
import { PhaseCompletedIcon } from './PhaseCompletedIcon';
import { PhaseInProgressIcon } from './PhaseInProgressIcon';
import { PhaseNotStartedIcon } from './PhaseNotStarted';

interface IPropTypes {
  cy: number
  phase: any
  r: number
  onPhaseIconClick: () => void
}

export const Phases = (props: IPropTypes) => {
  const {
    cy,
    phase,
    r,
    onPhaseIconClick
  } = props;

  const {
    CXOfPhaseIcon,
    progress,
    shortTitle
  } = phase;

  const positionOfPhaseTitle = (titleLength: number): number =>  {
    switch(titleLength) {
      case 1:
        return CXOfPhaseIcon - 5;
      case 2:
        return CXOfPhaseIcon - 10;
      case 3:
        return CXOfPhaseIcon - 15;
      case 4:
        return CXOfPhaseIcon - 20;
      case 5:
        return CXOfPhaseIcon - 24;
      case 6:
        return CXOfPhaseIcon - 28;
      case 7:
        return CXOfPhaseIcon - 32;
      case 8:
        return CXOfPhaseIcon - 36;
      case 9:
        return CXOfPhaseIcon - 40;
      case 10:
        return CXOfPhaseIcon - 44;
      default:
        return 0;
    }
  }

  return (
    <svg>
      <g>
        <text
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.05,
            fontFamily: fontFamilySans,
            fill: mainBlue
          }}
          x={positionOfPhaseTitle(shortTitle.length)}
          y={cy - 60}
        >
          {shortTitle}
        </text>
      </g>

      { progress == 0 ? 
        <PhaseNotStartedIcon
          cy={cy}
          phase={phase}
          r={r}
          onPhaseIconClick={onPhaseIconClick}
        />        
        : progress == 1 ? 
        <PhaseCompletedIcon 
          cy={cy}
          phase={phase}
          r={r}
          onPhaseIconClick={onPhaseIconClick}
        />    
        : 
        <PhaseInProgressIcon
          cy={cy}
          phase={phase}
          r={r}
          onPhaseIconClick={onPhaseIconClick}
        />
      }
    </svg>
  )
}
