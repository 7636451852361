import { IFeedbackSurvey } from '@u4i/modules/CourseDetailPage/interfaces/CourseInterface';
import React, { FC } from 'react';
import { CertificationStatusCompleted } from './components/CertificationStatusCompleted/CertificationStatusCompleted';
import { CertificationStatusHeading } from './components/CertificationStatusHeading/CertificationStatusHeading';
import { CertificationStatusIncomplete } from './components/CertificationStatusIncomplete/CertificationStatusIncomplete';
import { ProgressIndicator } from './components/ProgressIndicator/ProgressIndicator';
import './_certification-status-box.scss';

interface IProps {
  chapterCount: number
  feedbackSubmitted: boolean
  feedbackSurvey: IFeedbackSurvey
  isFeedbackMandatory: boolean
  numberOfChallenges: number
  numberOfCompletedChallenges: number
  showCertificate: boolean
  courseCompleted: boolean
  totalNumberOfChaptersAndOptionalChallenges: number
  viewedChapterCount: number
  onFeedbackSurveyStart: () => void
}

export const CertificationStatusBox: FC<IProps> = (props:IProps) => {
  const {
    chapterCount,
    feedbackSubmitted,
    feedbackSurvey,
    isFeedbackMandatory,
    numberOfChallenges,
    numberOfCompletedChallenges,
    showCertificate,
    courseCompleted,
    totalNumberOfChaptersAndOptionalChallenges,
    viewedChapterCount,
    onFeedbackSurveyStart
  } = props;

  let isFeedbackSubmitted: boolean = ((!isFeedbackMandatory) || (isFeedbackMandatory && feedbackSubmitted));
  let totalNumberOfChapters: number;

  if(numberOfChallenges == 0 && totalNumberOfChaptersAndOptionalChallenges > chapterCount) {
    totalNumberOfChapters = totalNumberOfChaptersAndOptionalChallenges;
  } else {
    totalNumberOfChapters = chapterCount;
  }

  return (
    <div className="course-detail-page__certification-status-box">
      <div className="course-detail-page__certification-status-box__ribbon" />

      <div className="course-detail-page__certification-status-box__progress">
        <ProgressIndicator
          totalNumberOfChapters={totalNumberOfChapters}
          completedChallengesCount={numberOfCompletedChallenges}
          feedbackSubmitted={feedbackSubmitted}
          feedbackSurvey={feedbackSurvey}
          isFeedbackMandatory={isFeedbackMandatory}
          courseCompleted={courseCompleted}
          totalNumberOfChallenges={numberOfChallenges}
          viewedChapterCount={viewedChapterCount}
        />
      </div>

      <h4 className="course-detail-page__certification-status-box__heading">
        <CertificationStatusHeading
          numberOfChallenges={numberOfChallenges}
          showCertificate={showCertificate}
        />
      </h4>

      <p className="course-detail-page__certification-status-box__description">
        {courseCompleted && isFeedbackSubmitted ?
          <CertificationStatusCompleted
            isFeedbackMandatory={isFeedbackMandatory}
            showCertificate={showCertificate}
            totalNumberOfChallenges={numberOfChallenges}
            totalNumberOfChapters={chapterCount}
          />
          :
          <CertificationStatusIncomplete
            chapterCount={totalNumberOfChapters}
            feedbackSubmitted={isFeedbackSubmitted}
            isFeedbackMandatory={isFeedbackMandatory}
            numberOfChallenges={numberOfChallenges}
            numberOfCompletedChallenges={numberOfCompletedChallenges}
            onFeedbackSurveyStart={onFeedbackSurveyStart}
            showCertificate={showCertificate}
            viewedChapterCount={viewedChapterCount}
          />
        }
      </p>
    </div>
  )
};
