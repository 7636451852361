import React from "react";
import EN_Icon from "@u4i/assets/images/en-US.png";
import DE_Icon from "@u4i/assets/images/de-DE.png";
import JP_Icon from "@u4i/assets/images/ja-JP.png";
import CZ_Icon from "@u4i/assets/images/cs-CZ.png";
import KR_Icon from "@u4i/assets/images/ko-KR.png";
import CN_Icon from "@u4i/assets/images/zh-CN.png";
import TH_Icon from "@u4i/assets/images/th-TH.png";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import cx from "classnames";
import "./_language-flag.scss";

interface IPropTypes {
  customStyling?: boolean;
  language: LanguageEnum;
  title?: string;
}

const defaultProps: Partial<IPropTypes> = {
  customStyling: false,
  title: ""
};

const availableLanguageIcons = [
  { language: LanguageEnum.DE_DE, icon: DE_Icon },
  { language: LanguageEnum.EN_US, icon: EN_Icon },
  { language: LanguageEnum.KO_KR, icon: KR_Icon },
  { language: LanguageEnum.CS_CZ, icon: CZ_Icon },
  { language: LanguageEnum.JA_JP, icon: JP_Icon },
  { language: LanguageEnum.TH_TH, icon: TH_Icon },
  { language: LanguageEnum.ZH_CN, icon: CN_Icon }
];

const LanguageFlag: React.FC<IPropTypes> = (props) => {
  return (
    <img
      alt={props.language}
      className={cx({
        "language-flag": !props.customStyling,
      })}
      src={availableLanguageIcons.find((al) => al.language == props.language)?.icon}
      title={props.title}
    />
  );
};

LanguageFlag.defaultProps = defaultProps;

export default LanguageFlag;
