import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';

import {FileExtension} from './FileExtension';
import {ICustomFieldProps} from '../../Field';

import intlMessages from './intlMessages';
import './_file-field.scss';

interface IPropTypes extends ICustomFieldProps<Array<File>>, WrappedComponentProps {
  allowedFileExtensions?: Array<FileExtension>;
  label?: string;
  maxFileSizeBytes?: number;
  multiple?: boolean;
  isNotSubmittable: boolean
}

// TODO: upgrade <Field> typings to avoid passing generic with props for specific field component
export type FileFieldPropTypes = Omit<IPropTypes, 'intl'>;

class FileField extends React.Component<IPropTypes> {
  private inputRef = React.createRef<HTMLInputElement>();

  get fileInfo(): React.ReactNode {
    const pickedFilesCount = this.props.value.length;

    if (pickedFilesCount === 0) {
      return <FormattedMessage {...intlMessages.noFilesSelected} />;
    }

    if (pickedFilesCount > 1) {
      return <FormattedMessage {...intlMessages.filesSelected} values={{count: pickedFilesCount}} />;
    }

    return this.props.value[0].name;
  }

  get fileInfoTooltip(): string {
    return this.props.value.reduce((accumulator, file, index) => {
      return accumulator + `${index > 0 ? ' ; ' : ''}${file.name}`;
    }, '');
  }

  get readableMaxFileSize(): string {
    const {maxFileSizeBytes} = this.props;

    if (!maxFileSizeBytes) {
      return '';
    }

    if (maxFileSizeBytes <= 999) {
      return `${maxFileSizeBytes}B`;
    } else if (maxFileSizeBytes <= Math.pow(1000, 2) - 1) {
      return `${Math.floor(maxFileSizeBytes / 1000)}kB`;
    } else if (maxFileSizeBytes <= Math.pow(1000, 3) - 1) {
      return `${Math.floor(maxFileSizeBytes / Math.pow(1000, 2))}MB`;
    } else {
      return `${Math.floor(maxFileSizeBytes / Math.pow(1000, 3))}GB`;
    }
  }

  clearFiles = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = '';
      this.props.onChange([]);
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files ? Array.from(event.target.files) : [];
    let fieldError = false;

    files = files.filter(file => {
      let extension = file.name.split('.').pop();

      if (extension) {
        extension = extension.toLowerCase();
      }

      if ((this.props.allowedFileExtensions && !this.props.allowedFileExtensions.includes(extension as FileExtension))
        || (this.props.maxFileSizeBytes && file.size > this.props.maxFileSizeBytes)) {
        fieldError = true;

        return false;
      }

      return true;
    });

    this.props.onChange(files);

    if (fieldError) {
      this.props.onError(this.props.intl.formatMessage(intlMessages.fileError));
    }
  };

  render() {
    return (
      <div
        className={cx({
          'reactive-forms__fields__file': true,
          'reactive-forms__file-field--invalid': this.props.meta.touched && this.props.meta.error,
        })}
      >
        <input
          accept={this.props.allowedFileExtensions ?
            this.props.allowedFileExtensions.map(entry => `.${entry}`).join(',') : '*'
          }
          className="reactive-forms__fields__file__input"
          disabled={this.props.disabled || this.props.isNotSubmittable}
          id={this.props.id}
          multiple={this.props.multiple !== undefined ? this.props.multiple : false}
          onChange={this.handleChange}
          onFocus={this.props.onFocus}
          ref={this.inputRef}
          type="file"
        />

        <label className="reactive-forms__fields__file__label" htmlFor={this.props.id} style={{cursor: (this.props.disabled || this.props.isNotSubmittable) ? 'not-allowed' : 'pointer'}}>
          <FontAwesome className="reactive-forms__fields__file__label-icon" name="upload" />

          {this.props.label || <FormattedMessage {...intlMessages.chooseFiles} />}
          {this.props.maxFileSizeBytes &&
            <FormattedMessage {...intlMessages.maxSizeLabel} values={{readableSize: this.readableMaxFileSize}} />
          }
        </label>

        <div className="reactive-forms__fields__file__info" title={this.fileInfoTooltip}>
          {this.fileInfo}
        </div>

        {this.props.value.length > 0 &&
          <button className="reactive-forms__fields__file__clear-button" onClick={this.clearFiles} type="button">
            <FontAwesome name="times" />
          </button>
        }

        {(this.props.meta.touched && this.props.meta.error) &&
          <div className="reactive-forms__fields__file__error">
            {this.props.meta.error}
          </div>
        }
      </div>
    );
  }
}

const WrappedComponent = injectIntl(FileField);

export {WrappedComponent as FileField};
