export interface IProgram {
  backgroundImage?: string
  createdAt?: string
  // createdBy: string       // ToDo: should be added on BE first
  description: string
  id: string
  internalDescription: string
  lastViewedPhaseId: string
  phases?: IProgramPhase[]
  status?: ProgramStatusEnum
  title: string
  updatedAt?: string;
  // updatedBy: string       // ToDo: should be added on BE first
}

export interface IProgramPost {
  background_image?: IImageFile[]
  description?: string
  internal_description?: string
  phases?: IProgramPhasePost[]
  status?: ProgramStatusEnum
  title: string
}

export interface IImageFile {
  lastModified: any
  lastModifiedDate: Date | any
  name: string
  originFileObj: any
  percent: number
  response: string
  size: number
  status: string
  thumbUrl: string
  url: string
  type: string
  uid: string
}

export interface IProgramPut {
  description: string
  internal_description: string
  phases?: IProgramPhasePut[]
  status?: ProgramStatusEnum
  title: string
}

export interface IProgramPhase {
  createdAt?: string
  collapsed?: boolean
  icon: string      // ToDo: Not sure if we should store it on BE.
  id?: string
  longTitle: string
  phaseModules?: IProgramModule[]   // ToDo: Not added yet on BE
  progress?: number | any
  order: number
  programId?: string      // ToDo: Think again if it is needed here
  required: boolean
  shortTitle: string
  updatedAt?: string

  style?: any   //FE property
  cardStyle?: any  //FE property
  cards?: any[]   //FE property
}

export interface IPhaseUI {
  createdAt?: string
  collapsed?: boolean
  id?: string
  phaseId: string
  longTitle: string
  phaseModules?: IProgramModule[]   // ToDo: Not added yet on BE
  progress?: number | any
  order: number
  programId?: string      // ToDo: Think again if it is needed here
  required: boolean
  shortTitle: string
  updatedAt?: string

  visible?: boolean   //FE property to show which phase is expanded on program UI
}

export interface IProgramPhasePost {
  icon: string
  id?: string
  long_title: string
  phaseModules?: IProgramModule[]   // ToDo: Not added yet on BE
  order?: number
  required: boolean
  short_title: string
}

export interface IProgramPhasePut {
  icon: string
  id?: string
  long_title: string
  phaseModules?: IProgramModule[]   // ToDo: Not added yet on BE
  order?: number
  required: boolean
  short_title: string
}

export interface IProgramModule {
  id?: string
  resource_url: string
  duration: string
  icon?: string
  internal_id?: string
  module_type: ModuleType | string
  order: number
  progress?: 0 | 1    // 0 = IN_PROGRESS aka NOT_STARTED; 1 = DONE;
  resource_type: ProgramResourceTypeEnum | string
  resource_id: string
  required: boolean
  title: string

  visible?: boolean   // only FE property to manage modal visibility
}

export interface IProgramModulePost {
  icon?: string    // ToDo: We dont need it at all for modules
  id?: string
  internal_id?: string
  module_type: string
  order?: number
  phase_id?: string
  resource_id: string
  resource_type: string
  required: boolean
  title?: string
}

export interface IResource {
  id: string
  internal_id: string
  long_title?: string
  title: string
}

export interface IManageResources {
  areChaptersLoaded: boolean
  chaptersList: IResource[]
  areCoursesLoaded: boolean
  coursesList: IResource[]
}

export interface IModuleOptions {
  name: string
  value: string
}

export enum ProgramResourceTypeEnum {
  Course = "Course",
  Chapter = "Chapter"
  // Topic = "Topic"
}

export enum ProgramStatusEnum {
  DRAFT = "draft",
  REVIEW = "review",
  PUBLISHED = "published"
}

export const programStatuses = [
  {
    value: "draft",
    text: "Draft",
  },
  {
    value: "review",
    text: "Review",
  },
  {
    value: "published",
    text: "Published"
  }
];

export enum ModuleType {
  ONLINE_DISCUSSION = 'online-discussion',
  ONLINE_COURSE = 'online-course',
  PRACTICE_EXERCISE = 'practice-exercise',
  INTERACTIVE_TASK = 'interactive-task',
  LABS = 'digital-jupiter-labs',
  LIVE_EVENT = 'live-event',
  USE_CASES = 'use-cases',
  SELF_REFLECTION = 'self-reflection',
  GROUP_DISCUSSION = 'group-discussion',
  KARL = 'karl',
}
