import {action, autorun, computed, IReactionDisposer, observable, runInAction,makeObservable} from 'mobx';
import {compact, debounce} from 'lodash';

import {IRootStore} from '@u4i/state/RootStore';
import {IStorage} from '@u4i/state/ServicesInterfaces';
import {DataColumn, DataColumnConfig} from './DataColumn';
import {FunctionalColumn, FunctionalColumnConfig} from './FunctionalColumn';
import {BaseFilter} from './filters/BaseFilter';

export class DataTableStore<RowDataShape extends {[key: string]: any}> {
  private _columns: Array<DataColumn<RowDataShape[keyof RowDataShape]> | FunctionalColumn<RowDataShape>>;
  private _currentFilters: { [filterName: string]: any } = {};
  private _currentSortDirection: 'ASC' | 'DESC' = 'ASC';
  private _currentSortedColumnId?: keyof RowDataShape;
  private _data: Array<RowDataShape> = [];
  private _filters?: Array<BaseFilter<any>>;
  private _pagination: {
    currentPage: number;
    enabled: boolean;
    itemsPerPage: number;
  } = {currentPage: 1, enabled: false, itemsPerPage: 0};
  private _reloading = true;
  private _showFilters = false;
  private _tableId: string;
  private _totalItems = 0;
  private dataProvider: (
    sortedColumnId: keyof RowDataShape | undefined,
    sortDirection: 'ASC' | 'DESC',
    filters: { [columnName in keyof RowDataShape]?: RowDataShape[columnName] },
    paginationInfo: {limit: number; offset: number;} | undefined,
  ) => Promise<{items: Array<RowDataShape>, totalItems: number}>;
  private saveStoredSettingsDisposer: IReactionDisposer;
  private storageService: IStorage;

  constructor(config: {
    dataColumns: { [columnName in keyof RowDataShape]?: DataColumnConfig<RowDataShape[columnName]>; };
    dataProvider: (
      sortedColumnId: keyof RowDataShape | undefined,
      sortDirection: 'ASC' | 'DESC',
      filters: { [columnName in keyof RowDataShape]?: RowDataShape[columnName] },
      paginationInfo: {limit: number; offset: number;} | undefined,
    ) => Promise<{items: Array<RowDataShape>, totalItems: number}>;
    defaultSortDirection?: 'ASC' | 'DESC';
    defaultSortedColumnId?: keyof RowDataShape;
    filters?: Array<BaseFilter<any> | undefined>;
    functionalColumns?: { [columnName: string]: FunctionalColumnConfig<RowDataShape> };
    immediatelyShowFilters?: true;
    pagination?: {
      enabled: true;
      itemsPerPage: number;
    };
    tableId: string;
  }, rootStore: IRootStore) {
    makeObservable<DataTableStore<RowDataShape>, "_columns" | "_currentFilters" | "_currentSortDirection" | "_currentSortedColumnId" | "_data" | "_pagination" | "_reloading" | "_showFilters" | "_tableId" | "_totalItems" | "generateColumns" | "loadStoredSettings" | "reloadData">(this, {
      _columns: observable,
      _currentFilters: observable,
      _currentSortDirection: observable,
      _currentSortedColumnId: observable,
      _data: observable,
      _pagination: observable,
      _reloading: observable,
      _showFilters: observable,
      _tableId: observable,
      _totalItems: observable,
      columns: computed,
      currentFilters: computed,
      currentPage: computed,
      currentSortDirection: computed,
      currentSortedColumnId: computed,
      data: computed,
      itemsPerPage: computed,
      paginationEnabled: computed,
      reloading: computed,
      showFilters: computed,
      tableId: computed,
      totalItems: computed,
      totalPages: computed,
      visibleColumnsCount: computed,
      applyFilters: action.bound,
      changeFilterApply: action.bound,
      changeItemsPerPage: action.bound,
      changePage: action.bound,
      changeSorting: action.bound,
      generateColumns: action.bound,
      loadStoredSettings: action.bound,
      reloadData: action.bound,
      toggleFilters: action.bound
    });

    const {storageService} = rootStore;

    this.storageService = storageService;

    this._filters = config.filters ? compact(config.filters) : [];
    this._tableId = config.tableId;
    this._columns = this.generateColumns(config.dataColumns, config.functionalColumns || {});

    this.dataProvider = config.dataProvider;

    if (this._filters) {
      this._filters.forEach((filter) => {
        filter.subscribe((value) => { this.applyFilters(value); });
      });
    }

    if (config.defaultSortDirection !== undefined) { this._currentSortDirection = config.defaultSortDirection; }
    if (config.defaultSortedColumnId !== undefined) { this._currentSortedColumnId = config.defaultSortedColumnId; }
    if (config.immediatelyShowFilters !== undefined) { this._showFilters = true; }

    if (config.pagination) {
      this._pagination.enabled = true;
      this._pagination.itemsPerPage = config.pagination.itemsPerPage;
    }

    this.loadStoredSettings();
    this.saveStoredSettingsDisposer = autorun(this.saveStoredSettings);

    this.reloadData();
  }

  get columns() { return this._columns; }
  get currentFilters() { return this._currentFilters; }
  get currentPage() { return this._pagination.currentPage; }
  get currentSortDirection() { return this._currentSortDirection; }
  get currentSortedColumnId() { return this._currentSortedColumnId; }
  get data() { return this._data; }
  get filters() { return this._filters; }
  get itemsPerPage() { return this._pagination.itemsPerPage; }
  get paginationEnabled() { return this._pagination.enabled; }
  get reloading() { return this._reloading; }
  get showFilters() { return this._showFilters; }
  get tableId() { return this._tableId; }
  get totalItems() { return this._totalItems; }
  get totalPages() { return Math.ceil(this._totalItems / this._pagination.itemsPerPage); }
  get visibleColumnsCount() { return this._columns.length; }

  applyFilters(changes: { [filterName: string]: any }) {
    Object.assign(this._currentFilters, changes);

    this.changeFilterApply();
  }

  changeFilterApply = debounce(() => {
    this._pagination.currentPage = 1;

    this.reloadData();
  }, 800);


  changeItemsPerPage(newItemsPerPage: number) {
    this._pagination.currentPage = 1;
    this._pagination.itemsPerPage = newItemsPerPage;

    this.reloadData();
  }

  changePage(newPage: number) {
    this._pagination.currentPage = newPage;

    this.reloadData();
  }

  changeSorting(newColumnId: keyof RowDataShape, newSortDirection?: 'ASC' | 'DESC') {
    if (newSortDirection) {
      this._currentSortDirection = newSortDirection;
    } else {
      if (this._currentSortedColumnId === newColumnId) {
        if (this._currentSortDirection === 'ASC') {
          this._currentSortDirection = 'DESC';
        } else if (this._currentSortDirection === 'DESC') {
          this._currentSortDirection = 'ASC';
          this._currentSortedColumnId = undefined;
        }
      } else {
        this._currentSortedColumnId = newColumnId as string;
        this._currentSortDirection = 'ASC';
      }
    }

    this.reloadData();
  }

  destroy = () => {
    this.saveStoredSettingsDisposer();

    if (this._filters) {
      this._filters.forEach((filter) => { filter.unsubscribeAll(); });
    }
  };

  private generateColumns(
    dataColumns: { [columnName in keyof RowDataShape]?: DataColumnConfig<RowDataShape[keyof RowDataShape]>; },
    functionalColumns: { [columnName: string]: FunctionalColumnConfig<RowDataShape> }
  ) {
    const preparedDataColumns = Object.keys(dataColumns).map((columnId) => {
      return new DataColumn(Object.assign({}, dataColumns[columnId], {id: columnId}));
    });

    const preparedFunctionalColumns = Object.keys(functionalColumns).map((columnId) => {
      return new FunctionalColumn(Object.assign({}, functionalColumns[columnId], {id: columnId}));
    });

    const allColumns = [...preparedDataColumns, ...preparedFunctionalColumns];

    return allColumns.sort((columnA, columnB) => {
      if (columnA.order > columnB.order) {
        return 1;
      }

      if (columnA.order < columnB.order) {
        return -1;
      }

      return 0;
    });
  }

  private loadStoredSettings() {
    const savedTablesSettings = this.storageService.dataTablesSettings.get();

    if (savedTablesSettings && savedTablesSettings[this._tableId]) {
      const tableSettings = savedTablesSettings[this._tableId];

      this._pagination.itemsPerPage = tableSettings.itemsPerPage;
      this._showFilters = tableSettings.showFilters;

      if (tableSettings.currentSortedColumnId) {
        const savedSortedColumn = this._columns.find(column => column.id === tableSettings.currentSortedColumnId);
        
        if (savedSortedColumn && savedSortedColumn instanceof DataColumn) {
          this._currentSortDirection = tableSettings.currentSortDirection;
          this._currentSortedColumnId = tableSettings.currentSortedColumnId;
        }
      }

      Object.keys(tableSettings.filters).forEach((filterName) => {
        if (this._filters) {
          const foundFilter = this._filters.find(filter => filter.storedValueName === filterName);

          if (foundFilter) {
            foundFilter.updateValues(tableSettings.filters[filterName]);
          }
        }
      });
    }
  }

  private async reloadData() {
    this._reloading = true;

    const nonEmptyFilters = {};

    Object.keys(this._currentFilters).forEach((columnName) => {
      const value = this._currentFilters[columnName];

      if (value) {
        nonEmptyFilters[columnName] = this._currentFilters[columnName];
      }
    });

    const provisionResult = await this.dataProvider(
      this._currentSortedColumnId,
      this._currentSortDirection,
      nonEmptyFilters,
      this._pagination.enabled ? {
        limit: this._pagination.itemsPerPage,
        offset: (this._pagination.currentPage - 1) * this._pagination.itemsPerPage,
      } : undefined,
    );

    runInAction(() => {
      this._data = provisionResult.items;
      this._reloading = false;
      this._totalItems = provisionResult.totalItems;
    });
  }

  private saveStoredSettings = () => {
    const savedTablesSettings = this.storageService.dataTablesSettings.get();

    const filters: { [key: string]: Object } = {};
    
    if (this._filters) {
      this._filters.forEach((filter) => {
        filters[filter.storedValueName] = filter.currentValues;
      });
    }

    this.storageService.dataTablesSettings.set({
      ...(savedTablesSettings ? savedTablesSettings : {}),
      [this._tableId]: {
        currentSortDirection: this._currentSortDirection,
        currentSortedColumnId: this._currentSortedColumnId as string,
        filters,
        itemsPerPage: this._pagination.itemsPerPage,
        showFilters: this._showFilters,
      },
    });
  };

  toggleFilters() {
    this._showFilters = !this._showFilters;
  }
}
