import { mainBlue, white } from "@u4i/styles/styles-variables";
import * as React from "react";
import { opacity7 } from "../../constants/Constants";
import { mapModuleStyling } from "../../constants/StaticMethods";

interface IPropTypes {
  cx: number
  cy: number
  isCompleted: boolean
  radius: number
  required: boolean
  title: string
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void 
  showModuleInVE: () => void
}

export const GroupDiscussionIcon = (props: IPropTypes) => {
  const {
    cx,
    cy,
    isCompleted,
    radius,
    required,
    title,
    onModuleHover,
    showModuleInVE
  } = props;

  const [opacity, setOpacity] = React.useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  const moduleHover = (mouseEnter: boolean) => {
    onModuleHover(cx, cy, title, mouseEnter);
  }

  return (
    <svg>
      <g
        onClick={showModuleInVE}
        onMouseEnter={() => { handleOpacity(); moduleHover(true); }}
        onMouseLeave={() => { handleOpacity(); moduleHover(false); }}
        style={{ cursor: "pointer", ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          cx={cx}
          cy={cy}
          r={isCompleted ? radius : radius-3}
          style={mapModuleStyling(isCompleted)}
        >
        </circle>
        <g fill={isCompleted ? white : mainBlue}>
          <path
            d={`m ${cx-0.5},${cy-11.5} c 0.186168,0.134281 0.296584,0.349876 0.296584,0.579455 v 21.428564 c 0,0.229579 -0.110416,0.44512 -0.296584,0.579428 -0.186277,0.134282 -0.425737,0.170835 -0.643573,0.09816 l -12.857154,-4.285713 c -0.291672,-0.09713 -0.488423,-0.370123 -0.488423,-0.677571 v -12.857139 c 0,-0.307437 0.196751,-0.5804 0.488423,-0.677625 l 12.857154,-4.285713 c 0.217863,-0.07262 0.457269,-0.03618 0.643573,0.09816 z m -12.56057,5.379999 v 11.82745 l 11.428569,3.809568 v -19.446532 z`}
            style={{strokeWidth: 1.5}}
          />
          <path
            d={`m ${cx-8},${cy-2} h -2.142856 v -1.428569 h 5.714281 v 1.428569 h -2.142856 v 5.714282 h -1.428569 z m 12.142851,-4.285713 c -0.788998,0 -1.428569,0.639599 -1.428569,1.428569 0,0.788971 0.639571,1.428575 1.428569,1.428575 0.789003,0 1.428574,-0.639604 1.428574,-1.428575 0,-0.78897 -0.639571,-1.428569 -1.428574,-1.428569 z m -2.857138,1.428569 c 0,-1.577952 1.279143,-2.857138 2.857138,-2.857138 1.578001,0 2.857144,1.279186 2.857144,2.857138 0,1.577958 -1.279143,2.857144 -2.857144,2.857144 -1.577995,0 -2.857138,-1.279186 -2.857138,-2.857144 z m 10.714282,0.714288 c -0.789004,0 -1.428575,0.639598 -1.428575,1.428569 0,0.78897 0.639571,1.428574 1.428575,1.428574 0.788997,0 1.428569,-0.639604 1.428569,-1.428574 0,-0.788971 -0.639572,-1.428569 -1.428569,-1.428569 z m -2.857144,1.428569 c 0,-1.577958 1.279143,-2.857139 2.857144,-2.857139 1.577995,0 2.857138,1.279181 2.857138,2.857139 0,1.577957 -1.279143,2.857143 -2.857138,2.857143 -1.578001,0 -2.857144,-1.279186 -2.857144,-2.857143 z m -2.857144,4.285712 h -4.285712 v -1.428569 h 5 c 0.39442,0 0.714287,0.319856 0.714287,0.714288 v 4.999994 c 0,2.366999 -1.918714,4.285713 -4.285713,4.285713 h -1.428574 v -1.428569 h 1.428574 c 1.577995,0 2.857138,-1.279143 2.857138,-2.857144 z m 7.857144,2.142857 h -4.285713 v -1.428569 h 4.999995 c 0.39442,0 0.714287,0.319856 0.714287,0.714287 v 2.857138 c 0,2.366999 -1.918714,4.285713 -4.285713,4.285713 h -1.428569 v -1.428569 h 1.428569 c 1.578001,0 2.857144,-1.279143 2.857144,-2.857144 z`}
            style={{strokeWidth: 1.5}}
          />
        </g>
      </g>
    </svg>
  )
}
