// Challenges
export const CHALLENGES_TYPEFORM_ORIGIN = 'https://university4industry.typeform.com';

// Chapter cards
export const CHAPTER_CARD_INITIAL_DECRIPTION_LENGTH = 80;
export const CHAPTER_CARD_MAX_DESCRIPTION_LENGTH = 250;

// Curriculum
export const CURRICULUM_CARD_WIDTH = 145;
export const CURRICULUM_ROW_LABEL_COLUMN_WIDTH = 180;
export const CURRICULUM_SPACING = 8;

// Discuss
export const DISCUSS_ANSWER_TRIMMING_THRESHOLD = 250;
export const DISCUSS_ANSWERS_PER_PAGE = 8;
export const DISCUSS_COMMENT_TRIMMING_THRESHOLD = 220;

// External page base url
export const EXTERNAL_BASE_URL = 'https://www.university4industry.com';

// External chatbot page base url
export const EXTERNAL_CHATBOT_BASE_URL = 'https://chatbot-prod-aws.university4industry.com';

// Notifications page
export const NOTIFICATIONS_PAGE_ITEMS_PER_PAGE = 4;

// Onboarding
export const ONBOARDING_ENABLE_FOR_GUESTS = false;

// Player (Vertical Experience)
export const PLAYER_AUTOPLAY_VISIBILITY_THRESHOLD = 0.75;
export const PLAYER_DISCUSS_QUESTION_TRIMMING_THRESHOLD = 270;
export const PLAYER_FONT_SIZE_SCALING_FACTOR = 9;
export const PLAYER_INTERACTIVE_MEDIA_FREEZE_THRESHOLD = 0.6;
export const PLAYER_INTERACTIVE_SCROLL_OVERLOAD_DIVIDER = 1.2;
export const PLAYER_MAX_PROVIDERS_DISPLAYED = 15;
export const PLAYER_SCROLL_LOWER_DELTA_VIEWPORT_FRACTION = 0.1;
export const PLAYER_SCROLL_RESTORATION_NOTIFICATION_TIMEOUT = 5000;
export const PLAYER_SCROLL_UPPER_DELTA_VIEWPORT_FRACTION = 0.4;
export const PLAYER_SCROLL_VELOCITY_MULTIPLIER = 1.7;
export const PLAYER_TRACKING_MILLISECONDS_REQUIRED = 100;
export const PLAYER_TRACKING_VISIBILITY_THRESHOLD = 0.5;
export const PLAYER_TRANSITION_CALCULATION_TOLERATION = 0.25;
export const PLAYER_VIEW_MILLISECONDS_REQUIRED = 300;
export const PLAYER_ELEMENT_ID_GUID_LENGTH = 36;
export const PLAYER_DEFAULT_MINIMUM_DURATION = 100;

// Search page
export const SEARCH_PAGE_ITEMS_PER_PAGE = 9;

// Skill detail page
export const SKILL_DETAIL_OVERVIEW_TRIMMING_THRESHOLD = 325;
export const SKILL_DETAIL_PAGE_ITEMS_PER_PAGE = 8;

// DL scheduling
export const BOOKED_EVENT_HEIGHT = 48;
export const DEFAULT_BOOKED_EVENT = 60;

// Max number of import user can parse on frontend without blocking/hanging the browser
export const MAX_RECORD_CAN_PARSE_ON_FE = 100000;

export const reactQuillModules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        ['clean']
    ]
}

export const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'color', 'background'
]

export const PLATFORM_ROUTES = [
    '/administration3',
    '/admin/setting/basic',
    '/administration',
    '/checkout',
    '/digital-labs/',
    '/discuss/:challengeSlug',
    '/exercises/',
    '/external-content',
    '/game',
    '/grading/attempt/',
    '/grading/attempts',
    '/grading/evaluation/',
    '/grading/progress/group/',
    '/grading/progress/user/',
    '/grading/progress',
    '/index.html',
    '/jupyter-lab/',
    '/login',
    '/notifications',
    '/pages/',
    '/player/skill/',
    '/player/chapter/',
    '/register',
    '/recover-password',
    '/reset-password/',
    '/register-confirm/',
    '/register-success',
    '/redeem',
    '/scorm-authentication',
    '/search',
    '/skill-not-found',
    '/skills/'
]