import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import './_seek-tooltip.scss';

SeekTooltip.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  positionPercentage: PropTypes.number.isRequired,
  targetTime: PropTypes.number.isRequired,
};

function SeekTooltip(props) {
  const showHours = moment.duration(props.targetTime).get('hours') > 0;
  const formatter = showHours ? 'HH:mm:ss' : 'mm:ss';
  const targetTimeFormatted = moment.utc(props.targetTime * 1000).format(formatter);

  return (
    <div
      className={cx({
        'youtube-player__seek-tooltip': true,
        'youtube-player__seek-tooltip--visible': props.isVisible,
      })}
      style={{left: `${props.positionPercentage}%`}}
    >
      {targetTimeFormatted}
    </div>
  );
}

export default SeekTooltip;
