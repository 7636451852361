import {defineMessages} from 'react-intl';

export default defineMessages({
  newPasswordConfirmErrorsPresence: {
    defaultMessage: 'Password confirm cannot be blank',
    id: 'Forms.ResetPassword.NewPasswordConfirm.Errors.Presence',
  },
  newPasswordConfirmErrorsRepeat: {
    defaultMessage: 'Password confirm is not equal to password',
    id: 'Forms.ResetPassword.NewPasswordConfirm.Errors.Repeat',
  },
  newPasswordConfirmLabel: {
    defaultMessage: 'New password confirm',
    id: 'Forms.ResetPassword.NewPasswordConfirm.Label',
  },
  newPasswordErrorsMinLength: {
    defaultMessage: 'Minimum {limit} characters',
    id: 'Forms.ResetPassword.NewPassword.Errors.MinLength',
  },
  newPasswordErrorsPresence: {
    defaultMessage: 'Password cannot be blank',
    id: 'Forms.ResetPassword.NewPassword.Errors.Presence',
  },
  newPasswordErrorsLowercasePresent: {
    defaultMessage: 'Password must contain at least one lowercase letter',
    id: 'Forms.ResetPassword.NewPassword.Errors.LowercasePresent',
  },
  newPasswordErrorsNumberPresent: {
    defaultMessage: 'Password must contain at least one numeric digit',
    id: 'Forms.ResetPassword.NewPassword.Errors.NumberPresent',
  },
  newPasswordErrorsSpecialCharacterPresent: {
    defaultMessage: 'Password must contain at least one special character',
    id: 'Forms.ResetPassword.NewPassword.Errors.SpecialCharacterPresent',
  },
  newPasswordErrorsUppercasePresent: {
    defaultMessage: 'Password must contain at least one uppercase letter',
    id: 'Forms.ResetPassword.NewPassword.Errors.UppercasePresent',
  },
  newPasswordLabel: {
    defaultMessage: 'New password',
    id: 'Forms.ResetPassword.NewPassword.Label',
  },
  submitTitle: {
    defaultMessage: 'Create',
    id: 'Forms.ResetPassword.SubmitTitle',
  },
  successMessage: {
    defaultMessage: 'Your password has been changed',
    id: 'Forms.ResetPassword.SuccessMessage',
  },
});
