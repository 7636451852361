import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {SortingButton} from './SortingButton';

import intlMessages from './intlMessages';
import './_sorting-buttons.scss';

interface IPropTypes {
  currentSortDirection: 'ASC' | 'DESC'
  currentSortedField: string
  onSortAnswers: (fieldName: string) => void
}

export const SortingButtons: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="discuss__sorting-buttons">
    <SortingButton
      currentSortDirection={props.currentSortDirection}
      currentSortedField={props.currentSortedField}
      fieldName="createdAt"
      onSortAnswers={props.onSortAnswers}
    >
      <FormattedMessage {...intlMessages.date} />
    </SortingButton>

    <SortingButton
      currentSortDirection={props.currentSortDirection}
      currentSortedField={props.currentSortedField}
      fieldName="likes"
      onSortAnswers={props.onSortAnswers}
    >
      <FormattedMessage {...intlMessages.likes} />
    </SortingButton>

    <SortingButton
      currentSortDirection={props.currentSortDirection}
      currentSortedField={props.currentSortedField}
      fieldName="comments"
      onSortAnswers={props.onSortAnswers}
    >
      <FormattedMessage {...intlMessages.comments} />
    </SortingButton>
  </div>
);
