import {AxiosInstance} from 'axios';
import {AdminDefaultNotificationsApi} from './adminApiModules/AdminDefaultNotificationsApi';
import {AdminProvidersApi} from './adminApiModules/AdminProvidersApi';
import {AdminUserGroupsApi} from './adminApiModules/AdminUserGroupsApi';
import {AdminUserProfilesApi} from './adminApiModules/AdminUserProfilesApi';
import {AdminUsersApi} from './adminApiModules/AdminUsersApi';
import {AdminUserSkillsApi} from './adminApiModules/AdminUserSkillsApi';
import {AdminUserCourseApi} from './adminApiModules/AdminUserCourseApi';
import {AdminUserImportApi} from './adminApiModules/AdminUserImportApi';
import {AdminUserExportApi} from './adminApiModules/AdminUserExportApi';
import {AdminCustomerIntegrationApi} from './adminApiModules/AdminCustomerIntegrationApi';
import {AdminProgramsApi} from './adminApiModules/AdminProgramsApi';
import {AdminChallengesApi} from './adminApiModules/AdminChallengesApi';
import {AdminFileUploadApi} from './adminApiModules/AdminFileUploadApi';
import {AdminCornerstoneCourseAssignmentApi} from './adminApiModules/AdminCornerstoneCourseAssignmentApi';
import {AdminAssessmentHubApi} from './adminApiModules/AdminAssessmentHubApi';
import {AdminChatbotApi} from './adminApiModules/AdminChatbotApi';
import {AdminGroupsApi} from "@u4i/state/services/apiModules/AdminApi/adminApiModules/AdminGroupsApi";

export class AdminApi {
  private apiClient: AxiosInstance;

  assessmentHub: AdminAssessmentHubApi;
  cornerstoneCourseAssignment: AdminCornerstoneCourseAssignmentApi;
  customerIntegration: AdminCustomerIntegrationApi;
  defaultNotifications: AdminDefaultNotificationsApi;
  providers: AdminProvidersApi;
  programs: AdminProgramsApi;
  userGroups: AdminUserGroupsApi;
  userProfiles: AdminUserProfilesApi;
  users: AdminUsersApi;
  userSkills: AdminUserSkillsApi;
  userCourse: AdminUserCourseApi;
  userImport: AdminUserImportApi;
  userExport: AdminUserExportApi;
  adminChallenges: AdminChallengesApi;
  fileUpload: AdminFileUploadApi;
  groups: AdminGroupsApi;
  chatbot: AdminChatbotApi;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;

    this.assessmentHub = new AdminAssessmentHubApi(this.apiClient);
    this.cornerstoneCourseAssignment = new AdminCornerstoneCourseAssignmentApi(this.apiClient);
    this.customerIntegration = new AdminCustomerIntegrationApi(this.apiClient);
    this.defaultNotifications = new AdminDefaultNotificationsApi(this.apiClient);
    this.providers = new AdminProvidersApi(this.apiClient);
    this.programs = new AdminProgramsApi(this.apiClient);
    this.userGroups = new AdminUserGroupsApi(this.apiClient);
    this.userProfiles = new AdminUserProfilesApi(this.apiClient);
    this.users = new AdminUsersApi(this.apiClient);
    this.userSkills = new AdminUserSkillsApi(this.apiClient);
    this.userCourse = new AdminUserCourseApi(this.apiClient);
    this.userImport = new AdminUserImportApi(this.apiClient);
    this.userExport = new AdminUserExportApi(this.apiClient);
    this.adminChallenges = new AdminChallengesApi(this.apiClient);
    this.providers = new AdminProvidersApi(this.apiClient);
    this.fileUpload = new AdminFileUploadApi(this.apiClient);
    this.groups = new AdminGroupsApi(this.apiClient)
    this.chatbot = new AdminChatbotApi(this.apiClient)
  }
}
