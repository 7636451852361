import cx from 'classnames';
import React, { FC } from 'react';
import SuccessIcon from '@material-ui/icons/Done';
import {FormattedMessage} from 'react-intl';
import intlMessages from '../../../AttemptEvaluation/components/AttemptEvaluationSubmissionOverlay/intlMessages';
import AppNavigator from '@u4i/common/AppNavigator';
import CircularProgress from '@u4i/common/CircularProgress';
import './_attempt-edit-submission-overlay.scss';
import { green } from '@u4i/styles/styles-variables';

interface IPropTypes {
  submissionSuccess: boolean
  submitting: boolean
}

export const AttemptEditSubmissionOverlay: FC<IPropTypes> = (props: IPropTypes) => {
  return(
    <div
      className={cx({
        'customer-grading__attempt-evaluation-submission-overlay': true,
        'customer-grading__attempt-evaluation-submission-overlay--visible': props.submitting || props.submissionSuccess
      })}
    >
      <div
        className={cx({
          'customer-grading__attempt-evaluation-submission-overlay__progress': true,
          'customer-grading__attempt-evaluation-submission-overlay__progress--visible': !props.submissionSuccess,
        })}
      >
        <CircularProgress noMargin />
      </div>

      <div
        className={cx({
          'customer-grading__attempt-evaluation-submission-overlay__success': true,
          'customer-grading__attempt-evaluation-submission-overlay__success--visible': props.submissionSuccess,
        })}
      >
        <SuccessIcon style={{color: green, height: 64, width: 64}} />

        <p className="customer-grading__attempt-evaluation-submission-overlay__message">
          <FormattedMessage {...intlMessages.submitted} />
        </p>

        <button
          className="customer-grading__attempt-evaluation-submission-overlay__button"
          onClick={AppNavigator.goBack}
          type="button"
        >
          <FormattedMessage {...intlMessages.goBack} />
        </button>
      </div>
    </div>
  )
};
