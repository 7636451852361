import {defineMessages} from 'react-intl';

export default defineMessages({
  duration: {
    defaultMessage: '{minutes, plural, one {# minute} other {# minutes}}',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.ChapterThumbnail',
  },
  warningMessage: {
    id: "CourseDetailPage.Overview.UncompletedChapter",
    defaultMessage:
      "In order to view the following chapters, all elements of the previous chapters need to be completed",
  },
});
