import React from 'react';
import {computed, observable, runInAction, action,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {get} from 'lodash';

import {FieldModel} from './FieldModel';
import {SubmissionError} from './SubmissionError';

import intlMessages from './intlMessages';

type NestedValue<T> = T | Nested<T>;

interface Nested<T> {
  [key: string]: NestedValue<T>;
}

interface IReactiveFormProps<ResultModel> {
  onSubmit: (data: ResultModel) => Promise<void>;
}

const TranslatedErrorMessage = (props: {generalError: {defaultMessage: string; id: string;}}) => <FormattedMessage {...props.generalError}/>

export abstract class ReactiveForm<PropTypes, ResultModel> extends React.Component<PropTypes & IReactiveFormProps<ResultModel>> {
  protected abstract formModel: { [key: string]: FieldModel<any> | Nested<FieldModel<any>>; };
  generalError?: React.ReactNode;
  private _submitSucceeded = false;
  private _submitting = false;

  constructor(props: PropTypes & IReactiveFormProps<ResultModel>) {
    super(props);

    makeObservable<ReactiveForm<PropTypes, ResultModel>, "_submitSucceeded" | "_submitting" | "handleSubmitEvent" | "generalError">(this, {
      generalError: observable,
      _submitSucceeded: observable,
      _submitting: observable,
      currentData: computed,
      dirty: computed,
      invalid: computed,
      pristine: computed,
      submitSucceeded: computed,
      submitting: computed,
      touched: computed,
      valid: computed,
      handleSubmit: action.bound,
      handleSubmitEvent: action.bound
    });
  }

  get currentData(): ResultModel {
    return this.resursiveObjectPropertiesMap(this.formModel, field => (field as FieldModel<any>).value) as ResultModel;
  }

  get dirty(): boolean {
    return !this.pristine;
  }

  get invalid(): boolean {
    return !this.valid;
  }

  get pristine(): boolean {
    let isPristine = true;

    this.resursiveObjectPropertiesMap(this.formModel, (field) => {
      if ((field as FieldModel<any>).dirty) {
        isPristine = false;
      }
    })

    return isPristine;
  }

  get submitSucceeded() {
    return this._submitSucceeded;
  }

  get submitting() {
    return this._submitting;
  }

  get touched(): boolean {
    let isTouched = false;

    this.resursiveObjectPropertiesMap(this.formModel, (field) => {
      if ((field as FieldModel<any>).touched) {
        isTouched = true;
      }
    })

    return isTouched;
  }

  get valid(): boolean {
    let isValid = true;

    this.resursiveObjectPropertiesMap(this.formModel, (field) => {
      if ((field as FieldModel<any>).invalid) {
        isValid = false;
      }
    })

    return isValid;
  }

  async handleSubmit() {
    this.resursiveObjectPropertiesMap(this.formModel, (field) => {
      (field as FieldModel<any>).validate();
    });
    
    if (this.invalid) {
      this.generalError = <TranslatedErrorMessage generalError={intlMessages.generalError}/>;
      return;
    }
    
    this._submitting = true;

    try {
      await this.props.onSubmit(this.currentData);

      runInAction(() => {
        this._submitSucceeded = true;
      });
    } catch (error) {
      if (error instanceof SubmissionError) {
        let castError = error as SubmissionError;

        runInAction(() => {
          if (castError.generalError) {
            this.generalError = castError.generalError;
          }

          if (castError.fieldsErrors) {
            Object.keys(castError.fieldsErrors).forEach((fieldName) => {
              const field: FieldModel<any> | undefined = get(this.formModel, fieldName);

              if (field) {
                field.setError(get(castError.fieldsErrors, fieldName));
              }
            });
          }
        });
      } else {
        runInAction(() => {
          this.generalError = (error as Error).toString();
        });
      }
    } finally {
      runInAction(() => {
        this._submitting = false;
      });
    }
  }

  protected handleSubmitEvent(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.handleSubmit();
  }

  private resursiveObjectPropertiesMap(target: Object, handler: Function): Object {
    const result = {};

    Object.keys(target).forEach((key) => {
      const property = target[key];

      if (property instanceof FieldModel) {
        result[key] = handler(property);
      } else {
        result[key] = this.resursiveObjectPropertiesMap(property, handler);
      }
    });

    return result;
  }

  protected abstract renderContent(): JSX.Element;

  render() {
    return (
      <form onSubmit={this.handleSubmitEvent}>
        {this.renderContent()}
      </form>
    );
  }
}
