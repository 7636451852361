import {defineMessages} from 'react-intl';

export default defineMessages({
  answersErrorRequired: {
    defaultMessage: 'Answer cannot be blank',
    id: 'Exercises.Forms.ExerciseForm.AnswerErrorRequired',
  },
  challengeAttemptCanNotBeChanged: {
    defaultMessage: 'Your challenge attempt cannot be changed while grading is in progress',
    id: 'Exercises.Forms.ExerciseForm.ChallengeAttemptChange'
  },
  criteriaHeading: {
    defaultMessage: 'Criteria',
    id: 'Exercises.Forms.ExerciseForm.CriteriaHeading',
  },
  filesErrorRequired: {
    defaultMessage: 'Files are required',
    id: 'Exercises.Forms.ExerciseForm.FilesErrorRequired',
  },
  taskNumberHeading: {
    defaultMessage: 'Task {current} of {total}',
    id: 'Exercises.Forms.ExerciseForm.TaskNumberHeading',
  },
  yourAnswer: {
    defaultMessage: 'Your answer',
    id: 'Exercises.Forms.ExerciseForm.YourAnswer',
  },
  yourAnswerPlaceholder: {
    defaultMessage: 'Please type your answer here..',
    id: 'Exercises.Forms.ExerciseForm.YourAnswerPlaceholder',
  },
});
