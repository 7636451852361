export const enum SectionTypeEnum {
  CHAPTER = 'CHAPTER',
  COMPARE_ME = 'COMPARE_ME',
  CURRICULUM = 'CURRICULUM',
  LOGIN = 'LOGIN',
  MYSKILLS = 'MYSKILLS',
  PARTNER_INFO = 'PARTNER_INFO',
  PROGRAM = 'PROGRAM',
  REGISTER = 'REGISTER',
  SKILL = 'SKILL',
  TRAILER = 'TRAILER',
};
