import FontAwesome from "react-fontawesome";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Spin } from "antd";
import { mainBlue } from "@u4i/styles/styles-variables";
import intlMessages from "./intlMessages";
import "./_pdf-download-button.scss";

interface IPropTypes {
  chapterTitle: string;
  url: string;
}

export const PDFDownloadButton: React.FC<IPropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const pdfDownload = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 7000);
  };

  return (
    <>
      {!isLoading && (
        <a
          className="player__pdf-download-button"
          download={`${props.chapterTitle} - Summary.pdf`}
          href={props.url}
          onClick={() => pdfDownload()}
        >
          <div className="player__pdf-download-button__icon">
            <FontAwesome name="file-pdf" />
          </div>

          <span className="player__pdf-download-button__text">
            <FormattedMessage {...intlMessages.text} />
          </span>
        </a>
      )}

      {isLoading &&
        <Spin style={{marginLeft: "53px", top: "-15px"}}>
          <span style={{
            color: mainBlue,
            fontSize:"9px",
            marginLeft: "103px",
            opacity: 0.9,
            paddingTop:"10px"
          }}>
            <FormattedMessage {...intlMessages.loading}/>
          </span>
        </Spin>
      }
    </>
  );
};
