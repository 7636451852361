import Parser from "@u4i/utils/Parser";
import { ICurriculum, ICurriculumItem } from "@u4i/common/Interfaces/CurriculumInterface";

export const CurriculumParser = new Parser<ICurriculum>((curriculumData) => {
  const curriculum: ICurriculum = {
    columns: curriculumData.columns,
    completedItems: 0,
    rows: curriculumData.rows.map((row) => ({
      id: btoa(unescape(encodeURIComponent(row.label))),
      items: row.items.map((skillsList) =>
        skillsList.map((skill) => {
          const skillProgressPercentage: number = parseFloat((skill.progress / 100).toFixed(2));

          return {
            id: skill.id,
            isBought: skill.is_bought,
            isCompleted: skill.is_completed,
            isPublished: skill.is_published,
            numberOfChallenges: skill.number_of_challenges,
            numberOfCompletedChallenges: skill.number_of_completed_challenges,
            skillProgressPercentage,
            skillProgressStatus: skill.status,
            title: skill.title,
            image: skill.image.url,
            slug: skill.slug,
            language: skill.language
          };
        })
      ),
      label: row.label
    })),
    totalItems: 0
  };

  curriculum.rows
    .map((row) => row.items)
    .forEach((cellSkills: ICurriculumItem[][]) => {
      cellSkills.forEach((cellSkill: ICurriculumItem[]) => {
        cellSkill.forEach((skill: ICurriculumItem) => {
          if(skill.isPublished) {
            curriculum.totalItems++;
          }          
          if(skill.isCompleted) {
            curriculum.completedItems++;
          }
        });
      });
    });

  return curriculum;
});
