import axios from 'axios';
import React, {useEffect, useState} from 'react';

interface VersionListProps {
  /**
   * defaults to div#version-list > div.version-list-item{version-text} * versions.length
   */
  transform?: typeof defaultTransform;
}

function fetchVersionText(): Promise<string> {
  return axios('/version.txt')
    .then(r => (r.status === 200 ? r.data : ""))
    .then(t => (/doctype html/i.test(t) ? '' : t));
}

function defaultTransform(versions: string[]) {
  if (!versions.length) {
    return null;
  }

  return (
    <div id="version-list" className="version-list">
      {versions.map(v => <div className="version-list-item" key={v}>{v}</div>)}
    </div>
  );
}

const VersionList: React.FC<VersionListProps> = (props) => {
  const [versionList, setVersionList] = useState(defaultTransform([]));

  useEffect(() => {
    fetchVersionText()
      .then((versionText) => {
        const parsed = versionText.split('\n').filter(v => v);
        const transformed = props.transform ? props.transform(parsed) : defaultTransform(parsed);
        setVersionList(transformed);
      });
  }, []);

  return versionList;
};

export default VersionList;
