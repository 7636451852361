import Parser from '@u4i/utils/Parser';

export interface ITermsOfService {
  checkboxLabel: string;
  content: string;
  description: string;
  id: string;
  isMandatory: boolean;
}

export const TermsOfServiceParser = new Parser<ITermsOfService>(termData => ({
  checkboxLabel: termData.acceptance_title,
  content: termData.content,
  description: termData.description,
  id: termData.id,
  isMandatory: termData.is_mandatory,
}));
