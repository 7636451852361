import {defineMessages} from 'react-intl';

export default defineMessages({
  goBack: {
    defaultMessage: 'Go back',
    id: 'Exercises.SubmissionOverlay.GoBack',
  },
  submitted: {
    defaultMessage: 'Submit successful!',
    id: 'Exercises.SubmissionOverlay.Submitted',
  },
  submitting: {
    defaultMessage: 'Submitting your answers',
    id: 'Exercises.SubmissionOverlay.Submitting',
  },
});
