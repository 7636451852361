/**
 * @author Pradeep Gill pradeep.gill@u4i.io
 */
import React, { useEffect, FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { toJS } from "mobx";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { FormattedMessage } from 'react-intl';

import { IRootStore } from "@u4i/state/RootStore";
import { LabSelectBox } from "./LabSelectBox";
import { BookingConfirmation } from "./BookingConfirmation";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { BackButton } from "@u4i/modules/CourseDetailPage/components/BackButton/BackButton";
import intlMessages from "./intlMessages";
import "./_booking.scss";

interface IPropTypes extends RouteComponentProps {
  rootStore: IRootStore;
  courseId: string;
}

export const BookingDigitalLab: FunctionComponent<IPropTypes> = inject(
  "rootStore"
)(
  observer((props) => {
    const {
      availableLabs,
      bookingSlotValidation,
      calendarLoader,
      isBookingConfirmationVisible,
      eventStartTime,
      eventEndTime,
      isBookingLoader,
      selectedLabRestTime,
      isDeleteConfirmationVisible,
      deleteSlot,
      initCalendar,
      onChangeLab,
      bookSlot,
      getSetCalendarEvent,
      hideBookingConformationModal,
      filterDLBySearchValue,
      onBlurDLSelectBox
    } = props.rootStore.digitalLabBookingStore;

    useEffect(() => {
      const courseId = props.match.params["courseId"];
      initCalendar(courseId);

      let apiInterval = setInterval(async () => {
        await getSetCalendarEvent(
          bookingSlotValidation.selectedLabId as string
        );
      }, 60000);

      return () => clearInterval(apiInterval);
    }, []);

    return (
      <div className="App">
        <BackButton
          onClick={() => props.history.goBack()}
          showButtonOnly
        />
        <Row gutter={8} align="middle">
          <Col span={4} className="selectLabText">
            <FormattedMessage {...intlMessages.selectDigitalLab} />
          </Col>
          <Col span={16}>
            <LabSelectBox
              selectedLabId={bookingSlotValidation.selectedLabId}
              availableLabs={toJS(availableLabs)}
              onChangeLab={onChangeLab}
              filterDLBySearchValue={filterDLBySearchValue}
              onBlurDLSelectBox={onBlurDLSelectBox}
            />
          </Col>
          <Col span={4}>
            {calendarLoader && (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            )}
          </Col>
        </Row>
        <div id="calendarWrapper" className="calendarWrapper"></div>
        <BookingConfirmation
          isBookingConfirmationVisible={isBookingConfirmationVisible}
          isBookingLoader={isBookingLoader}
          bookSlot={bookSlot}
          hideBookingConformationModal={hideBookingConformationModal}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          selectedLabRestTime={selectedLabRestTime}
        />
        <DeleteConfirmation
          isDeleteConfirmationVisible={isDeleteConfirmationVisible}
          hideBookingConformationModal={hideBookingConformationModal}
          isBookingLoader={isBookingLoader}
          deleteSlot={deleteSlot}
        />
      </div>
    );
  })
);
