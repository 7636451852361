import { ColumnType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";

export const DEFAULT_PAGE_SIZE = 10;

export interface AntDTableFilters<T> {
  current?: number
  filters?: {
    [columnName in keyof T]?: T[columnName];
  };
  limit?: number;
  offset?: number;
  orderBy?: {
    [columnName in keyof T]?: T[columnName];
  };
}

export interface AntDPagination {
  current: number 
  pageSize: number 
  showSizeChanger?: boolean
  total: number
}

export interface AntDSorter<T> {
  column?: ColumnType<T>,
  columnKey?: keyof T,
  field?: keyof T,
  order?: SortOrder
}

export interface AntDTableConfigs<T> {
  pagination: {
    current: number 
    pageSize: number 
    showSizeChanger: boolean
    total: number
  },
  filters: {[columnName in keyof T]?: T[columnName]},
  sorter: {
    column?: ColumnType<T>
    columnKey?: keyof T
    field?: keyof T
    order?: SortOrder
  }
}