import {defineMessages} from 'react-intl';

export default defineMessages({
  completed: {
    defaultMessage: 'All challenges completed',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.ChapterInfo.Completed',
  },
  gradingInProgress: {
    defaultMessage: 'Grading in progress',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.ChapterInfo.GradingInProgress',
  },
  unfinished: {
    defaultMessage: 'Unfinished challenges',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.ChapterInfo.Unfinished',
  },
});
