import React, { FC, Fragment, useState } from "react";
import { Alert } from "@u4i/common/Alert";
import { AlertStatusEnum } from "@u4i/common/enums/AlertStatusEnum";
import { Checkbox, Form } from "antd";
import { RedeemTermsOfServiceModal } from "../RedeemTermsOfServiceModal/RedeemTermsOfServiceModal";
import { injectIntl, WrappedComponentProps } from "react-intl";
import intlMessages from "./intlMessages";
import "./_redeem-additional-terms-of-service.scss";

interface IPropTypes extends WrappedComponentProps {
  additionalTerms: Array<any>;
  intl: any;
  onCheckboxChange: (checked: boolean) => void;
}

const RedeemAdditionalTermsOfService: FC<IPropTypes> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { additionalTerms, intl, onCheckboxChange } = props;

  const { formatMessage } = intl;
  const termsPresenceError: string = formatMessage(intlMessages.presenceError);

  const handleAccept = () => {
    setIsModalVisible(false);
    onCheckboxChange(true);
  };

  return (
    <Fragment>
      {additionalTerms?.length > 0 && (
        <>
          <Alert
            message={additionalTerms[0].description}
            type={AlertStatusEnum.INFO}
          />

          <div className="redeem-additional-terms-of-service__checkbox-section">
            <Form.Item
              className="disable-invert"
              name="group-terms"
              valuePropName="checked"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (!additionalTerms[0].is_mandatory) {
                      return Promise.resolve();
                    } else if (!value || value == false) {
                      return Promise.reject(termsPresenceError);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox
                autoFocus
                name="group-terms"
                onChange={(ev) => onCheckboxChange(ev.target.checked)}
                style={{ marginRight: 10 }}
              >
                <a
                  className="redeem-additional-terms-of-service__checkbox-section__label"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalVisible(true);
                  }}
                  style={{ marginTop: 5 }}
                >
                  {additionalTerms[0].acceptance_title}
                </a>
              </Checkbox>
            </Form.Item>
          </div>

          <RedeemTermsOfServiceModal
            content={additionalTerms[0]?.content}
            handleModalAccept={handleAccept}
            handleModalClose={() => setIsModalVisible(false)}
            isVisible={isModalVisible}
          />
        </>
      )}
    </Fragment>
  );
};

export default injectIntl(RedeemAdditionalTermsOfService);
