import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Radio, RadioChangeEvent, Row, Select, Space } from 'antd';
import { iron } from '@u4i/styles/styles-variables';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Paragraph from 'antd/es/typography/Paragraph';
import intlMessages from '../intlMessages';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';
import cx from "classnames";
import { QuestionTypeEnum } from '@u4i/modules/Admin/modules/AssesmentHub/state/AssessmentHubInterfaces';
import '../_mcq-test-element.scss';

const { Option } = Select;

interface IProps {
  test: any
  onTestFinish: (answers: any) => void
}

interface Answers {
  [key: number]: string[] | null;
}

const TestQuestionnaire: FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState<Answers | any>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<any>(0);
  const [selectedMatchingAnswers, setSelectedMatchingAnswers] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const currentQuestion: any = props.test.questions[currentQuestionIndex];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) { // left arrow key
        if (currentQuestionIndex > 0) {
          handlePrev();
        }
      } else if (event.keyCode === 39) { // right arrow key
        if (currentQuestion.type !== QuestionTypeEnum.MatchingQuestion && !(currentQuestionIndex === props.test.questions.length - 1 || !isQuesSelected(currentQuestion?.id))) {
          handleNext();
          return;
        } else if(currentQuestion.type === QuestionTypeEnum.MatchingQuestion && currentQuestion.clues.length == (Object.keys(selectedMatchingAnswers[currentQuestion.id] || 0)?.length)) {
          handleNext();
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentQuestionIndex, answers, selectedMatchingAnswers]);

  const onFinish = () => {
    setLoading(true);
    const concatenatedObj = { ...answers, ...selectedMatchingAnswers };
    props.onTestFinish(concatenatedObj);
  };

  const handleSingleChange = (id: number, e: RadioChangeEvent) => {
    setAnswers({ ...answers, [id]: e.target.value });
  };

  const handleMultipleChange = (id: number, values: CheckboxChangeEvent) => {
    const newValue: string = values.target.value;
    const isChecked: boolean = values.target.checked;

    // If answers[id] doesn't exist, create a new array
    const existingValues = answers[id] || [];

    if (existingValues.length >= currentQuestion.number_of_correct_answers && isChecked) {
      return;
    }
    // If the checkbox is checked, add the new value to the array
    // Otherwise, remove the value from the array
    const newValues = isChecked ? [...existingValues, newValue] : existingValues.filter((value) => value !== newValue);

    setAnswers({ ...answers, [id]: newValues });
  };

  const isQuesSelected = (queId: number) => {
    const currentAnswer = answers[queId];

    if (currentAnswer == undefined || currentAnswer?.length == 0)
      return false;
    return true;
  }

  const isCheckboxChecked = (queId: number, answId: number) => {
    const currentAnswer = answers[queId];

    if (currentAnswer?.includes(answId))
      return true;
    return false;
  }

  const handleNext = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handlePrev = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleMatchingAnswerChange = (clueId: string, answerId: string) => {
    setSelectedMatchingAnswers(prevState => {
      const updatedAnswers = { ...prevState };

      if (!updatedAnswers[currentQuestion.id]) {
        updatedAnswers[currentQuestion.id] = { [clueId]: answerId };
      } else {
        updatedAnswers[currentQuestion.id] = {
          ...updatedAnswers[currentQuestion.id],
          [clueId]: answerId,
        };
      }

      return updatedAnswers;
    });

  };

  const renderQuestion = (question: any, passmark: any) => {
    const { id, type, answers, clues } = question;

    return (
      <Card
        title={
          <Row justify="space-between">
            <Title level={4} style={{ margin: 0 }}
              className={cx({
                card_title_mcq: answers.length > 4,
              })}>
              <FormattedMessage
                {...intlMessages.questionNumber}
                values={{ current: currentQuestionIndex + 1, total: props.test.questions.length }}
              />
            </Title>
            <Paragraph style={{
              margin: 0
            }}>Pass mark: {props.test.passmark}%</Paragraph>
          </Row>
        }
        headStyle={{ backgroundColor: iron }}
        style={{ width: '100%' }}
        bodyStyle={{ padding: '5px 20px' }}
      >
        <Form.Item style={{ marginBottom: 5 }}>
          <Title level={5} className={cx({
            question_title_mcq: answers.length > 4,
          })} >
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.content) }}></div>
          </Title>

          {type === QuestionTypeEnum.SingleCorrectAnswer ? (
            <div className={cx({
              mcq_vertical_direction_default: true,
              mcq_vertical_direction: answers.length > 4,
            })} style={{ paddingLeft: 5 }}>
              {answers.map((option: any) => (
                <Radio
                  className={cx({
                    mcq_answer_select_box: true,
                    mcq_answer_select_box_small: answers.length > 4
                  })}
                  onChange={(e) => handleSingleChange(id, e)}
                  checked={isCheckboxChecked(id, option.id)}
                  key={option.id}
                  value={option.id}
                >
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.content) }}></div>
                </Radio>
              ))}
            </div>
          ) : type === QuestionTypeEnum.MultipleCorrectAnswer ? (
            <div  className={cx({
              mcq_vertical_direction_default: true,
              mcq_vertical_direction: answers.length > 4,
            })} style={{ paddingLeft: 5 }}>
              {answers.map((option: any) => (
                <Checkbox
                  className={cx({
                    mcq_answer_select_box: true,
                    mcq_answer_select_box_small: answers.length > 4
                  })}
                  onChange={(e) => handleMultipleChange(id, e)}
                  checked={isCheckboxChecked(id, option.id)}
                  key={option.id}
                  value={option.id}
                >
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.content) }}></div>
                </Checkbox>
              ))}
            </div>
          ) : (   // Matching questions are handled here.
            <>
              {clues.map((clue: any) => (
                <Row key={clue.id}>
                  <Col span="18">
                    <p>{clue.content}</p>
                  </Col>

                  <Col span="6">
                    <Select
                      value={selectedMatchingAnswers[currentQuestion.id]?.[clue.id]}
                      style={{ width: '100%' }}
                      onChange={(answerId) => handleMatchingAnswerChange(clue.id, answerId)}
                    >
                      {answers.map(answer => (
                        <Option key={answer.id} value={answer.id}>
                          {answer.content}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.Item>
      </Card>
    );
  };

  return (
    <Form
      className='mcq-test-form'
      form={form}
      onFinish={onFinish}
    >
      <div className='mcq-test-description-section'>
        <Title level={5} style={{ textAlign: 'justify', margin: 0 }}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.test.description) }}></div>
        </Title>

      </div>

      {currentQuestion && renderQuestion(currentQuestion, props.test.passmark)}

      <Form.Item className='button_container'>
        <Row justify="space-between" >
          <Button
            className='mcq-navigation__button'
            disabled={currentQuestionIndex === 0}
            onClick={handlePrev}
            type='primary'
          >
            <ArrowLeftOutlined />
            <FormattedMessage {...intlMessages.previous} />
          </Button>
          <Button
            className='mcq-submit-button'
            disabled={(currentQuestionIndex !== props.test.questions.length - 1) ||
              (currentQuestion.type !== QuestionTypeEnum.MatchingQuestion && currentQuestionIndex === props.test.questions.length - 1 && answers[currentQuestion.id] == undefined || answers[currentQuestion.id]?.length == 0) ||
              (currentQuestion.type === QuestionTypeEnum.MatchingQuestion && currentQuestionIndex == props.test.questions.length - 1 && currentQuestion.clues.length != (Object.keys(selectedMatchingAnswers[currentQuestion.id] || 0)?.length)) ||
              loading
            }
            loading={loading}
            htmlType='submit'
            type='primary'
          >
            <CheckOutlined />
            <FormattedMessage {...intlMessages.finishNow} />
          </Button>
          <Button
            className='mcq-navigation__button'
            disabled={currentQuestionIndex === props.test.questions.length - 1 ||
              (currentQuestion.type !== QuestionTypeEnum.MatchingQuestion && !isQuesSelected(currentQuestion?.id)) ||
              (currentQuestion.type === QuestionTypeEnum.MatchingQuestion && currentQuestion.clues.length != (Object.keys(selectedMatchingAnswers[currentQuestion.id] || 0)?.length))
            }
            onClick={handleNext}
            type='primary'
          >
            <FormattedMessage {...intlMessages.next} />
            <ArrowRightOutlined />
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}


export default TestQuestionnaire;
