import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import {PLAYER_FONT_SIZE_SCALING_FACTOR} from '@u4i/constantSettings';

import './_with-calculated-font-size.scss';

interface IState {
  fontSize: number;
}

export const withCalculatedFontSize = <IPropTypes extends object>(TargetComponent: React.ComponentType<IPropTypes>) =>
  class extends React.Component<IPropTypes, IState> {
    state = {
      fontSize: 0,
    }

    private containerRef? = React.createRef<HTMLDivElement>();
    private sizeObserver?: ResizeObserver;

    componentDidMount() {
      this.recalculateFontSize();

      this.sizeObserver = new ResizeObserver(this.recalculateFontSize);
    
      if (this.containerRef && this.containerRef.current) {
        this.sizeObserver.observe(this.containerRef.current);
      }
    }

    componentWillUnmount() {
      if (this.sizeObserver) {
        this.sizeObserver.disconnect();
      }
    }

    recalculateFontSize = () => {
      if (this.containerRef && this.containerRef.current) {
        const containerBoundingRect = this.containerRef.current.getBoundingClientRect();
        const viewportHeight = containerBoundingRect.height;
        const viewportWidth = containerBoundingRect.width;
  
        const xBlocks = viewportWidth / 16;
        const yBlocks = viewportHeight / 9;
  
        const containerHeight = xBlocks > yBlocks ? viewportHeight : viewportWidth * (9 / 16);
        const containerWidth = xBlocks > yBlocks ? containerHeight * (16 / 9) : viewportWidth;
  
        const widthContribution = containerWidth / (PLAYER_FONT_SIZE_SCALING_FACTOR * 16);
        const heightContribution = containerHeight / (PLAYER_FONT_SIZE_SCALING_FACTOR * 9);
  
        const calculatedFontSize = widthContribution + heightContribution;
  
        this.setState({fontSize: calculatedFontSize});
      }
    };

    render() {
      return (
        <div className="player__with-calculated-font-size" ref={this.containerRef}>
          <TargetComponent calculatedFontSize={this.state.fontSize} {...this.props} />
        </div>
      );
    }
  };
