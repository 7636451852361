import React, {Component, Fragment} from 'react';
import {animateScroll} from 'react-scroll';
import {computed,makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import { withRouter} from 'react-router-dom';
import { RouteComponentProps } from "@reach/router";
import SettingsStore from '@u4i/state/SettingsStore';
import UserStore from '@u4i/state/UserStore';
import {IRootStore} from '@u4i/state/RootStore';
import Navbar from './Navbar';
import NotificationsStore from '@u4i/state/NotificationsStore';
import Footer from './Footer/Footer';
import ImpersonationPanel from './ImpersonationPanel/ImpersonationPanel';

import './_main-layout.scss';

interface IPropTypes extends RouteComponentProps {
  children: React.ReactChildren;
  rootStore: IRootStore;
}

const MainLayout = inject('rootStore')(observer(class MainLayout extends Component<IPropTypes | any> {
  private settingsStore: SettingsStore;
  private userStore: UserStore;
  private notificationsStore: NotificationsStore;

  constructor(props) {
    super(props);

    makeObservable(this, {
      appLoadingFinished: computed
    });

    this.settingsStore = this.props.rootStore.settingsStore;
    this.userStore = this.props.rootStore.userStore;
    this.notificationsStore = this.props.rootStore.notificationsStore;
  }

  componentDidMount() {
    animateScroll.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.pathname !== prevProps.location.pathname) {
      animateScroll.scrollToTop();
      this.notificationsStore.updateUnreadCount();
    }
  }

  get appLoadingFinished() {
    return !this.settingsStore.fetching && !this.userStore.isLoading;
  }

  render() {
    return (
      <Fragment>
        <Navbar />
        <div className="main-layout__content">
          {this.userStore.userData.impersonated &&
            <ImpersonationPanel userStore={this.props.rootStore.userStore}/>
          }

          <div className="main-layout__page" style={{
            padding:window.location.href.split('/').includes('administration3') ? 0 : 24
          }}>
            {this.appLoadingFinished && this.props.children}
          </div>
          <Footer rootStore={this.props.rootStore}/>
        </div>
      </Fragment>
    );
  }
}));

export default withRouter(MainLayout);
