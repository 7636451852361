import { frenchGray, mainBlue, white } from '@u4i/styles/styles-variables';
import React, { FC } from 'react';

interface IProps {
  x: number
  y: number
  onToggleClick: () => void
}

export const ToggleOn: FC<IProps> = (props: IProps) => {
  const {
    x,
    y,
    onToggleClick
  } = props;

  const pathD: string = `m ${x},${y} h 19.793542 c 9.573347,0.362584 11.53125,16.707922 0,18.481517 h -19.793542 c -8.5476147,-3.975691 -8.7181164,-14.92537 0,-18.481517 z`;

  return (
    <svg>
      <g
        fillOpacity={1}
        onClick={onToggleClick}
        strokeOpacity={1}
        style={{ cursor: "pointer" }}
      >
        <path
          fill={mainBlue}
          strokeWidth={0.4}
          d={pathD}
        />
        <circle
          cx={x+21.5}
          cy={y+9}
          r={8.95}
          fill={white}
          stroke={frenchGray}
        />
      </g>
    </svg>
  )
}
