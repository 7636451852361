import DOMPurify from 'dompurify';

export default function prepareContent(content) {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    const childNode = node.children[0];

    if (childNode && childNode.getAttribute('target') && childNode.getAttribute('target') === '_blank') {
      childNode.setAttribute('rel', 'noreferrer noopener');
    }
  });

  const purifiedContent = DOMPurify.sanitize(content, {ADD_ATTR: ['target']});

  return purifiedContent;
}
