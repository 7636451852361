import React from 'react';
import {autorun, IReactionDisposer} from 'mobx';
import {inject, observer} from 'mobx-react';
import {IRootStore} from '@u4i/state/RootStore';
import {OnboardingSequenceEnum} from '@u4i/common/enums/OnboardingSequenceEnum';
import {OnboardingStore} from '@u4i/state/OnboardingStore';
import Tour from 'shepherd.js/src/types/tour';

interface IPropTypes {
  onboardingSequence: OnboardingSequenceEnum;
  rootStore?: IRootStore;
}

export const OnboardingTour = inject('rootStore')(observer(class OnboardingTour extends React.Component<any> {
  private onboardingStore: OnboardingStore;
  private visibilityCheckDisposer?: IReactionDisposer;

  constructor(props: IPropTypes) {
    super(props);

    this.onboardingStore = props.rootStore?.onboardingStore!;
  }

  componentDidMount() {
    this.visibilityCheckDisposer = autorun(this.checkIfVisible);
  }

  componentWillUnmount() {
    const sequence: Tour | undefined = this.onboardingStore.tours.get(this.props.onboardingSequence);
    
    if (this.visibilityCheckDisposer) { 
      this.visibilityCheckDisposer(); 
    }

    if (sequence) { 
      sequence.cancel(); 
    }
  }

  checkIfVisible = () => {
    if (!this.onboardingStore.shouldOnboardingRender)
      return;

    const {onboardingSequence}:any = this.props;

    if (!this.onboardingStore.onboardingProgress[onboardingSequence]) {
      const sequence: Tour | undefined = this.onboardingStore.tours.get(onboardingSequence);

      if (sequence) { 
        sequence.start(); 
      }
    } 
    else {
      const sequence: Tour | undefined = this.onboardingStore.tours.get(onboardingSequence);

      if (sequence) {
        sequence.cancel(); 
      }
    }
  };

  render() {
    return null;
  }
}));
