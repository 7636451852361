import cx from 'classnames';
import React from 'react';

import {ICustomFieldProps} from '../../Field';

import './_textarea-field.scss';

export interface IPropTypes extends ICustomFieldProps<string> {
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
}

export class TextareaField extends React.Component<IPropTypes> {
  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <div
        className={cx({
          'reactive-forms__fields__textarea': true,
          'reactive-forms__fields__textarea--dirty': this.props.meta.dirty,
          'reactive-forms__fields__textarea--focused': this.props.meta.focused,
          'reactive-forms__fields__textarea--invalid': this.props.meta.touched && this.props.meta.error,
        })}
      >
        <textarea
          className="reactive-forms__fields__textarea__input"
          disabled={this.props.disabled}
          id={this.props.id}
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          onFocus={this.props.onFocus}
          value={this.props.value}
        />
    
        <span className="reactive-forms__fields__textarea__placeholder">
          {this.props.placeholder}
        </span>
    
        {this.props.label &&
          <label className="reactive-forms__fields__textarea__label" htmlFor={this.props.id}>
            {this.props.label}
          </label>
        }
    
        {(this.props.meta.touched && this.props.meta.error) &&
          <div className="reactive-forms__fields__textarea__error">
            {this.props.meta.error}
          </div>
        }
      </div>
    );
  }
}
