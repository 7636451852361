import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Alert, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import intlMessages from "./intlMessages";
import { LabBookingButton } from "./LabBookingBtn/LabBookingBtn";
import "./_overview.scss";

interface IProps {
  description: string
  isBought: boolean
  courseId: string
  hasDigitalLabsWithScheduler: boolean
}

export const Overview: FC<IProps> = (props: IProps) => {
  const {
    description,
    isBought,
    courseId,
    hasDigitalLabsWithScheduler
  } = props;

  return (
    <div className="container">
      <div
        className={cx({
          "course-detail-page__overview": true,
          "course-detail-page__overview--with-certification-status": isBought,
        })}
      >
        <h3 className="course-detail-page__overview__heading">
          <FormattedMessage {...intlMessages.heading} />
        </h3>
        
        <Typography.Paragraph type="secondary" style={{
          fontSize:16
        }} className="disable-invert" >
          {description}
        </Typography.Paragraph>
        {hasDigitalLabsWithScheduler && isBought && (
          <Alert
            message={
              <span style={{
                fontSize: "19px"
              }}>
              <FormattedMessage {...intlMessages.digitalLabBookingMessage} />
              </span>
            }
            description={
              <div style={{
                marginRight: "46px"
              }}>
              <FormattedMessage
                {...intlMessages.digitalLabDescription}
                values={{ br: <br /> }}
              />
              </div>
            }
            type="info"
            showIcon
            icon={
              <InfoCircleOutlined
                style={{
                  fontSize: "32px",
                  margin: "0 25px 0 10px"
                }}
              />
            }
            action={<LabBookingButton courseId={courseId} />}
            style={{
              alignItems: "center",
              borderRadius: "5px",
              borderStyle: "dashed",
              marginTop: "40px",
              padding: "15px"
            }}
          />
        )}
      </div>
    </div>
  )
}
