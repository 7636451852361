export default function getTextsMeasurements(texts, classes) {
  const measurementContainer = document.createElement('div');

  measurementContainer.className = classes;
  measurementContainer.style.height = 'auto';
  measurementContainer.style.position = 'absolute';
  measurementContainer.style.visibility = 'hidden';
  measurementContainer.style.whiteSpace = 'nowrap';
  measurementContainer.style.width = 'auto';

  document.body.appendChild(measurementContainer);

  const measurements = texts.map((entry) => {
    measurementContainer.innerHTML = entry;

    return {
      height: measurementContainer.offsetHeight,
      width: measurementContainer.offsetWidth,
    };
  });

  document.body.removeChild(measurementContainer);

  return measurements;
}
