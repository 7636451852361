import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {range} from 'lodash';
import flaskIcon from '@u4i/assets/svg/flask.svg';

import intlMessages from './intlMessages';
import './_lab-intro.scss';

interface IPropTypes {
  isVisible: boolean
  onLoadingTransitionEnd: (event: React.TransitionEvent<HTMLDivElement>) => void
}

export const LabIntro: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="digital-lab__lab-intro">
    <div
      className={cx({
        'digital-lab__lab-intro__icon-wrapper': true,
        'digital-lab__lab-intro__icon-wrapper--move': props.isVisible,
      })}
    >
      <img alt="" className="digital-lab__lab-intro__icon" src={flaskIcon} />

      <div
        className={cx({
          'digital-lab__lab-intro__spinner': true,
          'digital-lab__lab-intro__spinner--visible': props.isVisible,
        })}
        onTransitionEnd={props.onLoadingTransitionEnd}
      >
        {range(12).map(value => (
          <div
            className={cx({
              'digital-lab__lab-intro__spinner-element': true,
              [`digital-lab__lab-intro__spinner-element-${value + 1}`]: true,
            })}
            key={value}
          />
        ))}
      </div>

      <p
        className={cx({
          'digital-lab__lab-intro__loading-info': true,
          'digital-lab__lab-intro__loading-info--visible': props.isVisible,
        })}
      >
        <FormattedMessage {...intlMessages.loadingMessage} />
      </p>
    </div>
  </div>
);
