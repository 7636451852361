import {action, computed, observable, runInAction,makeObservable} from 'mobx';
import {IAdminUserGroupDetailed, IGroupTermsOfService, ILinkedCustomPage} from '@u4i/parsers/admin/AdminUserGroupParser';
import {IApi} from '@u4i/state/ServicesInterfaces';
import {IProofOfQualification, IProofOfQualificationPreview} from '@u4i/parsers/admin/AdminProofOfQualificationParser';
import {IRootStore} from '@u4i/state/RootStore';
import {UploadFile} from 'antd/lib/upload/interface';
import IEntity from '../IEntity';
import { UserGroupEditFormData } from '@u4i/modules/Admin/modules/UserGroups/interfaces/AdminUserGroupInterface';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';

export class AdminUserGroupEntity implements IEntity<IAdminUserGroupDetailed> {
  anonymity: {
    id: number,
    name: string
  };
  collaborationCodes: Array<string>;
  customerGrading: boolean;
  defaultSkills: Array<{
    id: string,
    name: string,
  }> = [];
  description: string;
  discountPercentage: number;
  enableDirectLinkForExternalContent: boolean;
  enableEmailNotification: boolean;
  enableOnboardingTour: boolean;
  enableSkillCertificates: boolean;
  enforceAcceptanceOfU4IPrivacyPolicyOnUpdate: boolean;
  graders: Array<{
    email: string,
    firstName: string,
    id: string,
    lastName: string,
    username: string,
  }> = [];
  groupTermsOfService: IGroupTermsOfService;
  id: string;
  linkedCustomPages?: Array<ILinkedCustomPage>;
  mailDomains: Array<string>;
  name: string;
  navigationTabs: {
    discover: {
      id: string,
      isVisible: boolean,
      name: string,
    },
    home: {
      id: string,
      isVisible: boolean,
      name: string,
    },
  };
  playerDisclaimerDE;
  playerDisclaimerEN;
  proofOfQualificationSettings: {
    headerDE: string,
    headerEN: string,
    logoFile: string;
    logoFileList: Array<UploadFile>;
    signatureFile: string;
    signatureFileList: Array<UploadFile>;
    signatureFirstLine: string,
    signatureSecondLine: string,
  };
  fetchingEditedUserGroup = false;
  fetchingEditedUserGroupError = false;
  private apiService: IApi;
  private rootStore: IRootStore;

  constructor(adminUserGroupData: IAdminUserGroupDetailed, rootStore: IRootStore) {
    makeObservable(this, {
      anonymity: observable,
      customerGrading: observable,
      collaborationCodes: observable,
      defaultSkills: observable,
      description: observable,
      discountPercentage: observable,
      enableDirectLinkForExternalContent: observable,
      enableEmailNotification: observable,
      enableOnboardingTour: observable,
      enableSkillCertificates: observable,
      enforceAcceptanceOfU4IPrivacyPolicyOnUpdate: observable,
      graders: observable,
      groupTermsOfService: observable,
      id: observable,
      linkedCustomPages: observable,
      mailDomains: observable,
      name: observable,
      navigationTabs: observable,
      playerDisclaimerDE: observable,
      playerDisclaimerEN: observable,
      proofOfQualificationSettings: observable,
      fetchingEditedUserGroup: observable,
      fetchingEditedUserGroupError: observable,
      serializedData: computed,
      applyDataChanges: action.bound,
      updateUserGroup: action.bound,
      updateProofOfQualification: action.bound
    });

    const {apiService} = rootStore;

    this.apiService = apiService;
    this.rootStore = rootStore;

    this.applyDataChanges(adminUserGroupData);
  }

  get serializedData(): IAdminUserGroupDetailed {
    const {
      anonymity, 
      collaborationCodes, 
      customerGrading, 
      defaultSkills, 
      description, 
      discountPercentage, 
      enableDirectLinkForExternalContent,
      enableEmailNotification, 
      enableOnboardingTour,
      enableSkillCertificates, 
      enforceAcceptanceOfU4IPrivacyPolicyOnUpdate,
      graders, 
      groupTermsOfService, 
      id, 
      linkedCustomPages, 
      mailDomains, 
      name, 
      navigationTabs, 
      playerDisclaimerDE, 
      playerDisclaimerEN,
      proofOfQualificationSettings
    } = this;

    return {
      anonymity, 
      collaborationCodes, 
      customerGrading, 
      defaultSkills, 
      description, 
      discountPercentage, 
      enableDirectLinkForExternalContent,
      enableEmailNotification, 
      enableOnboardingTour, 
      enableSkillCertificates, 
      enforceAcceptanceOfU4IPrivacyPolicyOnUpdate,
      graders, 
      groupTermsOfService, 
      id, 
      linkedCustomPages, 
      mailDomains, 
      name, 
      navigationTabs, 
      playerDisclaimerDE, 
      playerDisclaimerEN,
      proofOfQualificationSettings
    };
  }

  applyDataChanges(dataChanges: Partial<any>) {
    Object.assign(this, dataChanges);
  }

  async updateUserGroup(partialDataChanges: Partial<UserGroupEditFormData>) {
    const snapshot: IAdminUserGroupDetailed = this.serializedData;

    this.applyDataChanges(partialDataChanges);

    const updatedUserGroupData = {...partialDataChanges};

    this.fetchingEditedUserGroup = true;
    this.fetchingEditedUserGroupError = false;

    try {
      const updatedAdminUserGroup: IAdminUserGroupDetailed = await this.apiService.admin.userGroups.updateUserGroup(this.id, updatedUserGroupData);

      this.applyDataChanges(updatedAdminUserGroup);

      Notification(NotificationEnum.Success, "Group update", `Group \"${snapshot.name}\" updated.`);
    } catch(error) {
      this.applyDataChanges(snapshot);

      this.fetchingEditedUserGroupError = true;

      const editUserGroupError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "Group update", editUserGroupError);
    } finally {
      this.fetchingEditedUserGroup = false;
    }
  }

  async updateProofOfQualification(partialDataChanges: IProofOfQualification) {
    const snapshot: IAdminUserGroupDetailed = this.serializedData;
    let {signatureFirstLine, signatureSecondLine} = partialDataChanges;

    if (signatureFirstLine) {
      signatureFirstLine = signatureFirstLine.replace(/\|/g, '');
    }

    if (signatureSecondLine) {
      signatureSecondLine = signatureSecondLine.replace(/\|/g, '');
    }

    const proofOfQualificationSettings = {
      ...partialDataChanges,
      signatureFirstLine: signatureFirstLine,
      signatureSecondLine: signatureSecondLine,
    }

    const dataChanges = {
      ...snapshot,
      proofOfQualificationSettings: proofOfQualificationSettings,
    };

    this.applyDataChanges(dataChanges);

    const updatedUserGroupData = {...proofOfQualificationSettings};

    this.fetchingEditedUserGroup = true;
    this.fetchingEditedUserGroupError = false;

    try {
      const updatedAdminUserGroup: IProofOfQualificationPreview = await this.apiService.admin.userGroups.updateProofOfQualificationSettings(this.id, updatedUserGroupData);

      const updatedDataChanges = {
        ...snapshot,
        proofOfQualificationSettings: updatedAdminUserGroup,
      }

      runInAction(() => {
        this.applyDataChanges(updatedDataChanges);
      });

    } catch(error) {
      this.applyDataChanges(snapshot);

      this.fetchingEditedUserGroupError = true;
    } finally {
      this.fetchingEditedUserGroup = false;
    }
  }
}
