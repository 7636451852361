import React, { FC } from 'react';

import './_slider-navigation.scss';

interface IPropTypes {
  currentPage: number
  mobileVersion: boolean
  pages: number
}

export const SliderNavigation: FC<IPropTypes> = (props: IPropTypes) => {
  if (props.mobileVersion) {
    return (
      <div className="landing-page__slider-navigation--mobile">
        {props.currentPage}/{props.pages}
      </div>
    );
  }

  return null;
}
