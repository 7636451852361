export interface CornerstoneCourseAssignmentsDto {
    id: string
    type: CornerstoneConfigTypesEnum | string
    value: string
}

export interface PostCornerstoneAssignment {
    id: string | null
    type: CornerstoneConfigTypesEnum | string
    value?: string
}

export interface ICornerstoneInitialValues {
    subjects: string[] | undefined
    modalities: string[] | undefined
    keywords: string[] | undefined
}

export interface CornerstoneListItemDto {
    id:	string
    value:	string
}

export interface ICourseSimplifiedObject {
    id: string
    internal_id: string
    title: string
}

export enum CornerstoneConfigTypesEnum {
    subject = 'subject',
    modality = 'modality',
    keyword = 'keywords'
}