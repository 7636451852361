import Parser from '@u4i/utils/Parser';

export interface IDiscussComment {
  anonymously: boolean;
  content: string;
  createdAt: string;
  id: string;
  isLiked: boolean;
  likeCount: number;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    profileImage: string;
  };
}

const discussCommentParser = new Parser<IDiscussComment>(discussComment => ({
  anonymously: discussComment.anonymously_posted,
  content: discussComment.content,
  createdAt: discussComment.created_at,
  id: discussComment.id,
  isLiked: discussComment.is_liked,
  likeCount: discussComment.like_count,
  user: {
    firstName: discussComment.user.first_name,
    id: discussComment.user.id,
    lastName: discussComment.user.last_name,
    profileImage: discussComment.user.profile_image,
  },
}));

export default discussCommentParser;
