import FormatDate from '@u4i/common/FormatDate';
import React, { FC } from 'react';
import './_comment-header.scss';

interface IProps {
  anonymously: boolean
  authorFirstname: string
  authorLastname: string
  creationDate: string
}

export const CommentHeader: FC<IProps> = (props: IProps) => (
  <header className="discuss__comment-header">
    <h4 className="discuss__comment-header__author-name">
      {props.authorFirstname}
      &nbsp;
      {props.authorLastname}
    </h4>

    <p className="discuss__comment-header__creation-date">
      <FormatDate source={props.creationDate} longMonth={true}/>
    </p>
  </header>
);
