import {defineMessages} from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Oops!',
    id: 'Player.ErrorAlert.Heading',
  },
  message401: {
    defaultMessage: 'Sorry, please log-in first if you want to view this page!',
    id: 'Player.ErrorAlert.Message401',
  },
  message403: {
    defaultMessage: 'Sorry, you’re not authorized to access this page.'
      + ' If you think you should be authorized, please contact'
      + ' {mail}.',
    id: 'Player.ErrorAlert.Message403',
  },
  message404: {
    defaultMessage: 'The page you are looking for does not exist.'
      + ' The link might be outdated or temporarily unavailable.'
      + ' Try searching again or go to U4I’s home page.',
    id: 'Player.ErrorAlert.Message404',
  },
});
