import {defineMessages} from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Course Detail',
    id: 'OnboardingStore.Tours.SkillDetailTour.Title',
  },
  step1: {
    defaultMessage: 'Welcome! Let’s take a tour of the most important features on this page.',
    id: 'OnboardingStore.Tours.SkillDetailTour.Step1',
  },
  step2: {
    defaultMessage: 'Click here to start the course and access the content.',
    id: 'OnboardingStore.Tours.SkillDetailTour.Step2',
  },
  step3: {
    defaultMessage: 'Here you can close the course and return to the course overview.',
    id: 'OnboardingStore.Tours.SkillDetailTour.Step3',
  },
  step4: {
    defaultMessage: 'Once you have started the course, you can view learning content and see your learning progress here.',
    id: 'OnboardingStore.Tours.SkillDetailTour.Step4',
  },
});
