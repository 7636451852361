import { defineMessages } from "react-intl";

export default defineMessages({
  bookingValidationLabRequired: {
    defaultMessage: "Please select a Lab to book a slot",
    id: "DigitalLab.DigitalLabBookingStore.Overview.BookingValidationLabRequired",
  },
  bookingSlotInPastTime: {
    defaultMessage: "Slot cannot be booked in past date or time",
    id: "DigitalLab.DigitalLabBookingStore.Overview.BookingSlotInPastTime",
  },
  bookingSlotNotAvailable: {
    defaultMessage: "Slot not available",
    id: "DigitalLab.DigitalLabBookingStore.Overview.bookingSlotNotAvailable",
  },
  bookingSlotTodayButtonText: {
    defaultMessage: "Today",
    id: "DigitalLab.DigitalLabBookingStore.Overview.bookingSlotTodayButtonText",
  },
});
