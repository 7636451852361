export const enum LanguageEnum {
  DE_DE = 'de-DE',
  EN_US = 'en-US',
  CS_CZ = 'cs-CZ',
  KO_KR = 'ko-KR',
  JA_JP = 'ja-JP',
  TH_TH = 'th-TH',
  // Chinese Simplified
  ZH_CN = 'zh-CN'
};
