import {IAccessData} from '@u4i/parsers/AccessDataParser';
import {IOnboardingProgress} from '@u4i/parsers/OnboardingProgressParser';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {ITermsOfService} from '@u4i/parsers/TermsOfServiceParser';
import {IStorage} from '../ServicesInterfaces';

export interface ILocation {
  hash: string;
  pathname: string;
  search: string;
}

export interface IStorageController<DataType> {
  empty(): void;
  get(): DataType | null;
  set(value: DataType): void;
}

class StorageController<DataType> implements IStorageController<DataType> {
  private storageApi: Storage;
  private targetKey: string;

  constructor(storageApi: Storage, targetKey: string) {
    this.storageApi = storageApi;
    this.targetKey = targetKey;
  }

  empty(): void {
    this.storageApi.removeItem(this.targetKey);
  }

  get(): DataType | null {
    const value = this.storageApi.getItem(this.targetKey);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  set(value: DataType): void {
    this.storageApi.setItem(this.targetKey, JSON.stringify(value));
  }
}

export class StorageService implements IStorage {
  accessData = new StorageController<IAccessData>(window.localStorage, 'ACCESS_DATA');
  dataTablesSettings = new StorageController<{
    [tableId: string]: {
      currentSortDirection: 'ASC' | 'DESC';
      currentSortedColumnId: string | undefined;
      filters: { [key: string]: Object };
      itemsPerPage: number;
      showFilters: boolean;
    };
  }>(window.sessionStorage, 'DATA_TABLES_SETTINGS');
  language = new StorageController<LanguageEnum>(window.localStorage, 'LANGUAGE');
  lastViewedData = new StorageController<{
    location?: ILocation,
    userId?: string;
  }>(window.localStorage, 'LAST_VIEWED_DATA');
  newUserPasswordLink = new StorageController<string>(window.localStorage, 'NEW_USER_PASSWORD_LINK');
  onboarding = new StorageController<IOnboardingProgress>(window.localStorage, 'ONBOARDING');
  scormSessionData = new StorageController<{
    authProvider?: string;
    scormEnabled: boolean;
    skillId: string;
  }>(window.sessionStorage, 'SCORM_SESSION_DATA');
  showDigitalLabNotification = new StorageController<boolean>(window.localStorage, 'SHOW_DIGITAL_LAB_NOTIFICATION');
  showExercisesGuideMessage = new StorageController<boolean>(window.localStorage, 'SHOW_EXERCISES_GUIDE_MESSAGE');
  showJupyterLabNotification = new StorageController<boolean>(window.localStorage, 'SHOW_JUPYTER_LAB_NOTIFICATION');
  termsOfServiceAccepted = new StorageController<ITermsOfService>(window.localStorage, 'TERMS_OF_SERVICE_ACCEPTED');
  homepage = new StorageController<string>(window.localStorage, 'HOMEPAGE');
}
