import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';

import './_answer-feedback.scss';

interface IPropTypes {
  content: string;
}

export const AnswerFeedback = observer(class AnswerFeedback extends React.Component<IPropTypes> {
  render() {
    return (
      <div className="reactive-forms__exercise-answer-feedback__box">
        <span className="reactive-forms__exercise-answer-feedback__title">
          <FormattedMessage
            {...intlMessages.feedback}
          />
        </span>
      <p>{this.props.content}</p>
      </div>
    );
  }
});
