/**
* @dev_date June 2022
* @desc This component handles store for Redeem Page
* @author Denis Shtupa denis.shtupa@u4i.io
*/

import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { IRootStore } from '@u4i/state/RootStore';
import { IApi } from '@u4i/state/ServicesInterfaces';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { IChapterData } from '../interfaces/ChapterInterface';
import { ICourse } from '../interfaces/CourseInterface';

export class CourseDetailPageStore {
  private apiService: IApi;
  public courseData: ICourse;
  public chapterData: IChapterData[];
  public errorCode: any;
  public fetching: boolean = false;
  public fetchingChapters: boolean = false;
  public page: number = 1;
  public totalPages: number;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      courseData: observable,
      chapterData: observable,
      errorCode: observable,
      fetching: observable,
      fetchingChapters: observable,
      page: observable,
      totalPages: observable,
      changeChaptersPage: action.bound,
      enrollFreeCourse: action.bound,
      loadCourseData: action.bound,
      loadChapters: action.bound,
      resetState: action.bound,
      recordCourseVisit: action.bound,
      startFeedbackSurvey: action.bound
  });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  public changeChaptersPage = (newPage: any) => {
    this.page = newPage;
  }

  public startFeedbackSurvey = () => {
    if (this.courseData?.feedbackSurvey) {
      let url: any = {
        challengePlatform: 'feedback_survey',
        targetUrl: this.courseData.feedbackSurvey?.url,
      }

      AppNavigator.push(appRoutes.externalContent, {}, null, url);
    }
  }

  public enrollFreeCourse = async (slugOrCourseId: string) => {
    try {
      await this.apiService.skills.enrollFreeCourse(slugOrCourseId);
    }
    catch(error) {

    }
  }

  public loadCourseData = async (courseSlug: string) => {
    this.fetching = true;
    try {
      await this.apiService.skills.getCourseData(courseSlug).then(res => {
        runInAction(() => {
          this.courseData = res;
        })
      })
    } catch(error) {
      this.errorCode = error?.response?.status;
    } finally {
      this.fetching = false;
    }
  }

  public loadChapters = async (courseSlug: string) => {
    this.fetchingChapters = true;
    try {
      await this.apiService.skills.getChapterData(courseSlug, this.page).then(res => {
        runInAction(() => {
          this.totalPages = res.totalPages;
          this.chapterData = res.data;
        })
      })
    } catch (error) {
      
    } finally {
      this.fetchingChapters = false;
    }
  }

  public recordCourseVisit = async (courseSlug: string) => {
    try {
      const response: any = await this.apiService.skills.recordCourseVisit(courseSlug);
    } catch(error) {
    }
  }

  resetState = () => {
    this.errorCode = undefined;
    this.fetching = false;
  }

}
