import React from 'react';
import {action, computed, observable, makeObservable} from 'mobx';
import {isEqual} from 'lodash';
import {observer} from 'mobx-react';

interface IPropTypes {
  decimalPlaces?: number;
  durationMilliseconds: number;
  targetValue: number;
}

export const AnimatedNumber = observer(class AnimatedNumber extends React.Component<IPropTypes> {
  private readonly ANIMATION_FRAME_RATE = 60;
  private animationId?: number;
  private animationStartValue = 0;
  private currentValue: number;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<AnimatedNumber, "currentValue">(this, {
      currentValue: observable,
      ticksPerAnimation: computed,
      animate: action.bound,
      cancelAnimation: action.bound
    });

    this.currentValue = props.targetValue;
  }

  componentDidUpdate(prevProps: IPropTypes) {
    if (!isEqual(this.props, prevProps)) {
      this.cancelAnimation();

      this.animationStartValue = this.currentValue;
      this.animationId = window.requestAnimationFrame(this.animate);
    }
  }

  get ticksPerAnimation() {
    return (this.props.durationMilliseconds / 1000) * this.ANIMATION_FRAME_RATE;
  }

  animate() {
    const modifier = Math.abs(this.props.targetValue - this.animationStartValue) / this.ticksPerAnimation;

    if (this.props.targetValue > this.animationStartValue) {
      this.currentValue += modifier;

      if (this.currentValue >= this.props.targetValue) {
        this.currentValue = this.props.targetValue;
        
        this.cancelAnimation();
      } else {
        this.animationId = window.requestAnimationFrame(this.animate);
      }
    } else {
      this.currentValue -= modifier;

      if (this.currentValue <= this.props.targetValue) {
        this.currentValue = this.props.targetValue;
        
        this.cancelAnimation();
      } else {
        this.animationId = window.requestAnimationFrame(this.animate);
      }
    }
  }

  cancelAnimation() {
    if (this.animationId) {
      window.cancelAnimationFrame(this.animationId);
      this.animationId = undefined;
    }
  }

  render() {
    const decimalPlaces = this.props.decimalPlaces || 0;
    const roundedValue = Math.round(this.currentValue * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

    return roundedValue.toFixed(decimalPlaces);
  }
});
