import Parser from '@u4i/utils/Parser';
import {SkillProgressEnum} from '@u4i/common/enums/SkillProgressEnum';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';

export interface ISkillBasic {
  duration: number;
  id: string;
  image: string;
  isBought: boolean;
  isCompleted: boolean;
  isPublished: boolean;
  language: LanguageEnum;
  numberOfChallenges: number;
  numberOfCompletedChallenges: number;
  skillProgressPercentage: number;
  skillProgressStatus: SkillProgressEnum;
  slug: string;
  title: string;
}

export const SkillParserBasic = new Parser<ISkillBasic>((skill) => {
  const skillProgressPercentage = parseFloat((skill.progress / 100).toFixed(2));

  return {
    duration: skill.duration,
    id: skill.id,
    image: skill.image.url,
    isBought: skill.is_bought,
    isCompleted: skill.is_completed,
    isPublished: skill.is_published,
    language: skill.language,
    numberOfChallenges: skill.number_of_challenges,
    numberOfCompletedChallenges: skill.number_of_completed_challenges,
    skillProgressPercentage,
    skillProgressStatus: skill.status,
    slug: skill.slug,
    title: skill.title,
  };
});
