import Parser from '@u4i/utils/Parser';

export interface IUserProgress {
  attemptsCount: {
    failed: number;
    passed: number;
    submitted: number;
    total: number;
  };
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export const UserProgressParser = new Parser<IUserProgress>(userProgress => ({
  attemptsCount: {
    failed: parseInt(userProgress.attemptsCount.failed, 10),
    passed: parseInt(userProgress.attemptsCount.passed, 10),
    submitted: parseInt(userProgress.attemptsCount.submitted, 10),
    total: parseInt(userProgress.attemptsCount.failed, 10)
      + parseInt(userProgress.attemptsCount.passed, 10)
      + parseInt(userProgress.attemptsCount.submitted, 10),
  },
  email: userProgress.email,
  firstName: userProgress.firstName,
  id: userProgress.id,
  lastName: userProgress.lastName,
}));
