import DOMPurify from 'dompurify';
import React from 'react';
import {action, computed, observable,makeObservable} from 'mobx';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import { Prompt } from 'react-router'
import {inject, observer} from 'mobx-react';

import {Answers} from '@u4i/parsers/ExerciseParser';
import {AnswerFeedback} from './components/AnswerFeedback';
import {ExercisesStore} from '../../../modules/Exercises/state/ExercisesStore';
import {ErrorMessage} from '../../formsSystem/ErrorMessage';
import {Field, FieldModel, ReactiveForm} from '../../formsSystem';
import {FileField, FileFieldPropTypes} from '../../formsSystem/fields/FileField';
import {IRootStore} from '@u4i/state/RootStore';
import {reactiveFormsValidators} from '../../formsSystem/validators';
import {TextareaField, TextareaFieldPropTypes} from '../../formsSystem/fields/TextareaField';
import {QuestionContent} from './components/QuestionContent';
import {QuestionNavigation} from './components/QuestionNavigation';

import intlMessages from './intlMessages';
import './_exercise-form.scss';
import ChallengeStatusEnum from '@u4i/common/enums/ChallengeStatusEnum';

interface IPropTypes extends WrappedComponentProps {
  questions: Array<{
    content: string;
    criteria?: string;
    id: string;
    requiredFileUpload: boolean;
    requiredTextAnswer: boolean;
    hideFileUpload: boolean;
  }>;
  answers?: Answers;
  status?: number;
  rootStore: IRootStore;
}

interface ResultModel {
  [questionId: string]: {
    answer?: string;
    files?: Array<File>;
  };
}

const ExerciseForm = inject('rootStore')(
  observer(class ExerciseForm extends ReactiveForm<IPropTypes, ResultModel> {
    displayedQuestion = 1;
    private exercisesStore: ExercisesStore;
    protected formModel: {
      [questionId: string]: {
        answer: FieldModel<string>;
        files: FieldModel<Array<File>>;
      };
    } = {};

    constructor(props) {
      super(props);

      makeObservable<ExerciseForm, "displayedQuestion">(this, {
        displayedQuestion: observable,
        totalQuestions: computed,
        handleBackClick: action.bound,
        sendAnswer: action.bound,
        handleNextClick: action.bound
      });

      const {exercisesStore} = this.props.rootStore;
      this.exercisesStore = exercisesStore;

      this.formModel = {};

      for (const question of (props as IPropTypes).questions) {

        const answer = props.answers && props.answers[question.id];
        if(answer) {
          this.formModel[question.id] = {
            answer: new FieldModel<string>(answer.answer),
            files: new FieldModel<Array<File>>(answer.files),
          };
        } else {
          this.formModel[question.id] = {
            answer: new FieldModel<string>(''),
            files: new FieldModel<Array<File>>([]),
          };
        }
      }
      const currentQuestion = this.props.questions[this.displayedQuestion - 1];
      this.exercisesStore.currentQuestionId = currentQuestion.id;
    }

    get totalQuestions() {
      return this.props.questions.length;
    }

    get isCurrentAnswerInvalid() {
      const currentQuestion = this.props.questions[this.displayedQuestion - 1];
      const currentQuestionAnswer = this.formModel[currentQuestion.id].answer;
      const currentQuestionFiles = this.formModel[currentQuestion.id].files;

      return !currentQuestionAnswer.valid || !currentQuestionFiles.valid;
    }

    handleBackClick() {
      if (this.displayedQuestion > 1) {
        this.displayedQuestion--;
      }
    }

    sendAnswer() {
      const currentQuestion = this.props.questions[this.displayedQuestion - 1];
      const answer = {
        answer: this.formModel[currentQuestion.id].answer.value as string,
        files: this.formModel[currentQuestion.id].files.value as File[],
      }

      this.exercisesStore.submitAnswer(answer, currentQuestion.id)
    }

    handleNextClick() {
      this.validateVisibleQuestion();
      if (this.isCurrentAnswerInvalid) {
        return;
      }
      if (this.displayedQuestion < this.totalQuestions) {
        if(!this.isNotSubmittable) {
          this.sendAnswer()
        }
        this.displayedQuestion++;
        const currentQuestion = this.props.questions[this.displayedQuestion - 1];
        this.exercisesStore.currentQuestionId = currentQuestion.id;
      }
    }

    validateVisibleQuestion = () => {
      const visibleQuestion = this.formModel[this.props.questions[this.displayedQuestion - 1].id];
      visibleQuestion.answer.validate();
      visibleQuestion.files.validate();
    };

    showFeedback(question) {
      return this.props.answers &&
      this.props.answers[question.id] &&
      this.props.answers[question.id].graderFeedback ?
        <AnswerFeedback content={this.props.answers[question.id].graderFeedback as string} /> : null;
    }

    sendBeforeLeave = () => {
      if(!this.isNotSubmittable) {
        this.sendAnswer();
      }
      return true;
    }

    get isGradingInProgress() {
      return !!(this.props.status && this.props.status == ChallengeStatusEnum.SUBMITTED)
    }

    get isNotSubmittable() {
      return !!(this.props.status && (this.props.status == ChallengeStatusEnum.PASSED || this.props.status == ChallengeStatusEnum.SUBMITTED))
    }

    renderContent() {
      const answersErrorRequired = this.props.intl.formatMessage(intlMessages.answersErrorRequired);
      const filesErrorRequired = this.props.intl.formatMessage(intlMessages.filesErrorRequired);

      return (
        <div>
          <Prompt when={true}  message={this.sendBeforeLeave} />
          <h4 className="reactive-forms__exercise-form__question-heading">
            <FormattedMessage
              {...intlMessages.taskNumberHeading}
              values={{current: this.displayedQuestion, total: this.totalQuestions}}
            />
          </h4>

          {this.props.questions.map((question, index) => (
            <div hidden={index + 1 !== this.displayedQuestion} key={question.id}>
              <QuestionContent content={question.content} />

              {question.criteria &&
                <div className="reactive-forms__exercise-form__criteria-wrapper">
                  <h4 className="reactive-forms__exercise-form__criteria-heading">
                    <FormattedMessage {...intlMessages.criteriaHeading} />
                  </h4>

                  <p
                  className="reactive-forms__exercise-form__criteria-content"
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.criteria, {ADD_ATTR: ['target']})}}
                  />
                </div>
              }

              <div className="reactive-forms__exercise-form__question-field">
                <Field <TextareaFieldPropTypes>
                  component={TextareaField}
                  componentProps={{
                    label: <FormattedMessage {...intlMessages.yourAnswer} />,
                    placeholder: <FormattedMessage {...intlMessages.yourAnswerPlaceholder} />,
                  }}
                  disabled={this.submitting || this.submitSucceeded}
                  model={this.formModel[question.id].answer}
                  validators={[
                    question.requiredTextAnswer ?
                      reactiveFormsValidators.requiredWithStringTrim(answersErrorRequired)
                      :
                      undefined,
                  ]}
                />
              </div>
              {this.showFeedback(question)}
            </div>
          ))}

          <div className="reactive-forms__exercise-form__controls">
            <div>
              {this.props.questions.map((question, index) => (
                <div hidden={(index + 1 !== this.displayedQuestion || question.hideFileUpload)} key={question.id}>
                  <Field  <FileFieldPropTypes>
                    component={FileField}
                    componentProps={{
                      allowedFileExtensions: [
                        'csv', 'doc', 'docx', 'gif', 'jpeg', 'jpg', 'pdf',
                        'png', 'ppt', 'pptx', 'rtf', 'txt', 'xls', 'xlsx',
                      ],
                      maxFileSizeBytes: 10 * Math.pow(1000, 2),
                      multiple: true,
                      isNotSubmittable: this.isNotSubmittable
                    }}
                    disabled={this.submitting || this.submitSucceeded || this.isNotSubmittable}
                    model={this.formModel[question.id].files}
                    validators={[
                      question.requiredFileUpload ?
                        reactiveFormsValidators.requiredFiles(filesErrorRequired)
                        :
                        undefined,
                    ]}
                  />
                </div>
              ))}
            </div>

            <QuestionNavigation
              currentQuestion={this.displayedQuestion}
              formInvalid={this.invalid}
              isNotSubmitabble={this.isNotSubmittable}
              onBackClick={this.handleBackClick}
              onNextClick={this.handleNextClick}
              submitting={this.submitting}
              submitSucceeded={this.submitSucceeded}
              totalQuestions={this.totalQuestions}
            />
          </div>

          { this.isGradingInProgress &&
            <div className="reactive-forms__exercise-form__error-message">
              <FormattedMessage {...intlMessages.challengeAttemptCanNotBeChanged}  />
            </div>
          }

          { this.generalError &&
            <div className="reactive-forms__exercise-form__error-message">
              <ErrorMessage>{this.generalError}</ErrorMessage>
            </div>
          }

        </div>
      );
    }
  })
);

const WrappedComponent = injectIntl(ExerciseForm);

export {WrappedComponent as ExerciseForm};
