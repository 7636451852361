import { makeObservable, action, observable, toJS } from "mobx";
import { IApi } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import UserStore from "@u4i/state/UserStore";
import { ImportUserValidation } from "../components/UserImport/Interface";
import { MAX_RECORD_CAN_PARSE_ON_FE } from "@u4i/constantSettings";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";

export class SegregationOfDutiesStore {
  private apiService: IApi;
  private userStore: UserStore;

  isLoadingSegData: boolean = false;
  isPreviewModalVisible: boolean = false;
  isApprovingRequests: boolean = false;
  segregationData: any = [];
  importUserValidationData: ImportUserValidation[] = [];
  apiConfig: {
    limit: number;
    offset: number;
    filters: { [key: string]: string | number };
    orderBy: { [key: string]: string };
  } = {
    limit: 100,
    offset: 0,
    filters: {
      status: 0,
    },
    orderBy: {
      requested_at: "asc",
    },
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      importUserValidationData: observable,
      isLoadingSegData: observable,
      isApprovingRequests: observable,
      isPreviewModalVisible: observable,
      segregationData: observable,
      approveAllUsers: action.bound,
      getPendingRequests: action.bound,
      hidePreviewPopup: action.bound,
      rejectAllUsers: action.bound,
      viewAllUsers: action.bound,
    });

    const { apiService, userStore } = rootStore;

    this.apiService = apiService;
    this.userStore = userStore;
  }

  getPendingRequests = async () => {
    this.isLoadingSegData = true;
    const logInUserId: string = this.userStore.userData.id;
    try {
      const { response, totalItems } = await this.apiService.admin.userImport.getImportHistory(this.apiConfig);
      const addedLoadingFlag = response.map((element) => {
        element["viewBtnLoadingFlag"] = false;
        element["approveBtnLoadingFlag"] = false;
        element["rejectBtnLoadingFlag"] = false;
        element["isOwnApproval"] = logInUserId === element.requester.id;
        return element;
      });

      this.segregationData = addedLoadingFlag;
      if (this.apiConfig.limit < totalItems) {
        this.apiConfig.limit = totalItems;
        const { response } = await this.apiService.admin.userImport.getImportHistory(this.apiConfig);

        const addedLoadingFlag = response.map((element) => {
          element["viewBtnLoadingFlag"] = false;
          element["approveBtnLoadingFlag"] = false;
          element["rejectBtnLoadingFlag"] = false;
          element["isOwnApproval"] = logInUserId === element.requester.id;
          return element;
        });
        this.segregationData = addedLoadingFlag;
      }
    } catch (error) {
      Notification(NotificationEnum.Error, "Segregation of Duties", error.response?.data?.errorMessage || "Something went wrong!");
    } finally {
      this.isLoadingSegData = false;
    }
  }

  viewAllUsers = async (id: string, fileExtension: string) => {
    this.importUserValidationData = [];
    let formData: any = {};
    let getOldUserGroupsRequestParam: string[] = [];

    this.segregationData = this.segregationData.map((element) => {
      if (element.id == id) {
        element.viewBtnLoadingFlag = true;
        formData = element.formData;
      }
      return element;
    });
    try {
      const response = await this.apiService.admin.userImport.viewUsersForBulkApproval(id, fileExtension);    
      const dataLength = response.length;
      for (let i = 0; i < dataLength; i++) {
        let userValidationObj: ImportUserValidation = {
          email: response[i].email,
          firstName: response[i].firstName,
          lastName: response[i].lastName,
          company: formData.company,
          language: formData.language == "en-US" ? "English (US)" : "Deutsch",
          role: formData.role.name,
          userGroup: formData.groups,
          mainUserGroup: formData.mainGroup.name,
          sendEmail: formData.sendPasswordCreateEmail ? "Yes" : "No",
          status: formData.status.name,
          tokenValidityDays: formData.tokenValidityDays,
          id: i,
          error: [],
        };
        this.importUserValidationData.push(userValidationObj);
        getOldUserGroupsRequestParam.push(response[i].email);
      }

      const oldUserGroups: any[] = await this.apiService.admin.userImport.getOldUserGroupByEmail(getOldUserGroupsRequestParam);

      this.importUserValidationData.map((data: ImportUserValidation) => {
        if(oldUserGroups[data.email]) {
          let initialUserGroups: any [] = [];
          oldUserGroups[data.email].forEach(ug => {
            let userGroup = { id: ug.id, name: ug.name, isMain: false, isOldGroup: true };
            initialUserGroups.push(userGroup);
          })

          data.userGroup = initialUserGroups
          .concat(toJS(data.userGroup))
          .reduce((aggr, el) => {
            if (!aggr.find((inst) => inst.id == el.id)) {
              return [...aggr, el]
            } else {
              return aggr;
            }
          }, []);
        }
      });

      this.isPreviewModalVisible = true;
    } catch (error) {
      if(error?.code == 'ERR_BAD_REQUEST' && error?.response?.status == 404) {
        Notification(NotificationEnum.Info, 'View all users in this group', `Sorry, files that contain ${MAX_RECORD_CAN_PARSE_ON_FE} or more users can not be displayed.`)
      } else {
        Notification(NotificationEnum.Error, "Segregation of Duties", error.response?.data?.errorMessage || "Something went wrong!");
      }
    } finally {
      this.segregationData = this.segregationData.map((element) => {
        if (element.id == id) {
          element.viewBtnLoadingFlag = false;
        }
        return element;
      });
    }
  }

  approveAllUsers = async (id: string) => {
    this.isApprovingRequests = true;
    this.segregationData = this.segregationData.map((element) => {
      if (element.id == id) {
        element.approveBtnLoadingFlag = true;
      }
      return element;
    });
    try {
      await this.apiService.admin.userImport.bulkApproval(id, "approved");
      await this.getPendingRequests();
      Notification(NotificationEnum.Success, "Segregation of Duties", "All users are approved!");
    } catch (error) {
      Notification(NotificationEnum.Error, "Segregation of Duties", error.response?.data?.errorMessage || "Something went wrong!");
    } finally {
      setTimeout(() => {
        this.isApprovingRequests = false;
      }, 2000)
      this.segregationData = this.segregationData.map((element) => {
        if (element.id == id) {
          element.approveBtnLoadingFlag = false;
        }
        return element;
      });
    }
  }

  rejectAllUsers = async (id: string) => {
    this.segregationData = this.segregationData.map((element) => {
      if (element.id == id) {
        element.rejectBtnLoadingFlag = true;
      }
      return element;
    });
    try {
      await this.apiService.admin.userImport.bulkApproval(id, "rejected");
      await this.getPendingRequests();
      Notification(NotificationEnum.Success, "Segregation of Duties", "All users are rejected!");
    } catch (error) {
      Notification(NotificationEnum.Error, "Segregation of Duties", error.response?.data?.errorMessage || "Something went wrong!");
    } finally {
      this.segregationData = this.segregationData.map((element) => {
        if (element.id == id) {
          element.rejectBtnLoadingFlag = false;
        }
        return element;
      });
    }
  }

  hidePreviewPopup() {
    this.isPreviewModalVisible = false;
  }
}
