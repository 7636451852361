import { FormInstance } from "antd";
import cx from "classnames";
import React, { FC } from "react";

import "./_text-field.scss";

interface IProps {
  form: FormInstance<any>
  id?: string
  label?: any
  name: string
  noMarginBottom?: boolean
  type?: string
}

const CustomInputField: FC<IProps> = (props: IProps) => {
  const { form, id, label, name, noMarginBottom, type,...rest } = props;
  const [activeInput, setActiveInput] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  
  setTimeout(() => {
    if (form.getFieldError(name).length > 0) {
      return setError(true);
    }
    return setError(false);
  }, 100);

  const isEmptyValue: boolean = form.getFieldValue(name) == "" || props.form.getFieldValue(name) === undefined ? true : false;

  return (
    <div
      className={cx({
        "text-field": true,
        "text-field__margin-bottom": noMarginBottom,
        "text-field--dirty": !isEmptyValue,
        "text-field--focused": activeInput,
        "text-field--invalid": hasError,
      })}
    >
      <input
        className="text-field__input"
        id={id}
        type={type}
        onBlur={() => setActiveInput(false)}
        onFocus={() => setActiveInput(true)}
        {...rest}
      />

      <label className="text-field__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default CustomInputField;
