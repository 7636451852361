import qs from 'qs';
import {AxiosInstance} from 'axios';
import DiscussAnswerParser, {IDiscussAnswer} from '@u4i/parsers/DiscussAnswerParser';
import DiscussCommentParser, {IDiscussComment} from '@u4i/parsers/DiscussCommentParser';
import DiscussLikeParser, {IDiscussLike} from '@u4i/parsers/DiscussLikeParser';
import DiscussPageParser, {IDiscussPage} from '@u4i/parsers/DiscussPageParser';
import {IPaginatedItems} from '../../ServicesInterfaces';
import { DiscussAnswerPost } from '@u4i/modules/DiscussPage/state/DiscussPageStore';

export class DiscussApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchAnswers(challengeSlug: string, config: {
    limit: number,
    offset: number,
    sortDirection: 'ASC' | 'DESC',
    sortedField: string,
  }): Promise<IPaginatedItems<IDiscussAnswer>> {
    const response = await this.apiClient.get(
      `/v1/discuss/questions/${challengeSlug}/answers`, {
        params: {
          limit: config.limit,
          offset: config.offset,
          orderBy: config.sortedField ? {
            [config.sortedField]: config.sortDirection,
          } : undefined,
        },
        paramsSerializer: params => qs.stringify(params),
      },
    );

    const answers = DiscussAnswerParser.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items: answers, totalItems};
  }

  async fetchComments(answerId: string): Promise<Array<IDiscussComment>> {
    const response = await this.apiClient.get(`/v1/discuss/answers/${answerId}/comments`);
  
    return DiscussCommentParser.parseArray(response.data.comments);
  }

  async fetchLikes(answerId: string): Promise<Array<IDiscussLike>> {
    const response = await this.apiClient.get(`/v1/discuss/answers/${answerId}/likes`);
  
    return DiscussLikeParser.parseArray(response.data.likes);
  }

  async fetchPageData(challengeSlug: string): Promise<IDiscussPage> {
    const response = await this.apiClient.get(`/v1/discuss/questions/${challengeSlug}`);
  
    return DiscussPageParser.parse(response.data);
  }

  async fetchUserAnswers(challengeSlug: string): Promise<Array<IDiscussAnswer>> {
    const response = await this.apiClient.get(`/v1/discuss/questions/${challengeSlug}/answers/my`);

    return DiscussAnswerParser.parseArray(response.data.answers);
  }

  async submitAnswer(challengeSlug: string, answerContent: DiscussAnswerPost): Promise<IDiscussAnswer> {
    const response = await this.apiClient.post(
      `/v1/discuss/questions/${challengeSlug}/answers`,
      {
        anonymously: answerContent.anonymity,
        answer: answerContent.answer,
      },
    );

    return DiscussAnswerParser.parse(response.data);
  }

  async submitComment(answerId: string, commentContent: {anonymity: boolean, content: string}): Promise<IDiscussComment> {
    const response = await this.apiClient.post(
      `/v1/discuss/answers/${answerId}/comments`,
      {
        anonymously: commentContent.anonymity,
        comment: commentContent.content,
      },
    );

    return DiscussCommentParser.parse(response.data);
  }

  async toggleAnswerLike(answerId: string): Promise<IDiscussLike> {
    const response = await this.apiClient.post(`/v1/discuss/answers/${answerId}/likes`);

    return DiscussLikeParser.parse(response.data);
  }

  async toggleCommentLike(commentId: string): Promise<IDiscussLike> {
    const response = await this.apiClient.post(`/v1/discuss/comments/${commentId}/likes`);

    return DiscussLikeParser.parse(response.data);
  }

  async updateAnswer(answerData: IDiscussAnswer): Promise<IDiscussAnswer> {
    const response = await this.apiClient.put(`/v1/discuss/answers/${answerData.id}`, answerData);

    return DiscussAnswerParser.parse(response.data);
  }

  async updateComment(commentData: IDiscussComment): Promise<IDiscussComment> {
    const response = await this.apiClient.put(`/v1/discuss/comments/${commentData.id}`, commentData);

    return DiscussCommentParser.parse(response.data);
  }
}
