import {action, computed, observable, runInAction,makeObservable} from 'mobx';

import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import {DISCUSS_ANSWERS_PER_PAGE} from '@u4i/constantSettings';
import {ILogger, IApi} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';

export class DiscussStore {
  private apiService: IApi;
  private challengeSlug: string;
  private logger: ILogger;
  private rootStore: IRootStore;
  private _currentSortDirection: 'ASC' | 'DESC' = 'ASC';
  private _currentSortedField;
  answers: Array<DiscussAnswerEntity> = [];
  fetching = true;
  fetchingPage = false;
  initialFetching = true;
  page = 1;
  totalPages = 1;

  constructor(rootStore: IRootStore, challengeSlug: string) {
    makeObservable<DiscussStore, "_currentSortDirection" | "_currentSortedField">(this, {
      _currentSortDirection: observable,
      _currentSortedField: observable,
      answers: observable,
      fetching: observable,
      fetchingPage: observable,
      initialFetching: observable,
      page: observable,
      totalPages: observable,
      currentSortDirection: computed,
      currentSortedField: computed,
      handleSortAnswers: action.bound,
      loadAnswers: action.bound,
      loadPage: action.bound,
      reloadAnswers: action.bound
    });

    const {apiService, loggerFactory} = rootStore;
    this.apiService = apiService;
    this.challengeSlug = challengeSlug;
    this.logger = loggerFactory.createLogger('DiscussStore');
    this.rootStore = rootStore;

    this.reloadAnswers();
  }

  get currentSortDirection() {return this._currentSortDirection; }
  get currentSortedField() {return this._currentSortedField; }

  handleSortAnswers(fieldName: string) {
    this.page = 1;

    if (this._currentSortedField === fieldName) {
      if (this._currentSortDirection === 'ASC') {
        this._currentSortDirection = 'DESC';
      } else if (this._currentSortDirection === 'DESC') {
        this._currentSortDirection = 'ASC';
        this._currentSortedField = undefined;
      }
    } else {
      this._currentSortedField = fieldName as string;
      this._currentSortDirection = 'ASC';
    }

    this.reloadAnswers();
  }

  async loadAnswers() {
    this.fetchingPage = true;

    const {items, totalItems}= await this.apiService.discuss.fetchAnswers(
      this.challengeSlug,
      { 
        limit: DISCUSS_ANSWERS_PER_PAGE,
        offset: (this.page - 1) * DISCUSS_ANSWERS_PER_PAGE,
        sortDirection: this._currentSortDirection,
        sortedField: this._currentSortedField,
      }
    );

    const answerEntities = items.map(item => new DiscussAnswerEntity(item, this.rootStore));
    const totalPages = Math.ceil(totalItems / DISCUSS_ANSWERS_PER_PAGE);

    runInAction(() => {
      this.answers = [...this.answers, ...answerEntities];
      this.fetchingPage = false;
      this.totalPages = totalPages;
    });

    this.logger.info('answers page loaded');
  }

  loadPage() {
    if (!this.fetchingPage) {
      this.page++;

      this.loadAnswers();
    }
  }

  async reloadAnswers() {
    this.fetching = true;

    const {items, totalItems} = await this.apiService.discuss.fetchAnswers(
      this.challengeSlug,
      { 
        limit: DISCUSS_ANSWERS_PER_PAGE,
        offset: (this.page - 1) * DISCUSS_ANSWERS_PER_PAGE,
        sortDirection: this._currentSortDirection,
        sortedField: this._currentSortedField,
      }
    );

    runInAction(() => {
      const answerEntities = items.map(item => new DiscussAnswerEntity(item, this.rootStore));
      const totalPages = Math.ceil(totalItems / DISCUSS_ANSWERS_PER_PAGE);
    
      this.answers = answerEntities;
      this.fetching = false;
      this.totalPages = totalPages;
    });

    this.logger.info('answers reloaded');
  }
}
