import cx from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import React, { FC } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import {AlertStatusEnum} from '@u4i/common/enums/AlertStatusEnum';
import { buttercup, guardsmanRed, mainBlue } from '@u4i/styles/styles-variables';
import './_alert.scss';

const closeIconColorsMap = new Map<AlertStatusEnum, string>([
  [AlertStatusEnum.ERROR, guardsmanRed],
  [AlertStatusEnum.INFO, mainBlue],
  [AlertStatusEnum.WARNING, buttercup ],
]);


const iconsMap = new Map<AlertStatusEnum, React.ReactElement>([
  [AlertStatusEnum.ERROR, <ErrorIcon style={{color: guardsmanRed, height: 25, width: 25}} />],
  [AlertStatusEnum.INFO, <InfoIcon style={{color: mainBlue, height: 25, width: 25}} />],
  [AlertStatusEnum.WARNING, <WarningIcon style={{color: buttercup, height: 25, width: 25}} />],
]);

const defaultProps: Partial<IProps> = {
  showCloseButton: false,
};

interface IProps {
  actionButtonLabel?: JSX.Element | React.ReactNode | string;
  heading?: React.ReactNode | JSX.Element | string;
  message: React.ReactNode | JSX.Element | string;
  onActionButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseButtonClick?: Function;
  showActionButton?: boolean;
  showCloseButton?: boolean;
  type: AlertStatusEnum;
}

export const Alert: FC<IProps> = (props: IProps) => {
  const alertIcon = iconsMap.get(props.type);
  const handleCloseButtonClick = () => {
    if(props.onCloseButtonClick) {
      props.onCloseButtonClick();
    }

    return;
  }

  return (
    <div
      className={cx({
        alert: true,
        'alert--error': props.type === AlertStatusEnum.ERROR,
        'alert--info': props.type === AlertStatusEnum.INFO,
        'alert--warning': props.type === AlertStatusEnum.WARNING,
      })}
    >
      {props.showCloseButton &&
        <button
          className="alert__close-button"
          onClick={handleCloseButtonClick}
          type="button"
        >
          <CloseIcon style={{color: closeIconColorsMap.get(props.type), height: 25, width: 25}} />
        </button>
      }

      {alertIcon && React.cloneElement(alertIcon, {
        className: 'alert__icon',
      })}

      {props.heading &&
        <strong className="alert__heading">
          {props.heading}
        </strong>
      }

      <p className="alert__message">
        {props.message}
      </p>

      {props.showActionButton &&
        <button
          className="alert__action-button"
          onClick={props.onActionButtonClick}
          type="button"
        >
          {props.actionButtonLabel}
        </button>
      }
    </div>
  );
}

Alert.defaultProps = defaultProps;
