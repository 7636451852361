import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {RouteComponentProps, withRouter} from 'react-router';
import intlMessages from './intlMessages';
import { Col, Modal, Row } from 'antd';
import { RedeemPageStore } from '@u4i/modules/RedeemPage/state/RedeemPageStore';
import UserStore from '@u4i/state/UserStore';
import { LanguageStore } from '@u4i/state/LanguageStore';
import { CheckoutPurchaseStore } from '@u4i/modules/Checkout/state/CheckoutStore';
import { CourseDetailPageStore } from '@u4i/modules/CourseDetailPage/state/CourseDetailPageStore';
import CircularProgress from '@u4i/common/CircularProgress';
import CoursePurchaseForm from '@u4i/common/forms/CoursePurchaseForm/CoursePurchaseForm';
import { CourseDetails } from '@u4i/modules/Checkout/SummaryPage/CourseDetails/CourseDetails';
import './_purchase-course-modal.scss';

interface IProps extends RouteComponentProps {
  courseDetailPageStore?: CourseDetailPageStore
  checkoutStore?: CheckoutPurchaseStore
  languageStore?: LanguageStore
  redeemPageStore?: RedeemPageStore
  userStore: UserStore
}

const PurchaseCourseModal: FC<IProps> = inject("rootStore") (
  observer((props: IProps) => {
    const {
      isLoginAndPurchase,
      isPurchaseCourseModalVisible,
      purchaseCourseModalHandle,
    } = props.userStore;

    let indexOfCourseSlug: number;
    let courseSlug: string;

    if(isLoginAndPurchase) {
      indexOfCourseSlug = props.location.pathname.indexOf('/skills/');
      
      if(indexOfCourseSlug > -1) {
        courseSlug = props.location.pathname.substring(indexOfCourseSlug + 8);
      }
    }

    const navigateToCourse = () => {
      //TODO: do nothing on click
    };

    return (
      <Modal
        centered
        mask={true}
        maskClosable={false}
        footer={null}
        onCancel={() => purchaseCourseModalHandle(false)}
        open={isPurchaseCourseModalVisible}
        title={
          <h3 style={{textAlign:'center', fontWeight: 500}}>
            <FormattedMessage {...intlMessages.purchaseCourse} /> 
          </h3>
        }
        style={{minWidth: '60%', height: '75%'}}
      >
        <Row justify='center'>
          <Col span={12}>
            <p className="purchase__summary-content__message">
              <FormattedMessage {...intlMessages.message} />
            </p>

            <CoursePurchaseForm
              courseDetailPageStore={props.courseDetailPageStore}
              checkoutStore={props.checkoutStore}
              languageStore={props.languageStore}
              userStore={props.userStore}
            />
          </Col>

          <Col span={10} style={{
            background: 'rgb(243 243 244)', 
            padding: 20, 
            marginLeft: 20
          }}>
            <div className="purchase__sidebar__inner-wrapper">
              {props.courseDetailPageStore?.fetching ?
                <CircularProgress />
                :
                props.courseDetailPageStore?.courseData &&
                  <CourseDetails
                    course={props.courseDetailPageStore.courseData}
                    disableHovering={true}
                    handleCardClick={navigateToCourse}
                  />
              }
            </div>                          
          </Col>
        </Row>
      </Modal>
    )
  })
)

export default withRouter(PurchaseCourseModal);
