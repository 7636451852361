import React, { Fragment } from 'react';
import {computed, observable,makeObservable} from 'mobx';
import {chunk} from 'lodash';
import {observer} from 'mobx-react';
import Slider from "react-slick";

import {BreakpointsEnum} from '@u4i/common/enums/BreakpointsEnum';
import {injectBreakpoint} from '@u4i/common/injectBreakpoint';
import ChapterCard from '@u4i/common/ChapterCard';
import {SliderNavigation} from './SliderNavigation';
import {SliderSettings} from './SliderSettings';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './_horizontal-slider.scss';

const ITEMS_PER_ROW: Map<BreakpointsEnum, number> = new Map([
  [BreakpointsEnum.PHONE, 1,],
  [BreakpointsEnum.TABLET, 2],
  [BreakpointsEnum.DESKTOP, 3],
]);

interface IPropTypes {
  children: Array<React.ReactElement>;
  currentBreakpoint?: BreakpointsEnum;
  forceArrowsNavigation?: boolean;
}

export const HorizontalSlider = injectBreakpoint(observer(class HorizontalSlider extends React.Component<IPropTypes> {
  // children (Chapter card) component reference
  private childRef = React.createRef<ChapterCard>();
  private sliderRef = React.createRef<HTMLDivElement>();

  // component states:
  //   1. if the slider is sliding or not
  //   2. active slider slide index for mobile only
  //   3. slider reference to show the tooltip outside the slider
  state = {
    isSliderSliding: false,
    currentSlideIndex: 1,
    sliderRef: null
  };

  constructor(props: IPropTypes) {
    super(props);

    makeObservable(this, {
      currentPage: observable,
      itemsPerRow: computed,
      pages: computed
    });
  }

  componentDidMount() {
    this.setState({ sliderRef: this.sliderRef.current});
  }

  currentPage = 1;

  get itemsPerRow(): number {
    return ITEMS_PER_ROW.get(this.props.currentBreakpoint!) || 3;
  }

  get pages(): number {
    return Math.ceil(this.props.children.length / this.itemsPerRow);
  }

  render() {
    const { children } = this.props;
    const childrenChunks: Array<Array<React.ReactElement>> = chunk(this.props.children, this.itemsPerRow);

    // slider settings and event functions
    const settings = {
      ...SliderSettings,
      beforeChange: () => { 
        this.setState({
          isSliderSliding: true
        });
        if(this.childRef && this.childRef.current){
          this.childRef.current!.contractCard;
        } 
      },
      afterChange: (currentSlide) => this.setState({isSliderSliding: false, currentSlideIndex: currentSlide+1})
    };

  /**
   * @desc react landingpage component return cources within the slider
   */
    return (
      <div className="landing-page__horizontal-slider" ref={this.sliderRef}>
        <Slider {...settings} key={children.length}>
            {this.props.children.map((singleChunk, chunkIndex) => (
              <Fragment key={chunkIndex}>
                <div className="landing-page__horizontal-slider__contentMargin">
                  {React.cloneElement(singleChunk, { refs: this.childRef, isSliderSliding: this.state.isSliderSliding, sliderRef: this.state.sliderRef })}
                </div>
              </Fragment>
            ))}
        </Slider>

        {childrenChunks.length > 1 &&
          <SliderNavigation
            currentPage={this.state.currentSlideIndex}
            mobileVersion={this.props.currentBreakpoint === BreakpointsEnum.PHONE}
            pages={this.pages}
          />
        }
      </div>
    );
  }
}));
