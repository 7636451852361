import {defineMessages} from 'react-intl';

export default defineMessages({
  labsMobileHeading: {
    defaultMessage: 'Oops!',
    id: 'Player.ElementsDisplay.ExternalChallengeElement.labsMobileHeading',
  },
  digitalLabsMobileMessage: {
    defaultMessage: 'Digital lab challenges do not support mobile devices, please use a desktop.',
    id: 'Player.ElementsDisplay.ExternalChallengeElement.DigitalLabsMobileMessage',
  },
  jupyterLabsMobileMessage: {
    defaultMessage: 'Jupyter lab challenges do not support mobile devices, please use a desktop.',
    id: 'Player.ElementsDisplay.ExternalChallengeElement.JupyterLabsMobileMessage',
  },
});
