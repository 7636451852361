import cx from 'classnames';
import React from 'react';

import {ICustomFieldProps} from '../../Field';

import './_numeric-field.scss';

export interface IPropTypes extends ICustomFieldProps<number> {
  centerInputContent?: boolean;
  label?: React.ReactNode;
  step: number;
  width?: number;
}

export class NumericField extends React.Component<IPropTypes> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const splitValue = event.target.value.split('.');

    if (splitValue[1] && splitValue[1].length > 2) {
      return;
    }

    this.props.onChange(parseFloat(event.target.value));
  };

  render() {
    return (
      <div
        className={cx({
          'reactive-forms__fields__numeric': true,
          'reactive-forms__fields__numeric--dirty': this.props.meta.dirty,
          'reactive-forms__fields__numeric--focused': this.props.meta.focused,
          'reactive-forms__fields__numeric--invalid': this.props.meta.touched && this.props.meta.error,
        })}
        style={{width: this.props.width || 'auto'}}
      >
        <input
          className={cx({
            'reactive-forms__fields__numeric__input': true,
            'reactive-forms__fields__numeric__input--center-content': this.props.centerInputContent === true,
          })}
          disabled={this.props.disabled}
          id={this.props.id}
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          onFocus={this.props.onFocus}
          step={this.props.step}
          type="number"
          value={this.props.value}
        />
    
        {this.props.label &&
          <label className="reactive-forms__fields__numeric__label" htmlFor={this.props.id}>
            {this.props.label}
          </label>
        }
    
        {(this.props.meta.touched && this.props.meta.error) &&
          <div className="reactive-forms__fields__numeric__error">
            {this.props.meta.error}
          </div>
        }
      </div>
    );
  }
}
