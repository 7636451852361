import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';

interface IProps {
  numberOfChallenges: number;
  showCertificate: boolean;
}

export const CertificationStatusHeading: FC<IProps> = (props: IProps) => {
  const {
    numberOfChallenges, 
    showCertificate
  } = props;

  if (numberOfChallenges === 0) {
    return <FormattedMessage {...intlMessages.contentViewedHeading} />;
  }

  if (showCertificate) {
    return <FormattedMessage {...intlMessages.certificateHeading} />;
  }

  return <FormattedMessage {...intlMessages.challengesHeading} />;
};
