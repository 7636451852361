import React from 'react';
import {observer} from 'mobx-react';

import {BaseSection} from '../../state/sections/BaseSection';

interface IPropTypes {
  sections: Array<BaseSection>;
}

export const SectionDisplayer = observer(class SectionDisplayer extends React.Component<IPropTypes> {
  render() {
    return this.props.sections
      .filter(section => section.isVisible)
      .map(section => section.render());
  }
});
