import {IApi} from "@u4i/state/ServicesInterfaces";
import {IRootStore} from "@u4i/state/RootStore";

export class AdminUserProfilesStore {
  private apiService: IApi;
  constructor(rootStore: IRootStore) {
    const {apiService} = rootStore;

    this.apiService = apiService;
  }
}
