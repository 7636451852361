import moment from 'moment';
import React, { FC } from 'react';

import './_time-display.scss';

interface IProps {
  currentTime: number
  duration: number
}

const TimeDisplay: FC<IProps> = (props: IProps) => {
  const { currentTime, duration } = props;

  const showHours: boolean = moment.duration(duration).get('hours') > 0;
  const formatter = showHours ? 'HH:mm:ss' : 'mm:ss';
  const currentTimeFormatted: string = moment.utc(currentTime * 1000).format(formatter);
  const durationFormatted: string = moment.utc(duration * 1000).format(formatter);

  return (
    <div className="youtube-player__time-display">
      {currentTimeFormatted} / {durationFormatted}
    </div>
  );
}

export default TimeDisplay;
