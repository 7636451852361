import React, { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import { Modal } from 'antd';
import UserStore from '@u4i/state/UserStore';
import { LanguageStore } from '@u4i/state/LanguageStore';
import GeneralTerms from './GeneralTerms/GeneralTerms';
import ModalContent from '../TermsOfServiceModal/ModalContent';

interface IProps {
  languageStore: LanguageStore
  userStore: UserStore
}

const GeneralTermsOfServiceModal: FC<IProps> = (props: IProps) => {
  const {
    isPrivacyPolicyModalOpen,
    arrayOfModals,
    isGeneralModalOpen,
    acceptGeneralTermsOfService,
    acceptUserGroupTermsOfService,
    closeGeneralModal,
    closePolicyModal
  } = props.userStore;

  const [modalsConfig,setModalsConfig] = useState<any>(arrayOfModals.reduce((o, key) => ({ ...o, [key.id]: true}), {}));
  const [generalModal,setGeneralModal] = useState<boolean>(isGeneralModalOpen);

  const handleAccept = () => acceptGeneralTermsOfService().then(() => {
    closeGeneralModal();
    setGeneralModal(false);
  });

  const handleAcceptGroup =(currentModal, isLastModal)=>{
     acceptUserGroupTermsOfService(true, currentModal.termsOfServiceModalConfigs.tosId).then(()=>{
      setModalsConfig((prev:any)=>({
        ...prev,
        [currentModal.id]:false
      }));
     });
     
     if(isLastModal){
      closePolicyModal();
     }
  }

  return (
    <>
      {isGeneralModalOpen && 
        <Modal 
          centered
          closable={false}
          destroyOnClose={true}
          footer={null}
          keyboard={false}   // Prevents closing modal on ESC button press.
          mask={true}
          maskClosable={false}
          open={generalModal}
          zIndex={49998}
          style={{minWidth: '31%'}}
        >
          <GeneralTerms onClickAccept={handleAccept} languageStore={props.languageStore!} />
        </Modal>
      }

      {isPrivacyPolicyModalOpen && 
        <>
          {arrayOfModals.map((modal:any, index)=>{
            return <Modal 
              centered
              closable={false}
              destroyOnClose={true}
              footer={null}
              keyboard={false}   // Prevents closing modal on ESC button press.
              key={index}
              mask={true}
              maskClosable={false}
              open={modalsConfig[modal.id]}
              style={{minWidth: '31%'}}
              zIndex={9999}
            >
              <ModalContent 
                sanitizedContent={DOMPurify.sanitize(modal.termsOfServiceModalConfigs.content)} 
                isLastModal={index === 0} 
                currentModal={modal} 
                sendAnswer={handleAcceptGroup}
              /> 
            </Modal>
          })}
        </>
      }
    </>
  )
}

export default GeneralTermsOfServiceModal;
