import cx from 'classnames';
import keycode from 'keycode';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {Component} from 'react';
import screenfull from 'screenfull';
import Youtube from 'react-youtube';
import {throttle} from 'lodash';
import {noop} from 'common/utils';
import Controls from './Controls';
import CircularProgress from '../CircularProgress';
import { XAPIVerbs } from '../enums/XApiEnum';
import { postStatement } from '../TraxLrs';
import './_youtube-player.scss';

const PlayerState = {
  BUFFERING: 3,
  CUED: 5,
  ENDED: 0,
  PAUSED: 2,
  PLAYING: 1,
  UNSTARTED: -1,
};

const SHORTEST_FRAME_DURATION = 1000 / 60;

class YoutubePlayer extends Component {
  static propTypes = {
    height: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    maintainAspectRatio: PropTypes.bool,
    onReady: PropTypes.func,
    onStateChange: PropTypes.func,
    elementId: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    videoId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    height: '100%',
    maintainAspectRatio: false,
    onReady: noop,
    onStateChange: noop,
  };

  didStart = false;
  frameRef = React.createRef();
  playerRef = React.createRef();
  pollingEnabled = false;

  state = {
    availablePlaybackRates: [1],
    controlsVisible: false,
    currentTime: 0,
    duration: 0,
    isFullscreen: false,
    isMuted: false,
    playbackRate: 1,
    playerSet: false,
    playerState: PlayerState.UNSTARTED,
    ready: false,
    volume: 100,
  };

  constructor() {
    super();

    this.pollProgress = throttle(this.pollProgress, SHORTEST_FRAME_DURATION).bind(this);
  }

  componentDidMount() {
    if (screenfull.isEnabled) {
      screenfull.on('change', this.handleFullscreenChange);
    }
  }

  componentWillUnmount() {
    if (screenfull.isEnabled) {
      screenfull.off('change', this.handleFullscreenChange);
    }

    if (this.playerInterval) {
      clearInterval(this.playerInterval);
    }
  }

  handleFullscreen = () => {
    if (screenfull.isEnabled) {
      if (this.state.isFullscreen) {
        screenfull.exit();
        return;
      }

      screenfull.request(this.playerRef.current);
    } else {
      alert('Your browser doesn\'t support fullscreen mode');
    }
  };

  handleFullscreenChange = () => {
    if (screenfull.isEnabled) {
      this.setState({isFullscreen: screenfull.element === this.playerRef.current});
    }
  };

  handleKeyControl = (event) => {
    const key = keycode(event);
    event.preventDefault();

    switch (key) {
      case 'escape':
        if (this.state.isFullscreen) { this.handleFullscreen(); }
        break;

      case 'f11':
        this.handleFullscreen();
        break;

      case 'space':
        this.togglePlayback();
        break;

      default:
    }
  };

  handlePlaybackRateChange = (event) => {
    const newPlaybackRate = event.data;

    this.setState({playbackRate: newPlaybackRate});
  };

  handlePlaybackRateSet = (newPlaybackRate) => {
    this.frameRef.current.internalPlayer.setPlaybackRate(newPlaybackRate);
  };

  handleReadiness = () => {
    this.reloadAvailablePlaybackRates();
    this.setState({ready: true}, this.props.onReady);
  }

  handleStateChange = (event) => {
    const newState = event.data;

    this.setState({playerState: newState}, async () => {
      const player = this.frameRef.current.internalPlayer;
      const {videoId} = this.props;
      const parsedQuery = qs.parse(this.props.url, {ignoreQueryPrefix: true});

      //can't extract the start parameter directly from the parsedQuery
      const startIndexStart = videoId.indexOf('?start=');
      const startIndexEnd = videoId.substring(startIndexStart).indexOf('&');
      const videoStart = startIndexStart === -1 ? 0 : videoId.slice(startIndexStart + 7, startIndexStart + startIndexEnd);
      let videoEnd = parsedQuery.end;

      //if end parameter comes directly after video id (so no start parameter is specified), can't extract it directly from the parsedQuery
      if (!videoEnd) {
        const endIndexStart = videoId.indexOf('?end=');
        const endIndexEnd = videoId.substring(endIndexStart).indexOf('&');
        videoEnd = endIndexStart === -1 ? 0 : videoId.slice(endIndexStart + 5, endIndexStart + endIndexEnd);
      }

      const videoDuration = await player.getDuration();

      switch (newState) {
        case PlayerState.ENDED:
          this.pollingEnabled = false;
          postStatement(XAPIVerbs.watched, 'element', this.props.elementId, this.props.title);
          player.stopVideo();
          break;
        case PlayerState.PAUSED:
          postStatement(XAPIVerbs.paused, 'element', this.props.elementId, this.props.title)
          break;
        case PlayerState.PLAYING:        
          this.didStart = true;
          postStatement(XAPIVerbs.played, 'element', this.props.elementId, this.props.title);

          if (!this.state.playerSet) {
            player.seekTo(videoStart);

            this.setState({
              playerSet: true,
            });
          }

          if (videoEnd) {
            this.playerInterval = setInterval(() => {
              player.getCurrentTime().then((value) => {
                if (value >= videoEnd) {
                  clearInterval(this.playerInterval);

                  player.stopVideo();
                }
              });
            }, 1000);
          }

          this.setState({duration: videoDuration}, () => {
            this.pollingEnabled = true;
            this.pollProgress();
          });
          break;

        default:
          this.pollingEnabled = false;
      }

      this.props.onStateChange(newState);
    });
  };

  handleVolumeChange = (newVolume) => {
    this.setState({isMuted: false, volume: newVolume}, () => {
      this.frameRef.current.internalPlayer.unMute();
      this.frameRef.current.internalPlayer.setVolume(newVolume);
    });
  };

  hideControls = (event) => {
    if (this.playerRef.current.contains(event.target)) {
      this.setState({controlsVisible: false});
    }
  };

  pausePlayback = () => {
    if (!this.didStart) {
      this.frameRef.current.internalPlayer.stopVideo();
    } else {
      this.frameRef.current.internalPlayer.pauseVideo();
    }
  };

  async pollProgress() {
    if (!this.pollingEnabled) {
      return;
    }

    if (this.frameRef.current) {
      const currentTime = await this.frameRef.current.internalPlayer.getCurrentTime();

      this.setState({currentTime}, this.pollProgress);
    } else {
      this.pollingEnabled = false;
    }
  }

  reloadAvailablePlaybackRates = async () => {
    const playbackRates = await this.frameRef.current.internalPlayer.getAvailablePlaybackRates();

    this.setState({availablePlaybackRates: playbackRates});
  };

  seekTo = (time) => {
    this.frameRef.current.internalPlayer.seekTo(time);
  };

  showControls = (event) => {
    if (this.playerRef.current.contains(event.target)) {
      this.setState({controlsVisible: true});
    }
  };

  startPlayback = () => {
    this.frameRef.current.internalPlayer.playVideo();
  };

  toggleSound = () => {
    if (this.state.isMuted) {
      this.frameRef.current.internalPlayer.unMute();
    } else {
      this.frameRef.current.internalPlayer.mute();
    }

    this.setState(prevState => ({isMuted: !prevState.isMuted}));
  };

  togglePlayback = () => {
    if (this.state.playerState === PlayerState.PLAYING) {
      if (!this.didStart) {
        this.frameRef.current.internalPlayer.stopVideo();
      } else {
        this.frameRef.current.internalPlayer.pauseVideo();
      }
    } else {
      this.frameRef.current.internalPlayer.playVideo();
    }
  };

  render() {
    const startIndex = this.props.videoId.indexOf('?start=');
    const videoId = startIndex !== -1 ? this.props.videoId.slice(0, startIndex) : this.props.videoId;

    return (
      <div
        className={cx({
          'youtube-player': true,
          'youtube-player--forced-ratio': this.props.maintainAspectRatio,
          'youtube-player--fullscreen': this.state.isFullscreen,
        })}
        onKeyDown={this.handleKeyControl}
        onMouseEnter={this.showControls}
        onMouseLeave={this.hideControls}
        onMouseMove={this.showControls}
        ref={this.playerRef}
        style={{
          height: this.state.isFullscreen ? '100%' :
            (this.props.maintainAspectRatio ? 'auto' : this.props.height),
        }}
      >
        {this.state.ready ?
          <Controls
            availablePlaybackRates={this.state.availablePlaybackRates}
            currentTime={this.state.currentTime}
            duration={this.state.duration}
            isFullscreen={this.state.isFullscreen}
            isMuted={this.state.isMuted}
            isPlaying={this.state.playerState === PlayerState.PLAYING}
            onFullscreenClick={this.handleFullscreen}
            onPlaybackRateSet={this.handlePlaybackRateSet}
            onPlaybackToggle={this.togglePlayback}
            onSeekTo={this.seekTo}
            onSoundToggle={this.toggleSound}
            onVolumePercentageChange={this.handleVolumeChange}
            playbackRate={this.state.playbackRate}
            visible={this.state.controlsVisible}
            volumePercentage={this.state.volume}
          />
          :
          <div className="youtube-player__cover">
            <div className="youtube-player__loading-wrapper">
              <CircularProgress />
            </div>
          </div>
        }

        <div className="youtube-player__frame">
          <Youtube
            containerClassName="youtube-player__frame-inner"
            onPlaybackRateChange={this.handlePlaybackRateChange}
            onReady={this.handleReadiness}
            onStateChange={this.handleStateChange}
            style={{
              height:'100%'
            }}
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                autohide: 1,
                cc_load_policy: 0,
                controls: 0,
                disablekb: 1,
                iv_load_policy: 3,
                loop: 0,
                modestbranding: 0,
                playsinline: 1,
                rel: 0,
              },
            }}
            ref={this.frameRef}
            videoId={videoId}
          />
        </div>
      </div>
    );
  }
}

export default YoutubePlayer;
