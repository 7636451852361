import cx from 'classnames';
import is from 'is_js';
import React from 'react';
import Rellax, {RellaxInstance} from 'rellax';
import {action, observable, runInAction,makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import DOMPurify from 'dompurify';
import ImageProcessor from '@u4i/common/ImageProcessor';
import {TitleHeading} from './TitleHeading';

import './_header.scss';

interface IPropTypes {
  description?: string;
  headerBackgroundUrl: string;
  logoUrl: string;
  mobileImageUrl?: string;
  subtitle: string;
  title: string;
}

export const Header = observer(class Header extends React.Component<IPropTypes> {
  private headerBackgroundRef = React.createRef<HTMLDivElement>();
  private headingRef = React.createRef<HTMLHeadingElement>();
  private isDesktop = is.desktop();
  private rellaxHeaderBackground: RellaxInstance;
  private rellaxHeading: RellaxInstance;
  private backgroundBrightness: number;
  private backgroundImage: string;


  constructor(props: IPropTypes) {
    super(props);

    makeObservable<Header, "backgroundBrightness" | "backgroundImage">(this, {
      backgroundBrightness: observable,
      backgroundImage: observable,
      setupNewBackgroundImage: action.bound
    });
  }


  componentDidMount() {
    this.setupNewBackgroundImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.headerBackgroundUrl !== prevProps.headerBackgroundUrl) {
      this.setupNewBackgroundImage();
    }
  }

  componentWillUnmount() {
    if (this.isDesktop) {
      this.destroyParallax();
    }
  }

  destroyParallax = () => {
    if (this.rellaxHeaderBackground) {
      this.rellaxHeaderBackground.destroy();
    }

    if (this.rellaxHeading) {
      this.rellaxHeading.destroy();
    }
  };

  async setupNewBackgroundImage() {
    const imageUrl = `${this.props.headerBackgroundUrl}?x-cors-fix=s3`;
    const image = await ImageProcessor.loadImageFromUrl(imageUrl);

    const processor = new ImageProcessor(image);
    const brightness = await processor.getAveragePerceivedImageBrightness();

    runInAction(() => {
      this.backgroundBrightness = brightness;
      this.backgroundImage = processor.getImageURL();
    });
    
    this.setupParallax();
  }

  setupParallax = () => {
    if (this.isDesktop) {
      this.destroyParallax();

      if (this.headerBackgroundRef && this.headerBackgroundRef.current) {
        this.rellaxHeaderBackground = new Rellax(this.headerBackgroundRef.current, {speed: 6});
      }

      if (this.headingRef && this.headingRef.current) {
        this.rellaxHeading = new Rellax(this.headingRef.current, {speed: -4});
      }
    }
  };

  render() {
    return (
      <header>
        <div className="landing-page__header__background-container">
          <div
            className={cx({
              'landing-page__header__background': true,
              'landing-page__header__background--desktop': true,
              'landing-page__header__background--forced': !this.props.mobileImageUrl,
              'landing-page__header__background--fixed': this.isDesktop,
            })}
            ref={this.headerBackgroundRef}
            style={{backgroundImage: `url(${this.backgroundImage})`}}
          />

          {this.props.mobileImageUrl &&
            <div
              className="landing-page__header__background landing-page__header__background--mobile"
              style={{backgroundImage: `url(${this.props.mobileImageUrl})`}}
            />
          }

          <TitleHeading
            backgroundBrightness={this.backgroundBrightness}
            headingRef={this.headingRef}
            isVisible={!!this.backgroundBrightness}
            subtitle={this.props.subtitle}
            title={this.props.title}
          />
        </div>

        <div className="container">
          <div className="landing-page__header__container mb-16">
            <div className="landing-page__header__logo">
              <img src={this.props.logoUrl} alt="Logo" />
            </div>

            {this.props.description &&
              <div 
                className="landing-page__header__description" 
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.description)}} 
              ></div>
            }
          </div>
        </div>
      </header>
    );
  }
});
