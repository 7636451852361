import cx from 'classnames';
import React, { FC } from 'react';
import SuccessIcon from '@material-ui/icons/Done';
import {FormattedMessage} from 'react-intl';

import CircularProgress from '@u4i/common/CircularProgress';

import intlMessages from './intlMessages';
import { green } from '@u4i/styles/styles-variables';
import './_submit-window.scss';

interface IPropTypes {
  isVisible: boolean
  loadingInProgress: boolean
  onGoBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const SubmitWindow: FC<IPropTypes> = (props: IPropTypes) => (
  <div
    className={cx({
      'jupyter-lab__submit-window': true,
      'jupyter-lab__submit-window--visible': props.isVisible,
    })}
  >
    <div className="jupyter-lab__submit-window__box">
      {props.loadingInProgress ?
        <CircularProgress />
        :
        <div className="jupyter-lab__submit-window__content">
          <SuccessIcon style={{color: green, height: 52, width: 52}} />

          <p className="jupyter-lab__submit-window__message">
            <FormattedMessage {...intlMessages.successMessage} />
          </p>

          <button
            className="jupyter-lab__submit-window__button"
            onClick={props.onGoBackClick}
            type="button"
          >
            <FormattedMessage {...intlMessages.goBack} />
          </button>
        </div>
      }
    </div>
  </div>
);
