import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_feedback-link-element.scss';

interface IPropTypes {
  calculatedFontSize: number
  onFeedbackSurveyStart: () => void
}

export const FeedbackLinkElement: FC<any> = (props: any) => (
  <div className="player__feedback-link-element" style={{fontSize: props.calculatedFontSize}}>
    <h3 className="player__feedback-link-element__title">
      <FontAwesome
        className="far player__feedback-link-element__icon"
        name="comment-dots"
      />

      <FormattedMessage {...intlMessages.title} />
    </h3>

    <button
      className="player__feedback-link-element__button"
      onClick={props.onFeedbackSurveyStart}
      type="button"
    >
      <FormattedMessage {...intlMessages.name} />
    </button>
  </div>
);
