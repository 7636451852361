import {defineMessages} from 'react-intl';

export default defineMessages({
  allLanguages: {
    defaultMessage: 'All languages',
    id: 'Subnavigation.LanguageSwitch.AllLanguages',
  },
  languageEnglish: {
    defaultMessage: 'English',
    id: 'Subnavigation.LanguageSwitch.LanguageEnglish',
  },
  languageGerman: {
    defaultMessage: 'German',
    id: 'Subnavigation.LanguageSwitch.LanguageGerman',
  },
  languageCzech: {
    defaultMessage: 'Czech',
    id: 'Subnavigation.LanguageSwitch.LanguageCzech',
  },
  languageKorean: {
    defaultMessage: 'Korean',
    id: 'Subnavigation.LanguageSwitch.LanguageKorean',
  },
  languageJapanese: {
    defaultMessage: 'Japanese',
    id: 'Subnavigation.LanguageSwitch.LanguageJapanese',
  },
  languageThai: {
    defaultMessage: 'Thai',
    id: 'Subnavigation.LanguageSwitch.LanguageThai',
  },
  languageChinese: {
    defaultMessage: 'Chinese',
    id: 'Subnavigation.LanguageSwitch.LanguageChinese',
  }
});
