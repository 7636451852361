/**
* @dev_date July 2022
* @desc This component keeps store for the Register Confirm Page
* @author Denis Shtupa denis.shtupa@u4i.io
*/

import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import RequestBucket from '@u4i/common/RequestBucket';
import { IRootStore } from '@u4i/state/RootStore';
import api from '@u4i/utils/api';
import { action, makeObservable, observable } from 'mobx';
import UserStore from '../../../state/UserStore';

const REQUEST_IDS = {fetchPageData: 'fetchPageData'};
const requestBucket = new RequestBucket();

export class RegisterConfirmPageStore {
  private userStore: UserStore;
  public fetching: boolean = false;
  public isTokenValid: boolean = false;
  public invalidTokenError: boolean =  false;
  public courseRedirectUrl: string = '';

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      fetching: observable,
      invalidTokenError: observable,
      isTokenValid: observable,
      courseRedirectUrl: observable,
      confirmRegistration: action.bound,
      fetchPageData: action.bound,
      resetState: action.bound
    });

    const { userStore } = rootStore;
    this.userStore = userStore;
  }

  confirmRegistration = async (token: string) => {
    try{
      this.userStore.confirmRegistration(token);
    }
    catch(error) {
      throw error;
    }
  }

  fetchPageData = async(token: string) => {
    this.fetching = true;
    try {
      requestBucket.cancel(REQUEST_IDS.fetchPageData);
      const cancelToken = requestBucket.generateToken(REQUEST_IDS.fetchPageData);

      await api.get(`/user/register-confirm/${token}`, {cancelToken});

      const response = await api.get(`/user/register-confirm/${token}`, {cancelToken});

      if(response.data?.userInvite?.register_ref && response.data?.userInvite?.register_ref != "") {
        this.courseRedirectUrl = response.data.userInvite?.register_ref;
      }

      this.isTokenValid = true;
    } catch(error) {
      if (error.response.status === 404) {
        this.invalidTokenError = true;
        return;
      }      
      this.resetState();
      AppNavigator.replace(appRoutes.home);
    }
    finally {
      this.fetching = false;
    }
  }

  resetState = () => {
    this.isTokenValid = false;
    this.invalidTokenError = false;
    requestBucket.cancelAll();
  }
}
