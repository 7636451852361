import React, {Component} from 'react';
import SeekTooltip from './SeekTooltip';

import './_progressbar.scss';

interface IProps {
  duration: number
  progressPercentage: number
  onSeekTo: (val: any) => any
}

class Progressbar extends Component<IProps> {

  state = {
    tooltipPositionPercentage: 0,
    tooltipVisible: false,
  };

  containerRef: any = React.createRef();

  calculateMousePositionFactorial = (mouseX) => {
    const containerBoundingRect = this.containerRef.current.getBoundingClientRect();

    const totalDistance = containerBoundingRect.width;
    const clickDistanceX = mouseX - containerBoundingRect.left;
    const normalizedClickDistanceX = Math.max(Math.min(clickDistanceX, totalDistance), 0);

    return normalizedClickDistanceX / totalDistance;
  };

  handleProgressbarClick = (event) => {
    const positionFactorial: any = this.calculateMousePositionFactorial(event.clientX);

    this.props.onSeekTo(positionFactorial);
  };

  hideTooltip = () => {
    this.setState({tooltipVisible: false});
  };

  showTooltip = (event) => {
    const positionFactorial = this.calculateMousePositionFactorial(event.clientX);

    this.setState({tooltipPositionPercentage: positionFactorial * 100, tooltipVisible: true});
  };

  updateTooltip = (event) => {
    const positionFactorial = this.calculateMousePositionFactorial(event.clientX);

    this.setState({tooltipPositionPercentage: positionFactorial * 100});
  };

  render() {
    return (
      <button
        className="youtube-player__progressbar"
        onClick={this.handleProgressbarClick}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        onMouseMove={this.updateTooltip}
        ref={this.containerRef}
      >
        <div
          className="youtube-player__progressbar__fill"
          style={{width: `${this.props.progressPercentage}%`}}
        />

        <SeekTooltip
          isVisible={this.state.tooltipVisible}
          positionPercentage={this.state.tooltipPositionPercentage}
          targetTime={(this.state.tooltipPositionPercentage / 100) * this.props.duration}
        />
      </button>
    );
  }
}

export default Progressbar;
