import {defineMessages} from 'react-intl';

export default defineMessages({
  chapters: {
    defaultMessage: 'Chapters',
    id: 'CourseDetailPage.MenuBar.Navigation.Chapters',
  },
  overview: {
    defaultMessage: 'Overview',
    id: 'CourseDetailPage.MenuBar.Navigation.Overview',
  },
  qualificationCertificate: {
    defaultMessage: 'Qualification certificate',
    id: 'CourseDetailPage.MenuBar.Navigation.QualificationCertificate',
  },
});
