import React, { FC, useEffect, useState } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import CustomInputField from '../components/TextField/CustomInputField';
import intlMessages from './intlMessages';
import UserStore from '@u4i/state/UserStore';
import { Form, Select, Typography } from 'antd';
import axios from 'axios';
import countryParser from '@u4i/parsers/CountryParser';
import api from '@u4i/utils/api';
import { guardsmanRed, mainBlue } from '@u4i/styles/styles-variables';
import CircularProgress from '@u4i/common/CircularProgress';
import { TermsSection } from '../components/TermsSection/TermsSection';
import { LanguageStore } from '@u4i/state/LanguageStore';
import { CheckoutPurchaseStore } from '@u4i/modules/Checkout/state/CheckoutStore';
import { CourseDetailPageStore } from '@u4i/modules/CourseDetailPage/state/CourseDetailPageStore';
import { observer } from 'mobx-react';
import { FilterOptions } from '@u4i/common/Interfaces/FilterOptionsInterface';
import cx from "classnames";
import { ICountry } from '@u4i/modules/CourseDetailPage/interfaces/CourseInterface';
import "./_course-purchase-form.scss";

const {Item} = Form;
const {Option} = Select;
const {Title} = Typography;

interface IProps extends WrappedComponentProps {
  checkoutStore?: CheckoutPurchaseStore
  courseDetailPageStore?: CourseDetailPageStore
  languageStore?: LanguageStore
  userStore?: UserStore
}

const CoursePurchaseForm: FC<IProps> = (props: IProps) => {
  const {
    languageStore,
    userStore
  } = props;

  const {
    successRedirectionPathname,
    userAddressData,
    fetchCurrentUserData,
    purchaseCourseModalHandle
  } = userStore!;

  const {formatMessage} = props.intl;
  const [purchaseForm] = Form.useForm<any>();
  const [activeInput, setActiveInput] = useState<boolean>(false);
  const [hasError, setError] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [countriesConfigs, setCountriesConfigs] = useState<any>({
    countries: [],
    countriesRequestCancelSource: null,
    countriesLoading: true
  });

  const cityLabel: string = formatMessage(intlMessages.cityLabel);
  const cityPresenceError: string = formatMessage(intlMessages.cityErrorsPresence);
  const countryLabel: string = formatMessage(intlMessages.countryLabel);
  const countryPresenceError: string = formatMessage(intlMessages.countryErrorsPresence);
  const postalCodeLabel: string = formatMessage(intlMessages.postalCodeLabel);
  const postalCodePresenceError: string = formatMessage(intlMessages.postalCodeErrorsPresence);
  const postalCodeMaxLengthError: string = formatMessage(intlMessages.postalCodeErrorsMaxLength, {limit: 5});
  const purchaseCourseLabel: string = formatMessage(intlMessages.purchaseCourseLabel);
  const purchaseCourseButton: string = formatMessage(intlMessages.purchaseCourseButton);
  const streetLabel: string = formatMessage(intlMessages.streetLabel);
  const streetPresenceError: string = formatMessage(intlMessages.streetErrorsPresence);

  const isEmptyValue: boolean = purchaseForm.getFieldValue('country') == "" || purchaseForm.getFieldValue('country') === undefined ? true : false;

  useEffect(() => {
    loadCountries();
    return () => {
      countriesConfigs.countriesRequestCancelSource?.cancel();
    }
  }, [])

  useEffect(() => {
    fetchCurrentUserData();
  }, [])

  const handleCheckbox = (checked: boolean) => {
    setChecked(checked);
  }

  const loadCountries = () => {
    const countriesRequestCancelSource = axios.CancelToken.source();
    setCountriesConfigs({countriesRequestCancelSource})
    api.get('countries', {
      cancelToken: countriesRequestCancelSource.token,
    }).then((response) => {
      const parsedData = response?.data.map(entry => countryParser.parse(entry));

      const selectFieldOptions: ICountry = parsedData.map(entry => ({
        label: entry.name,
        value: entry.id
      }));

      setCountriesConfigs({
        countries: selectFieldOptions,
        countriesLoading: false,
        countriesRequestCancelSource: null,
      });
    });
  }

  const filterOption = (input: string, option: FilterOptions) => {
    return option!.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
  };

  const onHandlePurchase = (values: any) => {
    props.checkoutStore?.startPayment(props.courseDetailPageStore?.courseData?.id!, successRedirectionPathname, values).then(res => {
      if(props.checkoutStore?.isCoursePurchased) {
        purchaseCourseModalHandle(false);
        props.courseDetailPageStore?.resetState();
        props.courseDetailPageStore?.loadCourseData(successRedirectionPathname);
        props.courseDetailPageStore?.loadChapters(successRedirectionPathname);
        userStore?.resetUserAddressData();
      }
    })
  }

  setTimeout(() => {
    if (purchaseForm.getFieldError('country').length > 0) {
      return setError(true);
    }
    return setError(false);
  }, 100);
  
  if(props.courseDetailPageStore?.fetching) {
    return <CircularProgress/>;
  }

  return (
    <div className='custom-form-fields'>
      <Form onFinish={onHandlePurchase} form={purchaseForm} requiredMark={'optional'}>
        {userAddressData ?
          <>
            <div style={{textAlign: 'center', paddingRight: 30, paddingBottom: 15}}>
              <Title level={5}>{purchaseCourseLabel}</Title>
            </div>

            <Item
              name='addressLine1'
              initialValue={userAddressData?.street?.toString()}
              rules={[
                {
                  message: streetPresenceError,
                  required: true,
                  whitespace: true
                },
              ]}
            >
              <CustomInputField
                label={streetLabel}
                type="text"
                name="addressLine1"
                form={purchaseForm}
              />
            </Item>

            <Item
              name="zipCode"
              initialValue={userAddressData?.zip?.toString()}
              rules={[
                {
                  message: postalCodePresenceError,
                  required: true,
                  whitespace: true
                },
                {
                  max: 5,
                  message: postalCodeMaxLengthError
                }
              ]}
            >
              <CustomInputField
                label={postalCodeLabel}
                type="text"
                name="zipCode"
                noMarginBottom={true}
                form={purchaseForm}
              />
            </Item>

            <Item
              name="city"
              initialValue={userAddressData?.city?.toString()}
              rules={[
                {
                  message: cityPresenceError,
                  required: true,
                  whitespace: true
                }
              ]}
              >
              <CustomInputField
                label={cityLabel}
                type="text"
                name="city"
                noMarginBottom={true}
                form={purchaseForm}
              />
            </Item>

            {countriesConfigs.countries?.length > 0 &&
              <Item
                label={<span className="country-field__label" style={{ color: (!isEmptyValue || activeInput || (userAddressData?.country?.toString() != "" && userAddressData?.country != undefined)) ? mainBlue : hasError ? guardsmanRed : 'rgba(0, 0, 0, 0.26)'}}><FormattedMessage {...intlMessages.countryLabel} /></span>}
                labelCol={{ span: 24 }}
                name="country"
                valuePropName="option"
                initialValue={userAddressData?.country?.toString()}
                style={{marginBottom: 20}}
                className={cx({
                  "disable-invert": true,
                  "country-field--dirty": !isEmptyValue,
                  "country-field--focused": activeInput
                })} 
                rules={[
                  {
                    message: countryPresenceError,
                    required: true
                  }
                ]}
              >
                <Select
                  allowClear
                  filterOption={filterOption}
                  loading={countriesConfigs.countriesLoading}
                  onBlur={() => setActiveInput(false)}
                  onFocus={() => setActiveInput(true)}
                  placeholder={countryLabel}
                  defaultValue={userAddressData?.country}
                  showSearch
                >
                  {countriesConfigs.countries.map((country: any) => (
                    <Option key={country.value} value={country.value}>{country.label}</Option>
                  ))}
                </Select>
              </Item>
            }

            <TermsSection
              checked={checked}
              form={purchaseForm}
              isLoginForm={true}
              onCheckboxChange={(val) => handleCheckbox(val)}
              languageStore={languageStore!}
            />
            <SubmitButton>
              {purchaseCourseButton}
            </SubmitButton>
          </>
          :
          <CircularProgress/>
        }
      </Form>
    </div>
  )
}

export default injectIntl(observer(CoursePurchaseForm))
