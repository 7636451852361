import Parser from '@u4i/utils/Parser';

export interface IAdminGrader {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  username: string;
}

export const AdminAvailableGradersParser = new Parser<IAdminGrader>(adminAvailableGrader => ({
  email: adminAvailableGrader.email,
  firstName: adminAvailableGrader.firstName,
  id: adminAvailableGrader.id,
  lastName: adminAvailableGrader.lastName,
  username: adminAvailableGrader.username,
}));
