import React, { FC, Fragment } from "react";
import { SummaryPricing } from "./SummaryPricing/SummaryPricing";
import CommonCard from "@u4i/common/Cards/CommonCard";
import { ICourse } from "@u4i/modules/CourseDetailPage/interfaces/CourseInterface";

interface IPropTypes {
  course: ICourse
  disableHovering?: boolean
  handleCardClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const CourseDetails: FC<IPropTypes> = (props) => {
  const {
    duration,
    image,
    is_completed,
    language,
    number_of_challenges,
    number_of_completed_challenges,
    price_summary,
    progress,
    title
  } = props.course;

  const { discount, price_net, purchase_price, vat } = price_summary;

  return (
    <Fragment>
      <CommonCard
        title={title}
        disableHovering={props.disableHovering}
        duration={duration}
        image={image.url}
        language={language}
        onCardClick={props.handleCardClick}
        progress={progress}
        isCompleted={is_completed}
        numberOfChallenges={number_of_challenges}
        numberOfCompletedChallenges={number_of_completed_challenges}
        isSkilCard
      />
      {purchase_price.value > 0 && 
        <SummaryPricing
          discountAmount={discount.amount.formatted}
          discountPercentage={discount.percentage}
          isDiscounted={discount.amount.value > 0}
          priceNet={price_net.formatted}
          pricePurchase={purchase_price.formatted}
          vatAmount={vat.amount.formatted}
          vatPercentage={vat.percentage}
        />
      }
    </Fragment>
  );
};
