import Parser from '@u4i/utils/Parser';

export interface IFeedbackSubmission {
  id: string;
  userId: string;
  skillId: string;
  feedbackSurveyId: string;
  isCompleted: boolean;
}

export const FeedbackSubmissionParser = new Parser<IFeedbackSubmission>(feedback => ({
  id: feedback.id,
  userId: feedback.user_id,
  skillId: feedback.skill_id,
  feedbackSurveyId: feedback.feedback_survey_id,
  isCompleted: feedback.is_completed,
}));
