import { Row } from 'antd';
import React, {FC, Fragment} from 'react';

import EntryButton from './components/EntryButton';
import EntryLink from './components/EntryLink';

import './_entry.scss';

interface IPropTypes {
  href?: string;
  icon: React.ReactElement;
  label: React.ReactElement | React.ReactNode | string | any;
  type: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  render?:any
}

const Entry: FC<IPropTypes> = (props: IPropTypes) => {

  const {render:Component}=props;
  const content = (
    <Fragment>
      {React.cloneElement(props.icon, {
        className: 'main-layout__entry__icon',
        style: {color: '#757575'}
      })}
      {props.render ? <Row style={{
        flex:1
      }} justify='space-between'><span>{props.label}</span> <Component /></Row> : props.label}
    </Fragment>
  );

  return (
    <li className="main-layout__entry">
      {(props.type === 'button' && props.onClick) &&
        <EntryButton onClick={props.onClick}>
          {content}
        </EntryButton>
      }

      {(props.type === 'link' && props.href) &&
        <EntryLink href={props.href}>
          {content}
        </EntryLink>
      }
         {(props.type !== 'link' && props.type !== 'button') &&
        <>
          {content}
        </>
      }
    </li>
  );
}

export default Entry;
