import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject} from 'mobx-react';
import AppNavigator, {appRoutes} from '@u4i/common/AppNavigator';
import UserStore from '@u4i/state/UserStore';
import {ActionsBar, DataTable, DataTableStore, DateRangeFilter, SelectFilter, TextFilter} from '@u4i/common/DataTable';
import {AttemptStatusLabel} from '@u4i/common/AttemptStatusLabel';
import {ChallengeAttemptStatusEnum} from '@u4i/common/enums/ChallengeAttemptStatusEnum';
import {IRootStore} from '@u4i/state/RootStore';
import {UserRolesEnum} from '@u4i/common/enums/UserRolesEnum';
import intlMessages from './intlMessages';
import AttemptLanguageLabel from './components/AttemptLanguageLabel/AttemptLanguageLabel';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';

interface IPropTypes {
  rootStore: IRootStore;
}

interface RowDataShape {
  canGrade: boolean;
  challengeName: string;
  date: string;
  updatedAt: string;
  id: string;
  groupName: string;
  status: ChallengeAttemptStatusEnum;
  language: LanguageEnum;
  userName: string;
}

export const AttemptsList = inject('rootStore')(class AttemptsList extends React.Component<IPropTypes> {
  private dataTableStore: DataTableStore<RowDataShape>;
  private userStore: UserStore;

  constructor(props: IPropTypes) {
    super(props);

    this.userStore = props.rootStore.userStore;

    this.dataTableStore = new DataTableStore<RowDataShape>({
      dataColumns: {
        challengeName: {label: <FormattedMessage {...intlMessages.challengeName} />, order: 1, width: 200},
        userName: {
          label: <FormattedMessage {...intlMessages.userName} />,
          order: 2,
          sortable: this.userStore.userData.role === UserRolesEnum.GRADER_ADMIN,
          width: 150,
        },
        groupName: {label:  <FormattedMessage {...intlMessages.group} />, order: 3, width: 150},
        status: {
          contentFormatter: status => <AttemptStatusLabel status={status} />,
          label: <FormattedMessage {...intlMessages.status} />,
          order: 4,
          width: 120,
        },
        language: {
          contentFormatter: language => <AttemptLanguageLabel language={language} />,
          label: <FormattedMessage {...intlMessages.language} />,
          order: 5,
          width: 100,
        },
        updatedAt: {label:  <FormattedMessage {...intlMessages.date} />, order: 6, width: 200},
      },
      dataProvider: this.tableDataProvider,
      defaultSortDirection: 'ASC',
      defaultSortedColumnId: 'updatedAt',
      filters: [
        new TextFilter('challengeName', 'challengeName'),
        this.userStore.userData.role === UserRolesEnum.GRADER_ADMIN ? new TextFilter('userName', 'userName') : undefined,
        new TextFilter('groupName', 'groupName'),
        new SelectFilter('status', 'status', [{
          label: <FormattedMessage {...intlMessages.submitted} />,
          value: ChallengeAttemptStatusEnum.SUBMITTED,
        }, {
          label: <FormattedMessage {...intlMessages.passed} />,
          value: ChallengeAttemptStatusEnum.PASSED,
        }, {
          label: <FormattedMessage {...intlMessages.failed} />,
          value: ChallengeAttemptStatusEnum.FAILED,
        }], ChallengeAttemptStatusEnum.SUBMITTED),
        new SelectFilter('language', 'language', [{
          label: "EN",
          value: LanguageEnum.EN_US,
        }, {
          label: "DE",
          value: LanguageEnum.DE_DE,
        }]),
        new DateRangeFilter('updatedAt', 'dateFrom', 'dateTo'),
      ],
      functionalColumns: {
        actions: {
          centerContent: true,
          content: (rowData) => (
            <ActionsBar
              buttons={[
                {
                  iconName: 'star',
                  onClick: this.handleGradeClick,
                  tooltipLabel: <FormattedMessage {...intlMessages.evaluate} />,
                  visible: rowData.canGrade,
                },
                {
                  iconName: 'edit',
                  onClick: this.handleEditClick,
                  tooltipLabel: <FormattedMessage {...intlMessages.edit} />,
                  visible: !rowData.canGrade,

                }
              ]}
              rowId={rowData.id}
            />
          ),
          label: <FormattedMessage {...intlMessages.actions} />,
          order: 6,
          width: 80,
        },
      },
      immediatelyShowFilters: true,
      pagination: {
        enabled: true,
        itemsPerPage: 10,
      },
      tableId: 'attempts-list',
    }, props.rootStore);
  }

  handleGradeClick = (attemptId: string) => {
    AppNavigator.push(appRoutes.gradingEvaluation, {attemptId});
  };

  handleEditClick = (attemptId: string) => {
    AppNavigator.push(appRoutes.gradingAttemptEdit, {attemptId});
  };

  tableDataProvider = async (
    sortedColumnId: string | undefined,
    sortDirection: 'ASC' | 'DESC',
    filters: { [filterName: string]: any },
    paginationInfo: {limit: number; offset: number;} | undefined,
  ): Promise<{items: Array<RowDataShape>, totalItems: number}> => {
    return await this.props.rootStore.apiService.grading.fetchAttemptsList({
      filters,
      limit: paginationInfo ? paginationInfo.limit : 10,
      offset: paginationInfo ? paginationInfo.offset : 0,
      sortDirection,
      sortedColumnId,
    });
  };

  render() {
    return (
      <div className="container">
        <DataTable 
          isAttemptsView={true}  
          store={this.dataTableStore}
        />
      </div>
    );
  }
});
