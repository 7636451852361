import {defineMessages} from 'react-intl';

export default defineMessages({
  archived: {
    defaultMessage: 'Archived',
    id: 'AttemptStatusLabel.Archived',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'AttemptStatusLabel.Failed',
  },
  passed: {
    defaultMessage: 'Passed',
    id: 'AttemptStatusLabel.Passed',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'AttemptStatusLabel.Pending',
  },
  submitted: {
    defaultMessage: 'Submitted',
    id: 'AttemptStatusLabel.Submitted',
  },
});
