import cx from 'classnames';
import React, { FC } from 'react';
import {range} from 'lodash';

import './_slider-navigation.scss';

interface IProps {
  currentPage: number
  pages: number
  onDotClick: (pageNo: number) => void
}

export const SliderNavigation: FC<IProps> = (props: IProps) => {
  const {
    currentPage,
    pages,
    onDotClick
  } = props;

  return (
    <div className='course-detail-page__slider-navigation'>
      {range(pages).map(index => (
        <button
          className={cx({
            'course-detail-page__slider-navigation__navigation-dot': true,
            'course-detail-page__slider-navigation__navigation-dot--active': index + 1 === currentPage
          })}
          key={index}
          onClick={() => onDotClick(index + 1)}
        />
      ))}
    </div>
  )
}
