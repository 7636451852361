/**
 * @desc Cornerstone course assignments state management component
 * @author Denis Shtupa denis.shtupa@u4i.io
*/

import { IRootStore } from '@u4i/state/RootStore';
import { IApi, IPaginatedItems } from '@u4i/state/ServicesInterfaces';
import { TablePaginationConfig } from 'antd/es/table';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CornerstoneConfigTypesEnum, CornerstoneCourseAssignmentsDto, ICornerstoneInitialValues, ICourseSimplifiedObject, PostCornerstoneAssignment } from '../interfaces/CornerstoneInterfaces';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';

export class AdminCornerstoneCourseAssignmentStore {
    private apiService: IApi;
 
    public isAssignedCoursesTable: boolean = true;
    public fetching: boolean = true;
    public notAssignedCoursesList: ICourseSimplifiedObject[];
    public assignedCoursesList: ICourseSimplifiedObject[];
    public assignedCourseAssignmentDetails: any;
    public cornerstoneAssignmentsList: any;
    public isDeletingAssignment: boolean = false;
    public assignmentInitialValues: ICornerstoneInitialValues = {
        keywords: [],
        modalities: [],
        subjects: []
    }

    public apiConfigAssigned: AntDTableFilters<ICourseSimplifiedObject> = {
        current: 0,
        filters: {},
        limit: 10,
        offset: 0,
        orderBy: {},
    };

    public apiConfigNotAssigned:  AntDTableFilters<ICourseSimplifiedObject> = {
        current: 0,
        filters: {},
        limit: 10,
        offset: 0,
        orderBy: {},
    };
  
    public paginationAssigned: TablePaginationConfig = {
        current: 1,
        showSizeChanger: true,
    };
  
    public paginationNotAssigned: TablePaginationConfig = {
        current: 1,
        showSizeChanger: true,
    };

    public tableData: Array<ICourseSimplifiedObject> = [];
    public validationErrors?: {
        name?: string;
        type?: string;
    };
 
    constructor(rootStore: IRootStore) {
        makeObservable(this, {
            apiConfigAssigned: observable,
            apiConfigNotAssigned: observable,
            assignedCoursesList: observable,
            assignedCourseAssignmentDetails: observable,
            assignmentInitialValues: observable,
            cornerstoneAssignmentsList: observable,
            fetching: observable,
            isAssignedCoursesTable: observable,
            isDeletingAssignment: observable,
            notAssignedCoursesList: observable,
            paginationAssigned: observable,
            paginationNotAssigned: observable,
            deleteCornerstoneAssignment: action.bound,
            getAssignedCoursesCornerstone: action.bound,
            getAssignedCourseAssignmentsData: action.bound,
            getNotAssignedCourses: action.bound,
            getCornerstoneAssignmentsList: action.bound,
            onTableChange: action.bound,
            postCornerstoneAssignments: action.bound,
            putCornerstoneAssignments: action.bound,
            resetState: action.bound
        });
    
        const { apiService } = rootStore;
        this.apiService = apiService;
    }
 
    onTableChange = async (
        pagination: AntDPagination, 
        filters: { [columnName in keyof ICourseSimplifiedObject]?: ICourseSimplifiedObject[columnName] }, 
        sorter: AntDSorter<ICourseSimplifiedObject>
    ) => {
        const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
        const { current, pageSize } = pagination;
        const setFilters = {};
        const orderBy = {};
    
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== null) {
                setFilters[key] = filters[key][0];
            }
        });
    
        if (sorter.field) {
            orderBy[sorter.field === 'title' ? 'name' : sorter.field] = sortDirection;
        }
    
        if(this.isAssignedCoursesTable) {
            this.apiConfigAssigned = {
                ...this.apiConfigAssigned,
                limit: pageSize,
                filters: setFilters,
                offset: (current - 1) * DEFAULT_PAGE_SIZE,
                orderBy
            }
            this.paginationAssigned = {
                ...pagination,
                current
            }
        } else if (!this.isAssignedCoursesTable) {
            this.apiConfigNotAssigned = {
                ...this.apiConfigAssigned,
                limit: pageSize,
                filters: setFilters,
                offset: (current - 1) * DEFAULT_PAGE_SIZE,
                orderBy
            };
            this.paginationNotAssigned = {
                ...pagination,
                current
            }
        }
        await this.isAssignedCoursesTable ? this.getAssignedCoursesCornerstone(this.apiConfigAssigned) : this.getNotAssignedCourses(this.apiConfigNotAssigned);
    
        if(this.isAssignedCoursesTable) {
            this.apiConfigAssigned = {
                ...this.apiConfigAssigned,
                filters: {}
            }
        } else if (!this.isAssignedCoursesTable){
            this.apiConfigNotAssigned = {
                ...this.apiConfigNotAssigned,
                filters: {}
            }
        }
    }
 
   getNotAssignedCourses = async (config: AntDTableFilters<ICourseSimplifiedObject>) => {
        this.fetching = true;
        this.apiConfigNotAssigned = config;
    
        try {
            const notAssignedCoursesPaginated: IPaginatedItems<ICourseSimplifiedObject> = await this.apiService.admin.cornerstoneCourseAssignment.fetchCoursesCornerstone(this.apiConfigNotAssigned, 0);
                
            const { items, totalItems } = notAssignedCoursesPaginated;
            
            runInAction(() => {
                this.isAssignedCoursesTable = false;
                this.notAssignedCoursesList = items;
    
                this.paginationNotAssigned = {
                    ...this.paginationNotAssigned,
                    pageSize: this.apiConfigNotAssigned.limit,
                    total: totalItems
                };
            })
        } 
        catch (error) {
            Notification(NotificationEnum.Error, "Fetch assigned courses", error)
    
            runInAction(() => {
                throw error;
            });
        } 
        finally {
            this.fetching = false;
        }
    }
 
    getAssignedCoursesCornerstone = async (config: AntDTableFilters<ICourseSimplifiedObject>) => {
        this.fetching = true;
        this.apiConfigAssigned = config;

        try {
            const assignedCoursesPaginated: IPaginatedItems<ICourseSimplifiedObject> = await this.apiService.admin.cornerstoneCourseAssignment.fetchCoursesCornerstone(this.apiConfigAssigned, 1);

            const { items, totalItems } = assignedCoursesPaginated;
            runInAction(() => {
                this.isAssignedCoursesTable = true;
                this.assignedCoursesList = items;
    
                this.paginationAssigned = {
                    ...this.paginationAssigned,
                    pageSize: this.apiConfigAssigned.limit,
                    total: totalItems
                };
            })
        }
        catch (error) {
            throw (error);
        }
        finally {
            this.fetching = false;
        }
    }

    getCornerstoneAssignmentsList = async () => {
        try {
            await this.apiService.admin.cornerstoneCourseAssignment.fetchCornerstoneAssignmentsList().then(res => {
                this.cornerstoneAssignmentsList = res;
            });
        }
        catch(error) {
            throw (error);
        }
    }

    getAssignedCourseAssignmentsData = async (courseId: string) => {
        this.assignmentInitialValues = {
            keywords: [],
            modalities: [],
            subjects: []
        }

        try {
            await this.apiService.admin.cornerstoneCourseAssignment.getAssignedCourseCornerstoneAssignments(courseId).then(res =>{
                runInAction(() => {
                    this.assignedCourseAssignmentDetails = res;
                    this.assignmentInitValues(this.assignedCourseAssignmentDetails);
                })
            });
        } 
        catch (error) {
            throw (error)
        }
    }

    assignmentInitValues = (assignmentDetails: CornerstoneCourseAssignmentsDto[]) => {
        this.assignmentInitialValues.keywords = assignmentDetails?.filter(init => init.type == CornerstoneConfigTypesEnum.keyword)[0].value?.split(',');
                    
        assignmentDetails.filter((init: CornerstoneCourseAssignmentsDto) => init.type == CornerstoneConfigTypesEnum.subject)?.forEach((sub) => {
            this.assignmentInitialValues.subjects = this.assignmentInitialValues.subjects?.concat(sub.id);
        })

        assignmentDetails.filter((init: CornerstoneCourseAssignmentsDto) => init.type == CornerstoneConfigTypesEnum.modality)?.forEach((mod) => {
            this.assignmentInitialValues.modalities = this.assignmentInitialValues.modalities?.concat(mod.id);
        })
    }

    postCornerstoneAssignments = async (course: any, model: PostCornerstoneAssignment[]) => {
        try {
            await this.apiService.admin.cornerstoneCourseAssignment.postCornerstoneAssignments(course.id, model).then((res: boolean) => {
                if(res) {        
                    Notification(NotificationEnum.Success, "Cornerstone assignment", `Cornerstone assignment for \"${course.name}\" is saved successfully.`);
                }
            });
            runInAction(() => {
                this.notAssignedCoursesList = this.notAssignedCoursesList.filter(c => c.id != course.id);
            })
        } 
        catch (error) {
            Notification(NotificationEnum.Error, "Cornerstone assignment", error);
            throw(error)
        }
    }

    putCornerstoneAssignments = async (course: any, model: PostCornerstoneAssignment[]) => {
        try {
            const response = await this.apiService.admin.cornerstoneCourseAssignment.putCornerstoneAssignments(course.id, model).then((res: boolean) => {
                if(res) {
                    Notification(NotificationEnum.Success, "Cornerstone assignment", `Cornerstone assignment for \"${course.name}\" is updated successfully.`);
                }
            });

            return response;
        }
        catch (error) {
            Notification(NotificationEnum.Error, "Cornerstone assignment", error)
            throw(error)
        }
    }

    deleteCornerstoneAssignment = async (course: any) => {
        this.isDeletingAssignment = true;
        try {
            await this.apiService.admin.cornerstoneCourseAssignment.deleteCornerstoneAssignments(course.id).then((res: boolean) => {
                if(res) {
                    Notification(NotificationEnum.Success, "Remove Cornerstone assignment", `Cornerstone assignment for \"${course.name}\" is deleted successfully.`);
                }
            });

            runInAction(() => {
                this.assignedCoursesList = this.assignedCoursesList.filter(c => c.id != course.id);
            })
        }
        catch (error) {
            Notification(NotificationEnum.Error, "Remove Cornerstone assignment", error);
            throw(error)
        }
        finally {
            this.isDeletingAssignment = false;
        }
    }
 
    resetState() {
        this.isAssignedCoursesTable = true;
        this.isDeletingAssignment = false
        this.notAssignedCoursesList = [];
        this.assignedCoursesList = [];
        this.assignedCourseAssignmentDetails = null;
        this.fetching = true;
    }
 
}
 