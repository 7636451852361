import React, { FC, useState } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import FontAwesome from 'react-fontawesome';
import cx from 'classnames';
import intlMessages from './intlMessages';
import CircularProgress from '@u4i/common/CircularProgress';
import { DiscussAnswerPost } from '@u4i/modules/DiscussPage/state/DiscussPageStore';
import { Form, Switch, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import './_edit-answer-form.scss';

const { Item } = Form;

interface IProps extends WrappedComponentProps {
  editModeEnabled: boolean
  formId: string
  initialValues: DiscussAnswerPost
  showAnonymitySettingsSwitch: boolean
  updatingContent: boolean
  handleSubmit: (event: any) => any
  onEditionCancel: (event: any) => any
}

const EditAnswerForm: FC<IProps> = (props: IProps) => {
  const {
    editModeEnabled,
    formId,
    intl,
    initialValues,
    showAnonymitySettingsSwitch,
    updatingContent,
    handleSubmit,
    onEditionCancel
  } = props;

  const {formatMessage} = intl;
  const [form] = Form.useForm();
  const anonymityFieldId: string = `edit-comment-anonymous.${formId}`;
  const [hasAnswerChanged, setHasAnswerChanged] = useState(false);
  const [hasAnonymitySwitchChanged, setHasAnonymitySwitchChanged] = useState(false)

  const contentMinLengthError: string = formatMessage(intlMessages.contentErrorsMinLength, {limit: 50});
  const contentPresenceError: string = formatMessage(intlMessages.contentErrorsPresence);

  const updateAnswerContent = () => {
    let answerFieldErrors: any[] = form.getFieldError('answer');
    
    if(answerFieldErrors.length > 0) {
      return;
    }

    let data: any = {
      content: form.getFieldValue('answer'),
      anonymity: form.getFieldValue('anonymity')
    }
    handleSubmit(data);
  }

  return (
    <Form 
      form={form}
      initialValues={initialValues}
      key={formId}
      name={`answer-edit-${formId}`}
    >
      <Item
        name="answer"
        rules={[
          {
            validator: async (rule, value) => {
              if(value.trim() != initialValues.answer) {
                setHasAnswerChanged(true);
              } else {
                setHasAnswerChanged(false);
              }
              if(!value) {
                return Promise.reject(contentPresenceError);
              }
              if (value.trim().length > 0 && value.trim().length < 50) {
                return Promise.reject(contentMinLengthError);
              }
            }
          }
        ]}
      >
        <TextArea rows={8}/>
      </Item>

      <div className="edit-answer-form__fields-wrapper">
        {showAnonymitySettingsSwitch &&
          <div className="edit-answer-form__anonymity-field">
            <div style={{marginTop: 5}}>
              <Typography.Text style={{textTransform: 'uppercase'}}> 
                <FormattedMessage {...intlMessages.anonymousPosting} />
              </Typography.Text>
            </div>

            <Item
              name="anonymity"
              style={{marginBottom: 0}}
              initialValue={initialValues.anonymity}
              rules={[
                {
                  validator: async (rule, value) => {
                    if(value != initialValues.anonymity) {
                      setHasAnonymitySwitchChanged(true);
                    } 
                    else {
                      setHasAnonymitySwitchChanged(false);
                    }
                  }
                }
              ]}
            >
              <Switch 
                defaultChecked={initialValues.anonymity} 
              />
            </Item>
          </div>
        }

        <div className="edit-answer-form__action-bar-wrapper">
          <ul className="list-unstyled discuss-page__answer-action-bar">
            <div
              className={cx({
                'discuss-page__answer-action-bar__spacer': true,
                'discuss-page__answer-action-bar__spacer--expanded': editModeEnabled,
              })}
            />

            <li className={cx({
              'discuss-page__answer-action-bar__entry': true,
              'discuss-page__answer-action-bar__entry--collapsed': !hasAnswerChanged && !hasAnonymitySwitchChanged,
              'discuss-page__answer-action-bar__entry--hidden': !editModeEnabled,
            })}>
              <button
                className="discuss-page__answer-action-bar__action"
                onClick={updateAnswerContent}
                type="button"
              >
                <FontAwesome className="discuss-page__answer-action-bar__icon" name="check" />
        
                <FormattedMessage {...intlMessages.save} />
              </button>
            </li>

            <li className={cx({
              'discuss-page__answer-action-bar__entry': true,
              'discuss-page__answer-action-bar__entry--hidden': !editModeEnabled,
            })}>
              <button
                className={cx({
                  'discuss-page__answer-action-bar__action': true,
                  'discuss-page__answer-action-bar__action--transformed': editModeEnabled
                })}
                disabled={updatingContent}
                onClick={onEditionCancel}
                type="button"
              >
                <FontAwesome className="discuss-page__answer-action-bar__icon" name="times" />
        
                <FormattedMessage {...intlMessages.cancel} />
              </button>
            </li>
          </ul> 
        </div>

        {updatingContent &&
          <div className="edit-answer-form__updating-progress">
            <CircularProgress key={anonymityFieldId}/>
          </div>
        }
      </div>
    </Form>
  );
}

export default injectIntl(EditAnswerForm);
