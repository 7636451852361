import {AxiosInstance} from 'axios';
import {AdminAvailableGradersParser, IAdminGrader} from '@u4i/parsers/admin/AdminAvailableGradersParser';
import {AdminAvailableSkillParser, IAdminSkill} from '@u4i/parsers/admin/AdminAvailableSkillParser';
import {AdminCustomPageParser, IAdminCustomPage} from '@u4i/parsers/admin/AdminCustomPageParser';
import {
  AdminProofOfQualificationParser,
  IProofOfQualification,
  IProofOfQualificationPreview
} from '@u4i/parsers/admin/AdminProofOfQualificationParser';
import {AdminUserGroupParser, AdminUserGroupParserDetailed, IAdminUserGroup, IAdminUserGroupDetailed} from '@u4i/parsers/admin/AdminUserGroupParser';
import {IPaginatedItems} from '../../../../ServicesInterfaces';
import qs from 'qs';
import { NewUserGroupData, UserGroupEditFormData } from '@u4i/modules/Admin/modules/UserGroups/interfaces/AdminUserGroupInterface';


export class AdminUserGroupsApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async copyUserGroup(id: string) : Promise<IAdminUserGroupDetailed> {
    const response = await this.apiClient.post(`admin/groups/${id}/copy`);

    return AdminUserGroupParserDetailed.parse(response.data);
  }

  async deleteUserGroup(id: string, alternativeGroupId: string): Promise<void> {
    await this.apiClient.delete(`/admin/groups/${id}`, {
      data: {
        alternativeGroupId: alternativeGroupId,
      }
    });
  }

  async fetchAvailableGraders(id: string): Promise<Array<IAdminGrader>> {
    const response = await this.apiClient.get(`/admin/groups/${id}/available-graders`);

    return AdminAvailableGradersParser.parseArray(response.data);
  }

  async fetchAvailableSkills(): Promise<Array<IAdminSkill>> {
    const response = await this.apiClient.get(`/admin/skills`);

    return AdminAvailableSkillParser.parseArray(response.data);
  }

  async fetchCustomPages(): Promise<Array<IAdminCustomPage>> {
    const response = await this.apiClient.get('/admin/custom-pages/all-pairs');

    return AdminCustomPageParser.parseArray(response.data);
  }

  async fetchUserGroupData(id: string): Promise<IAdminUserGroupDetailed> {
    const response = await this.apiClient.get(`/admin/groups/${id}`);

    return AdminUserGroupParserDetailed.parse(response.data);
  }

  async fetchUserGroupsData(config: {
    filters: {[columnName in keyof IAdminUserGroup]?: IAdminUserGroup[columnName]},
    limit?: number,
    offset: number,
    sortDirection: 'ASC',
    sortedColumnId: string | number | symbol | undefined,
  }): Promise<IPaginatedItems<IAdminUserGroup>> {
    const response = await this.apiClient.get('/admin/groups', {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.sortedColumnId ? {
          [config.sortedColumnId]: config.sortDirection,
        }: undefined,
      },
      paramsSerializer: params => qs.stringify(params),
    });

    const items = AdminUserGroupParser.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items, totalItems};
  }

  async fetchUserGroupsDataDetailed(config: {
    filters: {[columnName in keyof IAdminUserGroupDetailed]?: IAdminUserGroupDetailed[columnName]},
    limit?: number,
    offset: number,
    sortDirection: 'ASC',
    sortedColumnId: string | number | symbol | undefined,
  }): Promise<IPaginatedItems<IAdminUserGroupDetailed>> {
    const response = await this.apiClient.get('/admin/groups/expanded-list', {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.sortedColumnId ? {
          [config.sortedColumnId]: config.sortDirection,
        }: undefined,
      },
      paramsSerializer: params => qs.stringify(params),
    });

    const items = AdminUserGroupParserDetailed.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items, totalItems};
  }

  async submitNewUserGroup(newUserGroupData: NewUserGroupData): Promise<IAdminUserGroupDetailed> {
    const response = await this.apiClient.post('admin/groups', newUserGroupData);

    return AdminUserGroupParserDetailed.parse(response.data);
  }

  async updateProofOfQualificationSettings(
    userGroupId: string,
    updatedProofOfQualificationSettings: Partial<IProofOfQualification>
  ): Promise<IProofOfQualificationPreview> {
    const {headerDE, headerEN, signatureFirstLine, signatureSecondLine, logoFile, signatureFile} = updatedProofOfQualificationSettings;
    const formData = new FormData();

    if (headerDE) {
      formData.append('headerDE', headerDE);
    }

    if (headerEN) {
      formData.append('headerEN', headerEN);
    }

    if (signatureFirstLine) {
      formData.append('signatureFirstLine', signatureFirstLine);
    }

    if (signatureSecondLine) {
      formData.append('signatureSecondLine', signatureSecondLine);
    }

    if (logoFile) {
      formData.append('logoFile', logoFile);
    }

    if (signatureFile) {
      formData.append('signatureFile', signatureFile);
    }

    const response = await this.apiClient.post(`admin/groups/${userGroupId}/proof-of-qualification`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return AdminProofOfQualificationParser.parse(response.data);
  }

  async updateUserGroup(
    userGroupId: string,
    updatedUserGroupData: Partial<UserGroupEditFormData>,
  ): Promise<IAdminUserGroupDetailed> {
    const response = await this.apiClient.put(`admin/groups/${userGroupId}`, updatedUserGroupData);

    return AdminUserGroupParserDetailed.parse(response.data);
  }

  async generateGroupToken(groupId: string): Promise<string> {
    const response = await this.apiClient.get(`admin/groups/${groupId}/generate-token`);

    return response.data;
  }
}
