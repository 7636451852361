import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import {Alert} from '@u4i/common/Alert';
import {AlertStatusEnum} from '@u4i/common/enums/AlertStatusEnum';

import intlMessages from './intlMessages';
import './_error-message.scss';

interface IProps {
  errorCode: number;
}

const MESSAGES_MAP: Map<number, JSX.Element> = new Map([
  [403, <FormattedMessage {...intlMessages.message403} values={{mail: <a href="mailto:admin@university4industry.com">admin@university4industry.com</a>}} />],
  [404, <FormattedMessage {...intlMessages.message404} />],
]);

export const ErrorMessage: FC<IProps> = (props: IProps) => (
  <div className="discuss-page__error-message">
    <Alert
        heading={<FormattedMessage {...intlMessages.heading} />}
        message={MESSAGES_MAP.get(props.errorCode) || AlertStatusEnum.ERROR}
        type={AlertStatusEnum.ERROR}
      />
  </div>
);
