import {defineMessages} from 'react-intl';

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    id: 'ShepherdTourCreator.Back',
  },
  finish: {
    defaultMessage: 'Finish',
    id: 'ShepherdTourCreator.Finish',
  },
  next: {
    defaultMessage: 'Next',
    id: 'ShepherdTourCreator.Next',
  },
  skip: {
    defaultMessage: 'Skip',
    id: 'ShepherdTourCreator.Skip',
  },
});
