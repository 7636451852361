import { mainBlue, white } from '@u4i/styles/styles-variables';
import React, { useState } from 'react';
import { opacity7 } from '../../constants/Constants';

interface IPropTypes {
  cy: number
  phase: any
  r: number
  onPhaseIconClick: () => void
}

export const PhaseNotStartedIcon = (props: IPropTypes) => {
  const {
    cy,
    phase,
    onPhaseIconClick
  } = props;

  const {
    CXOfPhaseIcon,
    phaseId
  } = phase;

  const [opacity, setOpacity] = useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  return(
    <svg>
      <g
        id={phaseId}
        onClick={() => onPhaseIconClick()}
        onMouseEnter={handleOpacity}
        onMouseLeave={handleOpacity}
        style={{ cursor: 'pointer', userSelect: 'none', ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          style={{
            fill: white,
            fillOpacity: 0,
            stroke: mainBlue,
            strokeWidth: 8,
            strokeMiterlimit: 4
          }}
          cx={CXOfPhaseIcon}
          cy={cy}
          r={38.5}
        />

        <title>{phase.longTitle}</title>
      </g>
    </svg>
  )
}
