import React from 'react';
import {inject, observer} from 'mobx-react';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import ExpandableText from '@u4i/common/ExpandableText';
import {DISCUSS_ANSWER_TRIMMING_THRESHOLD} from '@u4i/constantSettings';
import {IRootStore} from '@u4i/state/RootStore';
import {DiscussAnswerStore} from './state/DiscussAnswerStore';
import { AnswerActionBar } from './components/AnswerActionBar/AnswerActionBar';
import { AnswerHeader } from './components/AnswerHeader/AnswerHeader';
import { DiscussComment } from './components/DiscussComment/DiscussComment';
import DiscussCommentForm from '@u4i/common/forms/DiscussCommentForm/DiscussCommentForm';
import './_discuss-answer.scss';

interface IPropTypes {
  answerId: string;
  entity: DiscussAnswerEntity;
  isAnonymityChecked: boolean;
  rootStore?: IRootStore;
  showAnonymitySettingsSwitch: boolean;
  teaserMode: boolean;
}

export const DiscussAnswer = inject('rootStore')(observer(class DiscussAnswer extends React.Component<IPropTypes> {
  uiStore: DiscussAnswerStore;

  constructor(props: IPropTypes) {
    super(props);

    this.uiStore = new DiscussAnswerStore(props.rootStore!, props.entity);
  }

  handleCommentSubmission = (values: {content: string, anonymity: boolean}) => {
    const submittedValues = Object.assign({}, values);

    submittedValues.anonymity = submittedValues.anonymity || false;
  
    this.uiStore.submitComment(submittedValues);
  };

  render() {
    const { entity, isAnonymityChecked, showAnonymitySettingsSwitch, teaserMode } = this.props;
    const { commentCount, comments, content, createdAt, isLiked, likeCount, user } = entity;
    const { commentModeEnabled, fetchingComments, showComments, togglingLike, toggleCommentMode, toggleComments, toggleLike} = this.uiStore;

    return (
      <div className="discuss__discuss-answer">
        <div className="discuss__discuss-answer__answer">
          <div className="discuss__discuss-answer__avatar">
            <img
              alt="User avatar"
              className="discuss__discuss-answer__avatar-image"
              src={user.profileImage}
            />
          </div>
    
          <div className="discuss__discuss-answer__main-content">
            <AnswerHeader
              authorFirstname={user.firstName}
              authorLastname={user.lastName}
              creationDate={createdAt}
            />
    
            <ExpandableText
              animationDurationSeconds={1}
              className="discuss__discuss-answer__content-text"
              content={content}
              hideExpandButton={teaserMode}
              trimmingThreshold={DISCUSS_ANSWER_TRIMMING_THRESHOLD}
            />
    
            <AnswerActionBar
              commentModeEnabled={commentModeEnabled}
              comments={commentCount}
              fetchingComments={fetchingComments}
              isLiked={isLiked}
              onCommentClick={toggleCommentMode}
              onCommentsClick={toggleComments}
              onLikesClick={toggleLike}
              likes={likeCount}
              showComments={showComments}
              teaserMode={teaserMode}
              togglingLike={togglingLike}
            />
          </div>
        </div>
    
        {(!teaserMode && showComments && !fetchingComments) &&
          <ul className="list-unstyled discuss__discuss-answer__comments">
            {comments.map(entry => (
              <li className="discuss__discuss-answer__comments-entry" key={entry.id}>
                <DiscussComment
                  entity={entry}
                  formId={entry.id}
                  showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
                />
              </li>
            ))}
          </ul>
        }
    
        {(!teaserMode && commentModeEnabled) &&
          <div className="discuss__discuss-answer__comment-form">
            <DiscussCommentForm
              handleSubmit={this.handleCommentSubmission}
              isAnonymityChecked={isAnonymityChecked}
              showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            />
          </div>
        }
      </div>
    );
  }
}));
