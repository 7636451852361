import {action, computed, observable, reaction, runInAction,makeObservable} from 'mobx';

import UserStore from '@u4i/state/UserStore';
import {IApi, ILogger} from '@u4i/state/ServicesInterfaces';
import {ILandingPage} from '@u4i/parsers/LandingPageParser';
import {IRootStore} from '@u4i/state/RootStore';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {BaseSection} from './sections/BaseSection';
import {SectionFactory} from './SectionFactory';
import {VisitTracker} from '../VisitTracker';

export class LandingPageStore {
  private apiService: IApi;
  private logger: ILogger;
  private pageSlug?: string;
  private rootStore: IRootStore;
  private userStore: UserStore;
  errorCode?: number;
  fetching = true;
  itemsLanguage: LanguageEnum | null = null;
  pageData?: ILandingPage;
  previewModeEnabled = false;
  sections: Array<BaseSection> = [];
  isCurriculumCourseVisible: boolean = false;
  isTermsOfServiceModalVisible: boolean = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      changeItemsLanguage: action.bound,
      createSections: action.bound,
      errorCode: observable,
      fetching: observable,
      init: action.bound,
      isCurriculumCourseVisible: observable,
      isPrivateModeEnabled: computed,
      isTermsOfServiceModalVisible: observable,
      itemsLanguage: observable,
      loadPageData: action.bound,
      pageData: observable,
      previewModeEnabled: observable,
      reset: action.bound,
      sections: observable
    });

    const {apiService, loggerFactory, userStore} = rootStore;

    this.apiService = apiService;
    this.logger = loggerFactory.createLogger('LandingPageStore');
    this.rootStore = rootStore;
    this.userStore = userStore;

    reaction(() => this.userStore.isAuthorized, this.reloadOnAuthorization);
  }

  get isPrivateModeEnabled(): boolean {
    return this.pageData ? this.pageData.privateMode : false;
  }

  changeItemsLanguage = (newItemsLanguage: LanguageEnum) => {
    this.itemsLanguage = newItemsLanguage;

    this.sections.forEach(section => section.reloadContent());
  }

  curriculumCourseModalHandle = (open: boolean = false) => {
    if(open) {
      this.isCurriculumCourseVisible = true;
    } else {
      this.isCurriculumCourseVisible = false;
    }
  }

  createSections = ()  =>{
    if (!this.pageData) {
      return;
    }

    this.sections = this.pageData.sections.map(section => SectionFactory.build(this.rootStore, section));
  }

  init = async (pageSlug: string, previewModeEnabled: boolean) => {
    this.pageSlug = pageSlug;
    this.previewModeEnabled = previewModeEnabled;

    if (!this.userStore.isAuthorized) {
      VisitTracker.addVisit(pageSlug);
    }

    await this.loadPageData();
    this.createSections();
  }

  loadPageData = async () => {
    if (!this.pageSlug) {
      return;
    }

    this.fetching = true;

    try {
      const landingPage = await this.apiService.landingPages.fetchPageData(this.pageSlug, this.previewModeEnabled);

      runInAction(() => {
        this.pageData = landingPage;
        this.fetching = false;
      });

      this.logger.info('landing page data loaded');
    } catch (error) {
      runInAction(() => {
        if (error.response) {
          this.errorCode = error.response.status;
        }

        this.fetching = false;
      });
    }
  }

  reloadOnAuthorization = () => {
    if (this.pageSlug) {
      const {pageSlug, previewModeEnabled} = this;

      this.reset();
      this.init(pageSlug, previewModeEnabled);
    }
  };

  
  termsOfServiceModalHandle = (open: boolean = false) => {
    if(open) {
      this.isTermsOfServiceModalVisible = true;
    } else {
      this.isTermsOfServiceModalVisible = false;
    }
  }

  reset = () => {
    this.errorCode = undefined;
    this.fetching = false;
    this.pageData = undefined;
    this.pageSlug = undefined;
    this.previewModeEnabled = false;
    this.sections = [];
  }
}
