import {defineMessages} from 'react-intl';

export default defineMessages({
  buttonText: {
    defaultMessage: 'Mark all as seen',
    id: 'NotificationsPage.MarkAllAsSeenButton.ButtonText',
  },
  pageTitle: {
    defaultMessage: 'Notifications',
    id: 'NotificationsPage.PageTitle',
  },
  generalText: {
    defaultMessage: 'General',
    id: 'NotificationsPage.GeneralText',
  },
  newText: {
    defaultMessage: 'new',
    id: 'NotificationsPage.NewText',
  },
  notification:{
    id: "NotificationsPage.NoItemsPlaceholder.Message",
    defaultMessage: "There are no notifications"
  }
});
