import cx from 'classnames';
import React from 'react';

import './_dot.scss';

interface IPropTypes {
  dotIndex: number;
  isActive: boolean;
  onClick(questionIndex: number): void;
}

export class Dot extends React.Component<IPropTypes> {
  handleClick = () => {
    this.props.onClick(this.props.dotIndex);
  };

  render() {
    return (
      <button
        className={cx({
          'dotted-navigation__dot': true,
          'dotted-navigation__dot--active': this.props.isActive,
        })}
        onClick={this.handleClick}
        type="button"
      />
    );
  }
}
