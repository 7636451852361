import {defineMessages} from 'react-intl';

export default defineMessages({
  emailErrorsPattern: {
    defaultMessage: 'Is not valid e-mail',
    id: 'Forms.PasswordRecovery.Email.Errors.Pattern',
  },
  emailErrorsPresence: {
    defaultMessage: 'E-mail cannot be blank',
    id: 'Forms.PasswordRecovery.Email.Errors.Presence',
  },
  emailLabel: {
    defaultMessage: 'E-mail address',
    id: 'Forms.PasswordRecovery.Email.Label',
  },
  hint: {
    defaultMessage: 'Enter your e-mail address. {br} We’ll send you recovery instructions!',
    id: 'Forms.PasswordRecovery.Hint',
  },
  submitCompleted: {
    defaultMessage: 'Recover password instructions have been sent to your e-mail',
    id: 'Forms.PasswordRecovery.SubmitCompleted',
  },
  submitTitle: {
    defaultMessage: 'Recover password',
    id: 'Forms.PasswordRecovery.SubmitTitle',
  },
});
