import { action, toJS, observable, runInAction, makeObservable } from "mobx";
import { TablePaginationConfig } from "antd/lib/table";
import { IApi } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { IAdminUser } from "@u4i/parsers/admin/AdminUsersParser";
import { IPaginatedItems } from '@u4i/state/ServicesInterfaces';
import { AdminUserCourse, Course, UserChallengesInfo } from "../interfaces/";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";

export class AdminUserCoursesStore {
  private apiService: IApi;
  private clonedPagination: TablePaginationConfig;
  clonedTableData: Array<AdminUserCourse> = [];
  cloneCourseList: Course[] = [];

  fetching = true;
  isUnassigining = false;
  loadingAssign = false;
  loadingUserList = false;
  proofOfQualificationLoader = false;
  isDownloadingCertificate = false;
  isDownloadingTranscript = false;

  tableData: Array<AdminUserCourse> = [];
  apiConfig: AntDTableFilters<AdminUserCourse> = {
    current: 0,
    limit: 10,
    offset: 0,
    filters: {},
    orderBy: {},
  };
  pagination: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true,
  };
  userCourseById: AdminUserCourse;
  coursesList: Course[] = [];
  normalizedUsersList: any[] = [];
  userCourseChallengesInfo: UserChallengesInfo[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      fetching: observable,
      isUnassigining: observable,
      loadingAssign: observable,
      loadingUserList: observable,
      tableData: observable,
      apiConfig: observable,
      pagination: observable,
      userCourseById: observable,
      coursesList: observable,
      normalizedUsersList: observable,
      proofOfQualificationLoader: observable,
      userCourseChallengesInfo: observable,
      isDownloadingCertificate: observable,
      isDownloadingTranscript: observable,
      getUserCourses: action.bound,
      onTableChange: action.bound,
      getUserCoursesById: action.bound,
      unassignCourse: action.bound,
      getCoursesList: action.bound,
      assignCourses: action.bound,
      getUsersList: action.bound,
      deleteProofOfQualificationCertificate: action.bound,
      generateProofOfQualificationCertificate: action.bound,
      getUserCourseChallengesInfo: action.bound,
      downloadPDF: action.bound
    });

    const { apiService } = rootStore;

    this.apiService = apiService;
  }

  searchCourse = (e) => {
    const value = e.target.value.toLocaleLowerCase();
    if (value) {
      const filterFromList =
        this.coursesList.length > this.cloneCourseList.length
          ? this.coursesList
          : this.cloneCourseList;
      this.coursesList = filterFromList.filter((item: Course) => {
        let itemTitle = item.title.toLocaleLowerCase();
        let itemInternalId = item.internal_id.toLocaleLowerCase();
        if (itemTitle.includes(value) || itemInternalId.includes(value)) {
          return true;
        }
      });
    } else {
      this.coursesList = this.cloneCourseList;
    }
  };

  resetTableData() {
    this.tableData = [...this.clonedTableData];
    this.pagination = { ...this.clonedPagination };
  }

  resetUserCourses() {
    this.coursesList = this.cloneCourseList;
  }

  getUserCourses = async (config: AntDTableFilters<AdminUserCourse>, updateClonedData: boolean) => {
    this.fetching = true;
    this.apiConfig = config;

    try {
      const userGroupsSimplified: IPaginatedItems<AdminUserCourse> = await this.apiService.admin.userCourse.fetchUserCourseData(this.apiConfig);

      const { items, totalItems } = userGroupsSimplified;

      this.pagination = {
        ...this.pagination,
        pageSize: this.apiConfig.limit,
        total: totalItems,
      };

      runInAction(() => (this.tableData = items));

      if (updateClonedData) {
        this.clonedTableData = items;
        this.clonedPagination = this.pagination;
      }
    } catch (error) {
      Notification(NotificationEnum.Error, "User courses fetch", error);

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetching = false;
    }
  }

  onTableChange = async (
    pagination: AntDPagination, 
    filters: { [columnName in keyof AdminUserCourse]?: AdminUserCourse[columnName] }, 
    sorter: AntDSorter<AdminUserCourse>
  ) => {
    const sortDirection: "ASC" | "DESC" = sorter.order === "descend" ? "DESC" : "ASC";

    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        if(key == 'purchaseDate' && filters[key]?.length){
          setFilters['purchaseDateFrom'] = filters[key]?.[0][0];
          setFilters['purchaseDateTo'] = filters[key]?.[0][1];
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field] = sortDirection;
    }

    this.apiConfig = {
      ...this.apiConfig,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.pagination = {
      ...this.pagination,
      current,
    };

    await this.getUserCourses(this.apiConfig, false);

    this.apiConfig = {
      ...this.apiConfig,
      filters: {},
    };
  }

  async getUserCoursesById(id: string) {
    if (this.tableData.length) {
      this.userCourseById = this.tableData.filter((item: AdminUserCourse) => item.id == id)[0];
    } else {
      this.fetching = true;
      try {
        this.userCourseById = await this.apiService.admin.userCourse.fetchUserCourseById(id);
      } catch (error) {

        runInAction(() => {
          throw error;
        });
      } finally {
        this.fetching = false;
      }
    }
  }

  async getUserCourseChallengesInfo(id: string) {
    try {
      this.userCourseChallengesInfo = await this.apiService.admin.userCourse.challengesInfo(id);
    } catch (error) {
      runInAction(() => {
        Notification(NotificationEnum.Error, "User course challenges", error);
        throw error;
      });
    } finally {
    }
  }

  async unassignCourse(id: string) {
    const unassignedCourse: AdminUserCourse[] = this.tableData.filter((item) => item.id == id);
    this.isUnassigining = true;
    try {
      const isUnassiged: boolean = await this.apiService.admin.userCourse.unassingUserCourse(id);

      if (isUnassiged) {
        this.tableData = this.tableData.filter((item) => item.id != id);
        this.pagination.total = this.pagination.total ? this.pagination.total - 1 : this.pagination.total;

        this.clonedTableData = this.tableData;
        this.clonedPagination = this.pagination;

        let successUnassignMessage: string = "";
        if (unassignedCourse[0]) {
          successUnassignMessage = `${unassignedCourse[0].skillTitle} is successfully unassigned from ${unassignedCourse[0].firstName} ${unassignedCourse[0].lastName}`;
        } else {
          successUnassignMessage = `${this.userCourseById.skillTitle} is successfully unassigned from ${this.userCourseById.firstName} ${this.userCourseById.lastName}`;
        }

        Notification(NotificationEnum.Success, "Course unassign", successUnassignMessage);
        AppNavigator.push(appRoutes.admin3UserCourseOverview);
      }
    } catch (error) {
      Notification(NotificationEnum.Error, "Course unassign", error);
      runInAction(() => {
        throw error;
      });
    } finally {
      this.isUnassigining = false;
    }
  }

  async getCoursesList() {
    this.fetching = true;
    try {
      this.coursesList = await this.apiService.admin.userCourse.fetchCoursesList();
      this.cloneCourseList = toJS(this.coursesList);
    } catch (error) {
      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetching = false;
    }
  }

  async assignCourses(userIds: string[], coursesIds: string[]) {
    if (!userIds.length || !coursesIds.length) {
      return;
    }

    const data = {
      user_ids: userIds,
      skill_ids: coursesIds,
    };

    this.loadingAssign = true;
    try {
      const response: boolean = await this.apiService.admin.userCourse.assignCourses(data);

      if (response) {
        if (userIds.length > 1 || coursesIds.length > 1) {
          Notification(NotificationEnum.Success, "Courses assign", "Selected courses are assigned to specified users.");
        } else {
          const filterCourse: Course = this.coursesList.filter((item: Course) => item.id == coursesIds[0])[0];
          Notification(NotificationEnum.Success, "Course assign",`${filterCourse?.title} is assigned to specified user.`);
        }
        await this.getUserCourses(this.apiConfig, true);
        AppNavigator.push(appRoutes.admin3UserCourseOverview);
      }
    } catch (error) {
      Notification(NotificationEnum.Error, "Courses assign", error);

      runInAction(() => {
        throw error;
      });
    } finally {
      this.loadingAssign = false;
    }
  }

  async getUsersList(val: string) {
    this.loadingUserList = true;

    const requestConfig = {
      current: 1,
      filters:{},
      sortDirection: "ASC" as const,
      sortedColumnId: undefined,
      offset: 0,
      limit: 50,
      orderBy: {}
    };
    try {
      let usersInfo: IPaginatedItems<IAdminUser>;

      if(val.trim()){
        usersInfo = await this.apiService.admin.userCourse.fetchUsersByKeyword(val);
      } else {
        usersInfo = await this.apiService.admin.users.fetchUsersData(requestConfig);
      }

      this.normalizedUsersList = usersInfo.items.map((item: IAdminUser) => {
        return {
          value: `${item.firstName} ${item.lastName} ${item.email}`,
          id: item.id,
          label: `${item.firstName} ${item.lastName}`,
        };
      });
    } catch (error) {
      runInAction(() => {
        throw error;
      });
    } finally {
      this.loadingUserList = false;
    }
  }

  async deleteProofOfQualificationCertificate(userCourseId: string) {
    this.proofOfQualificationLoader = true;
    try {
      const response: boolean = await this.apiService.admin.userCourse.deleteProofOfQualification(userCourseId);
      if (response) {
        this.userCourseById.canDownloadCertificate = false;
      } else {
        Notification(NotificationEnum.Error, "Certificate delete", "Not able to delete certificate from database!");
      }
    } catch (error) {
      runInAction(() => {
        throw error;
      });
    } finally {
      this.proofOfQualificationLoader = false;
    }
  }

  async generateProofOfQualificationCertificate(userCourseId: string) {
    this.proofOfQualificationLoader = true;
    try {
      const response: boolean = await this.apiService.admin.userCourse.generateProofOfQualification(userCourseId);
      if (response) {
        this.userCourseById.canDownloadCertificate = true;
      } else {
        Notification(NotificationEnum.Error, "Certificate generate", "Not able to generate certificate!");
      }
    } catch (error) {
      runInAction(() => {
        throw error;
      });
    } finally {
      this.proofOfQualificationLoader = false;
    }
  }

  async downloadPDF(id: string, courseTitle: string, fileName: string) {
    try {
      const courseTitleForFileName: string = courseTitle.replace(/ /g, '_');
        if(fileName == 'certificate'){
          this.isDownloadingCertificate = true;
          await this.apiService.admin.userCourse.downloadPdfFile(id, `Proof_of_Qualification_-_${courseTitleForFileName}.pdf`, 'certificate');
        } else {
          this.isDownloadingTranscript = true;
          await this.apiService.admin.userCourse.downloadPdfFile(id, `Transcript_-_${courseTitleForFileName}.pdf`, 'transcript');
        }
    } catch (error) {
      runInAction(() => {
        throw error;
      });
    } finally {
      this.isDownloadingCertificate = false;
      this.isDownloadingTranscript = false;
    }
  }

}
