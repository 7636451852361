import {defineMessages} from 'react-intl';

export default defineMessages({
  certificateDescriptionCompleted: {
    defaultMessage: 'Congratulations! You successfully finished this course!',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificationStatusCompleted.CertificateDescriptionCompleted',
  },
  challengesDescriptionCompleted: {
    defaultMessage: 'Congratulations! You have completed all challenges and feedback survey for this course.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificationStatusCompleted.ChallengesDescriptionCompleted',
  },
  chaptersDescriptionCompleted: {
    defaultMessage: 'Congratulations! You have completed all chapters and feedback survey for this course.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificationStatusCompleted.ChaptersDescriptionCompleted',
  },
});
