import { mainBlue, white } from "@u4i/styles/styles-variables";
import * as React from "react";
import { opacity7 } from "../../constants/Constants";
import { mapModuleStyling } from "../../constants/StaticMethods";

interface IPropTypes {
  cx: number
  cy: number
  isCompleted: boolean
  radius: number
  required: boolean
  title: string
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void 
  showModuleInVE: () => void
}

export const LiveEventIcon = (props: IPropTypes) => {
  const {
    cx,
    cy,
    isCompleted,
    radius,
    required,
    title,
    onModuleHover,
    showModuleInVE
  } = props;

  const [opacity, setOpacity] = React.useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  const moduleHover = (mouseEnter: boolean) => {
    onModuleHover(cx, cy, title, mouseEnter);
  }

  return (
    <svg>
      <g
        onClick={showModuleInVE}
        onMouseEnter={() => { handleOpacity(); moduleHover(true); }}
        onMouseLeave={() => { handleOpacity(); moduleHover(false); }}
        style={{ cursor: "pointer", ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          cx={cx}
          cy={cy}
          r={isCompleted ? radius : radius-3}
          style={mapModuleStyling(isCompleted)}
        >
        </circle>
        <g fill={isCompleted ? white : mainBlue}>
          <path
            d={`m ${cx+3},${cy-7.5} c 0,1.457296 -1.162499,2.615659 -2.624999,2.615659 -1.4625,0 -2.625,-1.158363 -2.625,-2.615659 0,-1.457294 1.1625,-2.615657 2.625,-2.615657 1.4625,0 2.624999,1.195729 2.624999,2.615657`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx+13.5},${cy-6} c 0,1.457295 -1.162501,2.615658 -2.625001,2.615658 -1.4625,0 -2.624999,-1.158363 -2.624999,-2.615658 0,-1.457295 1.162499,-2.615658 2.624999,-2.615658 1.4625,0 2.625001,1.158363 2.625001,2.615658`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx-7.5},${cy-6} c 0,1.457295 -1.162501,2.615658 -2.625001,2.615658 -1.4625,0 -2.625,-1.158363 -2.625,-2.615658 0,-1.457295 1.1625,-2.615658 2.625,-2.615658 1.4625,0 2.625001,1.158363 2.625001,2.615658`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx-14.5},${cy+11} c 0,-5.455517 6.6,-9.864768 14.775001,-9.864768 8.137499,0 14.774999,4.409251 14.774999,9.864768 z`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx-11},${cy+2.5} c 1.575,-1.046262 3.412501,-1.868327 5.4,-2.391458 v -0.411033 c 0,-0.411032 -0.187499,-0.822063 -0.45,-1.08363 -0.112507,-0.112106 -0.375,-0.261566 -0.7125,-0.411031 -2.062499,-0.971531 -4.5,-0.971531 -6.6,-0.03737 -0.375,0.149467 -0.675,0.336299 -0.825,0.448398 -0.225,0.261567 -0.4125,0.672598 -0.4125,1.083631 v 6.352313 c 0.8625,-1.307829 2.062501,-2.540925 3.6,-3.549822`}
            style={{strokeWidth: 0.7}}
          />
          <path
            d={`m ${cx+5},${cy-0.5} v -1.532028 c 0,-0.411033 -0.187501,-0.822065 -0.45,-1.083631 -0.112493,-0.112092 -0.375,-0.261564 -0.7125,-0.411032 -2.062501,-0.971531 -4.5,-0.971531 -6.6,-0.03737 -0.375,0.149465 -0.675,0.336299 -0.825001,0.448398 -0.299999,0.261565 -0.45,0.672598 -0.45,1.08363 v 1.532028 c 1.462501,-0.298932 2.962501,-0.448399 4.500001,-0.448399 1.537499,0 3.075,0.186833 4.5375,0.448399`}
            style={{strokeWidth: 0.7}}
          />
          <path
            d={`m ${cx+15},${cy+6} v -6.352314 c 0,-0.411032 -0.187499,-0.822063 -0.45,-1.08363 -0.112493,-0.112106 -0.375,-0.261566 -0.7125,-0.411031 -2.062499,-0.971531 -4.499999,-0.971531 -6.6,-0.03737 -0.374999,0.149467 -0.675,0.336299 -0.824999,0.448398 -0.300001,0.261567 -0.45,0.672598 -0.45,1.083631 v 0.411032 c 1.9875,0.523131 3.825,1.345196 5.4,2.391459 1.575,1.008897 2.775,2.241993 3.637499,3.549822`}
            style={{strokeWidth: 0.7}}
          />
        </g>
      </g>
    </svg>
  )
}
