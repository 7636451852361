import React from "react";
import ReactDom from "react-dom";
import { action, observable, makeObservable } from "mobx";
import Calendar from "@event-calendar/core";
import TimeGrid from "@event-calendar/time-grid";
import moment, { Moment } from "moment";
import { SelectValue } from "antd/lib/select";
import {v4 as uuid} from "uuid"; 
import { FormattedMessage, RawIntlProvider } from "react-intl";
import { DeleteFilled } from "@ant-design/icons";
import { IApi } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import UserStore from "@u4i/state/UserStore";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import { LanguageStore } from "@u4i/state/LanguageStore";
import {
  BOOKED_EVENT_HEIGHT,
  DEFAULT_BOOKED_EVENT,
} from "@u4i/constantSettings";
import {
  CalendarEvent,
  AvailableLabs,
  ILabs,
  SlotValidation,
} from "../components/Booking/interface";
import intlMessages from "./intlMessages";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";

let calendarInstance: any;

export class DigitalLabBookingStore {
  private apiService: IApi;
  private userStore: UserStore;
  private languageStore: LanguageStore;

  calendarLoader: boolean = false;
  isEventDeleteClick: boolean = false;
  isBookingConfirmationVisible: boolean = false;
  isDeleteConfirmationVisible: boolean = false;
  isBookingLoader: boolean = false;
  selectedLabRestTime: number;
  selectedLabUserSessionTime: number;
  currentlanguage: any;
  deleteSlotId: string;

  calendarEvents: CalendarEvent[] = [];
  eventStartTime: Moment | undefined;
  eventEndTime: Moment | undefined;
  labs: ILabs[] = [];
  availableLabs: AvailableLabs[] = [];
  cloneAvailableLabs: AvailableLabs[] = [];
  currentWeek = {
    from: "",
    to: ""
  };
  bookingSlotValidation: SlotValidation = {
    selectedLabId: ""
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      calendarEvents: observable,
      eventStartTime: observable,
      eventEndTime: observable,
      availableLabs: observable,
      bookingSlotValidation: observable,
      calendarLoader: observable,
      isBookingConfirmationVisible: observable,
      isBookingLoader: observable,
      isDeleteConfirmationVisible: observable,
      initCalendar: action.bound,
      calendarSlotClicked: action.bound,
      bookSlot: action.bound,
      onChangeLab: action.bound,
      eventTemplate: action.bound,
      getSetCalendarEvent: action.bound,
      hideBookingConformationModal: action.bound,
      deleteSlot: action.bound,
      filterDLBySearchValue: action.bound,
      onBlurDLSelectBox: action.bound
    });

    const { apiService, languageStore, userStore } = rootStore;
    this.apiService = apiService;
    this.languageStore = languageStore;
    this.userStore = userStore;
  }

  async initCalendar(courseId: string) {
    const todayButtonText = (
      <RawIntlProvider
        key={this.languageStore.currentLocale}
        value={this.currentlanguage}
      >
        <FormattedMessage {...intlMessages.bookingSlotTodayButtonText} />
      </RawIntlProvider>
    );

    this.currentlanguage =
      this.languageStore.currentIntl.locale == LanguageEnum.EN_US ||
      this.languageStore.currentIntl.locale == LanguageEnum.DE_DE ? this.languageStore.currentIntl : LanguageEnum.EN_US;

    this.labs = await this.apiService.digitalLab.getAllLabsList(courseId);

    for (let i = 0; i < this.labs.length; i++) {
      this.availableLabs.push({
        id: this.labs[i].id,
        title: this.labs[i].title,
        disable: this.labs[i].disable,
      });
    }

    this.cloneAvailableLabs = [...this.availableLabs];

    if (this.labs.length) {
      this.bookingSlotValidation.selectedLabId = this.availableLabs[0].id;
      this.selectedLabRestTime = this.labs[0].restTimeDuration;
      this.selectedLabUserSessionTime = this.labs[0].userSessionDuration;
    }

    calendarInstance = new Calendar({
      target: document.getElementById("calendarWrapper"),
      props: {
        plugins: [TimeGrid],
        options: {
          view: "timeGridWeek",
          height: "700px",
          locale: this.currentlanguage,
          slotDuration: "00:30:00",
          scrollTime: "08:00:00",
          firstDay: 1,
          eventSources: [
            {
              events: async (data) => {
                return this.calendarEvents;
              },
            },
          ],
          dateClick: (dateClickInfo) => {
            this.calendarSlotClicked(dateClickInfo);
          },
          eventClick: async (eventClickInfo) => {
            this.isEventDeleteClick = true;
            if (eventClickInfo.event.display === "auto") {
              let btn = eventClickInfo.el.querySelector('[data-button="deleteButton"]')?.getElementsByTagName("path")[0];

              let nodeData = null;
              nodeData = eventClickInfo.jsEvent.target?.querySelector("span")?.getElementsByTagName("path")[0];
              if (!nodeData) {
                nodeData = eventClickInfo.jsEvent.target;
              }

              if (nodeData === btn) {
                this.isDeleteConfirmationVisible = true;
                this.deleteSlotId = eventClickInfo.event.id;
              }
            } else {
              this.calendarSlotClicked(eventClickInfo);
            }
          },
          eventContent: (info) => {
            return info.event.display === "auto" ? this.eventTemplate(info) : "";
          },
          dayHeaderFormat: (date: Date) => {
            const dateNumber = moment(date).date();
            const month = moment(date).month();
            const weekDay = moment(date).format("ddd");
            return `${weekDay} ${dateNumber} - ${month + 1}`;
          },
          slotLabelFormat: (date) => {
            return moment(date).format("HH:mm");
          },
          datesSet: async (info) => {
            this.currentWeek.from = moment(info.startStr).format("YYYY-MM-DD");
            this.currentWeek.to = moment(info.endStr).format("YYYY-MM-DD");
            await this.getSetCalendarEvent(this.bookingSlotValidation.selectedLabId as string)
          },
        },
      },
    });

    await this.getSetCalendarEvent(
      this.bookingSlotValidation.selectedLabId as string
    );
  }

  eventTemplate(eventInfo) {
    const eventStartTime = moment(eventInfo.event.start);
    const eventEndTime = moment(eventInfo.event.end).subtract(this.selectedLabRestTime, "minutes");

    const isDeleteButtonHidden: boolean = moment(eventInfo.event.start).isAfter(new Date());
    const timeDifference: number = moment(eventEndTime).diff(eventStartTime, "minutes");

    const displayStartTime: string = eventStartTime.format("HH:mm");
    const displayEndTime: string = eventEndTime.format("HH:mm");

    const labResetClass = eventInfo.event.extendedProps.isMine ? "maintenanceLab" : "maintenanceLabDisable";

    const restTimeHeight: number = Math.round((BOOKED_EVENT_HEIGHT / DEFAULT_BOOKED_EVENT) * eventInfo.event.extendedProps.labResetDuration);

    const eventTitle = eventInfo.event.title || eventInfo.event.extendedProps.title;
    const translationText = (
      <RawIntlProvider
        key={this.languageStore.currentLocale}
        value={this.currentlanguage}
      >
        <FormattedMessage {...intlMessages.bookingSlotNotAvailable} />
      </RawIntlProvider>
    );

    const eventId = eventInfo.event.extendedProps.id;

    !eventInfo.event.extendedProps.isMine &&
      setTimeout(() => {
        ReactDom.render(translationText, document.getElementById(eventId));
      });

    return eventInfo.event.extendedProps.isMine
      ? this.getEventDisplayData(
          displayStartTime,
          displayEndTime,
          eventTitle,
          labResetClass,
          restTimeHeight,
          timeDifference,
          eventId,
          isDeleteButtonHidden
        )
      : `<div class="ec-event-time" id="${eventId}"></div>`;
  }

  getEventDisplayData = (
    displayStartTime: string,
    displayEndTime: string,
    title: string,
    labResetClass: string,
    restTimeHeight: number,
    timeDifference: number,
    eventId: string,
    isDeleteButtonHidden: boolean
  ) => {
    const deleteButtonIcon = (
      <span
        data-button="deleteButton"
        style={{
          padding: "1px",
          position: "absolute",
          right: "4px",
          top: "4px",
          zIndex: 1,
        }}
      >
        <DeleteFilled
          style={{
            fontSize: "18px",
          }}
        />
      </span>
    );

    if (isDeleteButtonHidden) {
      setTimeout(() => {
        ReactDom.render(deleteButtonIcon, document.getElementById(eventId));
      });
    }
    // if(timeDifference < 50 ){
    //   return `<div class="ec-event-time">${displayStartTime} - ${displayEndTime}</div>
    //             <div class="ec-event-title eventTitlePopup">
    //             <div class="arrow-down"></div>${title}</div>
    //           <div class="${labResetClass}" style="height:${restTimeHeight}px"></div>`;
    // }
    return `<div id="${eventId}"></div><div class="ec-event-time">${displayStartTime} - ${displayEndTime}</div>
    <div class="ec-event-title">${title}</div>
    <div class="${labResetClass}" style="height:${restTimeHeight}px"></div>`;
  };

  calendarSlotClicked(slotData) {
    if (!this.isEventDeleteClick) {
      this.eventStartTime = moment(slotData.date);

      const slotDuration: number = this.selectedLabUserSessionTime + this.selectedLabRestTime;

      this.eventEndTime = moment(slotData.date).add(slotDuration, "minutes");

      if (this.bookingSlotValidation.selectedLabId == "") {
        Notification(
          NotificationEnum.Error, 
          "Booking",
          <RawIntlProvider
            key={this.languageStore.currentLocale}
            value={this.currentlanguage}
          >
            <FormattedMessage {...intlMessages.bookingValidationLabRequired} />
          </RawIntlProvider>
        );
        return;
      }

      if (!this.eventStartTime || !this.eventEndTime) {
        Notification(NotificationEnum.Error, "Booking", `Booking time is required`);
        return;
      }

      if (moment(this.eventStartTime).isBefore(new Date())) {
        Notification(
          NotificationEnum.Error,
          "Booking",
          <RawIntlProvider
            key={this.languageStore.currentLocale}
            value={this.currentlanguage}
          >
            <FormattedMessage {...intlMessages.bookingSlotInPastTime} />
          </RawIntlProvider>
        );
        return;
      }

      if (moment(this.eventStartTime).isAfter(this.eventEndTime)) {
        Notification(NotificationEnum.Error, "Booking", `Booking end time should be after starting time`);
        return;
      }

      this.isBookingConfirmationVisible = true;
    }

    if (this.isEventDeleteClick) {
      setTimeout(() => {
        this.isEventDeleteClick = false;
      }, 100);
    }
  }

  async getSetCalendarEvent(labId: string) {
    this.calendarLoader = true;
    const labBookedSlots: CalendarEvent[] = await this.apiService.digitalLab.fetchLabBookingsById(labId, this.currentWeek);
    const userBookedSlots: Calendar[] = await this.apiService.digitalLab.getAllSlotsOfParticularUser(this.userStore.userData.id);
    const labBookedSlotsFleetStack: CalendarEvent[] = await this.apiService.digitalLab.fetchLabBookingsIdenticalFleetStack(labId);

    const tempObj = {};
    
    this.calendarEvents = labBookedSlots
      .concat(userBookedSlots)
      .concat(labBookedSlotsFleetStack)
      .filter((data: CalendarEvent) => {
        if (data.resourceId && data.resourceId !== labId) {
          data.isMine = false;
        }
        return tempObj[data.id] ? false : (tempObj[data.id] = true);
      });

    const startTimeObj = {};
    this.calendarEvents = this.calendarEvents.filter((data: CalendarEvent) => {
      if (!data.isMine) {
        return startTimeObj[data.start.toISOString()] ? false : (startTimeObj[data.start.toISOString()] = true);
      }
      return true;
    });

    const eventsLength: number = this.calendarEvents.length;

    for (let i = 0; i < eventsLength; i++) {
      this.calendarEvents[i].start = new Date(moment(this.calendarEvents[i].start).local().format("YYYY-MM-DD HH:mm:ss"));
      this.calendarEvents[i].end = new Date(moment(this.calendarEvents[i].end).local().format("YYYY-MM-DD HH:mm:ss"));
      this.calendarEvents[i].color = this.calendarEvents[i].isMine ? "#40a9ff" : "#d9d9d9";
      this.calendarEvents[i].extendedProps = {
        isMine: this.calendarEvents[i].isMine,
        labResetDuration: this.selectedLabRestTime,
        id: this.calendarEvents[i].id,
        title: this.calendarEvents[i].title,
      };
    }
    calendarInstance.refetchEvents();
    this.calendarLoader = false;
  }

  async onChangeLab(id: SelectValue) {
    this.bookingSlotValidation.selectedLabId = id;
    const selectedLab: ILabs[] = this.labs.filter((lab: ILabs) => lab.id == id);
    this.selectedLabRestTime = selectedLab[0].restTimeDuration;
    this.selectedLabUserSessionTime = selectedLab[0].userSessionDuration;
    await this.getSetCalendarEvent(id as string);
  }

  getTime(dateTime: Moment): Moment {
    return moment({ h: dateTime.hours(), m: dateTime.minutes() });
  }

  async filterDLBySearchValue(val: SelectValue) {
    let stringVal = val as string;
    if (stringVal.length) {
      this.availableLabs = this.cloneAvailableLabs.filter(
        (lab: AvailableLabs) => {
          let labTitle = lab.title.toLocaleLowerCase();
          if (labTitle.includes(stringVal.toLocaleLowerCase())) {
            return true;
          }
        }
      );
    } else {
      this.availableLabs = this.cloneAvailableLabs;
    }
  }

  async onBlurDLSelectBox() {
    this.availableLabs = this.cloneAvailableLabs;
  }

  async bookSlot() {
    this.isBookingLoader = true;
    const selectedLab = this.labs.filter(
      (lab) => lab.id == this.bookingSlotValidation.selectedLabId
    );

    const eventObj = {
      id: uuid(),
      resourceId: selectedLab[0].id,
      start: new Date(moment(this.eventStartTime).local().format("YYYY-MM-DD HH:mm:ss")),
      end: new Date(moment(this.eventEndTime).local().format("YYYY-MM-DD HH:mm:ss")),
      title: `${selectedLab[0].title}`,
      editable: true,
      color: "",
      isMine: true,
      extendedProps: {
        isMine: true,
        labResetDuration: this.selectedLabRestTime,
        id: uuid(),
        title: `${selectedLab[0].title}`,
      },
      errorMessage: undefined,
    };

    const utcEventObj = {
      start: moment.utc(eventObj.start).format(),
      end: moment.utc(eventObj.end).format(),
      title: eventObj.title,
    };

    try {
      const bookSlotResponse: CalendarEvent = await this.apiService.digitalLab.bookLabSlot(eventObj.resourceId, utcEventObj);

      if (!bookSlotResponse.errorMessage) {
        const newEvents = [...this.calendarEvents];
        eventObj.id = bookSlotResponse.id;
        newEvents.push(eventObj);
        this.calendarEvents = newEvents;
        calendarInstance.addEvent(eventObj);

        this.eventStartTime = undefined;
        this.eventEndTime = undefined;
      } else {
        Notification(NotificationEnum.Error, "Booking", `${bookSlotResponse.errorMessage}`);
      }
    } catch (err) {
      Notification(NotificationEnum.Error, "Booking", `${err}`);
    }
    this.isBookingConfirmationVisible = false;
    this.isBookingLoader = false;
  }

  async hideBookingConformationModal() {
    this.isBookingConfirmationVisible = false;
    this.isDeleteConfirmationVisible = false;
  }

  async deleteSlot() {
    this.isBookingLoader = true;
    try {
      const response: boolean = await this.apiService.digitalLab.deleteBoolingSlot(this.deleteSlotId);
      if (response) {
        calendarInstance.removeEventById(this.deleteSlotId);
      } else {
        Notification(NotificationEnum.Error, "Booking", `${response}`);
      }
      this.isDeleteConfirmationVisible = false;
      this.isBookingLoader = false;
      this.deleteSlotId = "";
    } catch (err) {
      Notification(NotificationEnum.Error, "Booking", `${err}`);
    }
  }
}
