import { mainBlue, white } from "@u4i/styles/styles-variables";
import * as React from "react";
import { opacity7 } from "../../constants/Constants";
import { mapModuleStyling } from "../../constants/StaticMethods";

interface IPropTypes {
  cx: number
  cy: number
  isCompleted: boolean
  radius: number
  required: boolean
  title: string
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void 
  showModuleInVE: () => void
}

export const UseCasesIcon = (props: IPropTypes) => {
  const {
    cx,
    cy,
    isCompleted,
    radius,
    required,
    title,
    onModuleHover,
    showModuleInVE
  } = props;

  const [opacity, setOpacity] = React.useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }
  
  const moduleHover = (mouseEnter: boolean) => {
    onModuleHover(cx, cy, title, mouseEnter);
  }

  return (
    <svg>
      <g
        onClick={showModuleInVE}
        onMouseEnter={() => { handleOpacity(); moduleHover(true); }}
        onMouseLeave={() => { handleOpacity(); moduleHover(false); }}
        style={{ cursor: "pointer", ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          cx={cx}
          cy={cy}
          r={isCompleted ? radius : radius-3}
          style={mapModuleStyling(isCompleted)}
        >
        </circle>
        <g fill={isCompleted ? white : mainBlue}>
         <path
           d={`M${cx+3} ${cy+10.5}c-2.06.014-4.122-.027-6.18.02-1.36.266-.756 2.342.528 1.983 1.951-.013 3.904.027 5.853-.02 1.162-.213.983-2.006-.201-1.983z`}
           strokeWidth={0.6}
         />
         <path
           d={`M${cx} ${cy+16}c1.018.202 2.587-1.397 1.923-2.006h-4.092c.066 1.105 1.062 2.027 2.169 2.006z`}
           strokeWidth={0.6}
         />
          <path
            d={`M${cx+8.5} ${cy}c.154-4.146-3.062-8.067-7.154-8.737-4.204-.852-8.689 1.975-9.83 6.09-.909 2.928-.075 6.28 2.048 8.482 1.023.912 1.105 2.86 2.41 3.311 2.656-.017 5.317.035 7.97-.026 1.051-.965 1.367-2.621 2.445-3.645 1.342-1.493 2.05-3.482 2.112-5.475zm-2-.035c-.003 1.864-.94 3.536-2.16 4.881-.817.605-.82 2.41-1.97 2.297h-5.66c-.695-1.616-1.99-2.846-2.86-4.345-1.628-3.1-.292-7.283 2.828-8.87 3.065-1.77 7.345-.48 8.977 2.649a6.717 6.717 0 01.844 3.388z`}
            strokeWidth={0.7}
          />
          <path
            d={`M${cx} ${cy-10}c.978-.117.63-1.241.695-1.92-.062-.675.278-1.79-.695-1.903-.978.116-.63 1.24-.695 1.92.078.663-.306 1.814.695 1.903z`}
            strokeWidth={0.6}
          />
          <path
            d={`M${cx-8} ${cy-7}c1.337.786 1.4-1.115.406-1.56-.545-.445-.927-1.179-1.638-1.339-1.29.277-.362 1.467.262 1.929l.97.97z`}
            strokeWidth={0.6}
          />
          <path
            d={`M${cx+7.5} ${cy-7}c.772-.23 1.21-1.028 1.818-1.533 1.171-.588.064-2.32-.822-1.142-.538.58-1.17 1.083-1.64 1.718-.193.44.168.971.644.957z`}
            strokeWidth={0.6}
          />
          <path
            d={`M${cx-11} ${cy-1}c-.9.03-1.811-.065-2.702.054-1.166.887.38 1.58 1.25 1.336.703-.093 1.64.276 2.092-.425.203-.436-.159-.983-.64-.965z`}
            strokeWidth={0.6}
          />
          <path
            d={`M${cx-8.5} ${cy+6.5}c-.62.654-1.314 1.244-1.88 1.944-.268 1.424 1.346.936 1.777.124.432-.522 1.123-.894 1.296-1.573.03-.617-.782-.95-1.192-.495z`}
            strokeWidth={0.6}
          />
          <path
            d={`M${cx+8} ${cy+6.5}c-1.345-.808-1.408 1.112-.404 1.56.544.445.925 1.178 1.636 1.337 1.29-.276.361-1.466-.263-1.928l-.97-.97z`}
            strokeWidth={0.6}
          />
          <path
            d={`M${cx+13} ${cy-1}c-.899.03-1.81-.064-2.701.055-1.167.887.379 1.579 1.25 1.335.703-.093 1.639.277 2.091-.424.203-.437-.158-.983-.64-.966z`}
            strokeWidth={0.6}
          />
        </g>
      </g>
    </svg>
  )
}
