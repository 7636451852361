import { mainBlue, white } from '@u4i/styles/styles-variables'
import * as React from 'react'
import { opacity7 } from '../../constants/Constants'
import { mapModuleStyling } from '../../constants/StaticMethods'

interface IPropTypes {
  cx: number
  cy: number
  isCompleted: boolean
  radius: number
  required: boolean
  title: string
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void 
  showModuleInVE: () => void
}

export const DigitalJupyterLabIcon = (props: IPropTypes) => {
  const {
    cx,
    cy,
    isCompleted,
    radius,
    required,
    title,
    onModuleHover, 
    showModuleInVE
  } = props;

  const [opacity, setOpacity] = React.useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  const moduleHover = (mouseEnter: boolean) => {
    onModuleHover(cx, cy, title, mouseEnter);
  }

  return (
    <svg>
      <g
        onClick={showModuleInVE}
        onMouseEnter={() => { handleOpacity(); moduleHover(true); }}
        onMouseLeave={() => { handleOpacity(); moduleHover(false); }}
        style={{ cursor: "pointer", ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          cx={cx}
          cy={cy}
          r={isCompleted ? radius : radius-3}
          style={mapModuleStyling(isCompleted)}
        >
        </circle>
        <g fill={isCompleted ? white : mainBlue}>
          <ellipse
            cx={cx-2}
            cy={cy-9}
            rx={2.145}
            ry={2.045}
            style={{strokeWidth: 0.4}}
          />
          <ellipse
            cx={cx-3}
            cy={cy-14.5}
            rx={1.07}
            ry={1.02}
            style={{strokeWidth: 0.3}}
          />
          <ellipse
            cx={cx+3}
            cy={cy-8}
            rx={1.072}
            ry={1.022}
            style={{strokeWidth: 0.3}}
          />
          <ellipse
            cx={cx+4}
            cy={cy-12.5}
            rx={1.43}
            ry={1.36}
            style={{strokeWidth: 0.4}}
          />
          <path
            d={`m ${cx+11},${cy-5.5} c -0.304049,-0.782998 -1.256506,-0.432295 -1.905189,-0.511363 -5.78851,0 -11.57702,0 -17.36553,0 -1.723533,0.570155 0.422512,1.559341 0.651824,2.445705 0.200483,1.247547 0.05442,2.531614 0.09898,3.795359 0.0074,4.239002 -0.0148,8.478794 0.01113,12.717301 0.112425,1.281187 1.455993,1.998612 2.638812,1.837088 4.194824,-0.007 8.390332,0.01411 12.584727,-0.01061 1.22445,-0.07369 2.101525,-1.284617 1.92664,-2.467508 0.0075,-5.157009 -0.01497,-10.314776 0.01125,-15.47131 -0.107378,-1.135274 1.421532,-1.351529 1.347353,-2.33466 z m -4.969628,17.556817 c -1.430109,0 -2.860218,0 -4.290327,0 0,-0.454545 0,-0.909091 0,-1.363636 1.430109,0 2.860218,0 4.290327,0 0,0.454545 0,0.909091 0,1.363636 z m 0,-2.727272 c -1.430109,0 -2.860218,0 -4.290327,0 0,-0.454546 0,-0.909091 0,-1.363637 1.430109,0 2.860218,0 4.290327,0 0,0.454546 0,0.909091 0,1.363637 z m 0,-2.727272 c -1.430109,0 -2.860218,0 -4.290327,0 0,-0.454546 0,-0.909091 0,-1.363637 1.430109,0 2.860218,0 4.290327,0 0,0.454546 0,0.909091 0,1.363637 z m 0,-2.727274 c -1.430109,0 -2.860218,0 -4.290327,0 0,-0.454545 0,-0.90909 0,-1.363635 1.430109,0 2.860218,0 4.290327,0 0,0.454545 0,0.90909 0,1.363635 z m 0,-2.727273 c -1.430109,0 -2.860218,0 -4.290327,0 0,-0.454545 0,-0.909091 0,-1.363636 1.430109,0 2.860218,0 4.290327,0 0,0.454545 0,0.909091 0,1.363636 z m 2.145163,-4.09091 c 0.266395,1.269261 -1.231835,0.01667 -1.932729,0.03733 -2.662182,-0.764823 -5.459319,-0.242435 -8.064627,0.486817 -1.410567,0.326296 -2.858374,0.463103 -4.303735,0.49858 0.0075,-0.927003 0.0564,-1.909423 -0.464785,-2.727272 5.076887,0 10.153774,0 15.230661,0 -0.293695,0.5174 -0.466551,1.107538 -0.464785,1.704543 z`}
            style={{strokeWidth: 0.6}}
          />
        </g>
      </g>
    </svg>
  )
}
