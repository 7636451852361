import {defineMessages} from 'react-intl';

export default defineMessages({
  challengeName: {
    defaultMessage: 'Challenge name',
    id: 'CustomerGrading.ProgressAttemptsList.ChallengeName',
  },
  date: {
    defaultMessage: 'Date',
    id: 'CustomerGrading.ProgressAttemptsList.Date',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'CustomerGrading.ProgressAttemptsList.Failed',
  },
  passed: {
    defaultMessage: 'Passed',
    id: 'CustomerGrading.ProgressAttemptsList.Passed',
  },
  status: {
    defaultMessage: 'Status',
    id: 'CustomerGrading.ProgressAttemptsList.Status',
  },
  submitted: {
    defaultMessage: 'Submitted',
    id: 'CustomerGrading.ProgressAttemptsList.Submitted',
  },
});
