import DOMPurify from 'dompurify';
import React from 'react';
import {computed,makeObservable} from 'mobx';
import {observer} from 'mobx-react';

interface IPropTypes {
  content: string;
}

export const QuestionContent = observer(class QuestionContent extends React.Component<IPropTypes> {
  constructor(props: IPropTypes) {
    super(props);

    makeObservable(this, {
      sanitizedContent: computed
    });
  }

  get sanitizedContent(): string {
    return DOMPurify.sanitize(this.props.content, {ADD_ATTR: ['target']});
  }

  render() {
    return (
      <p
        className="reactive-forms__attempt-grading-form__question-content"
        dangerouslySetInnerHTML={{__html: this.sanitizedContent}}
      />
    );
  }
});
