import { action, observable, runInAction, makeObservable } from "mobx";
import { AdminUserEntity } from "@u4i/state/entities/admin/AdminUserEntity";
import { IAdminUser } from "@u4i/parsers/admin/AdminUsersParser";
import { IAdminUserGroup } from "@u4i/parsers/admin/AdminUserGroupParser";
import { IApi, IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import { IAdminRedirectUrl } from "@u4i/parsers/admin/AdminRedirectUrlParser";
import {
  Notification,
  NotificationEnum,
} from "@u4i/modules/Admin/common/Notifications/Notification";

export class AdminUserDataStore {
  private apiService: IApi;
  private rootStore: IRootStore;
  fetching = true;
  adminUserEntity?: AdminUserEntity;
  becomeUserRedirectUrl = "";
  resetUserPasswordError?: string;
  resetUserPasswordUrl = "";
  userGroups: Array<IAdminUserGroup>;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      fetching: observable,
      adminUserEntity: observable,
      becomeUserRedirectUrl: observable,
      resetUserPasswordError: observable,
      resetUserPasswordUrl: observable,
      userGroups: observable,
      becomeUser: action.bound,
      fetchResetUserPasswordUrl: action.bound,
      loadUserEntity: action.bound,
      loadUserGroups: action.bound,
      resetData: action.bound,
      resetPassword: action.bound,
    });

    const { apiService } = rootStore;

    this.apiService = apiService;
    this.rootStore = rootStore;
  }

  async becomeUser(id: string) {
    try {
      const becomeUserRedirectUrl: IAdminRedirectUrl =
        await this.apiService.admin.users.becomeUser(id);

      runInAction(
        () => (this.becomeUserRedirectUrl = becomeUserRedirectUrl.url)
      );
    } catch (error) {
      const becomeUserError =
        error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "Become user", becomeUserError);
    }
  }

  async fetchResetUserPasswordUrl(id: string) {
    try {
      const resetPasswordUrl: string =
        await this.apiService.admin.users.fetchResetUserPasswordUrl(id);

      runInAction(() => (this.resetUserPasswordUrl = resetPasswordUrl));
    } catch (error) {
      const resetPasswordError =
        error.response.data.errorMessage || error.response.data.message;

      Notification(
        NotificationEnum.Error,
        "User password fetch",
        resetPasswordError
      );
    }
  }

  async loadUserEntity(id: string) {
    this.fetching = true;

    try {
      const activeAdminUser: IAdminUser =
        await this.apiService.admin.users.fetchUserData(id);

      runInAction(() => {
        this.adminUserEntity = new AdminUserEntity(
          activeAdminUser,
          this.rootStore
        );

        this.fetching = false;
      });
    } catch (error) {
      runInAction(() => {
        this.fetching = false;
        throw error;
      });
    }
  }

  async loadUserGroups(
    config = {
      current: 0,
      filters: {},
      limit: 10,
      offset: 0,
      orderBy: {},
    }
  ) {
    try {
      const userGroups: IPaginatedItems<IAdminUserGroup> =
        await this.apiService.admin.userGroups.fetchUserGroupsData(config);

      runInAction(() => {
        const sortedUserGroupsList: IAdminUserGroup[] = userGroups.items.sort(
          (previousItem: IAdminUserGroup, currentItem: IAdminUserGroup) => {
            if (
              previousItem.name.toLowerCase() < currentItem.name.toLowerCase()
            )
              return -1;
            if (
              previousItem.name.toLowerCase() > currentItem.name.toLowerCase()
            )
              return 1;
            return 0;
          }
        );

        this.userGroups = sortedUserGroupsList;
      });
    } catch (error) {
      throw error;
    }
  }

  resetData() {
    this.adminUserEntity = undefined;
    this.becomeUserRedirectUrl = "";
    this.fetching = true;
    this.resetUserPasswordUrl = "";
    this.resetUserPasswordError = undefined;
    this.userGroups = [];
  }

  async resetPassword(userId: string) {
    try {
      await this.apiService.admin.users.resetUserPassword(userId);
    } catch (error) {
      this.resetUserPasswordError =
        error.response.data.errorMessage || error.response.data.message;

      Notification(
        NotificationEnum.Error,
        "Reset user password",
        this.resetUserPasswordError
      );
    }
  }

  updateUser(userData: IAdminUser) {
    if (this.adminUserEntity) {
      this.adminUserEntity.updateUser(userData);
    }
  }
}
