import { action, autorun, observable, runInAction, makeObservable } from "mobx";

import UserStore from "./UserStore";
import { IApi } from "./ServicesInterfaces";
import { IRootStore } from "./RootStore";

class NotificationsStore {
  private apiService: IApi;
  private unreadCountIntervalId: number;
  private userStore: UserStore;
  public countUpdatedImpersonatedUser: boolean = false;
  public unreadCount = 0;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      countUpdatedImpersonatedUser: observable,
      markAllAsSeen: action.bound,
      unreadCount: observable,
      updateUnreadCount: action.bound,
    });

    const { apiService, userStore } = rootStore;

    this.apiService = apiService;
    this.userStore = userStore;

    autorun(this.pollingTimerSetup);
  }

  async markNotificationAsSeen(id) {
    await this.apiService.notifications.markNotificationAsSeen(id);
  }

  async markAllAsSeen() {
    await this.apiService.notifications.markAllAsSeen();

    runInAction(() => {
      this.unreadCount = 0;
    });
  }

  pollingTimerSetup = () => {
    if (this.userStore.isAuthorized) {
      this.updateUnreadCount();

      this.unreadCountIntervalId = window.setInterval(this.updateUnreadCount, 2 * 60 * 1000);
    } else {
      window.clearInterval(this.unreadCountIntervalId);
    }
  };

  async updateUnreadCount() {
    try {
      const unreadCount = await this.apiService.notifications.fetchUnreadCount();

      runInAction(() => {
        this.unreadCount = unreadCount;
        if (this.userStore.userData.impersonated) {
          this.countUpdatedImpersonatedUser = !this.countUpdatedImpersonatedUser;
        }
      });
    } catch (error) {
      this.unreadCount = 0;
    }
  }
}

export default NotificationsStore;
