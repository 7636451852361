import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_feedback-survey-section.scss';

interface IProps {
  onFeedbackSurveyStart: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FeedbackSurveySection: FC<IProps> = (props: IProps) => (
  <div className="course-detail-page__feedback-survey-section">
    <div className="container">
      <div className="course-detail-page__feedback-survey-section__content">
        <h3 className="course-detail-page__feedback-survey-section__heading">
          <FontAwesome
            className="far course-detail-page__feedback-survey-section__icon"
            name="comment-dots"
          />

          <FormattedMessage {...intlMessages.title} />
        </h3>

        <button
          className="course-detail-page__feedback-survey-section__button"
          onClick={(ev) => props.onFeedbackSurveyStart(ev)}
          type="button"
        >
          <FormattedMessage {...intlMessages.name} />
        </button>
      </div>
    </div>
  </div>
);
