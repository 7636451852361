import {defineMessages} from 'react-intl';

export default defineMessages({
  errorHeading: {
    defaultMessage: 'Sorry, there was a problem showing this content',
    id: 'Player.ElementManager.UnsupportedElement.ErrorHeading',
  },
  errorMessage: {
    defaultMessage: 'Please contact coach@university4industry.com for support.',
    id: 'Player.ElementManager.UnsupportedElement.ErrorMessage',
  },
});
