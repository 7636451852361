import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {Alert} from '@u4i/common/Alert';
import {AlertStatusEnum} from '@u4i/common/enums/AlertStatusEnum';
import {EXTERNAL_BASE_URL} from '@u4i/constantSettings';
import { LanguageStore } from '@u4i/state/LanguageStore';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import logoImage from '@u4i/assets/images/logo.png';
import intlMessages from './intlMessages';
import './_general-terms.scss';

interface IPropTypes {
  languageStore: LanguageStore
  onClickAccept: () => void
}

const GeneralTerms: FC<IPropTypes> = (props) => {
  const {languageStore, onClickAccept} = props;

  const isEnglish: boolean = languageStore.currentLocale === LanguageEnum.EN_US;

  return (
    <div className="general-terms-of-service-modal__general-terms">
      <img alt="Logo" className="general-terms-of-service-modal__general-terms__logo" src={logoImage} />

      <h3 className="general-terms-of-service-modal__general-terms__heading">
        <FormattedMessage {...intlMessages.heading} />
      </h3>

      <p className="general-terms-of-service-modal__general-terms__message">
        <FormattedMessage {...intlMessages.messageBeforeLink} />

        <a
          href={isEnglish ? `${EXTERNAL_BASE_URL}/en/privacy-policy` : `${EXTERNAL_BASE_URL}/de/datenschutz`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <FormattedMessage {...intlMessages.privacyPolicy} />
        </a>

        <FormattedMessage {...intlMessages.messageAfterLink} />
      </p>

      <div className="general-terms-of-service-modal__general-terms__buttons">
        <button className="general-terms-of-service-modal__general-terms__button" onClick={onClickAccept}>
          <FormattedMessage {...intlMessages.accept} />
        </button>
      </div>

      <div className="general-terms-of-service-modal__general-terms__alert">
        <Alert
          message={<FormattedMessage {...intlMessages.contactParagraph}
          values={{mail: <a href="mailto:info@university4industry.com">info@university4industry.com</a>}} />}
          type={AlertStatusEnum.INFO}
        />
      </div>
    </div>
  );
}

export default GeneralTerms;
