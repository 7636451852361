import { AxiosInstance } from "axios";

export class RedeemPageApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  checkCouponCode = async (collaborationCode: string): Promise<any> => {
    const response = await this.apiClient.post(`/v1/groups/check-coupon-code`, {collaboration_code: collaborationCode});
    return response.data;
  }

  joinUserGroupByCode = async (collaborationCode: string): Promise<any> => {
    const response = await this.apiClient.post(`/v1/groups/join-by-code`, {collaboration_code: collaborationCode});
    return response.data;
  }

}
