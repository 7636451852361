import PropTypes from 'prop-types';
import React from 'react';
import {CSSGrid, layout, makeResponsive, measureItems} from 'react-stonecutter';

const Grid = makeResponsive(measureItems(CSSGrid), {
  maxWidth: 1200,
  minPadding: 20,
});

MasonryGrid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  itemWidth: PropTypes.number.isRequired,
};

function MasonryGrid(props) {
  return (
    <Grid
      columnWidth={props.itemWidth}
      duration={400}
      gutterHeight={24}
      gutterWidth={24}
      layout={layout.pinterest}
      style={{margin: '0 auto'}}
    >
      {props.children}
    </Grid>
  );
}

export default MasonryGrid;
