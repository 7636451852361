import {action, computed, observable, runInAction,makeObservable} from 'mobx';

import {IApi} from '@u4i/state/ServicesInterfaces';
import {ICompareMe} from '@u4i/parsers/CompareMeParser';
import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {BaseSection} from '../BaseSection';
import {LandingPageStore} from '../../LandingPageStore';
import {VisualComponent} from './VisualComponent';
import { mainBlue, green, orange, white } from '@u4i/styles/styles-variables';

export const enum CompareMeTabIdEnum {
  CHALLENGES = 'challenges',
  CURRICULUM = 'curriculum',
  SKILLS = 'skills',
}

const accentColorsMap = new Map<CompareMeTabIdEnum, string>([
  [CompareMeTabIdEnum.CHALLENGES, orange],
  [CompareMeTabIdEnum.CURRICULUM, green],
  [CompareMeTabIdEnum.SKILLS, mainBlue],
]);

export class CompareMeSection extends BaseSection {
  private apiService: IApi;
  comparationData?: ICompareMe;
  currentTabId: CompareMeTabIdEnum;
  failedToFetch = false;
  fetching = true;
  private landingPageStore: LandingPageStore;
  lastStatisticalCirclesTabId: CompareMeTabIdEnum;

  constructor(rootStore: IRootStore, sectionData: ISection) {
    super(sectionData, VisualComponent);

    makeObservable(this, {
      comparationData: observable,
      currentTabId: observable,
      failedToFetch: observable,
      fetching: observable,
      lastStatisticalCirclesTabId: observable,
      isVisible: computed,
      relativePositionSliderData: computed,
      statisticalCirclesData: computed,
      changeTab: action.bound,
      reloadContent: action.bound
    });

    const {apiService, landingPageStore} = rootStore;
    this.apiService = apiService;
    this.landingPageStore = landingPageStore;

    this.reloadContent();
  }

  get isVisible(): boolean {
    return !this.landingPageStore.isPrivateModeEnabled && !this.failedToFetch;
  }

  get relativePositionSliderData() {
    return {
      accentColor: accentColorsMap.get(this.currentTabId) || white,
      percentage: this.comparationData ? this.comparationData[this.currentTabId]!.userRelativeTopPosition : 0,
    };
  }

  get statisticalCirclesData() {
    let tabData = {currentUser: 0, groupAverage: 0, groupTop: 0};

    if (this.comparationData) {
      switch (this.lastStatisticalCirclesTabId) {
        case CompareMeTabIdEnum.CHALLENGES:
          if (this.comparationData.challenges) {
            tabData = this.comparationData.challenges.completionCount;
          }
          break;

        case CompareMeTabIdEnum.SKILLS:
          if (this.comparationData.skills) {
            tabData = this.comparationData.skills.completionCount;
          }
          break;
      }
    }

    return {
      accentColor: accentColorsMap.get(this.lastStatisticalCirclesTabId) || white,
      ...tabData,
    };
  }

  changeTab(newTabId: CompareMeTabIdEnum) {
    this.currentTabId = newTabId;

    if (newTabId === CompareMeTabIdEnum.CHALLENGES || newTabId === CompareMeTabIdEnum.SKILLS) {
      this.lastStatisticalCirclesTabId = newTabId;
    }
  }

  async reloadContent(): Promise<void> {
    if (this.landingPageStore.isPrivateModeEnabled) {
      return;
    }

    try {
      this.fetching = true;

      const compareMeData = await this.apiService.landingPages.fetchSectionItems<ICompareMe>(
        this.id,
        this.type,
        this.landingPageStore.previewModeEnabled,
        this.landingPageStore.itemsLanguage,
      );

      runInAction(() => {
        this.comparationData = compareMeData[0];

        if (this.comparationData.skills) {
          this.changeTab(CompareMeTabIdEnum.SKILLS);
        } else if (this.comparationData.challenges) {
          this.changeTab(CompareMeTabIdEnum.CHALLENGES);
        } else {
          this.changeTab(CompareMeTabIdEnum.CURRICULUM);
        }

        this.fetching = false;
      });
    } catch (error) {
      runInAction(() => {
        this.failedToFetch = true;
        this.fetching = false;
      });
    }
  }
}
