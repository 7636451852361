import is from 'is_js';

class Route {
  creatorFunction:any = null;

  constructor(creatorFunction) {
    if (!is.function(creatorFunction)) {
      throw new Error('Route must have a creator function');
    }

    this.creatorFunction = creatorFunction;
  }

  parse(params = {}) {
    if (!is.object(params) || is.function(params)) {
      throw new Error('Route params must be an object');
    }

    return this.creatorFunction(params);
  }
}

export default Route;
