import { RouteComponentProps } from '@reach/router';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';
import { OnboardingSequenceEnum } from '@u4i/common/enums/OnboardingSequenceEnum';
import { OnboardingTour } from '@u4i/common/OnboardingTour';
import StickyElement from '@u4i/common/StickyElement';
import CircularProgress from '@u4i/common/CircularProgress';
import { IRootStore } from '@u4i/state/RootStore';
import { inject, observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { injectIntl, RawIntlProvider, WrappedComponentProps } from 'react-intl'
import Tour from 'shepherd.js/src/types/tour';
import withUser from '../UserManager/withUser'
import { BackButton } from './components/BackButton/BackButton';
import singleton from './components/BackButton/BackController';
import { CertificateSection } from './components/CertificateSection/CertificateSection';
import { ChallengesStatus } from './components/ChallengesStatus/ChallengesStatus';
import { ChaptersSection } from './components/ChaptersSection/ChaptersSection';
import { ErrorMessage } from './components/ErrorMessage/ErrorMessage';
import { FeedbackSurveySection } from './components/FeedbackSurveySection/FeedbackSurveySection';
import { Header } from './components/Header/Header';
import { MenuBar } from './components/MenuBar/MenuBar';
import { Overview } from './components/Overview/Overview';
import { RecentChapter } from './components/RecentChapter/RecentChapter';
import { Trailer } from './components/Trailer/Trailer';
import { IChapterData } from './interfaces/ChapterInterface';
import './_course-detail-page.scss';

interface IMatchParams extends RouteComponentProps {
  match: {
    params: {
      skillSlug: string
    }
  }
}

interface IProps extends IMatchParams, WrappedComponentProps {
  rootStore: IRootStore
}

const CourseDetailPage: FC<IProps> = injectIntl(inject("rootStore")(
  observer((props: IProps) => {
    const {
      match,
      rootStore
    } = props;

    const {
      isAuthorized,
      loginModalHandle,
      purchaseCourseModalHandle
    } = rootStore.userStore;

    const {
      chapterData,
      courseData,
      errorCode,
      page,
      fetching,
      fetchingChapters,
      totalPages,
      changeChaptersPage,
      enrollFreeCourse,
      loadChapters,
      loadCourseData,
      recordCourseVisit,
      resetState,
      startFeedbackSurvey
    } = props.rootStore.courseDetailPageStore;

    useEffect(() => {
      Promise.all([
        loadCourseData(match.params.skillSlug),
        loadChapters(match.params.skillSlug),
        recordVisit()
      ])
    }, [!courseData]);

    useEffect(() => {
      updateActiveTourMock();

      return () => {
        resetState();
      }
    }, []);
    
    const [forceTourMock, setforceTourMock] = useState<boolean>(false);
    const courseDetailTour: Tour | undefined = rootStore.onboardingStore.tours.get(OnboardingSequenceEnum.SKILL_DETAIL);
    const viewedChapterCount: number = chapterData?.filter(item => item.viewed).length;
    const touchedChallengesCount: number = courseData?.number_of_submitted_challenges + courseData?.number_of_completed_challenges;
    const hasChallenges: boolean = courseData?.number_of_challenges > 0;
    const allChallengesTouched: boolean = courseData?.number_of_challenges === touchedChallengesCount;
    const showChallengesStatus: boolean = allChallengesTouched && courseData?.number_of_submitted_challenges !== 0;
    const showRecentChapter = (!allChallengesTouched || !hasChallenges) && courseData?.last_viewed_chapter;
    const haveCourseAccess: boolean = courseData?.is_bought || courseData?.price_summary?.discount?.percentage == 100;

    const backTarget = singleton.getTargetFor(match.params.skillSlug);
    const backHandler = () => AppNavigator.directPush(backTarget);

    const handleHeaderButtonClick = () => {
      if (isAuthorized) {
        if (courseData?.is_free) {
          enrollFreeCourse(courseData?.id)
            .then(reloadCourseData)
            .then(reloadChapters);
        } else {
          purchaseCourseModalHandle(true, match.params.skillSlug);
        }
      } else {
        loginModalHandle(true, match.params.skillSlug, true, courseData.id);
      }
    };

    useEffect(() => {
      if (haveCourseAccess && !courseData?.is_bought) {
        enrollFreeCourse(courseData?.id);
      }
    }, [haveCourseAccess, courseData?.id])

    const handleChapterStart = (chapterData: IChapterData) => {
      AppNavigator.push(appRoutes.playerSkill, { chapterSlug: chapterData?.slug, skillSlug: courseData?.slug });
    };

    const reloadCourseData = (): Promise<any> => {
      resetState();

      return loadCourseData(props.match.params.skillSlug);
    };

    const reloadChapters = () => loadChapters(props.match.params.skillSlug);

    const recordVisit = () => {
      if (isAuthorized) {
        recordCourseVisit(match.params.skillSlug);
      }
    };

    const updateActiveTourMock = () => {
      if (courseDetailTour)
        setforceTourMock(courseDetailTour.isActive());
    };

    if (errorCode) {
      return (
        <div className="course-detail-page__error-wrapper">
          <ErrorMessage errorCode={errorCode} />
        </div>
      );
    }

    if (fetching) {
      return <CircularProgress />;
    }

    if (!courseData) {
      return null;
    }

    return (
      <div className="course-detail-page">
        <OnboardingTour onboardingSequence={OnboardingSequenceEnum.SKILL_DETAIL} />

        {(backTarget !== null && !rootStore.scormProxyService.isEnabled) &&
          <BackButton onClick={backHandler} />
        }

        <RawIntlProvider
          key={courseData?.language}
          value={rootStore.languageStore.intlMap.get((courseData?.language == LanguageEnum.EN_US || courseData?.language == LanguageEnum.DE_DE) ? courseData?.language : LanguageEnum.EN_US)!}
        >
          <>
            <Header
              backgroundUrl={courseData?.image.url}
              chapterCount={courseData?.number_of_chapters}
              duration={courseData?.duration}
              feedbackSubmitted={courseData?.feedbackSubmission?.is_feedback_submitted!}
              feedbackSurvey={courseData?.feedbackSurvey}
              isBought={haveCourseAccess && !forceTourMock}
              isCompleted={courseData?.is_completed}
              isFeedbackMandatory={courseData?.is_feedback_mandatory}
              onFeedbackSurveyStart={startFeedbackSurvey}
              numberOfChallenges={courseData?.number_of_challenges}
              numberOfCompletedChallenges={courseData?.number_of_completed_challenges}
              showCertificate={courseData?.show_certificate}
              title={courseData?.title}
              totalNumberOfChaptersAndOptionalChallenges={courseData?.total_number_of_chapters_and_optional_challenges}
              viewedChapterCount={viewedChapterCount}
            />

            {(!haveCourseAccess || forceTourMock) &&
              <StickyElement stickyTopOffset={64}>
                <MenuBar
                  hasChallenges={courseData?.number_of_challenges > 0}
                  isDiscounted={courseData?.price_summary.discount.amount.value > 0}
                  isFree={courseData?.is_free}
                  onBuyButtonClick={handleHeaderButtonClick}
                  priceDiscount={courseData?.price_summary?.discounted_price.formatted}
                  priceNet={courseData?.price_summary.price_net.formatted}
                  showCertificate={courseData?.show_certificate}
                />
              </StickyElement>
            }

            {(!haveCourseAccess && courseData?.trailer_url) &&
              <Trailer description={courseData?.trailer_description} videoUrl={courseData?.trailer_url} />
            }

            <section id="overview">
              <Overview
                description={courseData.description}
                courseId={courseData.id}
                hasDigitalLabsWithScheduler={courseData.has_digital_labs_with_scheduler}
                isBought={haveCourseAccess && !forceTourMock}
              />
            </section>

            {showChallengesStatus && <ChallengesStatus />}

            {showRecentChapter &&
              <RecentChapter
                chapterData={courseData?.last_viewed_chapter}
                onChapterStart={handleChapterStart}
              />
            }

            <section id="certificate">
              {(!haveCourseAccess && courseData?.show_certificate && courseData?.number_of_challenges > 0) &&
                <CertificateSection language={courseData?.language} />
              }
            </section>

            <section id="chapters">
              {chapterData && <ChaptersSection
                changeChaptersPage={changeChaptersPage}
                chapters={chapterData}
                fetching={fetching}
                isSkillBought={haveCourseAccess}
                onChapterStart={handleChapterStart}
                onPageChange={reloadChapters}
                page={page}
                skillHasChallenges={courseData?.number_of_challenges > 0}
                totalPages={totalPages}
                hasMandatoryChapters={courseData?.has_mandatory_chapters}
                fetchingChapters={fetchingChapters}
              />}
            </section>

            {(haveCourseAccess && typeof (courseData?.feedbackSurvey) == 'object' && courseData?.feedbackSurvey?.length != 0) &&
              <FeedbackSurveySection onFeedbackSurveyStart={startFeedbackSurvey} />
            }
          </>
        </RawIntlProvider>
      </div>
    )
  }))
)

export default withUser(CourseDetailPage);
