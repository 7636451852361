import React from 'react';
import {observer} from 'mobx-react';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import { ProgramSection } from './ProgramSection';
import { ProgramLearnersViewMain } from '../ProgramLearnersViewMain/ProgramLearnersViewMain';

interface IPropTypes {
  sectionModel: ProgramSection;
}

export const VisualComponentProgram = observer(class VisualComponentProgram extends React.Component<IPropTypes> {
  render() {
    return (
      <BasicInfoWrapper
        isLoading={false}
        itemsCountLabel={this.props.sectionModel.items.length}
        sectionModel={this.props.sectionModel}
      >
        <>
          {this.props.sectionModel.items != undefined && this.props.sectionModel.items.length &&
            <ProgramLearnersViewMain programId={this.props.sectionModel.items[0].id}/>
          }
        </>
      </BasicInfoWrapper>
    );
  }
});
