import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';

import './_summaries-list-item.scss';

interface IPropTypes {
  children: string
}

export const SummariesListItem: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="player__summaries-list-item">
    <div className="player__summaries-list-item__icon">
      <FontAwesome name="check" />
    </div>

    <p className="player__summaries-list-item__text">
      {props.children}
    </p>
  </div>
);
