import moment from 'moment';
import React from 'react';
import {BaseFilter} from '../BaseFilter';
import {Picker} from './Picker';

export class DateRangeFilter extends BaseFilter<string> {
  private outputFromFilterName: string;
  private outputToFilterName: string;

  constructor(
    attachedToColumn: string,
    outputFromFilterName: string,
    outputToFilterName: string,
  ) {
    super(attachedToColumn, {});

    this.outputFromFilterName = outputFromFilterName;
    this.outputToFilterName = outputToFilterName;
  }

  private handleDate = (dates: string) => {
    const date = moment(dates, 'DD-MMM-YYYY');
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  }

  private handleValueChange = (values: { startDate?: string; endDate?: string }) => {
    this.updateValues({
      [this.outputFromFilterName]: values.startDate ? this.handleDate(values.startDate) : null,
      [this.outputToFilterName]: values.endDate ? this.handleDate(values.endDate) : null
    });
  };

  render() {
    return (
      <Picker
        key={this.id}
        onValueChange={this.handleValueChange}
        value={{
          endDate: this.currentValues[this.outputToFilterName],
          startDate: this.currentValues[this.outputFromFilterName],
        }}
      />
    );
  }
}
