import cx from "classnames";
import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ChallengeProgressStatusEnum } from "@u4i/common/enums/ChallengeProgressStatusEnum";
import ChallengeIconCompleted from "@u4i/assets/svg/ChallengeIconCompleted.svg";
import ChallengeIconInGrading from "@u4i/assets/svg/ChallengeIconInGrading.svg";
import ChallengeIconOpen from "@u4i/assets/svg/ChallengeIconOpen.svg";
import ChallengeIconOpenTryAgain from "@u4i/assets/svg/ChallengeIconOpenTryAgain.svg";
import intlMessages from "./intlMessages";
import { Row, Col, Typography, Divider, notification } from "antd";
import { CloseCircleFilled, InfoCircleFilled, LockFilled } from "@ant-design/icons";
import { sliceText } from "@u4i/utils/helpers";
import { mainBlue } from "@u4i/styles/styles-variables";
import { IChapterPlayer } from "@u4i/modules/VirtualExperience/PlayerState/player-interface";
import "./_challenge-list-item.scss";

interface IPropTypes extends WrappedComponentProps {
  challengeId: string;
  challengeName: string;
  challengeStatus: ChallengeProgressStatusEnum;
  chapterId: string;
  isExpanded: boolean;
  isNowViewed: boolean;
  onClick: Function;
  topicId: string;
  chapters: Array<IChapterPlayer>;
  hasMandatoryChapters: boolean;
}

const challengeIcon = new Map([
  [ChallengeProgressStatusEnum.COMPLETED, ChallengeIconCompleted],
  [ChallengeProgressStatusEnum.GRADING_IN_PROGRESS, ChallengeIconInGrading],
  [ChallengeProgressStatusEnum.OPEN, ChallengeIconOpen],
  [ChallengeProgressStatusEnum.OPEN_TRY_AGAIN, ChallengeIconOpenTryAgain],
]);

const challengeStatusMessage = new Map([
  [
    ChallengeProgressStatusEnum.COMPLETED,
    <FormattedMessage {...intlMessages.challengeCompleted} />,
  ],
  [
    ChallengeProgressStatusEnum.GRADING_IN_PROGRESS,
    <FormattedMessage {...intlMessages.challengeInGrading} />,
  ],
  [
    ChallengeProgressStatusEnum.OPEN,
    <FormattedMessage {...intlMessages.challengeOpen} />,
  ],
  [
    ChallengeProgressStatusEnum.OPEN_TRY_AGAIN,
    <FormattedMessage {...intlMessages.challengeOpenTryAgain} />,
  ],
]);

class ChallengeListItem extends React.Component<IPropTypes> {
  checkIfChapterDisabled = () => {
    if (!this.props.hasMandatoryChapters) return false;
    const foundChapter = this.props.chapters.find((chapter) => chapter.id === this.props.chapterId);

    if (foundChapter) {
      for (const topic of foundChapter.topics) {
        if (topic.viewed) return false;
      }
    }
    return true;
  };

  handleClick = () => {
    this.props.onClick(
      this.props.challengeId,
      this.props.chapterId,
      this.props.topicId
    );
  };

 
  showNotification = () => {
    notification.info({
      message:  this.props.intl.formatMessage(intlMessages.warningMessage),
      placement: "topRight",
      closeIcon: ( <CloseCircleFilled  style={{ color: 'white', fontSize: 24 }}/> ),
      duration: 4,
      key: "chapter_not",
      style: { width: 600, background: mainBlue, marginTop: 50 },
      className: 'welcome_back_popup',
      icon: ( <InfoCircleFilled style={{ color: 'white' }}/> )
    });
  };

  render() {
    return (
      <>
        <Row
          onClick={() => {
            if (!this.checkIfChapterDisabled()) {
              this.handleClick();
            } else {
              this.showNotification();
            }
          }}
          gutter={8}
          className={cx({
            chapter_list_item: true,
          })}
        >
          <Col>
            {this.checkIfChapterDisabled() ? (
              <div
                className="circle_icon"
                style={{
                  borderColor: "black"
                }}
              >
                <LockFilled
                  className="icon_font_calcualator"
                  style={{
                    color: "black"
                  }}
                />
              </div>
            ) : (
              <div
                className={cx({
                  "circle_icon": true,
                  "status_circle--completed": this.props.challengeStatus === ChallengeProgressStatusEnum.COMPLETED,
                  "status_circle--in-grading": this.props.challengeStatus === ChallengeProgressStatusEnum.GRADING_IN_PROGRESS,
                  "status_circle--open": this.props.challengeStatus === ChallengeProgressStatusEnum.OPEN,
                  "status_circle--open-try-again": this.props.challengeStatus === ChallengeProgressStatusEnum.OPEN_TRY_AGAIN
                })}
              >
                <img
                  alt={this.props.challengeStatus}
                  className="player__challenge-list-item__icon icon_font_calcualator_img"
                  src={challengeIcon.get(this.props.challengeStatus)}
                />
              </div>
            )}
          </Col>
          <Col>
            <Typography.Title
              level={5}
              style={{
                marginBottom: 0,
                marginTop:0
              }}
              className="title_font_calcualator"
            >
              {sliceText(this.props.challengeName, 50)}
            </Typography.Title>
            <Typography.Text
              type="secondary"
       
            >
              <FormattedMessage {...intlMessages.status} />{" "}
              <span
                className={cx({
                  "player__challenge-list-item__status-message": true,
                  "player__challenge-list-item__status-message--completed": this.props.challengeStatus === ChallengeProgressStatusEnum.COMPLETED,
                  "player__challenge-list-item__status-message--in-grading": this.props.challengeStatus === ChallengeProgressStatusEnum.GRADING_IN_PROGRESS,
                  "player__challenge-list-item__status-message--open": this.props.challengeStatus === ChallengeProgressStatusEnum.OPEN,
                  "player__challenge-list-item__status-message--open-try-again": this.props.challengeStatus === ChallengeProgressStatusEnum.OPEN_TRY_AGAIN
                })}
              >
                {challengeStatusMessage.get(this.props.challengeStatus)}
              </span>
            </Typography.Text>
          </Col>
        </Row>
        <Divider
          style={{
            borderColor: "#F0F0F0",
            margin: 0
          }}
        />
      </>
    );
  }
}

export default injectIntl(ChallengeListItem);
