import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage, RawIntlProvider} from 'react-intl';
import {inject, observer} from 'mobx-react';
import { RouteComponentProps } from "@reach/router";
import CircularProgress from '@u4i/common/CircularProgress';
import {IRootStore} from '@u4i/state/RootStore';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {UserAnonymitySettingsEnum} from '@u4i/common/enums/UserAnonymitySettingsEnum';
import {Discuss} from './components/Discuss';
import { DiscussPageHeader } from './components/DiscussPageHeader/DiscussPageHeader';
import { DiscussPageInstructions } from './components/DiscussPageInstructions/DiscussPageInstructions';
import { DiscussPageAnswerForm } from './components/DiscussPageAnswerForm/DiscussPageAnswerForm';
import intlMessages from './intlMessages';
import { AnswersSection } from './components/AnswersSection/AnswersSection';
import { ErrorMessage } from './components/ErrorMessage/ErrorMessage';

import './_discuss-page.scss';

interface IMatchParams extends RouteComponentProps {
  match: any;
}

interface IProps extends IMatchParams {
  rootStore: IRootStore;
}

export const DiscussPage: FC<IProps> = inject('rootStore')(
  observer((props: IProps) => {
    const {
      languageStore,
      discussPageStore,
      userStore
    } = props.rootStore;

    const pageStore = discussPageStore.initializePage(props.match.params.challengeSlug);

    const {
      userAnswersStore,
      discussStore,
      entity,
      errorCode,
      fetchingPage,
      submitAnswer
    } = pageStore;
    
    const userAnonymityStatus: UserAnonymitySettingsEnum = userStore.userData.anonymity;
    const showAnonymitySettingsSwitch: boolean = userAnonymityStatus !== UserAnonymitySettingsEnum.ANONYMOUS && userAnonymityStatus !== UserAnonymitySettingsEnum.PUBLIC;
    const isAnonymityChecked: boolean = userAnonymityStatus === UserAnonymitySettingsEnum.CHOOSE_DEFAULT_ANONYMOUS;

    if (errorCode) {
      return <ErrorMessage errorCode={errorCode} />;
    }

    if (fetchingPage) {
      return <CircularProgress />;
    }
    
    return(
      <RawIntlProvider value={languageStore.intlMap.get((entity.language == LanguageEnum.EN_US || entity.language == LanguageEnum.DE_DE) ? entity.language as LanguageEnum : LanguageEnum.EN_US as LanguageEnum )!}>
        <div className={cx({
            'discuss-page': true,
            'discuss-page--with-answer': entity.answerCount > 0,
          })}
        >
          <DiscussPageHeader question={entity.content} />

          <h3 className="discuss-page__section-heading">
            <FormattedMessage {...intlMessages.instructionsHeading} />
          </h3>

          <DiscussPageInstructions content={entity.instructions} />

          <DiscussPageAnswerForm
            answersCount={discussStore.answers.length}
            currentUserAnswersCount={userAnswersStore.items.length}
            isAnonymityChecked={isAnonymityChecked}
            onAnswerSubmit={submitAnswer}
            onCloseSuccessMessage={userAnswersStore.closeSuccessMessage}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            userAnswersStore={userAnswersStore}
          />

          {userAnswersStore.items.length > 0 &&
            <>
              <h3 className="discuss-page__section-heading">
                <FormattedMessage {...intlMessages.answersHeading} />
              </h3>

              <AnswersSection
                discussPageLanguage={entity.language}
                showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
                store={userAnswersStore}
              />
            </>
          }

          <h3 className="discuss-page__section-heading">
            <FormattedMessage {...intlMessages.discussionHeading} />
          </h3>

          <div className="discuss-page__discuss">
            <Discuss
              isAnonymityChecked={isAnonymityChecked}
              showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
              discussStore={discussStore}
            />
          </div>
        </div>
      </RawIntlProvider>
    )
  })
)
