import {AxiosInstance} from 'axios';

import {JupyterLabParser, IJupyterLab} from '@u4i/parsers/JupyterLabParser';
import {JupyterLabSubmitParser, IJupyterLabSubmitResponse} from '@u4i/parsers/JupyterLabSubmitParser';

export class JupyterLabApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async closeSession(labId: string): Promise<IJupyterLabSubmitResponse> {
    const response = await this.apiClient.post(`/v2/jupyter-labs/${labId}/close`);

    return JupyterLabSubmitParser.parse(response.data);
  }

  async fetchStreamingUrl(labId: string): Promise<IJupyterLab> {
    const response = await this.apiClient.post(`/v2/jupyter-labs/${labId}/start`);

    return JupyterLabParser.parse(response.data);
  }

  async submitSession(labId: string, challengeAttemptId: string): Promise<IJupyterLabSubmitResponse> {
    const response = await this.apiClient.post(`/v2/jupyter-labs/${labId}/submit`, {
      challenge_attempt_id: challengeAttemptId,
    });

    return JupyterLabSubmitParser.parse(response.data);
  }
}
