import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import logoImage from "../../../../../../assets/images/wide-logo.png";
import intlMessages from "./intlMessages";
import "./_providers-element.scss";
import { PLAYER_MAX_PROVIDERS_DISPLAYED } from "@u4i/constantSettings";
import { PlayerContext } from "@u4i/modules/VirtualExperience/PlayerState/playerContext";
import { withCalculatedFontSize } from "@u4i/modules/VirtualExperience/components/withCalculatedFontSize";

const ProvidersElement = inject("rootStore")(
  observer(
    class ProvidersElement extends Component<any> {
      static propTypes = {
        calculatedFontSize: PropTypes.number.isRequired,
        providers: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          })
        ),
      };

      static contextType = PlayerContext;

      constructor(props) {
        super(props);
      }

      render() {
        const allowedProviders = this.context
          .allowedProviders
          .filter(
            (provider) =>
              provider.id !==
              this.props.rootStore.settingsStore.settings.player
                .tableOfContentsExcludedProviderId
          );
        const safelyLimitedProviders = allowedProviders.slice(
          0,
          PLAYER_MAX_PROVIDERS_DISPLAYED - 1
        );

        return (
          <div
            className="player__providers-element"
            style={{ fontSize: this.props.calculatedFontSize }}
          >
            <div className="player__providers-element__sidebar" />

            <div className="player__providers-element__content">
              <img
                alt="U4I"
                className="player__providers-element__main-provider"
                src={logoImage}
              />
              <h3 className="player__providers-element__heading">
                <FormattedMessage {...intlMessages.heading} />
              </h3>

              <ul className="list-unstyled player__providers-element__list">
                {safelyLimitedProviders.map((provider) => (
                  <li key={provider.id}>
                    <img
                      alt={provider.name}
                      className="player__providers-element__logo"
                      src={provider.url}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      }
    }
  )
);

export default withCalculatedFontSize(ProvidersElement);
