import AdminIcon from "@material-ui/icons/SettingsApplications";
import CallSplit from "@material-ui/icons/CallSplit";
import cx from "classnames";
import GradingIcon from "@material-ui/icons/School";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ProfileIcon from "@material-ui/icons/AccountBox";
import ProgressIcon from "@material-ui/icons/Storage";
import React, { FC } from "react";
import RedeemIcon from "@material-ui/icons/Redeem";
import SettingsIcon from "@material-ui/icons/Settings";
import { FormattedMessage } from "react-intl";

import Entry from "./Entry";
import VersionList from "@u4i/common/VersionList";

import intlMessages from "./intlMessages";
import "./_entries-list.scss";
import ThemeToggle from "@u4i/common/ThemeToggle";

interface IPropTypes {
  isAdmin: boolean;
  isAnon: boolean;
  isFaculty: boolean;
  isGraderAdmin: boolean;
  isGraderFaculty: boolean;
  isOpen: boolean;
  onGradingClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onLogoutClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onProgressClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onRedeemClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onAdminView: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  userId: string;
}

interface Entry {
  href?: string;
  icon: JSX.Element;
  id: string;
  label?: JSX.Element | any;
  type: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  render?: any;
}

const EntriesList: FC<IPropTypes> = (props: IPropTypes) => {
  const themeLabel = () => {
    return <FormattedMessage {...intlMessages.darkMode} />;
  };

  const entries: Array<Entry> = [
    {
      href: `/administration/u/${props.userId}`,
      icon: <ProfileIcon />,
      id: "profile",
      label: <FormattedMessage {...intlMessages.profile} />,
      type: "link",
    },
    {
      href: "/administration/user/account/edit",
      icon: <SettingsIcon />,
      id: "acccountSettings",
      label: <FormattedMessage {...intlMessages.accountSettings} />,
      type: "link",
    },
    {
      href: "/redeem",
      icon: <RedeemIcon />,
      id: "redeem",
      label: <FormattedMessage {...intlMessages.redeemCoupon} />,
      onClick: props.onRedeemClick,
      type: "button",
    },
    {
      icon: <SettingsIcon />,
      id: "toggle",
      label: themeLabel(),
      onClick: () => null,
      type: "button",
      render: ThemeToggle,
    },
  ];

  if (props.isFaculty) {
    entries.push({
      href: "/administration",
      icon: <AdminIcon />,
      id: "editors",
      label: <FormattedMessage {...intlMessages.editors} />,
      type: "link",
    });
    entries.push({
      href: "/administration3",
      icon: <AdminIcon />,
      id: "administration",
      label: <FormattedMessage {...intlMessages.administration} />,
      onClick: props.onAdminView,
      type: "link",
    });
  }

  if (props.isAdmin) {
    entries.push({
      href: "/administration",
      icon: <AdminIcon />,
      id: "administration2",
      label: <FormattedMessage {...intlMessages.administration2} />,
      type: "link",
    });

    entries.push({
      href: "/administration3",
      icon: <AdminIcon />,
      id: "administration",
      label: <FormattedMessage {...intlMessages.administration} />,
      onClick: props.onAdminView,
      type: "link",
    });

    entries.push({
      icon: <CallSplit />,
      id: "version-list-menu-item",
      label: <VersionList />,
      onClick: () => {},
      type: "button",
    });
  }

  if (props.isGraderAdmin || props.isGraderFaculty) {
    entries.push({
      icon: <GradingIcon />,
      id: "grading",
      label: <FormattedMessage {...intlMessages.evaluation} />,
      onClick: props.onGradingClick,
      type: "button",
    });
  }

  if (props.isGraderAdmin) {
    entries.push({
      icon: <ProgressIcon />,
      id: "progress",
      label: <FormattedMessage {...intlMessages.progress} />,
      onClick: props.onProgressClick,
      type: "button",
    });
  }

  entries.push({
    icon: <LogoutIcon />,
    id: "logout",
    label: <FormattedMessage {...intlMessages.logout} />,
    onClick: props.onLogoutClick,
    type: "button",
  });

  return (
    <ul
      className={cx({
        "main-layout__entries-list": true,
        "main-layout__entries-list--open": props.isOpen,
        "list-unstyled": true,
      })}
    >
      {entries.map((entry) => (
        <Entry
          href={entry.href}
          icon={entry.icon}
          key={entry.id}
          label={entry.label}
          onClick={entry.onClick}
          type={entry.type}
          render={entry.render}
        />
      ))}
    </ul>
  );
};

export default EntriesList;
