import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import LoginForm from '@u4i/common/forms/LoginForm/LoginForm';
import intlMessages from './intlMessages';
import './_access-block.scss';

interface IProps {
  isAuthorized: boolean;
  onForgotPasswordClick: () => void;
  onSubmitLoginForm: (data: any) => Promise<void>;
}

const AccessBlock: FC<IProps> = (props: IProps) => {
  const {
    isAuthorized,
    onForgotPasswordClick,
    onSubmitLoginForm
  } = props;

  if (isAuthorized) {
    return (
      <p className="landing-page__access-block__unauthorized">
        <FormattedMessage {...intlMessages.unauthorized} />
      </p>
    );
  }

  return (
    <section className="landing-page__access-block__form">
      <div className="landing-page__access-block__form-wrapper">
        <LoginForm handleSubmit={onSubmitLoginForm} />
      </div>

      <button
        className="landing-page__access-block__button"
        onClick={onForgotPasswordClick}
        type="button"
      >
        <FormattedMessage {...intlMessages.forgotPassword} />
      </button>
    </section>
  );
};

const WrappedComponent = observer(AccessBlock);
export {WrappedComponent as AccessBlock};
