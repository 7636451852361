import React, { FC } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import intlMessages from './intlMessages';
import CircularProgress from '@u4i/common/CircularProgress';
import { Form, Switch, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import './_discuss-comment-form.scss';

const { Item } = Form;

interface IProps extends WrappedComponentProps {
  isAnonymityChecked: boolean
  showAnonymitySettingsSwitch: boolean
  submitting?: boolean
  handleSubmit: (values) => any
}

const DiscussCommentForm: FC<IProps> = (props: IProps) => {
  const {
    intl,
    isAnonymityChecked,
    showAnonymitySettingsSwitch,
    submitting,
    handleSubmit
  } = props;

  const {formatMessage} = intl;
  const [form] = Form.useForm();

  const contentMinLengthError: string = formatMessage(intlMessages.contentErrorsMinLength, {limit: 20});
  const contentPresenceError: string = formatMessage(intlMessages.contentErrorsPresence);
  const contentLabel: string = formatMessage(intlMessages.contentLabel);

  if (submitting) {
    return <CircularProgress />;
  }

  return (
    <Form 
      form={form}
      onFinish={handleSubmit}
    >
      <div className="discuss-comment-form__content-field">
        <Item
          id="discuss-comment"
          name="content"
          rules={[
            {
              validator: async (rule, value) => {
                if(!value) {
                  return Promise.reject(contentPresenceError);
                }
                if (value.trim().length > 0 && value.trim().length < 20) {
                  return Promise.reject(contentMinLengthError);
                }
              }
            }
          ]}
        >
          <TextArea placeholder={contentLabel} rows={4}/>
        </Item>
      </div>

      <div className="discuss-comment-form__field-wrapper">
        {showAnonymitySettingsSwitch &&
          <div className="discuss-comment-form__anonymity-field">
            <div style={{marginTop: 5}}>
              <Typography.Text style={{textTransform: 'uppercase'}}> 
                <FormattedMessage {...intlMessages.anonymousPosting} />
              </Typography.Text>
            </div>

            <Item
              id="discuss-comment-anonymity"
              name="anonymity"
              initialValue={isAnonymityChecked}
              style={{marginBottom: 0}}
            >
              <Switch 
                defaultChecked={isAnonymityChecked} 
              />
            </Item>
          </div>
        }

        <div className="discuss-comment-form__submit-field">
          <SubmitButton centered={false} disabled={submitting} inline>
            <FormattedMessage {...intlMessages.submitTitle} />
          </SubmitButton>
        </div>
      </div>
    </Form>
  )
}

export default injectIntl(DiscussCommentForm);
