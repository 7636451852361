import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';

interface IPropTypes {
  currentlySorted: boolean
  currentSortDirection: 'ASC' | 'DESC'
}

export const SortingIcon: FC<IPropTypes> = (props: IPropTypes) => {
  if (props.currentlySorted) {
    if (props.currentSortDirection === 'ASC') {
      return <FontAwesome name="sort-up" />;
    }

    return <FontAwesome name="sort-down" />;
  }

  return <FontAwesome name="sort" />;
}
