import Shepherd from 'shepherd.js';
import {IntlShape} from 'react-intl';

import {IBasicShepherdButtons} from '../../TourCreator';

import intlMessages from './intlMessages';

export function configureSkillDetailTour(tour: Shepherd.Tour, basicButtons: IBasicShepherdButtons, intl: IntlShape) {
  tour.addStep({
    buttons: [basicButtons.SKIP, basicButtons.NEXT],
    text: intl.formatMessage(intlMessages.step1),
    title: intl.formatMessage(intlMessages.title),
  });

  tour.addStep({
    attachTo: {
      element: '.course-detail-page__buy-button',
      on: 'top'
    },
    buttons: [basicButtons.BACK, basicButtons.SKIP, basicButtons.NEXT],
    text: intl.formatMessage(intlMessages.step2),
    title: intl.formatMessage(intlMessages.title),
  });

  tour.addStep({
    attachTo: {
      element: '.course-detail-page__back-button__action',
      on: 'bottom'
    },
    buttons: [basicButtons.BACK, basicButtons.SKIP, basicButtons.NEXT],
    text: intl.formatMessage(intlMessages.step3),
    title: intl.formatMessage(intlMessages.title),
  });

  tour.addStep({
    attachTo: {
      element: '.course-detail-page__chapters-section__content',
      on: 'top'
    },
    buttons: [basicButtons.BACK, basicButtons.FINISH],
    text: intl.formatMessage(intlMessages.step4),
    // TO APPEND TIPPY SOMEWHERE ELSE OR PREVENT FLIPPING WITH SCROLL
    // tippyOptions: {
    //   flip: false,
    //   appendTo: () => document.querySelector('.course-detail-page__header'),
    // },
    title: intl.formatMessage(intlMessages.title),
  });
};
