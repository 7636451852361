import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import Select from 'react-select';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_toolbar.scss';

interface IPropTypes {
  currentItemsPerPage: number;
  onChangeItemsPerPage: (newItemsPerPage: number) => void;
  onToggleFilters: () => void;
  reloadingInProgress: boolean;
  totalItems: number;
}

export class Toolbar extends React.Component<IPropTypes> {
  handleItemsPerPageChange = (entry: {label: string; value: string} | null) => {
    if (this.props.reloadingInProgress) {
      return;
    }

    this.props.onChangeItemsPerPage(entry ? parseInt(entry.value, 10) : 10);
  };

  render() {
    return (
      <div className="data-table__toolbar">
        <div
          className={cx({
            'data-table__toolbar__reloading-badge': true,
            'data-table__toolbar__reloading-badge--busy': this.props.reloadingInProgress,
          })}
        />
    
        <FormattedMessage {...intlMessages.totalItems} values={{count: this.props.totalItems}} />
    
        <div className="data-table__toolbar__items-per-page">
          <FormattedMessage {...intlMessages.itemsPerPage} />

          <Select
            className="data-table__toolbar__items-per-page-field"
            clearable={false}
            inputProps={{readOnly:true}}
            onChange={this.handleItemsPerPageChange}
            options={[{label: '5', value: 5}, {label: '10', value: 10}, {label: '15', value: 15}, {label: '20', value: 20}]}
            placeholder=""
            value={this.props.currentItemsPerPage}
          />
        </div>
    
        <button
          className="data-table__toolbar__toggle-filters"
          onClick={this.props.onToggleFilters}
          type="button"
        >
          <div className="data-table__toolbar__toggle-filters-icon">
            <FontAwesome name="filter" />
          </div>
    
          <div className="data-table__toolbar__toggle-filters-content">
            <FormattedMessage {...intlMessages.toggleFilters} />
          </div>
        </button>
      </div>
    );
  }
}
