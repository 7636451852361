export interface ITestEntity {
  id: string
  title: string
  chapters: IChapterSimplified[]
  created_at: string
  updated_at: string
  description: string
  questions?: IQuestionSimplified[] | any[]
  related_categories?: ICategory[],
  number_of_questions: number
  tag?: string
  passmark: number
  are_attempts_limited: boolean
  randomize_question_order: boolean
  numberOfQuestions?: number
}

export interface ITestPost {
  title: string
  description: string
  are_attempts_limited: boolean
  questions?: string[]
  questionsList?: IQuestionSimplified[]   // FE only
  related_categories?: ICategory[],
  tag?: string
  passmark?: number
  randomize_question_order: boolean
  numberOfQuestions?: number
}

export interface IAssignedTest {
  detailsUrl: string
  title: string
}

export interface IChapterSimplified {
  id: string
  internal_id: string
  long_title: string
  title: string
}

export interface IQuestionEntity {
  answers: string | IAnswer[]
  created_at?: string
  correct_feedback: string
  incorrect_feedback: string
  id: string
  randomize_answers_order: boolean
  internal_id: string
  content: string
  points: number
  related_topics: IQuestionTopic[]
  selected_topics: string[]   // only used on FE
  related_categories: ICategory[],
  selected_categories: string[]   // only used on FE
  type: QuestionTypeEnum
  updated_at?: string
}

export interface IQuestionSimplified {
  id: string
  internal_id: string
  content: string
  type: QuestionTypeEnum
  related_categories: ICategory[]
  related_topics: IQuestionTopic[]
  created_at: string
  updated_at: string
}

export interface IQuestionPost {
  answers: IAnswer[] | any
  internal_id: string
  correct_feedback: string
  incorrect_feedback: string
  content: string
  type: QuestionTypeEnum
  points: number
  randomize_answers_order: boolean
  related_topics: string[]
  related_categories: string[]
  clues?: any
  correct_matched_pairs?: any
}

export interface IQuestionPut {
  answers: IAnswer[]
  internal_id: string
  correct_feedback: string
  incorrect_feedback: string
  content: string
  type: QuestionTypeEnum
  points: number
  randomize_answers_order: boolean
  selected_topics: string[]
  selected_categories: string[]
}

export interface IAnswer {
  id: string
  content: string
  is_correct: boolean
}

export interface IAnswerData {
  data: IAnswer[]
}

export interface IQuestionTopic {
  id: string
  internal_id: string
  long_title: string
  title: string
}

export interface ICategory {
  id: string
  name: string
  slug: string
  created_at: string
}

export interface ICategoryPost {
  name: string
}

export enum QuestionTypeEnum {
  SingleCorrectAnswer = 'single',
  MultipleCorrectAnswer = 'multiple',
  MatchingQuestion = 'matching'
}

export const questionStatuses = [
  {
    value: "single",
    text: "Single"
  },
  {
    value: "multiple",
    text: "Multiple"
  },
  {
    value: "matching",
    text: "Matching"
  }
];