import { AdminCustomerIntegrationStore } from '@u4i/modules/Admin/modules/CustomerIntegration/state/AdminCustomerIntegrationStore';
import {AdminDefaultNotificationsStore} from '@u4i/modules/Admin/modules/DefaultNotifications/state/AdminDefaultNotificationsStore';
import {AdminProvidersStore} from '@u4i/modules/Admin/modules/Providers/state/AdminProvidersStore';
import {AdminUserChallengeAttemptsStore} from '@u4i/modules/Admin/modules/UserChallengeAttempts/state/AdminUserChallengeAttemptsStore';
import {AdminUserDataStore} from '@u4i/modules/Admin/modules/Users/state/AdminUserDataStore';
import {AdminUserGroupsStore} from '@u4i/modules/Admin/modules/UserGroups/state/AdminUserGroupsStore';
import {AdminUserProfilesStore} from '@u4i/modules/Admin/modules/UserProfiles/state/AdminUserProfilesStore';
import {AdminUserCoursesStore} from '@u4i/modules/Admin/modules/UserCourses/state/AdminUserCoursesStore';
import {AdminUserImportStore} from '@u4i/modules/Admin/modules/Users/state/AdminUserImportStore';
import {AdminUserExportStore} from '@u4i/modules/Admin/modules/Users/state/AdminUserExportStore';
import {SegregationOfDutiesStore} from '@u4i/modules/Admin/modules/Users/state/SegregationOfDutiesStore';
import {AdminDigitalLabSchedulingStore} from '@u4i/modules/Admin/modules/Challenges/components/DigitalLabScheduling/state/AdminDigitalLabSchedulingStore';
import {AdminUsersStore} from '@u4i/modules/Admin/modules/Users/state/AdminUsersStore';
import {ILogger} from '@u4i/state/ServicesInterfaces';
import {IApi} from './ServicesInterfaces';
import {IRootStore} from './RootStore';
import { AdminProgramsStore } from '@u4i/modules/Admin/modules/Programs/state/AdminProgramsStore';
import { AdminCornerstoneCourseAssignmentStore } from '@u4i/modules/Admin/modules/CornerstoneCourseAssignments/state/CornerstoneCourseAssingmentStore';
import { AdminAssessmentHubStore } from '@u4i/modules/Admin/modules/AssesmentHub/state/AdminAssessmentHubStore';
import { AdminChatbotFileManagerStore } from "@u4i/modules/Admin/modules/ChatbotFileManager/state/AdminChatbotFileManagerStore";

export interface IAdminStore {
  adminAssessmentHubStore: AdminAssessmentHubStore;
  adminCornerstoneCourseAssignmentStore: AdminCornerstoneCourseAssignmentStore;
  adminCustomerIntegrationStore: AdminCustomerIntegrationStore;
  adminDefaultNotificationsStore: AdminDefaultNotificationsStore;
  adminProgramsStore: AdminProgramsStore;
  adminProvidersStore: AdminProvidersStore;
  adminUserChallengeAttemptsStore: AdminUserChallengeAttemptsStore;
  adminUserGroupsStore: AdminUserGroupsStore;
  adminUserProfilesStore: AdminUserProfilesStore;
  adminUserDataStore: AdminUserDataStore;
  adminUserCoursesStore: AdminUserCoursesStore;
  adminUserImportStore: AdminUserImportStore;
  segregationOfDutiesStore: SegregationOfDutiesStore;
  adminDigitalLabSchedulingStore: AdminDigitalLabSchedulingStore;
  adminUsersStore: AdminUsersStore;
  apiService: IApi;
  logError: (error: string) => void;
  adminChatbotFileManagerStore: AdminChatbotFileManagerStore;
}

export class AdminStore implements IAdminStore {
  private logger: ILogger;
  apiService: IApi;
  adminAssessmentHubStore: AdminAssessmentHubStore;
  adminCornerstoneCourseAssignmentStore: AdminCornerstoneCourseAssignmentStore;
  adminCustomerIntegrationStore: AdminCustomerIntegrationStore;
  adminDefaultNotificationsStore: AdminDefaultNotificationsStore;
  adminDigitalLabSchedulingStore: AdminDigitalLabSchedulingStore;
  adminProgramsStore: AdminProgramsStore;
  adminProvidersStore: AdminProvidersStore;
  adminUserChallengeAttemptsStore: AdminUserChallengeAttemptsStore;
  adminUserDataStore: AdminUserDataStore;
  adminUserGroupsStore: AdminUserGroupsStore;
  adminUserProfilesStore: AdminUserProfilesStore;
  adminUserCoursesStore: AdminUserCoursesStore;
  adminUsersStore: AdminUsersStore;
  adminUserImportStore: AdminUserImportStore;
  adminUserExportStore : AdminUserExportStore;
  segregationOfDutiesStore: SegregationOfDutiesStore;
  adminChatbotFileManagerStore: AdminChatbotFileManagerStore;

  constructor(rootStore: IRootStore) {
    const {loggerFactory} = rootStore;

    this.adminAssessmentHubStore = new AdminAssessmentHubStore(rootStore);
    this.adminCornerstoneCourseAssignmentStore = new AdminCornerstoneCourseAssignmentStore(rootStore);
    this.adminCustomerIntegrationStore = new AdminCustomerIntegrationStore(rootStore);
    this.adminDefaultNotificationsStore = new AdminDefaultNotificationsStore(rootStore);
    this.adminProgramsStore = new AdminProgramsStore(rootStore);
    this.adminProvidersStore = new AdminProvidersStore(rootStore);
    this.adminUserChallengeAttemptsStore = new AdminUserChallengeAttemptsStore(rootStore);
    this.adminUserDataStore = new AdminUserDataStore(rootStore);
    this.adminUserGroupsStore = new AdminUserGroupsStore(rootStore);
    this.adminUserProfilesStore = new AdminUserProfilesStore(rootStore);
    this.adminUserCoursesStore = new AdminUserCoursesStore(rootStore);
    this.adminUserImportStore = new AdminUserImportStore(rootStore);
    this.adminDigitalLabSchedulingStore = new AdminDigitalLabSchedulingStore(rootStore);
    this.adminUsersStore = new AdminUsersStore(rootStore);
    this.segregationOfDutiesStore = new SegregationOfDutiesStore(rootStore);
    this.adminChatbotFileManagerStore = new AdminChatbotFileManagerStore(rootStore);
    this.logger = loggerFactory.createLogger('Admin Store');
  }

  logError = (error: string) => {
    this.logger.info(error);
  };
}
