import cx from 'classnames';
import is from 'is_js';
import React, {FC, useEffect} from 'react';
import Rellax from 'rellax';
import { IFeedbackSurvey } from '../../interfaces/CourseInterface';
import { CertificationStatusBox } from './components/CertificationStatusBox/CertificationStatusBox';
import { CourseLabels } from './components/CourseLabels/CourseLabels';
import './_header.scss';

interface IProps {
  backgroundUrl: string
  chapterCount: number
  duration: number
  feedbackSubmitted: boolean
  feedbackSurvey?: IFeedbackSurvey
  isBought: boolean
  isCompleted: boolean
  isFeedbackMandatory: boolean
  numberOfChallenges: number
  numberOfCompletedChallenges: number
  showCertificate: boolean
  title: string
  totalNumberOfChaptersAndOptionalChallenges?: number
  viewedChapterCount: number
  onFeedbackSurveyStart: () => void
}

export const Header: FC<IProps> = (props: IProps) => {
  const {
    backgroundUrl,
    chapterCount,
    duration,
    feedbackSubmitted,
    feedbackSurvey,
    isBought,
    isCompleted,
    isFeedbackMandatory,
    numberOfChallenges,
    numberOfCompletedChallenges,
    showCertificate,
    title,
    totalNumberOfChaptersAndOptionalChallenges,
    viewedChapterCount,
    onFeedbackSurveyStart
  } = props;

  const headerBackgroundRef: React.RefObject<any> = React.createRef();
  const headingRef: React.RefObject<any>  = React.createRef();
  const isDesktop: any = is.desktop();
  let rellaxHeaderBackground: any = null;
  let rellaxHeading : any = null;

  useEffect(() => {
    if (isDesktop) {
      rellaxHeaderBackground = new Rellax(headerBackgroundRef.current, {
        speed: 6,
      });

      rellaxHeading = new Rellax(headingRef.current, {
        speed: -4,
      });
    }
  }, []);
  
  // Component unmount
  useEffect(() => {
    const destroyParallax = () => {
      if(isDesktop) {
        if (rellaxHeaderBackground) {
          rellaxHeaderBackground.destroy();
        }

        if (rellaxHeading) {
          rellaxHeading.destroy();
        }
      }
      return () => destroyParallax();
    };
  }, []);

  return (
    <div className="course-detail-page__header">
      <div className="course-detail-page__header__wrapper">
        <div
          className={cx({
            'course-detail-page__header__background': true,
            'course-detail-page__header__background--fixed': isDesktop,
          })}
          ref={headerBackgroundRef}
          style={{backgroundImage: `url(${backgroundUrl})`}}
        />

        <div className="container">
          <div
            className="course-detail-page__header__content"
            ref={headingRef}
          >
            <h2 className="course-detail-page__header__title">
              {title}
            </h2>

            <CourseLabels chapterCount={chapterCount} duration={duration} />
          </div>
        </div>
      </div>

      {isBought &&
        <CertificationStatusBox
          chapterCount={chapterCount}
          feedbackSubmitted={feedbackSubmitted}
          feedbackSurvey={feedbackSurvey!}
          isFeedbackMandatory={isFeedbackMandatory}
          onFeedbackSurveyStart={onFeedbackSurveyStart}
          numberOfChallenges={numberOfChallenges}
          numberOfCompletedChallenges={numberOfCompletedChallenges}
          showCertificate={showCertificate}
          courseCompleted={isCompleted}
          totalNumberOfChaptersAndOptionalChallenges={totalNumberOfChaptersAndOptionalChallenges!}
          viewedChapterCount={viewedChapterCount}
        />
      }
    </div>  
  )
}
