import Scroll from 'react-scroll';
import Shepherd from 'shepherd.js';
import {IntlShape} from 'react-intl';
import intlMessages from './intlMessages';

const DEFAULT_TOUR_OPTIONS: Shepherd.Tour.TourOptions = {
  confirmCancel: false,
  defaultStepOptions: {
    classes: 'shepherd-u4i-theme',
    highlightClass: 'shepherd-u4i-highlight',
    scrollTo: true,
    scrollToHandler: function defaultTourScrollHandler(element?: HTMLElement) {
      if (element) {
        const initialName = element.getAttribute('name');

        element.setAttribute('name', 'onboardingScrollTarget');
        Scroll.scroller.scrollTo('onboardingScrollTarget', {duration: 600, offset: -400, smooth: true});

        if (initialName) {
          element.setAttribute('name', initialName);
        } else {
          element.removeAttribute('name');
        }
      }
    },
  },
  useModalOverlay: true,
};

export interface IBasicShepherdButtons {
  BACK: Shepherd.Step.StepOptionsButton;
  FINISH: Shepherd.Step.StepOptionsButton;
  NEXT: Shepherd.Step.StepOptionsButton;
  SKIP: Shepherd.Step.StepOptionsButton;
}

function generateBasicButtons(tour: Shepherd.Tour, intl: IntlShape) {
  return {
    BACK: { action: tour.back, classes: 'shepherd-button--back', text: intl.formatMessage(intlMessages.back) },
    FINISH: { action: tour.complete, text: intl.formatMessage(intlMessages.finish) },
    NEXT: { action: tour.next, text: intl.formatMessage(intlMessages.next) },
    SKIP: { action: tour.complete, classes: 'shepherd-button--skip', text: intl.formatMessage(intlMessages.skip) },
  };
}

export class TourCreator {
  private intl: IntlShape;

  createTour(
    configureTour: (tour: Shepherd.Tour, basicButtons: IBasicShepherdButtons, intl: IntlShape) => void,
    onComplete: () => void,
    tourOptions?: Shepherd.Tour.TourOptions,
  ) {
    if (!this.intl) {
      throw new Error('can not create tour without intl');
    }

    const tour = new Shepherd.Tour(Object.assign({}, DEFAULT_TOUR_OPTIONS, tourOptions || {}));

    configureTour(tour, generateBasicButtons(tour, this.intl), this.intl);

    tour.on('complete', () => {
      onComplete();
    });

    this.handleWindowResize(tour);

    return tour;
  }

  private handleWindowResize(tour: Shepherd.Tour) {
    window.addEventListener('resize', () => {
      if (tour.isActive()) {
        const currentStep = tour.getCurrentStep();

        if (currentStep) {
          currentStep.hide();
          currentStep.show();
        }
      }
    });
  }

  setIntl(intl: IntlShape) {
    this.intl = intl;
  }
}
