import cx from 'classnames';
import React from 'react';
import {action, observable,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import closeIcon from '@u4i/assets/svg/closeIcon.svg';

import intlMessages from './intlMessages';
import './_close-lab-button.scss';

interface IPropTypes {
  onCloseLabClick: () => void
}

export const CloseLabButton = observer(class CloseLabButton extends React.Component<IPropTypes> {
  private showInformationWindow = false;

  handleClick = () => {
    this.props.onCloseLabClick();
  };

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<CloseLabButton, "showInformationWindow">(this, {
      showInformationWindow: observable,
      handleMouseEnter: action.bound,
      handleMouseLeave: action.bound
    });
  }

  handleMouseEnter() {
    this.showInformationWindow = true;
  }

  handleMouseLeave() {
    this.showInformationWindow = false;
  }

  render() {
    return (
      <div className="jupyter-lab__close-lab-button">
        <button
          className="jupyter-lab__close-lab-button__button"
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          type="button"
        >
          <img alt="close lab" src={closeIcon} />
        </button>
    
        <div
          className={cx({
            'jupyter-lab__close-lab-button__information': true,
            'jupyter-lab__close-lab-button__information--visible':
              this.showInformationWindow,
          })}
        >
          <p className="jupyter-lab__close-lab-button__information-label">
            <FormattedMessage {...intlMessages.informationMessage} />
          </p>
        </div>
      </div>
    );
  }
});
