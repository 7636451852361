import { AxiosInstance } from "axios";
import qs from "qs";
import {
  UserImportApiData,
  ImportHistoryData,
} from "@u4i/modules/Admin/modules/Users/components/UserImport/Interface";

import { downloadAutomatically } from "@u4i/common/utils";
import { AdminUsersHistoryParser } from "@u4i/parsers/admin/AdminUserImportParser";

export class AdminUserImportApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async downloadTemplate(): Promise<boolean> {
    const response = await this.apiClient.get(`/admin/users/imports/template`, {
      responseType: "arraybuffer",
    });
    downloadAutomatically(
      response.data,
      "User Import Template.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    return true;
  }

  async importUsers(data: UserImportApiData): Promise<string> {
    const response = await this.apiClient.post(
      `/admin/users/imports/json`,
      data
    );
    return response.data;
  }

  async getAdmins(): Promise<any> {
    const response = await this.apiClient.get(`/admin/users/admins`);
    return response.data;
  }

  async importUsersFromFile(data: FormData): Promise<boolean> {
    const response = await this.apiClient.post(
      `/admin/users/imports/xlsx`,
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  async getImportHistory(config: {
    limit: number;
    offset: number;
    filters: {
      [columnName in keyof ImportHistoryData]?: ImportHistoryData[columnName];
    };
    orderBy: {
      [columnName in keyof ImportHistoryData]?: ImportHistoryData[columnName];
    };
  }): Promise<any> {
    const response = await this.apiClient.get(`/admin/users/imports/requests`, {
      params: {
        limit: config.limit,
        offset: config.offset,
        filters: config.filters,
        orderBy: config.orderBy,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
    const totalItems = parseInt(response.headers["x-total-count"], 10);
    return {
      response: response.data,
      totalItems,
    };
  }

  async bulkApproval(id: string, status: string): Promise<boolean> {
    const response = await this.apiClient.put(
      `/admin/users/imports/requests/${id}`,
      { status }
    );
    return response.data;
  }

  async viewUsersForBulkApproval(id: string, fileExtension: string): Promise<any> {
    if(fileExtension == 'json'){
      const response = await this.apiClient.get(`/admin/users/imports/${fileExtension}/${id}`);
      return response.data;
    }
    const response = await this.apiClient.get(`/admin/users/imports/${fileExtension}/${id}`, {
      responseType: "arraybuffer",
    });
    let fileContentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if(fileExtension == 'csv'){
      fileContentType = "text/csv;charset=utf-8;";
    }
    downloadAutomatically(
      response.data,
      "Imported_Users_View",
      fileContentType
    );
    return response.data;
  }

  async getOldUserGroupByEmail(emails: string[]): Promise<any[]> {
    let body = { emails: emails };
    const response = await this.apiClient.post(`/admin/users/email-groups`, body);
    return response.data;
  }
}
