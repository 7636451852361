import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "@reach/router";

import { AttemptStatusLabel } from "@u4i/common/AttemptStatusLabel";
import { ChallengeAttemptStatusEnum } from "@u4i/common/enums/ChallengeAttemptStatusEnum";
import {
  DataTable,
  DataTableStore,
  DateRangeFilter,
  SelectFilter,
  TextFilter,
} from "@u4i/common/DataTable";
import { IRootStore } from "@u4i/state/RootStore";
import intlMessages from "./intlMessages";

interface IMatchParams extends RouteComponentProps {
  match: any;
}

interface IPropTypes extends IMatchParams {
  rootStore: IRootStore;
}

interface RowDataShape {
  challengeName: string;
  date: string;
  id: string;
  status: ChallengeAttemptStatusEnum;
}

export const ProgressAttemptsList = withRouter(inject("rootStore")(
  class ProgressAttemptsList extends Component<IPropTypes | any> {
    private dataTableStore: DataTableStore<RowDataShape>;

    constructor(props: IPropTypes) {
      super(props);

      this.dataTableStore = new DataTableStore<RowDataShape>(
        {
          dataColumns: {
            challengeName: {
              label: <FormattedMessage {...intlMessages.challengeName} />,
              order: 1,
              width: 350,
            },
            date: {
              label: <FormattedMessage {...intlMessages.date} />,
              order: 2,
              width: 200,
            },
            status: {
              centerContent: true,
              contentFormatter: (status) => (
                <AttemptStatusLabel status={status} />
              ),
              label: <FormattedMessage {...intlMessages.status} />,
              order: 3,
              width: 120,
            },
          },
          dataProvider: this.tableDataProvider,
          filters: [
            new TextFilter("challengeName", "challengeName"),
            new DateRangeFilter("date", "dateFrom", "dateTo"),
            new SelectFilter("status", "status", [
              {
                label: <FormattedMessage {...intlMessages.submitted} />,
                value: ChallengeAttemptStatusEnum.SUBMITTED,
              },
              {
                label: <FormattedMessage {...intlMessages.passed} />,
                value: ChallengeAttemptStatusEnum.PASSED,
              },
              {
                label: <FormattedMessage {...intlMessages.failed} />,
                value: ChallengeAttemptStatusEnum.FAILED,
              },
            ]),
          ],
          pagination: {
            enabled: true,
            itemsPerPage: 10,
          },
          tableId: "progress-attempts-list",
        },
        props.rootStore
      );
    }

    tableDataProvider = async (
      sortedColumnId: string | undefined,
      sortDirection: "ASC" | "DESC",
      filters: {
        [columnName in keyof RowDataShape]?: RowDataShape[columnName];
      },
      paginationInfo: { limit: number; offset: number } | undefined
    ): Promise<{ items: Array<RowDataShape>; totalItems: number }> => {
      const { userId } = this.props.match.params;
      const data =
        await this.props.rootStore.apiService.grading.fetchUserAttemptsList(
          userId,
          {
            filters,
            limit: paginationInfo ? paginationInfo.limit : 10,
            offset: paginationInfo ? paginationInfo.offset : 0,
            sortDirection,
            sortedColumnId,
          }
        );

      return {
        items: data.items,
        totalItems: data.totalItems,
      };
    };

    render() {
      return (
        <div className="container">
          <DataTable store={this.dataTableStore} />
        </div>
      );
    }
  }
));
