import React, { createContext } from "react";

export const ThemeContext = createContext({
});

const ThemeProvider = ({ children }) => {

    const [theme, setTheme] = React.useState(() => {
        const localTheme = localStorage.getItem('user-theme');
        if (localTheme) {
            if (localTheme === 'light' || localTheme === 'dark') {
                return localTheme;
            }
            return 'light'
        }
        return 'light'
    });

    React.useEffect(() => {
        if (theme === 'dark')
            document.querySelector('html')?.classList.remove(`theme-light`);
        if (theme === 'light')
            document.querySelector('html')?.classList.remove(`theme-dark`);

        document.querySelector('html')?.classList.add(`theme-${theme}`);
        updateLocalStorageTheme();
    }, [theme])

    const handleThemeSwitch = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    }

    const updateLocalStorageTheme = () => {
        localStorage.setItem('user-theme', theme);
    }

    return (
        <ThemeContext.Provider value={{
            theme,
            handleThemeSwitch
        }}>
            {children}
        </ThemeContext.Provider >
    )
}

export default ThemeProvider;