import {AxiosInstance} from 'axios';

import NotificationParser, {INotification} from '@u4i/parsers/NotificationParser';
import {IPaginatedItems} from '../../ServicesInterfaces';

export class NotificationsApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchNotifications(limit: number, offset: number): Promise<IPaginatedItems<INotification>> {
    const response = await this.apiClient.get('notification', {params: {limit, offset}});

    const notifications = NotificationParser.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items: notifications, totalItems};
  }

  async fetchUnreadCount(): Promise<number> {
    const response = await this.apiClient.get('notification/badge-count');

    const unreadCount = parseInt(response.data, 10);

    return unreadCount;
  }

  async markAllAsSeen(): Promise<void> {
    await this.apiClient.post('notification/mark-as-seen');
  }

  async markNotificationAsSeen(id:string): Promise<void> {
    await this.apiClient.post(`notification/mark-as-seen/${id}`);
  }
}
