import { mainBlue, white } from '@u4i/styles/styles-variables';
import React, { FC, useState } from 'react';
import { opacity8, opacity9 } from '../../constants/Constants';

interface IProps {
  x: number
  y: number
  onNextPhaseClick: () => void
}

const defaultProps: Partial<IProps> = {
  x: 200,
  y: 400
};

export const RightArrow: FC<IProps> = (props: IProps) => {
  const {
    x,
    y,
    onNextPhaseClick
  } = props;

  const [opacity, setOpacity] = useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  return (
    <svg>
    	<g
        onClick={onNextPhaseClick}
        onMouseEnter={handleOpacity}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          ...(opacity && { opacity: opacity8 })
        }}
      >
    		<path
          d={`m ${x+31},${y+10} c 4.4611,4.7370 8.922301,9.474197 13.383452,14.211295 -4.691106,5.059037 -9.382213,10.118074 -14.073319,15.177111 -0.0984,2.452563 0.610214,5.665165 3.385828,6.302426 1.466983,0.161498 3.581549,0.672029 4.634076,-0.640498 6.193577,-6.725035 12.439328,-13.406585 18.59994,-20.159088 1.228649,-1.38894 -0.146384,-2.865288 -1.258105,-3.740803 -6.016377,-6.016377 -12.032753,-12.032753 -18.04913,-18.04913 -2.333663,-0.274386 -5.490757,-0.174783 -6.577925,2.322204 -0.593342,1.45269 -0.377461,3.089858 -0.04482,4.576483 z`}
          style={{
            fill: mainBlue,
            opacity: opacity9
          }}
        />
    		<rect
          height="50"
          style={{
            fill: white,
            fillOpacity: 0
          }}
          width="33"
          x={x+28}
          y={y}
        >
          <title>Next Phase</title>
        </rect>
    	</g>
    </svg>
  )
}

RightArrow.defaultProps = defaultProps;
