import {defineMessages} from 'react-intl';

export default defineMessages({
  step1: {
    defaultMessage: 'Open this menu to see an overview of the content and directly access specific content.',
    id: 'OnboardingStore.Tours.PlayerTour.Step1',
  },
  step2: {
    defaultMessage: 'Click here to view further (or previous) learning content.',
    id: 'OnboardingStore.Tours.PlayerTour.Step2',
  },
  step3: {
    defaultMessage: 'Here you can close the course and return to the course overview.',
    id: 'OnboardingStore.Tours.PlayerTour.Step3',
  },
});
