import {defineMessages} from 'react-intl';

export default defineMessages({
  buttonText: {
    defaultMessage: 'Go to next Chapter',
    id: 'Player.ElementsDisplay.NextChapterElement.ButtonText',
  },
  warningText:{
    defaultMessage:'In order to view the next chapter you have to submit all the challenges!',
    id: 'Player.ElementsDisplay.NextChapterElement.WarningText',
  },
  name: {
    defaultMessage: 'Feedback Survey',
    id: 'Player.FeedbackButton.Name',
  }
});
