import Parser from '@u4i/utils/Parser';

export interface IAdminSkill {
  id: string;
  name: string;
}

export const AdminAvailableSkillParser = new Parser<IAdminSkill>(skill => ({
  id: skill.id,
  name: skill.title,
}));
