import Parser from '@u4i/utils/Parser';

import {ChallengeAttemptStatusEnum} from '@u4i/common/enums/ChallengeAttemptStatusEnum';

export interface IUserAttemptProgress {
  challengeName: string;
  date: string;
  id: string;
  status: ChallengeAttemptStatusEnum;
}

export const UserAttemptProgressParser = new Parser<IUserAttemptProgress>(userAttemptProgress => ({
  challengeName: userAttemptProgress.challengeName,
  date: userAttemptProgress.date,
  id: userAttemptProgress.id,
  status: userAttemptProgress.status,
}));
