import qs from 'qs';

class BackController {
  DATA_KEY = 'SKILL_DETAIL_BACK_DATA';

  urlPartialsBlacklist = [
    '/checkout',
    '/external-content',
    '/player',
    '/skills/',
  ];

  public history: any;
  public lastLocation: any;

  checkUrl(url) {
    let flag: boolean = true;

    this.urlPartialsBlacklist.forEach((entry) => {
      if (url.includes(entry)) {
        flag = false;
      }
    });

    return flag;
  }

  getData() {
    const data: string | null = sessionStorage.getItem(this.DATA_KEY);
    return JSON.parse(data!) || {};
  }

  getTargetFor(pageSlug) {
    const currentData = this.getData();

    return currentData[pageSlug] || null;
  }

  hook(history) {
    this.history = history;

    this.pullLocation();
    history.listen(() => {
      this.updateData();
      this.pullLocation();
    });
  }

  pullLocation() {
    const currentLocation = this.history.location;

    this.lastLocation = `${currentLocation.pathname}?${qs.stringify(currentLocation.query)}`;
  }

  saveData(data) {
    const preparedData: string = JSON.stringify(data);
    sessionStorage.setItem(this.DATA_KEY, preparedData);
  }

  updateData() {
    const currentData = this.getData();
    const isLastValidBack = this.checkUrl(this.lastLocation);

    const newLocation = this.history.location.pathname;
    const split = newLocation.split('/');
    const isCourseDetailPage: boolean = split[1] === 'skills';

    if (isCourseDetailPage && isLastValidBack) {
      const pageSlug = split[2];

      currentData[pageSlug] = this.lastLocation;
    }

    this.saveData(currentData);
  }
}

const singleton = new BackController();

export default singleton;
