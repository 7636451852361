import React, { FC } from 'react';
import {RouteComponentProps, withRouter} from 'react-router';

import './_admin-old.scss';

interface IPropTypes extends RouteComponentProps {}

const AdminOld: FC<IPropTypes> = (props: IPropTypes) => {
  const {pathname} = props.location;
  const iframeSrc = pathname ? pathname.replace('/administration', '') : undefined;

  return <iframe className="admin" src={iframeSrc} />;
};

const WrappedComponent = withRouter(AdminOld);

export {WrappedComponent as AdminOld};
