import {defineMessages} from 'react-intl';

export default defineMessages({
  next: {
    defaultMessage: 'Next',
    id: 'Player.Element.MCQTestSummary.Next'
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'Player.Element.MCQTestSummary.Previous'
  },
  questionHeader: {
    defaultMessage: 'Question {current} of {total}',
    id: 'Player.Element.MCQTestSummary.QuestionHeader'
  },
  summary: {
    defaultMessage: 'Summary',
    id: 'Player.Element.MCQTestSummary.Summary'
  }
});
