import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {Alert} from '@u4i/common/Alert';
import {AlertStatusEnum} from '@u4i/common/enums/AlertStatusEnum';

import intlMessages from './intlMessages';
import './_action-block.scss';

interface IPropTypes {
  blockTime: number
  disabled: boolean
  onButtonClick: () => void
  permissionError: boolean
  showLoading: boolean
  showWarning: boolean
}

export const ActionBlock: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="player__action-block">
    <button
      className="player__action-block__button"
      disabled={props.disabled}
      onClick={props.onButtonClick}
      type="button"
    >
      {props.showLoading ?
        <div className="player__action-block__spinner">
          <div />
          <div />
        </div>
        :
        <React.Fragment>
          {props.blockTime > 0 ?
            props.blockTime
            :
            <FormattedMessage {...intlMessages.start} />
          }
        </React.Fragment>
      }
    </button>

    <div
      className={cx({
        'player__action-block__alert': true,
        'player__action-block__alert--visible': props.permissionError,
      })}
    >
      <Alert
        heading={<FormattedMessage {...intlMessages.virtualLabPermissionErrorHeader} />}
        message={<FormattedMessage {...intlMessages.virtualLabPermissionErrorMessage} />}
        type={AlertStatusEnum.ERROR}
      />
    </div>

    <div
      className={cx({
        'player__action-block__alert': true,
        'player__action-block__alert--visible': props.showWarning,
      })}
    >
      <Alert
        heading={<FormattedMessage {...intlMessages.virtualLabUnavailableErrorHeader} />}
        message={<FormattedMessage {...intlMessages.virtualLabUnavailableErrorMessage} />}
        type={AlertStatusEnum.WARNING}
      />
    </div>
  </div>
);
