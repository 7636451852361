import { AxiosInstance } from "axios";
import { downloadAutomatically } from "@u4i/common/utils";
import { IAdminUserGroup } from "@u4i/parsers/admin/AdminUserGroupParser";
import { ExportProgress } from "@u4i/modules/Admin/modules/Users/components/UserExport/interface";

export class AdminUserExportApi {
  private apiClient: AxiosInstance;
  private exportId: string;
  fullExport: boolean = true;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  // get exportId and start users export
  async startExportUsers(selectedUsergroups: Array<IAdminUserGroup>): Promise<string> {
      this.fullExport = true;

      const formData = new FormData();
      selectedUsergroups?.forEach(userGroup => {
        this.fullExport = false;
        formData.append('group[]', userGroup.id);
      })

      const response  = await this.apiClient.post(`/admin/users-export`, formData)
      this.exportId = response.data.id

      return this.exportId;
  }

  // get export progress
  async getUserExportProgress(): Promise<ExportProgress> {
      return (await this.apiClient.get(`/admin/export/progress?id=` + this.exportId)).data; 
  }

  // download users export file
  async downloadUserExport() {

      const response = await this.apiClient.get(`/admin/export/download?id=` + this.exportId, {
        responseType: "arraybuffer",
        });
        downloadAutomatically(
          response.data,
          "Users export.xlsx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      return response.status;
  }

  getExportType(): string {
    return this.fullExport?"Full": "Usergroup(s)"
  }
}