import qs from 'qs';
import React, { FC, useEffect } from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import { runInAction } from 'mobx';
import {RouteComponentProps} from 'react-router-dom';
import LoginForm from '@u4i/common/forms/LoginForm/LoginForm';
import AppNavigator from '@u4i/common/AppNavigator';
import {IRootStore} from '@u4i/state/RootStore';
import intlMessages from './intlMessages';
import './_login-page.scss';

interface IProps extends RouteComponentProps {
  rootStore: IRootStore
}

export const LoginPage: FC<IProps> = inject('rootStore')(observer((props: IProps) => {
  useEffect(() => {
    if (props.rootStore.userStore.isAuthorized) {
      AppNavigator.directPush('/');
    }
  }, [])

  const handleFormSubmission = async (data: any) => {
    const parsedQuery = qs.parse(props.location.search, {ignoreQueryPrefix: true});
    await props.rootStore.userStore.login(data.email, data.password, data.recaptchaResponse);
    
    runInAction(() => {
      if (parsedQuery.successRecirectionPathname) {
        AppNavigator.directPush(parsedQuery.successRecirectionPathname);
      } else {
        AppNavigator.directPush('/');
      }
    })
  };

  const handleRecoveryClick = () => {
    AppNavigator.directPush('/recover-password');
  };

  const handleRegistrationClick = () => {
    AppNavigator.directPush('/register');
  };
  
  return (
    <div className="login-page">
      <div className="login-page__form-wrapper">
        <LoginForm 
          handleSubmit={(data) => handleFormSubmission(data)}
          isLoginModal={false} 
          isLoginPage={true}
          userStore={props.rootStore.userStore}  
        />

        <div className="login-page__info">
          <button
            className="login-page__switch-button"
            onClick={handleRecoveryClick}
            type="button"
          >
            <FormattedMessage {...intlMessages.forgotPassword} />
          </button>

          <p className="login-page__supporting-text">
            <FormattedMessage {...intlMessages.noAccount} />
          </p>

          <button
            className="login-page__switch-button"
            onClick={handleRegistrationClick}
            type="button"
          >
            <FormattedMessage {...intlMessages.registerHere} />
          </button>
        </div>
      </div>
    </div>
  )
}))
