import {action, observable, runInAction,makeObservable} from 'mobx';
import {AxiosError} from 'axios';

import {IApi} from '@u4i/state/ServicesInterfaces';
import {IChallengeAttempt} from '@u4i/parsers/ChallengeAttemptParser';
import {IRootStore} from '@u4i/state/RootStore';
import {SubmissionError} from '@u4i/reactiveForms';

export class GradingEvaluationStore {
  private apiService: IApi;
  private attemptId?: string;
  errorLoadingAttemptData = false;
  fetching = true;
  attemptData?: IChallengeAttempt;
  submissionSuccess = false;
  submitting = false;

  constructor(RootStore: IRootStore) {
    makeObservable(this, {
      errorLoadingAttemptData: observable,
      fetching: observable,
      attemptData: observable,
      submissionSuccess: observable,
      submitting: observable,
      init: action.bound,
      loadAttemptData: action.bound,
      reset: action.bound,
      submitGrading: action.bound,
      updateGrading: action.bound,
    });

    const {apiService} = RootStore;

    this.apiService = apiService;
  }

  async init(attemptId: string) {
    this.attemptId = attemptId;

    await this.loadAttemptData();
  }

  async loadAttemptData() {
    if (this.attemptId) {
      this.fetching = true;

      try {
        const attemptData = await this.apiService.grading.fetchAttempt(this.attemptId);

        runInAction(() => {
          this.attemptData = attemptData;
        });
      } catch(error) {
        this.errorLoadingAttemptData = true;
      } finally {
        this.fetching = false;
      }
    }
  }

  reset() {
    this.attemptId = undefined;
    this.errorLoadingAttemptData = false;
    this.fetching = true;
    this.attemptData = undefined;
    this.submissionSuccess = false;
    this.submitting = false;
  }

  async submitGrading(
    evaluationResult: {
      evaluations: { [questionId: string]: { feedback: string; points: number; } },
      feedback: string;
      status: string;
    }
  ) {
    if (this.attemptId) {
      this.submitting = true;

      try {
        await this.apiService.grading.submitAttempt(
          this.attemptId,
          evaluationResult.feedback,
          evaluationResult.status,
          evaluationResult.evaluations,
        );

        runInAction(() => {
          this.submissionSuccess = true;
        });
      } catch(error) {
        if (error.isAxiosError) {
          const castError = error as AxiosError<{errorMessage?: string}>;

          if (castError.response && castError.response.data.errorMessage) {
            throw new SubmissionError({generalError: castError.response.data.errorMessage});
          }
        } else {
          throw new SubmissionError({generalError: 'Sorry, there was an error during attempt grading submission'});
        }
      } finally {
        this.submitting = false;
      }
    }
  }

  async updateGrading(
    evaluationResult: {
      evaluations: { [questionId: string]: { feedback: string; points: number; } },
      feedback: string;
      status: string;
    }
  ) {
    if (this.attemptId) {
      this.submitting = true;
      try {
        await this.apiService.grading.updateAttempt(
          this.attemptId,
          evaluationResult.feedback,
          evaluationResult.status,
          evaluationResult.evaluations,
        );

        runInAction(() => {
          this.submissionSuccess = true;
        });
      } catch(error) {
        if (error.isAxiosError) {
          const castError = error as AxiosError<{errorMessage?: string}>;

          if (castError.response && castError.response.data.errorMessage) {
            throw new SubmissionError({generalError: castError.response.data.errorMessage});
          }
        } else {
          throw new SubmissionError({generalError: 'Sorry, there was an error during attempt grading submission'});
        }
      } finally {
        this.submitting = false;
      }
    }
  }
}
