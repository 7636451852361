import cx from 'classnames';
import React, { FC } from 'react';
import './_submit-button.scss';

interface IProps {
  children: any
  centered?: boolean
  disabled?: boolean
  inline?: boolean
}

const defaultProps: Partial<IProps> = {
  centered: true,
  disabled: false,
  inline: false
};


const SubmitButton: FC<IProps> = (props: IProps) => {
  return (
    <button
      className={cx({
        'submit-button': true,
        'submit-button--centered': props.centered,
        'submit-button--inline': props.inline,
      })}
      disabled={props.disabled}
      type="submit"
    >
      {props.children}
    </button>
  );
}

SubmitButton.defaultProps = defaultProps;

export default SubmitButton;
