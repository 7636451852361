import React, { FC } from 'react';
import {observer} from 'mobx-react';
import { RouteComponentProps } from "@reach/router";
import RegistrationForm from '@u4i/common/forms/RegistrationForm';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import {RegisterSection} from './RegisterSection';

import './_register-section.scss';

interface IMatchParams extends RouteComponentProps {
  slug: string;
}

interface IPropTypes extends IMatchParams {
  sectionModel: RegisterSection;
  submitForm: () => void;
}

const VisualComponent: FC<IPropTypes> = (props) => {
  return (
    <BasicInfoWrapper centered isLoading={false} sectionModel={props.sectionModel}>
      <div className="container">
        <div className="landing-page__register-section">
          <RegistrationForm onSubmit={props.sectionModel.handleRegistrationFormSubmission} rootStore={props.sectionModel.rootStore}/>
        </div>
      </div>
    </BasicInfoWrapper>
  );
};

const WrappedComponent = observer(VisualComponent);

export {WrappedComponent as VisualComponent};
