import {defineMessages} from 'react-intl';

export default defineMessages({
  emailErrorsPattern: {
    defaultMessage: 'Is not valid e-mail',
    id: 'Forms.Login.Email.Errors.Pattern',
  },
  emailErrorsPresence: {
    defaultMessage: 'E-mail cannot be blank',
    id: 'Forms.Login.Email.Errors.Presence',
  },
  emailLabel: {
    defaultMessage: 'E-mail address',
    id: 'Forms.Login.Email.Label',
  },
  passwordErrorsPresence: {
    defaultMessage: 'Password cannot be blank',
    id: 'Forms.Login.Password.Errors.Presence',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'Forms.Login.Password.Label',
  },
  submitTitle: {
    defaultMessage: 'Login',
    id: 'Forms.Login.SubmitTitle',
  }
});
