import { MobxStore } from '@u4i/MobxStore';
import axios from 'axios';
import {getNavigatorLanguage, isInternetExplorer} from '../common/utils';


const {store} = MobxStore;

const api = axios.create({
   // @ts-ignore
  baseURL: `${__WEB_ROOT__}api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 3600 * 1000,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const targetConfig = config;

  if (isInternetExplorer()) {
    const currentTimestamp = (new Date()).getTime();

    if (targetConfig.params) {
      targetConfig.params.ieCacheBuster = currentTimestamp;
    } else {
      targetConfig.params = {ieCacheBuster: currentTimestamp};
    }
  }

  return targetConfig;
});

api.interceptors.request.use((config) => {
  const targetConfig:any = config;
  const locale = store.languageStore.currentLocale || getNavigatorLanguage();

  targetConfig.headers['X-Language'] = locale;

  const accessData = store.storageService.accessData.get();
  if (accessData) {
    targetConfig.headers.Authorization = `Bearer ${accessData.token}`;
  }

  return targetConfig;
});

api.interceptors.request.use((config) => {
  const targetConfig:any = config;

  targetConfig.timing = performance.now();

  return targetConfig;
});

api.interceptors.response.use((response:any) => {
  response.config.timing = Math.round(Number(performance.now()) - response.config.timing);

  return response;
});
 // @ts-ignore
api.interceptors.response.use(null, (error) => {
  const {config} = error;

  if (axios.isCancel(error)) {
    return {catch: () => {}, then: () => {}};
  }

  if (config && error.response.status === 401) {
    if (store.userStore.isAuthorized) {
      store.userStore.logoutAndWipe();
    }
  }

  return Promise.reject(error);
});

export default api;
