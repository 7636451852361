
import { malachite, white } from '@u4i/styles/styles-variables';
import React, { useState } from 'react';
import { opacity7 } from '../../constants/Constants';

interface IPropTypes {
  cy: number
  phase: any
  r: number
  onPhaseIconClick: () => void
}

export const PhaseCompletedIcon = (props: IPropTypes) => {
  const {
    cy,
    phase,
    onPhaseIconClick
  } = props;

  const {
    CXOfPhaseIcon,
    phaseId
  } = phase;

  const [opacity, setOpacity] = useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  return(
    <svg
      id={phaseId}
      onClick={() => onPhaseIconClick()}
      onMouseEnter={handleOpacity}
      onMouseLeave={handleOpacity}
      style={{ cursor: 'pointer', userSelect: 'none', ...(opacity && { opacity: opacity7 }) }}
    >            
      <circle
        style={{
          fill: malachite,
          strokeWidth: 3,
          strokeMiterlimit: 4
        }}
        cx={CXOfPhaseIcon}
        cy={cy}
        r={40}
      />
      
      <title>{phase.longTitle}</title>

      <g transform="translate(-36.097 -595.93)">
        <path
          style={{
            fill: white,
            strokeWidth: 0.2
          }}
          d={`m ${CXOfPhaseIcon + 24},${cy + 603} 4.028604,-6.0429 c 3.325814,2.59986 3.57881,3.95589 5.02548,6.22601 7.413436,-14.56541 15.097992,-21.14211 25.897872,-29.06436 -10.45624,10.74629 -16.765028,17.99175 -26.185917,40 -3.261757,-5.2956 -5.505689,-10.80068 -8.766844,-11.11895 z`}
        />
      </g>
    </svg>
  )
}
