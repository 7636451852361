import {defineMessages} from 'react-intl';

export default defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: 'DigitalLab.ControlBar.ActionButton.Cancel',
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'DigitalLab.ControlBar.ActionButton.Confirm',
  },
  confirmationMessage: {
    defaultMessage: 'Are you sure?',
    id: 'DigitalLab.ControlBar.ActionButton.ConfirmationMessage',
  },
});
