import React, { FC } from 'react';

import './_attempt-edit-error-message.scss';

interface IPropTypes {
  icon: React.ReactNode
  message: React.ReactNode
}

export const AttemptEditErrorMessage: FC<IPropTypes> = (props) => (
  <div className="customer-grading__attempt-edit-error-message">
    <div className="customer-grading__attempt-edit-error-message__icon">
      {props.icon}
    </div>

    <h3 className="customer-grading__attempt-edit-error-message__text">
      {props.message}
    </h3>
  </div>
);
