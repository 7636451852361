import {AxiosInstance} from 'axios';

import SettingsParser, {ISettings} from '@u4i/parsers/SettingsParser';

export class SettingsApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchSettings(): Promise<ISettings> {
    const response = await this.apiClient.get('settings');

    return SettingsParser.parse(response.data);
  }
}
