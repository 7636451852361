import PropTypes from 'prop-types';
import qs from 'qs';
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import AppNavigator from '../common/AppNavigator/AppNavigator';
import appRoutes from '@u4i/common/AppNavigator/appRoutes';

function requireAuth(WrappedComponent) {
  const WrappedClass = inject('rootStore')(observer(class WrappedClass extends Component<any> {

    AppNavigatorReplacer: any = AppNavigator;

    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        query: PropTypes.object,
      }).isRequired
    };

    private userStore;

    constructor(props) {
      super(props);

      this.userStore = props.rootStore.userStore;
    }

    componentWillMount() {
      this.checkAccess();
    }

    componentDidUpdate() {
      this.checkAccess();
    }

    checkAccess = () => {
      if (!this.userStore.isLoading && !this.userStore.isAuthorized) {
        const loginSuccessRedirectionUrl: any = this.props.location.query ?
          `${this.props.location.pathname}?${qs.stringify(this.props.location.query)}`
          :
          this.props.location.pathname;

        this.AppNavigatorReplacer.replace(appRoutes.home, null, null, {
          successRecirectionPathname: loginSuccessRedirectionUrl,
        });
        this.userStore.loginModalHandle(true, loginSuccessRedirectionUrl)
      }
    }

    render() {
      if (this.userStore.isLoading || !this.userStore.isAuthorized) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  }));

  return WrappedClass;

}

export default requireAuth;
