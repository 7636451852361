import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_price-label.scss';

interface IProps {
  isDiscounted: boolean
  priceDiscount?: string | null
  priceNet: string
};

export const PriceLabel: FC<IProps> = (props: IProps) => {
  const {
    isDiscounted,
    priceDiscount,
    priceNet
  } = props;

  return (
    <div className="course-detail-page__price-label">
      {isDiscounted ?
        <FormattedMessage
          {...intlMessages.priceDiscounted}
          values={{priceDiscount: priceDiscount, priceNet: priceNet, space: <>&thinsp;</>, s: (...chunks) => <s>{chunks}</s>, small: (...chunks) => <small>{chunks}</small> }}
        />
        :
        <FormattedMessage
          {...intlMessages.priceNormal}
          values={{priceNet: priceNet, space: <>&thinsp;</>, small: (...chunks) => <small>{chunks}</small>}}
        />
      }
    </div>
  )
}
