import React, { FC } from 'react';

import './_submission-progress.scss';

interface IPropTypes {
  currentPercentage: number;
}

export const SubmissionProgress: FC<IPropTypes> = (props) => (
  <div className="exercises__submission-progress">
    <div
      className="exercises__submission-progress__fill"
      style={{width: `${props.currentPercentage * 100}%`}}
    />
  </div>
);
