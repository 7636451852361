import {defineMessages} from 'react-intl';

export default defineMessages({
  start: {
    defaultMessage: 'Start',
    id: 'Player.Element.ExternalChallengeElement.ActionBlock.Start',
  },
  virtualLabPermissionErrorHeader: {
    defaultMessage: 'Unauthorized',
    id: 'Player.Element.ExternalChallengeElement.ActionBlock.VirtualLabPermissionErrorHeader',
  },
  virtualLabPermissionErrorMessage: {
    defaultMessage: 'You are missing permissions to use virtual machines',
    id: 'Player.Element.ExternalChallengeElement.ActionBlock.VirtualLabPermissionErrorMessage',
  },
  virtualLabUnavailableErrorHeader: {
    defaultMessage: 'Try again in a minute',
    id: 'Player.Element.ExternalChallengeElement.ActionBlock.VirtualLabUnavailableErrorHeader',
  },
  virtualLabUnavailableErrorMessage: {
    defaultMessage: 'We have no virtual machine available at the moment',
    id: 'Player.Element.ExternalChallengeElement.ActionBlock.VirtualLabUnavailableErrorMessage',
  },
});
