import Parser from '@u4i/utils/Parser';
import {ChallengeAttemptStatusEnum} from '@u4i/common/enums/ChallengeAttemptStatusEnum';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';

export interface ISolutionFile {
  downloadUrl: string;
  name: string;
  path: string;
  previewUrl: string | null;
};

export interface IStudentAnswerFile {
  fileName: string;
  url: string;
}

export interface IQuestion {
  content: string;
  criterions: string;
  graderFeedback: string;
  id: string;
  modelAnswer?: string;
  possibleScore: number;
  studentAnswer: string;
  studentAnswerFiles: Array<IStudentAnswerFile>;
  userScore: number;
}

export interface IChallengeAttempt {
  canGrade: boolean;
  challengeName: string;
  challengeDetails?: any;
  contextInfo?: string | any;
  date: string;
  exampleSolutionFile?: {
    fileName: string;
    url: string;
  };
  graderFeedback?: string;
  grader: string;
  groupName: string;
  id: string;
  language: LanguageEnum;
  questions?: Array<IQuestion>;
  solutionFiles?: Array<ISolutionFile>;
  status: ChallengeAttemptStatusEnum | any;
  studentInstructions?: string | Node | any;
  updatedAt: string;
  userName: string;
}

export const ChallengeAttemptParser = new Parser<IChallengeAttempt>(challengeAttempt => {
  const {challengeDetails} = challengeAttempt;

  return {
    canGrade: challengeAttempt.canGrade,
    challengeName: challengeAttempt.challengeName,
    challengeDetails: challengeAttempt.challengeDetails,
    contextInfo: challengeAttempt.challengeDetails && challengeAttempt.challengeDetails.contextInfo,
    date: challengeAttempt.date,
    exampleSolutionFile: challengeAttempt.challengeDetails && challengeAttempt.challengeDetails.exampleSolutionFile ? {
      fileName: challengeAttempt.challengeDetails.exampleSolutionFile.fileName,
      url: challengeAttempt.challengeDetails.exampleSolutionFile.url,
    } : undefined,
    grader: challengeAttempt.grader,
    graderFeedback: challengeAttempt.graderFeedback,
    groupName: challengeAttempt.groupName,
    id: challengeAttempt.id,
    language: challengeAttempt.language,
    questions: challengeDetails && challengeDetails.questions && challengeDetails.questions.map(question => ({
      content: question.content,
      criterions: question.criterions,
      graderFeedback: question.graderFeedback,
      id: question.id,
      modelAnswer: question.modelAnswer,
      possibleScore: parseFloat(question.possibleScore),
      studentAnswer: question.studentAnswer,
      studentAnswerFiles: question.studentAnswerFiles.map(file => ({
        fileName: file.fileName,
        url: file.url,
      })),
      userScore: parseFloat(question.userScore),
    })),
    solutionFiles: challengeAttempt.challengeDetails && challengeAttempt.challengeDetails.solutionFiles,
    status: challengeAttempt.status,
    studentInstructions: challengeAttempt.challengeDetails && challengeAttempt.challengeDetails.studentInstructions,
    updatedAt: challengeAttempt.updatedAt,
    userName: challengeAttempt.userName,
  };
});
