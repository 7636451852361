import React from 'react';
import { FormattedMessage } from 'react-intl';
import intlMessages from './intlMessages';

interface IPropTypes {
  challengesCompleted: number;
  challengesInGrading: number;
  topicsCount: number;
  totalChallenges: number;
  viewedTopicsCount: number;
}

const ChapterStatistics: React.FC<IPropTypes> = (props) => (
  <span>
    {props.totalChallenges > 0 ?
      <FormattedMessage
        {...intlMessages.challengesStatusesMessage}
        values={{
          challengesCompleted: props.challengesCompleted,
          challengesInGrading: props.challengesInGrading,
          totalChallenges: props.totalChallenges,
        }} />
      :
      <FormattedMessage
        {...intlMessages.viewedStatusMessage}
        values={{
          topicsCount: props.topicsCount,
          viewedTopicsCount: props.viewedTopicsCount,
        }} />
    }
  </span>
)

export default ChapterStatistics;