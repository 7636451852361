export const enum ChallengePlatformEnum {
  CLASSMARKER = 'class_marker',
  COMMUNITY_CHALLENGE = 'community_challenge',
  DIGITAL_LABS = 'digital_lab',
  EMAIL = 'email',
  EXERCISES = 'exercise',
  EXTERNAL = 'external',
  FEEDBACK_SURVEY = 'feedback_survey',
  JUPYTER_LABS = 'jupyter_lab',
  LIVE_LABS = 'live_labs',
  LTI = 'lti',
  MCQ_TEST = 'mcq_test'
};
