import React from 'react';

export interface DataColumnConfig<ValueType> {
  centerContent?: boolean;
  contentFormatter?: (value: ValueType) => React.ReactNode;
  label?: React.ReactNode;
  order: number;
  sortable?: boolean;
  width: number;
}

export class DataColumn<ValueType> {
  centerContent: boolean;
  contentFormatter?: (value: ValueType) => React.ReactNode;
  id: string;
  label: React.ReactNode;
  order: number;
  sortable: boolean;
  width: number;

  constructor(config: DataColumnConfig<ValueType> & { id: string }) {
    this.centerContent = config.centerContent === undefined ? false : config.centerContent;
    this.contentFormatter = config.contentFormatter;
    this.id = config.id;
    this.label = config.label !== undefined ? config.label : '';
    this.order = config.order;
    this.sortable = config.sortable === undefined ? true : config.sortable;
    this.width = config.width;
  }
}
