import cx from 'classnames';
import is from 'is_js';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {action, computed, observable,makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import intlMessages from './intlMessages';
import './_expandable-text.scss';

interface IPropTypes {
  animationDurationSeconds: number;
  className?: string;
  content: string;
  hideExpandButton?: boolean;
  overrideIsExpanded?: boolean;
  trimmingThreshold: number;
}

const defaultProps: Partial<IPropTypes> = {
  className: '',
  hideExpandButton: false,
};

const ExpandableText = observer(class ExpandableText extends React.Component<IPropTypes> {
  private readonly ANIMATION_FRAME_RATE = 60;
  private animationLettersPerSecond: number;
  private animationRequestId: number;
  static defaultProps = defaultProps;
  currentContentLength: number;
  isExpanded = false;
  targetContentLength: number;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable(this, {
      currentContentLength: observable,
      isExpanded: observable,
      targetContentLength: observable,
      visibleContent: computed,
      animateContent: action.bound,
      resetAnimation: action.bound,
      toggleExpansion: action.bound
    });

    this.resetAnimation();
  }

  componentDidUpdate(prevProps: IPropTypes) {
    const overridePresent = is.boolean(this.props.overrideIsExpanded);
    
    if (this.props.content !== prevProps.content) {
      this.isExpanded = false;

      this.resetAnimation();
    }

    if (overridePresent && this.props.overrideIsExpanded !== this.isExpanded) {
      this.toggleExpansion();
    }
  }

  componentDidMount() {
    const overridePresent = is.boolean(this.props.overrideIsExpanded);
    
    if (overridePresent && this.props.overrideIsExpanded !== this.isExpanded) {
      this.toggleExpansion();
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationRequestId);
  }

  get visibleContent() {
    if (this.props.content.length < this.props.trimmingThreshold) {
      return this.props.content;
    }

    let visibleContent = this.props.content.substring(0, this.currentContentLength);

    if (visibleContent.substring(-1) === ' ') {
      visibleContent = visibleContent.slice(0, -1);
    }

    if (!this.isExpanded && this.currentContentLength === this.targetContentLength) {
      if (visibleContent.substring(-1) === '.') {
        visibleContent += '..';
      } else {
        visibleContent += '...';
      }
    }

    return visibleContent;
  }

  animateContent() {
    if (this.targetContentLength > this.currentContentLength) {
      const expectedLength: number = this.currentContentLength + this.animationLettersPerSecond;
      this.currentContentLength = Math.min(expectedLength, this.targetContentLength);

      if (this.currentContentLength < this.targetContentLength) {
        this.animationRequestId = window.requestAnimationFrame(this.animateContent);
      }
    } else if (this.targetContentLength < this.currentContentLength) {
      const expectedLength = this.currentContentLength - this.animationLettersPerSecond;
      this.currentContentLength = Math.max(expectedLength, this.targetContentLength);

      if (this.currentContentLength > this.targetContentLength) {
        this.animationRequestId = window.requestAnimationFrame(this.animateContent);
      }
    }
  }

  resetAnimation() {
    const animationFrameLength = this.props.animationDurationSeconds * this.ANIMATION_FRAME_RATE;
    this.animationLettersPerSecond = this.props.content.length / animationFrameLength;

    const initialContentLength: number = Math.min(this.props.content.length, this.props.trimmingThreshold);
    this.currentContentLength = initialContentLength;
    this.targetContentLength = initialContentLength;
  }

  toggleExpansion() {
    window.cancelAnimationFrame(this.animationRequestId);

    this.isExpanded = !this.isExpanded;
    
    if (this.isExpanded) {
      this.targetContentLength = this.props.content.length;
    } else {
      this.targetContentLength = this.props.trimmingThreshold;
    }

    this.animationRequestId = window.requestAnimationFrame(this.animateContent);
  }

  render() {
    if (this.currentContentLength === 0) {
      return null;
    }

    return (
      <p
        className={cx({
          'expandable-text': true,
          [this.props.className!]: true,
        })}
      >
        {this.visibleContent}

        {(!this.props.hideExpandButton && this.props.content.length > this.props.trimmingThreshold) &&
          <button className="expandable-text__button" onClick={this.toggleExpansion} type="button">
            {this.isExpanded ?
              <FormattedMessage {...intlMessages.showLess} />
              :
              <FormattedMessage {...intlMessages.showAll} />
            }
          </button>
        }
      </p>
    );
  }
});

export default ExpandableText;
