import "./_index.scss";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import DeFlag from "../../../../assets/svg/de.svg";
import ENFlag from "../../../../assets/svg/gb.svg";
import CZFlag from "../../../../assets/svg/cz.svg";
import THFlag from "../../../../assets/svg/th.svg";
import CHFlag from "../../../../assets/svg/cn.svg";
import JPFlag from "../../../../assets/svg/jp.svg";
import KRFlag from "../../../../assets/svg/kr.svg";
import React from "react";

const FlagIcon = ({ language,...rest }: any) => {
  const languageFlag = (): string => {
    switch (language) {
      case LanguageEnum.DE_DE:
        return "DE";
        break;

      case LanguageEnum.EN_US:
        return "EN";
        break;

      case LanguageEnum.CS_CZ:
        return "CZ";
        break;

      case LanguageEnum.KO_KR:
        return "KO";
        break;

      case LanguageEnum.JA_JP:
        return "JA";
        break;

      case LanguageEnum.TH_TH:
        return "TH";
        break;

      case LanguageEnum.ZH_CN:
        return "ZH";
        break;

      default:
        return "EN";
        break;
    }
  };
  
  return (
    <>
      <div className={"common-card-flag"+' '+rest.className} >
        {languageFlag()}
      </div>
    </>
  );
};

export default FlagIcon;
