import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_viewed-label.scss';

interface IProps {
  viewed: boolean;
}

export const ViewedLabel: FC<IProps> = (props: IProps) => {
  const { viewed } = props;
  let viewedLabel: React.ReactNode;

  if (viewed) {
    viewedLabel = <FormattedMessage {...intlMessages.contentViewed} />;
    return (
      <div
        className={cx({
          'course-detail-page__viewed-label': true,
          'course-detail-page__viewed-label--not-viewed': !viewed,
          'course-detail-page__viewed-label--viewed': viewed,
        })}
      >
        <div className="course-detail-page__viewed-label__title">
          {viewedLabel}
        </div>
      </div>
    );
  } 
  // else {
  //   viewedLabel = <FormattedMessage {...intlMessages.contentNotViewed} />;
  // }

  return (
    null
  )
}
