import { ChallengePlatformEnum } from "@u4i/common/enums/ChallengePlatformEnum";
import { ChallengeProgressStatusEnum } from "@u4i/common/enums/ChallengeProgressStatusEnum";
import { PlayerElementTypesEnum } from "@u4i/common/enums/PlayerElementTypesEnum";
import { PlayerContext } from "@u4i/modules/VirtualExperience/PlayerState/playerContext";
import UserStore from "@u4i/state/UserStore";
import React, { FC } from "react";
import { DiscussElement } from "./DiscussElement";
import { ExternalChallengeElement } from "./ExternalChallengeElement";
import ImageElement from "./ImageElement";
import InteractiveElement from "./InteractiveElement";
import MCQTestElement from "./MCQTestElement/MCQTestElement";
import NoGuestPermissionElement from "./NoGuestPermissionElement/NoGuestPermissionElement";
import TextElement from "./TextElement";
import UnsupportedElement from "./UnsupportedElement";
import VideoElement from "./VideoElement";
import ChatBotQuiz from "./ChatBotQuiz/ChatBotQuiz";

interface IProps {
  elementData: any;
  elementPositionIndex: number;
  userStore: UserStore;
  hasExtraElements: boolean;
  isUserAuth: boolean;
}

const ElementComponentSwitcher: FC<IProps> = (props) => {
  const playerState: any = React.useContext(PlayerContext);

  const onChangeMCQTestChallengeStatus = (
    challengeId: string,
    status: ChallengeProgressStatusEnum
  ) => {
    playerState.changeChallengeStatus(challengeId, status);
  };

  switch (props.elementData.type) {
    case PlayerElementTypesEnum.EXTERNAL_CHALLENGE:
      if (
        props.elementData.challenge.provider ===
        ChallengePlatformEnum.COMMUNITY_CHALLENGE
      ) {
        return (
          <DiscussElement
            questionContent={props.elementData.challenge.questionContent}
            questionSlug={props.elementData.challenge.questionSlug}
            title={props.elementData.title}
            elementId={props.elementData.id}
          />
        );
      }
      if (
        props.elementData.challenge.provider === ChallengePlatformEnum.MCQ_TEST
      ) {
        return (
          <MCQTestElement
            challenge={props.elementData.challenge}
            elementId={props.elementData.id}
            title={props.elementData.title}
            onMCQTestChallengeEvaluation={onChangeMCQTestChallengeStatus}
          />
        );
      }

      return (
        <ExternalChallengeElement
          challenge={props.elementData.challenge}
          elementId={props.elementData.id}
          title={props.elementData.title}
        />
      );

    case PlayerElementTypesEnum.IMAGE:
      return <ImageElement url={props.elementData.image} />;

    case PlayerElementTypesEnum.INTERACTIVE:
      return (
        <>
          {props.elementData.id === playerState.activeElementId &&
            props.isUserAuth && (
              <InteractiveElement
                onScroll={null}
                title={props.elementData.title}
                elementId={props.elementData.id}
                url={props.elementData.interactive.url}
                elementPositionIndex={props.elementPositionIndex}
                scrollPosition={null}
                elementHeight="100%"
                isCurrentElementActive={
                  props.elementData.id == playerState.activeElementId
                }
              />
            )}
          {!props.isUserAuth && (
            <InteractiveElement
              onScroll={null}
              title={props.elementData.title}
              url={props.elementData.interactive.url}
              elementPositionIndex={props.elementPositionIndex}
              scrollPosition={null}
              elementHeight="100%"
              isCurrentElementActive={
                props.elementData.id == playerState.activeElementId
              }
            />
          )}
        </>
      );

    case PlayerElementTypesEnum.NO_GUEST_PERMISSION:
      return (
        <NoGuestPermissionElement
          title={props.elementData.title}
          userStore={props.userStore}
        />
      );

    case PlayerElementTypesEnum.TEXT_IMAGE:
      return (
        <TextElement
          background={props.elementData.textImage.backgroundUrl}
          content={props.elementData.textImage.elementRender}
        />
      );

    case PlayerElementTypesEnum.VIDEO:
      return (
        <VideoElement
          elementId={props.elementData.id}
          title={props.elementData.title}
          elementVisibilityPercentage={0}
          provider={props.elementData.video.provider}
          url={props.elementData.video.url}
          videoId={props.elementData.video.id}
          hasExtraElements={props.hasExtraElements}
        />
      );
    case PlayerElementTypesEnum.CHATBOT:
      return props.elementData.chatbot && <ChatBotQuiz
        chatbotData={props.elementData.chatbot}
      />;

    default:
      return <UnsupportedElement />;
  }
};

export default ElementComponentSwitcher;
