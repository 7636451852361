import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_back-button.scss';

interface IPropTypes {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const BackButton: FC<IPropTypes> = (props: IPropTypes) => (
  <button className="exercises__back-button" onClick={props.onClick} type="button">
    <FontAwesome className="exercises__back-button__icon" name="arrow-left" />

    <FormattedMessage {...intlMessages.buttonLabel} />
  </button>
);
