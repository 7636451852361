import React, { lazy, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router';
import Application from '../containers/Application/Application';
import AppNavigator from '../common/AppNavigator/AppNavigator';
import InstantAuthorization from '../modules/UserManager/InstantAuthorization/InstantAuthorization';
import MainLayout from '../modules/MainLayout/MainLayout';
import { AdminOld } from '../modules/AdminOld/AdminOld';
import { DigitalLab } from '../modules/DigitalLab/DigitalLab';
import { Exercises } from '../modules/Exercises/Exercises';
import {
  GradingAttemptEdit,
  GradingAttemptEvaluation,
  GradingAttemptsList,
  GradingProgressGroupsList,
  GradingProgressAttemptsList,
  GradingProgressUsersList,
} from '../modules/CustomerGrading';
import { Homepage } from '../modules/Homepage/Homepage';
import { JupyterLab } from '../modules/JupyterLab/JupyterLab';
import { LandingPage } from '../modules/LandingPage/LandingPage';
import { LoginPage } from '../modules/LoginPage/LoginPage';
import { ScormAuthentication } from '../modules/ScormAuthentication/ScormAuthentication';
import { BookingDigitalLab } from '../modules/DigitalLab/components/Booking/Booking';
import requireAuth from './requireAuth';
import requireRole from '../routes/requireRole/requireRole';
import { RedeemPage } from '@u4i/modules/RedeemPage/RedeemPage';
import CourseDetailPage from '@u4i/modules/CourseDetailPage/CourseDetailPage';
import singleton from '@u4i/modules/CourseDetailPage/components/BackButton/BackController';
import ResetPasswordPage from '@u4i/modules/ResetPasswordPage/ResetPasswordPage';
import PasswordRecoveryPage from '@u4i/modules/PasswordRecoveryPage/PasswordRecoveryPage';
import { RegisterConfirmPage } from '@u4i/modules/RegisterConfirmPage/RegisterConfirmPage';
import RegistrationSuccess from '@u4i/modules/RegistrationSuccess/RegistrationSuccess';
import { DiscussPage } from '@u4i/modules/DiscussPage/DiscussPage';
import { UserRolesEnum } from '@u4i/common/enums/UserRolesEnum';
import NotFoundPage from '@u4i/modules/NotFoundPage/NotFoundPage';
import CircularProgress from '@u4i/common/CircularProgress';
import { RegistrationPage } from '@u4i/modules/RegistrationPage';
import { ExternalContent } from '@u4i/modules/ExternalContent';
import VirtualExperience from '@u4i/modules/VirtualExperience';

const Admin = lazy(() => import('./../modules/Admin/Admin'));

export default function () {
  singleton.hook(AppNavigator.history);

  return (
    <Router history={AppNavigator.history}>
      <Application>
        <Suspense fallback={<CircularProgress />}>
          <Switch>
            <Route path="/player/skill/:skillSlug/:chapterSlug?" component={VirtualExperience} />
            <Route path="/player/chapter/:chapterSlug" component={VirtualExperience} />
            <MainLayout>
              <Switch>
                <Route path="/" exact component={Homepage} />
                {/* HACK: for google crawler */}
                <Route path="/index.html" exact component={Homepage} />

                <Route path="/instant-authorization/:loginToken" component={InstantAuthorization} />

                <Route path="/scorm-authentication" component={ScormAuthentication} />

                <Route
                  path="/admin/setting/basic"
                  exact
                  component={requireRole(UserRolesEnum.SUPERADMIN, UserRolesEnum.U4I_ADMIN, UserRolesEnum.U4I_FACULTY)(AdminOld)}
                />

                <Route path="/administration/user/account/edit" component={AdminOld} />

                <Route path="/administration/u/:userId" component={AdminOld} />

                <Route
                  path="/administration"
                  component={requireRole(UserRolesEnum.SUPERADMIN, UserRolesEnum.U4I_ADMIN, UserRolesEnum.U4I_FACULTY)(AdminOld)}
                />

                <Route
                  path="/administration3"
                  component={requireRole(UserRolesEnum.SUPERADMIN, UserRolesEnum.U4I_ADMIN, UserRolesEnum.U4I_FACULTY)(Admin)}
                />

                <Route
                  path="/administration3"
                  component={requireRole(UserRolesEnum.SUPERADMIN, UserRolesEnum.U4I_ADMIN, UserRolesEnum.U4I_FACULTY)(Admin)}
                />

                <Route path="/administration" component={AdminOld} />

                <Route path="/digital-labs/:labId" component={requireAuth(DigitalLab)} />

                <Route path="/jupyter-lab/:labId" component={requireAuth(JupyterLab)} />

                <Route path="/discuss/:challengeSlug" component={requireAuth(DiscussPage)} />

                <Route
                  path="/grading/attempt/edit/:attemptId"
                  component={requireRole(UserRolesEnum.GRADER_ADMIN, UserRolesEnum.GRADER_FACULTY)(GradingAttemptEdit)}
                />

                <Route
                  path="/grading/attempts"
                  component={requireRole(UserRolesEnum.GRADER_ADMIN, UserRolesEnum.GRADER_FACULTY)(GradingAttemptsList)}
                />

                <Route
                  path="/grading/evaluation/:attemptId"
                  component={requireRole(UserRolesEnum.GRADER_ADMIN, UserRolesEnum.GRADER_FACULTY)(GradingAttemptEvaluation)}
                />

                <Route
                  path="/grading/progress/group/:groupId"
                  component={requireRole(UserRolesEnum.GRADER_ADMIN)(GradingProgressUsersList)}
                />

                <Route
                  path="/grading/progress/user/:userId"
                  component={requireRole(UserRolesEnum.GRADER_ADMIN)(GradingProgressAttemptsList)}
                />

                <Route
                  path="/grading/progress"
                  component={requireRole(UserRolesEnum.GRADER_ADMIN)(GradingProgressGroupsList)}
                />

                <Route path="/login" component={LoginPage} />

                <Route path="/booking-dl/:courseId" component={requireAuth(BookingDigitalLab)} />

                <Route path="/register" component={RegistrationPage} />

                <Route path="/recover-password" component={PasswordRecoveryPage} />

                <Route path="/reset-password/:guid/:token" component={ResetPasswordPage} />

                <Route path="/register-confirm/:token" component={RegisterConfirmPage} />

                <Route path="/register-success" component={RegistrationSuccess} />

                <Route path="/exercises/:exerciseId" component={requireAuth(Exercises)} />

                <Route path="/external-content" component={ExternalContent} />

                <Route path="/redeem" component={requireAuth(RedeemPage)} />

                <Route path="/skills/:skillSlug" component={CourseDetailPage} />

                <Route path="/:slug" component={LandingPage} />

                <Route component={NotFoundPage} />
              </Switch>
            </MainLayout>
          </Switch>
        </Suspense>
      </Application>
    </Router>
  );
}
