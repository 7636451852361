import { ICurriculum } from '@u4i/common/Interfaces/CurriculumInterface';
import {CURRICULUM_CARD_WIDTH, CURRICULUM_ROW_LABEL_COLUMN_WIDTH, CURRICULUM_SPACING} from '@u4i/constantSettings';

interface IAlgorithmData {
  cardWidth: number;
  cellSkillCounts: Array<Array<number>>;
  columnCount: number;
  containerSize: number;
  rowLabelColumnWidth: number;
  spacing: number;
}

export interface IColumnRepresentation {
  cardCount: number;
  growthFactor: number;
  width: number;
}

interface IAlgorithmResult {
  columnRepresentations: Array<IColumnRepresentation>;
  leftoverWidth: number;
}

export class ContainerTooSmallError extends Error {
  static NAME = 'ContainerTooSmallError';

  constructor() {
    super('Container size too small');

    this.name = ContainerTooSmallError.NAME;
  }
}

function columnSizingAlgorithm(data: IAlgorithmData): IAlgorithmResult {
  const totalMinimumCardWidth = data.columnCount * data.cardWidth;
  const totalMinimumSpacing = data.columnCount * data.spacing * 2;
  const expectedMinimumSpace = data.rowLabelColumnWidth + totalMinimumCardWidth + totalMinimumSpacing;

  if (data.containerSize < expectedMinimumSpace) {
    throw new ContainerTooSmallError();
  }

  const columnRepresentations: Array<IColumnRepresentation> = data.cellSkillCounts.map(columnCellsSkillCounts => ({
    cardCount: 1,
    growthFactor: Math.max(...columnCellsSkillCounts),
    width: data.cardWidth + (2 * data.spacing),
  }));

  let leftoverWidth = data.containerSize - totalMinimumCardWidth - totalMinimumSpacing - data.rowLabelColumnWidth;
  const cardAdditionWidth = data.cardWidth + data.spacing;

  while (leftoverWidth > cardAdditionWidth) {
    const columnsGrowthNeedFactors = columnRepresentations.map(entry => entry.growthFactor / entry.cardCount);
    const biggestGrowthNeedFactor = Math.max(...columnsGrowthNeedFactors);
    const targetColumnIndex = columnsGrowthNeedFactors.indexOf(biggestGrowthNeedFactor);
    const targetColumn = columnRepresentations[targetColumnIndex];

    targetColumn.width += cardAdditionWidth;
    targetColumn.cardCount += 1;
    leftoverWidth -= cardAdditionWidth;
  }

  return {columnRepresentations, leftoverWidth};
}

export function runColumnSizingAlgorithm(curriculum: ICurriculum, containerWidth: number): IAlgorithmResult {
  const cellSkillCounts = curriculum.columns.map(
    (label, index) => curriculum.rows.map(row => row.items[index].length),
  );

  return columnSizingAlgorithm({
    cardWidth: CURRICULUM_CARD_WIDTH,
    cellSkillCounts,
    columnCount: curriculum.columns.length,
    containerSize: containerWidth,
    rowLabelColumnWidth: CURRICULUM_ROW_LABEL_COLUMN_WIDTH,
    spacing: CURRICULUM_SPACING,
  });
}
