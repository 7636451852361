import React, { FC } from "react";
import { inject } from "mobx-react";
import { IRootStore } from "@u4i/state/RootStore";
import { PDFDownloadButton } from "./components/PDFDownloadButton";
import { SummariesList } from "./components/SummariesList";
import { SummaryHeading } from "./components/SummaryHeading";
import { SummarySidebar } from "./components/SummarySidebar";
import "./_summary-element.scss";
import { PlayerContext } from "@u4i/modules/VirtualExperience/PlayerState/playerContext";
import { withCalculatedFontSize } from "@u4i/modules/VirtualExperience/components/withCalculatedFontSize";

interface IPropTypes {
  calculatedFontSize?: number;
  chapterTitle: string;
  page: number;
  pagesTotal: number;
  rootStore?: IRootStore;
  showPDFDownload?: Boolean;
  summaries: Array<{
    id: string;
    text: string;
  }>;
  summaryPDF?: string;
}

const SummaryElement: FC<IPropTypes> = (props: IPropTypes) => {
  const playerState: any = React.useContext(PlayerContext);

  return (
    <div
      className="player__summary-element"
      style={{ fontSize: props.calculatedFontSize }}
    >
      <SummarySidebar />

      <div className="player__summary-element__right-section">
        <div className="player__summary-element__content-wrapper">
          <SummaryHeading page={props.page} pagesTotal={props.pagesTotal} />

          <div className="player__summary-element__list">
            <SummariesList entries={props.summaries} />
          </div>

          {props.showPDFDownload &&
            playerState.activeChapterData.summaryPDF && (
              <div className="player__summary-element__button">
                <PDFDownloadButton
                  chapterTitle={playerState.activeChapterData.chapterTitle}
                  url={playerState.activeChapterData.summaryPDF}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

const WrappedComponent: any = inject("rootStore")(
  withCalculatedFontSize(SummaryElement)
);

export { WrappedComponent as SummaryElement };
