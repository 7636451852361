import { withCalculatedFontSize } from '@u4i/modules/VirtualExperience/components/withCalculatedFontSize';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import prepareContent from './prepareContent';

import './_masonite.scss';
import './_text-element.scss';

class TextElement extends Component<any> {
  static propTypes = {
    background: PropTypes.string,
    calculatedFontSize: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired
  };

  static defaultProps = {
    background: null
  };

  prepareContent = memoize(prepareContent);

  render() {
    const preparedContent = this.prepareContent(this.props.content);

    return (
        <div className="player__text-element"
          style={{
            fontSize: this.props.calculatedFontSize,
          }}>
          <img src={this.props.background ? `${this.props.background}` : 'none'} alt="" className='text-image' />
          <span dangerouslySetInnerHTML={{ __html: preparedContent }}>
          </span>
        </div>
    );
  }
}

export default withCalculatedFontSize(TextElement);
