import { AntDTableFilters } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import { CornerstoneCourseAssignmentsDto, ICourseSimplifiedObject, PostCornerstoneAssignment } from "@u4i/modules/Admin/modules/CornerstoneCourseAssignments/interfaces/CornerstoneInterfaces";
import { IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { AxiosInstance } from "axios";
import qs from 'qs';

export class AdminCornerstoneCourseAssignmentApi {
    private apiClient: AxiosInstance;

    constructor(apiClient: AxiosInstance) {
        this.apiClient = apiClient;
    }

    async fetchCoursesCornerstone (config: AntDTableFilters<ICourseSimplifiedObject>, isCornerstoneAssignment: 0 | 1): Promise<IPaginatedItems<ICourseSimplifiedObject>> {
        const parameters: any = {
            filters: config.filters,
            limit: config.limit,
            offset: config.offset,
            orderBy: config.orderBy,
            cornerstone: isCornerstoneAssignment
        };

        const response = await this.apiClient.get("/admin/skills", {
            params: parameters,
            paramsSerializer: () => qs.stringify(parameters),
        });

        const items = response.data;
        const totalItems = parseInt(response.headers["x-total-count"], 10);

        return { items, totalItems };
    }

    fetchCornerstoneAssignmentsList = async (): Promise<CornerstoneCourseAssignmentsDto[]> => {
        const response = await this.apiClient.get("/admin/cornerstone/assignments/lists");
        return response.data;
    }

    getAssignedCourseCornerstoneAssignments = async (courseId: string): Promise<CornerstoneCourseAssignmentsDto[]> => {
        const response = await this.apiClient.get(`/admin/cornerstone/assignments/courses/${courseId}`);
        return response.data;
    }

    postCornerstoneAssignments = async (courseId: string, model: PostCornerstoneAssignment[]): Promise<boolean> => {
        const response = await this.apiClient.post(`/admin/cornerstone/assignments/courses/${courseId}`, {data: model});
        return response.data;
    }

    putCornerstoneAssignments = async (courseId: string, model: PostCornerstoneAssignment[]): Promise<boolean> => {
        const response = await this.apiClient.put(`/admin/cornerstone/assignments/courses/${courseId}`, {data: model});
        return response.data;
    }

    deleteCornerstoneAssignments = async (courseId: string): Promise<boolean> => {
        const response = await this.apiClient.delete(`/admin/cornerstone/assignments/courses/${courseId}`);
        return response.data;
    }
}
