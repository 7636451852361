import { action, makeObservable, observable, runInAction } from "mobx";
import { IApi, IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import { IAnswer, IAssignedTest, ICategory, ICategoryPost, IChapterSimplified, IQuestionEntity, IQuestionPost, IQuestionPut, IQuestionSimplified, IQuestionTopic, ITestEntity, ITestPost } from "./AssessmentHubInterfaces";
import moment from "moment";
import { TablePaginationConfig } from "antd/lib/table";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import DOMPurify from 'dompurify';

export class AdminAssessmentHubStore {
  private apiService: IApi;
  public apiConfigQ: AntDTableFilters<IQuestionEntity> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {}
  };
  public paginationQ: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true
  };

  public tableDataQ: IQuestionEntity[] = [];
  public creatingQuestion: boolean = false;
  public deletingQuestion: boolean = false;
  public fetchingQuestions: boolean = true;
  public questionById: IQuestionEntity | any;
  public topicsList: IQuestionTopic[] = [];
  public cantDeleteQuestion: boolean = false;
  public deleteCandidateQuestion: IQuestionEntity | any;
  public testsAssigned: IAssignedTest[];

  public apiConfigCategory: AntDTableFilters<ICategory> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {}
  };
  public paginationCategory: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true
  }
  public categoriesList: ICategory[] = [];
  public categorySaved: boolean = false;
  public fetchingCategories: boolean = true;
  public tableDataCategories: ICategory[] = [];

  public apiConfigTests: AntDTableFilters<ITestEntity> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {}
  };
  public paginationTests: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true
  };
  public tableDataTests: ITestEntity[] = [];
  public creatingTest: boolean = false;
  public fetchingTests: boolean = true;
  public fetchingTest: boolean = true;
  public testById: ITestEntity | any = undefined;
  public chaptersList: IChapterSimplified[] = [];
  public availableQuestions: any[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      apiConfigQ: observable,
      apiConfigTests: observable,
      apiConfigCategory: observable,
      availableQuestions: observable,
      categorySaved: observable,
      cantDeleteQuestion: observable,
      cantDeleteQuestionHandle: action.bound,
      chaptersList: observable,
      createCategory: action.bound,
      createQuestion: action.bound,
      createTest: action.bound,
      creatingQuestion: observable,
      creatingTest: observable,
      categoriesList: observable,
      deleteQuestion: action.bound,
      deleteCandidateQuestion: observable,
      deletingQuestion: observable,
      deleteCategory: action.bound,
      deleteTest: action.bound,
      fetchingQuestions: observable,
      fetchingCategories: observable,
      fetchingTests: observable,
      fetchingTest: observable,
      fetchQuestionsByChapters: action.bound,
      getAllQuestions: action.bound,
      getAllTests: action.bound,
      getAllCategories: action.bound,
      getCategoriesList: action.bound,
      getChaptersList: action.bound,
      getTopicsList: action.bound,
      getTestById: action.bound,
      getQuestionById: action.bound,
      initAvailableQuestionsList: action.bound,
      initTestById: action.bound,
      onTableChangeQ: action.bound,
      onTableChangeCategory: action.bound,
      paginationQ: observable,
      paginationTests: observable,
      paginationCategory: observable,
      questionById: observable,
      resetQuestionById: action.bound,
      tableDataQ: observable,
      tableDataTests: observable,
      tableDataCategories: observable,
      testById: observable,
      testsAssigned: observable,
      topicsList: observable,
      updateQuestion: action.bound,
      updateCategory: action.bound,
      updateTest: action.bound
    });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  //#region QUESTIONS BANK SECTION begins
  onTableChangeQ = async (
    pagination: AntDPagination,
    filters: { [columnName in keyof IQuestionEntity]?: IQuestionEntity[columnName] },
    sorter: AntDSorter<IQuestionEntity>
  ) => {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key: string) => {
      if (filters[key] !== null) {
        if (key === 'created_at' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['createdAtFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['createdAtTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field === 'created_at' ? 'created_at' : sorter.field] = sortDirection;
    }

    this.apiConfigQ = {
      ...this.apiConfigQ,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.paginationQ = {...pagination, current };

    await this.getAllQuestions(this.apiConfigQ);

    this.apiConfigQ = { ...this.apiConfigQ, filters: {}};
  }

  getAllQuestions = async (config: AntDTableFilters<IQuestionEntity>) => {
    this.fetchingQuestions = true;
    this.apiConfigQ = config;

    try {
      const questionsListPaginated: IPaginatedItems<IQuestionEntity> = await this.apiService.admin.assessmentHub.fetchQuestionsBankData(this.apiConfigQ);

      runInAction(() => {
        const { items, totalItems } = questionsListPaginated;

        this.tableDataQ = items;
        this.paginationQ = {
          ...this.paginationQ,
          pageSize: this.apiConfigQ.limit,
          total: totalItems
        };
      });
    } catch (error) {
      Notification(NotificationEnum.Error, "Fetch Questions", error);

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetchingQuestions = false;
    }
  }

  getQuestionById = async (id: string) => {
    this.fetchingQuestions = true;

    try {
      this.questionById = await this.apiService.admin.assessmentHub.fetchQuestionById(id);

      runInAction(() => {
        if(this.questionById) {
          this.prepareSelectedCategoriesTopics();
        }
      });
      return this.questionById;
    }
    catch (error) {
      throw (error);
    }
    finally {
      this.fetchingQuestions = false;
    }
  }

  private prepareSelectedCategoriesTopics = () => {
    let addedTopics: string[] = [];
    let addedCategories: string[] = [];

    this.questionById.related_topics.forEach((top: IQuestionTopic) => {
      addedTopics.push(top.id);
    })

    this.questionById.related_categories.forEach((cat: ICategory) => {
      addedCategories.push(cat.id)
    })

    this.questionById.selected_topics = addedTopics;
    this.questionById.selected_categories = addedCategories;
  }

  resetQuestionById = () => {
    this.questionById = null;
  }

  getTopicsList = async () => {
    try {
      let res = await this.apiService.admin.assessmentHub.getTopicsList();

      runInAction(() => {
        this.topicsList = res;
      })
    }
    catch(error) {

    }
  }

  getCategoriesList = async () => {
    try {
      let res = await this.apiService.admin.assessmentHub.getCategoriesList();

      runInAction(() => {
        this.categoriesList = res;
      })
    } catch (error) {

    }
  }

  createQuestion = async (model: IQuestionPost, answers: any, isMatchingQuestion: boolean = false) => {
    this.creatingQuestion = true;
    try {
      let postModel: IQuestionPost = {
        answers: {},
        internal_id: model.internal_id.trim(),
        correct_feedback: model.correct_feedback?.trim(),
        incorrect_feedback: model.incorrect_feedback?.trim(),
        content: model.content.trim(),
        points: model.points,
        type: model.type,
        related_topics: model.related_topics,
        related_categories: model.related_categories,
        randomize_answers_order: model.randomize_answers_order
      }

      if(isMatchingQuestion) {
        postModel.answers = answers.answers;
        postModel.clues = answers.clues;
        postModel.correct_matched_pairs = answers.correct_matched_pairs;
      } else {
        postModel.answers = answers;
      }

      await this.apiService.admin.assessmentHub.createQuestion(postModel).then(res => {
        Notification(NotificationEnum.Success, "Create Question", `Question \"${model.internal_id?.trim()}\" is added.`);
        AppNavigator.push(appRoutes.adminQuestionsOverview);
        this.creatingQuestion = false;
      })
    }
    catch (error) {
      const createQuestionError = error.response.data?.validationErrors?.internal_id || error.response.data?.validationErrors?.content || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Create Question", createQuestionError);
      } else {
        Notification(NotificationEnum.Error, "Create Question", createQuestionError);
      }

      this.creatingQuestion = false;
    }
  }

  updateQuestion = async (id: string, model: IQuestionPut, answers: any, editingFromTest: boolean = false, isMatchingQuestion: boolean = false) => {
    this.creatingQuestion = true;
    try {
      let putModel: IQuestionPost = {
        answers: {},
        internal_id: model.internal_id.trim(),
        correct_feedback: model.correct_feedback?.trim(),
        incorrect_feedback: model.incorrect_feedback?.trim(),
        content: model.content.trim(),
        points: model.points,
        type: model.type,
        related_topics: model.selected_topics,
        related_categories: model.selected_categories,
        randomize_answers_order: model.randomize_answers_order
      };

      if(isMatchingQuestion) {
        putModel.answers = answers.answers;
        putModel.clues = answers.clues;
        putModel.correct_matched_pairs = answers.correct_matched_pairs;
      } else {
        putModel.answers = answers;
      }

      await this.apiService.admin.assessmentHub.updateQuestion(id, putModel).then(res => {
        Notification(NotificationEnum.Success, "Update Question", `Question \"${model.internal_id?.trim()}\" is updated.`);
        if(!editingFromTest){
          AppNavigator.push(appRoutes.adminQuestionsOverview);
        }
        this.creatingQuestion = false;
      })
    }
    catch (error) {
      const updateQuestionError = error.response.data?.validationErrors?.internal_id || error.response.data?.validationErrors?.content || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Update Question", updateQuestionError);
      } else {
        Notification(NotificationEnum.Error, "Update Question", updateQuestionError);
      }

      this.creatingQuestion = false;
    }
  }

  deleteQuestion = async (record: IQuestionEntity) => {
    this.deletingQuestion = true;
    this.deleteCandidateQuestion = record;

    const isOneItemOnPage: boolean = this.tableDataQ.length === 1;
    try {
      await this.apiService.admin.assessmentHub.deleteQuestion(record.id).then(res => {
        if(res === true) {
          Notification(NotificationEnum.Success, "Delete Question", `Question \"${record.internal_id}\" is deleted.`);

          runInAction(() => {
            if (isOneItemOnPage) {
              this.apiConfigQ = {...this.apiConfigQ, current: 1, offset: 0};
            };

            this.getAllQuestions(this.apiConfigQ);
          })
        } else {
          this.cantDeleteQuestion = true;
          this.testsAssigned = res.tests;
        }
      });
    }
    catch(error) {
      let deletingQuestionError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Delete Question", deletingQuestionError);
    }
    finally {
      this.deletingQuestion = false;
    }
  }

  cantDeleteQuestionHandle = () => {
    this.cantDeleteQuestion = false;
  }
  //#endregion QUESTIONS BANK SECTION ends


  //#region TESTS SECTION begins
  onTableChangeTests = async(
    pagination: AntDPagination,
    filters: { [columnName in keyof ITestEntity]?: ITestEntity[columnName] },
    sorter: AntDSorter<ITestEntity>
  ) => {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key: string) => {
      if (filters[key] !== null) {
        if (key === 'created_at' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['createdAtFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['createdAtTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field === 'created_at' ? 'created_at' : sorter.field] = sortDirection;
    }

    this.apiConfigTests = {
      ...this.apiConfigTests,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.paginationTests = {...pagination, current};

    await this.getAllTests(this.apiConfigTests);

    this.apiConfigTests = {...this.apiConfigTests, filters: {}};
  }

  getAllTests = async(filters: AntDTableFilters<ITestEntity>) => {
    this.fetchingTests = true;
    this.apiConfigTests = filters;

    try {
      const testsListPaginated: IPaginatedItems<ITestEntity> = await this.apiService.admin.assessmentHub.fetchTestsData(this.apiConfigTests);

      runInAction(() => {
        const { items, totalItems } = testsListPaginated;

        this.tableDataTests = items;
        this.paginationTests = {
          ...this.paginationTests,
          pageSize: this.apiConfigTests.limit,
          total: totalItems
        };
      });
    } catch (error) {
      Notification(NotificationEnum.Error, "Fetch Tests", error);

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetchingTests = false;
    }
  }

  getChaptersList = async () => {
    try {
      let res = await this.apiService.admin.assessmentHub.fetchChaptersList();

      runInAction(() => {
        this.chaptersList = res;
      })
    }
    catch(error) {

    }
  }

  fetchQuestionsByChapters = async (chapters: string[]) => {
    try {
      let res: IQuestionSimplified[] = await this.apiService.admin.assessmentHub.fetchQuestionsByChapters(chapters);

      runInAction(() => {
        this.availableQuestions = res;
      })
    }
    catch(error) {

    }
  }

  initAvailableQuestionsList = () => {
    this.availableQuestions = [];
  }

  createTest = async (model: ITestPost) => {
    this.creatingTest = true;
    try {
      model.passmark = Number(model.passmark);
      model.title = model.title.trim();

      await this.apiService.admin.assessmentHub.createTest(model).then(res => {
        let testTitle: any = DOMPurify.sanitize(model?.title);
        const textContent = new DOMParser().parseFromString(testTitle, 'text/html').body.textContent;

        Notification(NotificationEnum.Success, "Create Test", `Test ${textContent} is added.`);
        AppNavigator.push(appRoutes.adminTestsOverview);
        this.creatingTest = false;
      })
    }
    catch (error) {
      const createTestError = error.response.data?.validationErrors?.title || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Create Test", createTestError);
      } else {
        Notification(NotificationEnum.Error, "Create Test", createTestError);
      }

      this.creatingTest = false;
    }
  }

  updateTest = async (id: string, model: ITestPost) => {
    this.creatingTest = true;
    try {
      //model.randomize_question_order = true;
      model.passmark = Number(model.passmark);
      model.title = model.title.trim();

      await this.apiService.admin.assessmentHub.updateTest(id, model).then((res: ITestPost) => {
        let testTitle: any = DOMPurify.sanitize(model?.title);
        const textContent = new DOMParser().parseFromString(testTitle, 'text/html').body.textContent;

        Notification(NotificationEnum.Success, "Update Test", `Test ${textContent} is updated.`);
        AppNavigator.push(appRoutes.adminTestsOverview);
        this.creatingTest = false;
      })
    }
    catch (error) {
      const updateTestError = error.response.data?.validationErrors?.title || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Update Test", updateTestError);
      } else {
        Notification(NotificationEnum.Error, "Update Test", updateTestError);
      }

      this.creatingTest = false;
    }
  }

  getTestById = async (id: string) => {
    this.fetchingTest = true;
    try {
      const response: ITestEntity = await this.apiService.admin.assessmentHub.fetchTestById(id);

      runInAction(() => {
        this.testById = response;
      });
    }
    catch (error) {
      throw (error);
    }
    finally {
      this.fetchingTest = false;
    }
  }

  initTestById = () => {
    this.testById = undefined;
  }

  deleteTest = async (record: ITestEntity) => {
    const isOneItemOnPage: boolean = this.tableDataTests.length === 1;
    try {
      await this.apiService.admin.assessmentHub.deleteTest(record.id).then(res => {
        if(res === true) {
          let testTitle: any = DOMPurify.sanitize(record.title);
          const textContent = new DOMParser().parseFromString(testTitle, 'text/html').body.textContent;

          Notification(NotificationEnum.Success, "Delete Test", `Test ${textContent} is deleted.`);

          runInAction(() => {
            if (isOneItemOnPage) {
              this.apiConfigTests = {...this.apiConfigTests, current: 1, offset: 0};
            };

            this.getAllTests(this.apiConfigTests);
          })
        }
      });
    }
    catch(error) {
      let deletingTestError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Delete Test", deletingTestError);
    }
  }

  //#endregion TESTS SECTION ends


  //#region CATEGORIES starts
  async onTableChangeCategory(
    pagination: AntDPagination,
    filters: { [columnName in keyof ICategory]?: ICategory[columnName] },
    sorter: AntDSorter<ICategory>
  ) {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key: string) => {
      if (filters[key] !== null) {
        if (key === 'created_at' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['createdAtFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['createdAtTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field === 'created_at' ? 'created_at' : sorter.field] = sortDirection;
    }

    this.apiConfigCategory = {
      ...this.apiConfigCategory,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.paginationCategory = {...pagination, current};

    await this.getAllCategories(this.apiConfigCategory);

    this.apiConfigCategory = {...this.apiConfigCategory, filters: {}};
  }

  async getAllCategories(filters: AntDTableFilters<ICategory>) {
    this.fetchingCategories = true;
    this.apiConfigCategory = filters;

    try {
      const categoriesListPaginated: IPaginatedItems<ICategory> = await this.apiService.admin.assessmentHub.fetchCategoriesData(this.apiConfigCategory);

      runInAction(() => {
        const { items, totalItems } = categoriesListPaginated;

        this.tableDataCategories = items;
        this.paginationCategory = {
          ...this.paginationCategory,
          pageSize: this.apiConfigCategory.limit,
          total: totalItems
        };
      });
    } catch (error) {
      Notification(NotificationEnum.Error, "Fetch Categories", error);

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetchingCategories = false;
    }
  }

  createCategory = async (model: ICategoryPost) => {
    this.categorySaved = false;
    try {
      await this.apiService.admin.assessmentHub.createCategory(model);

      runInAction(() => {
        Notification(NotificationEnum.Success, "Create Category", `Category \"${model.name?.trim()}\" is added.`);
        this.getAllCategories(this.apiConfigCategory);
        this.categorySaved = true;
      })
    }
    catch (error) {
      const createCategoryError = error.response.data?.validationErrors?.name || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Create Category", createCategoryError);
      } else {
        Notification(NotificationEnum.Error, "Create Category", createCategoryError);
      }
    }
    finally {
      this.categorySaved = false;
    }
  }

  updateCategory = async (id: string, model: ICategoryPost) => {
    try {
      await this.apiService.admin.assessmentHub.updateCategory(id, model);

      runInAction(() => {
        Notification(NotificationEnum.Success, "Update Category", `Category \"${model.name?.trim()}\" is updated.`);
        this.getAllCategories(this.apiConfigCategory);
        this.categorySaved = true;
      })
    }
    catch (error) {
      const updateCategoryError = error.response.data?.validationErrors?.name || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Update Category", updateCategoryError);
      } else {
        Notification(NotificationEnum.Error, "Update Category", updateCategoryError);
      }
    }
    finally {
      this.categorySaved = false;
    }
  }

  deleteCategory = async (record: ICategory) => {
    const isOneItemOnPage: boolean = this.tableDataCategories.length === 1;
    try {
      await this.apiService.admin.assessmentHub.deleteCategory(record.id).then((res: boolean) => {
        if(res === true) {
          Notification(NotificationEnum.Success, "Delete Category", `Category \"${record.name}\" is deleted.`);

          runInAction(() => {
            if (isOneItemOnPage) {
              this.apiConfigCategory = {...this.apiConfigCategory, current: 1, offset: 0};
            };

            this.getAllCategories(this.apiConfigCategory);
          })
        }
      });
    }

    catch(error) {
      let deletingCategoryError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Delete Category", deletingCategoryError);
    }
  }
  //#endregion   CATEGORIES ends

}
