import React from 'react';
import {inject} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router';

import AppNavigator, {appRoutes} from '@u4i/common/AppNavigator';
import {ActionsBar, DataTable, DataTableStore, TextFilter} from '@u4i/common/DataTable';
import {IRootStore} from '@u4i/state/RootStore';
import {SegementedBar} from '@u4i/common/SegementedBar';

import intlMessages from './intlMessages';
import { guardsmanRed, iron, mainBlue, green } from '@u4i/styles/styles-variables';

interface IMatchParams {
  groupId: string;
}

interface IPropTypes extends RouteComponentProps<IMatchParams> {
  rootStore: IRootStore;
}

interface RowDataShape {
  email: string;
  failedAttempts: number;
  firstName: string;
  id: string;
  lastName: string;
  passedAttempts: number;
  submittedAttempts: number;
  totalAttempts: number;
}


export const ProgressUsersList = withRouter(inject('rootStore')(class ProgressUsersList extends React.Component<IPropTypes> {
    private dataTableStore: DataTableStore<RowDataShape>;

    constructor(props: IPropTypes) {
      super(props);

      this.dataTableStore = new DataTableStore<RowDataShape>({
        dataColumns: {
          firstName: {label: <FormattedMessage {...intlMessages.firstName} />, order: 1, width: 150},
          lastName: {label: <FormattedMessage {...intlMessages.lastName} />, order: 2, width: 150},
          email: {label: <FormattedMessage {...intlMessages.email} />, order: 3, width: 120},
          submittedAttempts: {
            centerContent: true,
            label: <FormattedMessage {...intlMessages.submitted} />,
            order: 4,
            sortable: false,
            width: 120,
          },
          failedAttempts: {
            centerContent: true,
            label: <FormattedMessage {...intlMessages.failed} />,
            order: 5,
            sortable: false,
            width: 120,
          },
          passedAttempts: {
            centerContent: true,
            label: <FormattedMessage {...intlMessages.passed} />,
            order: 6,
            sortable: false,
            width: 120,
          },
          totalAttempts: {
            centerContent: true,
            label: <FormattedMessage {...intlMessages.total} />,
            order: 7,
            sortable: false,
            width: 120,
          },
        },
        dataProvider: this.tableDataProvider,
        filters: [
          new TextFilter('firstName', 'firstName'),
          new TextFilter('lastName', 'lastName'),
          new TextFilter('email', 'email'),
        ],
        functionalColumns: {
          chart: {
            centerContent: true,
            content: (rowData) => {
              if (rowData.totalAttempts === 0) {
                return <SegementedBar segments={[{color: iron, sizeFraction: 1}]} />;
              }

              return (
                <SegementedBar
                  segments={[{
                    color: mainBlue,
                    label: <FormattedMessage {...intlMessages.submitted} />,
                    sizeFraction: rowData.submittedAttempts / rowData.totalAttempts,
                  }, {
                    color: guardsmanRed,
                    label: <FormattedMessage {...intlMessages.failed} />,
                    sizeFraction: rowData.failedAttempts / rowData.totalAttempts,
                  }, {
                    color: green,
                    label: <FormattedMessage {...intlMessages.passed} />,
                    sizeFraction: rowData.passedAttempts / rowData.totalAttempts,
                  }]}
                />
              );
            },
            label: <FormattedMessage {...intlMessages.chart} />,
            order: 8,
            width: 200,
          },
          actions: {
            centerContent: true,
            content: (rowData) => (
              <ActionsBar
                buttons={[{
                  iconName: 'eye',
                  onClick: this.handleViewClick,
                  tooltipLabel: <FormattedMessage {...intlMessages.view} />,
                  visible: true,
                }]}
                rowId={rowData.id}
              />
            ),
            label: <FormattedMessage {...intlMessages.actions} />,
            order: 9,
            width: 80,
          },
        },
        pagination: {
          enabled: true,
          itemsPerPage: 10,
        },
        tableId: 'progress-users-list',
      }, props.rootStore);
    }

    handleViewClick = (userId: string) => {
      AppNavigator.push(appRoutes.gradingProgressUser, {userId});
    };

    tableDataProvider = async (
      sortedColumnId: string | undefined,
      sortDirection: 'ASC' | 'DESC',
      filters: { [columnName in keyof RowDataShape]?: RowDataShape[columnName] },
      paginationInfo: {limit: number; offset: number;} | undefined,
    ): Promise<{items: Array<RowDataShape>, totalItems: number}> => {
      const {groupId} = this.props.match.params;
      const data = await this.props.rootStore.apiService.grading.fetchUsersProgressList(groupId, {
        filters,
        limit: paginationInfo ? paginationInfo.limit : 10,
        offset: paginationInfo ? paginationInfo.offset : 0,
        sortDirection,
        sortedColumnId,
      });

      return {
        items: data.items.map(item => ({
          email: item.email,
          failedAttempts: item.attemptsCount.failed,
          firstName: item.firstName,
          id: item.id,
          lastName: item.lastName,
          passedAttempts: item.attemptsCount.passed,
          submittedAttempts: item.attemptsCount.submitted,
          totalAttempts: item.attemptsCount.total,
        })),
        totalItems: data.totalItems,
      };
    };

    render() {
      return (
        <div className="container">
          <DataTable store={this.dataTableStore} />
        </div>
      );
    }
  }));
