import React, { FC, useEffect } from 'react';
import {inject} from 'mobx-react';
import {IRootStore} from '@u4i/state/RootStore';
import AppNavigator from '@u4i/common/AppNavigator';
import { PasswordRecoveryForm } from '@u4i/common/forms/PasswordRecoveryForm/PasswordRecoveryForm';
import { RouteComponentProps } from 'react-router';
import './_password-recovery-page.scss';

interface IProps extends RouteComponentProps {
  rootStore: IRootStore;
}

const PasswordRecoveryPage: FC<IProps> = (props: IProps) => {
  const {
    passwordRecoverySubmitted,
    handlePasswordRecoveryFormSubmission
  } = props.rootStore.userStore;

  useEffect(() => {
    if (props.rootStore.userStore.isAuthorized) {
      AppNavigator.directPush('/');
    }
  }, [])
  
  return(
    <div className="password-recovery-page">
      <div className="password-recovery-page__form-wrapper">
        <PasswordRecoveryForm 
          handleSubmit={handlePasswordRecoveryFormSubmission} 
          submitSucceeded={passwordRecoverySubmitted}
        />
      </div>
    </div>
  )
}

export default inject('rootStore')(PasswordRecoveryPage);
