import {defineMessages} from 'react-intl';

export default defineMessages({
  context: {
    defaultMessage: 'Context',
    id: 'CustomerGrading.AttemptEdit.Context',
  },
  download: {
    defaultMessage: 'Download',
    id: 'CustomerGrading.AttemptEdit.Download',
  },
  downloadSolution: {
    defaultMessage: 'Download example solution',
    id: 'CustomerGrading.AttemptEdit.DownloadSolution',
  },
  incorrectId: {
    defaultMessage: 'Sorry, there is no challenge attempt with this id.',
    id: 'CustomerGrading.AttemptEdit.IncorrectId',
  },
  instructions: {
    defaultMessage: 'Instructions',
    id: 'CustomerGrading.AttemptEdit.Instructions',
  },
  solutionFiles: {
    defaultMessage: 'Files',
    id: 'CustomerGrading.AttemptEdit.SolutionFiles',
  },
  view: {
    defaultMessage: 'View',
    id: 'CustomerGrading.AttemptEdit.View',
  },
});
