import ExtendableError from 'es6-error';

interface ISubmissionErrorData {
  fieldsErrors?: { [fieldName: string]: string };
  generalError?: string;
}

export class SubmissionError extends ExtendableError {
  fieldsErrors?: { [fieldName: string]: string };
  generalError?: string;

  constructor(errorData: ISubmissionErrorData) {
    super('Submission error!');

    this.fieldsErrors = errorData.fieldsErrors;
    this.generalError = errorData.generalError;
  }
}
