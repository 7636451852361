import React from 'react';
import {v4 as uuid} from "uuid"; 
import {action, autorun, computed, observable,makeObservable} from 'mobx';

interface IFilterValue<ValueType> { [newValueKey: string]: ValueType | null }

export abstract class BaseFilter<ValueType> {
  private _attachedToColumn: string;
  private _currentValues: IFilterValue<ValueType> = {};
  private _storedValueName: string;
  protected id = uuid();
  private subscribers: Array<(newValue: IFilterValue<ValueType>) => void> = [];

  constructor(attachedToColumn: string, defaultValue?: IFilterValue<ValueType>) {
    makeObservable<BaseFilter<ValueType>, "_currentValues" | "subscribers">(this, {
      _currentValues: observable,
      subscribers: observable,
      currentValues: computed,
      unsubscribeAll: action.bound,
      updateValues: action.bound
    });

    this._attachedToColumn = attachedToColumn;
    this._storedValueName = attachedToColumn;

    if (defaultValue) {
      Object.assign(this._currentValues, defaultValue);
    }

    autorun(this.broadcast);
  }

  get attachedToColumn() {
    return this._attachedToColumn;
  }

  get currentValues() {
    return this._currentValues;
  }

  get storedValueName() {
    return this._storedValueName;
  }

  private broadcast = () => {
    this.subscribers.forEach((subscriber) => { subscriber(this._currentValues); });
  };

  subscribe = (callback: (newValue: IFilterValue<ValueType>) => void) => {
    this.subscribers.push(callback);
  };

  abstract render(): React.ReactNode;

  unsubscribeAll() {
    this.subscribers.splice(0, this.subscribers.length);
  }

  updateValues(newValues: IFilterValue<ValueType>) {
    this._currentValues = newValues;
  }
}
