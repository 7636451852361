import { Card, Col, Progress, Row } from "antd";
import React from "react";
import { Typography } from "antd";
import {
  ClockCircleFilled,
  PlayCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import intlMessages from "./intlMessages";
import { FormattedMessage } from "react-intl";
import FlagIcon from "./FlagIcon";
import { green } from "@u4i/styles/styles-variables";
import "./_index.scss";

const { Title, Text } = Typography;

const CommonCard = ({
  title = "",
  image = "",
  providers = null,
  language = "",
  disableHovering = false,
  description,
  onPlayClick,
  duration,
  progress,
  slug,
  isSkilCard,
  isCompleted,
  onCardClick,
}: any) => {
  const handlePlayClick = () => {
    if (isSkilCard) return onCardClick();
    return onPlayClick(slug);
  };

  const mainProvider = (): any => {
    if (!providers) return null;
    if (providers.length > 0) {
      return providers[0];
    }
    return null;
  };

  const durationHumanized: string = moment.duration(duration, "seconds").humanize();

  return (
    <Card className="common-card common-card-wrapper">
      <div
        style={{
          backgroundImage: `url(${image})`,
        }}
        className="common-card-header"
        onClick={handlePlayClick}
      >
        <FlagIcon language={language} />

        {mainProvider() && (
          <div className="common-card-provider">
            <img src={mainProvider().url} alt="provider" />
          </div>
        )}

        {!isCompleted && !disableHovering && (
          <div className="card-header-hover">
            <Row justify="center" align="middle">
              <Col md={24} className="text-center mb-8">
                <PlayCircleFilled />
              </Col>
              <Col md={24} className="text-center">
                <Text>
                  <FormattedMessage {...intlMessages.startNow} />
                </Text>
              </Col>
            </Row>
          </div>
        )}
        
        {isCompleted && (
          <div className="card-header-compleated">
            <Row justify="center" align="middle">
              <Col md={24} className="text-center mb-8">
                <CheckCircleFilled style={{ color: green }} />
              </Col>
              <Col md={24} className="text-center">
                <Text
                  strong
                  style={{
                    color: "white",
                  }}
                >
                  <FormattedMessage {...intlMessages.courseCompleted} />
                </Text>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <div className="mt-16 invert-section">
        <Title level={4}>{title}</Title>
        {duration && (
          <Row align="middle" className="mb-8">
            <ClockCircleFilled style={{ color: "#E3E3E3" }} />
            <Text type="secondary" className="ml-8">
              {durationHumanized}
            </Text>
          </Row>
        )}

        {isSkilCard && (
          <Row>
            <Progress percent={progress * 100} showInfo={false} />
          </Row>
        )}
      </div>
      <div className="mt-8 invert-section">
        {isSkilCard && (
          <div className="mb-8">
            <Text type="secondary">
              <FormattedMessage {...intlMessages.completed} />:{" "}
            </Text>
            <strong>{Math.round(progress * 100)}%</strong>
          </div>
        )}
        {description && (
          <div>
            <Text type="secondary">{description} </Text>
          </div>
        )}
      </div>
    </Card>
  );
};

export default CommonCard;
