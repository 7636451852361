import {defineMessages} from 'react-intl';

export default defineMessages({
  attemptEvaluated: {
    defaultMessage: 'Sorry, this attempt has already been evaluated.',
    id: 'CustomerGrading.AttemptGrading.AttemptEvaluated',
  },
  context: {
    defaultMessage: 'Context',
    id: 'CustomerGrading.AttemptGrading.Context',
  },
  download: {
    defaultMessage: 'Download',
    id: 'CustomerGrading.AttemptGrading.Download',
  },
  downloadExample: {
    defaultMessage: 'Download example solution',
    id: 'CustomerGrading.AttemptGrading.DownloadExample',
  },
  incorrectId: {
    defaultMessage: 'Sorry, there is no challenge attempt with this id.',
    id: 'CustomerGrading.AttemptGrading.IncorrectId',
  },
  instructions: {
    defaultMessage: 'Instructions',
    id: 'CustomerGrading.AttemptGrading.Instructions',
  },
  solutionFiles: {
    defaultMessage: 'Files',
    id: 'CustomerGrading.AttemptGrading.SolutionFiles',
  },
  view: {
    defaultMessage: 'View',
    id: 'CustomerGrading.AttemptGrading.View',
  },
});
