import {action, observable, runInAction,makeObservable} from 'mobx';
import {IApi, IPaginatedItems, IStorage} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import {
  AntDPagination, AntDSorter,
  AntDTableFilters,
  DEFAULT_PAGE_SIZE
} from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';
import {ICourseList, IGroupList} from "@u4i/modules/CourseDetailPage/interfaces/CourseInterface";
import {Notification, NotificationEnum} from "@u4i/modules/Admin/common/Notifications/Notification";
import {
  IChatbotKB,
  IKBFileInfo
} from "@u4i/modules/Admin/modules/ChatbotFileManager/interfaces/ChatbotKBInterfaces";
import {TablePaginationConfig} from "antd/lib/table";
import moment from "moment/moment";

import {Dispatch, SetStateAction, useState} from "react";

export class AdminChatbotFileManagerStore {
  private apiService: IApi;
  private rootStore: IRootStore;
  private storageService: IStorage;
  apiConfig: AntDTableFilters<IChatbotKB> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {},
  };
  //activatingUserError?: string;
  //config: AntDTableFilters<IChatbotKB>;
  currentOffset = 0;
  deleting = false;
  //deactivatingUserError?: string;
  //deletingUserError?: string;
  fetching = true;
  fetchingNewKnowledgeBase = false;
  fetchingNewKnowledgeBaseError = false;
  message = '';
  totalUsers = 0;

  validationErrors?: {
    company?: string;
    email?: string;
    firstName?: string;
    groups?: string;
    lastName?: string;
  };
  courseList: Array<ICourseList> = [];
  groupList: Array<IGroupList> = [];

  tableData: Array<IChatbotKB> = [];
  pagination: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true,
  };

  isLoading = false;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  selectedCourse: string;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      apiConfig: observable,
      //activatingUserError: observable,
      //config: observable,
      currentOffset: observable,
      //deactivatingUserError: observable,
      //deletingUserError: observable,
      pagination: observable,
      fetching: observable,
      fetchingNewKnowledgeBase: observable,
      fetchingNewKnowledgeBaseError: observable,

      message: observable,
      totalUsers: observable,
      validationErrors: observable,
      resetData: action.bound,
      getCourseListSimple: action.bound,
      courseList: observable,
      groupList: observable,

      isLoading: observable,
      selectedCourse: observable,

      deleting: observable,
      deleteFile: action.bound,
      deleteKnowledgeBase: action.bound,
    });

    const {apiService, storageService} = rootStore;
    this.apiService = apiService;
    this.rootStore = rootStore;
    this.storageService = storageService;

  }

  getCourseListSimple = async () => {
    try {
      let res = await this.apiService.skills.getListCoursesSimple();
      runInAction(() => {
        this.courseList = res;
      })
      return true;
    }
    catch(error) {
      return false;
    }
  }

  getGroupListSimple = async () => {
    try {
      let res = await this.apiService.admin.groups.getGroupListSimple();
      runInAction(() => {
        this.groupList = res;
      })
      return true;
    }
    catch(error) {
      return false;
    }
  }

  getLists = async () => {
    this.fetching = true;

    try {
      const responsesJSON = await Promise.all([
        this.getCourseListSimple(),
        this.getGroupListSimple()
      ]);
      const [getCourses, getGroups] = await Promise.all(responsesJSON.map(r => r));
      runInAction(() => {
        if (getCourses && getGroups) {
          this.fetching = false;
        }
      })
    } catch (error) {
    } finally {
    }
  }

  getFileInfo = async (args: IKBFileInfo) => {
      try {
          if (args.setLoading)
              args.setLoading(true);

          let res = await this.apiService.admin.chatbot.getFileInfo(args.body);
          runInAction(() => {
              if (args.onSuccess)
                  args.onSuccess(res.data);
              if (args.setLoading)
                  args.setLoading(false);
          })
      }
      catch(error) {
          // runInAction(() => {
          //     if (args.onError)
          //         args.onError(error);
          //     if (args.setLoading)
          //         args.setLoading(false);
          // })
      }
  }

  uploadFiles = async (args: any) => {
      try {
          if (args.setLoading)
              args.setLoading(true);
          let res = await this.apiService.admin.chatbot.upload(args.body);
          runInAction(() => {
              if (args.onSuccess) {
                args.onSuccess(res.files);
              }
              if (args.setLoading)
                  args.setLoading(false);
          })
      }
      catch(error) {
        if (args.onError)
          args.onError(error);
      } finally {
        if (args.setLoading)
          args.setLoading(false);
      }
  }
  syncFiles = async(args: any) => {
    try {
      if (args.setLoading)
        args.setLoading(true);
      let res = await this.apiService.admin.chatbot.syncFiles();
      runInAction(() => {
        if (args.onSuccess) {
          args.onSuccess(res);
        }
        if (args.setLoading)
          args.setLoading(false);
      })
    }
    catch(error) {
      if (args.onError)
        args.onError(error);
    } finally {
      if (args.setLoading)
        args.setLoading(false);
    }
  }
  generatePDFFiles = async(args: any) => {
    this.fetching = true;
    try {
      if (args.setLoading)
        args.setLoading(true);
      let res = await this.apiService.admin.chatbot.generatePDFFiles(args.body);
      runInAction(() => {
        if (args.onSuccess) {
          args.onSuccess(res.files);
          this.fetching = false;
        }
      })
    }
    catch(error) {
      if (args.onError)
        args.onError(error);
    } finally {
      this.fetching = false;
    }
  }

  transcribeVideos = async(args: any) => {
    this.fetching = true;
    try {
      if (args.setLoading)
        args.setLoading(true);
      let res = await this.apiService.admin.chatbot.transcribeVideos(args.body);
      runInAction(() => {
        if (args.onSuccess) {
          args.onSuccess(res.files);
          this.fetching = false;
        }
      })
    }
    catch(error) {
      if (args.onError)
        args.onError(error);
    } finally {
      this.fetching = false;
    }
  }
  getUploadedFiles = async (args: any) => {
      try {
          if (args.setLoading)
              args.setLoading(true);
          let res = await this.apiService.admin.chatbot.getUploadedFiles("");
          runInAction(() => {
              if (args.onSuccess)
                  args.onSuccess(res.file);
              if (args.setLoading)
                  args.setLoading(false);
          })
      }
      catch(error) {
        // runInAction(() => {
        //     if (args.onError)
        //         args.onError(error);
        //     if (args.setLoading)
        //         args.setLoading(false);
        // })
      }
  }

  createKnowledgeBase = async (knowledgeBaseData: any, args: any) => {
    this.fetchingNewKnowledgeBase = true;
    this.validationErrors = undefined;
    try {
      const res = await this.apiService.admin.chatbot.submitNewKnowledgeBase(knowledgeBaseData);

      runInAction(() => {
        if (args.onSuccess)
          args.onSuccess(res)
        Notification(NotificationEnum.Success, "Knowledge Base Created" );
      });
    } catch(error) {
      this.fetchingNewKnowledgeBaseError = true;

      if (error.data && error.data.validationErrors) {
        Notification(NotificationEnum.Error, "Knowledge Base Error", error.response.data.validationErrors.course_id);
      }
    } finally {
      this.fetchingNewKnowledgeBase = false;
    }
  }

  updateKnowledgeBase = async (knowledgeBaseData: any) => {
    this.fetchingNewKnowledgeBase = true;
    this.validationErrors = undefined;
    try {
      const userInfo = await this.apiService.admin.chatbot.submitUpdateKnowledgeBase(knowledgeBaseData);

      runInAction(() => {
        Notification(NotificationEnum.Success, "Knowledge Base Updated" );
      });
    } catch(error) {
      this.fetchingNewKnowledgeBaseError = true;

      const createNewKnowledgeBaseError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "Knowledge Base Update", createNewKnowledgeBaseError);

      if (error.response.data && error.response.data.validationErrors) {
        this.validationErrors = error.response.data.validationErrors;
      }
    } finally {
      this.fetchingNewKnowledgeBase = false;
    }
  }

  onTableChange = async (
    pagination: AntDPagination,
    filters: { [columnName in keyof IChatbotKB]?: IChatbotKB[columnName] },
    sorter: AntDSorter<IChatbotKB>
  ) => {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key: string) => {
      if (filters[key] !== null) {
        if (key == 'createdAt' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['creationDateFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['creationDateTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field === 'createdAt' ? 'creationDate' : sorter.field] = sortDirection;
    }

    this.apiConfig = {
      ...this.apiConfig,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.pagination = {
      ...pagination,
      current
    }

    await this.reloadChatbotKnowledgeBasesData(this.apiConfig);

    this.apiConfig = {
      ...this.apiConfig,
      filters: {}
    };
  }

  reloadChatbotKnowledgeBasesData = async(config: AntDTableFilters<IChatbotKB> | any) => {
    this.fetching = true;
    this.apiConfig = config;

    try {
      const chatbotKnowledgeBasesListPaginated: IPaginatedItems<IChatbotKB> = await this.apiService.admin.chatbot.fetchChatbotKnowledgeBasesData(this.apiConfig);

      const { items, totalItems } = chatbotKnowledgeBasesListPaginated;
      this.pagination = {
        ...this.pagination,
        pageSize: this.apiConfig.limit,
        total: totalItems
      };
      runInAction(() => (
        this.tableData = items
      ));

    } catch (error) {
      Notification(NotificationEnum.Error, "Chatbot Knowledge Bases fetch", error)

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetching = false;
    }
  }

  loadChatbotKnowledgeBaseData = async(args: any) => {
    this.fetching = true;

    try {
      const responsesJSON = await Promise.all([
        this.getCourseListSimple(),
        this.getGroupListSimple(),
        this.apiService.admin.chatbot.fetchChatbotKnowledgeBaseData(args.chatbotKBId)
      ]);
      const [getCourses, getGroups, res] = await Promise.all(responsesJSON.map(r => r));
      runInAction(() => {
        if (getCourses && getGroups && args.onSuccess) {
          args.onSuccess(res);
          this.fetching = false;
        }
      })
    } catch (error) {
      this.fetching = false;
    } finally {
    }
  }

  deleteFile = async(args: any) => {
      // this.fileDeleting = true;
      try {
        const res = await this.apiService.admin.chatbot.deleteFile(args.body);
        runInAction(() => {
          if (args.onSuccess) {
            args.onSuccess(res);
          }
        })
      } catch(error){

      } finally {
        // this.fileDeleting = false;
      }
  }

  deleteKnowledgeBase = async(args: any) => {
    // this.fileDeleting = true;
    try {
      const res = await this.apiService.admin.chatbot.deleteKnowledgeBase(args.body);
      runInAction(() => {
        if (args.onSuccess) {
          args.onSuccess(res);
        }
      })
    } catch(error){

    } finally {
      // this.fileDeleting = false;
    }
  }

  downloadFile = async(filepath: string) => {
      return await this.apiService.admin.chatbot.downloadFile(filepath);
  }

  resetData() {
    this.currentOffset = 0;
    this.fetching = true;
    this.fetchingNewKnowledgeBase = false;
    this.fetchingNewKnowledgeBaseError = false;
    this.message = '';
    this.totalUsers = 0;
    this.tableData = [];
  }
}
