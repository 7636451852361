import React, { FC } from 'react';

import {CompareMeTabIdEnum} from '../../../../CompareMeSection';

interface IPropTypes {
  id: CompareMeTabIdEnum
  title: string
}

export const Tab: FC<IPropTypes> = () => null
