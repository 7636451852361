import React, { Fragment } from 'react';
import { ModuleInfoLabel } from '../GeneralTextLabelElementsSVG/ModuleInfoLabel';
import { mainBlue } from '@u4i/styles/styles-variables';
import { ToggleOff } from '../GeneralTextLabelElementsSVG/ToggleOff';
import { ToggleOn } from '../GeneralTextLabelElementsSVG/ToggleOn';
import { PathLine } from '../GeneralTextLabelElementsSVG/PathLine';
import { SelfReflectionIcon } from '../ModuleIconComponentsSVG/SelfReflectionIcon';
import { GroupDiscussionIcon } from '../ModuleIconComponentsSVG/GroupDiscussionIcon';
import { DigitalJupyterLabIcon } from '../ModuleIconComponentsSVG/DigitalJupyterLabIcon';
import { InteractiveTaskIcon } from '../ModuleIconComponentsSVG/InteractiveTaskIcon';
import { OnlineDiscussionIcon } from '../ModuleIconComponentsSVG/OnlineCourse-CommunityChallengeIcon';
import { UseCasesIcon } from '../ModuleIconComponentsSVG/UseCasesIcon';
import { LiveEventIcon } from '../ModuleIconComponentsSVG/LiveEventIcon';
import { OnlineCourseChapterIcon } from '../ModuleIconComponentsSVG/OnlineCourse-ChapterIcon';
import { PracticeExerciseIcon } from '../ModuleIconComponentsSVG/PracticeExcerciseIcon';
import { IProgram, IProgramModule, ModuleType } from '@u4i/modules/Admin/modules/Programs/interfaces';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import intlMessages from '@u4i/modules/LandingPage/state/sections/ProgramLearnersViewMain/intlMessages';
import { LabelSvg } from '../GeneralTextLabelElementsSVG/LabelSvg';

interface IProps extends WrappedComponentProps {
  expandedPhase: any
  phasesCount: number
  programById: IProgram | any
  toggleContent: boolean
  toggleDetails: boolean
  xOfStart: number
  yAxis: number
  enrollUserToCourse: (courseSlug: string, programId: string) => any;
  hideModalDetails: () => void
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void
  toggleModuleDetails: () => void
  updateProgramClpUrl: (moduleId?: string) => void
}

export const PhaseModules = injectIntl((props: IProps) => {
  const {formatMessage} = props.intl;
  const durationMins = formatMessage(intlMessages.durationMins);

  const {
    expandedPhase,
    phasesCount,
    programById,
    toggleContent,
    toggleDetails,
    xOfStart,
    yAxis,
    enrollUserToCourse,
    hideModalDetails,
    onModuleHover,
    toggleModuleDetails,
    updateProgramClpUrl,
  } = props;

  const {
    CXOfPhaseIcon,
    CYOfPhaseIcon,
    order,
    phaseModules
  } = expandedPhase;

  const verticalDirection: string = `v`;
  const lineLengthV: number = 45;
  const moduleIconRadius: number = 35;
  const circleIconStep: number = lineLengthV + moduleIconRadius;

  const pathModulesPairsCalc = (): any[] => {
    let pathModulesPairsList: any[] = [];
    phaseModules.forEach((mod: IProgramModule, index: number) => {
      let pathModulePairObject: any = {
        resourceUrl: mod.resource_url,
        resourceType: mod.resource_type,
        direction: verticalDirection,
        duration: mod.duration,
        index: index,
        id: mod.id,
        lineLengthV: lineLengthV,
        moduleType: mod.module_type,
        order: mod.order,
        isCompleted: mod.progress == 1 ? true : false,
        required: mod.required,
        title: mod.title
      };
      pathModulesPairsList.push(pathModulePairObject);
    });
    return pathModulesPairsList;
  }

  const mapModules = (): JSX.Element[] => {
    return pathModulesPairsCalc().map(module => mapIconByModuleType(module));
  }

  const showDetailsBox = (): JSX.Element[] => {
    return pathModulesPairsCalc().map(textBox => mapModuleDetailsPosition(textBox));
  }

  const mapModuleDetailsPosition = (module: any): JSX.Element => {
    const startYd: number = (cyOfModule(module.index));
    return (<ModuleInfoLabel title={module.title} x={CXOfPhaseIcon + 42 } y={startYd + 1} key={module.order + 100} />);
  }

  const durationLabelPosition = (duration: number): number => {
    const durationPosition: number = Math.round(duration/60) < 10 ? CXOfPhaseIcon - 86 : Math.round(duration/60) < 100 ? CXOfPhaseIcon - 92 : CXOfPhaseIcon - 98;
    return durationPosition;
  }

  const durationLabelText = (duration: number): string => {
    return `${Math.round(duration/60)} ${durationMins}`;
  }

  const cyOfModule = (modIndex: number, isText: boolean = false): number => {
    if(!isText)
      return CYOfPhaseIcon + (circleIconStep * (modIndex + 1)) + (moduleIconRadius * modIndex);
    else
      return CYOfPhaseIcon + 4 + (circleIconStep * (modIndex + 1)) + (moduleIconRadius * modIndex);
  }

  const yOfPathLine = (pathModIndex: number): number => {
    return CYOfPhaseIcon + (circleIconStep * pathModIndex) + (moduleIconRadius * pathModIndex);
  }

  const showModuleContent = (resourceUrl: string, resourceType: string, moduleId: string): void => {
    updateProgramClpUrl(moduleId);

    if(resourceType == 'skill') {
      enrollUserToCourse(resourceUrl, programById.id);
      AppNavigator.push(appRoutes.courseDetailPage, {skillSlug: resourceUrl});
    } else {
      AppNavigator.push(appRoutes.playerChapter, {chapterSlug: resourceUrl});
    }

    hideModalDetails();
  }

  const mapIconByModuleType = (module: any): JSX.Element => {
    switch (module.moduleType) {
      case ModuleType.LABS:
        return (
          <Fragment key={module.index}>
            <DigitalJupyterLabIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.ONLINE_COURSE:
        return (
          <Fragment key={module.index}>
            <OnlineCourseChapterIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.ONLINE_DISCUSSION:
        return (
          <Fragment key={module.index}>
            <OnlineDiscussionIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.SELF_REFLECTION:
        return (
          <Fragment key={module.index}>
            <SelfReflectionIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.GROUP_DISCUSSION:
        return (
          <Fragment key={module.index}>
            <GroupDiscussionIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.USE_CASES:
        return (
          <Fragment key={module.index}>
            <UseCasesIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.LIVE_EVENT:
        return (
          <Fragment key={module.index}>
            <LiveEventIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.INTERACTIVE_TASK:
        return (
          <Fragment key={module.index}>
            <InteractiveTaskIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      case ModuleType.PRACTICE_EXERCISE:
        return (
          <Fragment key={module.index}>
            <PracticeExerciseIcon
              cx={CXOfPhaseIcon}
              cy={cyOfModule(module.index)}
              isCompleted={module.isCompleted}
              onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
              radius={moduleIconRadius}
              required={module.required}
              showModuleInVE={() => showModuleContent(module.resourceUrl, module.resourceType, module.id)}
              title={module.title}
              key={module.order}
            />
            <LabelSvg
              x={durationLabelPosition(module.duration)}
              y={cyOfModule(module.index, true)}
              text={durationLabelText(module.duration)}
              key={`${module.order}${module.index}`}
            />
          </Fragment>
        );
      default:
        return <></>;
    }
  }

  return (
    <svg>
      { mapModules() }

      { toggleDetails && showDetailsBox() }

      { pathModulesPairsCalc().map(pathMod =>
        <PathLine
          direction={pathMod.direction}
          key={pathMod.index + 1000}
          length={pathMod.lineLengthV}
          startX={CXOfPhaseIcon}
          startY={yOfPathLine(pathMod.index)}
          strokeColor={mainBlue}
        />)
      }

      { !toggleContent &&
        <>
          { toggleDetails && (
            <>
              <ToggleOn
                onToggleClick={toggleModuleDetails}
                x={xOfStart + 32}
                y={yAxis - 70}
              />
              <LabelSvg
                x={xOfStart + 20}
                y={yAxis - 30}
                text="Details"
              />
            </>
          )}

          { !toggleDetails && (
            <>
              <ToggleOff
                onToggleClick={toggleModuleDetails}
                x={xOfStart + 32}
                y={yAxis - 70}
              />
              <LabelSvg
                x={xOfStart + 20}
                y={yAxis - 30}
                text="Details"
              />
            </>
          )}
        </>
      }
    </svg>
  )
})
