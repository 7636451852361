import cx from 'classnames';
import React from 'react';
import {action, observable,makeObservable, computed} from 'mobx';
import { DiscussAnswerPost } from '../../state/DiscussPageStore';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import DiscussAnswerForm from '@u4i/common/forms/DiscussAnswerForm/DiscussAnswerForm';
import { UserAnswersStore } from '../../state/UserAnswersStore';
import intlMessages from './intlMessages';
import './_discuss-page-answer-form.scss';

interface IPropTypes {
  answersCount: number
  currentUserAnswersCount: number
  isAnonymityChecked: boolean
  showAnonymitySettingsSwitch: boolean
  userAnswersStore: UserAnswersStore
  onAnswerSubmit: (values: DiscussAnswerPost) => Promise<void>
  onCloseSuccessMessage: () => void
}

const DiscussPageAnswerForm = observer(class DiscussPageAnswerForm extends React.Component<IPropTypes> {
  public isInputFocused: boolean = false;
  public isPageScrolled: boolean = false;
  private formSectionWrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<DiscussPageAnswerForm, "isInputFocused" | "isPageScrolled">(this, {
      isInputFocused: observable,
      isPageScrolled: observable,
      shouldFormMinimize: computed,
      focusAnswerForm: action,
      handleAnswerSubmit: action.bound,
      minimizeDiscussAnswerForm: action.bound
    });
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
    this.isPageScrolled = false;
  }

  componenWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
  }

  get shouldFormMinimize() {
    return (this.isPageScrolled && !this.isInputFocused && (this.props.answersCount > 2 || this.props.currentUserAnswersCount > 0))
      || (this.isPageScrolled && this.props.userAnswersStore.submitSucceeded && (this.props.answersCount > 2 || this.props.currentUserAnswersCount > 0));
  }  

  focusAnswerForm = () => {
    this.isInputFocused = true;
  };

  minimizeDiscussAnswerForm = (answerFieldDistanceFromTop: number) => {
    const scrollTopPosition: number = window.scrollY || window.pageYOffset;

    if (scrollTopPosition !== 0 && answerFieldDistanceFromTop <= scrollTopPosition + 70 && (this.props.answersCount > 2 || this.props.currentUserAnswersCount > 0)) {
      this.isPageScrolled = true;
    } else if (this.isPageScrolled) {
      this.isPageScrolled = false;
    }
  };

  handleDocumentClick = (event) => {
    const formSectionWrapper = this.formSectionWrapperRef.current;

    if (formSectionWrapper && !formSectionWrapper.contains(event.target)) {
      this.isInputFocused = false;
    }
  };

  handleAnswerSubmit = async (values: DiscussAnswerPost) => {
    this.props.onAnswerSubmit(values)
  }

  render() {
    return (
      <section className="discuss-page__form-section">
        <div
          className={cx({
            'discuss-page__form-section__height-setter': true,
            'discuss-page__form-section__height-setter--maximized': this.isPageScrolled,
          })}
        />

        <div
          className={cx({
            'discuss-page__form-section__wrapper': true,
            'discuss-page__form-section__wrapper--minimized': this.isPageScrolled,
          })}
          ref={this.formSectionWrapperRef}
        >
          <div className="discuss-page__form-section__wrapper-inner">
            <p className="discuss-page__form-section__paragraph">
              <FormattedMessage {...intlMessages.paragraph} values={{strong: (...chunks) => <strong>{chunks}</strong> }} />
            </p>

            <div className="discuss-page__form-section__form">
              <DiscussAnswerForm
                handleSubmit={this.props.onAnswerSubmit}
                handleCloseSuccessMessage={this.props.onCloseSuccessMessage}
                isPageScrolled={this.isPageScrolled}
                isAnonymityChecked={this.props.isAnonymityChecked}
                onInputFocus={this.focusAnswerForm}
                onScroll={this.minimizeDiscussAnswerForm}
                showAnonymitySettingsSwitch={this.props.showAnonymitySettingsSwitch}
                shouldFormMinimize={this.shouldFormMinimize}
                submitSucceeded={this.props.userAnswersStore.submitSucceeded}
                submitting={this.props.userAnswersStore.submitting}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
});

const WrappedComponent = DiscussPageAnswerForm;

export {WrappedComponent as DiscussPageAnswerForm};
