import { mainBlue, white } from "@u4i/styles/styles-variables";
import * as React from "react";
import { opacity7 } from "../../constants/Constants";
import { mapModuleStyling } from "../../constants/StaticMethods";

interface IPropTypes {
  cx: number
  cy: number
  isCompleted: boolean
  radius: number
  required: boolean
  title: string
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void 
  showModuleInVE: () => void
}

export const InteractiveTaskIcon = (props: IPropTypes) => {
  const {
    cx,
    cy,
    isCompleted,
    radius,
    required,
    title,
    onModuleHover,
    showModuleInVE
  } = props;

  const [opacity, setOpacity] = React.useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  const moduleHover = (mouseEnter: boolean) => {
    onModuleHover(cx, cy, title, mouseEnter);
  }

  return (
    <svg>
      <g
        onClick={showModuleInVE}
        onMouseEnter={() => { handleOpacity(); moduleHover(true); }}
        onMouseLeave={() => { handleOpacity(); moduleHover(false); }}
        style={{ cursor: "pointer", ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          cx={cx}
          cy={cy}
          r={isCompleted ? radius : radius-3}
          style={mapModuleStyling(isCompleted)}
        >
        </circle>
        <g fill={isCompleted ? white : mainBlue}>
          <path
            d={`m ${cx+13},${cy+8.5} h -25.5 v -16.5 h 25.5 z m 0.75,-18.75 h -27 c -0.825,0 -1.5,0.675 -1.5,1.5 v 18 c 0,0.825 0.675,1.5 1.5,1.5 h 10.499999 v 2.25 h -3.749999 v 2.25 h 13.5 v -2.25 h -3.749999 v -2.25 h 10.499999 c 0.825,0 1.5,-0.675 1.5,-1.5 v -18 c 0,-0.825 -0.675,-1.5 -1.5,-1.5 z`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx+13},${cy+6} -4.331456,-4.329312 3.816828,-1.374486 c 0.195987,-0.06736 0.300286,-0.280838 0.232912,-0.476825 -0.03759,-0.109402 -0.123532,-0.195345 -0.232912,-0.232934 l -10.998039,-3.666728 c -0.03944,-0.01299 -0.08071,-0.01952 -0.122224,-0.0193 v 0 c -0.206023,0.0044 -0.369439,0.174994 -0.365021,0.381018 8.1e-4,0.03819 0.0075,0.07602 0.01979,0.112167 l 3.65815,11.008761 c 0.06905,0.195408 0.283432,0.297819 0.478841,0.228773 0.106914,-0.03778 0.190992,-0.121859 0.228774,-0.228773 l 1.376631,-3.821116 4.327168,4.327166 z`}
            style={{strokeWidth: 0.7}}
          />
        </g>
      </g>
    </svg>
  );
}
