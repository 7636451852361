import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { IQQQVideoPlayerConfig } from "./QQQPlayerInterfaces";
import { htmlTemplate } from "./QQQTemplate";

interface IPropTypes {
  containerId: string;
  dataId: string;
  playedStatement: () => void
  pausedStatement: () => void
  completedStatement: () => void
  //QQQ player Events
  onControllBarChange?: (event: Event) => void;
  onFullscreenChange?: (event: Event) => void;
  onPauseRequest?: (event: Event) => void;
  onPlayRequest?: (event: Event) => void;
  onSeekRequest?: (event: Event) => void;

  // Media Events
  onLoading?: (event: Event) => void;
  onPaused?: (event: Event) => void;
  onPlaying?: (event: Event) => void;
  onReady?: (event: Event) => void;
  onTimeChange?: (event: Event) => void;
  onVolumeChange?: (event: Event) => void;
}

const QQQVideoPlayer: React.FC<IPropTypes|any> = forwardRef(
  ({ containerId, dataId, playedStatement, pausedStatement, completedStatement, onReady, hasExtraElements }, ref) => {
    const [player, setPlayer] = useState<any>(null);

    const configs: IQQQVideoPlayerConfig = {
      container: containerId,
      controlBarAutoHide: false,
      dataid: dataId,
      layout: "default",
      width: "100%",
      height: "100%",
      template: {
        html: htmlTemplate,
        css:
          `#${containerId}-play-button-overlay-span { color: #fff !important; font-size: 80px !important;background: #0084d7;border-radius: 10px;box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; }\n` +
          /**
           * Expands native overlay to video width/height and center button
           */
          `#${containerId}-play-button-overlay { width: 100%; height: 100%; }\n` +
          `#${containerId}-flex-center-wrapper { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }\n` +
          `.sdn-time-loaded { transition: width 0.5s linear; height: 15px; }\n` +
          `.sdn-time-loaded-pointer { height: 30px; width: 30px; top: -8px; border-radius: 50%; }\n` +
          `.sdn-thumbnails { bottom: 110px !important; }\n` +
          `.sdn-time-scrubber > .sdn-seekbar-chapter:not(:first-child) { background: white; background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 4px, rgba(255,255,255,0.2) 4px, rgba(255,255,255,0.2) 100%); }\n` +
          `.sdn-time-scrubber > .sdn-seekbar-chapter:not(:first-child):hover { background: white; background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 4px, rgba(255,255,255,0.6) 4px, rgba(255,255,255,0.6) 100%); }\n` +
          `.sdn-time-seekbar { height: 14px; top: 0; transform: matrix(1, 0, 0, 0.5, 0, -3.5); } \n` +
          `.sdn-player-controls { height: 52px; } \n` +
          `.sdnicbsun-fullscreen {margin-right:${hasExtraElements ? '40px' : '10px'}}`
      },
    };

    useImperativeHandle(ref, () => ({
      pause: () => player?.pause(),
    }));

    const [mediaCompleted, setMediaCompleted] = useState<boolean>(false);

    useEffect(() => {
      const playerInstance = new window.js3q(configs);

      const completedListener = () => {
        if (!mediaCompleted) {
          completedStatement();
          setMediaCompleted(true);
        }
      };

      const pausedListener = () => {
        if (!mediaCompleted) {
          pausedStatement();
        }
      };

      const playerListeners = {
        "media.complete": completedListener,
        "media.ready": onReady,
        "media.playing": playedStatement,
        "media.paused": pausedListener,
      };

      Object.entries(playerListeners).forEach(([event, listener]) => {
        playerInstance.on(event, listener);
      });

      return () => {
        Object.entries(playerListeners).forEach(([event, listener]) => {
          playerInstance.off(event, listener);
        });
        playerInstance.destroy();
      };
    }, [mediaCompleted]);

    return <div id={containerId}></div>;
  }
);

export default QQQVideoPlayer;
