import {defineMessages} from 'react-intl';

export default defineMessages({
  enterCoupon: {
    defaultMessage: 'Enter coupon',
    id: 'Player.ElementManager.NoGuestPermissionElement.EnterCoupon',
  },
  login: {
    defaultMessage: 'Login',
    id: 'Player.ElementManager.NoGuestPermissionElement.Login',
  },
  message: {
    defaultMessage: 'Extend your skills! Register now to get access to the comprehensive'
      + ' content and functionalities of University4Industry.',
    id: 'Player.ElementManager.NoGuestPermissionElement.Message',
  },
  register: {
    defaultMessage: 'Register',
    id: 'Player.ElementManager.NoGuestPermissionElement.Register',
  },
  subheading: {
    defaultMessage: 'This content is only offered for registered users.',
    id: 'Player.ElementManager.NoGuestPermissionElement.Subheading',
  },
});
