import cx from "classnames";
import DOMPurify from "dompurify";
import React, { Component } from "react";
import { action, computed, observable, makeObservable } from "mobx";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "@reach/router";
import CircularProgress from "@u4i/common/CircularProgress";
import AppNavigator from "@u4i/common/AppNavigator";
import UserStore from "@u4i/state/UserStore";
import { Alert } from "@u4i/common/Alert";
import { AlertStatusEnum } from "@u4i/common/enums/AlertStatusEnum";
import { ExerciseForm } from "@u4i/reactiveForms/forms/ExerciseForm";
import { IRootStore } from "@u4i/state/RootStore";
import { IStorage } from "@u4i/state/ServicesInterfaces";
import { UserRolesEnum } from "@u4i/common/enums/UserRolesEnum";
import { BackButton } from "./components/BackButton";
import { ExercisesStore } from "./state/ExercisesStore";
import { SubmissionOverlay } from "./components/SubmissionOverlay";

import intlMessages from "./intlMessages";
import "./_exercises.scss";

interface IMatchParams extends RouteComponentProps {
  match: any;
}

interface IPropTypes extends IMatchParams {
  rootStore: IRootStore;
}

export const Exercises = inject("rootStore")(
  observer(
    class Exercises extends Component<IPropTypes> {
      private showExercisesMessage: boolean;
      private exercisesStore: ExercisesStore;
      private storageService: IStorage;
      private userStore: UserStore;

      constructor(props: IPropTypes) {
        super(props);

        makeObservable<Exercises, "showExercisesMessage">(this, {
          showExercisesMessage: observable,
          exerciseId: computed,
          showExcercisesMessage: computed,
          sanitizedInstructions: computed,
          handleCloseMessage: action.bound,
        });

        const { exercisesStore, storageService, userStore } =
          this.props.rootStore;
        this.exercisesStore = exercisesStore;
        this.storageService = storageService;
        this.userStore = userStore;
      }

      componentDidMount() {
        const showExercisesGuideMessage =
          this.storageService.showExercisesGuideMessage.get();
        this.exercisesStore.init(this.exerciseId);

        if (showExercisesGuideMessage === null) {
          this.storageService.showExercisesGuideMessage.set(true);
          this.showExercisesMessage = true;
        } else {
          this.showExercisesMessage = showExercisesGuideMessage;
        }
      }

      componentWillUnmount() {
        this.exercisesStore.reset();
      }

      get exerciseId() {
        return this.props.match.params.exerciseId;
      }

      get showExcercisesMessage() {
        const userRole = this.userStore.userData.role;
        const isGrader =
          userRole === UserRolesEnum.GRADER_ADMIN ||
          userRole === UserRolesEnum.GRADER_FACULTY;

        return !isGrader && this.showExercisesMessage;
      }

      get sanitizedInstructions() {
        if (!this.exercisesStore.exerciseData) {
          return "";
        }

        return DOMPurify.sanitize(
          this.exercisesStore.exerciseData.instructions,
          { ADD_ATTR: ["target"] }
        );
      }

      handleCloseMessage = () => {
        if (this.showExercisesMessage) {
          this.storageService.showExercisesGuideMessage.set(false);
          this.showExercisesMessage = false;
        }
      };

      render() {
        if (this.exercisesStore.permissionError) {
          return (
            <div className="exercises__permission-error">
              <Alert
                heading="Oops!"
                message="You are not authorized to view this exercise"
                type={AlertStatusEnum.ERROR}
              />
            </div>
          );
        }

        if (this.exercisesStore.attemptCreationFailed) {
          return <FormattedMessage {...intlMessages.attemptCreationFailed} />;
        }

        if (
          this.exercisesStore.fetching ||
          !this.exercisesStore.exerciseAttemptId
        ) {
          return <CircularProgress />;
        }

        const { exercisesStore } = this;
        const { exerciseData } = exercisesStore;

        if (!exerciseData) {
          return null;
        }

        return (
          <div className="exercises">
            <div
              className={cx({
                exercises__dimmer: true,
                "exercises__dimmer--visible":
                  exercisesStore.submitting || exercisesStore.submissionSuccess,
              })}
            />

            <BackButton onClick={AppNavigator.goBack} />

            <div className="container">
              {exerciseData.questions.length === 0 ? (
                <div className="alert-wrapper">
                  <Alert
                    message={
                      <FormattedMessage
                        {...intlMessages.warningMessageQuestions}
                      />
                    }
                    type={AlertStatusEnum.WARNING}
                  />
                </div>
              ) : (
                <>
                  <h3 className="exercises__title">{exerciseData.title}</h3>

                  <div
                    className="exercises__instructions"
                    dangerouslySetInnerHTML={{
                      __html: this.sanitizedInstructions,
                    }}
                  />

                  {this.showExcercisesMessage && (
                    <div className="exercises__message">
                      <Alert
                        message={
                          <FormattedMessage
                            {...intlMessages.introMessage}
                            values={{ br: <br /> }}
                          />
                        }
                        onCloseButtonClick={this.handleCloseMessage}
                        showCloseButton
                        type={AlertStatusEnum.INFO}
                      />
                    </div>
                  )}

                  <div className="exercises__form-wrapper">
                    <ExerciseForm
                      answers={exercisesStore.answers}
                      onSubmit={exercisesStore.submitAttempt}
                      status={exercisesStore.attemptStatus}
                      questions={exerciseData.questions}
                    />
                    <SubmissionOverlay
                      submissionProgress={exercisesStore.submissionProgress}
                      submissionProgressTotal={
                        exercisesStore.submissionProgressTotal
                      }
                      submissionSuccess={exercisesStore.submissionSuccess}
                      submitting={exercisesStore.submitting}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }
    }
  )
);
