import React, { FC, MouseEvent, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "@reach/router";
import { withRouter } from "react-router-dom";
import intlMessages from "./intlMessages";
import { ThemeContext } from "@u4i/utils/ThemeContext";
import ThemeToggle from "@u4i/common/ThemeToggle";
import "./_access-buttons.scss";

interface IPropTypes extends RouteComponentProps {
  location: any,
  onLoginClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onRegisterClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const AccessButtons: FC<IPropTypes | any> = (props) => {
  const {
    location,
    onLoginClick,
    onRegisterClick
  } = props;

  const { theme, handleThemeSwitch }: any = useContext(ThemeContext);
  return (
    <div className="main-layout__access-buttons">
      <div
        style={{
          marginTop: 5,
          marginRight: 20
        }}
      >
        <ThemeToggle onSwitch={handleThemeSwitch} theme={theme} />
      </div>
      {location?.pathname !== "/login" && (
        <div className="main-layout__access-buttons__button-wrap btn-login">
          <button
            className="main-layout__access-buttons__button"
            onClick={onLoginClick}
            type="button"
          >
            <FormattedMessage {...intlMessages.login} />
          </button>
        </div>
      )}

      {location?.pathname !== "/register" && (
        <div className="main-layout__access-buttons__button-wrap">
          <button
            className="main-layout__access-buttons__button main-layout__access-buttons__button--primary"
            onClick={onRegisterClick}
            type="button"
          >
            <FormattedMessage {...intlMessages.register} />
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(AccessButtons);
