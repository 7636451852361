import React from 'react';
import {computed,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {appRoutes} from '@u4i/common/AppNavigator';
import {PLAYER_DISCUSS_QUESTION_TRIMMING_THRESHOLD} from '@u4i/constantSettings';
import intlMessages from './intlMessages';
import { withCalculatedFontSize } from '@u4i/modules/VirtualExperience/components/withCalculatedFontSize';
import { postStatement } from '@u4i/common/TraxLrs';
import { XAPIVerbs } from '@u4i/common/enums/XApiEnum';
import './_discuss-element.scss';

interface IPropTypes {
  calculatedFontSize: number;
  questionContent: string;
  questionSlug: string;
  title: string;
  elementId: string
}

const DiscussElement = observer(class DiscussElement extends React.Component<IPropTypes> {
  private HOME_ROUTE: string;

  constructor(props) {
    super(props);

    makeObservable(this, {
      trimmedQuestionContent: computed
    });

    this.HOME_ROUTE = appRoutes.discuss.parse({questionSlug: props.questionSlug});
  }

  componentDidMount(): void {
    postStatement(XAPIVerbs.experienced, 'element', this.props.elementId, this.props.title);
  }

  get trimmedQuestionContent() {
    if (this.props.questionContent.length > PLAYER_DISCUSS_QUESTION_TRIMMING_THRESHOLD) {
      return `${this.props.questionContent.substring(0, PLAYER_DISCUSS_QUESTION_TRIMMING_THRESHOLD)}...`;
    }

    return this.props.questionContent;
  }

  render() {
    return (
      <div className="player__discuss-element" style={{fontSize: this.props.calculatedFontSize}}>
        <div className="player__discuss-element__wrapper">
          <h3 className="player__discuss-element__title">{this.props.title}</h3>

          <p className="player__discuss-element__content">{this.trimmedQuestionContent}</p>

          <Link className="player__discuss-element__button" to={this.HOME_ROUTE}>
            <FormattedMessage {...intlMessages.buttonLabel} />
          </Link>
        </div>
      </div>
    );
  }
});

const WrappedComponent = withCalculatedFontSize(DiscussElement);
export {WrappedComponent as DiscussElement};
