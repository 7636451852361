import cx from 'classnames';
import CodeIcon from '@material-ui/icons/Code';
import DOMPurfy from 'dompurify';
import FontAwesome from 'react-fontawesome';
import GetAppIcon from '@material-ui/icons/GetApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import React, { Component } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import { RouteComponentProps } from '@reach/router';

import CircularProgress from '@u4i/common/CircularProgress';
import {AttemptGradingForm} from '@u4i/reactiveForms/forms/AttemptGradingForm';
import {ISolutionFile} from '@u4i/parsers/ChallengeAttemptParser';
import {IRootStore} from '@u4i/state/RootStore';
import {AttemptEvaluationData} from './components/AttemptEvaluationData';
import {AttemptEvaluationErrorMessage} from './components/AttemptEvaluationErrorMessage';
import {GradingEvaluationStore} from './state/GradingEvaluationStore';
import {AttemptEvaluationSubmissionOverlay} from './components/AttemptEvaluationSubmissionOverlay';

import intlMessages from './intlMessages';
import './_attempt-evaluation.scss';

interface IMatchParams extends RouteComponentProps {
  match: any;
}

interface IPropTypes extends IMatchParams {
  rootStore: IRootStore;
}

export const AttemptEvaluation = inject('rootStore')(observer(class AttemptEvaluation extends Component<IPropTypes> {
  private gradingEvaluationStore: GradingEvaluationStore;

  constructor(props: IPropTypes) {
    super(props);

    this.gradingEvaluationStore = this.props.rootStore.gradingEvaluationStore;
  }

  componentDidMount() {
    this.gradingEvaluationStore.init(this.attemptId);
  }

  componentWillUnmount() {
    this.gradingEvaluationStore.reset();
  }

  get attemptId() {
    return this.props.match.params.attemptId;
  }

  get sanitizedContextInfo() {
    if (!this.gradingEvaluationStore.attemptData) {
      return '';
    }

    return DOMPurfy.sanitize(this.gradingEvaluationStore.attemptData.contextInfo, {ADD_ATTR: ['target']});
  }

  get sanitizedStudentInstructions() {
    if (!this.gradingEvaluationStore.attemptData) {
      return '';
    }

    return DOMPurfy.sanitize(this.gradingEvaluationStore.attemptData.studentInstructions, {ADD_ATTR: ['target']});
  }

  get solutionFilesList() {
    if (!this.gradingEvaluationStore.attemptData || !this.gradingEvaluationStore.attemptData.solutionFiles) {
      return [];
    }

    return this.gradingEvaluationStore.attemptData.solutionFiles;
  }

  render() {
    if (this.gradingEvaluationStore.fetching) {
      return <CircularProgress />;
    }

    const {gradingEvaluationStore} = this;
    const {attemptData} = gradingEvaluationStore;

    if (this.gradingEvaluationStore.errorLoadingAttemptData) {
      return (
        <AttemptEvaluationErrorMessage
          icon={<FontAwesome name="exclamation-triangle" />}
          message={<FormattedMessage {...intlMessages.incorrectId} />}
        />
      );
    }

    if (!attemptData) {
      return null;
    }

    if (!attemptData.canGrade) {
      return (
        <AttemptEvaluationErrorMessage
          icon={<FontAwesome name="clipboard-check" />}
          message={<FormattedMessage {...intlMessages.attemptEvaluated} />}
        />
      );
    }

    return (
      <div className="customer-grading__attempt-evaluation">
        <div
          className={cx({
            'customer-grading__attempt-evaluation__dimmer': true,
            'customer-grading__attempt-evaluation__dimmer--visible': gradingEvaluationStore.submitting || gradingEvaluationStore.submissionSuccess,
          })}
        />

        <div className="container">
          <div className="customer-grading__attempt-evaluation__header">
            <h3 className="customer-grading__attempt-evaluation__challenge-name">
              {attemptData.challengeName}
            </h3>

            <AttemptEvaluationData
              date={attemptData.date}
              groupName={attemptData.groupName}
              userName={attemptData.userName}
            />
          </div>

          {attemptData.exampleSolutionFile &&
            <a
              className="customer-grading__attempt-evaluation__example-solution-button"
              href={attemptData.exampleSolutionFile.url}
              download={attemptData.exampleSolutionFile.fileName}
              title={attemptData.exampleSolutionFile.fileName}
            >
              <span className="customer-grading__attempt-evaluation__example-solution-icon">
                <FontAwesome name="download" />
              </span>

              <FormattedMessage {...intlMessages.downloadExample} />
            </a>
          }

          {this.sanitizedContextInfo &&
            <div className="customer-grading__attempt-evaluation__context-wrapper">
              <h4 className="customer-grading__attempt-evaluation__context-heading">
                <FormattedMessage {...intlMessages.context} />
              </h4>

              <div
                className="customer-grading__attempt-evaluation__context"
                dangerouslySetInnerHTML={{__html: this.sanitizedContextInfo}}
              />
            </div>
          }

          {this.sanitizedStudentInstructions &&
            <div className="customer-grading__attempt-evaluation__instructions-wrapper">
              <h4 className="customer-grading__attempt-evaluation__instructions-heading">
                <FormattedMessage {...intlMessages.instructions} />
              </h4>

              <div
                className="customer-grading__attempt-evaluation__instructions"
                dangerouslySetInnerHTML={{__html: this.sanitizedStudentInstructions}}
              />
            </div>
          }

          <div className="customer-grading__attempt-evaluation__solution-files-wrapper">
              <h4 className="customer-grading__attempt-evaluation__solution-files-wrapper-heading">
                <FormattedMessage {...intlMessages.solutionFiles} />
              </h4>

              <div
                className="customer-grading__attempt-evaluation__solution-files-wrapper"
              >

                {this.solutionFilesList.length > 0 &&
                  <List component="nav" aria-label="main mailbox folders">
                    {this.solutionFilesList.map((file: ISolutionFile, index: number) => (
                      <ListItem className="customer-grading__attempt-evaluation__solution-file">
                        <ListItemIcon className="customer-grading__attempt-evaluation__list-icon">
                          <CodeIcon />
                        </ListItemIcon>

                        <span className="customer-grading__attempt-evaluation__file-name">{file.name}</span>

                        <ListItemSecondaryAction>

                          <div className="customer-grading__attempt-evaluation__file-wrapper">
                            {file.previewUrl !== null &&
                              <a
                                className="customer-grading__attempt-evaluation__view-file"
                                href={file.previewUrl}
                                target="_blank"
                              >
                                <VisibilityIcon/>

                                <FormattedMessage {...intlMessages.view} />
                              </a>
                            }

                            <a
                              className="customer-grading__attempt-evaluation__download-file"
                              download={file.name}
                              href={file.downloadUrl}
                            >
                              <GetAppIcon />

                              <FormattedMessage {...intlMessages.download} />
                            </a>
                          </div>

                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                }
              </div>
            </div>

          <div className="customer-grading__attempt-evaluation__form-wrapper">
            <AttemptGradingForm
              editMode={false}
              onSubmit={this.gradingEvaluationStore.submitGrading}
              questions={attemptData.questions}
            />

            <AttemptEvaluationSubmissionOverlay
              submissionSuccess={gradingEvaluationStore.submissionSuccess}
              submitting={gradingEvaluationStore.submitting}
            />
          </div>
        </div>
      </div>
    );
  }
}));
