import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_answer-action-bar.scss';

interface IProps {
  commentModeEnabled: boolean
  comments: number
  fetchingComments: boolean
  isLiked: boolean
  likes: number
  showComments: boolean
  teaserMode: boolean
  togglingLike: boolean  
  onCommentClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onCommentsClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onLikesClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const AnswerActionBar: FC<IProps> = (props: IProps) => {
  const {
    commentModeEnabled,
    comments,
    fetchingComments,
    isLiked,
    likes,
    showComments,
    teaserMode,
    togglingLike,
    onCommentClick,
    onCommentsClick,
    onLikesClick
  } = props;

  return (
    <ul className="list-unstyled discuss__answer-action-bar">
      <li className="discuss__answer-action-bar__entry">
        <button
          className={cx({
            'discuss__answer-action-bar__statistic': true,
            'discuss__answer-action-bar__statistic--active': isLiked,
            'discuss__answer-action-bar__statistic--animating': togglingLike,
          })}
          disabled={togglingLike}
          onClick={onLikesClick}
          type="button"
        >
          <FontAwesome className="discuss__answer-action-bar__icon" name="thumbs-up" />

          {likes}
        </button>
      </li>

      {!teaserMode && 
        <>
          <li className="discuss__answer-action-bar__entry">
            <button
              className={cx({
                'discuss__answer-action-bar__statistic': true,
                'discuss__answer-action-bar__statistic--active': showComments,
                'discuss__answer-action-bar__statistic--animating': fetchingComments,
                'discuss__answer-action-bar__statistic--muted': comments === 0,
              })}
              disabled={comments === 0 || fetchingComments}
              onClick={onCommentsClick}
              type="button"
            >
              <FontAwesome className="discuss__answer-action-bar__icon" name="comments" />

              {comments}
            </button>
          </li>

          <li className="discuss__answer-action-bar__entry">
            <button
              className={cx({
                'discuss__answer-action-bar__action': true,
                'discuss__answer-action-bar__action--active': commentModeEnabled,
              })}
              onClick={onCommentClick}
              type="button"
            >
              <FormattedMessage {...intlMessages.comment} />
            </button>
          </li>
        </>
      }
    </ul>
  )  
};
