import {defineMessages} from 'react-intl';

export default defineMessages({
  priceDiscounted: {
    defaultMessage: 'Participation fee {priceDiscount}{space}<s>{priceNet}</s>{space}<small>excl. VAT</small>',
    id: 'CourseDetailPage.MenuBar.PriceLabel.PriceDiscounted',
  },
  priceNormal: {
    defaultMessage: 'Participation fee {priceNet}{space}<small>excl. VAT</small>',
    id: 'CourseDetailPage.MenuBar.PriceLabel.PriceNormal',
  },
});
