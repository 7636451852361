import React, { FC } from "react";
import DOMPurify from 'dompurify';
import { FormattedMessage } from "react-intl";
import intlMessages from './intlMessages';
import { Modal } from "antd";
import './_redeem_terms-of-service-modal.scss';

interface IProps {
  content?: string
  isVisible: boolean
  handleModalAccept: () => void
  handleModalClose: () => void
}

export const RedeemTermsOfServiceModal: FC<IProps> = (props: IProps) => {
  const {
    content,
    isVisible,
    handleModalAccept,
    handleModalClose
  } = props;

  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <Modal
      centered
      destroyOnClose={true}
      footer={null}
      mask={true}
      maskClosable={false}
      okButtonProps={<></>}
      onCancel={handleModalClose}
      open={isVisible}
      wrapClassName='unscrollable'
    >
      <div
        className="redeem-terms-of-service-modal__content"
        dangerouslySetInnerHTML={{__html: sanitizedContent}}
      />

      <div className="redeem-terms-of-service-modal__buttons">
        <button
          className="redeem-terms-of-service-modal__button"
          onClick={handleModalAccept}
          type="button"
        >
          <span className="disable-button-invert">
          <FormattedMessage {...intlMessages.accept} />
          </span>
      
        </button>
      </div>
    </Modal>
  )
}
