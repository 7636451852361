import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import UserStore from '@u4i/state/UserStore';
import './_no-guest-permission-element.scss';
import { withCalculatedFontSize } from '@u4i/modules/VirtualExperience/components/withCalculatedFontSize';

interface IProps {
  calculatedFontSize?: number,
  title: string
  userStore: UserStore
}
const NoGuestPermissionElement: FC<IProps> = (props: IProps) => {
  const {
    loginModalHandle,
    registrationModalHandle
  } = props.userStore;

  const onClickRegister = () => {
    loginModalHandle(false);
    registrationModalHandle(true);
  }

  return (
    <div
      className="player__no-guest-permission-element"
      style={{fontSize: props.calculatedFontSize}}
    >
      <h3 className="player__no-guest-permission-element__title">
        <FontAwesome className="player__no-guest-permission-element__icon" name="user-lock" />

        {props.title}
      </h3>

      <p className="player__no-guest-permission-element__subheading">
        <FormattedMessage {...intlMessages.subheading} />
      </p>

      <p className="player__no-guest-permission-element__message">
        <FormattedMessage {...intlMessages.message} />
      </p>

      <div className="player__no-guest-permission-element__actions">
        <button
          className="player__no-guest-permission-element__action"
          onClick={() => loginModalHandle(true)}
        >
          <FormattedMessage {...intlMessages.login} />
        </button>

        <button
          className="player__no-guest-permission-element__action"
          onClick={onClickRegister}
        >
          <FormattedMessage {...intlMessages.register} />
        </button>
      </div>
    </div>
  );
}

export default withCalculatedFontSize(NoGuestPermissionElement);
