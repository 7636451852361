import React from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';

interface IPropTypes {
  formattedInHours: Date
  formattedInMinutes: Date
  hours: number
  minutes: number
}

export const DurationInHours: React.FC<IPropTypes> = (props) => {
  const {formattedInHours, formattedInMinutes, hours, minutes} = props;

  if (hours > 1) {
    if (minutes > 55 || minutes === 0) {
      return <FormattedMessage {...intlMessages.estimatedHours} values={{timeHours: formattedInHours}} />;
    } else if (minutes === 1) {
      return <FormattedMessage {...intlMessages.estimatedHoursAndMinute} values={{timeHours: formattedInHours, timeMinutes: formattedInMinutes}} />;
    }

    return <FormattedMessage {...intlMessages.estimatedHoursAndMinutes} values={{timeHours: formattedInHours, timeMinutes: formattedInMinutes}} />;
  } else if (hours === 1 && minutes > 1) {
    if (minutes > 55) {
      return <FormattedMessage {...intlMessages.estimatedHours} values={{timeHours: formattedInHours}} />;
    }

    return <FormattedMessage {...intlMessages.estimatedHourAndMinutes} values={{timeHours: formattedInHours, timeMinutes: formattedInMinutes}} />;
  } 

  return <FormattedMessage {...intlMessages.estimatedHourAndMinute} values={{timeHours: formattedInHours, timeMinutes: formattedInMinutes}} />;
};
