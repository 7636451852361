import React, { FC, useState } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import CircularProgress from '@u4i/common/CircularProgress';
import { Form } from 'antd';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import CustomInputField from '../components/TextField/CustomInputField';
import './_password-recovery-form.scss';

interface IProps {
  submitSucceeded: boolean
  handleSubmit: (data) => Promise<void>
}

export const PasswordRecoveryForm: FC<IProps> = (props: IProps) => {
  const {    
    submitSucceeded,
    handleSubmit
  } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  
  const onHandleSubmit = (values) => {
    const config = {
      email: "email"
    };

    setLoading(true);

    handleSubmit(values).then(() => {
      setLoading(false);
      form.resetFields();
    }).catch((error) => {
      setLoading(false);
      const { validationErrors } = error.response.data;
      form.setFields(
        Object.keys(validationErrors).map((key) => {
          return {
            name: config[key],
            errors: [validationErrors[key]],
          };
        })
      );
    });
  };


  if (loading) {
    return <CircularProgress />;
  }

  if (submitSucceeded) {
    return (
      <p className="password-recovery-form__submit-completed">
        <FormattedMessage {...intlMessages.submitCompleted} />
      </p>
    );
  }

  return (
    <div className='custom-form-fields'>
      <Form onFinish={onHandleSubmit} form={form}>
        <p className="password-recovery-form__hint">
          <FormattedMessage {...intlMessages.hint} values={{br: <br />}} />
        </p>

        <Form.Item
          name='email'
          rules={[
            {
              message: <FormattedMessage {...intlMessages.emailErrorsPresence} />,
              required: true,
              whitespace: true
            },
            {
              type: "email",
              message: <FormattedMessage {...intlMessages.emailErrorsPattern} />,
            }
          ]}
        >
          <CustomInputField
            label={<FormattedMessage {...intlMessages.emailLabel} />}
            type="text"
            name="email"
            form={form}
          />
        </Form.Item>

        <SubmitButton disabled={loading}>
          <FormattedMessage {...intlMessages.submitTitle} />
        </SubmitButton>
      </Form>
    </div>
  )
}
