import cx from 'classnames';
import React, { Component } from 'react';
import {action, observable,makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import { FormattedMessage } from "react-intl";
import intlMessages from './intlMessages';
import './_progress-indicator.scss';

interface IProps {
  completedChallengesCount: number
  feedbackSubmitted: boolean
  feedbackSurvey: object
  isFeedbackMandatory: boolean
  courseCompleted: boolean
  totalNumberOfChallenges: number
  totalNumberOfChapters: number
  viewedChapterCount: number
}

export const ProgressIndicator = observer(class ProgressIndicator extends Component<IProps> {
  private showProgress: boolean = false;
  private delayTimeout: null | ReturnType<typeof setTimeout> = null;

  constructor(props: IProps) {
    super(props);

    makeObservable<ProgressIndicator, "showProgress" | "delayTimeout">(this, {
      showProgress: observable,
      delayTimeout: observable,
      toggleProgressDisplay: action.bound
    });
  }

  componentDidMount() {
    this.delayTimeout = setTimeout(this.toggleProgressDisplay, 1000);
  }

  componentWillUnmount() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }
  }

  get areChallengesCompleted() {
    return this.props.completedChallengesCount/this.props.totalNumberOfChallenges === 1 ? true : false;
  }

  get isContentViewed() {
    return this.props.viewedChapterCount/this.props.totalNumberOfChapters === 1 ? true : false;
  }

  get progressPercentage() {
    return (this.props.completedChallengesCount / this.props.totalNumberOfChallenges) * 100;
  }

  get progressStatsForChallenges() {
    if(this.showProgress) {
      return `${this.props.completedChallengesCount}/${this.props.totalNumberOfChallenges}`;
    }
  }

  get progressStatsForChapters() {
    if(this.showProgress) {
      return `${this.props.viewedChapterCount}/${this.props.totalNumberOfChapters}`;
    }
  }

  get progressStatsForFeedback() {
    if(this.showProgress) {
      if (this.props.feedbackSubmitted && this.props.isFeedbackMandatory) {
        return `1/1`;
      } else {
        return `0/1`;
      }
    }
  }

  get calculatedCompletionWrapperHeightForChallenges() {
    if (this.props.totalNumberOfChallenges > 0) {
      if (this.showProgress) {
        return `${this.progressPercentage}%`;
      }
      else {
        return '0%';
      }
    }
    else {
      if(this.showProgress) {
        return `${this.viewedChaptersPercentage}%`;
      } else {
        return '0%';
      }
    }
  }

  get viewedChaptersPercentage() {
    return (this.props.viewedChapterCount/this.props.totalNumberOfChapters) * 100;
  }

  get calculatedCompletionWrapperHeightForFeedback() {
    if (this.props.feedbackSubmitted) {
      if (this.showProgress) {
        return '100%';
      }
    }

    return '0%';
  }

  toggleProgressDisplay() {
    this.showProgress = true;
  }

  render() {
    return (
      <>
        <div className="course-detail-page__progress-indicator">
          <div
            className={cx({
              "course-detail-page__progress-indicator__outline": true,
              "course-detail-page__progress-indicator__single-hexagon": !this.props.isFeedbackMandatory || (this.props.isFeedbackMandatory && !this.props.feedbackSurvey),
            })}
          >
            { this.props.totalNumberOfChallenges !== 0 ?
              <p className="course-detail-page__progress-indicator__hexagon-title">{this.props.totalNumberOfChallenges === 1 ? "Challenge" : "Challenges"}</p> :
              <p className="course-detail-page__progress-indicator__hexagon-title">
                <FormattedMessage
                  {...intlMessages.chapter}
                  values={{numberOfChapters: this.props.totalNumberOfChapters}}
                />
              </p>
            }

            <div
              className="course-detail-page__progress-indicator__completion-wrapper"
              style={{ height: "100%" }}
            >
              <div className="course-detail-page__progress-indicator__completion">
              {this.props.totalNumberOfChallenges === 0 && this.props.totalNumberOfChapters !== 0 ? this.progressStatsForChapters : this.progressStatsForChallenges}
              </div>
            </div>

            <div
              className="course-detail-page__progress-indicator__completion-wrapper"
              style={{
                height: this.calculatedCompletionWrapperHeightForChallenges,
              }}
            >
              {this.props.totalNumberOfChallenges !== 0 && (<div
                className={cx({
                  "course-detail-page__progress-indicator__completion": true,
                  "course-detail-page__progress-indicator__completion--fill": true,
                  "course-detail-page__progress-indicator__completion--fill-completed": this.areChallengesCompleted,
                })}
              >
                {this.progressStatsForChallenges}
              </div>)}

              {this.props.totalNumberOfChapters !== 0 && this.props.totalNumberOfChallenges === 0 && (<div
                className={cx({
                  "course-detail-page__progress-indicator__completion": true,
                  "course-detail-page__progress-indicator__completion--fill": true,
                  "course-detail-page__progress-indicator__completion--fill-completed": this.isContentViewed,
                })}
              >
                {this.progressStatsForChapters}
              </div>)}
            </div>
          </div>

          {this.props.isFeedbackMandatory && this.props.feedbackSurvey && (
            <div className="course-detail-page__progress-indicator__outline">
              <p className="course-detail-page__progress-indicator__hexagon-title">Feedback</p>

              <div
                className="course-detail-page__progress-indicator__completion-wrapper"
                style={{ height: "100%" }}
              >
                <div className="course-detail-page__progress-indicator__completion">
                  {this.progressStatsForFeedback}
                </div>
              </div>

              <div
                className="course-detail-page__progress-indicator__completion-wrapper"
                style={{
                  height: this.calculatedCompletionWrapperHeightForFeedback,
                }}
              >
                <div
                  className={cx({
                    "course-detail-page__progress-indicator__completion": true,
                    "course-detail-page__progress-indicator__completion--fill": true,
                    "course-detail-page__progress-indicator__completion--fill-completed": this.props.feedbackSubmitted,
                  })}
                >
                  {this.progressStatsForFeedback}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
});
