import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {AttemptStatusLabel} from '@u4i/common/AttemptStatusLabel';
import {ChallengeAttemptStatusEnum} from '@u4i/common/enums/ChallengeAttemptStatusEnum';
import intlMessages from './intlMessages';
import FormatDate from '@u4i/common/FormatDate';
import './_attempt-detail-data.scss';

interface IPropTypes {
  date: string
  graderName: string
  groupName: string
  status: ChallengeAttemptStatusEnum
  userName: string
  isMCQTest: any
}

export const AttemptDetailData: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="customer-grading__attempt-detail-data">
    <h4 className="customer-grading__attempt-detail-data__heading">
      <FormattedMessage {...intlMessages.attemptInfo} />
    </h4>

    <div className="customer-grading__attempt-detail-data__user-data">
      <FontAwesome className="customer-grading__attempt-detail-data__icon" name="user" />

      <FormattedMessage {...intlMessages.user} values={{userName: props.userName}}/>

      <span className="customer-grading__attempt-detail-data__user-group">{props.groupName}</span>
    </div>

    <div className="customer-grading__attempt-detail-data__user-data">
      <FontAwesome className="customer-grading__attempt-detail-data__icon" name="user-graduate" />

      <FormattedMessage {...intlMessages.grader} values={{graderName: props?.isMCQTest?.passmark ? 'Autograded' : props.graderName}} />
    </div>

    <div className="customer-grading__attempt-detail-data__date">
      <FontAwesome className="far customer-grading__attempt-detail-data__icon" name="calendar-alt" />

      <FormattedMessage {...intlMessages.date} />

      <FormatDate source={props.date} longMonth={true}/>
    </div>

    <div className="customer-grading__attempt-detail-data__status">
      <FontAwesome className="customer-grading__attempt-detail-data__icon" name="certificate" />

      <span className="customer-grading__attempt-detail-data__label">
        <FormattedMessage {...intlMessages.status} />
      </span>

      <AttemptStatusLabel inline status={props.status} />
    </div>
  </div>
);
