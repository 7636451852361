import {defineMessages} from 'react-intl';

export default defineMessages({
  hasAccount: {
    defaultMessage: 'Already signed up?',
    id: 'Modals.Registration.HasAccount',
  },
  loginHere: {
    defaultMessage: 'Login here',
    id: 'Modals.Registration.LoginHere',
  },
  modalTitle: {
    defaultMessage: 'Go ahead. Get access.',
    id: 'Modals.Registration.ModalTitle',
  },
});
