import Parser from '@u4i/utils/Parser';

export interface ICompareMe {
  challenges?: {
    completionCount: {
      currentUser: number;
      groupAverage: number;
      groupTop: number;
    };
    userRelativeTopPosition: number;
  };
  curriculum?: {
    completionPercentage: {
      currentUser: number;
      groupAverage: number;
      groupTop: number;
    };
    userRelativeTopPosition: number;
  };
  skills?: {
    completionCount: {
      currentUser: number;
      groupAverage: number;
      groupTop: number;
    };
    userRelativeTopPosition: number;
  };
}

export const CompareMeParser = new Parser<ICompareMe>((compareMe) => ({
  challenges: compareMe.completedChallenges ? {
    completionCount: {
      currentUser: compareMe.completedChallenges.userDone,
      groupAverage: compareMe.completedChallenges.groupAverage,
      groupTop: compareMe.completedChallenges.groupTopUserDone,
    },
    userRelativeTopPosition: (100 - compareMe.completedChallenges.percentageToGroupComparision) / 100,
  } : undefined,
  curriculum: compareMe.completedCurriculum ? {
    completionPercentage: {
      currentUser: compareMe.completedCurriculum.done / 100,
      groupAverage: compareMe.completedCurriculum.groupAverage / 100,
      groupTop: compareMe.completedCurriculum.groupTopUserDone / 100,
    },
    userRelativeTopPosition: (100 - compareMe.completedCurriculum.percentageToGroupComparision) / 100,
  } : undefined,
  skills: compareMe.completedSkills ? {
    completionCount: {
      currentUser: compareMe.completedSkills.userDone,
      groupAverage: compareMe.completedSkills.groupAverage,
      groupTop: compareMe.completedSkills.groupTopUserDone,
    },
    userRelativeTopPosition: (100 - compareMe.completedSkills.percentageToGroupComparision) / 100,
  } : undefined,
}));
