import React, { useContext } from "react";
import cx from "classnames";
import ChallengeList from "./ChallengeList";
import ChapterList from "./ChapterList";
import { fullPageApi } from "../config";
import FeedbackSurveyItem from "./FeedbackItem";
import { PlayerContext } from "../PlayerState/playerContext";
import { Divider, Row, Typography } from "antd";
import { sliceText } from "@u4i/utils/helpers";
import { ClockCircleFilled } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { SkillDuration } from "@u4i/common/SkillDuration";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import { injectIntl, RawIntlProvider } from "react-intl";
import "./_index.scss";

const PlayerMenu = (props) => {
  const playerState: any = useContext(PlayerContext);

  const handleChapterNavigation = (data, elementID = "intro") => {
    playerState.updateActiveElementId(elementID, "intro");
    props.onChapterNavigation(data.slug);
  };

  const handleChallengeClick = (challengeId, chapterId) => {
    playerState.changeCurrentlyViewedChallenge(challengeId);
    if (playerState.activeChapterData.id !== chapterId) {
      const chapter = playerState.navigationStructure.chapters.find((chapter) => chapter.id === chapterId);
      return handleChapterNavigation(chapter);
    }
    return fullPageApi.moveSectionTo(challengeId);
  };

  const handleFeedbackSurveyClick = () => {
    playerState.startFeedbackSurvey();
  };

  const navigationLanguage = () => {
    const courseLanguage = playerState.navigationStructure.skillLanguage || playerState.activeChapterData.language;

    if (props.rootStore.languageStore.currentLocale != courseLanguage) {
      return LanguageEnum.DE_DE
    }

    return courseLanguage
  }

  return (
    <div
      className={cx({
        menu_transition: true,
        ve_side_menu: true,
        ve_side_menu_open: props.isMenuActive,
        ve_side_menu_close: !props.isMenuActive
      })}
    >

      <RawIntlProvider value={props.rootStore.languageStore.intlMap.get((navigationLanguage() == LanguageEnum.EN_US || navigationLanguage() == LanguageEnum.DE_DE) ? navigationLanguage() : LanguageEnum.EN_US)}>
        {playerState.navigationStructure.skillTitle && (
          <div className="pt-16 pl-16 pr-16 ve_header_menu">
            <Typography.Title level={4} className="title_font_calcualator" style={{
              marginTop: 10
            }}>
              {sliceText(playerState.navigationStructure.skillTitle, 40)}
            </Typography.Title>
            <Row align="middle" className="mb-8 ">
              <ClockCircleFilled
                style={{
                  color: "#E3E3E3",
                  fontSize: 30
                }}
                className="icon_font_calcualator"
              />
              <Typography.Text type="secondary" className="ml-8">
                <SkillDuration
                  duration={playerState.navigationStructure.duration}
                />
              </Typography.Text>
            </Row>
            <Divider style={{
              borderColor: "#F0F0F0"
            }} />
          </div>
        )}

        <div className="pl-16 pr-16 pb-16">
          <ChapterList
            chapters={playerState.navigationStructure.chapters}
            activeChapterId={playerState.activeChapterData.id}
            nowViewedTopicId={playerState.activeTopicId}
            onSummaryClick={props.onSummaryClick}
            showSummary={
              playerState.summariesPaginated.length > 0 &&
              props.rootStore.userStore.isAuthorized
            }
            hasMandatoryChapters={playerState.navigationStructure.hasMandatoryChapters}
            activeChapterData={playerState.activeChapterData}
            topics={props.topics}
            handleChapterClick={handleChapterNavigation}
          />
          {playerState.challenges.length > 0 && (
            <ChallengeList
              activeChallengeId={playerState.activeChallengeId}
              challenges={playerState.challenges}
              chapters={playerState.navigationStructure.chapters}
              isExpanded={props.isMenuActive}
              onChallengeClick={handleChallengeClick}
              hasMandatoryChapters={playerState.navigationStructure.hasMandatoryChapters}
            />
          )}
          {playerState.navigationStructure.feedbackSurvey && (
            <FeedbackSurveyItem
              onFeedbackSurveyStart={handleFeedbackSurveyClick}
            />
          )}
        </div>
      </RawIntlProvider>
    </div>
  );
};

export default inject("rootStore")(injectIntl((observer(PlayerMenu))));
