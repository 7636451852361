import {action, observable,makeObservable} from 'mobx';

import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';

export class UserAnswerStore {
  private answerEntity: DiscussAnswerEntity;
  editionModeEnabled: boolean = false;
  fetchingComments: boolean = false;
  fetchingLikes: boolean = false;
  updatingContent: boolean = false;
  updatingContentError: string | undefined;

  constructor(answerEntity: DiscussAnswerEntity) {
    makeObservable(this, {
      editionModeEnabled: observable,
      fetchingComments: observable,
      fetchingLikes: observable,
      updatingContent: observable,
      updatingContentError: observable,
      commitEdition: action.bound,
      loadComments: action.bound,
      loadLikes: action.bound,
      toggleEditionMode: action.bound
    });

    this.answerEntity = answerEntity;
  }

  commitEdition = async (values) => {
    this.updatingContent = true;
    const submittedValues = Object.assign({}, values);
    submittedValues.anonymity = submittedValues.anonymity || false;
    try {
      await this.answerEntity.update({
        anonymously: submittedValues.anonymity,
        content: submittedValues.content,
      });
    } catch (error) {
      if (error.response) {
        this.updatingContentError = error.response.data.errorMessage;
      } else {
        this.updatingContentError = 'Error';
      }
    }

    this.updatingContent = false;
    this.toggleEditionMode();
  }

  loadComments = async () => {
    this.fetchingComments = true;
    await this.answerEntity.loadComments();
    this.fetchingComments = false;
  }

  loadLikes = async () => {
    this.fetchingLikes = true;
    await this.answerEntity.loadLikes();
    this.fetchingLikes = false;
  }

  toggleEditionMode = () => {
    this.editionModeEnabled = !this.editionModeEnabled;

    if (!this.editionModeEnabled) {
      this.updatingContentError = undefined;
    }
  }
}
