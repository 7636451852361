import {defineMessages} from 'react-intl';

export default defineMessages({
  forgotPassword: {
    defaultMessage: 'Forgot password?',
    id: 'LoginPage.ForgotPassword',
  },
  modalTitle: {
    defaultMessage: 'Login',
    id: 'LoginPage.ModalTitle',
  },
  noAccount: {
    defaultMessage: 'Don\'t have an account yet?',
    id: 'LoginPage.NoAccount',
  },
  registerHere: {
    defaultMessage: 'Register here',
    id: 'LoginPage.RegisterHere',
  },
});
