import { green, mainBlue, white } from "@u4i/styles/styles-variables";

export const mapModuleStyling = (completed: boolean): object => {
  if(completed) {
    let completedModule = {
      fill: green
    }
    return completedModule;
  } else {
    let notCompletedModule = {
      fill: white,
      stroke: mainBlue,
      strokeWidth: 6
    }
    return notCompletedModule;
  }
}
