import {action, observable, runInAction,makeObservable} from 'mobx';

import DiscussCommentEntity from '@u4i/state/entities/DiscussCommentEntity';

export class DiscussCommentStore {
  private commentEntity: DiscussCommentEntity;
  editionModeEnabled = false;
  togglingLike = false;
  updatingContent = false;
  updatingContentError: string | undefined;

  constructor(commentEntity: DiscussCommentEntity) {
    makeObservable(this, {
      editionModeEnabled: observable,
      togglingLike: observable,
      updatingContent: observable,
      updatingContentError: observable,
      commitEdition: action.bound,
      toggleEditionMode: action.bound,
      toggleLike: action.bound
    });

    this.commentEntity = commentEntity;
  }

  async commitEdition(values) {
    this.updatingContent = true;
    const submittedValues = Object.assign({}, values);
    submittedValues.anonymity = submittedValues.anonymity || false;

    try {
      await this.commentEntity.update({
        anonymously: submittedValues.anonymity,
        content: submittedValues.comment,
      });
    } catch (error) {
      if (error.response) {
        this.updatingContentError = error.response.data.errorMessage;
      } else {
        this.updatingContentError = 'Error';
      }
    }

    this.updatingContent = false;

    this.toggleEditionMode();
  }

  toggleEditionMode() {
    this.editionModeEnabled = !this.editionModeEnabled;

    if (!this.editionModeEnabled) {
      this.updatingContentError = undefined;
    }
  }

  async toggleLike() {
    this.togglingLike = true;

    await this.commentEntity.toggleLike();

    runInAction(() => {
      this.togglingLike = false;
    });
  }
}
