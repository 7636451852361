import {defineMessages} from 'react-intl';

export default defineMessages({
  anonymousPosting: {
    defaultMessage: 'Anonymous',
    id: 'Forms.EditAnswer.Anonymity.AnonymousPosting',
  },
  buttonLabelOff: {
    defaultMessage: 'Off',
    id: 'Forms.EditAnswer.Anonymity.ButtonLabelOff',
  },
  buttonLabelOn: {
    defaultMessage: 'On',
    id: 'Forms.EditAnswer.Anonymity.ButtonLabelOn',
  },
  contentErrorsMinLength: {
    defaultMessage: 'Minimum {limit} characters',
    id: 'Forms.EditAnswer.Content.Errors.MinLength',
  },
  contentErrorsPresence: {
    defaultMessage: 'Answer cannot be blank',
    id: 'Forms.EditAnswer.Content.Errors.Presence',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Forms.EditAnswer.Cancel',
  },
  save: {
    defaultMessage: 'Save',
    id: 'Forms.EditAnswer.Save',
  }
});
