import { charade, green, guardsmanRed } from "@u4i/styles/styles-variables";
import { Form, Input } from "antd";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import intlMessages from "./intlMessages";
import cx from 'classnames';
import Text from "antd/lib/typography/Text";
import RedeemAdditionalTermsOfService from "../RedeemAdditionalTermsOfService/RedeemAdditionalTermsOfService";
import './_coupon-redeem-button.scss';

const {Item} = Form;

interface IProps {
  additionalTerms: any[]
  couponCode: string
  successCouponCode: boolean
  validatingCoupon: boolean
  handleSubmit: (couponCode: string) => void
  checkCouponCode: (couponCode: string) => void
}

export const CouponRedeemForm: FC<IProps> = (props: IProps) => {
  const {
    additionalTerms,
    couponCode,
    successCouponCode,
    validatingCoupon,
    checkCouponCode,
    handleSubmit
  } = props;

  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [couponValue, setCouponValue] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkCouponCode(couponValue.trim());
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [couponValue]);

  const mapColor = (): string => {
    return couponCode === '' ? charade : successCouponCode === true ? green : guardsmanRed;
  }

  const handleCheckbox = (checked: boolean) => {
    setChecked(checked);
    form.setFieldsValue({['group-terms']: checked});
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="couponRedeem"
      onFinish={(val) => handleSubmit(val)}
    >
      <Item
        hasFeedback
        label={<Text style={{color: mapColor()}}>Coupon code</Text>}
        name="couponCode"
        validateStatus={validatingCoupon ? 'validating' : couponCode === '' ? '' : successCouponCode ? 'success' : 'error'}
        rules={[
          {
            message: '"Coupon code" is required',
            required: true
          }
        ]}
      >
        <Input
          onChange={(e) => setCouponValue(e.target.value)}
          style={{
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderColor: mapColor()
          }}
        />
      </Item>

      <RedeemAdditionalTermsOfService
        additionalTerms={additionalTerms}
        onCheckboxChange={(val) => handleCheckbox(val)}
      />

      <Item>
        <button
          className={cx({
            'redeem-button': true,
            'redeem-button--centered': true
          })}
          disabled={!successCouponCode || (additionalTerms?.length > 0 && !checked)}
          type="submit"
        >
          <FormattedMessage {...intlMessages.submitTitle} />
        </button>
      </Item>
    </Form>
  )
}
