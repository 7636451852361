import React from "react";
import { cloneDeep } from "lodash";
import RequestBucket from "../../../common/RequestBucket";
import api from "@u4i/utils/api";
import { MobxStore } from "@u4i/MobxStore";
import ChapterParser from "@u4i/parsers/ChapterParser";
import PlayerElementParser from "@u4i/parsers/PlayerElementParser";
import PlayerNavigationParser from "@u4i/parsers/PlayerNavigationParser";
import { ChallengePlatformEnum } from "@u4i/common/enums/ChallengePlatformEnum";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import qs from "qs";
import url from "url";
import { ChallengeProgressStatusEnum } from "@u4i/common/enums/ChallengeProgressStatusEnum";
import { IChallengePlayer } from "./player-interface";

const INITIAL_STATE = {
  activeChallengeId: null,
  activeChapterData: {},
  activeTopicId: null,
  activeElementId: null,
  challenges: [],
  elements: null,
  errorCode: null,
  fetching: true,
  navigationStructure: {
    skillId: null,
    skillSlug: null,
    skillTitle: null,
    chapters: [],
    feedbackSurvey: null,
    duration: 0,
  },
  savedScrollPosition: null,
  topics: [],
};

const PAGE_CHAR_LIMIT = 1500;
const PAGE_ENTRIES_LIMIT = 8;

function sumEntiresLength(accumulator, entry) {
  return accumulator + entry.text.length;
}

const requestBucket = new RequestBucket();
const requestIds = { ELEMENTS: "ELEMENTS", NAVIGATION: "NAVIGATION" };
export const PlayerContext = React.createContext({});

const PlayerProvider = ({ children }) => {
  const [state, setState] = React.useState<any>({
    activeChallengeId: null,
    activeChapterData: {},
    activeTopicId: null,
    activeElementId: null,
    challenges: [],
    elements: [],
    errorCode: null,
    fetching: true,
    navigationStructure: {
      skillId: null,
      skillSlug: null,
      skillTitle: null,
      chapters: [],
      feedbackSurvey: null,
      duration: 0,
    },
    savedScrollPosition: null,
    firstScrollPosition: null,
    topics: [],
  });

  const markElementViewed = (elementId) => {
    const newElements = cloneDeep(state.elements);

    newElements.find((element) => element.id === elementId).viewed = true;

    const elementTopic = state.topics.find((topic) =>
      topic.elementsIds.includes(elementId)
    );
    const topicElements = newElements.filter((element) =>
      elementTopic.elementsIds.includes(element.id)
    );
    const topicViewed = topicElements.reduce((accumulator, element) => {
      if (!element.viewed) {
        return false;
      }
      return accumulator;
    }, true);

    const newNavigationStructure = cloneDeep(state.navigationStructure);
    const allTopicsArrays = newNavigationStructure.chapters.map(
      (chapter) => chapter.topics
    );
    const allTopics: any = [].concat(...allTopicsArrays);
    allTopics.find((topic) => topic.id === elementTopic.id).viewed =
      topicViewed;

    setState((prevState) => ({
      ...prevState,
      elements: newElements,
      navigationStructure: newNavigationStructure,
    }));
  };

  const toggleFetching = (status) => {
    setState((prevState) => ({
      ...prevState,
      fetching: status,
    }));
  };

  const requestNavigationStructure = (skillSlug = null, chapterSlug = null) => {
    requestBucket.cancel(requestIds.NAVIGATION);
    const cancelToken = requestBucket.generateToken(requestIds.NAVIGATION);

    if (skillSlug) {
      return api.get(`/v1/player/navigation/skills/${skillSlug}`, {
        cancelToken,
      });
    }

    return api.get(`/v1/player/navigation/chapters/${chapterSlug}`, {
      cancelToken,
    });
  };

  const requestElements = (topicSlug) => {
    return api.get(`/v1/topics/${topicSlug}/elements`);
  };

  const changeCurrentlyViewedChallenge = (currentChallengeId) => {
    if (currentChallengeId !== state.activeChallengeId) {
      setState((prevState) => ({
        ...prevState,
        activeChallengeId: currentChallengeId,
      }));
    }
  };

  const changeCurrentlyViewedTopic = (currentElementId) => {
    const frontendElements = [
      "intro",
      "providers",
      "tableOfContents",
      "tableOfContents1",
      "tableOfContents2",
      "tableOfContents3",
      "summary",
      "summary1",
      "summary2",
      "summary3",
      "feedback",
    ];

    const firstElementsList = [
      "intro",
      "providers",
      "tableOfContents",
      "tableOfContents1",
      "tableOfContents2",
      "tableOfContents3",
    ];

    if (frontendElements.includes(currentElementId)) {
      if (
        currentElementId === "summary" ||
        currentElementId === "summary1" ||
        currentElementId === "summary2" ||
        currentElementId === "summary3"
      ) {
        setState((prevState) => ({
          ...prevState,
          activeTopicId: "summary",
          activeElementId: currentElementId,
        }));
      }
      if (firstElementsList.includes(currentElementId)) {
        if (state.activeChapterData?.topics) {
          setState((prevState) => ({
            ...prevState,
            activeTopicId: state.activeChapterData?.topics[0]?.id,
            activeElementId: currentElementId,
          }));
        }
      }
      return;
    }

    const targetTopic = state.topics.find((topic) =>
      topic.elementsIds.includes(currentElementId)
    );

    if (targetTopic.id !== state.activeTopicId) {
      setState((prevState) => ({
        ...prevState,
        activeTopicId: targetTopic.id,
        activeElementId: currentElementId,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        activeElementId: currentElementId,
      }));
    }
  };

  const changeSavedScrollPosition = (currentElementId) => {
    const { isAuthorized } = MobxStore.store.userStore;

    if (isAuthorized) {
      api.post("/v1/player/save-scroll-position", {
        chapter_slug: state.activeChapterData.slug,
        element_id: currentElementId,
        skill_slug: state.navigationStructure.skillSlug,
      });
    }
  };

  const loadElements = async (chapterSlug = null) => {
    const { chapters } = state.navigationStructure;

    if (state.navigationStructure.chapters.length === 0) {
      return;
    }

    toggleFetching(true);

    const targetChapterIndex = chapters.findIndex(
      (chapter) => chapter.slug === chapterSlug
    );
    const targetChapterId =
      chapters[targetChapterIndex === -1 ? 0 : targetChapterIndex].id;
    const targetChapterResponse = await api.get(
      `/v1/chapters/${targetChapterId}`
    );
    const targetChapter: any = ChapterParser.parse(targetChapterResponse.data);

    Object.assign(targetChapter, {
      topics:
        chapters[targetChapterIndex === -1 ? 0 : targetChapterIndex].topics,
    });

    const elementRequests = targetChapter.topics.map((topic) => ({
      request: requestElements(topic.slug),
      response: null,
      topicId: topic.id,
      topicSlug: topic.slug,
    }));

    const elementResponses = await Promise.all(
      elementRequests.map((entry) => entry.request)
    );
    elementRequests.forEach((entry, index) => {
      Object.assign(entry, { response: elementResponses[index] });
    });

    const allElements = [].concat(
      ...elementResponses.map((response) => response.data)
    );

    const parsedElements = PlayerElementParser.parseArray(allElements);
    // const sortedElements = parsedElements.sort(elementsSortPredicate);

    const topics = elementRequests.map((entry) => ({
      elementsIds: PlayerElementParser.parseArray(entry.response.data).map(
        (element) => element.id
      ),
      id: entry.topicId,
      slug: entry.topicSlug,
    }));

    setState((prevState) => ({
      ...prevState,
      elements: parsedElements,
      activeChapterData: targetChapter,
      topics: topics,
    }));
  };

  const loadNavigation = async (skillSlug = null, chapterSlug = null) => {
    toggleFetching(true);

    try {
      const navigationResponse = await requestNavigationStructure(
        skillSlug,
        chapterSlug
      );

      const parsedNavigationStructure = PlayerNavigationParser.parse(
        navigationResponse.data
      );

      if (parsedNavigationStructure?.chapters)
        buildChallenges(parsedNavigationStructure?.chapters);

      setState((prevState) => ({
        ...prevState,
        navigationStructure: parsedNavigationStructure,
      }));
    } catch (error) {
      if (error.response) {
        setState((prevState) => ({
          ...prevState,
          errorCode: error.response.status,
        }));
      }
      throw error;
    }
  };

  const recordElementView = async (
    elementId,
    elapsedMilliseconds,
    moduleId = undefined
  ) => {
    await api.post(`/v1/statistics/view-time/element/${elementId}`, {
      duration: elapsedMilliseconds,
      moduleId: moduleId,
    });

    markElementViewed(elementId);
  };

  const resetState = () => {
    requestBucket.cancelAll();
    setState(Object.assign({}, INITIAL_STATE));
  };

  const restoreScrollPosition = async (skillSlug = null, chapterSlug) => {
    let savedScrollPositionUrl: string = `/v1/player/scroll-position/${chapterSlug}`;

    if (skillSlug) {
      savedScrollPositionUrl += `/${skillSlug}`;
    }

    try {
      const savedScrollPosition: any = await api.get(savedScrollPositionUrl);

      setState((prevState) => ({
        ...prevState,
        activeElementId: savedScrollPosition.data.elementId,
        fetching: false,
        firstScrollPosition:
          state.firstScrollPosition === null &&
          savedScrollPosition.data.elementId,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        activeElementId: "intro",
        fetching: false,
      }));
    }
  };

  const startChallenge = async (challenge, elementId) => {
    const { skillId } = state.navigationStructure;

    if (challenge.provider === ChallengePlatformEnum.EXERCISES) {
      AppNavigator.push(appRoutes.exercises, {
        exerciseId: challenge.exerciseId,
      });
      return;
    }

    if (challenge.provider === ChallengePlatformEnum.DIGITAL_LABS) {
      AppNavigator.push(appRoutes.digitalLabs, {
        labId: challenge.digitalLabId,
      });
      return;
    }

    if (challenge.provider === ChallengePlatformEnum.JUPYTER_LABS) {
      AppNavigator.push(appRoutes.jupyterLabs, {
        labId: challenge.jupyterLabId,
      });
      return;
    }

    const challengeStartResponse = await api.post(
      "challenges/start-challenge",
      { elementId }
    );

    const parsedUrl: any = url.parse(challenge.url);
    const newUrlParams = qs.parse(parsedUrl.search, {
      ignoreQueryPrefix: true,
    });
    const urlBasePath = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`;

    if (skillId) {
      newUrlParams.cm_return_skill = skillId;
    }

    if (challenge.provider === ChallengePlatformEnum.CLASSMARKER) {
      newUrlParams.cm_user_id = challengeStartResponse.data.id;

      const targetUrl = `${urlBasePath}${qs.stringify(newUrlParams, {
        addQueryPrefix: true,
      })}`;

      if (challenge.useDirectLink) {
        window.location.href = targetUrl;
      } else {
        // @ts-ignore:
        AppNavigator.push(appRoutes.externalContent, null, null, { targetUrl });
      }
    } else if (
      challenge.provider === ChallengePlatformEnum.EXTERNAL ||
      challenge.provider === ChallengePlatformEnum.LIVE_LABS
    ) {
      // @ts-ignore:
      AppNavigator.push(appRoutes.externalContent, null, null, {
        targetUrl: challenge.url,
      });
    } else if (challenge.provider === ChallengePlatformEnum.LTI) {
      window.open(
        `${window.location.origin}/lti/launch/challenge/${challengeStartResponse.data.id}`,
        "popUpWindow",
        "height=900,width=900,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
      );
    }
  };

  const startFeedbackSurvey = () => {
    if (state.navigationStructure.feedbackSurvey) {
      // @ts-ignore:
      AppNavigator.push(appRoutes.externalContent, null, null, {
        challengePlatform: "feedback_survey",
        targetUrl: state.navigationStructure.feedbackSurvey.url,
      });
    }
  };

  const startQuickFeedbackSurvey = () => {
    if (state.navigationStructure.feedbackSurvey) {
      // @ts-ignore:
      AppNavigator.push(appRoutes.externalContent, null, null, {
        challengePlatform: "feedback_survey",
        targetUrl: state.navigationStructure.feedbackSurvey.quick_url,
      });
    }
  };

  const updateActiveTopicId = (id) => {
    setState((prevState) => ({
      ...prevState,
      activeTopicId: id,
    }));
  };

  const activeTopicNameSelector = () => {
    if (state.activeTopicId) {
      if (state.activeTopicId === "summary") {
        return state.activeTopicId;
      }

      if (state.activeChapterData.topics) {
        const activeTopic = state.activeChapterData.topics.filter((topic) => {
          return topic.id === state.activeTopicId ? topic : null;
        });

        return activeTopic.length > 0 ? activeTopic[0].title : null;
      }
    }
    return null;
  };

  const firstTopicNameSelector = () => {
    if (state.activeChapterData.topics) {
      return state.activeChapterData.topics[0].title;
    }

    return null;
  };

  const allowedProvidersSelector = () => {
    const allowedProviders = state.activeChapterData.providers
      ? state.activeChapterData.providers
      : [];
    return allowedProviders;
  };

  const contentsPaginatedSelector = () => {
    const contents = state.activeChapterData.contents || [];
    const result: any = [[]];

    contents.forEach((contentEntry: any) => {
      const lastPage = result[result.length - 1];
      const lastPageEntriesLength = lastPage.reduce(sumEntiresLength, 0);

      if (lastPage.length === PAGE_ENTRIES_LIMIT) {
        result.push([contentEntry]);
      } else {
        const willPassLimit =
          lastPageEntriesLength + contentEntry.text.length > PAGE_CHAR_LIMIT;

        if (lastPage.length === 0) {
          lastPage.push(contentEntry);
        } else if (lastPage.length === PAGE_ENTRIES_LIMIT || willPassLimit) {
          result.push([contentEntry]);
        } else {
          lastPage.push(contentEntry);
        }
      }
    });

    if (result[0].length === 0) {
      return [];
    }

    return result;
  };

  const isActiveChapterLastSelector = () => {
    const chapterPosition = state.navigationStructure.chapters
      .map((chapter) => chapter.id)
      .indexOf(state.activeChapterData.id);

    return chapterPosition + 1 === state.navigationStructure.chapters.length;
  };

  const summariesPaginatedSelector = () => {
    const summaries = state.activeChapterData.summaries || [];
    const result: any = [[]];

    summaries.forEach((summaryEntry) => {
      const lastPage = result[result.length - 1];
      const lastPageEntriesLength = lastPage.reduce(sumEntiresLength, 0);

      if (lastPage.length === PAGE_ENTRIES_LIMIT) {
        result.push([summaryEntry]);
      } else {
        const willPassLimit: boolean =
          lastPageEntriesLength + summaryEntry.text.length > PAGE_CHAR_LIMIT;

        if (lastPage.length === 0) {
          lastPage.push(summaryEntry);
        } else if (lastPage.length === PAGE_ENTRIES_LIMIT || willPassLimit) {
          result.push([summaryEntry]);
        } else {
          lastPage.push(summaryEntry);
        }
      }
    });

    if (result[0].length === 0) {
      return [];
    }

    return result;
  };

  const playerDisclaimerSelector = () => {
    const { playerDisclaimer } = state.navigationStructure;

    return playerDisclaimer && playerDisclaimer.length > 0
      ? playerDisclaimer
      : undefined;
  };

  const changeChallengeStatus = (
    currentChallengeId: string,
    status: ChallengeProgressStatusEnum
  ) => {
    const newChallengesUpdated: IChallengePlayer = state.challenges.map(
      (challenge) => {
        if (challenge.challengeId === currentChallengeId) {
          return { ...challenge, challengeStatus: status };
        } else {
          return challenge;
        }
      }
    );

    setState((prevState) => ({
      ...prevState,
      challenges: newChallengesUpdated,
    }));
  };

  const buildChallenges = (chapters: any[]) => {
    const result: any = [];

    chapters.forEach((chapter) => {
      chapter.topics.forEach((topic) => {
        topic.challenges.forEach((challenge: any) => {
          const challengeItem: IChallengePlayer = { ...challenge };

          challengeItem.chapterId = chapter.id;
          challengeItem.topicId = topic.id;

          result.push(challengeItem);
        });
      });
    });

    setState((prevState) => ({
      ...prevState,
      challenges: result,
    }));
  };

  const chapterChallengesSelector = (chapterId) => {
    const chapterById = state.navigationStructure.chapters.find(
      (chapter) => chapter.id === chapterId
    );

    if (!chapterById) {
      return null;
    }

    return [].concat(...chapterById.topics.map((topic) => topic.challenges));
  };

  const chapterChallengesCompletedSelector = (chapterId) => {
    const chapterById = state.navigationStructure.chapters.find(
      (chapter) => chapter.id === chapterId
    );

    if (!chapterById) {
      return null;
    }

    return []
      .concat(...chapterById.topics.map((topic) => topic.challenges))
      .reduce((accumulator, challengeItem: any) => {
        return (
          accumulator +
          (challengeItem.challengeStatus ===
          ChallengeProgressStatusEnum.COMPLETED
            ? 1
            : 0)
        );
      }, 0);
  };

  const chapterChallengesInGradingSelector = (chapterId) => {
    const chapterById = state.navigationStructure.chapters.find(
      (chapter) => chapter.id === chapterId
    );

    if (!chapterById) {
      return null;
    }

    return []
      .concat(...chapterById.topics.map((topic) => topic.challenges))
      .reduce((accumulator, challengeItem: any) => {
        return (
          accumulator +
          (challengeItem.challengeStatus ===
          ChallengeProgressStatusEnum.GRADING_IN_PROGRESS
            ? 1
            : 0)
        );
      }, 0);
  };

  const updateActiveElementId = (elementId, topicId) => {
    setState((prevState) => ({
      ...prevState,
      activeElementId: elementId,
      activeTopicId: topicId,
    }));
  };

  const nextChapterSlugSelector = () => {
    const chapterPosition = state.navigationStructure.chapters
      .map((chapter) => chapter.id)
      .indexOf(state.activeChapterData.id);

    if (chapterPosition + 1 === state.navigationStructure.chapters.length) {
      return null;
    }

    return state.navigationStructure.chapters[chapterPosition + 1].slug;
  };

  const rateCourseByChapter = async (skillId, model) => {
    return await api.post(`/v2/skills/${skillId}/quick-feedback/submit`, model);
  };

  return (
    <PlayerContext.Provider
      value={{
        ...state,
        markElementViewed,
        toggleFetching,
        requestNavigationStructure,
        requestElements,
        changeCurrentlyViewedChallenge,
        changeChallengeStatus,
        changeCurrentlyViewedTopic,
        changeSavedScrollPosition,
        loadElements,
        loadNavigation,
        recordElementView,
        resetState,
        restoreScrollPosition,
        startChallenge,
        startFeedbackSurvey,
        updateActiveTopicId,
        startQuickFeedbackSurvey,
        chapterChallengesSelector,
        chapterChallengesCompletedSelector,
        chapterChallengesInGradingSelector,
        updateActiveElementId,
        rateCourseByChapter,
        activeTopicName: activeTopicNameSelector(),
        firstTopicName: firstTopicNameSelector(),
        allowedProviders: allowedProvidersSelector(),
        contentsPaginated: contentsPaginatedSelector(),
        summariesPaginated: summariesPaginatedSelector(),
        isActiveChapterLast: isActiveChapterLastSelector(),
        playerDisclaimer: playerDisclaimerSelector(),
        nextChapterSlug: nextChapterSlugSelector(),
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

export default PlayerProvider;
