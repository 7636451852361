import { defineMessages } from "react-intl";

export default defineMessages({
  collaborationCodeInfo: {
    defaultMessage:
      "If you received a coupon code please enter it here to access exclusive content.",
    id: "Forms.Registration.CollaborationCode.Info",
  },
  collaborationCodeLabel: {
    defaultMessage: "Coupon code (optional)",
    id: "Forms.Registration.CollaborationCode.Label",
  },
  emailErrorsPattern: {
    defaultMessage: "Is not valid e-mail",
    id: "Forms.Registration.Email.Errors.Pattern",
  },
  emailErrorsPresence: {
    defaultMessage: "E-mail cannot be blank",
    id: "Forms.Registration.Email.Errors.Presence",
  },
  emailLabel: {
    defaultMessage: "Business e-mail address",
    id: "Forms.Registration.Email.Label",
  },
  firstnameErrorsPresence: {
    defaultMessage: "First name cannot be blank",
    id: "Forms.Registration.Firstname.Errors.Presence",
  },
  firstnameLabel: {
    defaultMessage: "First name",
    id: "Forms.Registration.Firstname.Label",
  },
  lastnameErrorsPresence: {
    defaultMessage: "Last name cannot be blank",
    id: "Forms.Registration.Lastname.Errors.Presence",
  },
  lastnameLabel: {
    defaultMessage: "Last name",
    id: "Forms.Registration.Lastname.Label",
  },
  submitTitle: {
    defaultMessage: "Register",
    id: "Forms.Registration.SubmitTitle",
  },
  emailErrorsMaxLength: {
    defaultMessage: "Maximum {limit} characters",
    id: "Forms.Registration.Email.Errors.MaxLength",
  },
  firstnameErrorsMaxLength: {
    defaultMessage: "Maximum {limit} characters",
    id: "Forms.Registration.Firstname.Errors.MaxLength",
  },
  lastnameErrorsMaxLength: {
    defaultMessage: "Maximum {limit} characters",
    id: "Forms.Registration.Lastname.Errors.MaxLength",
  },
  purchaseMessage: {
    defaultMessage:
      "Thank you for your interest in this course. We are delighted that you want" +
      " to deepen your knowledge. After completing this purchase, you will get access to" +
      " this course for one person. Upon successful completion of the course, you will receive" +
      " a qualification certificate. You have up to 3 months to complete the course.",
    id: "Forms.Registration.PurchaseMessage",
  },
  newsletterLabel: {
    defaultMessage: "I agree to receive your newsletters and accept the",
    id: "Forms.Registration.NewsletterLabel",
  },
  dataPrivacyStatement: {
    defaultMessage: "data privacy statement",
    id: "Forms.Registration.DataPrivacyStatement",
  },
  newsletterLabelExtra: {
    defaultMessage:
      "To become a part of our exclusive newsletter list, you need to confirm your email address via the confirmation email you will receive from us. {br} Please check your spam folder in case the message got stuck there.We're committed to your privacy. University4Industry uses the information you provide to us to contact you about our relevant content, projects, and services. You may unsubscribe from these communications at any time. For more information, check out our",
    id: "Forms.Registration.NewsletterLabelExtra",
  },
  passwordConfirmErrorsPresence: {
    defaultMessage: "Password cannot be blank",
    id: "Forms.Registration.PasswordConfirm.Errors.Presence",
  },
  passwordConfirmErrorsRepeat: {
    defaultMessage: "These passwords don't match. Try again?",
    id: "Forms.Registration.PasswordConfirm.Errors.Repeat",
  },
  passwordConfirmLabel: {
    defaultMessage: "Confirm Password",
    id: "Forms.Registration.PasswordConfirm.Label",
  },
  passwordErrorsLowercasePresent: {
    defaultMessage: "Password must contain at least one lowercase letter",
    id: "Forms.Registration.Password.Errors.LowercasePresent",
  },
  passwordErrorsMaxLength: {
    defaultMessage: "Maximum {limit} characters",
    id: "Forms.Registration.Password.Errors.MaxLength",
  },
  passwordErrorsMinLength: {
    defaultMessage: "Minimum {limit} characters",
    id: "Forms.Registration.Password.Errors.MinLength",
  },
  passwordErrorsNumberPresent: {
    defaultMessage: "Password must contain at least one numeric digit",
    id: "Forms.Registration.Password.Errors.NumberPresent",
  },
  passwordErrorsPresence: {
    defaultMessage: "Password cannot be blank",
    id: "Forms.Registration.Password.Errors.Presence",
  },
  passwordErrorsSpecialCharacterPresent: {
    defaultMessage: "Password must contain at least one special character",
    id: "Forms.Registration.Password.Errors.SpecialCharacterPresent",
  },
  passwordErrorsUppercasePresent: {
    defaultMessage: "Password must contain at least one uppercase letter",
    id: "Forms.Registration.Password.Errors.UppercasePresent",
  },
  passwordLabel: {
    defaultMessage: "Password",
    id: "Forms.Registration.Password.Label",
  },
  termsAnd: {
    defaultMessage: "and the",
    id: "Forms.Registration.Terms.And",
  },
  termsDisclaimerEnd: {
    defaultMessage: " ",
    id: "Forms.Registration.Terms.DisclamerEnd",
  },
  termsDisclaimerLink: {
    defaultMessage: "Disclaimer",
    id: "Forms.Registration.Terms.DisclaimerLink",
  },
  termsErrorsPresence: {
    defaultMessage: "You must agree to our Privacy Policy and the Disclaimer",
    id: "Forms.Registration.Terms.Errors.Presence",
  },
  termsLabel: {
    defaultMessage: "I agree to the",
    id: "Forms.Registration.Terms.Label",
  },
  termsPrivacyPolicyLink: {
    defaultMessage: "Privacy Policy",
    id: "Forms.Registration.Terms.PrivacyPolicy",
  },
  cityErrorsPresence: {
    defaultMessage: "City cannot be blank",
    id: "Forms.Registration.City.Errors.Presence",
  },
  cityLabel: {
    defaultMessage: "City",
    id: "Forms.Registration.City.Label",
  },
  countryErrorsPresence: {
    defaultMessage: "Country cannot be blank",
    id: "Forms.Registration.Country.Errors.Presence",
  },
  countryLabel: {
    defaultMessage: "Country",
    id: "Forms.Registration.Country.Label",
  },
  postalCodeErrorsPresence: {
    defaultMessage: "Postal code cannot be blank",
    id: "Forms.Registration.PostalCode.Errors.Presence",
  },
  postalCodeLabel: {
    defaultMessage: "Postal code",
    id: "Forms.Registration.PostalCode.Label",
  },
  postalCodeErrorsMaxLength: {
    defaultMessage: "Maximum {limit} characters",
    id: "Forms.Registration.PostalCode.Errors.MaxLength",
  },
  streetLabel: {
    defaultMessage: "Street",
    id: "Forms.Registration.Street.Label",
  },
  streetErrorsPresence: {
    defaultMessage: "Street cannot be blank",
    id: "Forms.Registration.Street.Errors.Presence",
  },
  purchaseCourseLabel: {
    defaultMessage: "Purchase course via invoice",
    id: "Forms.Registration.PurchaseCourse.Label",
  },
});
