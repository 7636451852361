import {action, observable, reaction, runInAction,makeObservable} from 'mobx';
import {inject} from 'mobx-react';

import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import DiscussPageStore from '../../../../../state/DiscussPageStore';
import {IRootStore} from '@u4i/state/RootStore';

export class DiscussAnswerStore {
  private answerEntity: DiscussAnswerEntity;
  commentsLoaded = false;
  commentModeEnabled = false;
  fetchingComments = false;
  showComments = false;
  togglingLike = false;
  discussPageStore: DiscussPageStore;
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore, answerEntity: DiscussAnswerEntity) {
    makeObservable(this, {
      commentsLoaded: observable,
      commentModeEnabled: observable,
      fetchingComments: observable,
      showComments: observable,
      togglingLike: observable,
      discussPageStore: observable,
      submitComment: action.bound,
      toggleCommentMode: action.bound,
      toggleComments: action.bound,
      toggleLike: action.bound
    });

    this.answerEntity = answerEntity;
    this.rootStore = rootStore;
    const {discussPageStore} = this.rootStore;
    this.discussPageStore = discussPageStore;
  }

  async submitComment(values: {anonymity: boolean, content: string}) {
    await this.answerEntity.submitComment(values);

    this.toggleCommentMode();
    
    if (!this.showComments) {
      this.toggleComments();
    }
  
    this.discussPageStore.setAnswerUpdated(this.answerEntity.id);
  }

  toggleCommentMode() {
    this.commentModeEnabled = !this.commentModeEnabled;
  }

  async toggleComments() {
    if (!this.commentsLoaded) {
      this.fetchingComments = true;

      await this.answerEntity.loadComments();

      runInAction(() => {
        this.commentsLoaded = true;
        this.fetchingComments = false;
      });
    }

    runInAction(() => {
      this.showComments = !this.showComments;
    });
  }

  async toggleLike() {
    this.togglingLike = true;

    await this.answerEntity.toggleLike();

    runInAction(() => {
      this.togglingLike = false;
      this.discussPageStore.setAnswerUpdated(this.answerEntity.id);
    });
  }
}
