import React, { FC } from "react";
import { inject } from "mobx-react";
import AppNavigator from "@u4i/common/AppNavigator";
import RegistrationForm from "@u4i/common/forms/RegistrationForm";
import { IRootStore } from "@u4i/state/RootStore";

import "./_registration-page.scss";

interface IPropTypes {
  rootStore: IRootStore;
}

const RegistrationPage: FC<IPropTypes> = (props) => {
  React.useEffect(() => {
    if (props.rootStore.userStore.isAuthorized) {
      AppNavigator.directPush("/");
    }
  }, []);

  const handleFormSubmission = (data) => {
    return props.rootStore.userStore.register(data);
  };

  return (
    <div className="registration-page">
      <div className="registration-page__form-wrapper">
        <RegistrationForm onSubmit={handleFormSubmission} rootStore={props.rootStore}/>
      </div>
    </div>
  );
};

export default inject("rootStore")(RegistrationPage);
