import {defineMessages} from 'react-intl';

export default defineMessages({
  and: {
    defaultMessage: 'and the',
    id: 'Forms.TermsSection.And',
  },
  disclaimerLink: {
    defaultMessage: 'Disclaimer',
    id: 'Forms.TermsSection.DisclaimerLink',
  },
  errorsPresence: {
    defaultMessage: 'You must agree to our Privacy Policy and the Disclaimer',
    id: 'Forms.TermsSection.Errors.Presence',
  },
  label: {
    defaultMessage: 'I agree to the',
    id: 'Forms.TermsSection.Label',
  },
  privacyPolicyLink: {
    defaultMessage: 'Privacy Policy',
    id: 'Forms.TermsSection.PrivacyPolicy',
  }
});
