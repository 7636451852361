import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import React, {FC, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import successIcon from '@u4i/assets/svg/success.svg'
import intlMessages from './intlMessages';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '@u4i/state/RootStore';
import { RouteComponentProps } from '@reach/router';
import './_registration-success.scss';

interface IProps extends RouteComponentProps {
  match: any
  rootStore: IRootStore
}

const RegistrationSuccess: FC<IProps> = inject("rootStore")(
  observer((props: IProps) => {
    let redirectionTimeout: any = null;
    const courseRedirectURL: string = props.rootStore.registerConfirmPageStore.courseRedirectUrl;

    useEffect(() => {
      redirectionTimeout = setTimeout(redirectHome, 3000);  
      return () => {
        clearTimeout(redirectionTimeout);
      }
    }, [])  

    const redirectHome = () => {
      if(courseRedirectURL != "") {
        let startingWithSlash: boolean = courseRedirectURL.toString().startsWith("/");
        if(!startingWithSlash) {
          AppNavigator.directPush(`/${courseRedirectURL.toString()}`);
        } else {
          AppNavigator.directPush(courseRedirectURL);
        }
      } else {
        AppNavigator.replace(appRoutes.home);
      }
      props.rootStore.registerConfirmPageStore.courseRedirectUrl = '';
    };

    return (
      <div className="registration-success">
        <img alt="" className="registration-success__icon" src={successIcon} />

        <div className="registration-success__title disable-invert">
          <FormattedMessage {...intlMessages.title} />
        </div>

        <div className="registration-success__text disable-invert">
          <FormattedMessage {...intlMessages.text} values={{br: <br />}} />
        </div>

        <div className="registration-success__text disable-invert">
          <FormattedMessage {...intlMessages.hint} />
          &nbsp;
          <Link className="registration-success__link" to={courseRedirectURL != "" ? `${courseRedirectURL}` : "/"}>
            <FormattedMessage {...intlMessages.link} />
          </Link>
          .
        </div>
      </div>
    );
  })
)

export default RegistrationSuccess;
