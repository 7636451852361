import { defineMessages } from "react-intl";

export default defineMessages({
  summaryLabel: {
    defaultMessage: "Summary",
    id: "Player.Navigation.ChaptersList.TopicsList.SummaryLabel",
  },
  warningMessage: {
    id: "CourseDetailPage.Overview.UncompletedChapter",
    defaultMessage: "In order to view the following chapters, all elements of the previous chapters need to be completed",
  },
});
