let windowReference: any = window;

class FullPageApi {
  constructor() {}

  moveSectionDown() {
    windowReference.fullpage_api.moveSectionDown();
  }
  moveSectionUp() {
    windowReference.fullpage_api.moveSectionUp();
  }
  moveSectionTo(id) {
    windowReference.fullpage_api.silentMoveTo(id, 0);
  }
  setAllowScrolling(status) {
    windowReference.fullpage_api.setAllowScrolling(status);
  }
  destroy() {
    windowReference.fullpage_api.destroy("all");
  }
  reBuild() {
    windowReference.fullpage_api.reBuild();
  }
  getActiveSlide() {
    windowReference.fullpage_api.getActiveSlide();
  }
  getActiveSection() {
    windowReference.fullpage_api.getActiveSection();
  }
  getFullPageReference() {
    return windowReference.fullpage_api;
  }
  setKeyboardScrolling() {
    windowReference.fullpage_api.setKeyboardScrolling(true);
  }
}

const fullPageApi = new FullPageApi();

export { fullPageApi };
