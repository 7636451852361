import axios from "axios";
import {
  getNavigatorLanguage,
  isInternetExplorer,
} from "../../common/utils";

function createApiClient(rootStore) {
  const logger = rootStore.loggerFactory.createLogger("API");
  const api = axios.create({
    // @ts-ignore
    baseURL: `${__WEB_ROOT__}api`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    timeout: 36000 * 1000,
    withCredentials: true,
  });

  api.interceptors.request.use((config) => {
    const targetConfig = config;

    if (isInternetExplorer()) {
      const currentTimestamp = new Date().getTime();

      if (targetConfig.params) {
        targetConfig.params.ieCacheBuster = currentTimestamp;
      } else {
        targetConfig.params = { ieCacheBuster: currentTimestamp };
      }
    }

    return targetConfig;
  });

  api.interceptors.request.use((config) => {
    const targetConfig: any = config;
    const locale =
      (rootStore.languageStore && rootStore.languageStore.currentLocale) ||
      getNavigatorLanguage();

    targetConfig.headers["X-Language"] = locale;

    const accessData = rootStore.storageService.accessData.get();
    if (accessData) {
      targetConfig.headers.Authorization = `Bearer ${accessData.token}`;
    }

    return targetConfig;
  });

  api.interceptors.request.use((config) => {
    const targetConfig: any = config;

    targetConfig.timing = performance.now();

    return targetConfig;
  });

  api.interceptors.response.use((response: any) => {
    const timing = Math.round(
      Number(performance.now()) - response.config.timing
    );

    logger.info(`${response.config.url} (${timing}ms)`);

    return response;
  });

  // @ts-ignore
  api.interceptors.response.use(null, (error) => {
    const { config } = error;

    logger.error(error);

    if (axios.isCancel(error)) {
      return { catch: () => {}, then: () => {} };
    }

    if (config && error.response.status === 401) {
      if (rootStore.userStore && rootStore.userStore.isAuthorized) {
        rootStore.userStore.logoutAndWipe();
      }
    }

    return Promise.reject(error);
  });

  return api;
}

export default createApiClient;
