import {defineMessages} from 'react-intl';

export default defineMessages({
  backButtonLabel: {
    defaultMessage: 'Back',
    id: 'ResetPasswordPage.BackButtonLabel',
  },
  pageTitle: {
    defaultMessage: 'Create new password',
    id: 'ResetPasswordPage.PageTitle',
  },
});
