import { TraxLrsUrl, TraxLrsCredentials } from "@u4i/modules/Admin/modules/Programs/interfaces/constants";
import { XAPIStatementPost, XAPIStatementResponse } from "@u4i/utils/interfaces/xapi.interfaces";
import axios from "axios";

export const postStatement = (verb: string, activity: string = "element", objectId: string = "", objectName: string = "", context?: any | undefined) => {
    let user: any = JSON.parse(localStorage.getItem('LAST_VIEWED_DATA')!);
    let currentHost: string = window.location.hostname;
    let homepage = JSON.parse(localStorage.getItem('HOMEPAGE')!);

    let statement: XAPIStatementPost = {
        actor: {
            objectType: "Agent",
            account: {
                name: user ? user.userId : "000",
                homePage: homepage ? `https://${currentHost}/${homepage}` : ''
            }
        },
        verb: {
            id: `http://adlnet.gov/expapi/verbs/${verb}`,
            display: {
                'en-US': verb,
            },
        },
        object: {
            objectType: "Activity",
            id: activity == 'element' ?  `https://${currentHost}/administration/content/elements/get-element?id=${objectId}` : `http://adlnet.gov/expapi/activities/${activity}/${objectId}`,
            definition: {
                name: {
                    'en-US': objectName,
                },
                type: `http://adlnet.gov/expapi/activities/${activity}`,
                extensions: context || context?.message !== undefined
                  ? {
                      [`https://${currentHost}/karl/${verb}`]: {
                        received: `${context.message.received}`,
                        sent: `${context.message.sent}`,
                        additional:  `${context.message.additional}`
                      }
                    }
                  : {}
            }
        }
    };
    if (context !== undefined) {
      let extensionURL: string = `https://${currentHost}/administration/actor/${user.userId}`;
      statement.context = {};
      if (context.chapterId != null) {
        statement.context.contextActivities = {
          "parent": [
            {
              "id": `https://${currentHost}/chapter/${context.chapterId}`,
              "objectType": "Activity"
            }
          ]
        }
      }
      if (context.groupId != null && context.groupName != null) {
        statement.context.team = {
          objectType: "Group",
          account: {
            homePage: `https://${currentHost}/administration/group/${context.groupId}`,
            name: context.groupName ? context.groupName : ""
          }
        }
      }
      if (context.email != null) {
        statement.context.extensions = {
          [extensionURL]: {actor: {objectType: "Agent", mbox: `mailto:${context.email}`}}
        }
      }
    }

    const axiosInstance = axios.create({
        baseURL: TraxLrsUrl,
        auth: TraxLrsCredentials
    });

    if(user) {
        // At request level
        axiosInstance.post<XAPIStatementResponse>(`/statements`, statement, {
            headers: {
                'X-Experience-API-Version': "1.0.3"
            },
            rejectUnauthorized: false,//add when working with https sites
            requestCert: false,//add when working with https sites
            agent: false,//add when working with https sites
        });
    }
}
