import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {range} from 'lodash';

import intlMessages from './intlMessages';
import './_relative-position-slider.scss';

interface IPropTypes {
  accentColor: string
  relativePositionPercentage: number
}

const LABEL_HEIGHT: number = 90;
const SLIDER_HEIGHT: number = 350;
const STEP_MARKER_HEIGHT: number = 2;
const ZONES: number = 5;

export const RelativePositionSlider: FC<IPropTypes> = (props: IPropTypes) => {
  const {accentColor, relativePositionPercentage} = props;

  let displayedPercentage: string;
  let thumbTopPosition: number;

  if (relativePositionPercentage <= 0.1) {
    displayedPercentage = '10%';
    thumbTopPosition = 0;
  } else if (relativePositionPercentage <= 0.2) {
    displayedPercentage = '10-20%';
    thumbTopPosition = 0.2;
  } else if (relativePositionPercentage <= 0.5) {
    displayedPercentage = '20-50%';
    thumbTopPosition = 0.4;
  } else if (relativePositionPercentage <= 0.8) {
    displayedPercentage = '20-50%';
    thumbTopPosition = 0.6;
  } else {
    displayedPercentage = '20%';
    thumbTopPosition = 0.8;
  }

  const heightWithoutStepMarkers: number = SLIDER_HEIGHT - (ZONES * STEP_MARKER_HEIGHT);
  const passedStepsHeight: number = ((thumbTopPosition * ZONES) + 1) * STEP_MARKER_HEIGHT;
  const thumbHeight: number = heightWithoutStepMarkers * (1 / ZONES);

  return (
    <div className="landing-page__relative-position-slider" style={{height: SLIDER_HEIGHT}}>
      <div className="landing-page__relative-position-slider__track">
        {range(ZONES + 1).map((index) => (
          <div
            className="landing-page__relative-position-slider__track-step"
            key={index}
            style={{
              height: STEP_MARKER_HEIGHT,
              top: `${index * 20}%`,
            }}
          />
        ))}
      </div>

      <div
        className="landing-page__relative-position-slider__thumb"
        style={{
          backgroundColor: accentColor,
          height: thumbHeight,
          top: (heightWithoutStepMarkers * thumbTopPosition) + passedStepsHeight,
        }}
      />

      <div
        className="landing-page__relative-position-slider__label"
        style={{
          height: LABEL_HEIGHT,
          top: (heightWithoutStepMarkers * thumbTopPosition) + passedStepsHeight - ((LABEL_HEIGHT - thumbHeight) / 2),
        }}
      >
        <div className="landing-page__relative-position-slider__label-content">
          {relativePositionPercentage <= 0.5 ?
            <FormattedMessage {...intlMessages.topPosition} />
            :
            <FormattedMessage {...intlMessages.lowerPosition} />
          }

          &nbsp;

          <strong style={{color: accentColor}}>
            {displayedPercentage}
          </strong>
          
          &nbsp;

          <FormattedMessage {...intlMessages.ofUsers} />
        </div>
      </div>
    </div>
  );
};
