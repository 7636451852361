import cx from 'classnames';
import React, { FC } from 'react';
import {Link} from 'react-router-dom';

import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {ITab} from '../tabsList';
import { steelGray } from '@u4i/styles/styles-variables';
import './_tabs-list-item.scss';

interface IPropTypes {
  activeTabId?: string;
  currentLocale: LanguageEnum;
  tab: ITab;
}

export const TabsListItem: FC<IPropTypes> = (props: IPropTypes) => (
  <li
    className={cx({
      'main-layout__tabs-list-item__entry': true,
      'main-layout__tabs-list-item__entry--mobile-hidden': props.tab.hideOnMobile,
    })}
    key={props.tab.id}
  >
    {props.tab.link &&
      <a
        className="main-layout__tabs-list-item__entry-link"
        href={props.tab.link[props.currentLocale]}
      >
        <div className="main-layout__tabs-list-item__entry-icon">
          {props.tab.icon &&
            React.cloneElement(props.tab.icon, {color: steelGray, style: {transition: 'color .5s'}})
          }
        </div>

        {props.tab.label}
      </a>
    }

    {props.tab.route &&
      <Link
        className={cx({
          'main-layout__tabs-list-item__entry-link': true,
          'main-layout__tabs-list-item__entry-link--active': props.tab.id === props.activeTabId,
        })}
        to={`/${props.tab.route}`}
      >
        <div className="main-layout__tabs-list-item__entry-icon">
          {props.tab.icon &&
            React.cloneElement(props.tab.icon, {color: steelGray, style: {transition: 'color .5s'}})
          }
        </div>

        {props.tab.label}
      </Link>
    }
  </li>
);
