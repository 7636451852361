import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import PlaybackRateDropdownEntry from './PlaybackRateDropdownEntry';

import './_playback-rate-dropdown.scss';

interface IProps {
  availablePlaybackRates: number[]
  isOpen: boolean
  onDropdownToggle: () => any
  onPlaybackRateSet: () => any
  playbackRate: number
}

const PlaybackRateDropdown: FC<IProps> = (props: IProps) => {
  const {
    availablePlaybackRates,
    isOpen,
    playbackRate,
    onDropdownToggle,
    onPlaybackRateSet
  } = props;

  return (
    <div className="youtube-player__playback-rate-dropdown">
      <button
        className="youtube-player__playback-rate-dropdown__button"
        onClick={onDropdownToggle}
      >
        <FontAwesome name="angle-double-right" />
      </button>

      <ul
        className={cx({
          'list-unstyled': true,
          'youtube-player__playback-rate-dropdown__list': true,
          'youtube-player__playback-rate-dropdown__list--open': isOpen,
        })}
      >
        {availablePlaybackRates.map(rate => (
          <PlaybackRateDropdownEntry
            isActive={rate === playbackRate}
            key={rate}
            onClick={onPlaybackRateSet}
            value={rate}
          />
        ))}
      </ul>
    </div>
  );
}

export default PlaybackRateDropdown;
