import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import successImage from '@u4i/assets/svg/sent-mail.svg';
import intlMessages from './intlMessages';
import { Modal } from 'antd';
import UserStore from '@u4i/state/UserStore';
import './_registration-success-modal.scss';

interface IProps {
  userStore: UserStore
}

const RegistrationSuccessModal: FC<IProps> = (props: IProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    isRegistrationSuccessModalVisible,
    registrationEmail,
    registrationSuccessModalHandle,
    registrationModalHandle,
    resendUserInvitation
  } = props.userStore;

  const onClickChangeEmail = () => {
    registrationSuccessModalHandle(false);
    registrationModalHandle(true);
  }

  const handleInvitationResend = () => {
    setErrorMessage('');

    resendUserInvitation(registrationEmail).then(() => {
      setErrorMessage('');
    }).catch((error) => {
      if(error.response.status === 422) {
        setErrorMessage(error.response.data.errorMessage);
      }
    });
  };

  return(
    <Modal
      centered
      mask={true}
      maskClosable={true}
      footer={null}
      onCancel={() => registrationSuccessModalHandle(false)}
      open={isRegistrationSuccessModalVisible}
      style={{minWidth: '15%'}}
    >
      <img className="registration-success-modal__image" src={successImage} alt="" />

      <h4 className="registration-success-modal__heading">
        <FormattedMessage {...intlMessages.modalHeading} />
      </h4>

      <span className="registration-success-modal__text">
        <FormattedMessage
          {...intlMessages.text}
          values={{ email: registrationEmail, br: <br /> }}
        />
      </span>

      <div className="registration-success-modal__info">
        <span className="registration-success-modal__supporting-text">
          <FormattedMessage {...intlMessages.hint} />
        </span>&nbsp;

        <button className="registration-success-modal__link" onClick={handleInvitationResend} type="button">
          <FormattedMessage {...intlMessages.resendEmail} />
        </button>&nbsp;

        <span className="registration-success-modal__supporting-text">
          <FormattedMessage {...intlMessages.or} />
        </span>&nbsp;

        <button className="registration-success-modal__link" onClick={onClickChangeEmail} type="button">
          <FormattedMessage {...intlMessages.changeEmail} />
        </button>
      </div>

      {errorMessage.trim() != "" &&
        <span className="registration-success-modal__confirmation-email">{errorMessage}</span>
      }
    </Modal>
  )
}

export default RegistrationSuccessModal;