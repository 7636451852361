import {AxiosInstance} from 'axios';
import {Answers, ExerciseParser, IAttempt, IExercise} from '@u4i/parsers/ExerciseParser';


const mapRequestAnswers = (answers) => {
  if(!answers) {
    return null;
  }
  const answ = {} as Answers;
  for (let i = 0; i < answers.length; i++) {
    const questionId = answers[i].exercise_question_id
    answ[questionId] = {
      answer: answers[i].content,
      files: answers[i].files.map(f => new File([], f.filename)),
      graderFeedback: answers[i].grader_feedback,
    }
  }

  return answ;
}

export class ExercisesApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async createAttempt(exerciseId: string): Promise<IAttempt> {
    const response = await this.apiClient.post(`/v2/exercises/${exerciseId}/attempt`);

    return { 
      attemptId: response.data.id,
      status: response.data.user_challenge_attempt_status,
      answers: mapRequestAnswers(response.data.answers)
    }
  }

  async fetchExerciseData(exerciseId: string): Promise<IExercise> {
    const response = await this.apiClient.get(`/v2/exercises/${exerciseId}`);

    return ExerciseParser.parse(response.data);
  }

  async submitAnswer(
    exerciseAttemptId: string,
    questionId: string,
    content: string,
    files?: Array<File>,
  ): Promise<void> {
    const formData = new FormData();

    formData.append('content', content);
    formData.append('exercise_attempt_id', exerciseAttemptId);

    if (files) {
      files.forEach((file, index) => {
        formData.append(`answer_files[${index}]`, file);
      });
    }

    await this.apiClient.post(`/v2/exercises/questions/${questionId}/answer`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  
  async submitAttempt(exerciseAttemptId: string): Promise<void> {
    await this.apiClient.post(`/v2/exercises/${exerciseAttemptId}/submit`);
  }
}
