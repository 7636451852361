import Parser from '@u4i/utils/Parser';

export interface IGroupProgress {
  attemptsCount: {
    failed: number;
    passed: number;
    submitted: number;
    total: number;
  };
  groupId: string;
  groupName: string;
}

export const GroupProgressParser = new Parser<IGroupProgress>(groupProgress => ({
  attemptsCount: {
    failed: parseInt(groupProgress.attemptsCount.failed, 10),
    passed: parseInt(groupProgress.attemptsCount.passed, 10),
    submitted: parseInt(groupProgress.attemptsCount.submitted, 10),
    total: parseInt(groupProgress.attemptsCount.failed, 10)
      + parseInt(groupProgress.attemptsCount.passed, 10)
      + parseInt(groupProgress.attemptsCount.submitted, 10),
  },
  groupId: groupProgress.id,
  groupName: groupProgress.groupName,
}));
