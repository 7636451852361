import {defineMessages} from 'react-intl';

export default defineMessages({
  hint: {
    defaultMessage: 'If you wait too long,',
    id: 'RegistrationSuccess.Hint',
  },
  link: {
    defaultMessage: 'click here',
    id: 'RegistrationSuccess.Link',
  },
  text: {
    defaultMessage: 'Thank you for registering.{br}You will be now redirected to the main page.',
    id: 'RegistrationSuccess.Text',
  },
  title: {
    defaultMessage: 'You are ready to go!',
    id: 'RegistrationSuccess.Title',
  },
});
