import {defineMessages} from 'react-intl';

export default defineMessages({
  answersHeading: {
    defaultMessage: 'Your answers',
    id: 'DiscussPage.AnswersHeading',
  },
  discussionHeading: {
    defaultMessage: 'Discussion',
    id: 'DiscussPage.DiscussionHeading',
  },
  instructionsHeading: {
    defaultMessage: 'Instructions',
    id: 'DiscussPage.InstructionsHeading',
  },
});
