import {defineMessages} from 'react-intl';

export default defineMessages({
  challengesStatusesMessage: {
    defaultMessage: `{challengesCompleted} of {totalChallenges} challenges 
      passed {challengesInGrading, plural, =0 {}
      other {({challengesInGrading} in grading)}
    }`,
    id: 'Player.Navigation.ChaptersList.ChaptersListItem.ChapterStatistics.ChallengesStatusesMessagelong',
  },
  viewedStatusMessage: {
    defaultMessage: '{viewedTopicsCount} of {topicsCount} content viewed',
    id: 'Player.Navigation.ChaptersList.ChaptersListItem.ChapterStatistics.ViewedStatusMessage',
  },
});
