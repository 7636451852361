import React, { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import intlMessages from "./intlMessages";
import { Col, Modal, Row } from "antd";
import RegistrationForm from "@u4i/common/forms/RegistrationForm";
import { IRootStore } from "@u4i/state/RootStore";
import CircularProgress from "@u4i/common/CircularProgress";
import { CourseDetails } from "@u4i/modules/Checkout/SummaryPage/CourseDetails/CourseDetails";
import "./_registration-modal.scss";

interface IProps extends RouteComponentProps {
  rootStore: IRootStore
}

const RegistrationModal: FC<IProps> = inject("rootStore")(
  observer((props: IProps) => {
    const {
      isLoginAndPurchase,
      isRegistrationModalVisible,
      purchasingCourseId,
      successRedirectionPathname,
      loginModalHandle,
      registrationModalHandle
    } = props.rootStore.userStore;

    
    const {
      course,
      isCourseLoading,
      loadCourse
    } = props.rootStore.checkoutPurchaseStore!;

    let indexOfCourseSlug: number;
    let courseSlug: string;

    if(isLoginAndPurchase){
      indexOfCourseSlug = props.location.pathname.indexOf('/skills/');
      
      if(indexOfCourseSlug > -1) {
        courseSlug = props.location.pathname.substring(indexOfCourseSlug + 8);
      }
    }

    useEffect(() => {
      if(courseSlug)
        loadCourse(courseSlug);       
    }, []);

    const navigateToCourse = () => {
      //TODO: do nothing on click
    };
    
    const handleFormSubmission = (data) => {
      return props.rootStore.userStore.register(data, props.location.pathname);
    };

    const loginHereClicked = () => {
      registrationModalHandle(false);
      loginModalHandle(true, successRedirectionPathname, isLoginAndPurchase, purchasingCourseId);
    }

    return (
      <Modal
        centered
        mask={true}
        maskClosable={false}
        footer={null}
        onCancel={() => registrationModalHandle(false)}
        open={isRegistrationModalVisible}
        title={<h3 style={{textAlign:'center', fontWeight: 500}}><FormattedMessage {...intlMessages.modalTitle} /></h3>}
        style={{minWidth: isLoginAndPurchase ? '60%': '25%', height: '75%'}}
      >
        {!isLoginAndPurchase ? 
          <>
            <RegistrationForm 
              onSubmit={handleFormSubmission} 
              rootStore={props.rootStore}
            />
          
            <div className="registration-modal__info">
              <p className="registration-modal__supporting-text">
                <FormattedMessage {...intlMessages.hasAccount} />
              </p>
              
              <button
                className="registration-modal__switch-button"
                onClick={loginHereClicked}
                type="button"
              >
                <FormattedMessage {...intlMessages.loginHere} />
              </button>
            </div>
          </>
          :
          <Row justify='center'>
            <Col span={12}>
              <RegistrationForm 
                onSubmit={handleFormSubmission}
                rootStore={props.rootStore}
              />
            
              <div className="registration-modal__info">
                <p className="registration-modal__supporting-text">
                  <FormattedMessage {...intlMessages.hasAccount} />
                </p>
                
                <button
                  className="registration-modal__switch-button"
                  onClick={loginHereClicked}
                  type="button"
                >
                  <FormattedMessage {...intlMessages.loginHere} />
                </button>
              </div>
            </Col>

            <Col span={10} style={{
              background: 'rgb(243 243 244)', 
              display: 'flex', 
              justifyContent: 'center', 
              padding: 20, 
              marginLeft: 20
            }}>
              <div className="register__sidebar__inner-wrapper">
                {isCourseLoading ?
                  <CircularProgress />
                  :
                  course &&
                    <CourseDetails
                      course={course}
                      disableHovering={true}
                      handleCardClick={navigateToCourse}
                    />
                }
              </div>
            </Col>
          </Row>
        }
      </Modal>
    )
  })
)

export default withRouter(RegistrationModal);
