import cx from 'classnames';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {DataColumn} from './DataColumn';
import {DataTableStore} from './DataTableStore';
import {Pagination} from './components/Pagination';
import {TableHeaderCell} from './components/TableHeaderCell';
import {Toolbar} from './components/Toolbar';
import intlMessages from './intlMessages';
import FormatDate from '../FormatDate';
import './_data-table.scss';

interface IPropTypes {
  isAttemptsView?: boolean
  store: DataTableStore<any>
}

class DataTable extends React.Component<IPropTypes> {
  componentWillUnmount() {
    this.props.store.destroy();
  }

  isDateRecord(date: any): boolean {
    const newDate = new Date(date);
    let isValidDate = !isNaN(newDate.getTime());
    return isValidDate && newDate.getHours() != 0 && newDate.getMinutes() != 0 && newDate.getSeconds() != 0;
  }

  render() {
    const {store} = this.props;

    return (
      <div className="data-table">
        <Toolbar
          currentItemsPerPage={store.itemsPerPage}
          onChangeItemsPerPage={store.changeItemsPerPage}
          onToggleFilters={store.toggleFilters}
          reloadingInProgress={store.reloading}
          totalItems={store.totalItems}
        />

        <div className="data-table__table-wrapper" style={{minHeight: this.props.isAttemptsView ? 500 : "auto" }}>
          <table cellPadding={0} cellSpacing={0} className="data-table__table">
            <thead>
              <tr>
                {store.columns.map(column => (
                  <TableHeaderCell
                    column={column}
                    currentSortDirection={store.currentSortDirection}
                    currentSortedColumnId={store.currentSortedColumnId}
                    filters={store.filters ?
                      store.filters.filter((filter) => filter.attachedToColumn === column.id)
                      :
                      []
                    }
                    key={column.id}
                    onChangeSorting={store.changeSorting}
                    reloadingInProgress={store.reloading}
                    showFilters={store.showFilters}
                    sortable={(column instanceof DataColumn) && column.sortable}
                  />
                ))}
              </tr>
            </thead>

            <tbody>
              {(!store.reloading && store.data.length === 0) &&
                <tr className="data-table__row">
                  <td colSpan={store.visibleColumnsCount}>
                    <div
                      className={cx({
                        'data-table__cell-content': true,
                        'data-table__cell-content--center': true,
                        'data-table__cell-content--no-items-message': true,
                      })}
                    >
                      <FormattedMessage {...intlMessages.noItems} />
                    </div>
                  </td>
                </tr>
              }

              {store.data.map((row, index) => (
                <tr className="data-table__row" key={index}>
                  {store.columns.map(column => (
                    <td key={column.id} style={{width: column.width}}>
                      <div
                        className={cx({
                          'data-table__cell-content': true,
                          'data-table__cell-content--center': column.centerContent,
                        })}
                      >
                        {(column instanceof DataColumn) ?
                          <React.Fragment>
                            {column.contentFormatter ?
                              column.contentFormatter(row[column.id]) : <div className="data-table__cell-default-content-formatter" title={row[column.id]}>
                                {this.isDateRecord(row[column.id]) ? <FormatDate source={row[column.id]}/> : row[column.id] }
                              </div>
                            }
                          </React.Fragment>
                          :
                          column.content(row)
                        }
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {(store.paginationEnabled && store.totalPages > 1) &&
          <Pagination
            isBlocked={store.reloading}
            onPageChange={store.changePage}
            page={store.currentPage}
            totalPages={store.totalPages}
          />
        }
      </div>
    );
  }
}

const WrappedComponent = observer(DataTable);

export {WrappedComponent as DataTable};
