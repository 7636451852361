import React, { FC } from 'react';
import {BuyButton} from './components/BuyButton/BuyButton';
import {Navigation} from './components/Navigation/Navigation';
import {PriceLabel} from './components/PriceLabel/PriceLabel';

import './_menu-bar.scss';

interface IProps {
  hasChallenges: boolean
  isDiscounted: boolean
  isFree: boolean
  priceDiscount?: string | null
  priceNet: string
  showCertificate: boolean
  onBuyButtonClick: () => void
};

export const MenuBar: FC<IProps> = (props: IProps) => {
  const {
    hasChallenges,
    isDiscounted,
    isFree,
    priceDiscount,
    priceNet,
    showCertificate,
    onBuyButtonClick
  } = props;

  return (
    <div className="course-detail-page__menu-bar">
      <div className="container">
        <div className="course-detail-page__menu-bar__content">
          <Navigation
            hasChallenges={hasChallenges}
            showCertificate={showCertificate}
          />

          <div className="course-detail-page__menu-bar__buying-controls">
            {!isFree &&
              <PriceLabel
                isDiscounted={isDiscounted}
                priceDiscount={priceDiscount}
                priceNet={priceNet}
              />
            }
            <BuyButton onClick={onBuyButtonClick} />
          </div>
        </div>
      </div>
    </div>
  )
};
