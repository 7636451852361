import FontAwesome from 'react-fontawesome';
import React from 'react';
import {action, computed, observable,makeObservable} from 'mobx';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {observer} from 'mobx-react';
import {DottedNavigation} from '@u4i/common/DottedNavigation';
import {ErrorMessage} from '../../formsSystem/ErrorMessage';
import {Field, FieldModel, ReactiveForm} from '../../formsSystem';
import {GradingGuidelines} from './components/GradingGuidelines';
import {GradingSubmitButton} from './components/GradingSubmitButton';
import {NumericField, NumericFieldPropTypes} from '../../formsSystem/fields/NumericField';
import {QuestionContent} from './components/QuestionContent';
import {QuestionNavigation} from './components/QuestionNavigation';
import {reactiveFormsValidators} from '../../formsSystem/validators';
import {StudentAnswer} from './components/StudentAnswer';
import {TextareaField, TextareaFieldPropTypes} from '../../formsSystem/fields/TextareaField';
import intlMessages from './intlMessages';
import './_attempt-grading-form.scss';

interface IPropTypes extends WrappedComponentProps {
  editMode: boolean
  graderEvaluationStatus: string
  graderFeedback?: string
  handlePassedFailedClick: (graderEvaluationStatus: string) => void;
  questions: Array<{
    content: string;
    criterions: string;
    graderFeedback: string;
    id: string;
    modelAnswer?: string;
    possibleScore: number;
    studentAnswer: string;
    studentAnswerFiles: Array<{
      fileName: string;
      url: string;
    }>;
    userScore: number | any;
  }>;
  triggerSubmitAttempt: boolean
}

interface ResultModel {
  evaluations: {
    [questionId: string]: {
      feedback: string;
      points: number;
    };
  };
  feedback: string;
  status: 'passed' | 'failed';
}

const AttemptGradingForm = observer(class AttemptGradingForm extends ReactiveForm<IPropTypes, ResultModel> {
  public displayedFormPage: number = 1;
  public attemptSubmitTriggered: boolean = false;
  protected formModel: {
    evaluations: {
      [questionId: string]: {
        feedback: FieldModel<string>;
        points: FieldModel<number>;
      };
    };
    feedback: FieldModel<string>;
    status: FieldModel<'passed' | 'failed'>;
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      displayedFormPage: observable,
      totalFormElements: computed,
      totalQuestions: computed,
      handleBackClick: action.bound,
      handleNextClick: action.bound,
      handleFailedSubmit: action.bound,
      handlePassedSubmit: action.bound,
      navigateToQuestion: action.bound
    });

    this.initFormModel(this.props);
  }

  initFormModel(props: IPropTypes) {
    this.formModel = {
      evaluations: {},
      feedback: new FieldModel<string>(props.editMode ? props.graderFeedback : ""),
      status: new FieldModel<'passed' | 'failed'>(null)
    };

    if(props.questions && props.questions.length > 0) {
      for (const question of (props as IPropTypes).questions) {
        this.formModel.evaluations[question.id] = {
          feedback: new FieldModel<string>(props.editMode ? question.graderFeedback : ""),
          points: new FieldModel<number>(props.editMode ? question.userScore : null),
        };
      }
    }
  }

  showQuestionsElement() {
    return this.props.questions && this.props.questions.length > 0;
  }

  get totalFormElements() {
    return this.totalQuestions + 1;
  }

  get totalQuestions() {
    return this.props.questions ? this.props.questions.length : 0;
  }

  handleBackClick() {
    if (this.displayedFormPage > 1) {
      this.displayedFormPage--;
    }
  }

  handleNextClick() {
    if (this.displayedFormPage < this.totalFormElements) {
      this.displayedFormPage++;
    }
  }

  handleFailedSubmit(event) {
    event.preventDefault();

    if(this.props.editMode) {
      this.props.handlePassedFailedClick('failed');
    } else {
      this.formModel.status.changeValue('failed');
      this.handleSubmit();
    }
  }

  handlePassedSubmit(event) {
    event.preventDefault();

    if(this.props.editMode) {
      this.props.handlePassedFailedClick('passed');
    } else {
      this.formModel.status.changeValue('passed');
      this.handleSubmit();
    }
  }

  componentDidUpdate() {
    if(this.props.triggerSubmitAttempt && !this.attemptSubmitTriggered) {
      this.formModel.status.changeValue(this.props.graderEvaluationStatus);
      this.handleSubmit();
      this.attemptSubmitTriggered = true;
    }
  }

  navigateToQuestion(targetQuestionIndex: number) {
    this.displayedFormPage = targetQuestionIndex + 1;
  }

  renderContent() {
    const pointsErrorMinValue = this.props.intl.formatMessage(intlMessages.pointsErrorMinValue);

    return (
      <div className="reactive-forms__attempt-grading-form">
        {this.showQuestionsElement() &&
          <div className="reactive-forms__attempt-grading-form__dotted-navigation">
            <DottedNavigation
              activeIndex={this.displayedFormPage - 1}
              dotCount={this.props.questions.length}
              onDotClick={this.navigateToQuestion}
            />
          </div>
        }

        {this.showQuestionsElement() && this.props.questions.map((question, index) => {
          const pointsErrorMaxValue = this.props.intl.formatMessage(
            intlMessages.pointsErrorMaxValue,
            {possibleScore: question.possibleScore},
          );

          return (
            <div
              className="reactive-forms__attempt-grading-form__question"
              hidden={index + 1 !== this.displayedFormPage}
              key={question.id}
            >
              <div className="reactive-forms__attempt-grading-form__question-heading">
                <FontAwesome
                  className="far reactive-forms__attempt-grading-form__question-icon"
                  name="question-circle"
                />

                <div className="reactive-forms__attempt-grading-form__question-header-wrapper">
                  <h4 className="reactive-forms__attempt-grading-form__question-header">
                    <FormattedMessage
                      {...intlMessages.questionNumberHeading}
                      values={{current: this.displayedFormPage, total: this.totalQuestions}}
                    />
                  </h4>

                  <QuestionContent content={question.content} />
                </div>
              </div>

              <div className="reactive-forms__attempt-grading-form__question-wrapper">
                <div className="reactive-forms__attempt-grading-form__answers-wrapper">
                  <StudentAnswer studentAnswer={question.studentAnswer} />

                  <h4 className="reactive-forms__attempt-grading-form__student-files-heading">
                    <FormattedMessage {...intlMessages.studentFiles} />
                  </h4>

                  {question.studentAnswerFiles.map((file, index) => (
                    <a download={file.fileName} href={file.url} key={index}>
                      {file.fileName}
                    </a>
                  ))}
                </div>

                <GradingGuidelines criterions={question.criterions} modelAnswer={question.modelAnswer} />
              </div>

              <div>
                <h4>
                  <FormattedMessage {...intlMessages.graderFeedback} />
                </h4>

                <div className="reactive-forms__attempt-grading-form__points-wrapper">
                  <Field <NumericFieldPropTypes>
                    component={NumericField}
                    componentProps={{
                      centerInputContent: true,
                      label: <FormattedMessage {...intlMessages.points} />,
                      step: 0.01,
                      width: 150
                    }}
                    model={this.formModel.evaluations[question.id].points}
                    validators={[
                      reactiveFormsValidators.maxNumberValue(question.possibleScore, pointsErrorMaxValue),
                      reactiveFormsValidators.minNumberValue(0, pointsErrorMinValue),
                    ]}
                  />

                  <div className="reactive-forms__attempt-grading-form__points-label">
                    <FormattedMessage {...intlMessages.maxScore} values={{possibleScore: question.possibleScore, strong: (...chunks) => <strong>{chunks}</strong> }} />
                  </div>
                </div>

                <Field <TextareaFieldPropTypes>
                  component={TextareaField}
                  componentProps={{
                    label: <FormattedMessage {...intlMessages.feedbackLabel} />,
                    placeholder: <FormattedMessage {...intlMessages.feedbackPlaceholder} />
                  }}
                  model={this.formModel.evaluations[question.id].feedback}
                />
              </div>
            </div>
          );
        })}

        <div
          className="reactive-forms__attempt-grading-form__evaluation"
          hidden={this.displayedFormPage !== this.totalFormElements}
        >
          {Object.keys(this.formModel.evaluations).length > 0 &&
            <div className="reactive-forms__attempt-grading-form__evaluation-heading">
              <h4 className="reactive-forms__attempt-grading-form__evaluation-header">
                <FormattedMessage {...intlMessages.summary} />
              </h4>
            </div>
          }

          <div>
            {Object.entries(this.formModel.evaluations).map(([questionId, evaluation], index) => (
              <div className="reactive-forms__attempt-grading-form__summary-entry" key={questionId}>
                <div className="reactive-forms__attempt-grading-form__summary-entry-heading">
                  <FormattedMessage {...intlMessages.questionHeading} values={{questionNumber: index + 1, strong: (...chunks) => <strong>{chunks}</strong> }} />
                </div>

                <div>
                  <FormattedMessage {...intlMessages.pointsValue} values={{points: evaluation.points.value || '?'}} />
                </div>

                <FormattedMessage {...intlMessages.summaryFeedback} />

                <div className="reactive-forms__attempt-grading-form__summary-feedback">
                  {evaluation.feedback.value}
                </div>
              </div>
            ))}
          </div>

          <div className="reactive-forms__attempt-grading-form__evaluation-heading">
            <h4 className="reactive-forms__attempt-grading-form__evaluation-header">
              <FormattedMessage {...intlMessages.evaluateAttempt} />
            </h4>
          </div>

          <div className="reactive-forms__attempt-grading-form__evaluation-wrapper">
            <div className="reactive-forms__attempt-grading-form__feedback-field">
              <Field <TextareaFieldPropTypes>
                component={TextareaField}
                componentProps={{
                  label: <FormattedMessage {...intlMessages.attemptFeedback} />,
                  placeholder: <FormattedMessage {...intlMessages.attemptFeedbackPlaceholder} />,
                }}
                model={this.formModel.feedback}
              />
            </div>

            <div className="reactive-forms__attempt-grading-form__submit-buttons-wrapper">
              <FormattedMessage {...intlMessages.submitMessage} />

              <div className="reactive-forms__attempt-grading-form__submit-buttons">
                <GradingSubmitButton onClick={this.handleFailedSubmit} type="failed">
                  <FormattedMessage {...intlMessages.submitFailed} />
                </GradingSubmitButton>

                <GradingSubmitButton onClick={this.handlePassedSubmit} type="passed">
                  <FormattedMessage {...intlMessages.submitPassed} />
                </GradingSubmitButton>
              </div>
            </div>
          </div>
        </div>

        <div className="reactive-forms__attempt-grading-form__controls">
          <QuestionNavigation
            currentQuestion={this.displayedFormPage}
            onBackClick={this.handleBackClick}
            onNextClick={this.handleNextClick}
            submitting={this.submitting}
            submitSucceeded={this.submitSucceeded}
            totalQuestions={this.totalQuestions}
          />
        </div>

        {this.generalError &&
          <div className="reactive-forms__attempt-grading-form__error-message">
            <ErrorMessage>{this.generalError}</ErrorMessage>
          </div>
        }
      </div>
    )
  }
});

const WrappedComponent = injectIntl(AttemptGradingForm);

export {WrappedComponent as AttemptGradingForm};
