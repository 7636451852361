export const alabaster = "#f8f8f8";
export const antDInfo = "#e6f4ff";
export const antDError = "#fff1f0";
export const antDSuccess = '#f6ffed';
export const antDWarn = "#fffbe6";
export const black = "#000";
export const burntOrange = "#ff7632";
export const buttercup = "#f1c40f";
export const charade = "#302e3a";
export const darkBlue = "#005a92";
export const darkGray = "#3b3b3b";
export const darkRed = "#8b0000";
export const fadedMustard = "#ffdb58";
export const fadedRed = "#ffb3a7";
export const frenchGray = "#b6b5bd";
export const gallery = "#eee";
export const green = "#80c402";
export const guardsmanRed = "#d50000";
export const iron = "#d8d8da";
export const jumbo = "#78777e";
export const lightBlue = "#b6d5eb";
export const lightGray = "#b5b5bc";
export const lightYellow = "#f5f10c";
export const mainBlue = "#0084d7";
export const malachite = "#6ca602";
export const mercury = "#e6e6e6";
export const mineShaft = "#323232";
export const neutralGray = "#979797";
export const nobel = "#bcb5b5";
export const orange = "#ff8e00";
export const porcelain = "#ecf0f1";
export const purpleHeart = "#722ed1";
export const red = "#ff0000";
export const scarpaFlow = "#545264";
export const shipGray = "#3f3d4b";
export const silver = "#bdbdbd";
export const spindle = "#b3d5eb";
export const softGreen = "#87d068";
export const steelGray = "#1c1b26";
export const tomato = "#ff5a4b";
export const torchRed = "#f5222d";
export const turquoise = "#00bba0";
export const white = "#fff";
