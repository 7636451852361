import {defineMessages} from 'react-intl';

export default defineMessages({
  challengeType: {
    defaultMessage: 'Digital Lab',
    id: 'DigitalLab.LabIntro.ChallengeType',
  },
  loadingMessage: {
    defaultMessage: 'We are preparing your lab. Please wait a moment...',
    id: 'DigitalLab.LabIntro.LoadingMessage',
  },
  start: {
    defaultMessage: 'Launch lab',
    id: 'DigitalLab.LabIntro.Start',
  },
});
