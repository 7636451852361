import {defineMessages} from 'react-intl';

export default defineMessages({
  skillsFilterAllButton: {
    defaultMessage: 'All',
    id: 'LandingPage.SectionManager.MySkills.FilterButtons.SkillsFilterAllButton',
  },
  skillsFilterCompleteButton: {
    defaultMessage: 'Completed courses',
    id: 'LandingPage.SectionManager.MySkills.FilterButtons.SkillsFilterCompleteButton',
  },
  skillsFilterUnfinishedButton: {
    defaultMessage: 'Unfinished courses',
    id: 'LandingPage.SectionManager.MySkills.FilterButtons.SkillsFilterUnfinishedButton',
  },
});
