import React, { FC, useRef, useState } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import CircularProgress from '@u4i/common/CircularProgress';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import CustomInputField from '../components/TextField/CustomInputField';
import intlMessages from './intlMessages';
import UserStore from '@u4i/state/UserStore';
import { Form } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import { observer } from 'mobx-react';

import "./_login-form.scss";

const {Item} = Form;

interface IProps extends WrappedComponentProps {
  isLoginModal?: boolean
  isLoginPage?: boolean
  userStore?: UserStore
  handleSubmit: (data) => Promise<void>
}

const LoginForm: FC<IProps> = (props: IProps) => {
  const {
    handleSubmit
  } = props;

  const {formatMessage} = props.intl;
  const recaptchaRef = useRef<any>();
  const [loginForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false);

  const emailLabel: string = formatMessage(intlMessages.emailLabel);
  const emailPresenceError: string = formatMessage(intlMessages.emailErrorsPresence);
  const emailPatternError: string = formatMessage(intlMessages.emailErrorsPresence);
  const passwordLabel: string = formatMessage(intlMessages.passwordLabel);
  const passwordErrorsPresence: string = formatMessage(intlMessages.passwordErrorsPresence);
 
  const onHandleSubmit = (values) => {
    const config = {
      email: "email",
      password: "password"
    };

    if(recaptchaRef.current) {
      const token = recaptchaRef?.current?.getValue();
      if(token && token != '') {
        values.recaptchaResponse = token;
      }
    }

    setLoading(true);
    handleSubmit(values).then(() => {
      setLoading(false);
      loginForm.resetFields();
    }).catch((error) => {
      setLoading(false);
      const { validationErrors } = error.response.data;
      loginForm.setFields(
        Object.keys(validationErrors).map((key) => {
          return {
            name: config[key],
            errors: [validationErrors[key]],
          };
        })
      );

      const timeDisabled: number = error.response.data.timeNextTries * 1000;
      setIsLoginDisabled(true);
      setTimeout(() => setIsLoginDisabled(false), timeDisabled);
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className='custom-form-fields'>
      <Form onFinish={onHandleSubmit} form={loginForm}>
        <Item
          name='email'
          rules={[
            {
              message: emailPresenceError,
              required: true,
              whitespace: true
            },
            {
              type: "email",
              message: emailPatternError,
            }
          ]}
        >
          <CustomInputField
            label={emailLabel}
            type="text"
            name="email"
            id={"login-email"}
            form={loginForm}
          />
        </Item>

        <Item
          name='password'
          rules={[
            {
              message: passwordErrorsPresence,
              required: true,
              whitespace: true
            },
          ]}
        >
          <CustomInputField
            label={passwordLabel}
            type="password"
            name="password"
            id="login-password"
            form={loginForm}
          />
        </Item>

        {props.userStore?.recaptchaKey && props.isLoginModal && !props.isLoginPage &&
          <div className="recaptcha-field" id="recaptcha-field">
            <ReCAPTCHA

              ref={recaptchaRef}
              sitekey={props.userStore?.recaptchaKey}
            />
          </div>
        }

        {props.userStore?.recaptchaKey && props.isLoginPage && !props.isLoginModal &&
          <div className="recaptcha-field" id="recaptcha-field">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={props.userStore?.recaptchaKey}
            />
          </div>
        }

        <SubmitButton disabled={isLoginDisabled || loading}>
          <FormattedMessage {...intlMessages.submitTitle} />
        </SubmitButton>
      </Form>
    </div>
  )
}

export default injectIntl(observer(LoginForm))
