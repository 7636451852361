import {defineMessages} from 'react-intl';

export default defineMessages({
  closeWindow: {
    defaultMessage: 'I understand',
    id: 'DigitalLab.NotificationWindow.CloseWindow',
  },
  message: {
    defaultMessage: 'Please remember to submit once you have finished working on this lab. Thank you.',
    id: 'DigitalLab.NotificationWindow.Message',
  },
});
