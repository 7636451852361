import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {EXTERNAL_BASE_URL} from '@u4i/constantSettings';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';

import intlMessages from './intlMessages';
import './_footer-info-links.scss';

interface IPropTypes {
  currentLocale: LanguageEnum;
}

export const FooterInfoLinks: FC<IPropTypes> = (props) => {
  const isEnglish: boolean = props.currentLocale === LanguageEnum.EN_US;

  return (
    <ul className="footer__info-pages list-unstyled">
      <li className="footer__info-pages-entry">
        <a
          className="footer__info-pages-link"
          href={isEnglish ? `${EXTERNAL_BASE_URL}/en/disclaimer` : `${EXTERNAL_BASE_URL}/de/haftungsausschluss`}
        >
          <FormattedMessage {...intlMessages.pagesDisclaimer} />
        </a>
      </li>

      <li className="footer__info-pages-entry">
        <a
          className="footer__info-pages-link"
          href={isEnglish ? `${EXTERNAL_BASE_URL}/en/privacy-policy` : `${EXTERNAL_BASE_URL}/de/datenschutz`}
        >
          <FormattedMessage {...intlMessages.pagesPrivacy} />
        </a>
      </li>

      <li className="footer__info-pages-entry">
        <a
          className="footer__info-pages-link"
          href={isEnglish ? `${EXTERNAL_BASE_URL}/en/imprint` : `${EXTERNAL_BASE_URL}/de/impressum`}
        >
          <FormattedMessage {...intlMessages.pagesImpressum} />
        </a>
      </li>
    </ul>
  );
}
