import cx from 'classnames';
import is from 'is_js';
import React, { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import screenfull, {Screenfull} from 'screenfull';
import {action, computed, observable, reaction,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import { RouteComponentProps } from "@reach/router";
import AppNavigator from '@u4i/common/AppNavigator';
import CircularProgress from '@u4i/common/CircularProgress';
import IRootStore from '@u4i/state/RootStore';
import {Alert} from '@u4i/common/Alert';
import {AlertStatusEnum} from '@u4i/common/enums/AlertStatusEnum';
import {IStorage} from '@u4i/state/ServicesInterfaces';
import {ControlBar} from './components/ControlBar';
import {DigitalLabStore} from './state/DigitalLabStore';
import {LabIntro} from './components/LabIntro';
import {NotificationWindow} from './components/NotificationWindow';
import {EndSessionNotification} from './components/EndSessionNotification';
import {SubmitWindow} from './components/SubmitWindow';
import { LabBookingButton } from '../CourseDetailPage/components/Overview/LabBookingBtn/LabBookingBtn';
import intlMessages from './intlMessages';
import './_digital-lab.scss';

declare global {
  interface Window {
    AppStream: any;
  }
}

interface ISessionError {
  errorCode: Number
  errorMessage: String
}

interface ISessionStateChangeData {
  sessionStatus: string;
  sessionTerminationReason: string;
  sessionDisconnectionReason: string;
}

interface IMatchParams extends RouteComponentProps {
  labId: string;
}

interface IPropTypes extends IMatchParams {
  match: any;
  rootStore: IRootStore;
}

const backgroundInactiveClass = 'digital-labs-page--background-inactive';
const digitalLabsPageClass = 'digital-labs-page';

export const DigitalLab = inject('rootStore')(observer(class DigitalLab extends Component<IPropTypes> {
  private readonly IS_MOBILE = is.mobile();
  private digitalLabRef = React.createRef<HTMLDivElement>();
  private digitalLabStore: DigitalLabStore;
  private storageService: IStorage;
  private appstreamEmbed: any;
  private closingLab = false;
  private introDidAnimate = false;
  private isLabClosedDisposer;
  private isLabFinishedLoadingDisposer;
  private labSubmitErrorDisposer;
  private isNotificationWindowVisible = false;
  private isSessionError = false;
  private isSessionStarted = false;
  private submittingLab = false;
  private courseId: string = '';
  showWindow: boolean;
  sizeObserver: ResizeObserver | null = null;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<DigitalLab, "closingLab" | "introDidAnimate" | "isNotificationWindowVisible" | "isSessionError" | "isSessionStarted" | "submittingLab">(this, {
      closingLab: observable,
      introDidAnimate: observable,
      isNotificationWindowVisible: observable,
      isSessionError: observable,
      isSessionStarted: observable,
      submittingLab: observable,
      showWindow: observable,
      sizeObserver: observable,
      labId: computed,
      handleBodyResize: action.bound,
      handleCloseLabClick: action.bound,
      handleCloseNotificationWindow: action.bound,
      handleFullscreenChange: action.bound,
      handleLabAfterLoading: action.bound,
      handleOpenNotificationWindow: action.bound,
      handleSessionError: action.bound,
      handleSessionStateChange: action.bound,
      handleSubmitClick: action.bound,
      markIntroAnimationFinished: action.bound,
      removeInactiveBackgroundOnError: action.bound
    });

    const {digitalLabStore, storageService} = this.props.rootStore;

    this.digitalLabStore = digitalLabStore;
    this.storageService = storageService;

    this.isLabClosedDisposer = reaction(() => this.digitalLabStore.isLabClosed, this.handleGoBackClick);
    this.isLabFinishedLoadingDisposer = reaction(() => this.digitalLabStore.isLabFinishedLoading, this.handleLabAfterLoading);
    this.labSubmitErrorDisposer = reaction(() => this.digitalLabStore.labSubmitError, this.removeInactiveBackgroundOnError);
  }

  componentDidMount() {
    document.body.classList.add(digitalLabsPageClass);

    if (this.IS_MOBILE) {
      return;
    }

    if(!this.digitalLabStore.labData) {
      this.digitalLabStore.init(this.labId);
    } else {
      this.isSessionStarted = true;
      this.introDidAnimate = true;
      this.handleLabAfterLoading()
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', this.handleFullscreenChange);
    }

    this.sizeObserver = new ResizeObserver(this.handleBodyResize);
    this.sizeObserver.observe(document.body);
  }

  componentWillUnmount() {
    this.storageService.showDigitalLabNotification.set(false);
    document.body.classList.remove(backgroundInactiveClass);
    document.body.classList.remove(digitalLabsPageClass);

    if (this.IS_MOBILE) {
      return;
    }

    if (screenfull.isEnabled) {
      screenfull.off('change', this.handleFullscreenChange);
    }

    this.digitalLabStore.reset();

    if (this.appstreamEmbed) {
      this.appstreamEmbed.removeEventListener(window.AppStream.Embed.Events.SESSION_ERROR, this.handleSessionError);
      this.appstreamEmbed.removeEventListener(window.AppStream.Embed.Events.SESSION_STATE_CHANGE, this.handleSessionStateChange);
    }

    if (this.sizeObserver) {
      this.sizeObserver.disconnect();
    }
    this.isLabClosedDisposer();
    this.isLabFinishedLoadingDisposer();
    this.labSubmitErrorDisposer();
  }

  get labId() {
    return this.props.match.params.labId;
  }

  handleBodyResize() {
    if(this.digitalLabRef.current && this.digitalLabRef.current.parentNode) {
      const parentClientRect = (this.digitalLabRef.current.parentNode as HTMLElement).getBoundingClientRect();

      this.digitalLabRef.current.style.height = `${parentClientRect.height - 8}px`;
    }
  }

  handleCloseLabClick() {
    if (!this.isSessionError) {
      this.appstreamEmbed.endSession();

      this.closingLab = true;
    }

    this.isSessionError = false;
  }

  handleCloseNotificationWindow() {
    this.isNotificationWindowVisible = false;

    if (this.showWindow) {
      this.storageService.showDigitalLabNotification.set(false);
      this.showWindow = false;
    }

    document.body.classList.remove(backgroundInactiveClass);
  }

  handleFullscreenChange() {
    this.digitalLabStore.changeFullscreenStatus((screenfull as Screenfull).isFullscreen);
  }

  handleFullscreenClick = () => {
    if (screenfull.isEnabled) {
      if (this.digitalLabStore.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request(this.digitalLabRef.current as Element | undefined);
      }
    } else {
      alert('Your browser doesn\'t support fullscreen mode');
    }
  };

  handleGoBackClick = () => {
    document.body.classList.remove(backgroundInactiveClass);

    const redirectionItem = AppNavigator.historyRecord.find(record => record.location.pathname.includes('player'));
    if (!this.isSessionError) {
      AppNavigator.directPush(redirectionItem.location);
    }
  };

  handleLabAfterLoading() {
    let showNotificationWidow = this.storageService.showDigitalLabNotification.get();

    if (!this.labId || this.digitalLabStore.labError) {
      this.storageService.showDigitalLabNotification.set(false);
      showNotificationWidow = false;
    }

    if (showNotificationWidow === null) {
      this.storageService.showDigitalLabNotification.set(true);
      this.showWindow = true;
      this.handleOpenNotificationWindow();
    } else {
      this.showWindow = showNotificationWidow;
    }

    if (this.digitalLabStore.labData) {
      const appstreamOptions = {
        sessionURL: this.digitalLabStore.labData.streamingUrl.url,
        userInterfaceConfig: {
          [window.AppStream.Embed.Options.HIDDEN_ELEMENTS]:[
            window.AppStream.Embed.Elements.FULLSCREEN_BUTTON,
            window.AppStream.Embed.Elements.END_SESSION_BUTTON,
            window.AppStream.Embed.Elements.CLIPBOARD_BUTTON,
            window.AppStream.Embed.Elements.COPY_LOCAL_BUTTON,
            window.AppStream.Embed.Elements.PASTE_REMOTE_BUTTON,
            window.AppStream.Embed.Elements.SETTINGS_BUTTON,
            window.AppStream.Embed.Elements.STREAMING_MODE_BUTTON,
            window.AppStream.Embed.Elements.SCREEN_RESOLUTION_BUTTON,
            window.AppStream.Embed.Elements.REGIONAL_SETTINGS_BUTTON,
            window.AppStream.Embed.Elements.FULLSCREEN_BUTTON,
            window.AppStream.Embed.Elements.END_SESSION_BUTTON,
          ]
        },
      };

      if (!this.appstreamEmbed) {
        this.appstreamEmbed = new window.AppStream.Embed("appstream-container", appstreamOptions);

        this.appstreamEmbed.addEventListener(window.AppStream.Embed.Events.SESSION_ERROR, this.handleSessionError);
        this.appstreamEmbed.addEventListener(window.AppStream.Embed.Events.SESSION_STATE_CHANGE, this.handleSessionStateChange);
      }
    }
  }

  handleOpenNotificationWindow() {
    this.isNotificationWindowVisible = true;

    document.body.classList.add(backgroundInactiveClass);
  }

  handleSessionError(data: ISessionError) {
    this.isSessionError = true;
  }

  handleSessionStateChange(data: ISessionStateChangeData) {
    if (data.sessionStatus === 'Started') {
      this.isSessionStarted = true;
    }

    if (data.sessionStatus === 'Ended' && data.sessionTerminationReason === 'USER_ENDED_SESSION') {
      if (this.closingLab && !this.isSessionError) {
        this.digitalLabStore.closeLab();
      }
      if (this.submittingLab) {
        this.digitalLabStore.submitLab();
      }

      this.isSessionError = false;
    }
  }

  handleSubmitClick() {
    if (!document.body.classList.contains(backgroundInactiveClass)) {
      document.body.classList.add(backgroundInactiveClass);
    }

    this.submittingLab = true;
    this.digitalLabStore.submitLab();
    this.isSessionError = false;
  }

  markIntroAnimationFinished() {
    this.introDidAnimate = true;

    this.digitalLabStore.setFetching();
  }

  removeInactiveBackgroundOnError() {
    if (document.body.classList.contains(backgroundInactiveClass)) {
      document.body.classList.remove(backgroundInactiveClass);
    }
  }

  render() {
    this.courseId = window.sessionStorage.getItem('courseId') || '';

    if (this.IS_MOBILE) {
      return (
        <div className="digital-lab__error-wrapper">
          <Alert
            heading={<FormattedMessage {...intlMessages.mobileHeading} />}
            message={<FormattedMessage {...intlMessages.mobileMessage} />}
            type={AlertStatusEnum.WARNING}
          />
        </div>
      );
    }

    if (!this.labId || this.digitalLabStore.labError || this.digitalLabStore.labSubmitError) {
      if (this.digitalLabStore.errorMessageDescription && this.digitalLabStore.errorMessageDescription.indexOf("currently disabled") != -1) {
        return (
          <div className="digital-lab__error-wrapper">
            <Alert
              heading={ <FormattedMessage {...intlMessages.disabledErrorHeading} /> }
              message={ <FormattedMessage {...intlMessages.disabledErrorMessage} /> }
              type={ AlertStatusEnum.ERROR }
            />
          </div>
        );
      } else if (this.digitalLabStore.errorMessageDescription && (this.digitalLabStore.errorMessageDescription.indexOf("not available") || this.digitalLabStore.errorMessageDescription.indexOf("busy") != -1)) {
        return (
          <div className="digital-lab__error-wrapper">
            <Alert
              heading={ <FormattedMessage {...intlMessages.busyErrorHeading} /> }
              message={ <FormattedMessage {...intlMessages.busyErrorMessage} /> }
              type={ AlertStatusEnum.ERROR }
            />
            {this.digitalLabStore.labHasScheduler && <LabBookingButton btnClassName="dl_error_book_btn" courseId={this.courseId} />}
          </div>
        );
      } else {
        return (
          <div className="digital-lab__error-wrapper">
            <Alert
              heading={ <FormattedMessage {...intlMessages.generalErrorHeading} /> }
              message={ <FormattedMessage {...intlMessages.generalErrorMessage} /> }
              type={ AlertStatusEnum.ERROR }
            />
            {this.digitalLabStore.labHasScheduler && <LabBookingButton btnClassName="dl_error_book_btn" courseId={this.courseId} />}
          </div>
        );
      }
    }

    return (
      <div className="digital-lab" ref={this.digitalLabRef}>
        <LabIntro
          isVisible={this.digitalLabStore.fetching}
          onLoadingTransitionEnd={this.markIntroAnimationFinished}
        />

        {this.digitalLabStore.labData &&
          <div
            className={cx({
              'digital-lab__header': true,
              'digital-lab__header--fullscreen': this.digitalLabStore.isFullscreen,
            })}
          >
            <ControlBar
              isVisible={this.introDidAnimate && this.isSessionStarted}
              labName={this.digitalLabStore.labData.title}
              onCloseLabClick={this.handleCloseLabClick}
              onFullscreenClick={this.handleFullscreenClick}
              onSubmitClick={this.handleSubmitClick}
              showExpandScreenButton={!this.digitalLabStore.isFullscreen}
              sessionCountDown={this.digitalLabStore.sessionCountDown}
            />
          </div>
        }

        {this.digitalLabStore.labData &&
          <div
            className={cx({
              'digital-lab__appstream-container': true,
              'digital-lab__appstream-container--visible': this.digitalLabStore.labData,
            })}
            id="appstream-container"
          ></div>
        }

        {this.digitalLabStore.closingLab &&
          <div className="digital-lab__close-window">
            <CircularProgress />
          </div>
        }

        <SubmitWindow
          isVisible={this.digitalLabStore.showSubmissionStatusWindow}
          loadingInProgress={this.digitalLabStore.submittingLab}
          onGoBackClick={this.handleGoBackClick}
        />

        <NotificationWindow
          isVisible={this.isNotificationWindowVisible}
          onCloseWindow={this.handleCloseNotificationWindow}
        />

        {this.digitalLabStore.isLabSessionEndsInFiveMin && <EndSessionNotification
          isVisible={this.digitalLabStore.isLabSessionEndsInFiveMin}
          onCloseWindow={this.digitalLabStore.closeLabSessionEndNotification}
        />}

      </div>
    );
  }
}));
