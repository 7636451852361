import qs from 'qs';
import React, { Component } from 'react';
import url from 'url';
import { withRouter } from 'react-router';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import isHostValid from '@u4i/utils/isHostValid';

const EXIT_PATHS_BLACKLIST = ['/digital-labs', '/discuss/', '/exercises', '/jupyter-lab'];

function withPlayerRouter(WrappedComponent) {
  const resultingComponent = class extends Component<any>{

    AppNavigatorReplacer: any = AppNavigator;

    playerExitTargetPathname = '/';

    constructor(props) {
      super(props);

      const { currentLocation, previousLocation } = this.AppNavigatorReplacer;

      if (previousLocation) {
        const pathnamesBlacklist = [...EXIT_PATHS_BLACKLIST, currentLocation.pathname];
        const blacklistCheckerPredicate = entry => pathnamesBlacklist.reduce(
          (accumulator, blacklistedURL) => accumulator && entry.pathname.indexOf(blacklistedURL) !== 0,
          true,
        );

        const previousLocations = this.AppNavigatorReplacer.allPreviousLocations;

        if (previousLocations.length > 0) {
          const filteredLocations = previousLocations.filter(blacklistCheckerPredicate);

          if (filteredLocations.length > 0) {
            const { pathname, search } = filteredLocations[0];

            this.playerExitTargetPathname = pathname + search;
          }
        }
      }
    }

    handleChapterNavigation = (targetChapterSlug) => {
      if (this.props.match.params.skillSlug) {
        this.AppNavigatorReplacer.replace(appRoutes.playerSkill, {
          chapterSlug: targetChapterSlug,
          skillSlug: this.props.match.params.skillSlug,
        });
      } else {
        this.AppNavigatorReplacer.replace(appRoutes.playerChapter, { chapterSlug: targetChapterSlug });
      }
    };

    handleExitPlayer = (programClpUrl) => {
      const parsedQuery:any = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

      if (parsedQuery.exitToUrl && isHostValid(parsedQuery.exitToUrl)) {
        const parsedExitUrl = url.parse(parsedQuery.exitToUrl);

        if (parsedExitUrl.protocol) {
          window.location.replace(parsedExitUrl.href);
        } else {
          this.AppNavigatorReplacer.directPush(parsedExitUrl.path);
        }
      }
      else {
        const skillSlug = this.props.match.params.skillSlug;

        if (skillSlug) {
          const skillOverviewExitUrl = `/skills/${this.props.match.params.skillSlug}`;
          this.AppNavigatorReplacer.directPush(skillOverviewExitUrl);
        } else {
          if (programClpUrl.trim() !== '') {
            this.AppNavigatorReplacer.directPush(programClpUrl.trim());
          } else {
            const homeUrl = '/home';
            this.AppNavigatorReplacer.directPush(homeUrl);
          }
        }
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          exitPlayer={this.handleExitPlayer}
          onChapterNavigation={this.handleChapterNavigation}
        />
      );
    }
  };

  return withRouter(resultingComponent);
}

export default withPlayerRouter;
