import React, { FC } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import intlMessages from './intlMessages';
import CircularProgress from '@u4i/common/CircularProgress';
import { Form, Switch, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import './_edit-comment-form.scss';

const { Item } = Form;

interface IProps extends WrappedComponentProps {
  form: any
  formId: string
  initialValues: {
    anonymity: boolean
    comment: string
  }
  showAnonymitySettingsSwitch: boolean
  updatingContent: boolean
  handleSubmit: (values) => any
  updateHasCommentChanged: (hasChanged: boolean) => void
  updateHasAnonymitySwitchChanged: (hasChanged: boolean) => void
}

const EditCommentForm: FC<IProps> = (props: IProps) => {
  const {
    form,
    formId,
    intl,
    initialValues,
    showAnonymitySettingsSwitch,
    updatingContent,
    handleSubmit,
    updateHasCommentChanged,
    updateHasAnonymitySwitchChanged
  } = props;
  
  const anonymityFieldId = `edit-comment-anonymous.${formId}`;
  const {formatMessage} = intl;

  const contentMinLengthError: string = formatMessage(intlMessages.contentErrorsMinLength, {limit: 20});
  const contentPresenceError: string = formatMessage(intlMessages.contentErrorsPresence);

  return (
    <Form 
      form={form}
      initialValues={initialValues}
      key={formId}
      name={`comment-edit-${formId}`}
      onFinish={handleSubmit}
    >
      <div className="edit-comment-form__fields-wrapper">
        <Item
          name="comment"
          rules={[
            {
              validator: async (rule, value) => {
                if(value.trim() != initialValues.comment) {
                  updateHasCommentChanged(true);
                } else {
                  updateHasCommentChanged(true);
                }
                if(!value) {
                  return Promise.reject(contentPresenceError);
                }
                if (value.trim().length > 0 && value.trim().length < 20) {
                  return Promise.reject(contentMinLengthError);
                }
              }
            }
          ]}
        >
          <TextArea rows={4}/>
        </Item>

        <div className="edit-comment-form__field-wrapper">
          {showAnonymitySettingsSwitch &&
            <div className="edit-comment-form__anonymity-field">
              <div style={{marginTop: 5}}>
                <Typography.Text style={{textTransform: 'uppercase'}}> 
                  <FormattedMessage {...intlMessages.anonymousPosting} />
                </Typography.Text>
              </div>

              <Item
                initialValue={initialValues.anonymity}
                name="anonymity"
                style={{marginBottom: 0}}
                rules={[
                  {
                    validator: async (rule, value) => {
                      if(value != initialValues.anonymity) {
                        updateHasAnonymitySwitchChanged(true);
                      } 
                      else {
                        updateHasAnonymitySwitchChanged(false);
                      }
                    }
                  }
                ]}
              >
                <Switch 
                  defaultChecked={initialValues.anonymity} 
                />
              </Item>
            </div>              
          }
        </div>

        {updatingContent &&
          <div className="edit-comment-form__updating-progress">
            <CircularProgress />
          </div>
        }
      </div>
    </Form>
  )
}

export default injectIntl(EditCommentForm);
