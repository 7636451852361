import '@babel/polyfill';
import './../client/styles/common.scss';
import {hot} from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import HacksManager from './utils/HacksManager';
import Root from './containers/Root/Root';
import initStatistic from './utils/statistic';
import { configure } from "mobx";

configure({ useProxies: "never" });

let windowReference: any = window;

windowReference.FontAwesomeConfig = {autoReplaceSvg: 'nest'};
windowReference.process = {};
// @ts-ignore
if (__DEV__) {
  global.React = React;
}
// @ts-ignore
if (__PROD__) {
  initStatistic();
}

HacksManager.blockRubberbandScroll();

const render = (RootComponent) => {
  const HotRoot = hot(module)(RootComponent);

  ReactDOM.render(<HotRoot  />, document.getElementById('root'));
};

// TODO: ensure intl files are loaded in a cleaner manner (prevents FOUC)
(new Promise((resolve:any) => {
  if (windowReference.intl || windowReference.Intl) {
    return resolve();
  }

  Promise.all([
    import("intl"),
    import("intl/locale-data/jsonp/en.js"),
    import("intl/locale-data/jsonp/de.js"),
  ]).then(resolve);
})).then(() => { render(Root); });
