import { AxiosInstance } from "axios";
import {
  IFileInfo,
  IUploadedFile,
  IFileUpload,
} from "@u4i/modules/Admin/modules/FileManagement/interface";

export class AdminFileUploadApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async getFileInfo(filename: string): Promise<IFileInfo> {
    const formData = new FormData();
    formData.append("filename", filename);

    return (await this.apiClient.post(`/admin/files/file-info`, formData)).data;
  }

  async getUploadedFiles(queryUrl): Promise<any> {
    return (await this.apiClient.get(`/admin/downloads/list` + queryUrl));
  }

  async deleteFile(id: string): Promise<any> {
    return await this.apiClient.delete(`/admin/downloads/${id}`);
  }

  async updateFile(id, updatedName): Promise<any> {
    return await this.apiClient.put(`/admin/downloads/${id}/info`, {
      data: {
        display_filename: updatedName,
      },
    });
  }

  async upload(model: IFileUpload): Promise<IUploadedFile> {
    const formData = new FormData();
    formData.append("file", model.file);

    if (model.canOverwrite != undefined) {
      formData.append("canOverwrite", model.canOverwrite);
    }

    return (await this.apiClient.post(`/admin/files/upload`, formData)).data;
  }
}
