import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {RouteComponentProps, withRouter} from 'react-router';
import intlMessages from './intlMessages';
import { Modal } from 'antd';
import { RedeemPageStore } from '@u4i/modules/RedeemPage/state/RedeemPageStore';
import LoginForm from '@u4i/common/forms/LoginForm/LoginForm';
import UserStore from '@u4i/state/UserStore';
import { LanguageStore } from '@u4i/state/LanguageStore';
import { CheckoutPurchaseStore } from '@u4i/modules/Checkout/state/CheckoutStore';
import { CourseDetailPageStore } from '@u4i/modules/CourseDetailPage/state/CourseDetailPageStore';
import './_login-modal.scss';

interface IProps extends RouteComponentProps {
  courseDetailPageStore?: CourseDetailPageStore
  checkoutStore?: CheckoutPurchaseStore
  languageStore?: LanguageStore
  redeemPageStore?: RedeemPageStore
  userStore: UserStore
}

const LoginModal: FC<IProps> = inject("rootStore") (
  observer((props: IProps) => {
    const {
      isLoginAndPurchase,
      isLoginModalVisible,
      isUserLoggedIn,
      purchasingCourseId,
      successRedirectionPathname,
      loginModalHandle,
      handleLoginFormSubmission,
      passwordRecoveryModalHandle,
      registrationModalHandle
    } = props.userStore;

    const onForgotPasswordClick = () => {
      loginModalHandle(false);
      passwordRecoveryModalHandle(true);
    }
    
    const onRegisterClick = () => {
      loginModalHandle(false, successRedirectionPathname, isLoginAndPurchase, purchasingCourseId);
      registrationModalHandle(true);
    }

    return (
      <Modal
        centered
        mask={true}
        maskClosable={false}
        footer={null}
        onCancel={() => loginModalHandle(false)}
        open={isLoginModalVisible}
        title={
          <h3 style={{textAlign:'center', fontWeight: 500}}>
            {
              isLoginAndPurchase ? <FormattedMessage {...intlMessages.modalTitlePurchase} /> : <FormattedMessage {...intlMessages.modalTitle} />
            }
          </h3>
        }
        style={{minWidth: '25%', height: '75%'}}
      >
   
        {!isUserLoggedIn &&
          <>
            <LoginForm
              handleSubmit={(data) => handleLoginFormSubmission(data, props.location.pathname, successRedirectionPathname)}
              isLoginModal={true} 
              isLoginPage={false}
              userStore={props.userStore}
            />
            <div className="login-modal__info">
              <button
                className="login-modal__switch-button"
                onClick={onForgotPasswordClick}
                type="button"
              >
                <FormattedMessage {...intlMessages.forgotPassword} />
              </button>

              <p className="login-modal__supporting-text">
                <FormattedMessage {...intlMessages.noAccount} />
              </p>

              <button
                className="login-modal__switch-button"
                onClick={onRegisterClick}
                type="button"
              >
                <FormattedMessage {...intlMessages.registerHere} />
              </button>
            </div>
          </>
        }
      </Modal>
    )
  })
)

export default withRouter(LoginModal);
