import {defineMessages} from 'react-intl';

export default defineMessages({
  goToHome: {
    defaultMessage: 'Go to home',
    id: 'RedeemPage.SuccessInfo.GoToHome',
  },
  redeemAnotherCoupon: {
    defaultMessage: 'Redeem another coupon',
    id: 'RedeemPage.SuccessInfo.RedeemAnotherCoupon',
  },
  title: {
    defaultMessage: 'Coupon redeemed',
    id: 'RedeemPage.SuccessInfo.Title',
  },
});
