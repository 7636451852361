import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";


const LoadingScreen = () => {
  return (
    <div  style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "black",
        zIndex: 999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }}>
      <CircularProgress
        style={{
          width: 80,
          height: 80,
        }}
      />
    </div>
  );
};

export default LoadingScreen;
