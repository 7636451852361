import {decamelizeKeys} from 'humps';
import api from './api';
import AppNavigator from '../common/AppNavigator/AppNavigator';

export function getStatistics() {
  const {
    history,
    innerHeight,
    innerWidth,
    navigator: {
      language, 
      platform
    },
    screen: {
      availHeight,
      availWidth,
      height,
      width
    }
  } = window;

  return {
    availHeight,
    availWidth,
    history: history.length,
    innerHeight,
    innerWidth,
    navigationLanguage: language,
    path: window.location.pathname,
    platform,
    screenHeight: height,
    screenWidth: width,
    timezone: new Date().getTimezoneOffset() / 60,
  };
}

export function logStatistic() {
  api.post('/statistic/page-view', decamelizeKeys(getStatistics()));
}

export default function () {
  AppNavigator.listen(location => logStatistic());
}
