/**
 * @desc This component is the handling store for programs
 * @author Denis Shtupa denis.shtupa@u4i.io
 */

import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { IRootStore } from '@u4i/state/RootStore';
import { IApi, IPaginatedItems } from '@u4i/state/ServicesInterfaces';
import { TablePaginationConfig } from 'antd/es/table';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { IManageResources, IProgram, IProgramModule, IProgramModulePost, IProgramPhase, IProgramPhasePost, IProgramPhasePut, IProgramPost, IProgramPut, ProgramStatusEnum } from '../interfaces';
import { moduleStyling, phaseStyling } from '../interfaces/constants';
import moment from 'moment';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';

export class AdminProgramsStore {
  private apiService: IApi;

  public apiConfig: AntDTableFilters<IProgram> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {},
  };
  public cantDeleteProgram: boolean = false;
  public customPages: any[] = [];
  public creatingProgram: boolean = false;
  public deleteCandidateProgram: IProgram | any;
  public deletingProgram: boolean = false;
  public deletingProgramError?: string;
  public fetching: boolean = true;
  public pagination: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true,
  };
  public phasesLength: number = 0;
  public programsData: any = { lanes: [] };
  public programById: IProgram | undefined;
  public resourcesList: IManageResources = {
    areChaptersLoaded: false,
    chaptersList: [],
    areCoursesLoaded: false,
    coursesList: []
  };
  public tableData: Array<IProgram> = [];
  public validationErrors?: {
    name?: string;
    type?: string;
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      apiConfig: observable,
      cantDeleteProgram: observable,
      cantDeleteProgramHandle: action.bound,
      customPages: observable,
      createProgram: action.bound,
      createPhase: action.bound,
      createModule: action.bound,
      creatingProgram: observable,
      deleteProgram: action.bound,
      deleteCandidateProgram: observable,
      deletePhase: action.bound,
      deleteModule: action.bound,
      deletingProgram: observable,
      deletingProgramError: observable,
      fetching: observable,
      getAllPrograms: action.bound,
      getProgramById: action.bound,
      getResourcesByType: action.bound,
      onTableChange: action.bound,
      pagination: observable,
      phasesLength: observable,
      programById: observable,
      programsData: observable,
      resourcesList: observable,
      savePhasesOrder: action.bound,
      saveModulesOrder: action.bound,
      tableData: observable,
      updateProgram: action.bound,
      updateProgramStatus: action.bound,
      updatePhase: action.bound,
      updateModule: action.bound,
      validationErrors: observable
    });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  //#region  PROGRAMS section
  onTableChange = async (
    pagination: AntDPagination, 
    filters: { [columnName in keyof IProgram]?: IProgram[columnName] }, 
    sorter: AntDSorter<IProgram>
  ) => {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        if (key === 'createdAt' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['createdAtFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['createdAtTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field === 'createdAt' ? 'created_at' : sorter.field] = sortDirection;
    }

    this.apiConfig = {
      ...this.apiConfig,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.pagination = {
      ...pagination,
      current
    }

    await this.getAllPrograms(this.apiConfig);

    this.apiConfig = {
      ...this.apiConfig,
      filters: {}
    };
  }

  getAllPrograms = async (config: AntDTableFilters<IProgram>) => {
    this.fetching = true;
    this.apiConfig = config;

    try {
      const programsListPaginated: IPaginatedItems<IProgram> =
        await this.apiService.admin.programs.fetchProgramData(
          this.apiConfig
        );

      const { items, totalItems } = programsListPaginated;
      this.pagination = {
        ...this.pagination,
        pageSize: this.apiConfig.limit,
        total: totalItems
      };

      runInAction(() => (this.tableData = items));

    } catch (error) {
      Notification(NotificationEnum.Error, "Fetch Programs", error)

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetching = false;
    }
  }

  async getProgramById(id: string) {
    this.fetching = true;
    this.programsData.lanes = [];

    try {
      this.programById = await this.apiService.admin.programs.fetchProgramById(id);

      runInAction(() => {
        if(this.programById) {
          this.programById.phases?.forEach((phase: IProgramPhase) => {
            let phaseLane: IProgramPhase = phase;
            phaseLane.cards = phase.phaseModules;
            phaseLane.style = phaseStyling;
            phaseLane.cardStyle = moduleStyling;
            phaseLane.collapsed = true;

            this.programsData.lanes.push(phaseLane);
          });
          this.phasesLength = this.programsData.lanes.length;
        }
      })
    }
    catch (error) {
      throw (error);
    }
    finally {
      this.fetching = false;
    }
  }

  async createProgram(model: IProgramPost) {
    this.validationErrors = undefined;
    this.creatingProgram = true;
    try {
      await this.apiService.admin.programs.createProgram(model).then(res => {
        Notification(NotificationEnum.Success, "Create Program", `Program \"${model.title}\" is added.`);
        let programId = res?.id;
        AppNavigator.push(appRoutes.adminProgramEdit, {programId: programId} );
        this.creatingProgram = false;
      })
    }

    catch (error) {
      const createProgramError = error.response.data?.validationErrors?.title || error.response.data.errorMessage || error.response.data.message;

      if(error.response.data?.validationErrors) {
        Notification(NotificationEnum.Warning, "Create Program", createProgramError);
      } else {
        Notification(NotificationEnum.Error, "Create Program", createProgramError);
      }
      
      this.creatingProgram = false;
    }
  }

  async updateProgram(id: string, model: IProgramPut, onlyPhaseOrder: boolean = false) {
    this.validationErrors = undefined;
    this.fetching = true;

    try {
      await this.apiService.admin.programs.updateProgram(id, model).then(res => {
        this.phasesLength = res.phases.length;
        if(this.programById) {
          this.programById.status = model.status;
          this.programById.title = model.title;
          this.programById.description = model.description;
          this.programById.internalDescription = model.internal_description;
        }
        if(!onlyPhaseOrder) {
          Notification(NotificationEnum.Success, "Update Program", `Program \"${model.title}\" is updated.`);
        }
      });
    }

    catch (error) {
      const updateProgramError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Update Program", updateProgramError);
    }

    finally {
      this.fetching = false;
    }
  }

  async updateProgramStatus(programId:string, status: ProgramStatusEnum) {
    try {
      if(this.programById?.status == status) {
        Notification(NotificationEnum.Info, "Update Program Status", `This program already has status: ${status.toUpperCase()}`);
        return;
      }

      await this.apiService.admin.programs.updateProgramStatus(programId, status).then(res => {
        Notification(NotificationEnum.Success, "Update Program Status", `Program new status is: ${status.toLocaleUpperCase()}`);
        if(this.programById) {
          this.programById.status = status;
        }
      })
    }
    catch(error) {
      const updateProgramStatusError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Update Program Status", updateProgramStatusError);
    }
  }

  async deleteProgram(record: IProgram) {
    this.deletingProgram = true;
    this.deleteCandidateProgram = record;

    const isOneItemOnPage: boolean = this.tableData.length === 1;
    try {
      await this.apiService.admin.programs.deleteProgram(record.id).then(res => {
        if(res === true) {
          Notification(NotificationEnum.Success, "Delete Program", `Program \"${record.title}\" is deleted.`);
          
          runInAction(() => {
            if (isOneItemOnPage) {
              this.apiConfig = {
                ...this.apiConfig,
                current: 1,
                offset: 0
              }
            };

            this.getAllPrograms(this.apiConfig);
          })
        } else {
          this.cantDeleteProgram = true;
          this.customPages = res?.customPages;
        }
      });
    }

    catch(error) {
      this.deletingProgramError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Delete Program", this.deletingProgramError);
    }

    finally {
      this.deletingProgram = false;
    }
  }

  cantDeleteProgramHandle = () => {
    this.cantDeleteProgram = false;
  }
  //#endregion PROGRAMS section

  //#region PHASES section
  async createPhase(phase: IProgramPhase) {
    try {
      let phaseModel: IProgramPhasePost = {
        icon: phase.icon,
        long_title: phase.longTitle,
        order: this.programsData.lanes.length,
        required: phase.required,
        short_title: phase.shortTitle
      };

      await this.apiService.admin.programs.createPhase(this.programById?.id!, phaseModel).then(res => {
        phase.order = res.order;
        phase.id = res.id;
        phase.cards = [];
        this.programsData.lanes.push(phase);
        this.phasesLength = this.programsData.lanes.length;
        Notification(NotificationEnum.Success, "Add Phase", `Phase \"${phase.longTitle}\" is added.`);
      });
    }

    catch(error) {
      const addPhaseError = error.response.data.errorMessage || error.response.data.message;
      
      Notification(NotificationEnum.Error, "Add Phase", addPhaseError);
    }
  }

  async updatePhase(phaseId: string, phaseData: any) {
    try {
      let dataArray: Array<any> = phaseData.title.split('||');
      let longTitle: string = dataArray[0];
      let shortTitle: string = dataArray[1];
      let required: boolean = dataArray[2] == "true" ? true : false;
      let phase: IProgramPhase = this.programsData.lanes?.find((pha: IProgramPhase) => pha.id === phaseId);

      if(phase) {
        phase.longTitle = longTitle.trim();
        phase.shortTitle = shortTitle.trim();
        phase.required = required;

        let phaseUpdateModel: IProgramPhasePut = {
          icon: phase.icon,
          long_title: phase.longTitle,
          order: phase.order,
          required: phase.required,
          short_title: phase.shortTitle
        };

        await this.apiService.admin.programs.updatePhase(phaseId, phaseUpdateModel).then(res => {
          Notification(NotificationEnum.Success, "Save Phase", `Phase \"${phase.longTitle}\" is updated.`);
        });
      }
    }

    catch(error) {
      const updatePhaseError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Save Phase", updatePhaseError);
    }
  }

  async deletePhase(phaseId: string) {
    try{
      await this.apiService.admin.programs.deletePhase(phaseId).then(res => {
        let currentProgram: IProgram | undefined = this.programById;
        let phaseToDelete: IProgramPhase = this.programsData.lanes.find((pha: IProgramPhase) => pha.id == phaseId);
        this.programsData.lanes.map((pha: IProgramPhase) => this.fixPhaseOrderOnDelete(pha, phaseToDelete.order));
        this.programsData.lanes = this.programsData.lanes.filter((pha: IProgramPhase) => pha.id != phaseId);

        Notification(NotificationEnum.Success, "Delete Phase", `Phase \"${phaseToDelete.longTitle}\" is deleted.`);

        this.updateProgram(currentProgram?.id!, this.createUpdatePhasesObject(currentProgram!, this.programsData.lanes), true);
      });
    }

    catch(error) {
      const deletingPhaseError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Delete Phase", deletingPhaseError);
    }
  }

  private fixPhaseOrderOnDelete = (phase: IProgramPhase, indexOfPhase: number) => {
    if(phase.order > indexOfPhase) {
      phase.order--;
    }
  }

  savePhasesOrder(oldIndex: number, newIndex: number, payload: IProgramPhase) {
    try {
      let currentProgram: IProgram | undefined = this.programById;
      let notDraggedPhases: IProgramPhase[] | undefined = this.programsData.lanes?.filter((pha: IProgramPhase) => pha.id != payload.id);
      let draggedPhase: IProgramPhase = this.programsData.lanes?.find((pha: IProgramPhase) => pha.id == payload.id);
      notDraggedPhases?.map((pha: IProgramPhase) => this.phaseOrderHandle(pha, oldIndex, newIndex));

      if(draggedPhase) {
        draggedPhase.order = newIndex;
      }

      if(currentProgram) {
        this.updateProgram(currentProgram.id, this.createUpdatePhasesObject(currentProgram, this.programsData.lanes), true).then((res) =>
          this.getProgramById(currentProgram?.id!)
        );
      }
    }

    catch(error) {
      const phasesOrderError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Order Phase", phasesOrderError);
    }
  }

  private phaseOrderHandle = (phase: IProgramPhase, oldIndex: number, newIndex: number) => {
    if(phase.order <= newIndex && phase.order > oldIndex) {
      phase.order--;
    } else if(phase.order < oldIndex && phase.order >= newIndex) {
      phase.order++;
    }
  }

  private createUpdatePhasesObject = (currentProgram: IProgram, programsData: IProgramPhase[]): IProgramPut => {
    let programUpdateModel: IProgramPut = {
      description: currentProgram.description,
      internal_description: currentProgram.internalDescription,
      phases: [],
      status: currentProgram.status,
      title: currentProgram.title,
    };

    programsData.forEach((pha: IProgramPhase) => {
      let phase: IProgramPhasePut = {
        icon: "nothing",
        id: pha.id,
        long_title: pha.longTitle,
        phaseModules: pha.cards!,
        order: pha.order,
        required: pha.required,
        short_title: pha.shortTitle
      }
      programUpdateModel.phases?.push(phase);
    });
    return programUpdateModel;
  }
  //#endregion PHASES section

  //#region MODULES section
  async createModule(phaseId: string, module: IProgramModule) {
    try {
      let phaseObject: IProgramPhase = this.programsData.lanes.find((pha: IProgramPhase) => pha.id === phaseId);
      if(phaseObject) {
        let moduleModel: IProgramModulePost = {
          icon: "UNDEFINED",
          order: phaseObject?.cards?.length!,
          module_type: module.module_type,
          resource_id: module.resource_id,
          resource_type: module.resource_type,
          required: module.required,
          title: module.title
        }
        await this.apiService.admin.programs.addModuleToPhase(phaseId, moduleModel).then(res => {
          moduleModel.id = res.id;
          moduleModel.internal_id = res.internal_id;
          phaseObject.cards?.push(moduleModel);
          //ToDo: In case of issues, double check if this is not needed.
          // phaseObject.modules?.push(this.buildModulesObject(moduleModel));
          Notification(NotificationEnum.Success, "Add Module", `Module \"${module.title}\" is added.`);
        })
      }
    }

    catch(error) {
      const addModuleError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Add Module", addModuleError);
    }
  }

  async updateModule(phaseId: string, moduleId: string, moduleData: any) {
    try {
      let currentPhase: IProgramPhase = this.programsData.lanes.find((pha: IProgramPhase) => pha.id === phaseId);
      let currentModule: IProgramModule = currentPhase.cards?.find((mod: IProgramModule) => mod.id === moduleId);

      if(currentModule) {
        let moduleModel: IProgramModulePost = {
          icon: "UNDEFINED",
          module_type: currentModule.module_type,
          order: currentModule.order,
          resource_id: moduleData.resource_id,
          resource_type: moduleData.resource_type,
          required: moduleData.required,
          title: moduleData.title
        }

        await this.apiService.admin.programs.updateModule(phaseId, moduleId, moduleModel).then(res => {
          Notification(NotificationEnum.Success, "Save Module", `Module \"${moduleData.title}\" is updated.`);
        });
      }
    }

    catch(error) {
      const updateModuleError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Save Module", updateModuleError);
    }
  }

  async deleteModule(phaseId: string, moduleId: string) {
    try {
      await this.apiService.admin.programs.deleteModule(phaseId, moduleId).then(res => {

        let phase: IProgramPhase = this.programsData.lanes.find((pha: IProgramPhase) => pha.id == phaseId);
        let moduleToDelete: IProgramModule = phase.cards?.find((mod: IProgramModule) => mod.id == moduleId);
        phase?.cards?.map((mod: IProgramModule) => this.fixModuleOrderOnDelete(mod, moduleToDelete.order))

        phase.cards = phase?.cards?.filter((mod: IProgramModule) => mod.id != moduleId);
        phase.phaseModules = phase?.phaseModules?.filter((mod: IProgramModule) => mod.id != moduleId);

        let currentProgram: IProgram | undefined = this.programById;
        Notification(NotificationEnum.Success, "Delete Module", `Module \"${moduleToDelete.title}\" is deleted.`);

        this.updateProgram(currentProgram?.id!, this.createUpdatePhasesObject(currentProgram!, this.programsData.lanes), true);
      })
    }

    catch(error) {
      const deletingModuleError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Delete Module", deletingModuleError);
    }
  }

  private fixModuleOrderOnDelete = (module: IProgramModule, indexOfModule: number) => {
    if(module.order > indexOfModule) {
      module.order--;
    }
  }

  saveModulesOrder(fromPhaseId: string, toPhaseId: string, moduleId: string, index: number) {
    try {
      let currentProgram: IProgram | undefined = this.programById;
      let originPhase: IProgramPhase = this.programsData.lanes.find((pha: IProgramPhase) => pha.id == fromPhaseId);
      let draggedModule: IProgramModule = originPhase?.cards?.find((mod: IProgramModule) => mod.id == moduleId);

      if(fromPhaseId == toPhaseId) {   // Module in same phase
        const draggedModuleNoRef = Object.assign({}, draggedModule);
        let notDraggedModules: IProgramModule[] | undefined = originPhase?.cards?.filter((mod: IProgramModule) => mod.id != moduleId)
        draggedModule.order = index;
        notDraggedModules?.map((mod: IProgramModule) => this.moduleOrderHandle(mod, draggedModuleNoRef.order, index));
      } else {
        let destinationPhase: IProgramModule[] = this.programsData.lanes.find((pha: IProgramPhase) => pha.id == toPhaseId)?.cards;
        const draggedModuleNoRef: IProgramModule = Object.assign({}, draggedModule);

        if(originPhase && draggedModule) {
          let biggerIndexModules: IProgramModule[] | undefined = originPhase?.cards?.filter((mod: IProgramModule) => mod.order > draggedModuleNoRef?.order);

          if(draggedModuleNoRef.order <= originPhase?.cards?.length!) {
            biggerIndexModules?.map((mod: IProgramModule) => mod.order--);
          }

          originPhase.cards = originPhase?.cards?.filter((mod: IProgramModule) => mod.id !== draggedModule.id);
        }

        if(destinationPhase && draggedModule) {
          let biggerIndexModules: IProgramModule[] | undefined = destinationPhase?.filter((mod: IProgramModule) => mod.order >= index);
          biggerIndexModules?.map((mod: IProgramModule) => mod.order++);
          draggedModuleNoRef.order = index;
          destinationPhase?.push(draggedModuleNoRef);
        }
      }
      if(currentProgram) {
        this.updateProgram(currentProgram.id, this.createUpdatePhasesObject(currentProgram, this.programsData.lanes), true).then((res) =>
          this.getProgramById(currentProgram?.id!)
        );
      }
    }

    catch(error) {
      const moduleOrderError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Order Module", moduleOrderError);
    }
  }

  private moduleOrderHandle = (module: IProgramModule, oldIndex: number, newIndex: number) => {
    if(module.order <= newIndex && module.order > oldIndex) {
      module.order--;
    } else if(module.order < oldIndex && module.order >= newIndex) {
      module.order++;
    }
  }

  async getResourcesByType (resourceType: string) {
    try {
      await this.apiService.admin.programs.getResourcesByType(resourceType).then((res) => {
        if(resourceType === "chapter") {
          this.resourcesList.areChaptersLoaded = true;
          this.resourcesList.chaptersList = res;
        }
        if(resourceType === "skill") {
          this.resourcesList.areCoursesLoaded = true;
          this.resourcesList.coursesList = res;
        }
      })
    }
    catch(error) {
      const getResourcesError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Load Resources", getResourcesError);
    }
  }
  //#endregion MODULES section

  resetData() {
    this.programById = undefined;
    this.fetching = true;
    this.validationErrors = undefined;
    this.customPages = [];
    this.cantDeleteProgram = false;
    this.deleteCandidateProgram = undefined;
  }

}
