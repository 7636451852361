import React, { Component } from 'react';
import qs from 'qs';
import {inject, observer} from 'mobx-react';
import {RawIntlProvider} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {RouteComponentProps} from '@reach/router';
import AppNavigator from '@u4i/common/AppNavigator';
import withUser from '@u4i/modules/UserManager/withUser';
import {IStorage} from '@u4i/state/ServicesInterfaces';
import {IUser} from '@u4i/parsers/UserParser';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';
import UserStore from '@u4i/state/UserStore';

interface IApplicationPropTypes extends RouteComponentProps {
  isAuthorized: boolean
  rootStore: any
  userData: IUser
  openPasswordRecoveryModal: () => void
}

const Application = inject('rootStore')(observer(
  class Application extends Component<IApplicationPropTypes | any> {
    private storageService: IStorage;
    private userStore: UserStore;

    constructor(props: IApplicationPropTypes) {
      super(props);
      const {storageService, userStore} = props.rootStore;
      this.storageService = storageService;
      this.userStore = userStore;
    }

    componentDidMount() {
      const {recoverPassword} = qs.parse(
        window.location.search,
        {ignoreQueryPrefix: true},
      );

      if (recoverPassword === 'yes') {
        this.userStore.passwordRecoveryModalHandle(true);
      }

      this.checkRedirects();
    }

    componentDidUpdate(prevProps) {
      if (this.props.isAuthorized !== prevProps.isAuthorized
        || this.props?.location?.pathname !== prevProps.location.pathname
        || this.props.userData.id !== prevProps.userData.id) {
        this.checkRedirects();
      }
    }

    checkRedirects = () => {
      const currentPath = this.props?.location?.pathname;
      const lastViewedData = this.storageService.lastViewedData.get();

      if (this.props.isAuthorized && currentPath === '/' && lastViewedData) {
        if (lastViewedData.userId === this.props.userData.id) {
          const {location} = lastViewedData;

          if (location) {
            AppNavigator.directPush(location);
          } else {
            AppNavigator.directReplace(this.props.userData.homepage);
          }
        } else {
          AppNavigator.directReplace(this.props.userData.homepage);
        }

        this.storageService.lastViewedData.set({userId: this.props.userData.id});
      }
    }

    render() {
      return (
        <RawIntlProvider
          key={this.props.rootStore.languageStore.currentLocale}
          value={(this.props.rootStore.languageStore.currentIntl.locale == LanguageEnum.EN_US || this.props.rootStore.languageStore.currentIntl.locale == LanguageEnum.DE_DE) ? this.props.rootStore.languageStore.currentIntl : LanguageEnum.EN_US}
        >
          <React.Fragment>
            {this.props.children}
          </React.Fragment>
        </RawIntlProvider>
      );
    }
  }
));

export default withRouter(withUser(Application));
