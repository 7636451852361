import {observable,makeObservable} from 'mobx';

import RootStore from '@u4i/state/RootStore';

class MobxStore {
  store: RootStore = new RootStore();

  constructor() {
    makeObservable(this, {
      store: observable
    });
  }
}

const singleton = new MobxStore();

if ((module as any).hot) {
  (module as any).hot.accept('./', () => {
    const NextRootStore = require('./state/RootStore.ts').default;

    singleton.store = new NextRootStore();
  });
}

export {singleton as MobxStore};
