import React, { Component } from 'react';
import getRoutes from 'routes';
import { RawIntlProvider } from 'react-intl';
import { observer, Provider as MobxProvider } from 'mobx-react';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';
import ThemeProvider from '@u4i/utils/ThemeContext';
import { MobxStore } from '@u4i/MobxStore';

interface IProps {
  store: any
};

const Root = observer(class Root extends Component<IProps> {

  render() {
    return (
      <ThemeProvider>
        <MobxProvider rootStore={MobxStore.store}>
          {      // @ts-ignore
            <RawIntlProvider value={(MobxStore.store.languageStore?.currentIntl?.locale == LanguageEnum.EN_US || MobxStore.store.languageStore?.currentIntl?.locale == LanguageEnum.DE_DE) ? MobxStore.store.languageStore.currentIntl : LanguageEnum.EN_US}>
              {getRoutes(this.props.store)}
            </RawIntlProvider>
          }
        </MobxProvider>
      </ThemeProvider>
    );
  }
});

export default Root;
