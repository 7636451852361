import {AxiosError} from 'axios';
import {action, observable, runInAction,makeObservable} from 'mobx';

import {IApi} from '@u4i/state/ServicesInterfaces';
import {Answer, Answers, IExercise} from '@u4i/parsers/ExerciseParser';
import {IRootStore} from '@u4i/state/RootStore';
import {SubmissionError} from '@u4i/reactiveForms';
export class ExercisesStore {
  private apiService: IApi;
  attemptCreationFailed = false;
  exerciseAttemptId?: string;
  currentQuestionId?: string;
  exerciseData?: IExercise;
  answers?: Answers | null;
  private exerciseId?: string;
  fetching = true;
  permissionError = false;
  submissionProgress = 0;
  submissionProgressTotal = 0;
  submissionSuccess = false;
  submitting = false;
  attemptStatus: number;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      attemptCreationFailed: observable,
      exerciseAttemptId: observable,
      currentQuestionId: observable,
      exerciseData: observable,
      answers: observable,
      fetching: observable,
      permissionError: observable,
      submissionProgress: observable,
      submissionProgressTotal: observable,
      submissionSuccess: observable,
      submitting: observable,
      attemptStatus: observable,
      createAttempt: action.bound,
      init: action.bound,
      loadExerciseData: action.bound,
      submitAnswer: action.bound,
      submitAttempt: action.bound,
      reset: action.bound
    });

    const {apiService} = rootStore;

    this.apiService = apiService;
  }

  async createAttempt() {
    if (this.exerciseId) {
      try {
        const {answers, attemptId, status} = await this.apiService.exercises.createAttempt(this.exerciseId);
        runInAction(() => {
          this.exerciseAttemptId = attemptId;
          this.answers = answers;
          this.attemptStatus = status;
        });
      } catch (error) {
        runInAction(() => {
          this.attemptCreationFailed = true;
        });
      }
    }
  }

  async init(exerciseId: string) {
    this.exerciseId = exerciseId;

    await this.loadExerciseData();
    if (!this.permissionError) { await this.createAttempt(); }
  }

  async loadExerciseData() {
    if (this.exerciseId) {
      this.fetching = true;

      try {
        const exerciseData = await this.apiService.exercises.fetchExerciseData(this.exerciseId);

        runInAction(() => {
          this.fetching = false;
          this.exerciseData = exerciseData;
        });
      } catch (error) {
        runInAction(() => {
          this.permissionError = true;
        });
      }
    }
  }

  async submitAnswer(answer: Answer, answerQuestionId: string) {
    if (this.exerciseAttemptId) {
      try {
        await this.apiService.exercises.submitAnswer(
          this.exerciseAttemptId!,
          answerQuestionId,
          answer.answer,
          answer.files,
        );

        runInAction(() => {
          this.submissionProgress++;
        });
      } catch (error) {
        if (error.isAxiosError) {
          const castError = error as AxiosError<{errorMessage: string}>;

          if (castError.response) {
            throw castError.response.data.errorMessage;
          } else {
            throw error;
          }
        }
      }
    }
  }
  
  async submitAttempt(answers: Answers) {
    if (this.exerciseAttemptId) {
      this.submitting = true;

      const fieldsErrors = {};

      try {
        this.submitAnswer(answers[this.currentQuestionId as string], this.currentQuestionId as string);  

        if (Object.keys(fieldsErrors).length > 0) {
          const error = Error();
          error.name = 'fieldsError';
          throw error;
        }
  
        await this.apiService.exercises.submitAttempt(this.exerciseAttemptId);

        await new Promise(resolve => setTimeout(resolve, 1000));

        runInAction(() => {
          this.submissionProgress++;
          this.submissionSuccess = true;
        });
      } catch (error) {
        this.submissionProgress = 0;
        this.submissionProgressTotal = 0;

        if (error.name === 'fieldsError') {
          throw new SubmissionError({
            fieldsErrors,
            generalError: 'Sorry, there was an error during exercise submission - please check fields',
          });
        } else {
          throw new SubmissionError({generalError: 'Sorry, there was an error during exercise submission'});
        }
      } finally {
        this.submitting = false;
      }
    }
  }

  reset() {
    this.attemptCreationFailed = false;
    this.exerciseAttemptId = undefined;
    this.exerciseData = undefined;
    this.exerciseId = undefined;
    this.fetching = true;
    this.permissionError = false;
    this.submissionProgress = 0;
    this.submissionProgressTotal = 0;
    this.submissionSuccess = false;
    this.submitting = false;
  }
}
