import { IProgramPhase } from "@u4i/modules/Admin/modules/Programs/interfaces";
import Parser from "@u4i/utils/Parser";
import { AdminProgramModulesParser } from "./AdminProgramModulesParser";

export const AdminProgramPhasesParser = new Parser<IProgramPhase>(p => ({
  createdAt: undefined,
  id: p.id,
  icon: p.icon,
  longTitle: p.long_title,
  phaseModules: AdminProgramModulesParser.parseArray(p.phaseModules),
  progress: p.progress,
  order: p.order,
  required: p.required,
  shortTitle: p.short_title,
  updatedAt: undefined
}));
