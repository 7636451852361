import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_summary-heading.scss';

interface IPropTypes {
  page: number;
  pagesTotal: number;
}

export const SummaryHeading: FC<IPropTypes> = (props) => (
  <h4 className="player__summary-heading">
    <FormattedMessage {...intlMessages.text} />

    {props.pagesTotal > 1 &&
      <span className="player__summary-heading__pagination">
        {props.page}/{props.pagesTotal}
      </span>
    }
  </h4>
);
