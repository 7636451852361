import {action, observable, runInAction,makeObservable} from 'mobx';
import DiscussPageEntity from '@u4i/state/entities/DiscussPageEntity';
import {IApi, ILogger} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import {ScormProxyService} from '@u4i/state/services/ScormProxyService';
import {DiscussStore} from '../components/Discuss';
import {UserAnswersStore} from './UserAnswersStore';
import { IDiscussPage } from '@u4i/parsers/DiscussPageParser';

export interface DiscussAnswerPost {
  answer: string
  anonymity?: boolean
}

class DiscussPageStore {
  private pages: Map<string, DiscussPage> = new Map();
  private rootStore: IRootStore;
  public updatedAnswerId?: string;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      updatedAnswerId: observable,
      initializePage: action.bound,
      setAnswerUpdated: action.bound,
      setAnswerNotUpdated: action.bound
    });

    this.rootStore = rootStore;
  }

  initializePage(challengeSlug: string): DiscussPage {
    if (this.pages.has(challengeSlug)) {
      return this.pages.get(challengeSlug)!;
    }

    const discussPage = new DiscussPage(this.rootStore, challengeSlug);
    this.pages.set(challengeSlug, discussPage);

    return discussPage;
  }

  setAnswerUpdated(answerId: string) {
    this.updatedAnswerId = answerId;
  }

  setAnswerNotUpdated() {
    this.updatedAnswerId = undefined;
  }
}

export class DiscussPage {
  private apiService: IApi;
  private challengeSlug: string;
  public discussStore: DiscussStore;
  public entity: DiscussPageEntity;
  private logger: ILogger;
  private scormProxyService: ScormProxyService;
  public userAnswersStore: UserAnswersStore;
  public errorCode: number | null = null;
  public fetchingPage: boolean = false;

  constructor(rootStore: IRootStore, challengeSlug: string) {
    makeObservable(this, {
      errorCode: observable,
      fetchingPage: observable,
      loadPage: action.bound,
      submitAnswer: action.bound
    });

    const {apiService, loggerFactory, scormProxyService} = rootStore;

    this.apiService = apiService;
    this.logger = loggerFactory.createLogger('DiscussPageStore');

    this.challengeSlug = challengeSlug;
    this.discussStore = new DiscussStore(rootStore, this.challengeSlug);
    this.userAnswersStore = new UserAnswersStore(rootStore, challengeSlug);

    this.loadPage();
  }

  loadPage = async () => {
    this.errorCode = null;
    this.fetchingPage = true;

    try {
      const discussPage: IDiscussPage = await this.apiService.discuss.fetchPageData(this.challengeSlug);
      const discussPageEntity: DiscussPageEntity = new DiscussPageEntity(discussPage);

      runInAction(() => {
        this.entity = discussPageEntity;
      });
    } catch (error) {
      this.errorCode = error.response.status;

      this.logger.error(error);
    } finally {
      runInAction(() => {
        this.fetchingPage = false;
      });
  
      this.logger.info('page data loaded');
    }
  }

  submitAnswer = async (values: DiscussAnswerPost) => {
    const submittedValues: DiscussAnswerPost = Object.assign({}, values);

    submittedValues.anonymity = submittedValues.anonymity || false;

    await this.userAnswersStore.submitAnswer(submittedValues);

    if (this.scormProxyService) {
      await this.scormProxyService.syncProgress();
    }

    this.discussStore.reloadAnswers();
  }
}

export default DiscussPageStore;
