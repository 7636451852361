import Parser from '@u4i/utils/Parser';

export interface IDiscussPage {
  answerCount: number;
  content: string;
  createdAt: string;
  id: string;
  instructions: string;
  language: string;
  userId: string;
}

const discussPageParser = new Parser<IDiscussPage>(discussPage => ({
  answerCount: discussPage.answer_count,
  content: discussPage.content,
  createdAt: discussPage.created_at,
  id: discussPage.id,
  instructions: discussPage.instructions,
  language: discussPage.language,
  userId: discussPage.user_id,
}));

export default discussPageParser;
