import {defineMessages} from 'react-intl';

export default defineMessages({
  actions: {
    defaultMessage: 'Actions',
    id: 'CustomerGrading.ProgressGroupsList.Actions',
  },
  chart: {
    defaultMessage: 'Chart',
    id: 'CustomerGrading.ProgressGroupsList.Chart',
  },
  email: {
    defaultMessage: 'Email',
    id: 'CustomerGrading.ProgressGroupsList.Email',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'CustomerGrading.ProgressGroupsList.Failed',
  },
  firstName: {
    defaultMessage: 'First name',
    id: 'CustomerGrading.ProgressGroupsList.FirstName',
  },
  lastName: {
    defaultMessage: 'Last name',
    id: 'CustomerGrading.ProgressGroupsList.LastName',
  },
  passed: {
    defaultMessage: 'Passed',
    id: 'CustomerGrading.ProgressGroupsList.Passed',
  },
  submitted: {
    defaultMessage: 'Submitted',
    id: 'CustomerGrading.ProgressGroupsList.Submitted',
  },
  total: {
    defaultMessage: 'Total',
    id: 'CustomerGrading.ProgressGroupsList.Total',
  },
  view: {
    defaultMessage: 'View',
    id: 'CustomerGrading.ProgressGroupsList.View',
  },
});
