import {defineMessages} from 'react-intl';

export default defineMessages({
  attemptFeedback: {
    defaultMessage: 'Feedback',
    id: 'ReactiveForms.Forms.AttemptGradingForm.AttemptFeedback',
  },
  attemptFeedbackPlaceholder: {
    defaultMessage: 'Write your feedback for the challenge attempt here',
    id: 'ReactiveForms.Forms.AttemptGradingForm.AttemptFeedbackPlaceholder',
  },
  evaluateAttempt: {
    defaultMessage: 'Please evaluate the challenge attempt',
    id: 'ReactiveForms.Forms.AttemptGradingForm.EvaluateAttempt',
  },
  feedbackLabel: {
    defaultMessage: 'Feedback',
    id: 'ReactiveForms.Forms.AttemptGradingForm.FeedbackLabel',
  },
  feedbackPlaceholder: {
    defaultMessage: 'Write your feedback here',
    id: 'ReactiveForms.Forms.AttemptGradingForm.FeedbackPlaceholder',
  },
  graderFeedback: {
    defaultMessage: 'Feedback',
    id: 'ReactiveForms.Forms.AttemptGradingForm.GraderFeedback',
  },
  maxScore: {
    defaultMessage: 'out of <strong>{possibleScore}</strong> max score',
    id: 'ReactiveForms.Forms.AttemptGradingForm.MaxScore',
  },
  points: {
    defaultMessage: 'Points',
    id: 'ReactiveForms.Forms.AttemptGradingForm.Points',
  },
  pointsErrorMaxValue: {
    defaultMessage: 'You can assign at most {possibleScore} points for this question',
    id: 'ReactiveForms.Forms.AttemptGradingForm.PointsErrorMaxValue',
  },
  pointsErrorMinValue: {
    defaultMessage: 'You can\'t assign less than zero points',
    id: 'ReactiveForms.Forms.AttemptGradingForm.PointsErrorMinValue',
  },
  pointsValue: {
    defaultMessage: '{points} points',
    id: 'ReactiveForms.Forms.AttemptGradingForm.PointsValue',
  },
  questionHeading: {
    defaultMessage: 'Question <strong>{questionNumber}</strong>',
    id: 'ReactiveForms.Forms.AttemptGradingForm.QuestionHeading',
  },
  questionNumberHeading: {
    defaultMessage: 'Question {current} of {total}',
    id: 'ReactiveForms.Forms.AttemptGradingForm.QuestionNumberHeading',
  },
  result: {
    defaultMessage: 'Result',
    id: 'ReactiveForms.Forms.AttemptGradingForm.Result',
  },
  studentFiles: {
    defaultMessage: 'Learner\'s files:',
    id: 'ReactiveForms.Forms.AttemptGradingForm.StudentFiles',
  },
  submitFailed: {
    defaultMessage: 'Failed',
    id: 'ReactiveForms.Forms.AttemptGradingForm.SubmitFailed',
  },
  submitMessage: {
    defaultMessage: 'Submit your challenge attempt evaluation',
    id: 'ReactiveForms.Forms.AttemptGradingForm.SubmitMessage',
  },
  submitPassed: {
    defaultMessage: 'Passed',
    id: 'ReactiveForms.Forms.AttemptGradingForm.SubmitPassed',
  },
  summary: {
    defaultMessage: 'Summary',
    id: 'ReactiveForms.Forms.AttemptGradingForm.Summary',
  },
  summaryFeedback: {
    defaultMessage: 'Feedback:',
    id: 'ReactiveForms.Forms.AttemptGradingForm.SummaryFeedback',
  },
});
