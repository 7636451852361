import React from 'react';
import ReactTooltip from 'react-tooltip';
import {v4 as uuid} from "uuid"; 

import './_segmented-bar.scss';

interface IPropTypes {
  segments: Array<{
    color: string;
    label?: React.ReactNode;
    sizeFraction: number;
  }>;
}

export class SegementedBar extends React.Component<IPropTypes> {
  private idForTooltips = uuid();

  render() {
    return (
      <div className="segmented-bar">
        {this.props.segments.map((segment, index) => (
          <div
            className="segmented-bar__segment"
            data-for={`segmented-bar-tooltip-${this.idForTooltips}-bar-${index}`}
            data-tip
            key={index}
            style={{backgroundColor: segment.color, flex: `0 0 ${segment.sizeFraction * 100}%`}}
          />
        ))}

        {this.props.segments.map((entry, index) => entry.label && (
          <ReactTooltip
            effect="solid"
            id={`segmented-bar-tooltip-${this.idForTooltips}-bar-${index}`}
            key={index}
            place="top"
            type="dark"
          >
            {entry.label}: {Math.round(entry.sizeFraction * 100)}%
          </ReactTooltip>
        ))}
      </div>
    );
  }
}
