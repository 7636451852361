import cx from 'classnames';
import React, { FC } from 'react';

import './_summary-pricing-row.scss';

interface IPropTypes {
  bordered?: boolean;
  discount?: boolean;
  label: JSX.Element | React.ReactNode | string;
  summary?: boolean;
  value: JSX.Element | React.ReactNode | string;
}

export const SummaryPricingRow: FC<IPropTypes> = (props: IPropTypes) => {
  const {
    bordered,
    discount,
    label,
    summary,
    value
  } = props;

  return (
    <div
      className={cx({
        'checkout__summary-pricing-row': true,
        'checkout__summary-pricing-row--bordered': bordered,
        'checkout__summary-pricing-row--discount': discount,
        'checkout__summary-pricing-row--summary': summary,
      })}
    >
      <span>{label}</span>
      <span>{value}</span>
    </div>
  )

}
