import { mainBlue, white } from "@u4i/styles/styles-variables";
import * as React from "react";
import { opacity7 } from "../../constants/Constants";
import { mapModuleStyling } from "../../constants/StaticMethods";

interface IPropTypes {
  cx: number
  cy: number
  isCompleted: boolean
  radius: number
  required: boolean
  title: string
  onModuleHover: (cx: number, cy: number, title: string, hoverIn: boolean) => void 
  showModuleInVE: () => void
}

export const PracticeExerciseIcon = (props: IPropTypes) => {
  const {
    cx,
    cy,
    isCompleted,
    radius,
    required,
    title,
    onModuleHover,
    showModuleInVE
  } = props;

  const [opacity, setOpacity] = React.useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  const moduleHover = (mouseEnter: boolean) => {
    onModuleHover(cx, cy, title, mouseEnter);
  }

  return (
    <svg>
      <g
        onClick={showModuleInVE}
        onMouseEnter={() => { handleOpacity(); moduleHover(true); }}
        onMouseLeave={() => { handleOpacity(); moduleHover(false); }}
        style={{ cursor: "pointer", ...(opacity && { opacity: opacity7 }) }}
      >
        <circle
          cx={cx}
          cy={cy}
          r={isCompleted ? radius : radius-3}
          style={mapModuleStyling(isCompleted)}
        >
        </circle>
        <g fill={isCompleted ? white : mainBlue}>
          <path
            d={`m ${cx+11},${cy-9.5} c 0.504467,-0.590542 1.256114,-1.028881 1.533719,-1.762618 -0.145443,-0.757941 -0.94162,-1.156232 -1.406127,-1.734307 -0.650108,-0.754793 -1.338493,0.119158 -1.799983,0.591952 -0.438504,0.355473 -0.818357,0.711053 -0.163987,1.068537 0.612126,0.612145 1.224252,1.224291 1.836378,1.836436 z`}
            style={{strokeWidth: 0.5}}
          />
          <path
            d={`m ${cx+6},${cy+5} c -0.0981,-0.401671 0.28691,-1.286452 -0.401754,-1.09756 -0.780716,0 -1.561432,0 -2.342148,0 0,1.341463 0,2.682926 0,4.024389 -0.736801,-0.232471 -0.533014,0.475646 -0.548781,0.964209 0.194961,0.304811 0.950378,0.04917 1.365962,0.133352 1.922728,0 3.845456,0 5.768184,0 0,-4.268292 0,-8.536585 0,-12.804877 -1.02939,0 -2.05878,0 -3.08817,0 -0.365854,0.365854 -0.731707,0.731707 -1.097561,1.097561 1.02939,0 2.05878,0 3.08817,0 0,1.097561 0,2.195122 0,3.292683 -1.890488,0 -3.780975,0 -5.671463,0 0.0981,0.401671 -0.28691,1.286453 0.401754,1.097561 1.756569,0 3.513139,0 5.269709,0 0,0.731707 0,1.463415 0,2.195122 -0.495311,-0.0029 -1.147221,-0.162496 -0.914633,0.562455 -0.225623,0.708869 0.441006,0.525486 0.914633,0.535105 0,0.97561 0,1.951219 0,2.926829 -1.463414,0 -2.926829,0 -4.390243,0 0,-0.97561 0,-1.951219 0,-2.926829 0.54878,0 1.097561,0 1.646341,0 z`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx+12},${cy-9} c -0.731104,0.731555 -1.462209,1.463109 -2.193313,2.194664 0.906448,0 1.812896,0 2.719344,0 0,5.974365 0,11.94873 0,17.923095 -7.131314,0 -14.262628,0 -21.393942,0 0.379083,-1.744993 0.124212,-3.546456 0.19192,-5.319866 0,-4.201077 0,-8.402153 0,-12.603229 3.59216,0 7.184319,0 10.776479,0 0.729764,-0.731555 1.459528,-1.463109 2.189292,-2.194664 -4.321925,0 -8.643851,0 -12.965776,0 0.308546,-2.243483 -2.131689,-4.29798 -4.287005,-3.513347 -1.619419,0.498318 -2.553096,2.294124 -2.292386,3.924077 0.03755,6.421669 -0.07922,12.850768 0.06031,19.267789 0.322557,1.811403 2.279252,2.904526 4.023708,2.633856 8.630236,0 17.260471,0 25.890707,0 0,-7.437458 0,-14.874917 0,-22.312375 -0.906442,0 -1.812916,0 -2.719338,0 z m -23.965966,20.117759 c -2.005675,0.05048 -0.671656,-3.335459 0.76545,-1.892852 0.701718,0.614974 0.185275,1.959881 -0.76545,1.892852 z m 1.096657,-4.197297 c -0.763303,-0.528703 -2.724993,0.576998 -2.19266,-1.117116 0.01282,-5.130083 -0.02954,-10.260319 0.02163,-15.390282 0.298645,-1.594116 2.773236,-0.643183 2.171034,0.779605 0,5.242598 -10e-7,10.485195 -10e-7,15.727793 z`}
            style={{strokeWidth: 0.5}}
          />
          <path
            d={`m ${cx-2},${cy-3} c 0.365122,-0.365854 0.730244,-0.731707 1.095366,-1.097561 -1.889634,0 -3.779268,0 -5.668902,0 0,4.268292 0,8.536585 0,12.804877 2.378292,0 4.756585,0 7.134877,0 -0.0981,-0.401671 0.28691,-1.286453 -0.401754,-1.097561 -0.331087,-0.173242 -0.05779,-0.950869 -0.147026,-1.365962 0,-0.886143 0,-1.772285 0,-2.658427 -0.914634,0 -1.829268,0 -2.743902,0 0.0981,0.401671 -0.28691,1.286452 0.401754,1.09756 0.373257,0.121956 1.258528,-0.268102 1.244587,0.241052 0,0.895259 0,1.790518 0,2.685777 -1.463658,0 -2.927317,0 -4.390975,0 0,-0.97561 0,-1.951219 0,-2.926829 0.495392,0.0028 1.147918,0.162703 0.915366,-0.562455 0.225754,-0.709244 -0.441696,-0.525284 -0.915366,-0.535105 0,-0.731707 0,-1.463415 0,-2.195122 0.672073,0 1.344147,0 2.01622,0 -0.0039,-0.352758 0.699492,-1.216159 0.04262,-1.097561 -0.68628,0 -1.37256,0 -2.05884,0 0,-1.097561 0,-2.195122 0,-3.292683 1.158658,0 2.317317,0 3.475975,0 z`}
            style={{strokeWidth: 0.6}}
          />
          <path
            d={`m ${cx+8},${cy-11.5} c -2.991585,2.997439 -5.98317,5.994878 -8.974755,8.992317 -0.379146,1.142317 -0.758293,2.284634 -1.137439,3.426951 1.16088,-0.408947 2.374884,-0.731632 3.503156,-1.193525 2.972525,-2.972646 5.94505,-5.945292 8.917575,-8.917938 -0.769512,-0.769268 -1.539025,-1.538537 -2.308537,-2.307805 z m -7.044145,10.652196 c -0.51122,0.168536 -1.02244,0.337073 -1.53366,0.505609 -0.513285,-0.445513 0.129251,-1.126782 0.194384,-1.682495 0.646444,-1.075108 2.282797,0.309112 1.339276,1.176886 z`}
            style={{strokeWidth: 0.5}}
          />
        </g>
      </g>
    </svg>
  )
}
