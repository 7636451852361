import { Tag } from "antd";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  CloseCircleOutlined
} from "@ant-design/icons";
import intlMessages from "./intlMessages";
import "./_challenge-label.scss";

interface IProps {
  challengesCompleted: boolean;
  challengesSubmitted: boolean;
}

export const ChallengeLabel: FC<IProps> = (props: IProps) => {
  const { challengesCompleted, challengesSubmitted } = props;

  let challengeLabel: React.ReactNode;

  if (challengesCompleted) {
    challengeLabel = <FormattedMessage {...intlMessages.completed} />;
  } else if (props.challengesSubmitted) {
    challengeLabel = <FormattedMessage {...intlMessages.gradingInProgress} />;
  } else {
    challengeLabel = <FormattedMessage {...intlMessages.unfinished} />;
  }

  return (
    <div className="course-detail-page__challenge-label mb-8">
        <Tag  color={challengesCompleted ? 'success' : !challengesCompleted && challengesSubmitted ? 'warning' : 'error'}>
        {challengeLabel}
        </Tag>
    </div>
  );
};
