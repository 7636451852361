import React, { FC } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import intlMessages from "./intlMessages";
import { Form } from "antd";
import CustomInputField from "../components/TextField/CustomInputField";
import {
  lowerCaseRegEx,
  numberRegEx,
  specialCharacterRegEx,
  upperCaseRegEx,
} from "../components/RegexValidations";
import SubmitButton from "../components/SubmitButton/SubmitButton";
import "./_reset-password-form.scss";

const { Item } = Form;

interface IProps extends WrappedComponentProps {
  handleSubmit: any;
}

const ResetPasswordForm: FC<IProps> = (props: IProps) => {
  const { handleSubmit } = props;
  const [form] = Form.useForm<any>();
  const { formatMessage } = props.intl;
  const newPasswordConfirmPresenceError: string = formatMessage(
    intlMessages.newPasswordConfirmErrorsPresence
  );
  const newPasswordConfirmRepeatError: string = formatMessage(
    intlMessages.newPasswordConfirmErrorsRepeat
  );
  const newPasswordLowercasePresentError: string = formatMessage(
    intlMessages.newPasswordErrorsLowercasePresent
  );
  const newPasswordMinLengthError: string = formatMessage(
    intlMessages.newPasswordErrorsMinLength,
    { limit: 10 }
  );
  const newPasswordNumberPresentError: string = formatMessage(
    intlMessages.newPasswordErrorsNumberPresent
  );
  const newPasswordPresenceError: string = formatMessage(
    intlMessages.newPasswordErrorsPresence
  );
  const newPasswordSpecialCharacterPresentError: string = formatMessage(
    intlMessages.newPasswordErrorsSpecialCharacterPresent
  );
  const newPasswordUppercasePresentError: string = formatMessage(
    intlMessages.newPasswordErrorsUppercasePresent
  );

  const handleFormSubmit = (data) => {
    const config = {
      newPassword: "newPassword",
      newPasswordConfirm: "newPasswordConfirm",
    };

    handleSubmit(data)
      .then((resp) => {})
      .catch((error) => {
        const { validationErrors, errorMessage } = error.response.data;
        if (validationErrors) {
          form.setFields(
            Object.keys(validationErrors).map((key) => {
              return {
                name: config[key],
                errors: [validationErrors[key]],
              };
            })
          );
        } else {
          form.setFields([
            {
              name: "newPassword",
              errors: [errorMessage],
            },
          ]);
        }
      });
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Item
        name="newPassword"
        rules={[
          { required: true, message: newPasswordPresenceError },
          { min: 10, message: newPasswordMinLengthError },
          {
            message: newPasswordLowercasePresentError,
            pattern: lowerCaseRegEx,
          },
          {
            message: newPasswordUppercasePresentError,
            pattern: upperCaseRegEx,
          },
          { message: newPasswordNumberPresentError, pattern: numberRegEx },
          {
            message: newPasswordSpecialCharacterPresentError,
            pattern: specialCharacterRegEx,
          },
        ]}
      >
        <CustomInputField
          form={form}
          id="reset-password-new-password"
          label={<FormattedMessage {...intlMessages.newPasswordLabel} />}
          name="newPassword"
          noMarginBottom={true}
          type="password"
        />
      </Item>

      <Item
        name="newPasswordConfirm"
        rules={[
          { required: true, message: newPasswordConfirmPresenceError },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                value?.trim() != "" &&
                getFieldValue("newPassword") != value
              ) {
                return Promise.reject(new Error(newPasswordConfirmRepeatError));
              }
              return Promise.resolve();
            },
          }),
        ]}
        style={{ padding: "25px 0px" }}
      >
        <CustomInputField
          form={form}
          id="reset-password-new-password-confirm"
          label={<FormattedMessage {...intlMessages.newPasswordConfirmLabel} />}
          name="newPasswordConfirm"
          noMarginBottom={true}
          type="password"
        />
      </Item>

      <Item>
        <SubmitButton>
          <FormattedMessage {...intlMessages.submitTitle} />
        </SubmitButton>
      </Item>
    </Form>
  );
};

export default injectIntl(ResetPasswordForm);
