import Shepherd from 'shepherd.js';
import {IntlShape} from 'react-intl';

import {IBasicShepherdButtons} from '../../TourCreator';

import intlMessages from './intlMessages';

export function configurePlayerTour(tour: Shepherd.Tour, basicButtons: IBasicShepherdButtons, intl: IntlShape){
  tour.addStep({
    text: intl.formatMessage(intlMessages.step1),
    title: 'Player',
    attachTo: { 
      element: '.player__toggle-button', 
      on: 'right'
    },
    buttons: [basicButtons.SKIP, basicButtons.NEXT],
  });

  tour.addStep({
    text: intl.formatMessage(intlMessages.step2),
    title: 'Player',
    attachTo: { 
      element: '.player__on-screen-navigation__button:not(.player__on-screen-navigation__button--hidden)', 
      on: 'left'
    },
    buttons: [basicButtons.BACK, basicButtons.SKIP, basicButtons.NEXT],
  });

  tour.addStep({
    text: intl.formatMessage(intlMessages.step3),
    title: 'Player',
    attachTo: { 
      element: '.player__exit-button',
      on: 'top'
    },
    buttons: [basicButtons.BACK, basicButtons.FINISH],
  });
  
  // EXAMPLE OF INTERACTIVE TOUR
  // STEPS IDENTIFIED BY VARAIBLES (RETURNED FROM .addStep())
  // EVENTS CAN BE CONNECTED IN REACT COMPONENTS BY PULLING TOUR OBJECTS FROM ONBOARDING STORE "TOURS" MAP
  // THEN .on() AND .trigger() WILL BE AVAILABLE ON TOUR, WHICH FUNCTIONS AS ISOLATED EVENT BUS

  // tour.on('show', (data: {previous: Shepherd.Step, step: Shepherd.Step}) => {
  //   if (data.step === navigationStep && data.previous === null) {
  //     setImmediate(() => tour.hide());
      
  //     tour.on('navigationAnimationFinished', () => {
  //       navigationStep.show();
  //     });
  //   }
  // });

  //   if (data.step === chapterEntryStep && data.previous === exitButtonStep) {
  //     tour.trigger('openNavigation');

  //     setImmediate(() => tour.hide());

  //     setTimeout(() => {
  //       chapterEntryStep.show();
  //     }, 1000);
  //   }

  //   if (data.step === exitButtonStep) {
  //     tour.trigger('closeNavigation');
  //   }
  // });

  // tour.on('navigationOpened', () => {
  //   if (tour.getCurrentStep() === menuOpenningStep) {
  //     tour.hide();
  //     setTimeout(tour.next, 1000);
  //   }
  // });
}
