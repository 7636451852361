import React from 'react';
import Select from 'react-select';
import {BaseFilter} from '../BaseFilter';

import './_select-filter.scss';

export class SelectFilter<ValueType> extends BaseFilter<ValueType> {
  private entries: Array<{ label: React.ReactNode; value: ValueType; }>;
  private outputFilterName: string;

  constructor(
    attachedToColumn: string,
    outputFilterName: string,
    entries: Array<{ label: React.ReactNode; value: ValueType; }>,
    defaultValue?: ValueType,
  ) {
    super(attachedToColumn, defaultValue ? { [outputFilterName]: defaultValue } : {});

    this.entries = entries;
    this.outputFilterName = outputFilterName;
  }

  private handleValueChange = (entry: {label: React.ReactNode; value: ValueType} | null) => {
    this.updateValues({ [this.outputFilterName]: entry ? entry.value : null });
  };

  render() {
    return (
      <Select
        className="data-table__select-filter"
        key={this.id}
        onChange={this.handleValueChange}
        options={this.entries}
        placeholder=""
        value={this.currentValues[this.outputFilterName]}
      />
    );
  }
}
