import React from 'react';
import {range} from 'lodash';
import {Dot} from './Dot';

interface IPropTypes {
  activeIndex: number;
  dotCount: number;
  onDotClick(targetQuestionIndex: number): void;
}

export const DottedNavigation: React.FC<IPropTypes> = (props) => (
  <div>
    {range(props.dotCount).map((dotIndex) => (
      <Dot
        dotIndex={dotIndex}
        isActive={dotIndex === props.activeIndex}
        key={dotIndex}
        onClick={props.onDotClick}
      />
    ))}
  </div>
);
