import {defineMessages} from 'react-intl';

export default defineMessages({
  actions: {
    defaultMessage: 'Actions',
    id: 'CustomerGrading.AttemptsList.Actions',
  },
  challengeName: {
    defaultMessage: 'Challenge name',
    id: 'CustomerGrading.AttemptsList.ChallengeName',
  },
  date: {
    defaultMessage: 'Date',
    id: 'CustomerGrading.AttemptsList.Date',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'CustomerGrading.AttemptsList.Failed',
  },
  evaluate: {
    defaultMessage: 'Evaluate',
    id: 'CustomerGrading.AttemptsList.Evaluate',
  },
  group: {
    defaultMessage: 'Group',
    id: 'CustomerGrading.AttemptsList.Group',
  },
  heading: {
    defaultMessage: 'Sorry',
    id: 'CustomerGrading.AttemptsList.Heading',
  },
  language: {
    defaultMessage: 'Language',
    id: 'AttemptsLanguageLabel.Language',
  },
  message: {
    defaultMessage: 'You are not allowed to access this page',
    id: 'CustomerGrading.AttemptsList.Message',
  },
  passed: {
    defaultMessage: 'Passed',
    id: 'CustomerGrading.AttemptsList.Passed',
  },
  status: {
    defaultMessage: 'Status',
    id: 'CustomerGrading.AttemptsList.Status',
  },
  submitted: {
    defaultMessage: 'Submitted',
    id: 'CustomerGrading.AttemptsList.Submitted',
  },
  userName: {
    defaultMessage: 'User name',
    id: 'CustomerGrading.AttemptsList.UserName',
  },
  view: {
    defaultMessage: 'View',
    id: 'CustomerGrading.AttemptsList.View',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'CustomerGrading.AttemptsList.Edit',
  }
});
