import AppNavigator from '@u4i/common/AppNavigator';
import React from 'react';

import './_not-found-page.scss';

const NotFoundPage = () => {
  const navigateHome = () => {
    AppNavigator.directPush('/');
  }

  return (
    <div className="not-found-page">
      <h1 className="not-found-page__message">
        Oops! 
        <br />
        <small>
          This page can’t be found.
        </small>
      </h1>

      <button 
        className="not-found-page__button" 
        onClick={navigateHome} 
        type="button"
      >
        Homepage
      </button>
    </div>
  );
}

export default NotFoundPage;
