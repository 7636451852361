import {defineMessages} from 'react-intl';

export default defineMessages({
  attemptCreationFailed: {
    defaultMessage: 'Something went wrong, please contact our support team: coach@u4i.io',
    id: 'Exercises.AttemptCreationFailed',
  },
  introMessage: {
    defaultMessage: 'In order to successfully pass this challenge all tasks need to be completed.'
      + ' Your submission will be assessed based on correctness, clarity, thoroughness, extent of'
      + ' research, and extent of engagement (if applicable); and you will receive your results'
      + ' within 2 work days. There is no time limit.',
    id: 'Excercises.IntroMessage',
  },
  warningMessageQuestions:{
    defaultMessage:'This exercise has no configured questions yet.',
    id:'Excercises.WarningMessageQuestions'
  }
});
