import { Col, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PlayCircleFilled, CloudDownloadOutlined } from "@ant-design/icons";
import intlMessages from "./intlMessages";
import { green, mainBlue } from "@u4i/styles/styles-variables";

const { Text } = Typography;

const ChapterButtons = ({
  challengesCompleted,
  viewed,
  onClick,
  showDownloadPdfButton,
  chapterTitle,
  pdfDownloadUrl,
  showButtonResume
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pdfDownload = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 7000);
  };

  return (
    <>
      <Col md={12}>
        {showDownloadPdfButton &&
          viewed && (
            <a
              className="chapter-card-play"
              download={`${chapterTitle} - Summary.pdf`}
              href={pdfDownloadUrl}
              onClick={() => pdfDownload()}
            >
              <Row justify="center" align="middle">
                <Col md={24} className="text-center mb-8">
                  {isLoading ? (
                    <Spin size="large" />
                  ) : (
                    <CloudDownloadOutlined className="antd-icon-cloud" />
                  )}
                </Col>
                <Col md={24} className="text-center" style={{ overflow: "inherit", whiteSpace: "nowrap"}}>
                  <Text>
                    {isLoading ? (
                      <FormattedMessage {...intlMessages.loading} />
                    ) : (
                      <FormattedMessage {...intlMessages.summary} />
                    )}
                  </Text>
                </Col>
              </Row>
            </a>
          )}
      </Col>
      <Col md={12}>
        <div className="chapter-card-play" onClick={onClick}>
          <Row justify="center" align="middle">
            <Col md={24} className="text-center mb-8">
              <PlayCircleFilled
                className="antd-icon-start"
                style={{
                  color: challengesCompleted || viewed ? green : mainBlue
                }}
              />
            </Col>
            <Col md={24} className="text-center">
              <Text>
                {challengesCompleted || viewed ? (
                  challengesCompleted ? (
                    <FormattedMessage {...intlMessages.retake} />
                  ) : (
                    <FormattedMessage {...intlMessages.watchAgain} />
                  )
                ) : showButtonResume ? (
                  <FormattedMessage {...intlMessages.buttonLabel} />
                ): (
                  <FormattedMessage {...intlMessages.start} />
                )}
              </Text>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ChapterButtons;
