import PropTypes from 'prop-types';
import React, {Component} from 'react';

import './_volume-bar.scss';

class VolumeBar extends Component<any> {
  static propTypes = {
    onVolumePercentageChange: PropTypes.func.isRequired,
    volumePercentage: PropTypes.number.isRequired,
  };

  volumeBarRef:any = React.createRef();

  componentWillUnmount() {
    this.cancelVolumeAdjustment();
  }

  adjustVolume = (mouseX) => {
    const volumeBarBoundingRect:any = this.volumeBarRef.current.getBoundingClientRect();

    const totalDistance:any = volumeBarBoundingRect.width;
    const clickDistanceX:any = Math.min(mouseX - volumeBarBoundingRect.left, totalDistance);
    // @ts-ignore
    const newVolumePercentage:any = parseInt((clickDistanceX / totalDistance) * 100, 10);

    this.props.onVolumePercentageChange(newVolumePercentage > 2 ? newVolumePercentage : 0);
  };

  cancelVolumeAdjustment = () => {
    window.removeEventListener('mousemove', this.handleVolumeAdjustment);
    window.removeEventListener('mouseup', this.cancelVolumeAdjustment);
  };

  handleVolumeAdjustment = (event) => {
    this.adjustVolume(event.clientX);
  };

  startVolumeAdjustment = (event) => {
    this.adjustVolume(event.clientX);

    window.addEventListener('mousemove', this.handleVolumeAdjustment);
    window.addEventListener('mouseup', this.cancelVolumeAdjustment);
  };

  render() {
    return (
      <button
        className="youtube-player__volume-bar"
        onMouseDown={this.startVolumeAdjustment}
        ref={this.volumeBarRef}
      >
        <div
          className="youtube-player__volume-bar__fill"
          style={{width: `${this.props.volumePercentage}%`}}
        />
      </button>
    );
  }
}

export default VolumeBar;
