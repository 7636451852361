import {action, computed, observable, runInAction,makeObservable} from 'mobx';
import {AdminUserGroupEntity} from '@u4i/state/entities/admin/AdminUserGroupEntity';
import {IAdminUserGroup, IAdminUserGroupDetailed} from '@u4i/parsers/admin/AdminUserGroupParser';
import {IApi, IPaginatedItems} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';
import { AntDTableFilters, DEFAULT_PAGE_SIZE } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';
import { NewUserGroupData } from '@u4i/modules/Admin/common/Interfaces/UserGroups.interfaces';

export class AdminUserGroupsStore {
  activeUserGroup: AdminUserGroupEntity;
  allUserGroupsData: Array<IAdminUserGroup> = [];
  config: AntDTableFilters<IAdminUserGroup> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {}
  };
  currentOffset = 0;
  deletingGroup = false;
  deletingUserGroupError?: string;
  fetching = true;
  fetchingNewUserGroup = false;
  fetchingNewUserGroupError = false;
  totalUserGroups = 0;
  userGroupsData: Array<AdminUserGroupEntity> = [];
  userGroupList: Array<IAdminUserGroup> = [];
  private apiService: IApi;
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      activeUserGroup: observable,
      allUserGroupsData: observable,
      config: observable,
      currentOffset: observable,
      deletingGroup: observable,
      deletingUserGroupError: observable,
      fetching: observable,
      fetchingNewUserGroup: observable,
      fetchingNewUserGroupError: observable,
      totalUserGroups: observable,
      userGroupsData: observable,
      userGroupList: observable,
      userGroupsFilters: computed,
      copyUserGroup: action.bound,
      createUserGroup: action.bound,
      deleteUserGroup: action.bound,
      getUserGroupsList: action.bound,
      reloadUserGroupsData: action.bound,
      resetData: action.bound,
      setActiveUserGroup: action.bound
    });

    const {apiService} = rootStore;

    this.apiService = apiService;
    this.rootStore = rootStore;
  }

  get userGroupsFilters() {
    let userGroupsFilters: Array<{text: string, value: string}> = [];

    if (this.userGroupList.length > 0) {
      this.userGroupList.forEach(userGroup => {
        userGroupsFilters.push({
          text: userGroup.name,
          value: userGroup.id,
        });
      });

      return userGroupsFilters;
    }

    return [];
  }

  async copyUserGroup(groupId: string, name: string) {
    this.fetchingNewUserGroup = true;
    const newGroupCopyName: string = `${name}_copy`;

    if (newGroupCopyName.length > 45) {
      this.fetchingNewUserGroup = false;

      return Notification(NotificationEnum.Error, "Group copy", `The group name \"${newGroupCopyName}\" is too long`);
    }

    try {
      const userGroupInfo: IAdminUserGroupDetailed = await this.apiService.admin.userGroups.copyUserGroup(groupId);

      runInAction(() => {
        const newAdminUserGroupEntity: AdminUserGroupEntity = new AdminUserGroupEntity(userGroupInfo, this.rootStore);

        this.userGroupsData = [newAdminUserGroupEntity, ...this.userGroupsData];
        this.activeUserGroup = newAdminUserGroupEntity;

        Notification(NotificationEnum.Success, "Group copy", `Group \"${name}\" copied.`);
      });
    } catch(error) {
      const copyUserGroupError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "Group copy", `${copyUserGroupError.errorMessage}. ${copyUserGroupError.validationErrors.name}`);

      this.fetchingNewUserGroupError = true;
    } finally {
      this.fetchingNewUserGroup = false;
    }
  }

  async createUserGroup(groupData: NewUserGroupData) {
    this.fetchingNewUserGroup = true;

    try {
      const userGroupInfo: IAdminUserGroupDetailed = await this.apiService.admin.userGroups.submitNewUserGroup(groupData);

      runInAction(() => {
        const newAdminUserGroupEntity: AdminUserGroupEntity = new AdminUserGroupEntity(userGroupInfo, this.rootStore);

        this.userGroupsData = [newAdminUserGroupEntity, ...this.userGroupsData];
        this.activeUserGroup = newAdminUserGroupEntity;

        Notification(NotificationEnum.Success, "Group create", `Group \"${groupData.name}\" created.`);
      });
    } catch(error) {
      const createUserGroupError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "Group create", createUserGroupError);

      this.fetchingNewUserGroupError = true;
    } finally {
      this.fetchingNewUserGroup = false;
    }
  }

  async deleteUserGroup(groupId: string, alternativeGroupId: string, groupName: string) {
    this.deletingGroup = true;
    const isOneItemOnPage: boolean = this.userGroupsData.length === 1;

    try {
      await this.apiService.admin.userGroups.deleteUserGroup(groupId, alternativeGroupId);

      if (isOneItemOnPage) {
        this.config = {
          ...this.config,
          current: this.config.current === 1 ? 1 : this.config.current - 1,
          offset: this.config.offset - DEFAULT_PAGE_SIZE,
        }
      }

      this.reloadUserGroupsData(this.config);

      Notification(NotificationEnum.Success, "Group delete", `Group \"${groupName}\" deleted.`);

    } catch(error) {
      this.deletingUserGroupError = error.response.data.errorMessage || error.response.data.message;

      Notification(NotificationEnum.Error, "Group delete", this.deletingUserGroupError);
    } finally {
      this.deletingGroup = false;
    }
  }

  async getUserGroupsList(config: AntDTableFilters<IAdminUserGroup>) {
    const userGroupList: IPaginatedItems<IAdminUserGroup> = await this.apiService.admin.userGroups.fetchUserGroupsData(config);

    runInAction(() => {
      this.userGroupList =  userGroupList.items;
    });
  }

  async reloadUserGroupsData(config: AntDTableFilters<IAdminUserGroup> | any) {
    this.fetching = true;

    try {
      let currentOffset: number = 0;
      let userGroupsCount;
      currentOffset = config.current ? config.current - 1 : 0;

      this.config = {
        ...config,
        limit: config.limit? config.limit : DEFAULT_PAGE_SIZE,
        offset: currentOffset * DEFAULT_PAGE_SIZE,
      }

      if (!config.limit) {
        const userGroupsSimplified = await this.apiService.admin.userGroups.fetchUserGroupsData(config);
        const {items, totalItems} = userGroupsSimplified;
        userGroupsCount = totalItems;

        if (this.allUserGroupsData.length === 0) {
          this.allUserGroupsData = items;
        }
      }

      const userGroupsInfo = await this.apiService.admin.userGroups.fetchUserGroupsDataDetailed(this.config);

      runInAction(() => {
        const {items, totalItems} = userGroupsInfo;
        const adminUserGroups: AdminUserGroupEntity[] = items.map((user: IAdminUserGroupDetailed) => new AdminUserGroupEntity(user, this.rootStore));

        if (!userGroupsCount) {
          userGroupsCount = totalItems;
        }

        this.currentOffset = currentOffset;
        this.fetching = false;
        this.totalUserGroups = userGroupsCount;
        this.userGroupsData = adminUserGroups;
      });
    } catch(error) {
      runInAction(() => {
        this.fetching = false;

        throw(error);
      });
    }
  }

  async generateToken(groupId: string) {
    return this.apiService.admin.userGroups.generateGroupToken(groupId);
  }

  resetData() {
    this.allUserGroupsData = [];
    this.currentOffset = 0;
    this.fetching = true;
    this.fetchingNewUserGroup = false;
    this.fetchingNewUserGroupError = false;
    this.totalUserGroups = 0;
    this.userGroupsData = [];
  }

  setActiveUserGroup(adminUserGroupEntity: AdminUserGroupEntity) {
    this.activeUserGroup = adminUserGroupEntity;
  }
}
