import React from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_unsupported-element.scss';
import { AlertStatusEnum } from '@u4i/common/enums/AlertStatusEnum';
import { Alert } from '@u4i/common/Alert';

function UnsupportedElement() {
  return (
    <div className="player__unsupported-element">
      <Alert
        heading={<FormattedMessage {...intlMessages.errorHeading} />}
        message={<FormattedMessage {...intlMessages.errorMessage} />}
        type={AlertStatusEnum.ERROR}
      />
    </div>
  );
}

export default UnsupportedElement;
