import { defineMessages } from "react-intl";

export default defineMessages({
  selectDigitalLab: {
    defaultMessage: "Select a Digital lab",
    id: "DigitalLab.Booking.SelectDigitalLab",
  },
  BookASlot: {
    defaultMessage: "Book a slot",
    id: "DigitalLab.Booking.BookASlot",
  },
  Cancel: {
    defaultMessage: "Cancel",
    id: "DigitalLab.Booking.Cancel",
  },
  Book: {
    defaultMessage: "Book",
    id: "DigitalLab.Booking.BookASlot",
  },
  EndTime: {
    defaultMessage: "End time",
    id: "DigitalLab.Booking.EndTime",
  },
  StartTime: {
    defaultMessage: "Start time",
    id: "DigitalLab.Booking.StartTime",
  },
});
