import {defineMessages} from 'react-intl';

export default defineMessages({
  browserHeading: {
    defaultMessage: 'Browser not supported',
    id: 'JupyterLab.BrowserHeading',
  },
  browserMessage: {
    defaultMessage: 'Unfortunately Safari and Internet Explorer are not supported at the moment. Please use the latest Chrome, Firefox or Edge.',
    id: 'JupyterLab.BrowserMessage',
  },
  errorHeading: {
    defaultMessage: 'Oops!',
    id: 'JupyterLab.ErrorHeading',
  },
  errorMessage: {
    defaultMessage: 'This lab is currently disabled. Please try again later. If you keep encountering this problem, contact our support team: support@university4industry.com',
    id: 'JupyterLab.ErrorMessage',
  },
  goBack: {
    defaultMessage: 'Go back',
    id: 'JupyterLab.GoBack',
  },
  mobileHeading: {
    defaultMessage: 'Oops!',
    id: 'JupyterLab.MobileHeading',
  },
  mobileMessage: {
    defaultMessage: 'Jupyter lab challenges do not support mobile devices, please use a desktop.',
    id: 'JupyterLab.MobileMessage',
  },
  submitErrorMessage: {
    defaultMessage: 'There was a problem submitting the lab.',
    id: 'JupyterLab.SubmitErrorMessage',
  },
});
