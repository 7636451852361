import { Alert } from '@u4i/common/Alert';
import { AlertStatusEnum } from '@u4i/common/enums/AlertStatusEnum';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';

interface IProps {
  errorCode: number
}

export const ErrorMessage: FC<IProps> = (props: IProps) => {
  const { errorCode } = props;

  switch (errorCode) {
    case 401:
      return (
        <Alert
          heading={<FormattedMessage {...intlMessages.errorHeading401} />}
          message={<FormattedMessage {...intlMessages.errorMessage401} />}
          type={AlertStatusEnum.ERROR}
        />
      );

    case 403:
      return (
        <Alert
          heading={<FormattedMessage {...intlMessages.errorHeading403} />}
          message={<FormattedMessage {...intlMessages.errorMessage403} values={{mail: <a href="mailto:admin@university4industry.com">admin@university4industry.com</a>}} />}
          type={AlertStatusEnum.ERROR}
        />
      );

    case 404:
      return (
        <Alert
          heading={<FormattedMessage {...intlMessages.errorHeading404} />}
          message={<FormattedMessage {...intlMessages.errorMessage404} />}
          type={AlertStatusEnum.ERROR}
        />
      );

    default:
      return null;
  }
}

