import { withCalculatedFontSize } from "@u4i/modules/VirtualExperience/components/withCalculatedFontSize";
import React, { FC } from "react";
import { TableOfContentsList } from "./components/TableOfContentsList";
import { TableOfContentsListHeading } from "./components/TableOfContentsListHeading";
import { TableOfContentsSidebar } from "./components/TableOfContentsSidebar";
import "./_table-of-contents-element.scss";

interface IPropTypes {
  calculatedFontSize?: number;
  contents: Array<{
    id: string;
    text: string;
  }>;
  page: number;
  pagesTotal: number;
}

const TableOfContentsElement: FC<IPropTypes> = (props) => {
  return (
    <div
      className="player__table-of-contents-element"
      style={{ fontSize: props.calculatedFontSize }}
    >
      <TableOfContentsSidebar />

      <div className="player__table-of-contents-element__right-section">
        <div className="player__table-of-contents-element__content-wrapper">
          <TableOfContentsListHeading
            page={props.page}
            pagesTotal={props.pagesTotal}
          />

          <TableOfContentsList contents={props.contents} />
        </div>
      </div>
    </div>
  );
};

const WrappedElement = withCalculatedFontSize(TableOfContentsElement);

export { WrappedElement as TableOfContentsElement };
