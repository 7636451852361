import {AxiosInstance} from 'axios';

import {ChapterParser} from '@u4i/parsers/ChapterParserNew';
import {CompareMeParser} from '@u4i/parsers/CompareMeParser';
import {CurriculumParser} from '@u4i/parsers/CurriculumParser';
import {ILandingPage, LandingPageParser} from '@u4i/parsers/LandingPageParser';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {PartnerInfoParser} from '@u4i/parsers/PartnerInfoParser';
import {SectionTypeEnum} from '@u4i/common/enums/SectionTypeEnum';
import {SkillParserBasic} from '@u4i/parsers/SkillParserBasic';
import {TrailerParser} from '@u4i/parsers/TrailerParser';
import { ProgramUIParser } from '@u4i/parsers/ProgramParser';

export class LandingPagesApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchPageData(pageSlug: string, previewModeEnabled: boolean): Promise<ILandingPage> {
    const response = await this.apiClient.get(`/v1/custom-pages/${pageSlug}`, {
      params: {preview: previewModeEnabled},
    });

    return LandingPageParser.parse(response.data);
  }

  async fetchSectionItems<SectionItemType>(
    sectionId: string,
    sectionType: SectionTypeEnum,
    previewModeEnabled: boolean,
    itemsLanguage: LanguageEnum | null,
  ): Promise<Array<SectionItemType>> {
    const params: {language?: LanguageEnum, preview: boolean} = {preview: previewModeEnabled};

    if (itemsLanguage) {
      params.language = itemsLanguage;
    }

    const response = await this.apiClient.get(`/v2/custom-pages/section/${sectionId}/items`, {params});

    const parsers = {
      [SectionTypeEnum.CHAPTER]: ChapterParser,
      [SectionTypeEnum.COMPARE_ME]: CompareMeParser,
      [SectionTypeEnum.CURRICULUM]: CurriculumParser,
      [SectionTypeEnum.MYSKILLS]: SkillParserBasic,
      [SectionTypeEnum.PARTNER_INFO]: PartnerInfoParser,
      [SectionTypeEnum.SKILL]: SkillParserBasic,
      [SectionTypeEnum.TRAILER]: TrailerParser,
      [SectionTypeEnum.PROGRAM]: ProgramUIParser
    };

    return parsers[sectionType].parseArray(response.data);
  }
}
