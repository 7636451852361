import React from 'react';
import {inject, observer} from 'mobx-react';

function withUser(InnerComponent) {
  function WrapperWithUser(props) {
    return (
      <InnerComponent
        isAuthorized={props.rootStore.userStore.isAuthorized}
        userData={props.rootStore.userStore.userData}
        {...props}
      />
    );
  }

  return inject('rootStore')(observer(WrapperWithUser));
}

export default withUser;
