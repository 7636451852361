export const htmlTemplate = `
<js3q-container data-role="sdn-player">
  <js3q-container data-role="sdn-display">
    `
    +
    /**
     * Commented out to hide stats that contains debug info
     * <js3q-container data-role="sdn-stats"></js3q-container>
     */
    `
    <js3q-container data-role="motion-poster"></js3q-container>
    <js3q-container data-role="sdn-wall"></js3q-container>
    <js3q-container data-role="display-ad"></js3q-container>
    <js3q-button data-role="adskip-button"> {{skip}} </js3q-button>
    <js3q-button data-role="sdn-unmute-button"></js3q-button>

    <js3q-container data-role="channel-label"></js3q-container>
    <js3q-container data-role="title"></js3q-container>

    <video data-role="source-container" x-webkit-airplay="allow" webkit-playsinline="true" playsinline="true" src=""></video>

    <js3q-container data-role="play-button-overlay">
      <js3q data-role="flex-center-wrapper">
        <js3q-span data-role="play-button-overlay-span"></js3q-span>
      </js3q>
    </js3q-container>

    <js3q-container data-role="play-buffer-overlay">
      <js3q-container data-role="play-buffer-overlay-spinner"></js3q-container>
    </js3q-container>

    <js3q-container data-role="player-controls">` +

      /*  seek bar section starts
      This section handles seekbar.
        - pointer
        - scrubber
      */
     `<js3q-container data-role="seek-bar">
        <js3q-container data-role="scrubberbar"></js3q-container>
          <js3q-container data-role="scrubber-loaded">
            <js3q-container data-role="scrubber-loaded-pointer"></js3q-container>
          </js3q-container>
          <js3q-container data-role="scrubberdragger"></js3q-container>
        <js3q-container data-role="scrubber-playahead"></js3q-container>
      </js3q-container>` +
      /*  seek bar section ends  */


      /* video audio controls starts
      This section contains audio and video handlers. Some important buttons here are:
        - play button
        - pause button
        - volume button
        - time tracker
        - fullscreen
      */
     `<js3q-container data-role="chrome">
        <js3q-button data-role="pause-button" tooltip="{{tips.play}}" tooltip-position="left" ></js3q-button>
        <js3q-button data-role="play-button" tooltip="{{tips.pause}}" tooltip-position="left" ></js3q-button>
        <js3q-button data-role="back-button" tooltip="{{playlist.playback}}" tooltip-position="left" ></js3q-button>
        <js3q-button data-role="next-button" tooltip="{{playlist.playnext}}" tooltip-position="left" ></js3q-button>
        <js3q-button data-role="play-minus10-button"></js3q-button>
        <js3q-button data-role="play-plus10-button"></js3q-button>
        <js3q-button data-role="play-minus15-button"></js3q-button>
        <js3q-button data-role="play-plus15-button"></js3q-button>
        <js3q-button data-role="volume-button" tooltip="{{tips.mutevolume}}" tooltip-position="left" ></js3q-button>

        <js3q-container data-role="volume-display-wrapper">
          <js3q-container data-role="volume-display">
            <js3q-container data-role="volume-controls-marker">
              <js3q-span data-role="volume-controls-thumb"></js3q-span>
            </js3q-container>
          </js3q-container>
        </js3q-container>

        <js3q-button data-role="timeleft-display">
          <js3q-container data-role="timeleft-span">
            <js3q-container> ● </js3q-container> Live
          </js3q-container>
        </js3q-button>

        <js3q-button data-role="enter-button" tooltip="{{tips.fullscreen}}"></js3q-button>
        <js3q-button data-role="exit-button" tooltip="{{tips.exitfullscreen}}" ></js3q-button>
        <js3q-button data-role="qos-button" tooltip="{{tips.settings}}"></js3q-button>
        <js3q-button data-role="cast-button" tooltip="{{tips.cast}}"></js3q-button>
        <js3q-button data-role="airplay-button" tooltip="{{tips.airplay}}" ></js3q-button>
      </js3q-container>` +
      /* video audio controls ends */

      `<js3q-container data-role="thumbnails"></js3q-container>
    </js3q-container>

    <js3q-container data-role="gradiant"></js3q-container>
    <js3q-container data-role="gradiant-top"></js3q-container>` +

    /*  qos menu section starts
    This section contains configurations related to settings, among them are:
      - playback rate settings
      - quality of video
      - audio settings
    */
   `<js3q-container data-role="qos-menu">
      <js3q-h1 data-role="qos-menu-header">
        {{tips.settings}}
        <button tooltip-position="left" tooltip="{{tips.settingsClose}}" data-role="qos-menu-header-close-button" ></button>
      </js3q-h1>

      <js3q-container data-role="qos-menu-wrapper">
        <js3q-container data-role="qs-playbackrate-settings">
          <js3q-h2>{{playbackrate}}</js3q-h2>
          <js3q-ul data-role="qs-playbackrate-menu"></js3q-ul>
        </js3q-container>

        <js3q-container data-role="qs-quality-settings">
          <js3q-h2>{{quality}}</js3q-h2>
          <js3q-ul class="sdn-ul-menu">
            <js3q-li>
              <js3q-span class="current">{{auto}}</js3q-span>
            </js3q-li>
          </js3q-ul>
        </js3q-container>

        <js3q-container data-role="qs-audio-settings">
          <js3q-h2>{{audiotracks}}</js3q-h2>
          <js3q-ul data-role="qs-audio-menu"></js3q-ul>
        </js3q-container>

        <js3q-container data-role="qs-cc-settings"></js3q-container>
      </js3q-container>
    </js3q-container>` +
    /* qos menu section ends */

   `<js3q-container data-role="top-chrome">
      <js3q-container data-role="share-menu">
        <js3q-container data-role="sidebar-button"></js3q-container>
      </js3q-container>
    </js3q-container>

    <js3q-container data-role="sidebar"></js3q-container>

    <js3q-container data-role="playlist"></js3q-container>
  </js3q-container>
</js3q-container>
`;
