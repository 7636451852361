import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import {LoginSection} from './LoginSection';

import intlMessages from './intlMessages';
import LoginForm from '@u4i/common/forms/LoginForm/LoginForm';
import './_login-section.scss';

interface IProps {
  sectionModel: LoginSection
  login: () => void
}

const VisualComponent: FC<IProps> = (props: IProps) => {
  const {sectionModel} = props;
  const {handleLoginFormSubmission, openPasswordRecoveryModal} = sectionModel;
  return (
    <BasicInfoWrapper 
      centered 
      isLoading={false} 
      sectionModel={sectionModel}
    >
      <div className="container">
        <div className="landing-page__login-section">
          <div className="landing-page__login-section__form-wrapper">
            <LoginForm handleSubmit={handleLoginFormSubmission} />
          </div>

          <button
            className="landing-page__login-section__button"
            onClick={openPasswordRecoveryModal}
            type="button"
          >
            <FormattedMessage {...intlMessages.forgotPassword} />
          </button>
        </div>
      </div>
    </BasicInfoWrapper>
  );
};

const WrappedComponent = (observer(VisualComponent));
export {WrappedComponent as VisualComponent};
