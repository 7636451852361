import Parser from '@u4i/utils/Parser';

export interface IAccessData {
  createdAt: string;
  expires: string;
  token: string;
}

export const AccessDataParser = new Parser<IAccessData>(accessData => ({
  createdAt: accessData.created_at,
  expires: accessData.expires,
  token: accessData.token,
}));
