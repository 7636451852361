import React from 'react';
import {observer} from 'mobx-react';

import {FieldModel} from './FieldModel';

export interface ICustomFieldProps<ValueType> {
  disabled: boolean;
  id: string;
  meta: {
    dirty: boolean;
    error?: string;
    focused: boolean;
    touched: boolean;
  };
  onBlur: () => void;
  onChange: (newValue: ValueType) => void;
  onError: (newError: string) => void;
  onFocus: () => void;
  value: ValueType;
}


interface IPropTypes<T extends ICustomFieldProps<any>> {
  component: React.ComponentType<ICustomFieldProps<any>>;
  componentProps: Omit<T, keyof ICustomFieldProps<any>>;
  disabled?: boolean;
  model: FieldModel<any>;
  validators?: Array<((value: any) => string | undefined) | undefined>;
}

export const Field = observer(
  class Field<T extends ICustomFieldProps<any>> extends React.Component<IPropTypes<T>> {
    constructor(props) {
      super(props);

      const validators = this.props.validators ? this.props.validators.filter(validator => validator !== undefined) : [];

      this.props.model.connect(validators as Array<(value: any) => string | undefined>);
    }

    componentWillUnmount() {
      this.props.model.disconnect();
    }

    setBlurred = () => {
      this.props.model.changeFocused(false);
    };

    setFocused = () => {
      this.props.model.changeFocused(true);
    };

    render() {
      const {model} = this.props;

      return (
        <this.props.component
          {...this.props.componentProps}
          disabled={this.props.disabled !== undefined ? this.props.disabled : false}
          id={model.id}
          meta={{
            dirty: model.dirty,
            error: model.error,
            focused: model.value? true :model.focused,
            touched: model.touched
          }}
          onBlur={this.setBlurred}
          onChange={model.changeValue}
          onError={model.setError}
          onFocus={this.setFocused}
          value={model.value}
        />
      );
    }
  }
);
