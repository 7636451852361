import {defineMessages} from 'react-intl';

export default defineMessages({
  showAll: {
    defaultMessage: '[show all]',
    id: 'ExpandableText.ShowAll',
  },
  showLess: {
    defaultMessage: '[show less]',
    id: 'ExpandableText.ShowLess',
  },
});
