import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_footer-changing-language-overlay.scss';

export const FooterChangingLanguageOverlay: FC = () => {
  return (
    <div className="footer__changing-language-overlay">
      <div className="footer__changing-language-overlay-box">
        <FormattedMessage {...intlMessages.changingLanguageMessage} />

        <FontAwesome
          className="footer__changing-language-overlay-icon"
          name="circle-notch"
          spin
        />
      </div>
    </div>
  );
}
