import Parser from "@u4i/utils/Parser";

const playerNavigationParser = new Parser((navigation) => ({
  chapters: navigation.chapters.map((chapter) => ({
    backgroundUrl: chapter.preview.url,
    id: chapter.id,
    slug: chapter.slug,
    title: chapter.title,
    showChatbot: chapter.showChatbot,
    topics: chapter.topics.map((topic) => ({
      challenges: topic.challenges.map((challenge) => ({
        challengeId: challenge.challengeId,
        challengeName: challenge.challengeName,
        challengeStatus: challenge.challengeStatus,
      })),
      id: topic.id,
      slug: topic.slug,
      title: topic.title,
      viewed: topic.viewed,
    })),
    viewedStatus: chapter.viewedStatus,
    previousViewedStatus: chapter.previousViewedStatus,
  })),
  feedbackSurvey:
    navigation.feedbackSurvey && !Array.isArray(navigation.feedbackSurvey)
      ? {
          name: navigation.feedbackSurvey.name,
          url: navigation.feedbackSurvey.url,
          quick_url: navigation.feedbackSurvey.quick_url,
        }
      : null,
  playerDisclaimer: navigation.playerDisclaimer,
  skillId: navigation.id,
  skillLanguage: navigation.language || null,
  skillSlug: navigation.slug,
  skillTitle: navigation.title,
  hasMandatoryChapters: navigation.hasMandatoryChapters,
  hasQuickFeedback: navigation.showQuickFeedback,
  duration: navigation.duration,
  title: navigation.title,
  id: navigation.id,
}));

export default playerNavigationParser;
