import {defineMessages} from 'react-intl';

export default defineMessages({
  start: {
    defaultMessage: 'Start',
    id: 'Player.Element.MCQTest.Start',
  },
  successTitle: {
    defaultMessage: 'Congratulations!',
    id: 'Player.Element.MCQTest.SuccessTitle'
  },
  successDescription: {
    defaultMessage: 'You have successfully passed this challenge! Overall score was {percentage}%.',
    id: 'Player.Element.MCQTest.SuccessDescription'
  },
  failedTitle: {
    defaultMessage: 'Try again.',
    id: 'Player.Element.MCQTest.FailedTitle'
  },
  failedDescription: {
    defaultMessage: "Unfortunately, you didn't score enough points to pass this challenge… yet! Overall score was {percentage}%. We recommend you go through the previous chapter(s) to get the required knowledge. When you are ready, you can take this challenge again.",
    id: 'Player.Element.MCQTest.FailedDescription'
  },
  warningTitle: {
    defaultMessage: 'Thank you!',
    id: 'Player.Element.MCQTest.WarningTitle'
  },
  warningDescription: {
    defaultMessage: 'You have reached the maximum number of attempts for this test. It cannot be taken again. Please contact support@university4industry.com to be able to try again.',
    id: 'Player.Element.MCQTest.WarningDescription'
  },
  next: {
    defaultMessage: 'Next',
    id: 'Player.Element.MCQTest.Next'
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'Player.Element.MCQTest.Previous'
  },
  finishNow: {
    defaultMessage: 'Finish now',
    id: 'Player.Element.MCQTest.FinishNow'
  },
  questionNumber: {
    defaultMessage: 'Question {current} of {total}',
    id: 'Player.Element.MCQTest.QuestionNumber',
  },
});
