import React, { FC } from 'react';
import {CSSTransition} from 'react-transition-group';
import {observer} from 'mobx-react';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import {CompareMeSection, CompareMeTabIdEnum} from './CompareMeSection';
import {CurriculumProgressChart} from './components/CurriculumProgressChart';
import {RelativePositionSlider} from './components/RelativePositionSlider';
import {StatisticsCircles} from './components/StatisticsCircles';
import {Tabs} from './components/Tabs';

import './_compare-me-section.scss';

interface IPropTypes {
  sectionModel: CompareMeSection;
}

const VisualComponent: FC<IPropTypes> = (props: IPropTypes) => {
  const {sectionModel} = props;
  const {comparationData, currentTabId, statisticalCirclesData} = sectionModel;

  return (
    <BasicInfoWrapper
      isLoading={sectionModel.fetching}
      sectionModel={sectionModel}
    >
      <div className="container">
        <Tabs currentTabId={currentTabId} onTabChange={sectionModel.changeTab}>
          {(comparationData && comparationData.skills) &&
            <Tabs.Tab id={CompareMeTabIdEnum.SKILLS} title="Courses" />
          }

          {(comparationData && comparationData.challenges) &&
            <Tabs.Tab id={CompareMeTabIdEnum.CHALLENGES} title="Challenges" />
          }

          {(comparationData && comparationData.curriculum) &&
            <Tabs.Tab id={CompareMeTabIdEnum.CURRICULUM} title="Curriculum" />
          }
        </Tabs>

        <div className="landing-page__compare-me-section__content-wrapper">
          <div className="landing-page__compare-me-section__switchable-content">
            <CSSTransition
              appear
              classNames="landing-page__compare-me-section__switchable-content-transition"
              in={currentTabId === CompareMeTabIdEnum.CHALLENGES || currentTabId === CompareMeTabIdEnum.SKILLS}
              timeout={500}
            >
              <div className="landing-page__compare-me-section__switchable-content-transition">
                <StatisticsCircles
                  accentColor={statisticalCirclesData.accentColor}
                  currentUser={statisticalCirclesData.currentUser}
                  groupAverage={statisticalCirclesData.groupAverage}
                  groupTop={statisticalCirclesData.groupTop}
                  tabId={sectionModel.lastStatisticalCirclesTabId}
                />
              </div>
            </CSSTransition>

            {(comparationData && comparationData.curriculum) &&
              <CSSTransition
                appear
                classNames="landing-page__compare-me-section__switchable-content-transition"
                in={currentTabId === CompareMeTabIdEnum.CURRICULUM}
                timeout={500}
              >
                <div className="landing-page__compare-me-section__switchable-content-transition">
                  <CurriculumProgressChart
                    groupAveragePercentage={comparationData.curriculum.completionPercentage.groupAverage}
                    groupTopPercentage={comparationData.curriculum.completionPercentage.groupTop}
                    progressPercentage={comparationData.curriculum.completionPercentage.currentUser}
                  />
                </div>
              </CSSTransition>
            }
          </div>

          <RelativePositionSlider
            accentColor={sectionModel.relativePositionSliderData.accentColor}
            relativePositionPercentage={sectionModel.relativePositionSliderData.percentage}
          />
        </div>
      </div>
    </BasicInfoWrapper>
  );
};

const WrappedComponent = observer(VisualComponent);

export {WrappedComponent as VisualComponent};
