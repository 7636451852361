import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import { AlertStatusEnum } from '@u4i/common/enums/AlertStatusEnum';
import { Alert } from '@u4i/common/Alert';

ErrorAlert.propTypes = {
  code: PropTypes.number.isRequired,
};

function getErrorMessage(code) {
  switch (code) {
    case 401:
      return <FormattedMessage {...intlMessages.message401} />;

    case 403:
      return <FormattedMessage {...intlMessages.message403} values={{mail: <a href="mailto:admin@university4industry.com">admin@university4industry.com</a>}} />;

    case 404:
      return <FormattedMessage {...intlMessages.message404} />;

    default:
      return 'Error';
  }
}

function ErrorAlert(props) {
  return (
    <Alert
      heading={<FormattedMessage {...intlMessages.heading} />}
      message={getErrorMessage(props.code)}
      type={AlertStatusEnum.ERROR}
    />
  );
}

export default ErrorAlert;
