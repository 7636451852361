import {defineMessages} from 'react-intl';

export default defineMessages({
  contentNotViewed: {
    defaultMessage: 'Content Not Viewed',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.ChapterInfo.ViewedLabel.ContentNotViewed',
  },
  contentViewed: {
    defaultMessage: 'Content Viewed',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.ChapterInfo.ViewedLabel.ContentViewed',
  },
});
