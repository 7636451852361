import React, { useEffect, FC } from "react";
import { RouteComponentProps } from "@reach/router";
import { PathOfPhases } from "@u4i/modules/ProgramsLearnerView/interfaces/ProgramLearnerViewInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import { inject, observer } from "mobx-react";
import { mainBlue } from "@u4i/styles/styles-variables";
import { RightArrow } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/RightArrow";
import { LabelSvg } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/LabelSvg";
import { LeftArrow } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/LeftArrow";
import { ModuleInfoLabel } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/ModuleInfoLabel";
import { ToggleOff } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/ToggleOff";
import { ToggleOn } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/ToggleOn";
import { PathLine } from "@u4i/modules/ProgramsLearnerView/components/GeneralTextLabelElementsSVG/PathLine";
import { Phases } from "@u4i/modules/ProgramsLearnerView/components/PhaseModulesSVG/Phases";
import { FormattedMessage } from "react-intl";
import keycode from "keycode";
import intlMessages from "./intlMessages";
import { PhaseModules } from "@u4i/modules/ProgramsLearnerView/components/PhaseModulesSVG/PhaseModules";
import './_program_learners_view_main.scss';

interface IPropTypes extends RouteComponentProps {
  rootStore?: IRootStore
  programId: string
}

export const ProgramLearnersViewMain: FC<IPropTypes> = inject("rootStore")(
  observer((props: IPropTypes) => {
    const { programsLearnerViewStore } = props.rootStore!;
    const {
      allPhases,
      allModules,
      expandedPhase,
      isBuiltProgramUI,
      isModuleInfoLabelVisible,
      lineLength,
      phasesPaths,
      phaseModuleCircleRadius,
      programById,
      moduleInfoLabelProps,
      showNextButton,
      showPreviousButton,
      svgContainerWidth,
      svgContainerHeight,
      toggleContent,
      toggleDetails,
      XofStartIcon,
      XofEndIcon,
      yAxis,
      enrollUserToCourse,
      getProgramById,
      hideModalDetails,
      nextPhaseHandle,
      onModuleHover,
      previousPhaseHandle,
      showModules,
      toggleAllContent,
      toggleModuleDetails,
      updateProgramClpUrl
    } = programsLearnerViewStore;

    useEffect(() => {
      getProgramById(props.programId);
    }, []);

    React.useEffect(() => {
      window.addEventListener('keydown', (event) => {
        const key = keycode(event);
        switch(key) {
          case 'left':
            previousPhaseHandle();
            break;
          case 'right':
            nextPhaseHandle();
            break;
          default: 
            break;
        }
      });
    }, []);

    return (
      <div className="main-container">
        { programById && isBuiltProgramUI && (
          <svg
            width={svgContainerWidth}
            height={svgContainerHeight}
            viewBox={`0 0 ${svgContainerWidth} ${svgContainerHeight}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            { phasesPaths.map((path: PathOfPhases) =>
              <PathLine
                direction='h'
                key={path.id}
                length={lineLength}
                startX={path.XofPath}
                startY={yAxis}
                strokeColor={mainBlue}
              />
            )};

            { allModules && allPhases.map((pha) =>
              <Phases
                cy={yAxis}
                key={pha.phaseId}
                onPhaseIconClick={() => showModules(pha.phaseId)}
                phase={pha}
                r={phaseModuleCircleRadius}
              />
            )}

            { allPhases.map((pha) => ( pha.visible === true ?
                <PhaseModules
                  enrollUserToCourse={enrollUserToCourse}
                  expandedPhase={pha}
                  hideModalDetails={hideModalDetails}
                  key={pha.phaseId}
                  onModuleHover={(cx, cy, title, hoverIn) => onModuleHover(cx, cy, title, hoverIn)}
                  phasesCount={allPhases.length}
                  programById={programById}
                  toggleContent={toggleContent}
                  toggleDetails={toggleDetails}
                  toggleModuleDetails={toggleModuleDetails}
                  updateProgramClpUrl={updateProgramClpUrl}
                  xOfStart={XofStartIcon}
                  yAxis={yAxis}
                />
                :
                <></>
              ))
            }

            { toggleContent && (
              <>
                <ToggleOn
                  onToggleClick={toggleAllContent}
                  x={XofStartIcon + 33}
                  y={yAxis - 10}
                />
                <LabelSvg
                  text={<FormattedMessage {...intlMessages.allContent}/>}
                  x={XofStartIcon + 3}
                  y={yAxis + 30}
                />
              </>
            )}

            { !toggleContent && (
              <>
                <ToggleOff
                  onToggleClick={toggleAllContent}
                  x={XofStartIcon + 33}
                  y={yAxis - 10}
                />
                <LabelSvg
                  text={<FormattedMessage {...intlMessages.allContent}/>}
                  x={XofStartIcon + 3}
                  y={yAxis + 30}
                />
              </>
            )}

            {expandedPhase && showPreviousButton &&
              <LeftArrow
                onPreviousPhaseClick={previousPhaseHandle}
                x={XofStartIcon + 130}
                y={yAxis - 24}
              />
            }

            {expandedPhase && showNextButton &&
              <RightArrow
                onNextPhaseClick={nextPhaseHandle}
                x={XofEndIcon - 80}
                y={yAxis - 24}
              />
            }

            { !toggleDetails && isModuleInfoLabelVisible && moduleInfoLabelProps != null &&
              <ModuleInfoLabel 
                isHovering={true}
                title={moduleInfoLabelProps?.title} 
                x={moduleInfoLabelProps?.x} 
                y={moduleInfoLabelProps?.y}
              ></ModuleInfoLabel>
            }
          </svg>
        )}
      </div>
    )
  }
))
