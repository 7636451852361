import is from 'is_js';
import qs from 'qs';
import {createBrowserHistory} from 'history';
import Action from './Action';
import Location from './Location';
import Route from './Route';
import { green, white } from '@u4i/styles/styles-variables';

const HISTORY_RECORD_LENGTH = 20;
const HISTORY_RECORD_STORAGE_KEY = 'HISTORY_RECORD';

class AppNavigator {

  history = createBrowserHistory();
  // @ts-ignore
  historyRecord = JSON.parse(sessionStorage.getItem(HISTORY_RECORD_STORAGE_KEY)) || [];

  constructor() {
    // @ts-ignore
    if (__DEV__) {
      console.info(
        `%c[AppNavigator]%c >> deserialized history record with length: ${this.historyRecord.length}`,
        `background: ${green}; border-radius: 2px; color: ${white}; padding: 2px 4px;`,
        null,
      );
    }
    // @ts-ignore
    this.recordHistory(new Location(this.history.location), new Action('PUSH'));
    this.listen(this.recordHistory);
  }

  get allPreviousLocations() {
    if (this.historyRecord.length < 2) {
      return [];
    }

    return this.historyRecord.slice(1).map(entry => entry.location);
  }

  get currentLocation() {
    return this.history.location;
  }

  get length() {
    return this.history.length;
  }

  moveBySteps(steps) {
    this.history.go(steps);
  }

  get previousLocation() {
    if (this.historyRecord.length < 2) {
      return null;
    }

    return this.historyRecord[1].location;
  }

  directPush(targetPathname) {
    this.history.push(targetPathname);
  }

  directReplace(targetPathname) {
    this.history.replace(targetPathname);
  }

  goBack = () => {
    this.history.goBack();
  };

  goForward(steps = 1) {
    // @ts-ignore
    this.history.goForward(steps);
  }

  listen(callback) {
    const listenerUnmountFunction = this.history.listen((newLocation:any, usedAction) => {
      const location = new Location({
        hash: newLocation.hash,
        pathname: newLocation.pathname,
        search: newLocation.search,
      });

      const action = new Action(usedAction);

      callback(location, action);
    });

    return listenerUnmountFunction;
  }

  push(targetRoute, params = {}, state = null, queryString = null) {
    if (!(targetRoute instanceof Route)) {
      throw new Error('Navigation target must be a Route');
    }

    if (!is.null(state) && (!is.object(state) || is.function(state))) {
      throw new Error('If new router state is present it must be an object');
    }

    if (!is.null(queryString) && (!is.object(queryString) || is.function(queryString))) {
      throw new Error('If query string is present it must be an object');
    }

    this.history.push({
      pathname: targetRoute.parse(params || {}),
      // @ts-ignore
      search: queryString ? qs.stringify(queryString) : null,
      state
    });
  }

  recordHistory = (location, action) => {
    this.historyRecord.unshift({action, location});

    if (this.historyRecord.length > HISTORY_RECORD_LENGTH) {
      this.historyRecord.pop();
    }

    this.serializeHistoryRecord();
// @ts-ignore
    if (__DEV__) {
      console.info(
        `%c[AppNavigator]%c >> navigated to ${location.pathname} {${action.type}}`,
        `background: ${green}; border-radius: 2px; color: ${white}; padding: 2px 4px;`,
        null,
      );

      console.info(
        `%c[AppNavigator]%c >> history record length: ${this.historyRecord.length}`,
        `background: ${green}; border-radius: 2px; color: ${white}; padding: 2px 4px;`,
        null,
      );
    }
  };

  replace(targetRoute, params = null, state = null, queryString: any = null) {
    if (!(targetRoute instanceof Route)) {
      throw new Error('Navigation target must be a Route');
    }

    if (!is.null(state) && (!is.object(state) || is.function(state))) {
      throw new Error('If new router state is present it must be an object');
    }

    if (!is.null(queryString) && (!is.object(queryString) || is.function(queryString))) {
      throw new Error('If query string is present it must be an object');
    }

    this.history.replace({
      pathname: targetRoute.parse(params || {}),
      // @ts-ignore
      search: queryString ? qs.stringify(queryString) : null,
      state
    });
  }

  serializeHistoryRecord() {
    sessionStorage.setItem(HISTORY_RECORD_STORAGE_KEY, JSON.stringify(this.historyRecord));
  }
}

const singleton = new AppNavigator();

export default singleton;
