import {defineMessages} from 'react-intl';

export default defineMessages({
  changeEmail: {
    defaultMessage: 'change your e-mail address',
    id: 'Modals.RegistrationSuccess.ChangeEmail',
  },
  hint: {
    defaultMessage: 'Don\'t see the e-mail? Check your spam,',
    id: 'Modals.RegistrationSuccess.Hint',
  },
  modalHeading: {
    defaultMessage: 'Please check your e-mail',
    id: 'Modals.RegistrationSuccess.ModalHeading',
  },
  or: {
    defaultMessage: 'or',
    id: 'Modals.RegistrationSuccess.Or',
  },
  resendEmail: {
    defaultMessage: 'resend the e-mail',
    id: 'Modals.RegistrationSuccess.ResendEmail',
  },
  text: {
    defaultMessage: 'Thank you for registering an account at University4Industry! {br} {br}'
      + ' We sent an approval e-mail to {email}. {br} {br} To continue please click'
      + ' on the link in your approval e-mail.',
    id: 'Modals.RegistrationSuccess.Text',
  },
});
