import {defineMessages} from 'react-intl';

export default defineMessages({
  goBack: {
    defaultMessage: 'Go back',
    id: 'Modals.PasswordRecovery.GoBack',
  },
  modalTitle: {
    defaultMessage: 'Password recovery',
    id: 'Modals.PasswordRecovery.ModalTitle',
  },
});
