import { frenchGray, white } from '@u4i/styles/styles-variables';
import React, { FC } from 'react';

interface IProps {
  x: number
  y: number
  onToggleClick: () => void
}

export const ToggleOff: FC<IProps> = (props: IProps) => {
  const {
    x,
    y,
    onToggleClick
  } = props;

  const pathD: string = `m ${x},${y} h 19.464236 c 9.414075,0.344633 11.339403,15.88072 0,17.566507 h -19.464236 c -6.888317,-1.321777 -7.087891,-17.691944 0,-17.566507 z`;

  return (
    <svg>
      <g
        fillOpacity={1}
        onClick={onToggleClick}
        strokeOpacity={1}
        style={{ cursor: "pointer" }}
      >
        <path
          fill={frenchGray}
          strokeWidth={0.4}
          d={pathD}
        />
        <circle
          cx={x+1}
          cy={y+8.5}
          r={8.55}
          fill={white}
          stroke={frenchGray}
          strokeWidth={0.5}
        />
      </g>
    </svg>
  )
}
