// TODO: refactor this inside the user store once its fully migrated from typescript
// it should be in user store because that will be the entity responsible for registration of users
// one of two options
// one: listen to navigation changes from user store and prepare path (probably worse, because page url might change, but app routes can be used)
// two: send information about visits from landing page store to user store

class VisitTracker {
  private visitedPages: Array<string> = [];

  addVisit(pageName: string) {
    const lastElement = this.visitedPages[this.visitedPages.length - 1];

    if (pageName !== lastElement) {
      this.visitedPages.push(pageName);
    }
  }

  getVisitList(): string {
    return this.visitedPages.join(';');
  }

  reset(): void {
    this.visitedPages = [];
  }
}

const instance = new VisitTracker();

export {instance as VisitTracker};

(window as any).VisitTracker = instance;
