import Parser from '@u4i/utils/Parser';

export interface IOnboardingProgress {
  player: boolean;
  showOnboarding: boolean;
  skillDetail: boolean;
}

export const OnboardingProgressParser = new Parser<IOnboardingProgress>(onboardingProgress => ({
  player: onboardingProgress.player,
  showOnboarding: typeof onboardingProgress.showOnBoarding === 'boolean' ? onboardingProgress.showOnBoarding : true,
  skillDetail: onboardingProgress.skillDetail,
}));
