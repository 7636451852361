import Parser from '@u4i/utils/Parser';

export interface IJupyterLab {
  chalengeAttemptId: string;
  id: string;
  title: string;
}

export const JupyterLabParser = new Parser<IJupyterLab>(jupyterLab => ({
  chalengeAttemptId: jupyterLab.challenge_attempt_id,
  id: jupyterLab.id,
  title: jupyterLab.title,
}));
