import {defineMessages} from 'react-intl';

export default defineMessages({
  chooseFiles: {
    defaultMessage: 'Choose files...',
    id: 'Exercises.FormsSystem.Fields.FileField.ChooseFiles',
  },
  fileError: {
    defaultMessage: 'Invalid file uploaded - maximum file size exceeded or the file type is not allowed',
    id: 'Exercises.FormsSystem.Fields.FileField.FileErrorTooBig',
  },
  filesSelected: {
    defaultMessage: '{count} files selected',
    id: 'Exercises.FormsSystem.Fields.FileField.FilesSelected',
  },
  maxSizeLabel: {
    defaultMessage: ' (max. {readableSize})',
    id: 'Exercises.FormsSystem.Fields.FileField.MaxSize',
  },
  noFilesSelected: {
    defaultMessage: 'No files selected',
    id: 'Exercises.FormsSystem.Fields.FileField.NoFilesSelected',
  },
});
