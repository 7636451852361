import React, { FC } from 'react';

interface IPropTypes {
  children: React.ReactElement;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const EntryButton: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <button className="main-layout__entry__button" onClick={props.onClick}>
      {props.children}
    </button>
  );
}

export default EntryButton;
