import {defineMessages} from 'react-intl';

export default defineMessages({
  buttonLabel: {
    defaultMessage: 'Resume watching',
    id: 'CourseDetailPage.RecentChapter.ButtonLabel',
  },
  chapterRetake: {
    defaultMessage: 'Retake',
    id: 'CourseDetailPage.RecentChapter.ChapterRetake',
  },
  heading: {
    defaultMessage: 'Recently viewed chapter',
    id: 'CourseDetailPage.RecentChapter.Heading',
  },
});
