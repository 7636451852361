import React from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_table-of-contents-list-heading.scss';

interface IPropTypes {
  page: number;
  pagesTotal: number;
}

export const TableOfContentsListHeading: React.FC<IPropTypes> = (props) => (
  <h4 className="player__table-of-contents-list-heading">
    <FormattedMessage {...intlMessages.text}/>

    {props.pagesTotal > 1 &&
      <span className="player__table-of-contents-list-heading__pagination">
        {props.page}/{props.pagesTotal}
      </span>
    }
  </h4>
);
