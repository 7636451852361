import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from 'antd';

import intlMessages from "./intlMessages";
import "./_lab-booking-button.scss";

interface IProps {
  btnClassName?: string;
  courseId: string;
}

export const LabBookingButton: FC<IProps> = (props: IProps) => {
  const {
    btnClassName,
    courseId
  } = props;

  return (
    <div className={!btnClassName ? 'lab-booking-btn' : `lab-booking-btn__${btnClassName}`}>
      <Button
        className="lab-booking-btn__booking-button"
        href={`/booking-dl/${courseId}`}
        type="primary"
      >
        <span className="disable-button-invert">
        <FormattedMessage {...intlMessages.btnName} />
        </span>
      </Button>
    </div>
  )  
}
