import is from 'is_js';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

class TitleBackground extends Component<any> {
  static propTypes = {
    backgroundImage: PropTypes.string,
  };

  static defaultProps = {
    backgroundImage: null,
  };

  isEdge = is.edge();

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="100%" preserveAspectRatio="xMidYMax slice" viewBox="0 0 1920 1080" width="100%">
        {this.isEdge ?
          <image
            height="1080"
            preserveAspectRatio="xMidYMid slice"
            width="1920"
            x="0"
            xlinkHref={this.props.backgroundImage}
            y="0"
          />
          :
          <React.Fragment>
            <defs>
              <rect height="100%" id="blurRect" width="100%" x="0" y="0" />
            </defs>

            <image
              height="1080"
              preserveAspectRatio="xMidYMid slice"
              width="1920"
              x="0"
              xlinkHref={this.props.backgroundImage}
              y="0"
            />
          </React.Fragment>
        }
      </svg>
    );
  }
}

export default TitleBackground;
