
export enum XAPIVerbs {
  played = 'played',    // Triggered when user plays a video of 3Q in VE
  paused = 'paused',    // triggered when user pauses or leaves a video of 3Q in VE
  watched = 'watched',    // triggered when user finishes a video of 3Q in VE
  completed = 'completed',
  experienced = 'experienced',
  read = 'read',   // Triggered when an element is viewed in VE
  launched = 'launched',   // triggered when a digital lab is started
  submitted = 'submitted',   // triggered when a digital lab is submitted
  opened = 'opened',    // Triggered when user opens the chatbot Karl
  closed = 'closed',    // Triggered when user closes the chatbot Karl
  asked = 'asked',    // Triggered when user sends a message to the chatbot Karl
  liked = 'liked',    // Triggered when user likes a response from chatbot Karl
  disliked = 'disliked',    // Triggered when user dislikes a response from chatbot Karl
}