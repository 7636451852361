import qs from 'qs';
import {AxiosInstance} from 'axios';
import {AdminRedirectUrlParser, IAdminRedirectUrl} from '@u4i/parsers/admin/AdminRedirectUrlParser';
import {AdminUsersParser, IAdminUser, IChangeRequest, NewUserData} from '@u4i/parsers/admin/AdminUsersParser';
import {IPaginatedItems} from '../../../../ServicesInterfaces';
import { UserData } from '@u4i/modules/Admin/common/Interfaces/UserGroups.interfaces';

export class AdminUsersApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async becomeUser(id: string): Promise<IAdminRedirectUrl> {
    const response = await this.apiClient.post(`/admin/users/${id}/become-user`);
    return AdminRedirectUrlParser.parse(response.data);
  }

  async deleteUser(id: string, activeChangeRequest: Array<IChangeRequest>): Promise<void> {
    const userData = {
      data: {
        changeRequests: activeChangeRequest,
      }
    }

    await this.apiClient.delete(`/admin/users/${id}`, userData);
  }

  async fetchUserData(id: string) : Promise<IAdminUser> {
    const response = await this.apiClient.get(`/admin/users/${id}`);
    return AdminUsersParser.parse(response.data);
  }

  async fetchUsersData(config: {
    filters: { [columnName in keyof IAdminUser]? : any },
    limit?: number,
    offset: number,
    sortDirection: 'ASC',
    sortedColumnId: string | number | symbol | undefined
  }): Promise<IPaginatedItems<IAdminUser>> {
    const response = await this.apiClient.get('/admin/users', {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.sortedColumnId ? {
          [config.sortedColumnId]: config.sortDirection,
        }: undefined
      },
      paramsSerializer: params => qs.stringify(params),
    });

    const items = AdminUsersParser.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items, totalItems};
  }

  async fetchUsersCount(): Promise<number> {
    const response = await this.apiClient.get(`/admin/users/count`);

    return response.data;
  }

  async resetUserPassword(id: string): Promise<void> {
    return await this.apiClient.post(`/admin/users/${id}/password-reset`);
  }

  async fetchResetUserPasswordUrl(id: string): Promise<string> {
    const response = await this.apiClient.get(`/admin/users/${id}/password-reset-url`);

    return response.data.passwordUrl;
  }

  async submitNewUser(newUserData: NewUserData): Promise<any> {
    const response = await this.apiClient.post('admin/users', newUserData);

    return response.data;
  }

  async updateUser(userData: UserData): Promise<IAdminUser> {
    const response = await this.apiClient.put(`/admin/users/${userData.id}`, userData);

    return response.data;
  }
}
