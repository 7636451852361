import PropTypes from 'prop-types';
import React from 'react';

import './_image-element.scss';

ImageElement.propTypes = {
  url: PropTypes.string.isRequired,
};

function ImageElement(props) {
  return <img alt="" className="player__image-element" src={props.url} />;
}

export default ImageElement;
