import {defineMessages} from 'react-intl';

export default defineMessages({
  average: {
    defaultMessage: 'Group average',
    id: 'LandingPage.CompareMeSection.StatisticsCircles.Average',
  },
  challengesSuffix: {
    defaultMessage: '{count, plural, one {challenge} other {challenges}} passed',
    id: 'LandingPage.CompareMeSection.StatisticsCircles.ChallengesSuffix',
  },
  skillsSuffix: {
    defaultMessage: '{count, plural, one {course} other {courses}} completed',
    id: 'LandingPage.CompareMeSection.StatisticsCircles.SkillsSuffix',
  },
  top: {
    defaultMessage: 'Group TOP user',
    id: 'LandingPage.CompareMeSection.StatisticsCircles.Top',
  },
});
