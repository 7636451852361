import { Row, Col } from "antd";
import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { green } from "@u4i/styles/styles-variables";

const ChapterThumbnail = ({
  challengesCompleted,
  isSkillBought,
  hasChallenges,
  viewed,
  url
}: any) => {
  const areChallengesCompleted: boolean = challengesCompleted && isSkillBought;
  const skillViewed: boolean = !hasChallenges && viewed && isSkillBought;

  return (
    <div
      className="chapter-card-header disable-invert"
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      {viewed && (
        <div className="card-header-compleated">
          <Row justify="center" align="middle">
            <Col md={24} className="text-center mb-8">
              <CheckCircleFilled style={{ color: green, fontSize: 36 }} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ChapterThumbnail;
