import {defineMessages} from 'react-intl';

export default defineMessages({
  commentsTooltipMessage: {
    defaultMessage: 'Show comments',
    id: 'DiscussPage.AnswersSection.UserAnswer.AnswerStatistics.CommentsTooltipMessage',
  },
  likesTooltipMessage: {
    defaultMessage: 'Show likes',
    id: 'DiscussPage.AnswersSection.UserAnswer.AnswerStatistics.LikesTooltipMessage',
  },
});
