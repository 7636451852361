import ChapterCard from "@u4i/common/Cards/ChapterCard";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IChapterData } from "../../interfaces/ChapterInterface";
import intlMessages from "./intlMessages";
import "./_recent-chapter.scss";

interface IProps {
  chapterData: IChapterData;
  onChapterStart: (chapterData: IChapterData) => void;
}

export const RecentChapter: FC<IProps> = (props: IProps) => {
  const { chapterData, onChapterStart } = props;

  const handleStartClick = () => {
    onChapterStart(chapterData);
  };

  return (
    <div className="container">
      <div className="course-detail-page__recent-chapter">
        <h3 className="course-detail-page__recent-chapter__heading">
          <FormattedMessage {...intlMessages.heading} />
        </h3>
        <div
          style={{
            marginLeft: 65,
            marginRight:15,
            transform: "translateX(30px)",
          }}
    
        >
          <ChapterCard
            data={chapterData}
            onStartClick={handleStartClick}
            isSkillBought
            showButtonResume
            className="disable-invert"
          />
        </div>
      </div>
    </div>
  );
};
