import React from "react";
import { PlayCircleFilled } from "@ant-design/icons";
import { Col, Progress, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import intlMessages from "./intlMessages";
import { SkillProgressEnum } from "@u4i/common/enums/SkillProgressEnum";
import FlagIcon from "@u4i/common/Cards/CommonCard/FlagIcon";
import { green, mainBlue, white } from "@u4i/styles/styles-variables";
import { ICurriculumItem } from "@u4i/common/Interfaces/CurriculumInterface";
import "./_mini-skill-card.scss";

const TRIM_LIMIT = 30;

const iconStyle = {
  fontSize: 25,
  color: white
};

const { Text } = Typography;

interface IPropTypes {
  skill: ICurriculumItem
  onClick: (skill: ICurriculumItem) => void
}

export class MiniSkillCard extends React.Component<IPropTypes> {
  get trimmedTitle() {
    const { title } = this.props.skill;

    if (title.length <= TRIM_LIMIT) {
      return title;
    }
    
    const trimmedString: string = title.substring(0, TRIM_LIMIT).trim();

    if (trimmedString.substring(-1) === ".") {
      return `${trimmedString}..`;
    }

    return `${trimmedString}...`;
  }

  handleClick = () => {
    this.props.onClick(this.props.skill);
  };

  render() {
    const { skill } = this.props;

    return (
      <button
        className="landing-page__mini-skill-card"
        onClick={this.handleClick}
        type="button"
      >
        <div
          style={{backgroundImage: `url(${skill.image})`}}
          className="common-card-header-curriculum "
        >
          <FlagIcon language={skill.language} className="custom-flag-curriculum"/>

          <div
            className="card-header-hover-curriculum"
            style={{ backgroundColor: skill.skillProgressStatus == SkillProgressEnum.COMPLETED ? green : mainBlue }}
          >
            <Row justify="center" align="middle" className="pt-8">
              <Col xs={24} className="text-center">
                <PlayCircleFilled style={iconStyle} />
              </Col>
              <Col md={24} className="text-center">
                <Text style={{ color: white }}>
                  {skill.skillProgressStatus == SkillProgressEnum.NOT_STARTED && (
                    <FormattedMessage {...intlMessages.start} />
                  )}
                  {skill.skillProgressStatus == SkillProgressEnum.IN_PROGRESS && (
                    <FormattedMessage {...intlMessages.buttonLabel} />
                  )}
                  {skill.skillProgressStatus == SkillProgressEnum.COMPLETED && (
                    <FormattedMessage {...intlMessages.retake} />
                  )}
                </Text>
              </Col>
            </Row>
          </div>
        </div>
        <Text className="pl-8 pr-8 pt-8 invert-section">
          {this.trimmedTitle}
        </Text>
        <div
          style={{
            padding: "0px 10px 10px 10px",
          }}
          className="invert-section"
        >
          <Row>
            <Progress
              percent={skill.skillProgressPercentage * 100}
              showInfo={false}
            />
          </Row>
          <div className="mb-8">
            <Text type="secondary">
              <FormattedMessage {...intlMessages.completed} />:{" "}
            </Text>
            <strong>{Math.round(skill.skillProgressPercentage * 100)}%</strong>
          </div>
        </div>
      </button>
    );
  }
}
