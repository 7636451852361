import cx from 'classnames';
import React, { FC } from 'react';
import './_recalculation-overlay.scss';
import { Skeleton } from 'antd';

interface IPropTypes {
  isVisible: boolean
}

export const RecalculationOverlay: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <div
      className={cx({
        'landing-page__recalculation-overlay': true,
        'landing-page__recalculation-overlay--visible': props.isVisible,
      })}
    >
      <Skeleton active style={{
        marginTop:65
      }} />
    </div>
  );
};
