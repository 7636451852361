import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import intlMessages from "./intlMessages";
import { Empty } from "antd";

export const NoItemsMessage: FC = () => (
  <div className="disable-invert">
    <Empty description={<FormattedMessage {...intlMessages.message} />} />
  </div>
);
