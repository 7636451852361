/**
 * @desc This component will holds the settings for the responsive Carousel
 * @author Pradeep Gill pradeep.gill@u4i.io
 */

import React from "react";
import arrowLeftIcon from '@u4i/assets/svg/arrow-left.svg'
import arrowRightIcon from '@u4i/assets/svg/arrow-right.svg'
import {ArrowRightOutlined,ArrowLeftOutlined} from "@ant-design/icons"

/**
  * @desc Custom component for next arrow the slider
  * 
  * @param props - slider props from the plugin
*/
function SliderNextArrow(props) {
  const { currentSlide, onClick } = props;
  return (
    <button disabled={currentSlide === 0} onClick={onClick} className="landing-page__horizontal-slider__leftArrow mr-8">
      <ArrowLeftOutlined />
    </button>
  );
}

/**
  * @desc Custom component for prev arrow the slider
  * 
  * @param props - slider props from the plugin
*/
function SliderPrevArrow(props) {
  const { slideCount, currentSlide, onClick } = props;
  return (
    <button disabled={(slideCount - currentSlide) <= 3} onClick={onClick} className="landing-page__horizontal-slider__rightArrow">
      <ArrowRightOutlined />
    </button>
  );
}

// Slider component settings
export const SliderSettings = {
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  draggable: false,
  nextArrow: <SliderPrevArrow />,
  prevArrow: <SliderNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
