import React from 'react';
import {action, observable,makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import {BreakpointsEnum} from '@u4i/common/enums/BreakpointsEnum';

function getActiveBreakpointName(): BreakpointsEnum {
  const property = window.getComputedStyle(document.body, '::after').getPropertyValue('content');

  return property.replace(/"/g, '') as BreakpointsEnum;
}

interface IInjectedPropTypes {
  currentBreakpoint: BreakpointsEnum;
}

export const injectBreakpoint = <T extends React.ComponentType>(
  WrappedComponent: T,
): T  => {
  const InjectedBreakpoint = observer(class InjectedBreakpoint extends React.Component<IInjectedPropTypes> {
    currentBreakpoint: BreakpointsEnum = getActiveBreakpointName();

    constructor(props: IInjectedPropTypes) {
      super(props);

      makeObservable(this, {
        currentBreakpoint: observable,
        checkBreakpointChange: action.bound
      });
    }

    componentDidMount() {
      window.addEventListener('resize', this.checkBreakpointChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.checkBreakpointChange);
    }

    checkBreakpointChange() {
      const newBreakpoint = getActiveBreakpointName();

      if (newBreakpoint !== this.currentBreakpoint) {
        this.currentBreakpoint = newBreakpoint;
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props as any} currentBreakpoint={this.currentBreakpoint} />
      );
    }
  });

  return InjectedBreakpoint as any as T;
};
