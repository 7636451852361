import React, { FC } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {inject} from 'mobx-react';
import {EXTERNAL_BASE_URL} from '@u4i/constantSettings';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import intlMessages from './intlMessages';
import { Checkbox, Form } from 'antd';
import { LanguageStore } from '@u4i/state/LanguageStore';
import './_terms-section.scss';

interface IPropTypes extends WrappedComponentProps {
  checked: boolean
  form: any
  isLoginForm?: boolean
  languageStore: LanguageStore
  onCheckboxChange: (checked: boolean) => void
}

const TermsSection: FC<IPropTypes> = (props) => {
  const {formatMessage} = props.intl;
  const {Item} = Form;
  const isEnglish: boolean = props.languageStore.currentLocale === LanguageEnum.EN_US;

  const termsPresenceError: string = formatMessage(intlMessages.errorsPresence);

  const termsLabel = (
    <span>
      <FormattedMessage {...intlMessages.label} /> &nbsp;

      <a
        className="payment-form__terms-section__link"
        href={isEnglish ? `${EXTERNAL_BASE_URL}/en/privacy-policy` : `${EXTERNAL_BASE_URL}/de/datenschutz`}
      >
        <FormattedMessage {...intlMessages.privacyPolicyLink} />
      </a> &nbsp;

      <FormattedMessage {...intlMessages.and} /> &nbsp;

      <a
        className="payment-form__terms-section__link"
        href={isEnglish ? `${EXTERNAL_BASE_URL}/en/disclaimer` : `${EXTERNAL_BASE_URL}/de/haftungsausschluss`}
      >
        <FormattedMessage {...intlMessages.disclaimerLink} />
      </a>
    </span>
  );

  return (
    <div className="payment-form__terms-section">
      <Item
        className="disable-invert"
        name="terms"
        valuePropName="checked"
        style={{marginLeft: props.isLoginForm ? 0 : 120, marginTop: 40}}
        rules={[
          {
            validator: async (_, value) => {
              if(!value || value == false) {
                return Promise.reject(termsPresenceError)
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Checkbox
          autoFocus
          checked={props.checked}
          onChange={(ev) => props.onCheckboxChange(ev.target.checked)}
          style={{marginRight: 10 }}
        >
          {termsLabel}
        </Checkbox>

      </Item>
    </div>
  );
}

const WrappedComponent = inject('rootStore')(injectIntl(TermsSection));

export {WrappedComponent as TermsSection}
