import Parser from '@u4i/utils/Parser';

export interface ITrailer {
  id: string;
  videoId: string;
}

export const TrailerParser = new Parser<ITrailer>(trailer => ({
  id: btoa(trailer.url),
  videoId: trailer.url.split('watch?v=')[1],
}));
