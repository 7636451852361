import {action, observable, reaction, runInAction,makeObservable} from 'mobx';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import {IApi, ILogger} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import DiscussPageStore, { DiscussAnswerPost } from './DiscussPageStore';
import { IDiscussAnswer } from '@u4i/parsers/DiscussAnswerParser';

export class UserAnswersStore {
  private apiService: IApi;
  private challengeSlug: string;
  private logger: ILogger;
  private rootStore: IRootStore;
  discussPageStore: DiscussPageStore;
  fetching: boolean = true;
  items: Array<DiscussAnswerEntity> = [];
  public submitSucceeded: boolean = false;
  public submitting: boolean = false;

  constructor(rootStore: IRootStore, challengeSlug: string) {
    makeObservable(this, {
      discussPageStore: observable,
      fetching: observable,
      items: observable,
      submitSucceeded: observable,
      submitting: observable,
      closeSuccessMessage: action.bound,
      loadAnswers: action.bound,
      submitAnswer: action.bound
    });

    const {apiService, loggerFactory} = rootStore;

    this.apiService = apiService;
    this.challengeSlug = challengeSlug;
    this.logger = loggerFactory.createLogger('UserAnswersStore');
    this.rootStore = rootStore;
    const {discussPageStore} = this.rootStore;
    this.discussPageStore = discussPageStore;

    this.loadAnswers();

    reaction(() => this.discussPageStore.updatedAnswerId, () => {
      const updatedUserAnswer: DiscussAnswerEntity[] = this.items.filter((item: DiscussAnswerEntity) => item.id === this.discussPageStore.updatedAnswerId);
  
      if (updatedUserAnswer.length !== 0) {
        this.loadAnswers();
      }
      
      this.discussPageStore.setAnswerNotUpdated();
    });
  }

  loadAnswers = async () => {
    this.fetching = true;

    const answers: IDiscussAnswer[] = await this.apiService.discuss.fetchUserAnswers(this.challengeSlug);
    const answersEntities: DiscussAnswerEntity[] = answers.map(data => new DiscussAnswerEntity(data, this.rootStore));

    runInAction(() => {
      this.items = answersEntities;
      this.fetching = false;
    });
  }

  submitAnswer = async (values: DiscussAnswerPost) => {
    this.submitting = true;
    try {
      const answer: IDiscussAnswer = await this.apiService.discuss.submitAnswer(this.challengeSlug, values);
      const answerEntity: DiscussAnswerEntity = new DiscussAnswerEntity(answer, this.rootStore);

      runInAction(() => {
        this.items.unshift(answerEntity);
        this.submitSucceeded = true;
      });

      this.logger.info('answer submitted');
    } catch (error) {
      throw error;
    }
    finally {
      this.submitting = false;
    }
  }

  closeSuccessMessage = () => {
    this.submitSucceeded = false;
  }
}
