import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {ChallengeAttemptStatusEnum} from '@u4i/common/enums/ChallengeAttemptStatusEnum';
import intlMessages from './intlMessages';
import './_attempt-status-label.scss';

interface IPropTypes {
  inline?: boolean;
  status: ChallengeAttemptStatusEnum;
}

const labelMap: Map<ChallengeAttemptStatusEnum, React.ReactNode> = new Map([
  [ChallengeAttemptStatusEnum.ARCHIVED, <FormattedMessage {...intlMessages.archived} />],
  [ChallengeAttemptStatusEnum.FAILED, <FormattedMessage {...intlMessages.failed} />],
  [ChallengeAttemptStatusEnum.PASSED, <FormattedMessage {...intlMessages.passed} />],
  [ChallengeAttemptStatusEnum.PENDING, <FormattedMessage {...intlMessages.pending} />],
  [ChallengeAttemptStatusEnum.SUBMITTED, <FormattedMessage {...intlMessages.submitted} />],
]);

export const AttemptStatusLabel: FC<IPropTypes> = (props) => (
  <div
    className={cx({
      'attempt-status-label': true,
      'attempt-status-label--archived': props.status === ChallengeAttemptStatusEnum.ARCHIVED,
      'attempt-status-label--failed': props.status === ChallengeAttemptStatusEnum.FAILED,
      'attempt-status-label--inline': props.inline,
      'attempt-status-label--passed':props.status === ChallengeAttemptStatusEnum.PASSED,
      'attempt-status-label--pending': props.status === ChallengeAttemptStatusEnum.PENDING,
      'attempt-status-label--submitted': props.status === ChallengeAttemptStatusEnum.SUBMITTED,
    })}
  >
    {labelMap.get(props.status)}
  </div>
);
