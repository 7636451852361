import {defineMessages} from 'react-intl';

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    id: 'ReactiveForms.Forms.ExerciseForm.QuestionNavigation.Back',
  },
  next: {
    defaultMessage: 'Next',
    id: 'ReactiveForms.Forms.ExerciseForm.QuestionNavigation.Next',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'ReactiveForms.Forms.ExerciseForm.QuestionNavigation.Submit',
  },
});
