import {action, computed,makeObservable} from 'mobx';
import UserStore from '@u4i/state/UserStore';
import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {BaseSection} from '../BaseSection';
import {LandingPageStore} from '../../LandingPageStore';
import {VisualComponent} from './VisualComponent';

export class RegisterSection extends BaseSection {
  private landingPageStore: LandingPageStore;
  private userStore: UserStore;
  public rootStore: IRootStore;

  constructor(rootStore: IRootStore, sectionData: ISection) {
    super(sectionData, VisualComponent);

    makeObservable(this, {
      isVisible: computed,
      reloadContent: action.bound
    });

    const {landingPageStore, userStore} = rootStore;
    this.rootStore = rootStore;
    this.landingPageStore = landingPageStore;
    this.userStore = userStore;
  }

  get isVisible(): boolean {
    return !this.landingPageStore.isPrivateModeEnabled && !this.userStore.isAuthorized;
  }

  handleRegistrationFormSubmission = (data) => {
    return this.userStore.register(data);
  };

  async reloadContent(): Promise<void> {
    return Promise.resolve();
  }
}
