import cx from 'classnames';
import React, {Component} from 'react';

import './_sticky-element.scss';

interface IProps {
  children: any
  className?: string
  stickyTopOffset: number
}

class StickyElement extends Component<IProps> {
  state = {
    isSticky: false,
  };

  compensatorRef: any = null;
  wrapperRef: any = null;

  componentDidMount() {
    document.addEventListener('scroll', this.updateCalculations);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.isSticky !== this.state.isSticky || nextProps.children !== this.props.children;
  }

  componentDidUpdate() {
    this.compensatorRef.style.height = this.state.isSticky
      ? `${this.wrapperRef.clientHeight}px` : 0;
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.updateCalculations);
  }

  updateCalculations = () => {
    const wrapperBoundingRect = this.compensatorRef.getBoundingClientRect();
    const topDistance: number = wrapperBoundingRect.top - this.props.stickyTopOffset;

    this.setState({isSticky: topDistance <= 0});
  }

  render() {
    return (
      <div className={this.props.className}>
        <div ref={(ref) => { this.compensatorRef = ref; }} />

        <div
          className={cx({
            'sticky-element--sticky': this.state.isSticky,
          })}
          ref={(ref) => { this.wrapperRef = ref; }}
          style={{top: this.props.stickyTopOffset}}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default StickyElement;
