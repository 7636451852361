import {defineMessages} from 'react-intl';

export default defineMessages({
  actions: {
    defaultMessage: 'Actions',
    id: 'CustomerGrading.ProgressGroupsList.Actions',
  },
  chart: {
    defaultMessage: 'Chart',
    id: 'CustomerGrading.ProgressGroupsList.Chart',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'CustomerGrading.ProgressGroupsList.Failed',
  },
  groupName: {
    defaultMessage: 'Group name',
    id: 'CustomerGrading.ProgressGroupsList.GroupName',
  },
  passed: {
    defaultMessage: 'Passed',
    id: 'CustomerGrading.ProgressGroupsList.Passed',
  },
  submitted: {
    defaultMessage: 'Submitted',
    id: 'CustomerGrading.ProgressGroupsList.Submitted',
  },
  total: {
    defaultMessage: 'Total',
    id: 'CustomerGrading.ProgressGroupsList.Total',
  },
  view: {
    defaultMessage: 'View',
    id: 'CustomerGrading.ProgressGroupsList.View',
  }
});
