import React from 'react';
import {SortingIcon} from './SortingIcon';
import './_sorting-button.scss';

interface IPropTypes {
  children?: any
  currentSortDirection: 'ASC' | 'DESC'
  currentSortedField: string
  fieldName: string
  onSortAnswers: (fieldName: string) => void
}

export class SortingButton extends React.Component<IPropTypes> {
  handleSortClick = () => {
    this.props.onSortAnswers(this.props.fieldName);
  };

  render() {
    return (
      <button
        className="discuss__sorting-button"
        onClick={this.handleSortClick}
        type="button"
      >
        {this.props.children}

        <div className="discuss__sorting-button__icon">
          <SortingIcon
            currentlySorted={this.props.currentSortedField === this.props.fieldName}
            currentSortDirection={this.props.currentSortDirection}
          />
        </div>
      </button>
    );
  }
}
