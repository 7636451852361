import React, { Component } from "react";
import { action, observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import UserStore from "@u4i/state/UserStore";
import withUser from "@u4i/modules/UserManager/withUser";
import { IRootStore } from "@u4i/state/RootStore";
import { UserRolesEnum } from "@u4i/common/enums/UserRolesEnum";
import DesktopMenuTrigger from "./components/DesktopMenuTrigger";
import EntriesList from "./components/EntriesList";
import MobileMenuTrigger from "./components/MobileMenuTrigger/MobileMenuTrigger";
import "./_user-menu.scss";

interface IPropTypes {
  rootStore?: IRootStore;
  userData: {
    firstname: string;
    id: string;
    lastname: string;
    profileImage: string;
    role: UserRolesEnum;
    username: string;
  };
}

const UserMenu = inject("rootStore")(
  observer(
    class UserMenu extends Component<IPropTypes> {
      private menuIsOpen: boolean = false;
      private userStore: UserStore;

      private containerRef = React.createRef<HTMLDivElement>();

      constructor(props: IPropTypes) {
        super(props);

        makeObservable<UserMenu, "menuIsOpen">(this, {
          menuIsOpen: observable,
          checkBlurClick: action.bound,
          handleGradingClick: action.bound,
          handleLogoutClick: action.bound,
          handleProgressClick: action.bound,
          handleRedeemClick: action.bound,
          handleAdminView: action.bound,
          toggle: action.bound,
        });

        const { userStore } = this.props.rootStore!;
        this.userStore = userStore;
      }

      componentWillUnmount() {
        window.removeEventListener("click", this.checkBlurClick);
      }

      checkBlurClick = (event) => {
        const container = this.containerRef.current;

        if (container && !container.contains(event.target)) {
          this.toggle();
        }
      };

      handleGradingClick() {
        this.toggle();
        AppNavigator.push(appRoutes.gradingAttemptsList);
      }

      handleLogoutClick() {
        this.toggle();
        this.userStore.logout();
      }

      handleProgressClick() {
        this.toggle();
        AppNavigator.push(appRoutes.gradingProgress);
      }

      handleRedeemClick() {
        this.toggle();
        AppNavigator.push(appRoutes.redeemPage);
      }

      handleAdminView() {
        this.toggle();
        AppNavigator.push(appRoutes.admin3View);
      }

      toggle() {
        this.menuIsOpen = !this.menuIsOpen;

        if (this.menuIsOpen) {
          window.addEventListener("click", this.checkBlurClick);
        } else {
          window.removeEventListener("click", this.checkBlurClick);
        }
      }

      render() {
        const userRole: UserRolesEnum = this.props.userData.role;
        const isAdmin: boolean = userRole === UserRolesEnum.U4I_ADMIN || userRole === UserRolesEnum.SUPERADMIN;
        const isAnon: boolean = userRole === UserRolesEnum.ANONYMOUS;
        const isFaculty: boolean = userRole === UserRolesEnum.U4I_FACULTY;
        const isGraderAdmin: boolean = userRole === UserRolesEnum.GRADER_ADMIN;
        const isGraderFaculty: boolean = userRole === UserRolesEnum.GRADER_FACULTY;

        return (
          <div className="main-layout__user-menu" ref={this.containerRef}>
            <DesktopMenuTrigger
              onClick={this.toggle}
              profileImage={this.props.userData.profileImage}
              userFirstname={this.props.userData.firstname}
              userLastname={this.props.userData.lastname}
              userUsername={this.props.userData.username}
            />

            <MobileMenuTrigger
              keepActive={this.menuIsOpen}
              onClick={this.toggle}
            />

            <EntriesList
              isAdmin={isAdmin}
              isAnon={isAnon}
              isFaculty={isFaculty}
              isGraderAdmin={isGraderAdmin}
              isGraderFaculty={isGraderFaculty}
              isOpen={this.menuIsOpen}
              onGradingClick={this.handleGradingClick}
              onLogoutClick={this.handleLogoutClick}
              onProgressClick={this.handleProgressClick}
              onRedeemClick={this.handleRedeemClick}
              onAdminView={this.handleAdminView}
              userId={this.props.userData.id}
            />
          </div>
        );
      }
    }
  )
);
export default withUser(UserMenu);
