import React, {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import {observer} from 'mobx-react';

import {IRootStore} from '@u4i/state/RootStore';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {UserRolesEnum} from '@u4i/common/enums/UserRolesEnum';
import VersionList from '@u4i/common/VersionList';
import { FooterChangingLanguageOverlay } from './components/FooterChangingLanguageOverlay/FooterChangingLanguageOverlay';
import { FooterPageLinks } from './components/FooterPageLinks/FooterPageLinks';
import { FooterLanguageSwitch } from './components/FooterLanguageSwitch/FooterLanguageSwitch';
import { FooterInfoLinks } from './components/FooterInfoLinks/FooterInfoLinks';
import './_footer.scss';

interface IProps {
  rootStore: IRootStore;
}

const Footer: FC<IProps> = (props: IProps) => {
  const {
    languageStore,
    scormProxyService,
    userStore
  } = props.rootStore;

  let changingLanguage: boolean = false;
  const currentYear: number = (new Date()).getFullYear();
  const userRole: UserRolesEnum = userStore.userData.role;
  const isAdmin: boolean = userRole === UserRolesEnum.U4I_ADMIN || userRole === UserRolesEnum.SUPERADMIN;

  const handleChangeLanguage = async (newLocale: LanguageEnum) => {
    if (newLocale === languageStore.currentLocale) {
      return;
    }

    changingLanguage = true;
    await userStore.changeLanguage(newLocale);
    changingLanguage = false;
  };

  return (
    <footer className="footer">
      {changingLanguage &&
        <FooterChangingLanguageOverlay />
      }

      <div className="footer__upper-section">
        <div className="container">
          <div className="footer__upper-content">
            {!scormProxyService.isEnabled &&
              <FooterPageLinks currentLocale={languageStore.currentLocale} />
            }

            <FooterLanguageSwitch
              locale={languageStore.currentLocale}
              onChangeLanguage={handleChangeLanguage}
            />
          </div>
        </div>
      </div>

      <div className="footer__info">
        <div className="container">
          <div className="footer__info-content">
            <FooterInfoLinks currentLocale={languageStore.currentLocale} />

            <div className="footer__info-copyright">
              <span
                data-for='version-list-tooltip'
                data-tip=''
                data-effect='solid'
              >
                &copy; {currentYear}
              </span>
              <ReactTooltip
                id="version-list-tooltip"
                getContent={() => <VersionList />}
                disable={!isAdmin}
              />
              <strong className="footer__info-company">
                Education4Industry GmbH
              </strong>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default observer(Footer);
