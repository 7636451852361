import React from 'react';
import {action, observable, reaction,makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import { UserAnswer } from './UserAnswer/UserAnswer';
import CircularProgress from '@u4i/common/CircularProgress';
import {IRootStore} from '@u4i/state/RootStore';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import {UserAnswersStore} from '../../state/UserAnswersStore';
import './_answers-section.scss';

interface IPropTypes {
  discussPageLanguage: string;
  rootStore?: IRootStore;
  showAnonymitySettingsSwitch: boolean;
  store: UserAnswersStore;
}

const AnswersSection = inject('rootStore')(observer(class AnswersSection extends React.Component<IPropTypes> {
  public userAnswerEntries: DiscussAnswerEntity[] = this.props.store.items;
  private userAnswerEntriesDisposer: any;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable(this, {
      userAnswerEntries: observable,
      setUserAnswerEntries: action.bound
    });

    this.userAnswerEntriesDisposer = reaction(() => this.props.store.items, this.setUserAnswerEntries);
  }

  componentWillUnmount() {
    this.userAnswerEntriesDisposer();
  }

  setUserAnswerEntries() {
    this.userAnswerEntries = this.props.store.items;
  }

  render() {
    if (this.props.store.fetching) {
      return <CircularProgress />;
    }

    return (
      <section className="discuss-page__answers-section">
        <ul className="list-unstyled">
          {this.userAnswerEntries.map((entry, index) => (
            <li className="discuss-page__answers-section__item" key={entry.id}>
              <UserAnswer
                entity={entry}
                formId={entry.id}
                index={index}
                language={this.props.discussPageLanguage}
                showAnonymitySettingsSwitch={this.props.showAnonymitySettingsSwitch}
              />
            </li>
          ))}
        </ul>
      </section>
    );
  }
}));

const WrappedComponent = observer(AnswersSection);

export {WrappedComponent as AnswersSection};
