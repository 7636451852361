import cx from 'classnames';
import React from 'react';

import './_classic-submit-button.scss';

interface IPropTypes {
  children: React.ReactNode;
  disabled?: boolean;
  inline?: boolean;
  submitting?: boolean;
}

export const ClassicSubmitButton: React.FunctionComponent<IPropTypes> = (props) => (
  <button
    className={cx({
      'reactive-forms__classic-submit-button': true,
      'reactive-forms__classic-submit-button--inline': props.inline !== undefined ? props.inline : false,
    })}
    disabled={props.disabled !== undefined ? props.disabled : false}
    type="submit"
  >
    <div
      className={cx({
        'reactive-forms__classic-submit-button__spinner': true,
        'reactive-forms__classic-submit-button__spinner--visible': props.submitting !== undefined ?
          props.submitting : false,
      })}
    />
        
    <div
      className={cx({
        'reactive-forms__classic-submit-button__content-wrapper': true,
        'reactive-forms__classic-submit-button__content-wrapper--hidden': props.submitting !== undefined ?
          props.submitting : false,
      })}
    >
      {props.children}
    </div>
  </button>
);
