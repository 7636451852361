import { AntDTableFilters } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import { ICustomerIntegration, ILTIConfigurationsPost, ILTIConfigurationsPut } from "@u4i/modules/Admin/modules/CustomerIntegration/interfaces";
import { AdminCustomerIntegrationParser } from "@u4i/parsers/admin/AdminCustomerIntegrationParser";
import { IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { AxiosInstance } from "axios";
import qs from 'qs';

export class AdminCustomerIntegrationApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchCustomerIntegrationData(config: AntDTableFilters<ICustomerIntegration>): Promise<IPaginatedItems<ICustomerIntegration>> {
    const response = await this.apiClient.get("/admin/customer-integrations", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.orderBy,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });

    const items = AdminCustomerIntegrationParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items, totalItems };
  }

  async createCustomerIntegration(model: ILTIConfigurationsPost | any): Promise<boolean> {
    const response = await this.apiClient.post(`/admin/customer-integrations`, model);

    return response.data;
  }

  async deleteCustomerIntegration(id: string | any): Promise<boolean> {
    const response = await this.apiClient.delete(`/admin/customer-integrations/${id}`);

    return response.data;
  }

  async fetchCustomerIntegrationById(id: string): Promise<ICustomerIntegration> {
    const response = await this.apiClient.get(`/admin/customer-integrations/${id}`);

    return response.data;
  }

  async updateCustomerIntegration(id: string, model: ILTIConfigurationsPut | any): Promise<boolean> {
    const response = await this.apiClient.post(`/admin/customer-integrations/${id}`, model);

    return response.data;
  }
}
