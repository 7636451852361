import { CheckCircleFilled, MinusCircleOutlined } from "@ant-design/icons";
import { IChapterData } from "@u4i/modules/CourseDetailPage/interfaces/ChapterInterface";
import { Steps } from "antd";
import React, { FC } from "react";
import ChapterCard from "../../../../../common/Cards/ChapterCard";
import "./_chapters-list.scss";

interface IProps {
  isSkillBought: boolean;
  items: IChapterData[];
  skillHasChallenges?: boolean;
  onItemClick: (id: string) => void;
  hasMandatoryChapters: boolean;
  page: number;
}

export const ChaptersList: FC<IProps> = (props: IProps) => {
  const {
    isSkillBought,
    items,
    skillHasChallenges,
    hasMandatoryChapters,
    onItemClick,
  } = props;

  const checkCardVissibility = (index: number) => {
    if (!isSkillBought && hasMandatoryChapters) return false;

    if (!hasMandatoryChapters) return false;

    if (index === 0 && props.page === 1) return false;

    if (items[index].previousViewedStatus) return false;

    return true;
  };

  return (
    <ul className="list-unstyled">
      <Steps direction="vertical">
        {items.map((item, index) => {
          const { challenges_completed, has_challenges, viewed, viewedStatus, has_mandatory_challenges } =
            item;

          const areChallengesCompleted: boolean =
            challenges_completed && isSkillBought;
          // const skillViewed: boolean =
          //   !has_challenges &&
          //   (hasMandatoryChapters ? viewedStatus : viewed) &&
          //   isSkillBought;

          const skillViewed = () => {
            if (has_mandatory_challenges) return challenges_completed;
            
            if(!hasMandatoryChapters) return viewed;

            return viewedStatus;
          };

          return (
            <Steps.Step
              key={index}
              status={skillViewed() ? "finish" : "wait"}
              icon={
                skillViewed() ? (
                  <CheckCircleFilled
                    style={{
                      fontSize: 32,
                    }}
                  />
                ) : (
                  <MinusCircleOutlined
                    style={{
                      fontSize: 32,
                    }}
                    className="unfinished-icon"
                  />
                )
              }
              description={
                <>
                  <li
                    className="course-detail-page__chapters-list__item"
                    key={item.id}
                  >
                    <ChapterCard
                      data={item}
                      isSkillBought={isSkillBought}
                      onStartClick={onItemClick}
                      skillHasChallenges={skillHasChallenges!}
                      isDisabled={checkCardVissibility(index)}
                      hasMandatoryChapters={hasMandatoryChapters}
                    />
                  </li>
                </>
              }
            ></Steps.Step>
          );
        })}
      </Steps>
    </ul>
  );
};
