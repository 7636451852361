import DOMPurify from 'dompurify';
import React from 'react';
import {computed,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

import intlMessages from './intlMessages';
import './_student-answer.scss';

interface IPropTypes {
  studentAnswer: string;
}

export const StudentAnswer = observer(class StudentAnswer extends React.Component<IPropTypes> {
  constructor(props: IPropTypes) {
    super(props);

    makeObservable(this, {
      studentAnswer: computed
    });
  }

  get studentAnswer() {
    return DOMPurify.sanitize(this.props.studentAnswer);
  }

  render() {
    return (
      <div className="reactive-forms__attempt-grading-form__student-answer">
        <h5 className="reactive-forms__attempt-grading-form__student-answer__header">
          <FormattedMessage {...intlMessages.answer} />
        </h5>

        <p
          className="reactive-forms__attempt-grading-form__student-answer__content"
          dangerouslySetInnerHTML={{__html: this.props.studentAnswer}}
        />
      </div>
    );
  }
});
