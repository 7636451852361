import Parser from "@u4i/utils/Parser";

const chapterParser = new Parser(chapter => ({
  challengesCompleted: chapter.challenges_completed,
  challengesSubmitted: chapter.challenges_submitted,
  contents: chapter.contents.map(content => ({
    id: btoa(unescape(encodeURIComponent(content))),
    text: content,
  })),
  description: chapter.description,
  duration: chapter.duration,
  hasChallenges: chapter.has_challenges,
  hasMandatoryChallenges: chapter.has_mandatory_challenges,
  id: chapter.id,
  language: chapter.language,
  preview: {
    url: chapter.preview.url,
  },
  providers: chapter.providers.map(provider => ({
    id: provider.id,
    name: provider.name,
    url: provider.url,
  })),
  showProviders: chapter.show_providers,
  slug: chapter.slug,
  summaries: chapter.summaries.map(summary => ({
    id: btoa(unescape(encodeURIComponent(summary))),
    text: summary,
  })),
  summaryPDF: chapter.pdf_summary_url,
  title: chapter.title,
  viewed: chapter.viewed,
  showDownloadPdfButton: chapter.show_download_pdf_button,
  viewedStatus:chapter.viewedStatus
}));

export default chapterParser;
