import React, { FC } from 'react';

import './_desktop-menu-trigger.scss';

interface IPropTypes {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  profileImage: string;
  userFirstname: string;
  userLastname: string;
  userUsername: string;
}

const DesktopMenuTrigger: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <button className="main-layout__desktop-menu-trigger" onClick={props.onClick}>
      <img
        alt="User avatar"
        className="main-layout__desktop-menu-trigger__avatar"
        src={props.profileImage}
      />

      {(props.userFirstname && props.userLastname) ?
        `${props.userFirstname} ${props.userLastname}`
        :
        props.userUsername
      }
    </button>
  );
}

export default DesktopMenuTrigger;
