  /**
  * @desc This component handles store for programs on learners side
  * @author Denis Shtupa denis.shtupa@u4i.io
  */

import AppNavigator from '@u4i/common/AppNavigator';
import { IPhaseUI, IProgram, IProgramPhase } from '@u4i/modules/Admin/modules/Programs/interfaces';
import { IRootStore } from '@u4i/state/RootStore';
import { IApi } from '@u4i/state/ServicesInterfaces';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ModuleInfoLabel, PathOfPhases } from '../interfaces/ProgramLearnerViewInterfaces';

export class ProgramsLearnerViewStore {
  apiService: IApi;
  activeModuleIdInVE: string = '';
  allPhases: IPhaseUI[] = [];
  allModules: any[] = [];
  expandedPhase?: IPhaseUI;       // Phase-modules that are showing on UI, showing only one
  fetching: boolean = true;
  isBuiltProgramUI: boolean = false;
  isModuleInfoLabelVisible: boolean = false;    // Controls when the right text box is visible. on hovering
  lineLength: number = 65;     // length of line that connects phases with each other
  phaseModuleCircleRadius: number = 40;      // radius of phase and module circles
  phaseModuleCircleWidth: number = this.phaseModuleCircleRadius * 2;     // diameter of phase and module circles
  phaseStep: number = this.phaseModuleCircleWidth + this.lineLength;     // length of phase with single path. 50 is length of phase and 40 is length of phase.
  phasesPaths: PathOfPhases[] = [];     // Paths between phases
  programById: IProgram | any;
  programClpUrl: string = '';     // Stores clp url of current program and then helps to navigate back on clicking close button on VE
  moduleInfoLabelProps: ModuleInfoLabel | null = null;    // Contains module details that are shown on hover of module
  showPreviousButton: boolean = false;     // Show/Hide previous phase-modules button
  showNextButton: boolean = false;      // Show/Hide next phase-modules button
  svgContainerWidth: number;      // Constants used only in this store to calculate svg container dimensions
  svgContainerHeight: number;       // Constants used only in this store to calculate svg container dimensions
  svgContainerMinWidth: number = 550;       // Constants used only in this store to calculate svg container dimensions
  svgContainerMinHeight: number = 350;      // Constants used only in this store to calculate svg container dimensions
  svgWidthStep: number = 142;       // Constants used only in this store to calculate svg container dimensions
  svgHeightStep: number = 100;        // Constants used only in this store to calculate svg container dimensions
  toggleContent: boolean = false;
  toggleDetails: boolean = true;
  XofStartIcon: number = 80;      // x coordinate of Start icon
  XofEndIcon: number;     // x coordinate of End icon
  XofFirstPath: number = this.XofStartIcon + this.phaseStep + 93;      // x coordinate of the first path that connects Start with first Phase.
  XofFirstPhase: number = this.XofStartIcon + 198;     // x coordinate of first Phase. Actually this is x coordinate of the center of the first circle.
  yAxis: number = 100;     // y coordinate of phasesPaths that are connecting phases to each other

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      activeModuleIdInVE: observable,
      allPhases: observable,
      allModules: observable,
      XofFirstPhase: observable,
      expandedPhase: observable,
      fetching: observable,
      isBuiltProgramUI: observable,
      isModuleInfoLabelVisible: observable,
      lineLength: observable,
      phasesPaths: observable,
      phaseStep: observable,
      phaseModuleCircleRadius: observable,
      phaseModuleCircleWidth: observable,
      programById: observable,
      programClpUrl: observable,
      moduleInfoLabelProps: observable,
      showNextButton: observable,
      showPreviousButton: observable,
      svgContainerWidth: observable,
      svgContainerHeight: observable,
      toggleContent: observable,
      toggleDetails: observable,
      XofStartIcon: observable,
      XofFirstPath: observable,
      XofEndIcon: observable,
      yAxis: observable,
      enrollUserToCourse: action.bound,
      initialStateOfProgram: action.bound,
      getProgramById: action.bound,
      onModuleHover: action.bound,
      navButtonsVisibility: action.bound,
      nextPhaseHandle: action.bound,
      phaseIconsCalc: action.bound,
      previousPhaseHandle: action.bound,
      showModules: action.bound,
      toggleAllContent: action.bound,
      toggleModuleDetails: action.bound,
      updateProgramClpUrl: action.bound,
    });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  getProgramById = async (id: string) => {
    this.fetching = true;
    this.resetProgramViewValues();

    try {
      this.programById = await this.apiService.admin.programs.fetchProgramById(id);

      runInAction(() => {
        this.enrollProgram(this.programById.id);
        this.svgContainerHeightCalc(this.programById);
        this.svgContainerWidthCalc(this.programById);
        this.pathsBetweenPhasesCalc(this.programById);
        this.phaseIconsCalc(this.programById);
        this.initialStateOfProgram(this.programById);
        this.isBuiltProgramUI = true;
      })
    }
    catch (error) {
      throw (error);
    }
    finally {
      this.fetching = false;
    }
  }

  enrollProgram = async (programId: string) => {
    try{
      await this.apiService.admin.programs.enrollUserToProgram(programId);
    }
    catch(error) {
    }
  }

  enrollUserToCourse = async (courseSlug: string, programId: string) => {
    try{
      await this.apiService.skills.enrollProgramsCourse(courseSlug, programId);
    }
    catch(error) {
    }
  }

  initialStateOfProgram = (program: IProgram) => {
    let areAllPhasesCompleted: boolean | undefined = program.phases?.some((ph: IProgramPhase) => ph.progress != 1);
    
    if(!areAllPhasesCompleted) {
      this.toggleAllContent();
    } else if(program.phases && !program.lastViewedPhaseId) {
      this.showModules(program.phases[0].id!)
    } else if(program.lastViewedPhaseId) {
      this.showModules(program.lastViewedPhaseId);
    }
  }

  modulesIconsCalc = (phase: any) => {
    let moduleObject: any = {
      xAxisCenter: phase.CXOfPhaseIcon,
      visible: false,
      yAxisCenter: this.yAxis,
      phaseId: phase.phaseId,
      modules: phase.phaseModules
    };
    this.allModules.push(moduleObject)
  }

  navButtonsVisibility = () => {
    this.showPreviousButton = this.expandedPhase?.order == 1 ? false : true;
    this.showNextButton = this.expandedPhase?.order == this.allPhases.length ? false : true;
  }

  nextPhaseHandle = () => {
    let index: number = this.allPhases.findIndex(u => u.visible === true);
    if(index >= 0 && index < this.allPhases.length - 1) {
      this.toggleContent = false;
      this.allPhases[index].visible = false;
      this.allPhases[index+1].visible = true;
      this.allPhases[index+1].visible == true ? this.expandedPhase = this.allPhases[index+1] : this.expandedPhase = undefined;
      this.navButtonsVisibility();
    }
  }

  onModuleHover = (cx: number, cy: number, title: string, hoverIn: boolean) => {
    if(hoverIn) {
      this.isModuleInfoLabelVisible = true;
      this.moduleInfoLabelProps = { 
        key: cx,
        x: cx + 42, 
        y: cy + 1, 
        title: title
      }
    } else {
      this.isModuleInfoLabelVisible = false;
      this.moduleInfoLabelProps = null;
    }
  }

  hideModalDetails = () => {
    this.isModuleInfoLabelVisible = false;
    this.moduleInfoLabelProps = null;
  }

  pathsBetweenPhasesCalc = (program: IProgram) => {
    for (var i = 0; i < program.phases?.length! - 1; i++) {
      this.phasesPaths.push({ id: `${(i+i)*i}`, XofPath: this.XofFirstPath + this.phaseStep * i });
    }
  }

  phaseIconsCalc = (program: IProgram) => {
    program.phases?.forEach((pha: IProgramPhase, index: number) => {
      let phaseObject: any = {
        CXOfPhaseIcon: this.XofFirstPhase + this.phaseStep * index,
        CYOfPhaseIcon: this.yAxis + this.phaseModuleCircleRadius,
        order: index + 1,
        phaseId: pha.id,
        phaseModules: pha.phaseModules,
        progress: pha.progress,
        shortTitle: pha.shortTitle,
        longTitle: pha.longTitle,
        required: pha.required
      };
      this.modulesIconsCalc(phaseObject);
      this.allPhases.push(phaseObject);
    });
    this.XofEndIcon = (this.phasesPaths.length * this.lineLength) + (this.allPhases.length * this.phaseModuleCircleWidth) + this.XofFirstPath;
  }

  previousPhaseHandle = () => {
    let index: number = this.allPhases.findIndex(u => u.visible === true);
    if(index > 0) {
      this.toggleContent = false;
      this.allPhases[index].visible = false;
      this.allPhases[index-1].visible = true;
      this.allPhases[index-1].visible == true ? this.expandedPhase = this.allPhases[index-1] : this.expandedPhase = undefined;
      this.navButtonsVisibility();
    }
  }

  resetProgramViewValues = () => {
    this.activeModuleIdInVE = '';
    this.allModules = [];
    this.allPhases = [];
    this.isBuiltProgramUI = false;
    this.phasesPaths = [];
    this.programById = undefined;
    this.programClpUrl = '';
    this.showNextButton = false;
    this.showPreviousButton = false;
    this.toggleContent = false;
    this.toggleDetails = true;
  }

  showModules(phaseId: string) {
    if (this.expandedPhase?.phaseId != phaseId) {
      let index = this.allPhases.findIndex(u => u.phaseId === phaseId);
      this.toggleContent = false;
      this.toggleDetails = true;
      this.allPhases.map(pha => pha.visible = false)
      this.allPhases[index].visible = true;
      this.allPhases[index].visible == true ? this.expandedPhase = this.allPhases[index] : this.expandedPhase = undefined;
      this.navButtonsVisibility();
    }
    else {
      let index = this.allPhases.findIndex(u => u.phaseId === phaseId);
      this.toggleContent = false;
      this.toggleDetails = true;
      this.allPhases[index].visible = this.allPhases[index].visible == true ? false : true;
      this.allPhases[index].visible == true ? this.expandedPhase = this.allPhases[index]  : this.expandedPhase = undefined;
      this.navButtonsVisibility();
    }
  }

  toggleAllContent = () => {
    this.toggleContent = !this.toggleContent;
    this.expandedPhase = undefined;
    if(this.toggleContent) {
      this.toggleDetails = false;
      this.showNextButton = false;
      this.showPreviousButton = false;
      this.allPhases.forEach((phase: any) => {
        phase.visible = true;
      });
    } else {
      this.allPhases.forEach((phase) => {
        phase.visible = false;
      });
    }
  }

  toggleModuleDetails = () => {
    this.toggleContent = false;
    this.toggleDetails = !this.toggleDetails;
  }

  updateProgramClpUrl = (moduleId?: string) => {
    this.activeModuleIdInVE = moduleId != undefined ? moduleId : '';
    if(AppNavigator.currentLocation) {
      this.programClpUrl = AppNavigator.currentLocation.pathname;
    }
  }

  svgContainerWidthCalc = (program: IProgram) => {
    let phasesCount: number = 1;
    phasesCount = program.phases?.length!;
    this.svgContainerWidth = (phasesCount * this.svgWidthStep) + this.svgContainerMinWidth;
  }

  svgContainerHeightCalc = (program: IProgram) => {
    let longestPhaseCount: number = 1;
    let phaseModules: any[] = [];
    program.phases?.forEach((pha: IProgramPhase) => {
      phaseModules.push(pha.phaseModules);
    })

    for (var i = 0; i < phaseModules.length; i++) {
      if (phaseModules[i].length > longestPhaseCount) {
        longestPhaseCount = phaseModules[i].length;
      }
    }
    this.svgContainerHeight = (longestPhaseCount * this.svgHeightStep) + this.svgContainerMinHeight
  }

}
