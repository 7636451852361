import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import { AlertStatusEnum } from '@u4i/common/enums/AlertStatusEnum';
import UserStore from '@u4i/state/UserStore';
import { Alert } from '@u4i/common/Alert';

import intlMessages from './intlMessages';
import './_require-role.scss';

function requireRole(...allowedRoles) {
  return function addComponentToRequireRole(WrappedComponent) {
    const WrappedClass = inject('rootStore')(observer(class WrappedClass extends Component {
      public userStore: UserStore;

      constructor(props) {
        super(props);

        this.userStore = props.rootStore.userStore;
      }

      render() {
        if (this.userStore.isLoading) {
          return null;
        }

        if (!this.userStore.isAuthorized || !allowedRoles.includes(this.userStore.userData.role)) {
          return (
            <div className="require-role">
              <Alert
                heading={<FormattedMessage {...intlMessages.heading} />}
                message={<FormattedMessage {...intlMessages.message} />}
                type={AlertStatusEnum.ERROR}
              />
            </div>
          );
        }

        return <WrappedComponent {...this.props} />;
      }
    }));

    return WrappedClass;
  };
}

export default requireRole;
