import React from 'react';

import {ISection} from '@u4i/parsers/LandingPageParser';
import {SectionTypeEnum} from '@u4i/common/enums/SectionTypeEnum';

export abstract class BaseSection {
  private component: React.ElementType;
  description: string;
  id: string;
  positionIndex: number;
  title: string;
  type: SectionTypeEnum;

  constructor(sectionData: ISection, component: React.ElementType) {
    this.component = component;
    this.description = sectionData.description;
    this.id = sectionData.id;
    this.positionIndex = sectionData.positionIndex;
    this.title = sectionData.title;
    this.type = sectionData.type;
  }

  abstract get isVisible(): boolean;

  abstract reloadContent(): Promise<void>;

  render(): React.ReactElement {
    return <this.component key={this.id} sectionModel={this} />;
  }
}
