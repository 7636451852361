import EditAnswerForm from '@u4i/common/forms/EditAnswerForm/EditAnswerForm';
import { DiscussAnswerPost } from '@u4i/modules/DiscussPage/state/DiscussPageStore';
import React, { FC } from 'react';
import './_answer-editable-content.scss';

interface IProps {
  anonymity: boolean
  content: string
  editModeEnabled: boolean
  formId: string
  showAnonymitySettingsSwitch: boolean
  updatingContent: boolean
  updatingContentError?: string
  onEditionCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  onEditionCommit: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const AnswerEditableContent: FC<IProps> = (props: IProps) => {
  const {
    anonymity,
    content,
    editModeEnabled,
    formId,
    showAnonymitySettingsSwitch,
    updatingContent,
    updatingContentError,
    onEditionCancel,
    onEditionCommit
  } = props;

  const initialValues: DiscussAnswerPost = {
    anonymity: anonymity,
    answer: content
  }

  return (
    <div className="discuss-page__answer-editable-content">
      <EditAnswerForm
        editModeEnabled={editModeEnabled}
        formId={formId}
        initialValues={initialValues}
        onEditionCancel={onEditionCancel}
        handleSubmit={onEditionCommit}
        showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
        updatingContent={updatingContent}
      />

      {updatingContentError &&
        <p className="discuss-page__answer-editable-content__error">
          {updatingContentError}
        </p>
      }
    </div>
  );
}
