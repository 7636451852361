import React, { FC, useEffect, useState } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { Button, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import intlMessages from './intlMessages';
import DOMPurify from 'dompurify';
import { IQuestionVESummary } from '@u4i/utils/interfaces';
import { red } from '@u4i/styles/styles-variables';
import './_mcq-test-feedback.scss';

interface IProps {
  questions: IQuestionVESummary[]
}

const MCQTestFeedback: FC<IProps> = (props: IProps) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const currentQuestion = props.questions[currentQuestionIndex];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) { // left arrow key
        if (currentQuestionIndex > 0)
          handlePreviousQuestion();
      } else if (event.keyCode === 39) { // right arrow key
        if (!(currentQuestionIndex === props.questions.length - 1))
          handleNextQuestion();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentQuestionIndex, props.questions]);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <div className='mcq-summary' style={{overflowY: 'auto', height: '60%', scrollbarWidth: 'thin'}}>
      <Title level={3}>
        <FormattedMessage {...intlMessages.summary} />
      </Title>

      <div className='mcq-summary-header'>
        {currentQuestion.is_correctly_answered ?
          <div className="ant-result-icon mcq-summary-result-icon">
            <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="3em" height="3em" fill="#52c41a" aria-hidden="true">
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
              </svg>
            </span>
          </div>
          :
          <div className="ant-result-icon mcq-summary-result-icon">
            <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="3em" height="3em" fill={red} aria-hidden="true">
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
              </svg>
            </span>
          </div>
        }

        <Title level={4}>
          <FormattedMessage
            {...intlMessages.questionHeader}
            values={{ current: currentQuestionIndex + 1, total: props.questions.length }}
          />
        </Title>
      </div>

      <Paragraph>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentQuestion.content) }}></div>
      </Paragraph>

      {currentQuestion.is_correctly_answered && currentQuestion.correct_feedback && currentQuestion.correct_feedback != '<p><br></p>' &&
        <Title level={4}>
          Feedback:
        </Title>
      }

      {!currentQuestion.is_correctly_answered && currentQuestion.incorrect_feedback && currentQuestion.incorrect_feedback != '<p><br></p>' &&
        <Title level={4}>
          Feedback:
        </Title>
      }

      {currentQuestion.is_correctly_answered && currentQuestion.correct_feedback && currentQuestion.correct_feedback != '<p><br></p>' &&
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentQuestion.correct_feedback) }}></div>
        </Paragraph>
      }

      {!currentQuestion.is_correctly_answered && currentQuestion.incorrect_feedback && currentQuestion.incorrect_feedback != '<p><br></p>' &&
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentQuestion.incorrect_feedback) }}></div>
        </Paragraph>
      }

      <div className='button_container' style={{
        marginBottom: 24,
      }}>
        <div style={{
          margin: '0 auto 24px auto',
          left: 'auto',
          width: '80%',
        }}>
          <Row justify="space-between">
            <Button
              className='mcq-summary-navigation__button'
              disabled={currentQuestionIndex === 0}
              onClick={handlePreviousQuestion}
              type='primary'
            >
              <ArrowLeftOutlined />
              <FormattedMessage {...intlMessages.previous} />
            </Button>

            <Button
              className='mcq-summary-navigation__button'
              disabled={currentQuestionIndex === props.questions.length - 1}
              onClick={handleNextQuestion}
              type='primary'
            >
              <FormattedMessage {...intlMessages.next} />
              <ArrowRightOutlined />
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default MCQTestFeedback;
