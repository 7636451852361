import moment from 'moment';
import {action, computed, observable, runInAction,makeObservable} from 'mobx';
import {createIntl, createIntlCache, IntlShape} from 'react-intl';

import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import deMessages from '../../i18n/de.json';
import enMessages from '../../i18n/en.json';
import {IRootStore} from './RootStore';
import {IStorage} from './ServicesInterfaces';

export class LanguageStore {
  private activeLocale: LanguageEnum;
  intlMap: Map<LanguageEnum, IntlShape> = new Map([
    [LanguageEnum.DE_DE, createIntl({locale: LanguageEnum.DE_DE, messages: deMessages}, createIntlCache())],
    [LanguageEnum.EN_US, createIntl({locale: LanguageEnum.EN_US, messages: enMessages}, createIntlCache())],
  ]);
  private storageService: IStorage;

  constructor(rootStore: IRootStore) {
    makeObservable<LanguageStore, "activeLocale">(this, {
      activeLocale: observable,
      currentIntl: computed,
      currentLocale: computed,
      applyLanguageChange: action.bound
    });

    const {storageService} = rootStore;
    this.storageService = storageService;

    this.activeLocale = storageService.language.get() || this.getNavigatorLanguage();
  }

  get currentIntl(): IntlShape {
    return this.intlMap.get(this.activeLocale)!;
  }

  get currentLocale() {
    return this.activeLocale;
  }

  applyLanguageChange(newLocale: LanguageEnum) {
    if (newLocale === this.activeLocale) {
      return;
    }

    this.storageService.language.set(newLocale);
    moment.locale(newLocale);

    runInAction(() => {
      this.activeLocale = newLocale;
    });
  }

  private getNavigatorLanguage(): LanguageEnum {
    const navigatorLanguage = (navigator.languages && navigator.languages[0]) || navigator.language;

    switch (navigatorLanguage) {
      case 'de':
      case 'de-DE':
        return LanguageEnum.DE_DE;

      case 'en':
      case 'en-US':
        return LanguageEnum.EN_US;

      default:
        return LanguageEnum.EN_US;
    }
  }
}
