import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from "@reach/router";
import AppNavigator, {appRoutes} from '@u4i/common/AppNavigator';
import {CHALLENGES_TYPEFORM_ORIGIN} from '@u4i/constantSettings';
import isHostValid from '../../utils/isHostValid';
import UserStore from '@u4i/state/UserStore';
import { IRootStore } from '@u4i/state/RootStore';
import { inject, observer } from 'mobx-react';

interface IPropTypes extends RouteComponentProps {
  rootStore: IRootStore;
}

const ExternalContent = inject('rootStore')(observer(
  class ExternalContent extends Component<IPropTypes | any> {
  private challengePlatform?: string | any; // TODO: use enum for challenge platforms
  private targetUrl?: string | any;
  private skillId: string | any;
  private userStore: UserStore;

  constructor(props: IPropTypes | any) {
    super(props);

    const {userStore} = this.props.rootStore;
    this.userStore = userStore;

    const parsedQuery: any = qs.parse(this.props.location?.search, {ignoreQueryPrefix: true});
    this.challengePlatform = parsedQuery?.challengePlatform;

    const indexOfSkill: number = parsedQuery.targetUrl.indexOf("cm_return_skill=");
    if(indexOfSkill !== -1) {
      this.skillId = parsedQuery.targetUrl.slice(indexOfSkill + 16)
    }

    if(parsedQuery?.targetUrl && isHostValid(parsedQuery.targetUrl)) {
      this.targetUrl = parsedQuery?.targetUrl;
    }
  }

  get usesTypeforms() {
    return this.challengePlatform === 'feedback_survey';
  }

  componentDidMount() {
    if (this.usesTypeforms) {
      window.addEventListener('message', this.handleMessage);
    }
  }

  componentWillUnmount() {
    if (this.usesTypeforms) {
      window.removeEventListener('message', this.handleMessage);
    }
  }

  handleMessage = (event: MessageEvent) => {
    if (event.origin !== CHALLENGES_TYPEFORM_ORIGIN) {
      return;
    }

    if (event.data.type == 'form-submit') {
      if(this.skillId) {
        this.userStore.feedbackSubmission(this.skillId);
      }
      AppNavigator.push(appRoutes.home);
    }
  };

  render() {
    return (
      <iframe
        src={this.targetUrl}
        style={{
          border: 'none',
          height: 'calc(100vh - 112px)',
          width: '100%',
        }}
      />
    );
  }
}
));

export default ExternalContent;
