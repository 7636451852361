import {RcFile} from 'antd/lib/upload/interface';

import Parser from '@u4i/utils/Parser';
import {UploadFile} from 'antd/lib/upload/interface';

export interface IProofOfQualification {
  headerDE: string;
  headerEN: string;
  logoFile: RcFile;
  signatureFile: RcFile;
  signatureFirstLine: string;
  signatureSecondLine: string;
}

export interface IProofOfQualificationPreview {
  headerDE: string;
  headerEN: string;
  logoFileList?: Array<UploadFile>;
  signatureFileList?: Array<UploadFile>;
  signatureFirstLine: string;
  signatureSecondLine: string;
}

export const AdminProofOfQualificationParser = new Parser<IProofOfQualificationPreview>(proofOfQualification => ({
  headerDE: proofOfQualification.headerDE,
  headerEN: proofOfQualification.headerEN,
  logoFileList: [{
    fileName: proofOfQualification.logoFile.filename,
    name: proofOfQualification.logoFile.filename,
    size: proofOfQualification.logoFile.size,
    status: 'done',
    type: proofOfQualification.logoFile.type,
    uid: proofOfQualification.logoFile.id,
    url: proofOfQualification.logoFile.url,
  }],
  signatureFileList: [{
    fileName: proofOfQualification.signatureFile.filename,
    name: proofOfQualification.logoFile.filename,
    size: proofOfQualification.signatureFile.size,
    status: 'done',
    type: proofOfQualification.signatureFile.type,
    uid: proofOfQualification.signatureFile.id,
    url: proofOfQualification.signatureFile.url,
  }],
  signatureFirstLine: proofOfQualification.signatureFirstLine,
  signatureSecondLine: proofOfQualification.signatureSecondLine,
}));
