const isHostValid = (url) => {
  const allowList = [
    "classmarker.com",
    "cmkr.co",
    "university4industry.typeform.com",
    "university4industry.com",
    "docs.google.com",
    "forms.gle",
    "agility-tool.com",
    "ad-biel-00001.ceyeclon.com",
    "goo.gl",
    "scg-dev-aws.university4industry.com",
    "scm-dev-aws.university4industry.com",
    "scmskate-dev-aws.university4industry.com",
    "scm-skate-aws.university4industry.com",
    "scm-medical-aws.university4industry.com"
  ];

  const matches = url.match(/^(?:https?:\/\/)?(?:www[0-9]*\.)?([^\/?#]+)(?:[\/?#]|$)/i);
  const domain = matches && matches[1];
  return !!allowList.find(el => el === domain); //  <array>.find returns value which found or undefined so in order to return true|false I used "!!" /pg
}

export default isHostValid;
