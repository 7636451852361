import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';
import cx from 'classnames';
import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_footer-language-switch.scss';

interface IProps {
  locale: string;
  onChangeLanguage: (newLocale: LanguageEnum) => void;
}

export const FooterLanguageSwitch: FC<IProps> = (props: IProps) => {
  const {
    locale,
    onChangeLanguage
  } = props;

  const changeLanguageToEN = () => {
    onChangeLanguage(LanguageEnum.EN_US);
  };

  const changeLanguageToDE = () => {
    onChangeLanguage(LanguageEnum.DE_DE);
  };

  return (
    <div className="footer__language-wrapper">
      <h5 className="footer__language-header">
        <FormattedMessage {...intlMessages.languageHeader} />
      </h5>

      <ul className="footer__language-entries list-unstyled">
        <li
          className={cx({
            'footer__language-entry': true,
            'footer__language-entry--active': locale === LanguageEnum.EN_US,
          })}
        >
          <button
            className="footer__language-entry-button"
            onClick={changeLanguageToEN}
            type="button"
          >
            English
          </button>
        </li>

        <li
          className={cx({
            'footer__language-entry': true,
            'footer__language-entry--active': locale === LanguageEnum.DE_DE,
          })}
        >
          <button
            className="footer__language-entry-button"
            onClick={changeLanguageToDE}
            type="button"
          >
            Deutsch
          </button>
        </li>
      </ul>
    </div>
  );
}
