import Parser from '@u4i/utils/Parser';

export interface INotification {
  content: string;
  date: string;
  id: string;
  isNew: boolean;
  link: string;
  senderAvatar: string;
  senderName: string;
  title: string;
}

const notificationParser = new Parser<INotification>(notification => ({
  content: notification.content,
  date: notification.date,
  id: notification.id,
  isNew: notification.isNew,
  link: notification.link,
  senderAvatar: notification.sender.avatar,
  senderName: notification.sender.name,
  title: notification.title,
}));

export default notificationParser;
