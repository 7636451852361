import {defineMessages} from 'react-intl';

export default defineMessages({
  busyErrorHeading: {
    defaultMessage: 'Oops!',
    id: 'DigitalLab.BusyErrorHeading',
  },
  busyErrorMessage: {
    defaultMessage: 'This lab is currently busy. Please try again later. If you keep encountering this problem, contact our support team: support@university4industry.com or book a slot for lab to use',
    id: 'DigitalLab.BusyErrorMessage',
  },
  disabledErrorHeading: {
    defaultMessage: 'Oops!',
    id: 'DigitalLab.DisabledErrorHeading',
  },
  disabledErrorMessage: {
    defaultMessage: 'This lab is currently disabled. Please try again later. If you keep encountering this problem, contact our support team: support@university4industry.com',
    id: 'DigitalLab.DisabledErrorMessage',
  },
  generalErrorHeading: {
    defaultMessage: 'Oops!',
    id: 'DigitalLab.GeneralErrorHeading',
  },
  generalErrorMessage: {
    defaultMessage: 'Please try again later. If you keep encountering this problem, contact our support team: support@university4industry.com',
    id: 'DigitalLab.GeneralErrorMessage',
  },
  mobileHeading: {
    defaultMessage: 'Oops!',
    id: 'DigitalLab.MobileHeading',
  },
  mobileMessage: {
    defaultMessage: 'Digital lab challenges do not support mobile devices, please use a desktop.',
    id: 'DigitalLab.MobileMessage',
  },
});
