import {AxiosInstance} from 'axios';

import {IOnboardingProgress, OnboardingProgressParser} from '@u4i/parsers/OnboardingProgressParser';

export class OnboardingApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchOnboardingProgress(): Promise<IOnboardingProgress> {
    const response = await this.apiClient.get('/v1/onboarding/progress');

    return OnboardingProgressParser.parse(response.data);
  }

  async updateOnboardingProgress(onboardingProgress: IOnboardingProgress): Promise<IOnboardingProgress> {
    const response = await this.apiClient.put('/v1/onboarding/progress', onboardingProgress);

    return OnboardingProgressParser.parse(response.data);
  }
}
