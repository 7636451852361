import React, { FC } from 'react';
import {CURRICULUM_CARD_WIDTH, CURRICULUM_SPACING} from '@u4i/constantSettings';
import {MiniSkillCard} from './MiniSkillCard';
import { ICurriculumItem } from '@u4i/common/Interfaces/CurriculumInterface';
import './_skill-list.scss';

interface IPropTypes {
  flexibleWidth?: boolean;
  items: Array<ICurriculumItem>;
  onSkillCardClick: (skill: ICurriculumItem) => void;
}

export const SkillList: FC<IPropTypes> = (props) => (
  <div>
    <ul
      className="list-unstyled landing-page__skill-list"
      style={{
        padding: props.flexibleWidth ?
          undefined
          :
          `${CURRICULUM_SPACING / 2}px ${CURRICULUM_SPACING / 2}px ${CURRICULUM_SPACING * 1.5}px`,
      }}
    >
      {props.items.map((course: ICurriculumItem) => (course.isPublished &&
        <li
          className="landing-page__skill-list__item"
          key={course.id}
          style={props.flexibleWidth ? undefined : {
            flex: `0 0 ${CURRICULUM_CARD_WIDTH}px`,
            marginBottom: 0,
            marginLeft: CURRICULUM_SPACING / 2,
            marginRight: CURRICULUM_SPACING / 2,
            marginTop: CURRICULUM_SPACING,
            width: CURRICULUM_CARD_WIDTH,
          }}
        >
          <MiniSkillCard
            onClick={props.onSkillCardClick}
            skill={course}
          />
        </li>
      ))}
    </ul>
  </div>
);

SkillList.defaultProps = {
  flexibleWidth: false,
};
