import FormatDate from '@u4i/common/FormatDate';
import DiscussLikeEntity from '@u4i/state/entities/DiscussLikeEntity';
import PropTypes from 'prop-types';
import React from 'react';
import './_answer-like.scss';

AnswerLike.propTypes = {
  entity: PropTypes.instanceOf(DiscussLikeEntity).isRequired,
};

function AnswerLike(props) {
  return (
    <div className="answer-likes-modal__answer-like">
      <div className="answer-likes-modal__answer-like__avatar">
        <img
          alt="User avatar"
          className="answer-likes-modal__answer-like__avatar-image"
          src={props.entity.user.profileImage}
        />
      </div>

      <div className="answer-likes-modal__answer-like__info-block">
        <h4 className="answer-likes-modal__answer-like__user-name">
          {props.entity.user.firstName}
          &nbsp;
          {props.entity.user.lastName}
        </h4>

        <p className="answer-likes-modal__answer-like__timestamp">
          <FormatDate source={props.entity.createdAt} longMonth={true}/>
        </p>
      </div>
    </div>
  );
}

export default AnswerLike;
