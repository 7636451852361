import {defineMessages} from 'react-intl';

export default defineMessages({
  message: {
    defaultMessage: 'Thank you for your interest in this course. We are delighted that you want'
    + ' to deepen your knowledge. After completing this purchase, you will get access to'
    + ' this course for one person. Upon successful completion of the course, you will receive'
    + ' a qualification certificate. You have up to 3 months to complete the course.',
    id: 'Modals.PurchaseCourseModal.PurchaseMessage',
  },
  purchaseCourse: {
    defaultMessage: 'Purchase course',
    id: 'Modals.PurchaseCourseModal.PurchaseCourse'
  }
});
