import Fontawesome from 'react-fontawesome';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {v4 as uuid} from "uuid"; 

import './_actions-bar.scss';

interface IPropTypes {
  buttons: Array<{
    iconName: string;
    onClick: (rowId: string) => void;
    tooltipLabel: React.ReactNode;
    visible: boolean;
  }>;
  rowId: string;
}

export class ActionsBar extends React.Component<IPropTypes> {
  private idForTooltips = uuid();

  render() {
    return (
      <div style={{display:"flex"}}>
        {this.props.buttons.filter(entry => entry.visible).map((entry, index) => (
          <button
            className="data-table__actions-bar__button"
            style={index > 0 ? {marginLeft:"10px"} : {}}
            data-for={`action-bar-tooltip-${this.idForTooltips}-button-${index}`}
            data-tip
            onClick={() => entry.onClick(this.props.rowId)}
            key={index}
            type="button"
          >
            <Fontawesome name={entry.iconName} />
          </button>
        ))}

        {this.props.buttons.filter(entry => entry.visible).map((entry, index) => (
          <ReactTooltip
            effect="solid"
            id={`action-bar-tooltip-${this.idForTooltips}-button-${index}`}
            key={index}
            place="top"
            type="dark"
          >
            {entry.tooltipLabel}
          </ReactTooltip>
        ))}
      </div>
    );
  }
}
