import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_question-navigation.scss';

interface IPropTypes {
  currentQuestion: number
  submitting: boolean
  submitSucceeded: boolean
  totalQuestions: number
  onBackClick: () => void
  onNextClick: () => void
}

export const QuestionNavigation: FC<IPropTypes> = (props) => (
  <div className="reactive-forms__attempt-grading-form__question-navigation">
    <div
      className={cx({
        'reactive-forms__attempt-grading-form__question-navigation__button-wrapper': true,
        'reactive-forms__attempt-grading-form__question-navigation__button-wrapper--visible': props.currentQuestion > 1,
      })}
    >
      <button
        className="reactive-forms__attempt-grading-form__question-navigation__button"
        disabled={props.submitting || props.submitSucceeded}
        onClick={props.onBackClick}
        type="button"
      >
        <FormattedMessage {...intlMessages.back} />
      </button>
    </div>

    <div className="reactive-forms__attempt-grading-form__question-navigation__forward-controls">
      <div
        className={cx({
          'reactive-forms__attempt-grading-form__question-navigation__button-wrapper': true,
          'reactive-forms__attempt-grading-form__question-navigation__button-wrapper--absolute': true,
          'reactive-forms__attempt-grading-form__question-navigation__button-wrapper--visible': props.currentQuestion < props.totalQuestions + 1,
        })}
      >
        <button
          className="reactive-forms__attempt-grading-form__question-navigation__button"
          disabled={props.submitting || props.submitSucceeded}
          onClick={props.onNextClick}
          type="button"
        >
          <FormattedMessage {...intlMessages.next} />
        </button>
      </div>
    </div>
  </div>
);
