import {action, computed, observable, runInAction,makeObservable} from 'mobx';
import {IApi} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {BaseSection} from '../BaseSection';
import {LandingPageStore} from '../../LandingPageStore';
import {VisualComponent} from './VisualComponent';
import {ICurriculum} from '@u4i/common/Interfaces/CurriculumInterface';

export class CurriculumSection extends BaseSection {
  public apiService: IApi;
  curriculum: ICurriculum;
  public landingPageStore: LandingPageStore;
  fetching = true;

  constructor(rootStore: IRootStore, sectionData: ISection) {
    super(sectionData, VisualComponent);

    makeObservable(this, {
      curriculum: observable,
      fetching: observable,
      isVisible: computed,
      reloadContent: action.bound
    });

    const {apiService, landingPageStore} = rootStore;
    this.apiService = apiService;
    this.landingPageStore = landingPageStore;

    this.reloadContent();
  }

  get isVisible(): boolean {
    return !this.landingPageStore.isPrivateModeEnabled;
  }

  handleCurriculumCourseModal = (open: boolean) => {
    this.landingPageStore.curriculumCourseModalHandle(open);
  }

  get isCurriculumCourseModalVisible(): boolean {
    return this.landingPageStore.isCurriculumCourseVisible;
  }

  async reloadContent(): Promise<void> {
    if (this.landingPageStore.isPrivateModeEnabled) {
      return;
    }
    
    this.fetching = true;

    const items: ICurriculum[] = await this.apiService.landingPages.fetchSectionItems<ICurriculum>(
      this.id,
      this.type,
      this.landingPageStore.previewModeEnabled,
      this.landingPageStore.itemsLanguage,
    );

    runInAction(() => {
      this.fetching = false;
      this.curriculum = items[0];
    });
  }
}
