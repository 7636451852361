import {defineMessages} from 'react-intl';

export default defineMessages({
  certificateDescriptionUncompleted: {
    defaultMessage: 'Finish ' + '{numberOfChallenges, plural, one {the challenge} other {all {numberOfChallenges} challenges}}' + ' to earn your qualification certificate.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificateDescriptionUncompleted',
  },

  certificateDescriptionUncompletedAndFeedbackNotSubmitted: {
    defaultMessage: 'Finish ' + '{numberOfChallenges, plural, one {the challenge} other {all {numberOfChallenges} challenges}}' + ' and submit <func>feedback</func> survey to earn your qualification certificate.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificateDescriptionUncompletedAndFeedbackNotSubmitted',
  },
  certificateDescriptionUncompletedAndFeedbackSubmitted: {
    defaultMessage: 'Finish ' + '{numberOfChallenges, plural, one {the challenge} other {all {numberOfChallenges} challenges}}' + ' to earn your qualification certificate.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificateDescriptionUncompletedAndFeedbackSubmitted',
  },
  certificateDescriptionCompletedAndFeedbackNotSubmitted: {
    defaultMessage: 'Submit <func>feedback</func> survey to earn your qualification certificate.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.CertificateDescriptionCompletedAndFeedbackNotSubmitted',
  },

  certificateDescriptionUncompletedNoChallenges: {
    defaultMessage: 'View content to complete your progress.',
    id: 'CourseDetailPage.Header.CertificateStatusBox.CertificateDescriptionUncompletedNoChallenges',
  },
  certificateDescriptionUncompletedNoChallengesAndFeedbackMandatory: {
    defaultMessage: 'View content and submit <func>feedback</func> survey to complete your progress.',
    id: 'CourseDetailPage.Header.CertificateStatusBox.CertificateDescriptionUncompletedNoChallengesAndFeedbackMandatory',
  },
  certificateDescriptionUncompletedFeedbackMandatoryNoChallenges: {
    defaultMessage: 'Submit <func>feedback</func> survey to complete your progress.',
    id: 'CourseDetailPage.Header.CertificateStatusBox.CertificateDescriptionUncompletedFeedbackMandatoryNoChallenges',
  },

  challengesDescriptionCompletedAndFeedbackNotSubmitted: {
    defaultMessage: 'Submit <func>feedback</func> survey to complete the course.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.ChallengesDescriptionCompletedAndFeedbackNotSubmitted',
  },
  challengesDescriptionUncompletedAndFeedbackSubmitted: {
    defaultMessage: 'Finish ' + '{numberOfChallenges, plural, one {the challenge} other {all {numberOfChallenges} challenges}}' + ' to complete the course.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.ChallengesDescriptionUncompletedAndFeedbackSubmitted',
  },
  challengesDescriptionUncompletedAndFeedbackNotSubmitted: {
    defaultMessage: 'Finish ' + '{numberOfChallenges, plural, one {the challenge} other {all {numberOfChallenges} challenges}}' + ' and submit <func>feedback</func> survey to complete the course.',
    id: 'CourseDetailPage.Header.CertificationStatusBox.ChallengesDescriptionUncompletedAndFeedbackNotSubmitted',
  },
  feedbackText: {
    defaultMessage: 'Feedback',
    id: 'CourseDetailPage.Header.CertificationStatusBox.FeedbackText'
  }
});
