import DOMPurify from 'dompurify';
import React, { FC } from 'react';

import './_discuss-page-instructions.scss';

interface IProps {
  content: string;
}

export const DiscussPageInstructions: FC<IProps> = (props: IProps) => {
  const sanitizedContent: string = DOMPurify.sanitize(props.content, {ADD_ATTR: ['target']});

  return (
    <section className="discuss-page__instructions">
      <p
        className="discuss-page__instructions__paragraph"
        dangerouslySetInnerHTML={{__html: sanitizedContent}}
      />
    </section>
  )
}
