import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SkillDuration } from "@u4i/common/SkillDuration";
import intlMessages from "./intlMessages";
import { ClockCircleFilled,CopyFilled } from "@ant-design/icons";
import "./_course-labels.scss";

interface IProps {
  chapterCount: number;
  duration: number;
}

export const CourseLabels: FC<IProps> = (props: IProps) => {
  const { chapterCount, duration } = props;

  return (
    <div className="course-detail-page__course-labels">
      <div className="course-detail-page__course-labels__label">
        <ClockCircleFilled className="course-detail-page__course-labels__label-icon"/>
        <SkillDuration duration={duration} />
      </div>

      <div className="course-detail-page__course-labels__label">
      <CopyFilled className="course-detail-page__course-labels__label-icon"/>

        <FormattedMessage
          {...intlMessages.chapters}
          values={{ chapters: chapterCount }}
        />
      </div>
    </div>
  );
};
