import EditCommentForm from '@u4i/common/forms/EditCommentForm/EditCommentForm';
import React, { FC, useState } from 'react';
import {FormattedMessage} from 'react-intl';
import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import intlMessages from './intlMessages';
import ExpandableText from '@u4i/common/ExpandableText';
import { DISCUSS_COMMENT_TRIMMING_THRESHOLD } from '@u4i/constantSettings';
import { Form } from 'antd';

import './_comment-editable-content.scss';

interface IProps {
  anonymity: boolean
  content: string
  editModeEnabled: boolean
  formId: string
  isLiked: boolean
  likes: number
  showAnonymitySettingsSwitch: boolean
  showEditAction: boolean
  togglingLike: boolean
  updatingContent: boolean
  updatingContentError?: string
  onEditionCommit: (event: any) => Promise<any>
  onEditClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onEditionCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  onLikesClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const CommentEditableContent: FC<IProps> = (props: IProps) => {
  const {
    anonymity,
    content,
    editModeEnabled,
    formId,
    isLiked,
    likes,
    showAnonymitySettingsSwitch,
    showEditAction,
    togglingLike,
    updatingContent,
    updatingContentError,
    onEditionCommit,
    onEditClick,
    onEditionCancel,
    onLikesClick
  } = props;

  const [form] = Form.useForm();
  const [hasCommentChanged, setHasCommentChanged] = useState<boolean>(false);
  const [hasAnonymitySwitchChanged, setHasAnonymitySwitchChanged] = useState<boolean>(false)

  const updateHasContentChanged = (hasChanged: boolean) => {
    hasChanged ? setHasCommentChanged(true) : setHasCommentChanged(false);
  }

  const updateHasAnonymitySwitchChanged = (hasChanged: boolean) => {
    hasChanged ? setHasAnonymitySwitchChanged(true) : setHasAnonymitySwitchChanged(false);
  }

  const updateCommentContent = () => {
    let commentFieldErrors: any[] = form.getFieldError('comment');
    
    if(commentFieldErrors.length > 0) {
      return;
    }
    let data: any = {
      comment: form.getFieldValue('comment'),
      anonymity: form.getFieldValue('anonymity')
    }
    onEditionCommit(data);
  }

  const handleEditionButton = (event) => {
    if (editModeEnabled) {
      onEditionCancel(event);
    } else {
      onEditClick(event);
    }
  };

  return (
    <>  
      {editModeEnabled ? 
        <div className="discuss__comment-editable-content">
          <EditCommentForm
            form={form}
            formId={formId}
            initialValues={{
              anonymity: anonymity,
              comment: content
            }}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            handleSubmit={onEditionCommit}
            updateHasCommentChanged={updateHasContentChanged}
            updateHasAnonymitySwitchChanged={updateHasAnonymitySwitchChanged}
            updatingContent={updatingContent}
          />

          {updatingContentError &&
            <p className="discuss__comment-editable-content__error">
              {updatingContentError}
            </p>
          }
        </div>
      
      :

        <ExpandableText
          animationDurationSeconds={1}
          className="discuss__discuss-comment__content-text"
          content={content}
          trimmingThreshold={DISCUSS_COMMENT_TRIMMING_THRESHOLD}
        />
      }

      <ul className="list-unstyled discuss__comment-action-bar">
        <li className="discuss__comment-action-bar__entry">
          <button
            className={cx({
              'discuss__comment-action-bar__statistic': true,
              'discuss__comment-action-bar__statistic--active': isLiked,
              'discuss__comment-action-bar__statistic--animating': togglingLike,
            })}
            disabled={togglingLike}
            onClick={onLikesClick}
            type="button"
          >
            <FontAwesome className="discuss__comment-action-bar__icon" name="thumbs-up" />

            {likes}
          </button>
        </li>

        <div
          className={cx({
            'discuss__comment-action-bar__spacer': true,
            'discuss__comment-action-bar__spacer--expanded': editModeEnabled,
          })}
        />

        <li
          className={cx({
            'discuss__comment-action-bar__entry-special': true,
            'discuss__comment-action-bar__entry-special--collapsed': !hasCommentChanged && !hasAnonymitySwitchChanged,
            'discuss__comment-action-bar__entry-special--hidden': !editModeEnabled,
          })}
        >
          <button
            className="discuss__comment-action-bar__action"
            onClick={updateCommentContent}
            type="button"
          >
            <FontAwesome className="discuss__comment-action-bar__action-icon" name="check" />
            <FormattedMessage {...intlMessages.save} />
          </button>
        </li>

        {showEditAction &&
          <li className="discuss__comment-action-bar__entry">
            <button
              className={cx({
                'discuss__comment-action-bar__action': true,
                'discuss__comment-action-bar__action--transformed': editModeEnabled,
              })}
              onClick={handleEditionButton}
              type="button"
            >
              <span
                className={cx({
                  'discuss__comment-action-bar__action-text': true,
                  'discuss__comment-action-bar__action-text--visible': editModeEnabled,
                })}
              >
                <FontAwesome className="discuss__comment-action-bar__action-icon" name="times" />
                <FormattedMessage {...intlMessages.cancel} />
              </span>

              <span
                className={cx({
                  'discuss__comment-action-bar__action-text': true,
                  'discuss__comment-action-bar__action-text--visible': !editModeEnabled,
                })}
              >
                <FormattedMessage {...intlMessages.edit} />
              </span>
            </button>
          </li>
        }
      </ul>
    </>      
  )
}
