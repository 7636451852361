import {defineMessages} from 'react-intl';

export default defineMessages({
  accountSettings: {
    defaultMessage: 'Account settings',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.AccountSettings',
  },
  administration: {
    defaultMessage: 'Administration',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Administration',
  },
  administration2: {
    defaultMessage: 'Administration 2.0',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Administration2',
  },
  editors: {
    defaultMessage: 'Editors',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Editors',
  },
  evaluation: {
    defaultMessage: 'Evaluation',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Evaluation',
  },
  logout: {
    defaultMessage: 'Logout',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Logout',
  },
  profile: {
    defaultMessage: 'Profile',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Profile',
  },
  progress: {
    defaultMessage: 'Progress',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Progress',
  },
  recommend: {
    defaultMessage: 'Recommend U4I',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.Recommend',
  },
  redeemCoupon: {
    defaultMessage: 'Redeem coupon',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.RedeemCoupon',
  },
  darkMode: {
    defaultMessage: 'Dark mode',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.darkMode',
  },
  lightMode: {
    defaultMessage: 'Light mode',
    id: 'MainLayout.Navbar.UserMenu.EntriesList.lightMode',
  },
});
