import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './_title-heading.scss';

TitleHeading.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
};

function TitleHeading(props) {
  return (
    <h2
      className={cx({
        'player__title-heading': true,
        'player__title-heading--visible': props.isVisible,
      })}
    >
      <div className="player__title-heading__text">
        {props.title}
      </div>

      <div className="player__title-heading__decoration">
        <div className="player__title-heading__line" />
        <div className="player__title-heading__square" />
        <div className="player__title-heading__line" />
      </div>
    </h2>
  );
}

export default TitleHeading;
