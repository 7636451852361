import { makeObservable, computed, action, observable, toJS } from "mobx";
import readXlsxFile from "read-excel-file";
import Papa, { ParseResult } from "papaparse";
import xlsxParser from "xls-parser";
import { TablePaginationConfig } from "antd/lib/table";
import { IApi } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import {
  ImportUser,
  ImportUserValidation,
  UserImportApiData,
  IAdmin,
  ImportHistoryData,
} from "../components/UserImport/Interface";
import { MAX_RECORD_CAN_PARSE_ON_FE } from "@u4i/constantSettings";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";

export class AdminUserImportStore {
  private apiService: IApi;
  private rootStore: IRootStore;

  selectedGroups: Array<{ id: string; name: string }> = [];
  superAdminUsers: IAdmin[] = [];
  fileRawData: string[][] = [[]];
  importUserValidationData: ImportUserValidation[] = [];
  uploadedFileName: string = "";
  selectedMainUserGroup: string = "";
  isPreviewModalVisible: boolean = false;
  importFileHasError: boolean = false;
  isPreviewButtonDisable: boolean = true;
  isFileParsing: boolean = false;
  isLargeFile: boolean = false;
  isFormReset: boolean = false;
  apiDataToSend: UserImportApiData = {
    fileData: [],
    formData: {},
    fileName: "",
    file: undefined,
  };
  importFile: File;
  fileFormData: FormData;
  isUserLoading: boolean;
  uploadedFileType: string;
  isNewItemAddedInUserGroup: boolean = false;
  importHistoryData: ImportHistoryData[] = [];
  apiConfig: AntDTableFilters<ImportHistoryData> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {}
  };
  importHistoryPagination: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true,
  };
  isLoadingImportHistory: boolean = false;
  numberOfUsersInFile: number = 0;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      importFileHasError: observable,
      isPreviewButtonDisable: observable,
      importUserValidationData: observable,
      isPreviewModalVisible: observable,
      selectedGroups: observable,
      superAdminUsers: observable,
      uploadedFileName: observable,
      isFileParsing: observable,
      isLargeFile: observable,
      isUserLoading: observable,
      isFormReset: observable,
      isNewItemAddedInUserGroup: observable,
      importHistoryData: observable,
      isLoadingImportHistory: observable,
      importHistoryPagination: observable,
      tokenValidityDays: computed,
      downloadUserImportTemplate: action.bound,
      getAllSuperAdminUsers: action.bound,
      importUsers: action.bound,
      onChangeGroup: action.bound,
      onChangeMainUserGroup: action.bound,
      parseFileData: action.bound,
      hidePreviewPopup: action.bound,
      updateUserInformation: action.bound,
      getFile: action.bound,
      importUsersFromFile: action.bound,
      getFileFormData: action.bound,
      resetFormFlag: action.bound,
      importHistory: action.bound,
      onImportHistoryTableChange: action.bound,
    });

    const { apiService } = rootStore;

    this.apiService = apiService;
    this.rootStore = rootStore;
  }

  get tokenValidityDays() {
    let days: number[] = [];

    for (let i = 3; i <= 99; i = i + 3) {
      days.push(i);
    }
    return days;
  }

  resetFormFlag(value: boolean) {
    this.isFormReset = value;
  }

  getFileFormData(data: FormData) {
    this.fileFormData = data;
  }

  checkErrorForUserData(userData: ImportUserValidation) {
    let displayError: string[] = [];
    let specialCharFormat: RegExp = /[!@#$%^&*()_+"?|/\`~]/;

    if (
      userData.email &&
      typeof userData.email == "string" &&
      !this.isEmailValid(userData.email.trim())
    ) {
      displayError.push("Email is not valid");
    } else if (
      !userData.email ||
      userData.email == "" ||
      userData.email == null
    ) {
      displayError.push("Email is required");
    }

    if (
      userData.firstName &&
      typeof userData.firstName == "string" &&
      !isNaN(Number(userData.firstName))
    ) {
      displayError.push("First name should not be number only");
    } else if (
      userData.firstName &&
      typeof userData.firstName == "string" &&
      specialCharFormat.test(userData.firstName)
    ) {
      displayError.push("Special characters are not allowed in First name");
    } else if (
      !userData.firstName ||
      userData.firstName == "" ||
      userData.firstName == null
    ) {
      displayError.push("First name is required");
    }

    if (
      userData.lastName &&
      typeof userData.lastName == "string" &&
      specialCharFormat.test(userData.lastName)
    ) {
      displayError.push("Special characters are not allowed in Last name");
    } else if (
      !userData.lastName ||
      userData.lastName == "" ||
      userData.lastName == null
    ) {
      displayError.push("Last name is required");
    }
    return displayError;
  }

  isEmailValid(email: string) {
    const re: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   *
   * @param formValue : Data of the from fields
   *
   */
  async parseFileData(formValue) {
    this.isFileParsing = true;
    this.importUserValidationData = [];
    let getOldUserGroupsRequestParam: string[] = [];

    if (this.uploadedFileType === "xls") {
      if (this.fileRawData && !this.fileRawData.length) {
        Notification(NotificationEnum.Error, "User import", "Please upload CSV or excel file");
        this.isFileParsing = false;
        this.isPreviewButtonDisable = true;
        return;
      }
    } else {
      if (this.fileRawData[0] && !this.fileRawData[0].length) {
        Notification(NotificationEnum.Error, "User import", "Please upload CSV or excel file");
        this.isFileParsing = false;
        this.isPreviewButtonDisable = true;
        return;
      }
    }

    let dataLength = this.fileRawData?.length;
    let fileData: ImportUser[] = [];
    let emailSets = new Set();

    let emailTempKey: number | string = 0;
    let fNameTempKey: number | string = 1;
    let lNameTempKey: number | string = 2;
    let initialDataSetIndex: number = 1;
    if (this.uploadedFileType === "xls") {
      const firstObjectKeys = Object.keys(this.fileRawData[0]);
      emailTempKey = firstObjectKeys[0];
      fNameTempKey = firstObjectKeys[1];
      lNameTempKey = firstObjectKeys[2];
      initialDataSetIndex = 0;
    }

    for (let i = initialDataSetIndex; i < dataLength; i++) {
      let apiUserObj: ImportUser = {
        email: this.fileRawData[i][emailTempKey],
        firstName: this.fileRawData[i][fNameTempKey],
        lastName: this.fileRawData[i][lNameTempKey],
        id: i,
        error: [],
      };

      fileData.push(apiUserObj);

      let userValidationObj: ImportUserValidation = {
        email: this.fileRawData[i][emailTempKey],
        firstName: this.fileRawData[i][fNameTempKey],
        lastName: this.fileRawData[i][lNameTempKey],
        company: formValue.company,
        language: formValue.language == "en-US" ? "English (US)" : "Deutsch",
        role: formValue.role.label,
        userGroup: this.selectedGroups.concat(),
        mainUserGroup: this.selectedMainUserGroup,
        sendEmail: formValue.sendPasswordCreateEmail ? "Yes" : "No",
        status: formValue.status.label,
        tokenValidityDays: formValue.tokenValidityDays,
        id: i,
        error: [],
      };

      userValidationObj.error = this.checkErrorForUserData(userValidationObj);

      if (!this.importFileHasError && userValidationObj.error.length) {
        this.importFileHasError = true;
      }

      if (!emailSets.has(apiUserObj.email.trim())) {
        if (userValidationObj.error.length) {
          this.importUserValidationData.unshift(userValidationObj);
        } else {
          this.importUserValidationData.push(userValidationObj);
        }
        emailSets.add(apiUserObj.email.trim());
      }
      getOldUserGroupsRequestParam.push(this.fileRawData[i][emailTempKey]);
    }
    
    const response: any[] = await this.apiService.admin.userImport.getOldUserGroupByEmail(getOldUserGroupsRequestParam);

    this.importUserValidationData.map((data: ImportUserValidation) => {
      if (response[data.email]) {
        let initialUserGroups: any[] = [];
        response[data.email].forEach(user => {
          let userGroup = { id: user.id, name: user.name, isMain: false, isOldGroup: true };
          initialUserGroups.push(userGroup);
        });

        data.userGroup = initialUserGroups
          .concat(toJS(data.userGroup))
          .reduce((aggr, el) => {
            if (!aggr.find((inst) => inst.id == el.id)) {
              return [...aggr, el]
            } else {
              return aggr;
            }
          }, []);
      }
    });

    formValue["fileUsersCount"] = this.numberOfUsersInFile;
    formValue["sendPasswordCreateEmail"] = formValue.sendPasswordCreateEmail ? formValue.sendPasswordCreateEmail : false;

    this.apiDataToSend["fileData"] = fileData;
    this.apiDataToSend["formData"] = formValue;
    this.apiDataToSend["fileName"] = this.uploadedFileName;

    if (this.isLargeFile) {
      this.isPreviewModalVisible = false;
      await this.importUsers();
    } else {
      this.isPreviewModalVisible = true;
    }
    this.isFileParsing = false;
  }

  async onChangeGroup(groups: Array<string>) {
    if (this.selectedGroups.length > groups.length) {
      this.isNewItemAddedInUserGroup = false;
    } else {
      this.isNewItemAddedInUserGroup = true;
    }
    this.selectedGroups = [];

    groups.forEach((selectedGroup: string) => {
      this.rootStore.adminStore.adminUserDataStore.userGroups?.forEach(
        (userGroup) => {
          if (
            userGroup.id === selectedGroup &&
            !this.selectedGroups.includes(userGroup)
          ) {
            this.selectedGroups.push({
              id: userGroup.id,
              name: userGroup.name,
            });
          }
        }
      );
    });
  }

  async onChangeMainUserGroup(groupId: string) {
    this.selectedGroups.forEach((group) => {
      group.id == groupId
        ? (this.selectedMainUserGroup = group.name)
        : undefined;
    });
  }

  async importUsers() {
    this.isUserLoading = true;
    this.importUserValidationData.map(
      (item: ImportUserValidation, i: number) => {
        this.apiDataToSend.fileData[i].email = item.email;
        this.apiDataToSend.fileData[i].firstName = item.firstName;
        this.apiDataToSend.fileData[i].lastName = item.lastName;
        this.apiDataToSend.fileData[i].id = item.id;
      }
    );

    if (this.isLargeFile) {
      await this.importUsersFromFile();
      return false;
    } else {
      this.apiDataToSend.file = undefined;
    }

    try {
      const response = await this.apiService.admin.userImport.importUsers(
        this.apiDataToSend
      );
      if (response) {
        Notification(NotificationEnum.Success, "User import", "Bulk approval request created");
        this.isPreviewButtonDisable = true;
      } else {
        Notification(NotificationEnum.Error, "User import", response);
      }
    } catch (error) {
      Notification(NotificationEnum.Error, "User import", error.message);
    }
    await this.importHistory(this.apiConfig);
    this.hidePreviewPopup();
    this.isUserLoading = false;
    this.resetFormFlag(true);
    this.uploadedFileName = "";
  }

  async getAllSuperAdminUsers() {
    const admins = await this.apiService.admin.userImport.getAdmins();
    admins.forEach((admin) => {
      const adminObj = {
        name: `${admin.firstName} ${admin.lastName}`,
        email: admin.email,
        id: admin.id,
      };
      this.superAdminUsers.push(adminObj);
    });
  }

  async downloadUserImportTemplate() {
    this.apiService.admin.userImport.downloadTemplate();
  }

  async getFile(file: File) {
    this.importFile = file;
    this.fileRawData = [];
    this.uploadedFileType = file?.name?.split(".")[1].toLowerCase();

    if (this.uploadedFileType == "csv") {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results: ParseResult<string[]>) => {
          if (results.data.length <= 1) {
            Notification(NotificationEnum.Error, "User import", "File does not contains any user");
            this.isPreviewButtonDisable = true;
          } else {
            this.fileRawData = results.data;
            this.uploadedFileName = file.name;
            this.isPreviewButtonDisable = false;
            this.numberOfUsersInFile = results.data.length - 1;
            if (results.data.length >= MAX_RECORD_CAN_PARSE_ON_FE) {
              this.isLargeFile = true;
            } else {
              this.isLargeFile = false;
            }
          }
        },
        error: (error: Error) => {
          Notification(NotificationEnum.Error, "User import", error.message);
        },
      });
    } else if (
      this.uploadedFileType == "xlsm" ||
      this.uploadedFileType == "xlsx"
    ) {
      readXlsxFile(file).then((rows: string[][]) => {
        if (rows.length <= 1) {
          Notification(NotificationEnum.Error, "User import", "File does not contains any user");
          this.isPreviewButtonDisable = true;
        } else {
          this.fileRawData = rows;
          this.uploadedFileName = file.name;
          this.isPreviewButtonDisable = false;
          this.numberOfUsersInFile = rows.length - 1;
          if (rows.length >= MAX_RECORD_CAN_PARSE_ON_FE) {
            this.isLargeFile = true;
          } else {
            this.isLargeFile = false;
          }
        }
      });
    } else if (this.uploadedFileType == "xls") {
      xlsxParser.onFileSelection(file).then((data) => {
        const fileData: any = Object.values(data)[0];
        const fileDataLeng = fileData.length;
        if (fileDataLeng == 0) {
          Notification(NotificationEnum.Error, "User import", "File does not contains any user");
          this.isPreviewButtonDisable = true;
        } else {
          this.fileRawData = fileData;
          this.uploadedFileName = file.name;
          this.isPreviewButtonDisable = false;
          this.numberOfUsersInFile = fileDataLeng;
          if (fileDataLeng >= MAX_RECORD_CAN_PARSE_ON_FE) {
            this.isLargeFile = true;
          } else {
            this.isLargeFile = false;
          }
        }
      });
    } else {
      Notification(NotificationEnum.Error, "User import", "File type is not supported");
    }
  }

  hidePreviewPopup() {
    this.isPreviewModalVisible = false;
    this.importFileHasError = false;
  }

  async updateUserInformation(userData: ImportUserValidation) {
    let hasErrorFound: boolean = false;
    let lastObjectIndex: number = this.importUserValidationData.length;
    this.importUserValidationData = this.importUserValidationData.map(
      (item: ImportUserValidation, i: number) => {
        if (item.id == userData.id) {
          item.error = this.checkErrorForUserData(userData);
          item.email = userData.email;
          item.firstName = userData.firstName;
          item.lastName = userData.lastName;
        }

        if (item.error.length) {
          hasErrorFound = true;
        }
        if (lastObjectIndex === i + 1 && !hasErrorFound) {
          this.importFileHasError = false;
        }
        return item;
      }
    );
  }

  async importUsersFromFile() {
    this.fileFormData.append("fileName", this.importFile.name);
    this.fileFormData.append(
      "formData",
      JSON.stringify(this.apiDataToSend.formData)
    );

    try {
      const response =
        await this.apiService.admin.userImport.importUsersFromFile(
          this.fileFormData
        );
      if (response) {
        Notification(NotificationEnum.Success, "User import", "Bulk approval request created");
        this.isPreviewButtonDisable = true;
      } else {
        Notification(NotificationEnum.Error, "User import", response);
      }
    } catch (error) {
      Notification(NotificationEnum.Error, "User import", error.message);
    }
    this.resetFormFlag(true);
    this.uploadedFileName = "";
  }

  async onImportHistoryTableChange(
    pagination: AntDPagination, 
    filters: { [columnName in keyof ImportHistoryData]?: ImportHistoryData[columnName] }, 
    sorter: AntDSorter<ImportHistoryData>
  ) {
    const sortDirection: "ASC" | "DESC" = sorter.order === "descend" ? "DESC" : "ASC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        if (key == "purchaseDate" && filters[key]?.length) {
          setFilters["purchaseDateFrom"] = filters[key]?.[0][0];
          setFilters["purchaseDateTo"] = filters[key]?.[0][1];
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field] = sortDirection;
    }

    this.apiConfig = {
      ...this.apiConfig,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy,
    };

    this.importHistoryPagination.current = current;

    await this.importHistory(this.apiConfig);

    this.apiConfig = {
      ...this.apiConfig,
      filters: {},
    };
  }

  async importHistory(config: AntDTableFilters<ImportHistoryData>) {
    this.isLoadingImportHistory = true;
    this.apiConfig = config;
    this.importHistoryData = [];
    try {
      const { response, totalItems } = await this.apiService.admin.userImport.getImportHistory(this.apiConfig);
      this.importHistoryPagination.total = totalItems;
      this.importHistoryPagination.pageSize = this.apiConfig.limit;

      response.forEach((element, i: number) => {
        const tmepObj: ImportHistoryData = {
          id: element.id,
          srNumber: i + 1,
          requested_at: element.requestedAt,
          requested_by_firstname: `${element.requester.firstName} ${element.requester.lastName}`,
          users_main_group: element.formData.mainGroup.name,
          users_amount: element.formData.fileUsersCount,
          status: element.status.name,
          verified_by_firstname: element.verifier
        };
        this.importHistoryData.push(tmepObj);
      });
    } catch (error) {
      Notification(NotificationEnum.Error, "User import", error.message);
    } finally {
      this.isLoadingImportHistory = false;
    }
  }
}
