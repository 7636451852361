import qs from "qs";
import {
  action,
  computed,
  observable,
  runInAction,
  makeObservable,
} from "mobx";
import { isEmpty, isEqual } from "lodash";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { IAccessData } from "@u4i/parsers/AccessDataParser";
import { IUser } from "@u4i/parsers/UserParser";
import { VisitTracker } from "@u4i/modules/LandingPage/VisitTracker";
import { IApi, IStorage } from "./ServicesInterfaces";
import { IRootStore } from "./RootStore";
import { LanguageStore } from "./LanguageStore";
import { IFeedbackSubmission } from "@u4i/parsers/FeedbackSubmissionParser";
import { PLATFORM_ROUTES } from "@u4i/constantSettings";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import { RegistrationInfo } from "@u4i/common/Interfaces/RegistrationInterfaces";
import { CourseDetailPageStore } from "@u4i/modules/CourseDetailPage/state/CourseDetailPageStore";

class UserStore {
  accessData?: IAccessData;
  private apiService: IApi;
  public userService: any;
  public courseDetailPageStore: CourseDetailPageStore;
  public isLoading: boolean = true;
  public isLoginDisabled: boolean = false;
  private languageStore: LanguageStore;
  public recaptchaKey: string | null = null;
  public feedbackSubmissionDto: IFeedbackSubmission;
  private refreshInterval?: number;
  private storageService: IStorage;
  public userData: IUser = {} as IUser;
  public passwordRecoverySubmitted: boolean = false;
  public isLoginModalVisible: boolean = false;
  public isLoginSubmitting: boolean = false;
  public isLoginAndPurchase: boolean = false;
  public isPasswordRecoveryModalVisible: boolean = false;
  public isPurchaseCourseModalVisible: boolean = false;
  public isRegistrationModalVisible: boolean = false;
  public isRegistrationSuccessModalVisible: boolean = false;
  public isGeneralTermsOfServiceModalVisible: boolean = false;
  public isPrivacyPolicyModalOpen: boolean = false;
  public isGeneralModalOpen: boolean = false;
  public isUserLoggedIn: boolean = false;
  public termsOfServiceModalConfigs: any;
  public registrationEmail: string = "";
  public purchasingCourseId: string | any;
  public successRedirectionPathname: string;
  public arrayOfModals: Array<any> = [];
  public userAddressData: any;

  constructor(rootStore: IRootStore) {
    makeObservable<UserStore, "setupRefreshInterval">(this, {
      accessData: observable,
      acceptGeneralTermsOfService: action.bound,
      acceptUserGroupTermsOfService: action.bound,
      checkSavedAuthenticationData: action.bound,
      enableLogin: action.bound,
      feedbackSubmission: action.bound,
      fetchCurrentUserData: action.bound,
      generalTermsOfServiceModalHandle: action.bound,
      handlePasswordRecoveryFormSubmission: action.bound,
      handleLoginFormSubmission: action.bound,
      instantLogin: action.bound,
      isLoading: observable,
      arrayOfModals: observable,
      isLoginDisabled: observable,
      isAuthorized: computed,
      isInstantAuthorization: computed,
      isLoginSubmitting: observable,
      isLandingPage: computed,
      isLoginAndPurchase: observable,
      isLoginModalVisible: observable,
      isPasswordRecoveryModalVisible: observable,
      isPurchaseCourseModalVisible: observable,
      isRegistrationModalVisible: observable,
      isRegistrationSuccessModalVisible: observable,
      isGeneralTermsOfServiceModalVisible: observable,
      isPrivacyPolicyModalOpen: observable,
      isGeneralModalOpen: observable,
      isUserLoggedIn: observable,
      login: action.bound,
      loginModalHandle: action.bound,
      logout: action.bound,
      logoutAndWipeOnOutdatedToken: action.bound,
      logoutAndWipe: action.bound,
      passwordRecoveryModalHandle: action.bound,
      passwordRecoverySubmitted: observable,
      purchasingCourseId: observable,
      recaptchaKey: observable,
      registrationEmail: observable,
      registrationModalHandle: action.bound,
      registrationSuccessModalHandle: action.bound,
      resendUserInvitation: action.bound,
      setupRefreshInterval: action.bound,
      successRedirectionPathname: observable,
      userData: observable,
      userAddressData: observable
    });

    const { apiService, languageStore, storageService, courseDetailPageStore } = rootStore;
    this.apiService = apiService;
    const { user } = apiService;
    this.userService = user;
    this.languageStore = languageStore;
    this.storageService = storageService;
    this.courseDetailPageStore = courseDetailPageStore;
  }

  get isAuthorized(): boolean {
    return !isEmpty(this.userData);
  }

  get isInstantAuthorization(): boolean {
    const location = AppNavigator.history.location;
    return new RegExp("/instant-authorization/").test(location.pathname);
  }

  get isLandingPage(): boolean {
    const location = AppNavigator.history.location;
    const routes = PLATFORM_ROUTES;
    return !new RegExp(routes.join("|")).test(location.pathname);
  }

  acceptGeneralTermsOfService = async () => {
    return await this.apiService.user.acceptGeneralTermsOfService();
  };

  closePolicyModal = () => {
    this.isPrivacyPolicyModalOpen = false;
  }

  closeGeneralModal = () => {
    this.isGeneralModalOpen = false;
  }

  acceptUserGroupTermsOfService = async (isAccepted: boolean, tosId: string) => {
    return await this.apiService.user.acceptUserGroupTermsOfService( isAccepted, tosId);
  };

  cancelImpersonation = async () => {
    try {
      const redirectUrl: string = await this.apiService.user.cancelImpersonation();

      window.location.replace(redirectUrl);
    } catch (error) {
      alert("Error: could not switch user back, please log out instead");
    }
  };

  changeLanguage = async (newLocale: LanguageEnum) => {
    if (this.isAuthorized) {
      await this.apiService.user.changeLanguage(newLocale);
    }

    this.languageStore.applyLanguageChange(newLocale);
  };

  checkSavedAuthenticationData = async () => {
    // should be private but right now, for this fix purpose, it will be public
    const savedData = this.storageService.accessData.get();

    if (!savedData) {
      this.isLoading = false;
      return;
    }

    this.accessData = savedData;

    try {
      const tokenRequest: Promise<IAccessData> = this.apiService.user.refreshToken(savedData.token);
      const userRequest: Promise<IUser> = this.apiService.user.fetchData();

      const apiCallsResults: [IAccessData, IUser] = await Promise.all([tokenRequest, userRequest]);

      this.handleLogin(apiCallsResults[0], apiCallsResults[1]);
    } catch (error) {
      if (error && error.response !== undefined) {
        this.logoutAndWipeOnOutdatedToken();
      }
    }

    this.isLoading = false;
    this.setupRefreshInterval();
  };

  confirmRegistration = async (token: string) => {
    try {
      const loginData = await this.apiService.user.confirmRegistration(token);
      this.handleLogin(loginData.accessData, loginData.user);

      AppNavigator.replace(appRoutes.registerSuccessPage);

      return new Promise(() => {});
    } catch (error) {
      throw error;
    }
  };

  enableLogin = () => {
    this.isLoginDisabled = false;
  };

  private handleLogin = async (accessData: IAccessData, userData: IUser) => {
    this.storageService.accessData.set(accessData);
    this.storageService.homepage.set(userData.homepage);

    const lastViewedData = this.storageService.lastViewedData.get();

    if (!lastViewedData) {
      this.storageService.lastViewedData.set({ userId: userData.id });
    }

    this.accessData = accessData;
    this.userData = userData;

    if (!this.userData.impersonated) {
      const termsOfServiceAccepted = this.storageService.termsOfServiceAccepted.get();

      this.userData.termsOfService.requiredGroupTerms.forEach(
        (entry, index) => {
          const currentEntry = {
            checkboxLabel: entry?.checkboxLabel,
            content: entry?.content,
            description: entry?.description,
            isMandatory: entry?.isMandatory,
          };
          const currentTermsOfServiceAccepted = {
            checkboxLabel: termsOfServiceAccepted?.checkboxLabel,
            content: termsOfServiceAccepted?.content,
            description: termsOfServiceAccepted?.description,
            isMandatory: termsOfServiceAccepted?.isMandatory,
          };

          const isTermsOfServiceChanged: boolean = !isEqual(currentEntry, currentTermsOfServiceAccepted);

          if (isTermsOfServiceChanged) {
            let termsOfServiceModalConfigs = {
              content: entry.content,
              enableAcceptanceInModal: true,
              tosId: entry.id,
            };

            this.termsOfServiceModalConfigs = termsOfServiceModalConfigs;

            this.storageService.termsOfServiceAccepted.set(entry);

            this.arrayOfModals.push({
              termsOfServiceModalConfigs: termsOfServiceModalConfigs,
              id: index
            });
          }
        }
      );

      if (this.arrayOfModals.length > 0) {
        this.isPrivacyPolicyModalOpen = true;
      }

      if (!this.userData.termsOfService.generalTermsAccepted) {
        this.isGeneralModalOpen = true;
      }

      if (this.isPrivacyPolicyModalOpen || this.isGeneralModalOpen) {
        this.generalTermsOfServiceModalHandle(true);
      }
    }

    await this.changeLanguage(this.userData.language);
    this.setupRefreshInterval();
  };

  feedbackSubmission = async (skillId: string) => {
    try {
      const feedbackSubmission: IFeedbackSubmission = await this.apiService.user.feedbackSubmission(skillId);

      runInAction(() => {
        this.feedbackSubmissionDto = feedbackSubmission;
      });
    } catch (error) {
      throw error;
    }
  };

  fetchCurrentUserData = async () => {
    try {
      const userData: IUser = await this.apiService.user.fetchData();

      runInAction(() => {
        this.userData = userData;
        this.userAddressData = userData.userAddressData;
      });
    } catch (error) {
      throw error;
    }
  };

  instantLogin = async (loginToken: string) => {
    const loginData = await this.apiService.user.instantLogin(loginToken);

    this.handleLogin(loginData.accessData, loginData.user);
  };

  login = async (email: string, password: string, recaptcha?: string) => {
    try {
      const loginData = await this.apiService.user.login(email, password,recaptcha);
      
      if(loginData)
        this.userAddressData = loginData.user.userAddressData;

      this.handleLogin(loginData.accessData, loginData.user);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.needCaptcha
      ) {
        runInAction(() => {
          this.recaptchaKey = error.response.data.captchaPublicKey;
        });
      }
      throw error;
    }
  };

  loginModalHandle = (open: boolean = true, successRedirectionPathname?: string, isLoginAndPurchase: boolean = false, courseId?: string) => {
    this.isLoginAndPurchase = isLoginAndPurchase;
    courseId ? this.purchasingCourseId = courseId : this.purchasingCourseId = undefined;
    
    if (open) {
      this.isLoginModalVisible = true;
      this.isLoginSubmitting = false;
      if (successRedirectionPathname) {
        this.successRedirectionPathname = successRedirectionPathname;
      }
    } else {
      this.isLoginModalVisible = false;
      if(!isLoginAndPurchase) {
        this.successRedirectionPathname = "";
      }
    }
  };

  generalTermsOfServiceModalHandle = (open: boolean = false) => {
    open ? this.isGeneralTermsOfServiceModalVisible = true : this.isGeneralTermsOfServiceModalVisible = false;
  };

  handleLoginFormSubmission = async (data, pathname: string, successRedirectionPathname?: string) => {
    this.isLoginSubmitting = true;
    await this.login(data.email, data.password, data.recaptchaResponse);
    if(this.isLoginAndPurchase) {
      await this.courseDetailPageStore.loadCourseData(this.successRedirectionPathname);
    }

    runInAction(() => {
      this.isLoginSubmitting = false;

      if(this.isLoginAndPurchase && this.successRedirectionPathname != "") {
        this.isUserLoggedIn = true;
        this.loginModalHandle(false, this.successRedirectionPathname, this.isLoginAndPurchase, this.purchasingCourseId);

        if(!this.courseDetailPageStore?.courseData.is_bought && !this.courseDetailPageStore?.courseData.is_free) {
          this.purchaseCourseModalHandle(true);
        }
      } else {
        this.isLoginModalVisible = false;
      }

      if (["/login", "/register"].includes(pathname)) {
        AppNavigator.directPush("/");
      } else if (successRedirectionPathname) {
        AppNavigator.directPush(successRedirectionPathname);
      } else if (this.successRedirectionPathname != "") {
        AppNavigator.directPush(this.successRedirectionPathname);
      }
    });
  };

  handlePasswordRecoveryFormSubmission = async (data) => {
    await this.apiService.user.recoverPassword(data.email);

    runInAction(() => {
      this.passwordRecoverySubmitted = true;
    });
  };

  passwordRecoveryModalHandle = (open: boolean = false) => {
    if (open) {
      this.isPasswordRecoveryModalVisible = true;
      this.passwordRecoverySubmitted = false;
    } else {
      this.isPasswordRecoveryModalVisible = false;
    }
  };

  purchaseCourseModalHandle = (open: boolean = true, courseId?: string) => {
    courseId ? this.purchasingCourseId = courseId : this.purchasingCourseId = undefined;
    if (open) {
      if (courseId) {
        this.successRedirectionPathname = courseId;
      }
      this.isPurchaseCourseModalVisible = true;
    } else {
      this.isPurchaseCourseModalVisible = false;
      this.successRedirectionPathname = '';
    }
  }

  registrationModalHandle = (open: boolean = false) => {
    if(!open) {
      this.isLoginAndPurchase = false;
      this.successRedirectionPathname = "";
    }
    open ? this.isRegistrationModalVisible = true : this.isRegistrationModalVisible = false;
  };

  registrationSuccessModalHandle = (open: boolean = false) => {
    open ? this.isRegistrationSuccessModalVisible = true : this.isRegistrationSuccessModalVisible = false;

    if (!open) {
      this.registrationEmail = "";
    }
  };

  logout = async () => {
    if (!this.isAuthorized) {
      return;
    }
    await this.apiService.user.logout();
    this.logoutAndWipe();
  };

  resetUserAddressData = () => {
    this.userAddressData = undefined;
  }

  register = async (registrationData: RegistrationInfo, redirectUrl: string = '') => {
    let landingPageRefSequence: string = VisitTracker.getVisitList();
    if(redirectUrl.startsWith('/player') || redirectUrl.startsWith('/skills')) {
      landingPageRefSequence = redirectUrl;
    } else if(redirectUrl.startsWith('/') && redirectUrl.substring(1) == landingPageRefSequence) {
      landingPageRefSequence = landingPageRefSequence;
    } else if(redirectUrl != '') {
      landingPageRefSequence = redirectUrl;
    }

    await this.apiService.user.register(registrationData, landingPageRefSequence);

    runInAction(() => {
      VisitTracker.reset();

      this.registrationEmail = registrationData.email;
      this.registrationModalHandle(false);
      this.registrationSuccessModalHandle(true);
    });
  };

  resendUserInvitation = async (email: string) => {
    await this.apiService.user.resendInvitation(email);
  };

  resetUserPassword = async (
    guid: string,
    token: string,
    newPassword: string,
    newPasswordConfirm: string
  ) => {
    const redirectUrl: string = await this.apiService.user.resetPassword(
      guid,
      token,
      newPassword,
      newPasswordConfirm
    );

    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 3000);
  };

  private setupRefreshInterval = async () => {
    this.refreshInterval = window.setInterval(async () => {
      if (!this.accessData) {
        return;
      }

      try {
        const newAccessData: IAccessData = await this.apiService.user.refreshToken(this.accessData.token);

        this.accessData = newAccessData;
      } catch (error) {
        if (error && error.response !== undefined) {
          this.logoutAndWipeOnOutdatedToken();
        }
      }
    }, 12 * 60 * 60 * 1000);
  };

  logoutAndWipeOnOutdatedToken = () => {
    this.isLoading = true;
    this.accessData = undefined;
    this.userData = {} as IUser;
    this.storageService.accessData.empty();

    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
    VisitTracker.reset();

    const location = AppNavigator.history.location;
    const { scormSkillId, auth_mode } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const lastViewedData = this.storageService.lastViewedData.get();

    if (lastViewedData && lastViewedData.userId) {
      this.storageService.lastViewedData.set({
        ...lastViewedData,
        location: location,
      });
    }

    // don't redirect if user is already on the login page or on one of the scorm pages or the landing page or instant authorization page
    if (
      location.pathname !== "/login" &&
      !scormSkillId &&
      !auth_mode &&
      !this.isLandingPage &&
      !this.isInstantAuthorization
    ) {
      AppNavigator.replace(appRoutes.home);
    }

    this.isLoading = false;
  };

  logoutAndWipe = () => {
    this.isLoading = true;
    this.isUserLoggedIn = false;
    this.accessData = undefined;
    this.userData = {} as IUser;
    this.storageService.accessData.empty();
    this.storageService.lastViewedData.empty();

    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
    VisitTracker.reset();

    AppNavigator.replace(appRoutes.home);

    this.isLoading = false;
  };
}

export default UserStore;
