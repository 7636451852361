import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { MySkillsFiltersEnum } from "@u4i/common/enums/MySkillsFiltersEnum";
import intlMessages from "./intlMessages";
import { Tabs } from "antd";
import "./_filter-buttons.scss";

interface IProps {
  skillsCompletedPresent: boolean
  skillsUnfinishedPresent: boolean
  onFilterSkills: (newFilter: MySkillsFiltersEnum) => void
}

export const FilterButtons: FC<IProps> = (props: IProps) => {
  const {
    skillsCompletedPresent,
    skillsUnfinishedPresent,
    onFilterSkills
  } = props;

  const handleShowAll = () => {
    onFilterSkills(MySkillsFiltersEnum.ALL);
  };

  const handleShowCompleted = () => {
    onFilterSkills(MySkillsFiltersEnum.COMPLETED);
  };

  const handleShowUnfinished = () => {
    onFilterSkills(MySkillsFiltersEnum.UNFINISHED);
  };

  const handleTabChange = (key: MySkillsFiltersEnum) => {
    switch (key) {
      case MySkillsFiltersEnum.ALL:
        handleShowAll();
        break;
      case MySkillsFiltersEnum.COMPLETED:
        handleShowCompleted();
        break;
      case MySkillsFiltersEnum.UNFINISHED:
        handleShowUnfinished();
        break;
      default:
        break;
    }
  };

  return (
    <div className="landing-page__filter-buttons">
      <Tabs 
        className="disable-invert"
        items={[
          {
            label: (<FormattedMessage {...intlMessages.skillsFilterAllButton} />),
            key: MySkillsFiltersEnum.ALL
          },
          { 
            disabled: !skillsCompletedPresent,
            label: (<FormattedMessage {...intlMessages.skillsFilterCompleteButton} />),
            key: MySkillsFiltersEnum.COMPLETED
          },
          {
            disabled: !skillsUnfinishedPresent,
            label: ( <FormattedMessage {...intlMessages.skillsFilterUnfinishedButton}/>),
            key: MySkillsFiltersEnum.UNFINISHED
          }
        ]}
        onChange={handleTabChange}
        size="large" 
        type="card" 
      >
      </Tabs>
    </div>
  )
}
