import cx from 'classnames';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {action, observable,makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import intlMessages from './intlMessages';
import { mainBlue } from '@u4i/styles/styles-variables';

import './_action-button.scss';

interface IPropTypes {
  confirmationRequired?: boolean;
  icon: JSX.Element;
  label: JSX.Element | string;
  onClick: () => void;
}

export const ActionButton = observer(class ActionButton extends Component<IPropTypes> {
  static defaultProps: Partial<IPropTypes> = {
    confirmationRequired: false,
  };

  private showConfirmationDialog = false;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<ActionButton, "showConfirmationDialog">(this, {
      showConfirmationDialog: observable,
      closeConfirmationDialog: action.bound,
      handleClick: action.bound
    });
  }

  closeConfirmationDialog() {
    this.showConfirmationDialog = false;
  }

  handleClick() {
    if (this.props.confirmationRequired) {
      this.showConfirmationDialog = true;
    } else {
      this.props.onClick();
    }
  }

  handleConfirmation = () => {
    this.closeConfirmationDialog();
    this.props.onClick();
  };

  render() {
    return (
      <div className="jupyter-lab__action-button">
        <button
          className={cx({
            'jupyter-lab__action-button__button': true,
            'jupyter-lab__action-button__button--active': this.showConfirmationDialog,
          })}
          onClick={this.handleClick}
        >
          {React.cloneElement(this.props.icon, {style: {color: mainBlue, height: 25}})}

          <span className="jupyter-lab__action-button__label">
            {this.props.label}
          </span>
        </button>

        <div
          className={cx({
            'jupyter-lab__action-button__confirmation': true,
            'jupyter-lab__action-button__confirmation--visible':
              this.showConfirmationDialog,
          })}
        >
          <p className="jupyter-lab__action-button__confirmation-label">
            <FormattedMessage {...intlMessages.confirmationMessage} />
          </p>

          <div className="jupyter-lab__action-button__confirmation-buttons">
            <button
              className="jupyter-lab__action-button__confirmation-accept"
              onClick={this.handleConfirmation}
              type="button"
            >
              <FormattedMessage {...intlMessages.confirm} />
            </button>

            <button
              className="jupyter-lab__action-button__confirmation-cancel"
              onClick={this.closeConfirmationDialog}
              type="button"
            >
              <FormattedMessage {...intlMessages.cancel} />
            </button>
          </div>
        </div>
      </div>
    );
  }
});
