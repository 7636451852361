import qs from 'qs';
import React, { Component } from 'react';
// import {configureAnchors} from 'react-scrollable-anchor';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router-dom';
import { RouteComponentProps } from "@reach/router";
import CircularProgress from '@u4i/common/CircularProgress';
import UserStore from '@u4i/state/UserStore';
import {IRootStore} from '@u4i/state/RootStore';
import {AccessBlock} from './components/AccessBlock';
import {Header} from './components/Header';
import {LandingPageStore} from './state/LandingPageStore';
import {Navigation} from './components/Navigation';
import {PreviewBar} from './components/PreviewBar';
import {remountOnRouteParamChange} from '@u4i/common/remountOnRouteParamChange';
import {SectionDisplayer} from './components/SectionDisplayer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import './_landing-page.scss';

interface IMatchParams extends RouteComponentProps {
  match: any;
  slug: string;
}

interface IPropTypes extends IMatchParams {
  rootStore: IRootStore;
}

export const LandingPage = remountOnRouteParamChange('slug')(withRouter(inject('rootStore')(
  (observer(class LandingPage extends Component<IPropTypes | any> {
    private landingPageStore: LandingPageStore;
    private userStore: UserStore;

    constructor(props: IPropTypes) {
      super(props);

      const {landingPageStore, userStore} = props.rootStore;

      this.landingPageStore = landingPageStore;
      this.userStore = userStore;
    }

    componentDidMount() {
      const parsedQuery = qs.parse(this.props.location?.search, {ignoreQueryPrefix: true});
      const enablePreviewMode = parsedQuery.preview === 'true';

      this.landingPageStore.init(this.props.match.params.slug, enablePreviewMode);
    }

    componentWillUnmount() {
      this.landingPageStore.reset();
    }

    handleLoginFormSubmission = async (data) => {
      await this.userStore.login(data.email, data.password, data.recaptchaResponse);
    };

    render() {
      if (this.landingPageStore.errorCode === 404) {
        return <NotFoundPage />;
      }

      if (this.landingPageStore.fetching) {
        return <CircularProgress />;
      }

      const {pageData} = this.landingPageStore;

      const {passwordRecoveryModalHandle}= this.userStore;

      if (!pageData) {
        return null;
      }

      return (
        <div className="landing-page">
          {this.landingPageStore.previewModeEnabled && <PreviewBar />}

          {pageData.headerBackgroundUrl &&
            <Header
              description={pageData.privateMode ? undefined : pageData.description}
              headerBackgroundUrl={pageData.headerBackgroundUrl}
              logoUrl={pageData.logoUrl}
              mobileImageUrl={pageData.mobileImageUrl}
              subtitle={pageData.subtitle}
              title={pageData.title}
            />
          }

          <main>
            {(this.landingPageStore.sections.length > 0 && !this.landingPageStore.isPrivateModeEnabled)  &&
              <Navigation
                displayLanguageFilter={pageData.isLanguageFilterVisible}
                itemsLanguage={this.landingPageStore.itemsLanguage}
                onLanguageChange={this.landingPageStore.changeItemsLanguage}
                sections={this.landingPageStore.sections}
              />
            }

            <SectionDisplayer sections={this.landingPageStore.sections} />
          </main>

          {this.landingPageStore.isPrivateModeEnabled &&
            <AccessBlock
              isAuthorized={this.userStore.isAuthorized}
              onForgotPasswordClick={() => passwordRecoveryModalHandle(true)}
              onSubmitLoginForm={this.handleLoginFormSubmission}
            />
          }
        </div>
      );
    }
  }))
)));
