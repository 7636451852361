import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React, { FC, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import DiscussAnswerEntity from '@u4i/state/entities/DiscussAnswerEntity';
import AnswerLikesModal from '@u4i/modules/Modals/AnswerLikesModal';
import AnswerCommentsModal from '@u4i/modules/Modals/AnswerCommentsModal/AnswerCommentsModal';
import './_answer-statistics.scss';

interface IProps {
  entity: DiscussAnswerEntity
  showAnonymitySettingsSwitch: boolean
  onCommentsClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onLikesClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const AnswerStatistics: FC<IProps> = (props: IProps) => {
  const {
    entity,
    showAnonymitySettingsSwitch,
    onCommentsClick,
    onLikesClick
  } = props;

  const [isLikesModalVisible, setIsLikesModalVisible] = useState<boolean>(false);
  const [isCommentsModalVisible, setIsCommentsModalVisible] = useState<boolean>(false);

  const onLikesButtonClick = (event) => {
    onLikesClick(event);
    setIsLikesModalVisible(true);
  }

  const handleLikesModalClose = (): void => {
    setIsLikesModalVisible(false);
  }

  const onCommentsButtonClick = (event) => {
    onCommentsClick(event);
    setIsCommentsModalVisible(true);
  }

  const handleCommentsModalClose = (): void => {
    setIsCommentsModalVisible(false);
  }

  return (
    <div className="discuss-page__answer-statistics">
      <div
        className={cx({
          'discuss-page__answer-statistics__entry': true,
          'discuss-page__answer-statistics__entry--enabled': entity.likeCount > 0,
        })}
      >
        <div className="discuss-page__answer-statistics__icon">
          <FontAwesome name="thumbs-up" />
        </div>

        <button
          className="discuss-page__answer-statistics__value"
          data-for="likes-tooltip"
          data-tip
          disabled={entity.likeCount === 0}
          onClick={onLikesButtonClick}
          type="button"
        >
          {entity.likeCount}
        </button>

        {isLikesModalVisible && 
          <AnswerLikesModal
            likes={entity?.likes}
            isVisible={isLikesModalVisible}
            handleModalClose={handleLikesModalClose}
          >
          </AnswerLikesModal>
        }

        <ReactTooltip effect="float" id="likes-tooltip" place="top" type="dark">
          <FormattedMessage {...intlMessages.likesTooltipMessage} />
        </ReactTooltip>
      </div>

      <div
        className={cx({
          'discuss-page__answer-statistics__entry': true,
          'discuss-page__answer-statistics__entry--enabled': entity.commentCount > 0,
        })}
      >
        <div className="discuss-page__answer-statistics__icon">
          <FontAwesome name="comments" />
        </div>

        <button
          className="discuss-page__answer-statistics__value"
          data-for="comments-tooltip"
          data-tip
          disabled={entity.commentCount === 0}
          onClick={onCommentsButtonClick}
          type="button"
        >
          {entity.commentCount}
        </button>

        {isCommentsModalVisible && 
          <AnswerCommentsModal
            comments={entity?.comments}
            formId={'commentsModal'}
            isVisible={isCommentsModalVisible}
            showAnonymitySettingsSwitch={showAnonymitySettingsSwitch}
            closeCurrentModal={handleCommentsModalClose}
          >
          </AnswerCommentsModal>
        }

        <ReactTooltip effect="float" id="comments-tooltip" place="top" type="dark">
          <FormattedMessage {...intlMessages.commentsTooltipMessage} />
        </ReactTooltip>
      </div>
    </div>
  )
};
