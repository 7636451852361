import {defineMessages} from 'react-intl';

export default defineMessages({
  login: {
    defaultMessage: 'Login',
    id: 'MainLayout.Navbar.AccessButtons.Login',
  },
  register: {
    defaultMessage: 'Register',
    id: 'MainLayout.Navbar.AccessButtons.Register',
  },
});
