import {defineMessages} from 'react-intl';

export default defineMessages({
  archived: {
    defaultMessage: 'Archived',
    id: 'DiscussPage.AnswersSection.UserAnswer.AnswerStatus.Archived',
  },
  failed: {
    defaultMessage: 'Not passed',
    id: 'DiscussPage.AnswersSection.UserAnswer.AnswerStatus.Failed',
  },
  passed: {
    defaultMessage: 'Passed',
    id: 'DiscussPage.AnswersSection.UserAnswer.AnswerStatus.Passed',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'DiscussPage.AnswersSection.UserAnswer.AnswerStatus.Pending',
  },
});
