import {action, observable, runInAction,makeObservable} from 'mobx';

import {IApi} from '@u4i/state/ServicesInterfaces';
import {IChallengeAttempt} from '@u4i/parsers/ChallengeAttemptParser';
import {IRootStore} from '@u4i/state/RootStore';

export class GradingEditStore {
  private apiService: IApi;
  private attemptId?: string;
  errorLoadingAttemptData = false;
  fetching = true;
  attemptData?: IChallengeAttempt;

  constructor(RootStore: IRootStore) {
    makeObservable(this, {
      errorLoadingAttemptData: observable,
      fetching: observable,
      attemptData: observable,
      init: action.bound,
      loadAttemptData: action.bound,
      reset: action.bound
    });

    const {apiService} = RootStore;

    this.apiService = apiService;
  }

  async init(attemptId: string) {
    this.attemptId = attemptId;

    await this.loadAttemptData();
  }

  async loadAttemptData() {
    if (this.attemptId) {
      this.fetching = true;

      try {
        const attemptData = await this.apiService.grading.fetchAttempt(this.attemptId);

        runInAction(() => {
          this.attemptData = attemptData;
        });
      } catch(error) {
        this.errorLoadingAttemptData = true;
      } finally {
        this.fetching = false;
      }
    }
  }

  reset() {
    this.attemptData = undefined;
    this.attemptId = undefined;
    this.errorLoadingAttemptData = false;
    this.fetching = true;
  }
}
