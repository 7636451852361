import {defineMessages} from 'react-intl';

export default defineMessages({
  loading: {
    defaultMessage: 'Loading...',
    id: 'Player.ElementsDisplay.SummaryElement.PDFDownloadButton.Loading'
  },
  text: {
    defaultMessage: 'Download PDF summary',
    id: 'Player.ElementsDisplay.SummaryElement.PDFDownloadButton.Text',
  },
});
