import React from "react";

const sliceText = (text, length) => {
  if (text) {
    if (text.length > length) return text.slice(0, length) + "...";
    return text;
  }
  return text;
};

const ellipses = (str: string, len: number = 45): string => {
  str = str.substring(str.lastIndexOf("/") + 1);
  if (str.length > len)
    str = "..."+str.slice(-(len-3));
  return str;
}

const convertToByte = (x) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export { sliceText, convertToByte, ellipses };

//chatbot helpers
export function parseTextForLinks(text: string) {
  const parts = text.split(/(\bhttps?:\/\/\S+\b)/g);
  return parts.map((part: any, index: number) => {
    // Test if part is a URL
    if (/\bhttps?:\/\/\S+\b/.test(part)) {
      return (
        <a
          href={part}
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          className="link-highlight"
        >
          {part}
        </a>
      );
    } else {
      // For non-URL parts, wrap with a span to preserve text structure
      // This is important to ensure spaces around parts are not collapsed
      return <span key={index}>{part}</span>;
    }
  });
}

export const processMessageContent = (text) => {
  if (!text) {
    return ""; // Return an empty string if text is null or undefined
  }
  // Process the text as needed
  return text.split(" ").map((word, index) => <span key={index}>{word} </span>);
};

export const formatText = (input: string) => {
  // Remove leading and trailing whitespace
  let formatted = input.trim();

  // Replace multiple spaces or new lines with a single space
  formatted = formatted.replace(/\s+/g, " ");

  // Handle special cases, e.g., replace \n\n with actual new lines
  formatted = formatted.replace(/\\n\\n/g, "\n\n");

  return formatted;
};
