import React, { FC } from 'react';
import {observer} from 'mobx-react';
import {BasicInfoWrapper} from '../common/BasicInfoWrapper';
import {NoItemsMessage} from '../common/NoItemsMessage';
import {PartnerInfoEntry} from './PartnerInfoEntry';
import {PartnerInfoSection} from './PartnerInfoSection';
import MasonryGrid from '@u4i/common/MasonryGrid/MasonryGrid';

interface IPropTypes {
  sectionModel: PartnerInfoSection;
}

const VisualComponent: FC<IPropTypes> = (props) => {
  const {sectionModel} = props;

  return (
    <BasicInfoWrapper
      isLoading={sectionModel.fetching}
      itemsCountLabel={sectionModel.items.length}
      sectionModel={sectionModel}
    >
      {sectionModel.items.length > 0 ?
        <MasonryGrid itemWidth={372}>
          {sectionModel.items.map(entry => (
            <div key={btoa(entry.title)} style={{width: 372}}>
              <PartnerInfoEntry
                beforeActionText={entry.beforeActionText}
                buttonAction={entry.buttonAction}
                buttonText={entry.buttonText}
                description={entry.description}
                title={entry.title}
              />
            </div>
          ))}
        </MasonryGrid>
        :
        <NoItemsMessage />
      }
    </BasicInfoWrapper>
  );
};

const WrappedComponent = observer(VisualComponent);

export {WrappedComponent as VisualComponent};
