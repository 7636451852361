import cx from "classnames";
import React, { FC, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "@reach/router";
import withUser from "@u4i/modules/UserManager/withUser";
import { IRootStore } from "@u4i/state/RootStore";
import AccessButtons from "./components/AccessButtons";
import LoginModal from "@u4i/modules/Modals/LoginModal/LoginModal";
import PasswordRecoveryModal from "@u4i/modules/Modals/PasswordRecoveryModal/PasswordRecoveryModal";
import RegistrationModal from "@u4i/modules/Modals/RegistrationModal/RegistrationModal";
import RegistrationSuccessModal from "@u4i/modules/Modals/RegistrationSuccessModal/RegistrationSuccessModal";
import GeneralTermsOfServiceModal from "@u4i/modules/Modals/GeneralTermsOfServiceModal/GeneralTermsOfServiceModal";
import NotificationIndicator from "./components/NotificationIndicator/NotificationIndicator";
import NavbarBase from "./components/NavbarBase/NavbarBase";
import PurchaseCourseModal from "@u4i/modules/Modals/PurchaseCourseModal/PurchaseCourseModal";
import "./_navbar.scss";

interface IProps extends RouteComponentProps {
  isAuthorized: boolean
  rootStore: IRootStore
}

const Navbar: FC<IProps> = inject("rootStore")(
  observer((props: IProps) => {
    const {
      checkoutPurchaseStore,
      courseDetailPageStore,
      languageStore,
      notificationsStore,
      redeemPageStore,
      scormProxyService,
      settingsStore,
      userStore
    } = props.rootStore;

    const {
      isLoading, 
      isLoginModalVisible, 
      isPasswordRecoveryModalVisible, 
      isPurchaseCourseModalVisible,
      isRegistrationModalVisible,
      isRegistrationSuccessModalVisible,
      isGeneralTermsOfServiceModalVisible,
      userData,
      loginModalHandle,
      passwordRecoveryModalHandle,
      registrationModalHandle
    } = props.rootStore.userStore;

    const [controlsVisibleMobile, setcontrolsVisibleMobile] = useState<boolean>(false)

    useEffect(() => {
      if (userData.impersonated && !notificationsStore.countUpdatedImpersonatedUser) {
        notificationsStore.updateUnreadCount();
      }
    }, []);
    
    const isRegisterConfirmationPage: boolean | undefined = props.location?.pathname.includes("register-confirm");

    const toggleControlsMobile = () => {
      setcontrolsVisibleMobile(!controlsVisibleMobile);
    }
    
    const loginModalAction = () => {
      loginModalHandle(true);
      registrationModalHandle(false);
      passwordRecoveryModalHandle(false);
    }

    const registrationModalAction = () => {
      registrationModalHandle(true);
      loginModalHandle(false);
      passwordRecoveryModalHandle(false);
    }
    
    return (
      <nav
        className={cx({
          "main-layout__navbar": true,
          "main-layout__navbar--with-notifications": !isLoading && props.isAuthorized,
        })}
      >
        <NavbarBase
          controlsVisibleMobile={controlsVisibleMobile}
          handleControlsMobileClick={toggleControlsMobile}
          languageStore={languageStore}
          showUserMenu={!scormProxyService.isEnabled}
          settingsStore={settingsStore}
          useLinkInLogo={!scormProxyService.isEnabled}
          userStore={userStore}
        />

        {!userStore.isLoading && (
          <div
            className={cx({
              "main-layout__navbar__controls": true,
              "main-layout__navbar__controls--visible-mobile": controlsVisibleMobile && !props.isAuthorized
            })}
          >
            {!props.isAuthorized && !isRegisterConfirmationPage && (
              <AccessButtons
                onLoginClick={loginModalAction}
                onRegisterClick={registrationModalAction}
              />
            )}
          </div>
        )}      

        {isGeneralTermsOfServiceModalVisible && 
          <GeneralTermsOfServiceModal
            languageStore={languageStore}
            userStore={userStore}
          ></GeneralTermsOfServiceModal>
        }

        {isLoginModalVisible && 
          <LoginModal
            courseDetailPageStore={courseDetailPageStore}
            checkoutStore={checkoutPurchaseStore}
            languageStore={languageStore}
            redeemPageStore={redeemPageStore}
            userStore={userStore}
          ></LoginModal>
        }

        {isPurchaseCourseModalVisible && 
          <PurchaseCourseModal
            courseDetailPageStore={courseDetailPageStore}
            checkoutStore={checkoutPurchaseStore}
            languageStore={languageStore}
            redeemPageStore={redeemPageStore}
            userStore={userStore}
          ></PurchaseCourseModal>
        }

        {isPasswordRecoveryModalVisible && 
          <PasswordRecoveryModal
            userStore={userStore}
          ></PasswordRecoveryModal>
        }

        {isRegistrationModalVisible &&
          <RegistrationModal
            rootStore={props.rootStore}
          ></RegistrationModal>
        }

        {isRegistrationSuccessModalVisible &&
          <RegistrationSuccessModal
            userStore={userStore}
          ></RegistrationSuccessModal>
        }

        {!isLoading && props.isAuthorized && !scormProxyService.isEnabled && (
          <NotificationIndicator
            unreadCount={notificationsStore.unreadCount}
          />
        )}
      </nav>
    );
  })
)

export default withRouter(withUser(Navbar));
