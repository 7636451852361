import {defineMessages} from 'react-intl';

export default defineMessages({
  accept: {
    defaultMessage: 'Agree',
    id: 'Modals.TermsOfServiceModal.GeneralTerms.Accept',
  },
  contactParagraph: {
    defaultMessage: 'In case you do not agree with this update, please contact us at'
      + ' {mail}.',
    id: 'Modals.TermsOfServiceModal.GeneralTerms.ContactParagraph',
  },
  heading: {
    defaultMessage: 'Privacy Policy Update',
    id: 'Modals.TermsOfServiceModal.GeneralTerms.Heading',
  },
  messageAfterLink: {
    defaultMessage: '. Thank you!',
    id: 'Modals.TermsOfServiceModal.GeneralTerms.MessageAfterLink',
  },
  messageBeforeLink: {
    defaultMessage: 'University4Industry has updated its privacy policy. You can find the updated version here: ',
    id: 'Modals.TermsOfServiceModal.GeneralTerms.MessageBeforeLink',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    id: 'Modals.TermsOfServiceModal.GeneralTerms.PrivacyPolicy',
  },
});
