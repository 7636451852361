import {defineMessages} from 'react-intl';

export default defineMessages({
  attemptInfo: {
    defaultMessage: 'Attempt info:',
    id: 'CustomerGrading.AttemptDetail.AttemptDetailData.AttemptInfo',
  },
  date: {
    defaultMessage: 'Date - ',
    id: 'CustomerGrading.AttemptDetail.AttemptDetailData.Date',
  },
  grader: {
    defaultMessage: 'Evaluator - {graderName}',
    id: 'CustomerGrading.AttemptDetail.AttemptDetailData.Grader',
  },
  status: {
    defaultMessage: 'Status - ',
    id: 'CustomerGrading.AttemptDetail.AttemptDetailData.Status',
  },
  user: {
    defaultMessage: 'User - {userName}',
    id: 'CustomerGrading.AttemptDetail.AttemptDetailData.User',
  },
});
