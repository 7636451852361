import {defineMessages} from 'react-intl';

export default defineMessages({
  attemptInfo: {
    defaultMessage: 'Attempt info',
    id: 'CustomerGrading.AttemptEvaluation.AttemptEvaluationData.AttemptInfo',
  },
  date: {
    defaultMessage: 'Date - ',
    id: 'CustomerGrading.AttemptEvaluation.AttemptEvaluationData.Date',
  },
  user: {
    defaultMessage: 'User - {userName}',
    id: 'CustomerGrading.AttemptEvaluation.AttemptEvaluationData.User',
  },
});
