import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import intlMessages from "./intlMessages";

interface IProps {
  isFeedbackMandatory: boolean
  showCertificate: boolean
  totalNumberOfChallenges: number
  totalNumberOfChapters: number
}

export const CertificationStatusCompleted: FC<IProps> = (props: IProps) => {
  const {
    isFeedbackMandatory,
    showCertificate,
    totalNumberOfChallenges,
    totalNumberOfChapters
  } = props;

  if (showCertificate) {
    return (
      <FormattedMessage {...intlMessages.certificateDescriptionCompleted} />
    );
  } else {
    if (totalNumberOfChallenges !== 0 && isFeedbackMandatory) {
      return (
        <FormattedMessage {...intlMessages.challengesDescriptionCompleted} />
      );
    } else if (totalNumberOfChapters !== 0 && isFeedbackMandatory) {
      return (
        <FormattedMessage {...intlMessages.chaptersDescriptionCompleted} />
      );
    }
  }

  return <FormattedMessage {...intlMessages.certificateDescriptionCompleted} />;
};
