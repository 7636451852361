import {defineMessages} from 'react-intl';

export default defineMessages({
  discount: {
    defaultMessage: '- Discount ({percentage}%)',
    id: 'Checkout.SummaryPage.SkillDetails.SummaryPricing.Discount',
  },
  price: {
    defaultMessage: 'Price',
    id: 'Checkout.SummaryPage.SkillDetails.SummaryPricing.Price',
  },
  total: {
    defaultMessage: 'Total (incl. VAT)',
    id: 'Checkout.SummaryPage.SkillDetails.SummaryPricing.Total',
  },
  VAT: {
    defaultMessage: '+ VAT ({percentage}%)',
    id: 'Checkout.SummaryPage.SkillDetails.SummaryPricing.VAT',
  },
});
