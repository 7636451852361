import {action, computed,makeObservable} from 'mobx';
import UserStore from '@u4i/state/UserStore';
import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {BaseSection} from '../BaseSection';
import {LandingPageStore} from '../../LandingPageStore';
import {VisualComponent} from './VisualComponent';

export class LoginSection extends BaseSection {
  private landingPageStore: LandingPageStore;
  private userStore: UserStore;

  constructor(rootStore: IRootStore, sectionData: ISection) {
    super(sectionData, VisualComponent);

    makeObservable(this, {
      isVisible: computed,
      reloadContent: action.bound
    });

    const {landingPageStore, userStore} = rootStore;
    this.landingPageStore = landingPageStore;
    this.userStore = userStore;
  }

  get isVisible(): boolean {
    return !this.landingPageStore.isPrivateModeEnabled && !this.userStore.isAuthorized;
  }

  handleLoginFormSubmission = async (data) => {
    await this.userStore.login(data.email, data.password, data.recaptchaResponse);
  };

  openPasswordRecoveryModal = () => {
    this.userStore.passwordRecoveryModalHandle(true);
  }

  async reloadContent(): Promise<void> {
    return Promise.resolve();
  }
}
