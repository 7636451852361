import DiscussAnswerStatus from '@u4i/common/enums/DiscussAnswerStatus';
import Parser from '@u4i/utils/Parser';

export interface IDiscussAnswer {
  anonymously: boolean;
  commentCount: number;
  content: string;
  createdAt: string;
  id: string;
  isLiked: boolean;
  likeCount: number;
  status: DiscussAnswerStatus;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    profileImage: string;
  };
}

const discussAnswerParser = new Parser<IDiscussAnswer>(discussAnswer => ({
  anonymously: discussAnswer.anonymously_posted,
  commentCount: discussAnswer.comment_count,
  content: discussAnswer.content,
  createdAt: discussAnswer.created_at,
  id: discussAnswer.id,
  isLiked: discussAnswer.is_liked,
  likeCount: discussAnswer.like_count,
  status: discussAnswer.status,
  user: {
    firstName: discussAnswer.user.first_name,
    id: discussAnswer.user.id,
    lastName: discussAnswer.user.last_name,
    profileImage: discussAnswer.user.profile_image,
  },
}));

export default discussAnswerParser;
