import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {NavigationEntry} from './NavigationEntry/NavigationEntry';
import intlMessage from './intlMessages';
import './_navigation.scss';

interface IProps {
  hasChallenges: boolean
  showCertificate: boolean
};

export const Navigation: FC<IProps> = (props: IProps) => {
  const {
    hasChallenges,
    showCertificate
  } = props;
  
  return(
    <ul className="list-unstyled course-detail-page__navigation">
      <NavigationEntry targetHash="overview">
        <FormattedMessage {...intlMessage.overview} />
      </NavigationEntry>

      {(showCertificate && hasChallenges) &&
        <NavigationEntry targetHash="certificate">
          <FormattedMessage {...intlMessage.qualificationCertificate} />
        </NavigationEntry>
      }

      <NavigationEntry targetHash="chapters">
        <FormattedMessage {...intlMessage.chapters} />
      </NavigationEntry>
    </ul>
  )  
};
