import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import { SummaryPricingRow } from './SummaryPricingRow/SummaryPricingRow';
import './_summary-pricing.scss';

interface IPropTypes {
  discountAmount: string;
  discountPercentage: number;
  isDiscounted: boolean;
  priceNet: string;
  pricePurchase: string;
  vatAmount: string;
  vatPercentage: number;
}

export const SummaryPricing: FC<IPropTypes> = (props: IPropTypes) => {
  const {
    discountAmount,
    discountPercentage,
    isDiscounted,
    priceNet,
    pricePurchase,
    vatAmount,
    vatPercentage
  } = props;

  return(
    <div className="checkout__summary-pricing">
      <SummaryPricingRow
        bordered
        label={<FormattedMessage {...intlMessages.price} />}
        value={priceNet}
      />

      {isDiscounted &&
        <SummaryPricingRow
          bordered
          discount
          label={<FormattedMessage
            {...intlMessages.discount}
            values={{percentage: discountPercentage}}
          />}
          value={discountAmount}
        />
      }

      <SummaryPricingRow
        label={<FormattedMessage
          {...intlMessages.VAT}
          values={{percentage: vatPercentage}}
        />}
        value={vatAmount}
      />

      <SummaryPricingRow
        label={<FormattedMessage {...intlMessages.total} />}
        summary
        value={pricePurchase}
      />
    </div>
  )
};
