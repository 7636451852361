import {action, computed, observable, makeObservable} from 'mobx';

import {IDiscussPage} from '@u4i/parsers/DiscussPageParser';
import IEntity from './IEntity';

class DiscussPageEntity implements IEntity<IDiscussPage> {
  answerCount: number;
  content: string;
  createdAt: string;
  fetchingData = false;
  id: string;
  instructions: string;
  language: string;
  userId: string;

  constructor(discussPageData: IDiscussPage) {
    makeObservable(this, {
      answerCount: observable,
      content: observable,
      createdAt: observable,
      fetchingData: observable,
      id: observable,
      instructions: observable,
      language: observable,
      userId: observable,
      serializedData: computed,
      applyDataChanges: action.bound
    });

    this.applyDataChanges(discussPageData);
  }

  get serializedData(): IDiscussPage {
    const {answerCount, content, createdAt, id, instructions, language, userId} = this;
    return {answerCount, content, createdAt, id, instructions, language, userId};
  }

  applyDataChanges(dataChanges: Partial<IDiscussPage>) {
    Object.assign(this, dataChanges);
  }
}

export default DiscussPageEntity;
