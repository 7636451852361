import {action, computed, observable, runInAction,makeObservable} from 'mobx';

import {IApi, ILogger} from '@u4i/state/ServicesInterfaces';
import {IDiscussComment} from '@u4i/parsers/DiscussCommentParser';
import {IRootStore} from '@u4i/state/RootStore';
import IEntity from './IEntity';

class DiscussCommentEntity implements IEntity<IDiscussComment> {
  private apiService: IApi;
  private logger: ILogger;
  private rootStore: IRootStore;
  anonymously: boolean;
  content: string;
  createdAt: string;
  id: string;
  isLiked: boolean;
  likeCount: number;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    profileImage: string;
  };

  constructor(commentData: IDiscussComment, rootStore: IRootStore) {
    makeObservable(this, {
      anonymously: observable,
      content: observable,
      createdAt: observable,
      id: observable,
      isLiked: observable,
      likeCount: observable,
      user: observable,
      isOwn: computed,
      serializedData: computed,
      applyDataChanges: action.bound,
      toggleLike: action.bound,
      update: action.bound
    });

    const {apiService, loggerFactory} = rootStore;

    this.apiService = apiService;
    this.logger = loggerFactory.createLogger('DiscussCommentEntity');
    this.rootStore = rootStore;

    this.applyDataChanges(commentData);
  }

  get isOwn() {
    return this.user.id === this.rootStore.userStore.userData.id;
  }

  get serializedData(): IDiscussComment {
    const {anonymously, content, createdAt, id, isLiked, likeCount, user} = this;
    return {anonymously, content, createdAt, id, isLiked, likeCount, user};
  }

  applyDataChanges(dataChanges: Partial<IDiscussComment>) {
    Object.assign(this, dataChanges);
  }

  async toggleLike() {
    const likeCountModifier = this.isLiked ? -1 : 1;

    this.isLiked = !this.isLiked;
    this.likeCount += likeCountModifier;

    try {
      await this.apiService.discuss.toggleCommentLike(this.id);

      this.logger.info('like toggled');
    } catch (error) {
      runInAction(() => {
        this.likeCount -= likeCountModifier;
        this.isLiked = !this.isLiked;
      });

      this.logger.error(error);

      throw error;
    }
  }

  async update(partialDataChanges: Partial<IDiscussComment>) {
    const originalDataSnapshot = this.serializedData;
    this.applyDataChanges(partialDataChanges);

    try {
      const updatedComment = await this.apiService.discuss.updateComment(this.serializedData);
      this.applyDataChanges(updatedComment);

    } catch (error) {
      this.applyDataChanges(originalDataSnapshot);

      throw error;
    }
  }
}

export default DiscussCommentEntity;
