import {defineMessages} from 'react-intl';

export default defineMessages({
  estimatedHour: {
    defaultMessage: '{time} hour',
    id: 'SkillDuration.EstimatedHour',
  },
  estimatedMinutes: {
    defaultMessage: '{time} minutes',
    id: 'SkillDuration.EstimatedMinutes',
  }
});
