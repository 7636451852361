
import { ThemeContext } from "@u4i/utils/ThemeContext";
import React from "react";
import "./_themetoggle.scss";



const ThemeToggle = (props: any) => {

  const {theme,handleThemeSwitch}:any = React.useContext(ThemeContext);

  return (
    <div className="ml-8">
      <input
        type="checkbox"
        className="checkbox"
        checked={theme === "dark"}
        id="checkbox"
        onChange={handleThemeSwitch}
      />
      <label htmlFor="checkbox" className="toggle-label">
        <i className="fas fa-moon"></i>
        <i className="fas fa-sun"></i>
        <div className="ball"></div>
      </label>
    </div>
  );
};

export default ThemeToggle;
