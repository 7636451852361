import InfoIcon from "@material-ui/icons/InfoOutlined";
import cx from "classnames";
import React from "react";

import "./_player-disclaimer.scss";

interface IPropTypes {
  content: string;
  isMenuActive: boolean;
}

export const PlayerDisclaimer: React.FC<IPropTypes> = (props) => (
  <div className="player__disclaimer">
    <p
      style={{
        transition: "transform 0.3s ease-in-out",
        display:'flex',
        alignItems:'center',
        margin:0
      }}
      className={cx({
        player__disclaimer__text: true,
        header_transition_title_open: props.isMenuActive,
        header_transition_title_close: !props.isMenuActive,
      })}
    >
      <InfoIcon className="player__disclaimer__info-icon" /> {props.content}
    </p>
  </div>
);
