import { UploadFile } from "antd/lib/upload/interface";

import Parser from "@u4i/utils/Parser";
import { IAdminGrader } from "./AdminAvailableGradersParser";
import { IAdminSkill } from "./AdminAvailableSkillParser";

export interface IGroupTermsOfService {
  english: {
    acceptanceTitle: string;
    content: string;
    description: string;
    isMandatory: boolean;
  };
  german: {
    acceptanceTitle: string;
    content: string;
    description: string;
    isActive: boolean;
  };
}

export interface ILinkedCustomPage {
  id: string;
  title: string;
  detailsUrl: string;
}

export interface IAdminUserGroupDetailed {
  anonymity: {
    id: number;
    name: string;
  };
  collaborationCodes: Array<string>;
  customerGrading: boolean;
  defaultSkills: Array<IAdminSkill>;
  description: string;
  discountPercentage: number;
  enableDirectLinkForExternalContent: boolean;
  enableEmailNotification: boolean;
  enableOnboardingTour: boolean;
  enableSkillCertificates: boolean;
  enforceAcceptanceOfU4IPrivacyPolicyOnUpdate: boolean;
  graders: Array<IAdminGrader>;
  groupTermsOfService: IGroupTermsOfService;
  id: string;
  linkedCustomPages?: Array<ILinkedCustomPage>;
  logoFile?: string;
  mailDomains: Array<string>;
  name: string;
  navigationTabs: {
    discover: {
      id: string;
      isVisible: boolean;
      name: string;
    };
    home: {
      id: string;
      isVisible: boolean;
      name: string;
    };
  };
  playerDisclaimerDE: string;
  playerDisclaimerEN: string;
  proofOfQualificationSettings: {
    headerDE: string;
    headerEN: string;
    logoFileList?: Array<UploadFile>;
    signatureFileList?: Array<UploadFile>;
    signatureFirstLine: string;
    signatureSecondLine: string;
  };
  signatureFile?: string;
}

export interface IAdminUserGroup {
  description: string;
  id: string;
  linkedCustomPages?: any;
  mailDomains: Array<string>;
  name: string;
}

export const AdminUserGroupParser = new Parser<IAdminUserGroup>(
  (adminUserGroup) => ({
    description: adminUserGroup.description,
    id: adminUserGroup.id,
    mailDomains: adminUserGroup.mailDomains,
    name: adminUserGroup.name,
  })
);

export const AdminUserGroupParserDetailed = new Parser<IAdminUserGroupDetailed>(
  (adminUserGroup) => ({
    anonymity: {
      id: adminUserGroup.anonymity.id,
      name: adminUserGroup.anonymity.name,
    },
    collaborationCodes: adminUserGroup.collaborationCodes,
    customerGrading: adminUserGroup.customerGrading,
    defaultSkills:
      adminUserGroup.defaultSkills &&
      adminUserGroup.defaultSkills.map((defaultSkill: IAdminSkill) => ({
        id: defaultSkill.id,
        name: defaultSkill.name,
      })),
    description: adminUserGroup.description,
    discountPercentage: adminUserGroup.discountPercentage,
    enableDirectLinkForExternalContent:
      adminUserGroup.enableDirectLinkForExternalContent,
    enableEmailNotification: adminUserGroup.enableEmailNotification,
    enableOnboardingTour: adminUserGroup.enableOnboardingTour,
    enableSkillCertificates: adminUserGroup.enableSkillCertificates,
    enforceAcceptanceOfU4IPrivacyPolicyOnUpdate:
      adminUserGroup.enforceAcceptanceOfU4IPrivacyPolicyOnUpdate,
    graders:
      adminUserGroup.graders &&
      adminUserGroup.graders.map((grader: IAdminGrader) => ({
        email: grader.email,
        firstName: grader.firstName,
        id: grader.id,
        lastName: grader.lastName,
        username: grader.username,
      })),
    groupTermsOfService: {
      english: {
        acceptanceTitle:
          adminUserGroup.groupTermsOfService.english.acceptanceTitle,
        content: adminUserGroup.groupTermsOfService.english.content,
        description: adminUserGroup.groupTermsOfService.english.description,
        isMandatory: !!adminUserGroup.groupTermsOfService.english.isMandatory,
      },
      german: {
        acceptanceTitle:
          adminUserGroup.groupTermsOfService.german.acceptanceTitle,
        content: adminUserGroup.groupTermsOfService.german.content,
        description: adminUserGroup.groupTermsOfService.german.description,
        isActive: !!adminUserGroup.groupTermsOfService.german.isActive,
      },
    },
    id: adminUserGroup.id,
    linkedCustomPages: adminUserGroup.linkedCustomPages,
    mailDomains: adminUserGroup.mailDomains,
    name: adminUserGroup.name,
    navigationTabs: {
      discover: {
        id: adminUserGroup.navigationTabs.discover.id,
        isVisible: adminUserGroup.navigationTabs.discover.isVisible,
        name: adminUserGroup.navigationTabs.discover.name,
      },
      home: {
        id: adminUserGroup.navigationTabs.home.id,
        isVisible: adminUserGroup.navigationTabs.home.isVisible,
        name: adminUserGroup.navigationTabs.home.name,
      },
    },
    playerDisclaimerDE: adminUserGroup.playerDisclaimerDE,
    playerDisclaimerEN: adminUserGroup.playerDisclaimerEN,
    proofOfQualificationSettings: {
      headerDE: adminUserGroup.proofOfQualificationSettings.headerDE,
      headerEN: adminUserGroup.proofOfQualificationSettings.headerEN,
      logoFileList: [
        {
          fileName:
            adminUserGroup.proofOfQualificationSettings.logoFile.filename,
          name: adminUserGroup.proofOfQualificationSettings.logoFile.filename,
          size: adminUserGroup.proofOfQualificationSettings.logoFile.size,
          status: "done",
          type: adminUserGroup.proofOfQualificationSettings.logoFile.type,
          uid: adminUserGroup.proofOfQualificationSettings.logoFile.id,
          url: adminUserGroup.proofOfQualificationSettings.logoFile.url,
        },
      ],
      signatureFileList: [
        {
          fileName:
            adminUserGroup.proofOfQualificationSettings.signatureFile.filename,
          name: adminUserGroup.proofOfQualificationSettings.signatureFile
            .filename,
          size: adminUserGroup.proofOfQualificationSettings.signatureFile.size,
          status: "done",
          type: adminUserGroup.proofOfQualificationSettings.signatureFile.type,
          uid: adminUserGroup.proofOfQualificationSettings.signatureFile.id,
          url: adminUserGroup.proofOfQualificationSettings.signatureFile.url,
        },
      ],
      signatureFirstLine:
        adminUserGroup.proofOfQualificationSettings.signatureFirstLine,
      signatureSecondLine:
        adminUserGroup.proofOfQualificationSettings.signatureSecondLine,
    },
  })
);
