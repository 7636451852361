import React from "react";
import { observer } from "mobx-react";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { ISkillBasic } from "@u4i/parsers/SkillParserBasic";
import { BasicInfoWrapper } from "../common/BasicInfoWrapper";
import { HorizontalSlider } from "../common/HorizontalSlider";
import { NoItemsMessage } from "../common/NoItemsMessage";
import { SkillSection } from "./SkillSection";
import CommonCard from "@u4i/common/Cards/CommonCard";

interface IPropTypes {
  sectionModel: SkillSection;
}

export const VisualComponent = observer(
  class VisualComponent extends React.Component<IPropTypes> {
    handleSkillCardClick = (skill: ISkillBasic) => {
      AppNavigator.push(appRoutes.courseDetailPage, { skillSlug: skill.slug });
    };

    render() {
      const { sectionModel } = this.props;

      return (
        <BasicInfoWrapper
          isLoading={sectionModel.fetching}
          itemsCountLabel={sectionModel.items.length}
          sectionModel={sectionModel}
        >
          <div className="container">
            {sectionModel.items.length > 0 ? (
              <HorizontalSlider>
                {sectionModel.items.map((entry: ISkillBasic, index: any) => (
                  <CommonCard
                    title={entry.title}
                    duration={entry.duration}
                    image={entry.image}
                    language={entry.language}
                    onCardClick={() => this.handleSkillCardClick(entry)}
                    key={entry.id}
                    progress={entry.skillProgressPercentage}
                    isCompleted={entry.isCompleted}
                    numberOfChallenges={entry.numberOfChallenges}
                    numberOfCompletedChallenges={entry.numberOfCompletedChallenges}
                    isSkilCard
                  />
                ))}
              </HorizontalSlider>
            ) : (
              <NoItemsMessage />
            )}
          </div>
        </BasicInfoWrapper>
      );
    }
  }
);
