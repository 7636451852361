import cx from 'classnames';
import React, { FC } from 'react';
import arrow from '@u4i/assets/svg/arrow.svg';
import {SwitchButton} from './components/SwitchButton';

import './_item-switch.scss';

interface IPropTypes {
  activeItems: Array<{
    id: string
    label: string
  }>
  isOpen: boolean
  selectedItem: {
    id: string
    label: string
  }
  onItemClick: (itemId: string) => void
  onSwitchClick: () => void
}

export const ItemSwitch: FC<IPropTypes> = (props: IPropTypes) => {
  const selectableItems = props.activeItems.filter(item => item.id !== props.selectedItem.id);

  return (
    <div className="landing-page__item-switch">
      <div className="landing-page__item-switch__inner-wrap">
        {props.selectedItem &&
          <button
            className={cx({
              'landing-page__item-switch__button': true,
              'landing-page__item-switch__button--with-icon': true,
            })}
            onClick={props.onSwitchClick}
            type="button"
          >
            {props.selectedItem.label}

            <img
              alt="arrow"
              className={cx({
                'landing-page__item-switch__arrow': true,
                'landing-page__item-switch__arrow--hide': props.isOpen,
              })}
              src={arrow}
            />
          </button>
        }

        {(props.isOpen && selectableItems.length > 0) &&
          <ul className="landing-page__item-switch__menu list-unstyled">
            {selectableItems.map(entry => (
              <li key={entry.id}>
                <SwitchButton
                  id={entry.id}
                  label={entry.label}
                  onItemClick={props.onItemClick}
                />
              </li>
            ))}
          </ul>
        }
      </div>
    </div>
  );
};
