import jsrsasign from 'jsrsasign';
import {AxiosInstance} from 'axios';
import {AccessDataParser, IAccessData} from '@u4i/parsers/AccessDataParser';
import {IUser, UserParser} from '@u4i/parsers/UserParser';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import { FeedbackSubmissionParser, IFeedbackSubmission } from '@u4i/parsers/FeedbackSubmissionParser';
import { RegistrationInfo } from '@u4i/common/Interfaces/RegistrationInterfaces';

export class UserApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  acceptGeneralTermsOfService = async () => {
    const response = await this.apiClient.post('/v1/terms-of-service/accept', { general_terms: true });
    
    return response;
  }

  acceptUserGroupTermsOfService = async (isAccepted: boolean, tosId: string) => {
    const response = await this.apiClient.post('/v1/terms-of-service/accept', { group_terms: [{accepted: isAccepted, id: tosId}] });
    
    return response;
  }

  async cancelImpersonation(): Promise<string> {
    const response = await this.apiClient.post('/user/login-back-to-origin');

    return response.data.redirect_url;
  }

  async changeLanguage(newLocale: LanguageEnum): Promise<void> {
    await this.apiClient.post('/user/set-language', {language: newLocale});
  }

  async confirmRegistration(token: string): Promise<{accessData: IAccessData, user: IUser}> {
    const response = await this.apiClient.post(`/user/register-confirm/${token}`);

    return {
      accessData: AccessDataParser.parse(response.data.access_token),
      user: UserParser.parse(response.data.user),
    };
  }

  async feedbackSubmission(skillId: string): Promise<IFeedbackSubmission> {
    const response = await this.apiClient.post(`/v2/skills/${skillId}/feedback/submit`);

    return FeedbackSubmissionParser.parse(response.data);
  }

  async fetchData(): Promise<IUser> {
    const response = await this.apiClient.get('/user');

    return UserParser.parse(response.data.user);
  }

  async instantLogin(loginToken: string): Promise<{accessData: IAccessData, user: IUser}> {
    const response = await this.apiClient.post('/user/login', {login_token: loginToken});

    return {
      accessData: AccessDataParser.parse(response.data.access_token),
      user: UserParser.parse(response.data.user),
    };
  }

  async invite(email: string, message: string): Promise<void> {
    await this.apiClient.post('/user/invite', {email, message});
  }

  async login(email: string, password: string, recaptcha?: string): Promise<{accessData: IAccessData, user: IUser}> {
    const response = await this.apiClient.post('/user/login', {email, password, recaptchaResponse: recaptcha});

    return {
      accessData: AccessDataParser.parse(response.data.access_token),
      user: UserParser.parse(response.data.user),
    };
  }

  async logout(): Promise<void> {
    await this.apiClient.post('/user/logout');
  }

  async recoverPassword(email: string): Promise<void> {
    await this.apiClient.post('/user/recover-password', {email});
  }

  async refreshToken(accessToken: string): Promise<IAccessData> {
    const response = await this.apiClient.post('/user/refresh', {access_token: accessToken});

    return AccessDataParser.parse(response.data);
  }

  async register(registrationData: RegistrationInfo, landingPageRefSequence: string): Promise<void> {
    let dataModel: any = {
      email: registrationData.email,
      first_name: registrationData.firstname,
      last_name: registrationData.lastname,
      newPassword: registrationData.newPassword,
      newPasswordConfirm: registrationData.newPasswordConfirm,
      terms: registrationData.terms,
      newsletter: registrationData.newsletter,
      register_ref: landingPageRefSequence ? landingPageRefSequence : ''
    }

    if(registrationData.collaborationCode) {
      dataModel.collaboration_code = registrationData.collaborationCode;
    }

    if(registrationData.addressLine1 && registrationData.zipCode && registrationData.city && registrationData.country) {
      dataModel.address_line_1 = registrationData.addressLine1;
      dataModel.zip_code = registrationData.zipCode;
      dataModel.city = registrationData.city;
      dataModel.country = registrationData.country;
    }

    await this.apiClient.post('/user/register', dataModel);
  }

  async resendInvitation(email: string): Promise<void> {
    await this.apiClient.post('/user/resend-invitation', {email})
  }

  async resetPassword(guid: string, token: string, newPassword: string, newPasswordConfirm: string): Promise<string> {
    const response = await this.apiClient.post('/user/reset-password', {guid, token, newPassword, newPasswordConfirm});

    return response.data.redirect_url;
  }

  async scormStudentLogin(authMode: string, skillId: string, learnerId: string, secretKey: string): Promise<string> {
    
    const unixTimestampSeconds = Math.floor(Date.now() / 1000);

    const jwtToken = jsrsasign.KJUR.jws.JWS.sign('HS256', {
      alg: 'HS256',
      typ: 'JWT',
    } as {alg: string}, {
      exp: unixTimestampSeconds + (5 * 60),
      iat: unixTimestampSeconds,
      learnerId,
    }, secretKey);

    const response = await this.apiClient.post('/v2/user/login-scorm', {
      auth_mode: authMode,
      skill_id: skillId,
      token: jwtToken,
    });

    return response.data.redirectUrl;
  }
}
