import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';

interface IProps {
  chapterCount: number
  feedbackSubmitted: boolean
  isFeedbackMandatory: boolean
  numberOfChallenges: number
  numberOfCompletedChallenges: number
  showCertificate: boolean
  viewedChapterCount: number
  onFeedbackSurveyStart: () => void
}

export const CertificationStatusIncomplete: FC<IProps> = (props:IProps) => {
  const {
    chapterCount,
    feedbackSubmitted,
    isFeedbackMandatory,
    numberOfChallenges,
    numberOfCompletedChallenges,
    showCertificate,
    viewedChapterCount,
    onFeedbackSurveyStart
  } = props;

  let isContentViewed: boolean = viewedChapterCount/chapterCount === 1 ? true : false;
  let areChallengesCompleted: boolean = numberOfCompletedChallenges/numberOfChallenges === 1 ? true : false;

  if (numberOfChallenges === 0) {
    if (isFeedbackMandatory && !feedbackSubmitted && !isContentViewed){
      return  (
        <FormattedMessage
          {...intlMessages.certificateDescriptionUncompletedNoChallengesAndFeedbackMandatory}
          values={{ func: (feedback) => <a onClick={onFeedbackSurveyStart}>{feedback}</a>}}
        />
      )
    } else if (isFeedbackMandatory && !feedbackSubmitted && isContentViewed) {
      return (
        <FormattedMessage
          {...intlMessages.certificateDescriptionUncompletedFeedbackMandatoryNoChallenges}
          values={{ func: (feedback) => <a onClick={onFeedbackSurveyStart}>{feedback}</a>}}
        />
      )
    } else {
      return <FormattedMessage {...intlMessages.certificateDescriptionUncompletedNoChallenges} />
    }
  }

  if (showCertificate) {
    if(isFeedbackMandatory && !feedbackSubmitted && !areChallengesCompleted) {
      return (
        <FormattedMessage
          {...intlMessages.certificateDescriptionUncompletedAndFeedbackNotSubmitted}
          values={{
            numberOfChallenges: numberOfChallenges,
            func: (feedback) => <a onClick={onFeedbackSurveyStart}>{feedback}</a>
          }}
        />
      );
    } else if(isFeedbackMandatory && feedbackSubmitted && !areChallengesCompleted) {
      return (
        <FormattedMessage
          {...intlMessages.certificateDescriptionUncompletedAndFeedbackSubmitted}
          values={{numberOfChallenges: numberOfChallenges}}
        />
      );
    } else if(isFeedbackMandatory && !feedbackSubmitted && areChallengesCompleted) {
      return (
        <FormattedMessage
          {...intlMessages.certificateDescriptionCompletedAndFeedbackNotSubmitted}
          values={{ func: (feedback) => <a onClick={onFeedbackSurveyStart}>{feedback}</a>}}
        />
      );
    }
  }

  if(!showCertificate) {
    if(isFeedbackMandatory && !feedbackSubmitted && !areChallengesCompleted) {
      return(
        <FormattedMessage
          {...intlMessages.challengesDescriptionUncompletedAndFeedbackNotSubmitted}
          values={{
            numberOfChallenges: numberOfChallenges,
            func: (feedback) => <a onClick={onFeedbackSurveyStart}>{feedback}</a>
          }}
        />
      )
    } else if(isFeedbackMandatory && feedbackSubmitted && !areChallengesCompleted) {
      return(
        <FormattedMessage
          {...intlMessages.challengesDescriptionUncompletedAndFeedbackSubmitted}
          values={{numberOfChallenges: numberOfChallenges}}
        />
      )
    } else if(isFeedbackMandatory && !feedbackSubmitted && areChallengesCompleted) {
      return (
        <FormattedMessage
          {...intlMessages.challengesDescriptionCompletedAndFeedbackNotSubmitted}
          values={{ func: (feedback) => <a onClick={onFeedbackSurveyStart}>{feedback}</a>}}
        />
      );
    }
  }

  return (
    <FormattedMessage
      {...intlMessages.certificateDescriptionUncompleted}
      values={{numberOfChallenges: numberOfChallenges}}
    />
  );
}
