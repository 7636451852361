const ACTION_TYPES = ['POP', 'PUSH', 'REPLACE'];

class Action {
  type = null;

  constructor(type) {
    if (!type) {
      throw new Error('Missing action type');
    }

    if (!ACTION_TYPES.includes(type)) {
      throw new Error('Invalid action type');
    }

    this.type = type;
  }
}

export default Action;
