import React from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import { mercury, green, white } from '@u4i/styles/styles-variables';
import './_curriculum-progress-chart.scss';

interface IPropTypes {
  groupAveragePercentage: number;
  groupTopPercentage: number;
  progressPercentage: number;
}

const CHART_RADIUS = 40;
const CIRCLE_BORDER_LENGTH = Math.PI * 2 * CHART_RADIUS;

export const CurriculumProgressChart: React.FC<IPropTypes> = (props) => (
  <div className="landing-page__curriculum-progress-chart">
    <div className="landing-page__curriculum-progress-chart__chart-container">
      <svg className="landing-page__curriculum-progress-chart__chart" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          fill={white}
          r="40"
        />

        <circle
          cx="50"
          cy="50"
          fill="transparent"
          r="40"
          stroke={mercury}
          strokeWidth="4"
        />

        <circle
          cx="50"
          cy="50"
          fill="transparent"
          r="40"
          stroke={green}
          strokeDasharray={CIRCLE_BORDER_LENGTH}
          strokeDashoffset={(1 - props.progressPercentage) * CIRCLE_BORDER_LENGTH}
          strokeWidth="16"
        />
      </svg>

      <span className="landing-page__curriculum-progress-chart__chart-label">
        <strong className="landing-page__curriculum-progress-chart__chart-label-percentage">
          {Math.floor(props.progressPercentage * 100)}%
        </strong>

        <FormattedMessage {...intlMessages.count} />
      </span>
    </div>

    <div className="landing-page__curriculum-progress-chart__group-info">
      <span>
        <FormattedMessage {...intlMessages.average} />:&nbsp;
        <strong>{Math.floor(props.groupAveragePercentage * 100)}%</strong>
      </span>

      <div className="landing-page__curriculum-progress-chart__group-info-divider" />

      <span>
        <FormattedMessage {...intlMessages.top} />:&nbsp;
        <strong>{Math.floor(props.groupTopPercentage * 100)}%</strong>
      </span>
    </div>
  </div>
);
