import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {roundSecondsToMinutes, SECONDS_IN_HOUR} from '@u4i/common/utils';
import {DurationInHours} from './DurationInHours';

import intlMessages from './intlMessages';
import './_skill-duration.scss';

interface IPropTypes {
  duration: number;
  theme?: string;
}

export const SkillDuration: FC<IPropTypes> = (props: IPropTypes) => {
  const durationSeconds = roundSecondsToMinutes(props.duration);
  const duration = moment.duration(durationSeconds, 'seconds');
  let formattedInMinutes;
  let formattedInHours;
  let hours;
  let minutes;

  if (durationSeconds > SECONDS_IN_HOUR) {
    const durationMinutes = moment.duration(durationSeconds % 3600, 'seconds');
    let durationHours;

    hours = Math.floor(props.duration / 3600);
    minutes = Math.floor((props.duration % 3600) / 60);
    formattedInMinutes = durationMinutes.asMinutes();

    if (formattedInMinutes > 55) {
      durationHours = moment.duration(hours + 1, 'h');
    } else {
      durationHours = moment.duration(hours, 'h');
    }
    formattedInHours = durationHours.asHours();
  } else if(durationSeconds === SECONDS_IN_HOUR) {
    formattedInHours = moment.utc(duration.asMilliseconds()).format('h');
  } else {
    formattedInMinutes = moment.utc(duration.asMilliseconds()).format('m');
  }

  return (
    <span className={cx({
      'skill-duration': true,
      'skill-duration--dark': props.theme === 'dark',
    })}>
      {
        durationSeconds === SECONDS_IN_HOUR &&
          <strong><FormattedMessage {...intlMessages.estimatedHour} values={{time: formattedInHours}} /></strong>
      }
      {
        durationSeconds < SECONDS_IN_HOUR &&
          <strong><FormattedMessage {...intlMessages.estimatedMinutes} values={{time: formattedInMinutes}} /></strong>
      }
      {
        (durationSeconds > SECONDS_IN_HOUR) &&
          <strong>
            <DurationInHours
              formattedInHours={formattedInHours}
              formattedInMinutes={formattedInMinutes}
              hours={hours}
              minutes={minutes}
            />
          </strong>
      }
    </span>
  );
};
