export const enum UserRolesEnum {
  USER = 0,
  SUPERADMIN = 1,
  U4I_ADMIN = 2,
  U4I_FACULTY = 3,
  GRADER_FACULTY = 4,
  GRADER_ADMIN = 5,
  ANONYMOUS = 6,
  EXTERNAL_API_ACCESS = 7  
}
