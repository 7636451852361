import Parser from "@u4i/utils/Parser";

const playerElementParser = new Parser((element) => {
  return {
    challenge:
      element.type === "challenge"
        ? {
            description: element.challenge.description,
            digitalLabId: element.challenge.digitalLabId,
            exerciseId: element.challenge.exerciseId,
            jupyterLabId: element.challenge.jupyterLabId,
            mcqTestId: element.challenge.mcqTestId,
            provider: element.challenge.provider,
            questionContent: element.challenge.questionContent,
            questionSlug: element.challenge.questionSlug,
            url: element.challenge.url,
            useDirectLink: element.challenge.useDirectLink,
          }
        : undefined,
    duration: element.duration,
    id: element.id,
    interactive:
      element.type === "interactive"
        ? {
            url: element.interactive.url,
          }
        : undefined,
    internalId: element.internal_id,
    inTransition: element.in_transition,
    language: element.language,
    order: element.order,
    textImage:
      element.type === "text_image"
        ? {
            backgroundUrl: element.text_image.background_url,
            elementRender: element.text_image.element_render,
          }
        : undefined,
    title: element.title,
    type: element.type,
    video:
      element.type === "video"
        ? {
            id: element.video.video_id,
            provider: element.video.provider,
            url: element.video.url,
          }
        : undefined,
    chatbot:
      element.type === "chatbot"
        ? {
          topic: element.chatbot?.data?.topic
            ? element.chatbot?.data.topic : undefined,
          type: element.chatbot?.data?.type
            ? element.chatbot?.data.type : undefined,
        }
        : undefined,
    viewed: element.viewed,
  };
});

export default playerElementParser;
