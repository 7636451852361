import React, { FC } from 'react';

interface IPropTypes {
  children: React.ReactElement;
  href: string;
}

const EntryLink: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <a className="main-layout__entry__button" href={props.href}>
      {props.children}
    </a>
  );
}

export default EntryLink;
