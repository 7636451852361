import React, { FC } from "react";
import { IChapterData } from "../../interfaces/ChapterInterface";
import { ChaptersList } from "./ChaptersList/ChaptersList";
import { Col, Pagination, Row, Skeleton } from "antd";
import "./_chapters-section.scss";

interface IProps {
  chapters: IChapterData[];
  fetching: boolean;
  isSkillBought: boolean;
  page: number;
  skillHasChallenges?: boolean;
  totalPages: number;
  changeChaptersPage: (newPage: any) => void;
  onChapterStart: (chapter: IChapterData) => void;
  onPageChange: () => void;
  hasMandatoryChapters: boolean;
  fetchingChapters:boolean;
}

export const ChaptersSection: FC<IProps> = (props: IProps) => {
  const {
    chapters,
    fetchingChapters,
    isSkillBought,
    page,
    skillHasChallenges,
    totalPages,
    hasMandatoryChapters,
    changeChaptersPage,
    onChapterStart,
    onPageChange,
  } = props;

  const handleChapterStart = (chapterId: string) => {
    const targetChapter = chapters.find((chapter) => chapter.id === chapterId);

    onChapterStart(targetChapter!);
  };

  const handlePageChange = (newPage: any) => {
    if (newPage === page) {
      return;
    }

    changeChaptersPage(newPage);
    onPageChange();
  };

  return (
    <div className="course-detail-page__chapters-section">
      <div className="container">
        <div className="course-detail-page__chapters-section__content">
          {totalPages > 1 && (
            <Row justify="center">
              <Col>
                <Pagination
                  current={page}
                  total={totalPages * 8}
                  pageSize={8}
                  onChange={handlePageChange}
                  className="disable-invert"
                />
              </Col>
            </Row>
          )}
          {fetchingChapters ? (
          <>
           <Skeleton active />
          </>
          ) : (
            <ChaptersList
              isSkillBought={isSkillBought}
              items={chapters}
              onItemClick={handleChapterStart}
              skillHasChallenges={skillHasChallenges}
              hasMandatoryChapters={hasMandatoryChapters}
              page={page}
            />
          )}
          {totalPages > 1 && (
            <Row justify="center">
              <Col>
                <Pagination
                  current={page}
                  total={totalPages * 8}
                  pageSize={8}
                  onChange={handlePageChange}
                  className="disable-invert"
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
