import React, {Component} from 'react';
import {action, observable, autorun,makeObservable } from 'mobx';
import {observer} from 'mobx-react';

import './_icon-hoverable.scss';

interface IPropTypes {
  alt: string;
  classNames?: string;
  iconHovered: string;
  iconNonHovered: string;
  isActive?: boolean;
}

const IconHoverable = observer(class IconHoverable extends Component<IPropTypes> {
  private iconType: string = this.props.iconNonHovered;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<IconHoverable, "iconType">(this, {
      iconType: observable,
      changeIconColorHovered: action.bound,
      changeIconColorNonHovered: action.bound
    });

    autorun(this.switchIconType);
  }
  
  changeIconColorHovered() {
    if (!this.props.isActive && this.iconType === this.props.iconNonHovered) {
      this.iconType = this.props.iconHovered;  
    }
  }

  changeIconColorNonHovered() {
    if (!this.props.isActive) {
      this.iconType = this.props.iconNonHovered;
    }
  }

  switchIconType = () => {
    this.iconType = this.props.isActive ? this.props.iconHovered : this.props.iconNonHovered;
  }

  render() {
    return (
      <div
        className="icon-hoverable"
        onMouseEnter={this.changeIconColorHovered}
        onMouseLeave={this.changeIconColorNonHovered}
      >
        <img
          alt={this.props.alt}
          className={this.props.classNames}
          src={this.iconType}
        />
      </div>
    );
  }
});

export default IconHoverable;
