import Route from './Route';

const appRoutes = {
  admin3View: new Route(() => `/administration3`),
  admin3UserCourseOverview: new Route(() => `/administration3/user-courses`),
  adminCornerstoneOverview: new Route(() => `/administration3/cornerstone`),
  adminCornerstoneAssignCourse: new Route(() => `/administration3/cornerstone/cornerstone-course-assign`),
  adminCustomerIntegrationOverview: new Route(() => `/administration3/customer-integration`),
  adminReportsCourseProgress: new Route(() => `/administration3/reports-course-progress`),
  adminReportsGroupReminders: new Route(() => `/administration3/reports-group-reminders`),
  adminReportsCourseEnrollements: new Route(() => `/administration3/reports-course-enrollements`),
  adminReportsUserCoursesProgress: new Route(() => `/administration3/reports-user-course-progress`),
  adminCustomerIntegrationEdit: new Route((params) => `/administration3/customer-integration/edit/${params.customerIntId}`),
  adminProgramEdit: new Route((params) => `/administration3/programs/edit/${params.programId}`),
  adminProgramOverview: new Route(() => `/administration3/programs`),
  adminQuestionsEdit: new Route((params) => `/administration3/questions-bank/edit/${params.questionId}`),
  adminQuestionsOverview: new Route(() => `/administration3/questions-bank`),
  adminTestsOverview: new Route((params) => `/administration3/tests`),
  adminProviderEdit: new Route((params) => `/administration3/providers/edit/${params.providerId}`),
  adminUserGroupEdit: new Route(params => `/administration3/user-groups/edit/${params.groupId}`),
  adminUserGroupsOverview: new Route(() => '/administration3/user-groups'),
  adminUsersOverview: new Route(() => '/administration3/users'),
  digitalLabs: new Route(params => `/digital-labs/${params.labId}`),
  discuss: new Route(params => `/discuss/${params.questionSlug}`),
  exercises: new Route(params => `/exercises/${params.exerciseId}`),
  externalContent: new Route(() => '/external-content'),
  fileManager: new Route(() => `/administration3/file-management`),
  game: new Route(() => '/game'),
  gradingAttemptEdit: new Route(params => `/grading/attempt/edit/${params.attemptId}`),
  gradingAttemptsList: new Route(() => '/grading/attempts'),
  gradingEvaluation: new Route(params => `/grading/evaluation/${params.attemptId}`),
  gradingProgress: new Route(() => '/grading/progress'),
  gradingProgressGroup: new Route(params => `/grading/progress/group/${params.groupId}`),
  gradingProgressUser: new Route(params => `/grading/progress/user/${params.userId}`),
  home: new Route(() => '/'),
  login: new Route(() => '/login'),
  jupyterLabs: new Route(params => `/jupyter-lab/${params.labId}`),
  playerChapter: new Route(params => `/player/chapter/${params.chapterSlug}`),
  playerSkill: new Route(params => `/player/skill/${params.skillSlug}/${params.chapterSlug}`),
  redeemPage: new Route(() => '/redeem'),
  registerSuccessPage: new Route(() => '/register-success'),
  search: new Route(() => '/search'),
  courseDetailPage: new Route(params => `/skills/${params.skillSlug}`),
  adminChatbotFileManagerOverview: new Route(() => `/administration3/chatbot-knowledge-bases`),
  adminChatbotFileManagerEdit: new Route((params) => `/administration3/chatbot-knowledge-bases/edit/${params.chatbotKBId}`),
};

export default appRoutes;
