import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import AppNavigator from '@u4i/common/AppNavigator';
import { DiscussPageBackButton } from './components/DiscussPageBackButton/DiscussPageBackButton';
import './_discuss-page-header.scss';

interface IProps {
  question: string
}

export const DiscussPageHeader: FC<IProps> = (props: IProps) => {
  const handleBackButtonClick = () => {
    AppNavigator.goBack();
  }

  return (
    <div className="discuss-page__header">
      <DiscussPageBackButton onClick={handleBackButtonClick} />

      <FontAwesome
        className="far discuss-page__header__icon"
        name="question-circle"
      />
  
      <h2 className="discuss-page__header__heading">
        {props.question}
      </h2>
    </div>
  )
}
