import React, { FC } from 'react';
import Scrollchor from 'react-scrollchor';
import './_navigation-entry.scss';

const SCROLL_OFFSET_INCLUDING_NAVIGATION = -140;

interface IProps {
  children: React.ReactNode | string;
  targetHash: string;
};

export const NavigationEntry: FC<IProps> = (props: IProps) => {
  const {
    children,
    targetHash
  } = props;
  
  return (
    <li className="course-detail-page__navigation-entry">
      <Scrollchor
        animate={{duration: 600, offset: SCROLL_OFFSET_INCLUDING_NAVIGATION}}
        className="course-detail-page__navigation-entry__button"
        disableHistory
        to={targetHash}
      >
        {children}
      </Scrollchor>
    </li>
  )
}
