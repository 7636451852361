import Parser from '@u4i/utils/Parser';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {UserAnonymitySettingsEnum} from '@u4i/common/enums/UserAnonymitySettingsEnum';
import {UserRolesEnum} from '@u4i/common/enums/UserRolesEnum';
import {UserStatusEnum} from '@u4i/common/enums/UserStatusEnum';

import {ITermsOfService, TermsOfServiceParser} from './TermsOfServiceParser';

export interface IUser {
  anonymity: UserAnonymitySettingsEnum;
  email: string;
  firstname: string;
  fullName: string;
  groupId: string;
  groupLogoImage: string;
  groupName: string;
  homepage: string;
  id: string;
  impersonated: boolean;
  language: LanguageEnum;
  lastname: string;
  profileImage: string;
  role: UserRolesEnum;
  status: UserStatusEnum;
  superAdmin: boolean;
  tabs: { [tabId: string]: string };
  termsOfService: {
    generalTermsAccepted: boolean;
    requiredGroupTerms: Array<ITermsOfService>;
  };
  username: string
  userAddressData: any
}

export const UserParser = new Parser<IUser>(user => ({
  anonymity: user.anonymity,
  email: user.email,
  firstname: user.firstname,
  fullName: user.fullName,
  groupId: user.group_id,
  groupLogoImage: user.group_logo_image,
  groupName: user.group_name,
  homepage: user.tabs.home,
  id: user.id,
  impersonated: user.impersonated,
  language: user.language,
  lastname: user.lastname,
  profileImage: user.profile_image,
  role: user.role,
  status: user.status,
  superAdmin: user.super_admin,
  tabs: user.tabs,
  termsOfService: {
    generalTermsAccepted: user.terms_of_service.general_terms_accepted,
    requiredGroupTerms: TermsOfServiceParser.parseArray(user.terms_of_service.required_group_terms),
  },
  username: user.username,
  userAddressData: user.user_address
}));
