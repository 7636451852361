import {defineMessages} from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Oops!',
    id: 'DiscussPage.ErrorMessage.Heading',
  },
  message403: {
    defaultMessage: 'Sorry, you’re not authorized to access this page.'
      + ' If you think you should be authorized, please contact'
      + ' {mail}.',
    id: 'DiscussPage.ErrorMessage.Message403',
  },
  message404: {
    defaultMessage: 'The page you are looking for does not exist.'
      + ' The link might be outdated or temporarily unavailable.'
      + ' Try searching again or go to U4I’s home page.',
    id: 'DiscussPage.ErrorMessage.Message404',
  },
});
