import Parser from '@u4i/utils/Parser';

export interface IDiscussLike {
  createdAt: string;
  id: string;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    profileImage: string;
  };
}

const discussLikeParser = new Parser<IDiscussLike>(discussLike => ({
  createdAt: discussLike.created_at,
  id: discussLike.id,
  user: {
    firstName: discussLike.user.first_name,
    id: discussLike.user.id,
    lastName: discussLike.user.last_name,
    profileImage: discussLike.user.profile_image,
  },
}));

export default discussLikeParser;
