import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";
import { notification } from "antd";
import ElementComponentSwitcher from "./PlayerElements/ElementSwitcher";
import TitleElement from "./PlayerElements/TitleElement";
import { TableOfContentsElement } from "./PlayerElements/TableOfContentsElement";
import { SummaryElement } from "./PlayerElements/SummaryElement/SummaryElement";
import { FeedbackLinkElement } from "./PlayerElements/FeedbackLinkElement";
import { CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import ProvidersElement from "./PlayerElements/ProvidersElement";
import ReactFullpage from "@fullpage/react-fullpage";
import { PlayerElementTypesEnum } from "@u4i/common/enums/PlayerElementTypesEnum";
import { PlayerDisclaimer } from "../../components/PlayerDisclaimer";
import QuickFeedBack from "../../components/QuickFeedBack";
import { injectIntl } from "react-intl";
import { fullPageApi } from "../../config";
import debounce from "lodash.debounce";
import intlMessages from "../intlMessages";
import { mainBlue } from "@u4i/styles/styles-variables";
import { NotificationPlacement } from "antd/es/notification/interface";

const PlayerSlider = (props: any) => {
  const [showSlideNotification, setShowSlideNotification] =
    useState<boolean>(false);
  const [feedBackStatus, setFeedBackStatus] = useState<boolean>(false);
  const [activeSlide, setActiveSlide] = useState<any>(null);
  const fullpageApiRef = useRef<any>(null);

  const mouseTarget: any = window.document.getElementById("slider_player");
  const dotsList: any = window.document.querySelector(
    "#fp-nav ul, .fp-slidesNav ul"
  );
  const dotListParent: any = window.document.querySelector("#fp-nav");

  useEffect(() => {
    return () => {
      notification.destroy("feedback");
    };
  }, []);

  useEffect(() => {
    if (
      feedBackStatus &&
      props.skillID &&
      props.isUserAuth &&
      props.hasQuickFeedback
    ) {
      showFeedbackPopUp("bottomRight");
    }
  }, [feedBackStatus]);

  useEffect(() => {
    handleMovingDots();
  }, [dotsList]);

  useEffect(() => {
    if (
      !props.featching &&
      props.firstScrollPosition !== null &&
      props.firstScrollPosition === props.activeElementId
    ) {
      showLastElementViewedPopUp();
    }
  }, []);

  useEffect(() => {
    if (!props.featching && props.activeElementId && fullpageApiRef.current) {
      fullpageApiRef.current.moveTo(props.activeElementId);
      if (
        props.elements.length > 0 &&
        props.activeElementId === props.elements[props.elements?.length - 1].id
      ) {
        setFeedBackStatus(true);
      }
      handleMovingDots();
    }
  }, [props.activeElementId]);

  useEffect(() => {
    if (fullpageApiRef.current && mouseTarget) {
      mouseTarget.addEventListener("mouseleave", () => {
        fullpageApiRef.current.setAllowScrolling(false);
      });
      mouseTarget.addEventListener("mouseenter", () => {
        setTimeout(() => {
          fullpageApiRef.current.setAllowScrolling(true);
        }, 1000);
      });
    }
  }, [mouseTarget, fullpageApiRef.current]);

  const playerElements = () => {
    const elements = [
      {
        id: "intro",
        color: "white",
        component: <TitleElement />,
      },
    ];

    if (props.showProvidersSlide()) {
      elements.push({
        id: "providers",
        color: "white",
        component: <ProvidersElement />,
      });
    }

    props.contentsPaginated.forEach((entry, index) => {
      elements.push({
        color: "white",
        id: index === 0 ? "tableOfContents" : "tableOfContents" + index,
        component: (
          <TableOfContentsElement
            contents={entry}
            page={index + 1}
            pagesTotal={props.contentsPaginated.length}
          />
        ),
      });
    });

    props.elements?.forEach((element, index) => {
      elements.push({
        color:
          element.type === PlayerElementTypesEnum.NO_GUEST_PERMISSION
            ? "black"
            : "white",
        id: element.id,
        component: (
          <ElementComponentSwitcher
            elementData={element}
            elementPositionIndex={index}
            userStore={props.userStore}
            isUserAuth={props.isUserAuth}
            hasExtraElements={props.elements?.length > 20}
          />
        ),
      });
    });

    props.summariesPaginated.forEach((entry, index) => {
      elements.push({
        color: "white",
        id: index === 0 ? "summary" : `summary${index}`,
        component: (
          <SummaryElement
            page={index + 1}
            pagesTotal={props.summariesPaginated.length}
            showPDFDownload={
              props.showDownloadPdfButton &&
              index === props.summariesPaginated.length - 1
            }
            summaries={entry}
          />
        ),
      });
    });

    if (props.isActiveChapterLast && props.feedbackSurvey) {
      elements.push({
        color: "white",
        id: "feedback",
        component: (
          <FeedbackLinkElement
            onFeedbackSurveyStart={props.startFeedbackSurvey}
          />
        ),
      });
    }

    return elements;
  };

  const handleMovingDots = () => {
    const elementsRendered = playerElements();
    const lengthOfElements = elementsRendered.length * 21;
    const hightOfInnerPart = window.innerHeight - 100;

    if (dotsList) {
      if (lengthOfElements > hightOfInnerPart) {
        const indexFound = elementsRendered.findIndex((object) => {
          return object.id === props.activeElementId;
        });
        let calculations =
          lengthOfElements < hightOfInnerPart
            ? 0
            : (lengthOfElements - hightOfInnerPart) / elementsRendered.length;
        dotListParent.style.alignItems = "flex-start";
        dotsList.style.transform = `translateY(-${
          indexFound === elementsRendered.length - 1
            ? indexFound * (calculations + 0.3)
            : indexFound * calculations
        }px)`;
      } else {
        dotListParent.style.alignItems = "center";
        dotsList.style.transform = `translateY(0px)`;
      }
    }
  };

  const showLastElementViewedPopUp = () => {
    notification.info({
      message: props.intl.formatMessage(intlMessages.notificationContent),
      placement: "topRight",
      closeIcon: <CloseCircleFilled style={{ color: "white", fontSize: 24 }} />,
      duration: 3,
      key: "notify",
      style: { width: 600, background: mainBlue },
      className: "welcome_back_popup",
      icon: <InfoCircleFilled style={{ color: "white" }} />,
    });
  };

  const showFeedbackPopUp = (placement: NotificationPlacement) => {
    notification.open({
      message: props.intl.formatMessage(
        intlMessages.quickNotificationContentHeader
      ),
      key: "feedback",
      description: (
        <QuickFeedBack
          activeChapterData={props.activeChapterData}
          skillID={props.skillID}
          rateCourseByChapter={props.rateCourseByChapter}
          startFullFeedBack={props.startFeedbackSurvey}
          likely={props.intl.formatMessage(intlMessages.likely)}
          unlikely={props.intl.formatMessage(intlMessages.unlikely)}
          feedBackText={props.intl.formatMessage(intlMessages.thankYouFeedBack)}
          clickLinkText={props.intl.formatMessage(intlMessages.clickLink)}
          showFullFeedBack={props.feedbackSurvey}
        />
      ),
      placement,
      duration: 0,
      className: "feedback_review",
      icon: null,
    });
  };

  const updateSlidesFirstTime = debounce((destination, origin) => {
    if (props.isUserAuth) {
      props.onMarkCurrentlyViewed(
        props.firstScrollPosition !== null ? destination.anchor : "intro"
      );
      props.handleElementView(
        props.firstScrollPosition !== null ? destination.anchor : "intro"
      );
    }
    setShowSlideNotification(true);
  }, 10);

  const updateSlidesSecondTime = debounce((destination) => {
    if (props.isUserAuth) {
      props.onMarkCurrentlyViewed(destination.anchor);
      props.handleElementView(destination.anchor);
    }
    setShowSlideNotification(true);
  }, 0);

  useEffect(() => {
    const checkCurrentSlide = () => {
      const allClasses = Array.from(document.body.classList);
      const currentClass = allClasses.find((cls) =>
        cls.startsWith("fp-viewing-")
      );

      if (currentClass) {
        const currentId = currentClass.replace("fp-viewing-", "");

        const currentElement = props.elements.find((el) => el.id === currentId);
        if (currentElement && currentElement.type === "chatbot") {
          props.setIsChatbotHidden(true);
        } else {
          props.setIsChatbotHidden(false);
        }
      } else {
        props.setIsChatbotHidden(false);
      }
    };

    checkCurrentSlide();
  }, [props.elements, props.setIsChatbotHidden]);

  return (
    <div
      id="slider_player"
      className={cx({
        player_slider: true,
        fadein: true,
        [props.pageWidth]: true,
      })}
      style={{
        transition: props.isMenuActive ? "width 0.3s ease-in-out" : "",
      }}
    >
      {!props.featching && (
        <ReactFullpage
          licenseKey={"WF6KK-1SUBI-9MKL8-6J83I-NKZYN"}
          afterSlideLoad={(section, origin, destination, direction) => {
            setActiveSlide(destination.index);
          }}
          scrollingSpeed={1000}
          normalScrollElements=".ve_side_menu, .ant-modal-root"
          navigation={true}
          lockAnchors
          scrollOverflow={false}
          keyboardScrolling={true}
          anchors={playerElements().map((element) => {
            return element.id;
          })}
          sectionsColor={playerElements().map((element) => {
            return element.color;
          })}
          afterRender={() => {
            document.getElementById("slider_player")?.focus();
          }}
          afterLoad={(origin, destination) => {
            if (!showSlideNotification && fullpageApiRef.current) {
              fullpageApiRef.current.reBuild();
            }

            if (destination) {
              if (destination.index !== 0) {
                if (!showSlideNotification) {
                  updateSlidesFirstTime(destination, origin);
                } else {
                  updateSlidesSecondTime(destination);
                }
              }
            }
          }}
          onLeave={(origin, destination) => {
            props.setPageLocation((prev) => ({
              lastPage: destination.isLast,
              firstPage: destination.isFirst,
            }));
          }}
          render={({ state, fullpageApi }) => {
            fullpageApiRef.current = fullpageApi;
            return (
              <>
                {playerElements().map((element, index) => {
                  return (
                    <div className="section" key={element.id}>
                      <div
                        className={cx({
                          section_height_full: !props.showDisclaimer,
                          section_height_croped: props.showDisclaimer,
                        })}
                      >
                        {element.component}
                      </div>
                    </div>
                  );
                })}
              </>
            );
          }}
        />
      )}

      {props.showDisclaimer && (
        <PlayerDisclaimer
          content={props.showDisclaimer}
          isMenuActive={props.isMenuActive}
        />
      )}
    </div>
  );
};

export default injectIntl(PlayerSlider);
