import React, { FC } from 'react';
import compressIcon from '@u4i/assets/svg/compressIcon.svg';
import expandIcon from '@u4i/assets/svg/expandIcon.svg';

import './_fullscreen-button.scss';

interface IPropTypes {
  onFullscreenClick: () => void
  showExpandScreenButton: boolean
}

export const FullscreenButton: FC<IPropTypes> = (props: IPropTypes) => (
  <button className="jupyter-lab__fullscreen-button" onClick={props.onFullscreenClick} type="button">
    {props.showExpandScreenButton ?
      <img alt="expand screen" src={expandIcon} />
      :
      <img alt="compress screen" src={compressIcon} />
    }
  </button>
);
