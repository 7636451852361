import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React, { FC, useEffect } from 'react';
import {debounce} from 'lodash';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import intlMessages from './intlMessages';
import { Form, Switch, Typography } from 'antd';
import CircularProgress from '@u4i/common/CircularProgress';
import TextArea from 'antd/lib/input/TextArea';
import { DiscussAnswerPost } from '@u4i/modules/DiscussPage/state/DiscussPageStore';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import './_discuss-answer-form.scss';

const { Item } = Form;

interface IProps extends WrappedComponentProps {
  isAnonymityChecked: boolean
  isPageScrolled: boolean
  showAnonymitySettingsSwitch: boolean
  shouldFormMinimize: boolean
  submitSucceeded: boolean
  submitting: boolean
  handleCloseSuccessMessage: () => void
  handleSubmit: (values: DiscussAnswerPost) => Promise<any>
  onInputFocus: () => void
  onScroll: (scrollTop: any) => void
}

const DiscussAnswerForm: FC<IProps> = (props: IProps) => {
  const {
    intl,
    isAnonymityChecked,
    isPageScrolled,
    showAnonymitySettingsSwitch,
    shouldFormMinimize,
    submitSucceeded,
    submitting,
    handleCloseSuccessMessage,
    handleSubmit,
    onInputFocus,
    onScroll
  } = props;

  const [form] = Form.useForm();
  const {formatMessage} = intl;

  let answerFieldClientRect: any;
  let answerFieldRef: any = React.createRef();
  let answerFieldTop: any;
  let hasTransitioned: boolean = false;

  const answerMinLengthError: string = formatMessage(intlMessages.answerErrorsMinLength, {limit: 50});
  const answerPresenceError: string = formatMessage(intlMessages.answerErrorsPresence);
  const answerPlaceholder: string = formatMessage(intlMessages.answerPlaceholder);
  const showAnonymitySwitch: boolean = showAnonymitySettingsSwitch && !submitting && !submitSucceeded;

  useEffect(() => {
    const answerField: any = answerFieldRef.current;

    if (answerField) {
      answerFieldClientRect = answerField.getBoundingClientRect();
      answerFieldTop = answerFieldClientRect.top;

      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  })

  const handleScroll = debounce(() => {
    const answerField: any = answerFieldRef.current;

    if (answerField) {
      onScroll(answerFieldTop);
    }
  }, 10);

  const onAnswerFieldTransitionEnd = () => {
    hasTransitioned = true;
  };

  const inputFocus = () => {
    onInputFocus();

    if (isPageScrolled) {
      hasTransitioned = false;
    }
  };

  const onInputBlur = () => {
    if (isPageScrolled) {
      hasTransitioned = true;
    }
  };

  const handleDiscussAnswerFormSubmit = (values: DiscussAnswerPost) => {
    handleSubmit(values);
  }

  const onCloseSuccessMessage = () => {
    handleCloseSuccessMessage()
    form.resetFields();
  };

  return (
    <Form 
      className="discuss-answer-form" 
      form={form}
      name="discussAnswerForm"
      onFinish={handleDiscussAnswerFormSubmit}
    >
      <div
        className={cx({
          'discuss-answer-form__answer-field': true,
          'discuss-answer-form__answer-field--minimized': shouldFormMinimize,
          'discuss-answer-form__answer-field--transitioned': hasTransitioned,
        })}
        onTransitionEnd={onAnswerFieldTransitionEnd}
        ref={answerFieldRef}
      >
        <Item
          id="discuss-answer"
          name="answer"
          rules={[
            {
              validator: async (rule, value) => {
                if(!value) {
                  return Promise.reject(answerPresenceError);
                }
                if (value.trim().length > 0 && value.trim().length < 50) {
                  return Promise.reject(answerMinLengthError);
                }
              }
            }
          ]}
        >
          <TextArea 
            onBlur={onInputBlur}
            onFocus={inputFocus}
            placeholder={answerPlaceholder}
            rows={shouldFormMinimize ? 2 : 8} 
          />
        </Item>
      </div>

      {showAnonymitySwitch &&
        <div className="discuss-answer-form__fields-wrap">
          <div className="discuss-answer-form__anonymity-field">    
            <div style={{marginTop: 5}}>
              <Typography.Text style={{textTransform: 'uppercase'}} className="disable-invert"> 
                <FormattedMessage {...intlMessages.anonymousPosting} />
              </Typography.Text>
            </div>

            <Item
              id="discuss-answer-anonymous"
              name="anonymity"
              initialValue={isAnonymityChecked}
              style={{marginBottom: 0}}
            >
              <Switch 
                defaultChecked={isAnonymityChecked} 
              />
            </Item>
          </div>
        </div>
      }

      {submitSucceeded && 
        <div
          className={cx({
            'discuss-answer-form__overlay': true,
            'discuss-answer-form__overlay--visible': submitting || submitSucceeded,
            'discuss-answer-form__overlay--minimized': isPageScrolled,
            'discuss-answer-form__overlay--longer-height': !showAnonymitySwitch
          })}
        >
          <div
            className={cx({
              'discuss-answer-form__overlay-loading': true,
              'discuss-answer-form__overlay-loading--visible': submitting,
            })}
          >
            <CircularProgress />
          </div>

          <div
            className={cx({
              'discuss-answer-form__overlay-info': true,
              'discuss-answer-form__overlay-info--visible': submitSucceeded,
            })}
          >
            <FontAwesome className="discuss-answer-form__overlay-icon" name="check-circle" />

            <h5 className="discuss-answer-form__overlay-heading">
              <FormattedMessage {...intlMessages.overlayHeading} />
            </h5>

            <p className="discuss-answer-form__overlay-message">
              <FormattedMessage {...intlMessages.overlayMessage} />
            </p>

            <button
              className="discuss-answer-form__overlay-button"
              onClick={onCloseSuccessMessage}
              type="button"
            >
              <FormattedMessage {...intlMessages.overlayButtonLabel} />
            </button>
          </div>
        </div>
      }

      {(!submitting && !submitSucceeded) &&
        <div className="discuss-answer-form__submit-field">
          <SubmitButton centered={false} inline>
            <FormattedMessage {...intlMessages.submitTitle} />
          </SubmitButton>
        </div>
      }
    </Form>
  )
}

export default injectIntl(DiscussAnswerForm);