import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject} from 'mobx-react';

import AppNavigator, {appRoutes} from '@u4i/common/AppNavigator';
import {ActionsBar, DataTable, DataTableStore, TextFilter} from '@u4i/common/DataTable';
import {IRootStore} from '@u4i/state/RootStore';
import {SegementedBar} from '@u4i/common/SegementedBar';

import intlMessages from './intlMessages';
import { guardsmanRed, iron, mainBlue, green } from '@u4i/styles/styles-variables';

interface IPropTypes {
  rootStore: IRootStore;
}

interface RowDataShape {
  failedAttempts: number;
  groupId: string;
  groupName: string;
  passedAttempts: number;
  submittedAttempts: number;
  totalAttempts: number;
}

export const ProgressGroupsList = inject('rootStore')(class ProgressGroupsList extends React.Component<IPropTypes> {
  private dataTableStore: DataTableStore<RowDataShape>;

  constructor(props: IPropTypes) {
    super(props);

    this.dataTableStore = new DataTableStore<RowDataShape>({
      dataColumns: {
        groupName: {label: <FormattedMessage {...intlMessages.groupName} />, order: 1, width: 200},
        submittedAttempts: {
          centerContent: true,
          label: <FormattedMessage {...intlMessages.submitted} />,
          order: 2,
          sortable: false,
          width: 120,
        },
        failedAttempts: {
          centerContent: true,
          label: <FormattedMessage {...intlMessages.failed} />,
          order: 3,
          sortable: false,
          width: 120,
        },
        passedAttempts: {
          centerContent: true,
          label: <FormattedMessage {...intlMessages.passed} />,
          order: 4,
          sortable: false,
          width: 120,
        },
        totalAttempts: {
          centerContent: true,
          label: <FormattedMessage {...intlMessages.total} />,
          order: 5,
          sortable: false,
          width: 120,
        },
      },
      dataProvider: this.tableDataProvider,
      filters: [
        new TextFilter('groupName', 'groupName'),
      ],
      functionalColumns: {
        chart: {
          centerContent: true,
          content: (rowData) => {
            if (rowData.totalAttempts === 0) {
              return <SegementedBar segments={[{color: iron, sizeFraction: 1}]} />;
            }

            return (
              <SegementedBar
                segments={[{
                  color: mainBlue,
                  label: <FormattedMessage {...intlMessages.submitted} />,
                  sizeFraction: rowData.submittedAttempts / rowData.totalAttempts,
                }, {
                  color: guardsmanRed,
                  label: <FormattedMessage {...intlMessages.failed} />,
                  sizeFraction: rowData.failedAttempts / rowData.totalAttempts,
                }, {
                  color: green,
                  label: <FormattedMessage {...intlMessages.passed} />,
                  sizeFraction: rowData.passedAttempts / rowData.totalAttempts,
                }]}
              />
            );
          },
          label: <FormattedMessage {...intlMessages.chart} />,
          order: 6,
          width: 200,
        },
        actions: {
          centerContent: true,
          content: (rowData) => (
            <ActionsBar
              buttons={[{
                iconName: 'eye',
                onClick: this.handleViewClick,
                tooltipLabel: <FormattedMessage {...intlMessages.view} />,
                visible: true,
              }]}
              rowId={rowData.groupId}
            />
          ),
          label: <FormattedMessage {...intlMessages.actions} />,
          order: 7,
          width: 80,
        },
      },
      pagination: {
        enabled: true,
        itemsPerPage: 10,
      },
      tableId: 'progress-groups-list',
    }, props.rootStore);
  }

  handleViewClick = (groupId: string) => {
    AppNavigator.push(appRoutes.gradingProgressGroup, {groupId});
  };

  tableDataProvider = async (
    sortedColumnId: string | undefined,
    sortDirection: 'ASC' | 'DESC',
    filters: { [columnName in keyof RowDataShape]?: RowDataShape[columnName] },
    paginationInfo: {limit: number; offset: number;} | undefined,
  ): Promise<{items: Array<RowDataShape>, totalItems: number}> => {
    const data = await this.props.rootStore.apiService.grading.fetchGroupsProgressList({
      filters,
      limit: paginationInfo ? paginationInfo.limit : 10,
      offset: paginationInfo ? paginationInfo.offset : 0,
      sortDirection,
      sortedColumnId,
    });

    return {
      items: data.items.map(item => ({
        failedAttempts: item.attemptsCount.failed,
        groupId: item.groupId,
        groupName: item.groupName,
        passedAttempts: item.attemptsCount.passed,
        submittedAttempts: item.attemptsCount.submitted,
        totalAttempts: item.attemptsCount.total,
      })),
      totalItems: data.totalItems,
    };
  };

  render() {
    return (
      <div className="container">
        <DataTable store={this.dataTableStore} />
      </div>
    );
  }
});
