import is from "is_js";
import React from "react";
import { FormattedMessage } from "react-intl";
import { inject } from "mobx-react";
import api from "@u4i/utils/api";
import IRootStore from "@u4i/state/RootStore";
import { Alert } from "@u4i/common/Alert";
import { AlertStatusEnum } from "@u4i/common/enums/AlertStatusEnum";
import { ChallengePlatformEnum } from "@u4i/common/enums/ChallengePlatformEnum";
import { IStorage } from "@u4i/state/ServicesInterfaces";
import { ActionBlock } from "./ActionBlock";
import intlMessages from "./intlMessages";
import LiveLabStatusCodesEnum from "@u4i/common/enums/LiveLabStatusCodesEnum";
import { PlayerContext } from "@u4i/modules/VirtualExperience/PlayerState/playerContext";
import { withCalculatedFontSize } from "@u4i/modules/VirtualExperience/components/withCalculatedFontSize";
import { postStatement } from "@u4i/common/TraxLrs";
import { XAPIVerbs } from "@u4i/common/enums/XApiEnum";
import "./_external-challenge-element.scss";

interface IPropTypes {
  calculatedFontSize: number;
  challenge: {
    description?: string;
    jupyterLabId?: string;
    digitalLabId?: string;
    provider: ChallengePlatformEnum;
    url: string;
  };
  elementId: string;
  rootStore: IRootStore;
  startChallenge: any;
  title: string;
}

interface IState {
  blockTime: number;
  fetching: boolean;
  permissionError: boolean;
}

const ExternalChallengeElement = inject("rootStore")(
  class ExternalChallengeElement extends React.Component<IPropTypes, IState> {
    private blockReductionInterval: ReturnType<typeof setTimeout> | null = null;
    private storageService: IStorage;
    state = {
      blockTime: 0,
      fetching: false,
      permissionError: false,
    };

    static contextType = PlayerContext;

    constructor(props: IPropTypes) {
      super(props);

      const { storageService } = this.props.rootStore;

      this.storageService = storageService;
    }

    componentWillUnmount() {
      if (this.blockReductionInterval) {
        clearInterval(this.blockReductionInterval);
      }
    }

    get isDigitalLabsAndDisabled() {
      return (
        this.props.challenge.provider === ChallengePlatformEnum.DIGITAL_LABS &&
        is.mobile()
      );
    }

    get isJupyterLabsAndDisabled() {
      return (
        this.props.challenge.provider === ChallengePlatformEnum.JUPYTER_LABS &&
        is.mobile()
      );
    }

    checkLiveLabAvailability() {
      this.setState({ fetching: true }, async () => {
        const response = await api.get(`challenges/${this.props.elementId}/get-live-lab-status`);

        this.setState({ fetching: false }, () => {
          switch (response.data) {
            case LiveLabStatusCodesEnum.AVAILABLE:
              this.context.startChallenge(this.props.challenge, this.props.elementId);
              break;

            case LiveLabStatusCodesEnum.BUSY:
              this.setState({ blockTime: 60 }, () => {
                this.blockReductionInterval = setInterval(this.reduceBlockTime, 1000);
              });
              break;

            case LiveLabStatusCodesEnum.UNAUTHORIZED:
              this.setState({ permissionError: true });
              break;

            default:
              this.context.startChallenge(this.props.challenge, this.props.elementId);
          }
        });
      });
    }

    handleStartClick = () => {
      if (this.props.challenge.provider === ChallengePlatformEnum.LIVE_LABS) {
        this.checkLiveLabAvailability();
      } else {
        if (this.props.challenge.provider === ChallengePlatformEnum.DIGITAL_LABS) {
          this.storageService.showDigitalLabNotification.empty();
        }

        if (this.props.challenge.provider === ChallengePlatformEnum.JUPYTER_LABS) {
          this.storageService.showJupyterLabNotification.empty();
        }
        this.context.startChallenge(this.props.challenge, this.props.elementId);  
        
        postStatement(XAPIVerbs.experienced, 'element', this.props.elementId, this.props.title);
      }
    };

    reduceBlockTime = () => {
      this.setState(
        (state) => ({ blockTime: state.blockTime - 1 }),
        () => {
          if (this.state.blockTime === 0 && this.blockReductionInterval) {
            clearInterval(this.blockReductionInterval);
          }
        }
      );
    };

    render() {
      return (
        <div
          className="player__external-challenge-element"
          style={{ fontSize: this.props.calculatedFontSize }}
        >
          <h3 className="player__external-challenge-element__title">
            {this.props.title}
          </h3>

          {this.props.challenge.description && (
            <p className="player__external-challenge-element__description">
              {this.props.challenge.description}
            </p>
          )}

          {this.props.challenge.provider !== ChallengePlatformEnum.EMAIL && (
            <ActionBlock
              blockTime={this.state.blockTime}
              disabled={
                this.state.fetching ||
                this.state.blockTime > 0 ||
                this.state.permissionError ||
                this.isDigitalLabsAndDisabled ||
                this.isJupyterLabsAndDisabled
              }
              onButtonClick={this.handleStartClick}
              permissionError={this.state.permissionError}
              showLoading={this.state.fetching}
              showWarning={this.state.blockTime > 0}
            />
          )}

          {this.isDigitalLabsAndDisabled && (
            <Alert
              heading={<FormattedMessage {...intlMessages.labsMobileHeading} />}
              message={
                <FormattedMessage {...intlMessages.digitalLabsMobileMessage} />
              }
              type={AlertStatusEnum.WARNING}
            />
          )}

          {this.isJupyterLabsAndDisabled && (
            <Alert
              heading={<FormattedMessage {...intlMessages.labsMobileHeading} />}
              message={
                <FormattedMessage {...intlMessages.jupyterLabsMobileMessage} />
              }
              type={AlertStatusEnum.WARNING}
            />
          )}
        </div>
      );
    }
  }
);

const WrappedComponent:any = withCalculatedFontSize(ExternalChallengeElement);

export { WrappedComponent as ExternalChallengeElement };
