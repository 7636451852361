import React, { FC } from 'react';
import {LanguageEnum} from '@u4i/common/enums/LanguageEnum';
import {LanguageSwitch} from './LanguageSwitch';
import {Menu} from './Menu';

import './_subnavigation.scss';

interface IPropTypes {
  activeItemId: number | string
  activeItemsLanguage: LanguageEnum | null
  displayLanguageFilter: boolean
  items?: Array<{
    id: string
    name: string
    sortOrder: number
  }>
  menuDropdownLabel: JSX.Element | React.ReactNode
  onLanguageItemClick: (newItemsLanguage: LanguageEnum | null) => void
  onMenuItemClick: (sectionId: string) => void
}

export const Subnavigation: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="subnavigation">
    <div className="container">
      <div className="subnavigation__content" id='area'>
        {props.items &&
          <Menu
            activeItemId={props.activeItemId}
            items={props.items}
            menuDropdownLabel={props.menuDropdownLabel}
            onMenuItemClick={props.onMenuItemClick}
          />
        }

        {props.displayLanguageFilter &&
          <LanguageSwitch
            activeItemsLanguage={props.activeItemsLanguage}
            onLanguageItemClick={props.onLanguageItemClick}
          />
        }
      </div>
    </div>
  </div>
);
