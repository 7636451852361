import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import UserStore from '@u4i/state/UserStore';
import { Modal } from 'antd';
import intlMessages from './intlMessages';
import { ButtonTypeEnum } from '@u4i/common/enums/ButtonTypeEnum';
import { PasswordRecoveryForm } from '@u4i/common/forms/PasswordRecoveryForm/PasswordRecoveryForm';
import { RouteComponentProps, withRouter } from 'react-router';
import U4IButton from '@u4i/common/ButtonU4I/U4IButton';
import './_password-recovery-modal.scss';

interface IProps extends RouteComponentProps{
  userStore: UserStore
}

const PasswordRecoveryModal: FC<IProps> = inject("rootStore")(
  observer((props: IProps) => {
    const {
      isPasswordRecoveryModalVisible,
      passwordRecoverySubmitted,
      handlePasswordRecoveryFormSubmission,
      loginModalHandle,
      passwordRecoveryModalHandle
    } = props.userStore;

    const onGoBack = () => {
      passwordRecoveryModalHandle(false);
      loginModalHandle(true);
    }

    return (
      <Modal
        centered
        footer={null}
        mask={true}
        maskClosable={false}
        onCancel={() => passwordRecoveryModalHandle(false)}
        open={isPasswordRecoveryModalVisible}
        title={<h3 style={{textAlign:'center', fontWeight: 500}}><FormattedMessage {...intlMessages.modalTitle} /></h3>}
        style={{minWidth: '25%'}}
      >
        <PasswordRecoveryForm 
          handleSubmit={handlePasswordRecoveryFormSubmission} 
          submitSucceeded={passwordRecoverySubmitted}
        />

        <U4IButton
          className="password-recovery-modal__switch-button"
          muted
          onClick={onGoBack}
          type={ButtonTypeEnum.ACTION}
        >
          <span className='disable-button-invert'>
          <FormattedMessage {...intlMessages.goBack} />
          </span>
        </U4IButton>
      </Modal>
    )
  })
)

export default withRouter(PasswordRecoveryModal)