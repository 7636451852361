
import { malachite, white } from '@u4i/styles/styles-variables';
import React, { useState } from 'react';
import { opacity7 } from '../../constants/Constants';

interface IPropTypes {
  cy: number
  phase: any
  r: number
  onPhaseIconClick: () => void
}

export const PhaseInProgressIcon = (props: IPropTypes) => {
  const {
    cy,
    phase,
    onPhaseIconClick
  } = props;

  const {
    CXOfPhaseIcon,
    phaseId,
  } = phase;

  const [opacity, setOpacity] = useState<boolean>(false);

  const handleOpacity = () => {
    opacity ? setOpacity(false) : setOpacity(true);
  }

  return(
    <svg  
      id={phaseId}
      onClick={() => onPhaseIconClick()}
      onMouseEnter={handleOpacity}
      onMouseLeave={handleOpacity}
      style={{ cursor: 'pointer', userSelect: 'none', ...(opacity && { opacity: opacity7 }) }}
    >
      <ellipse
        style={{
          fill: white,
          stroke: malachite,
          strokeWidth: 8,
          strokeMiterlimit: 4
        }}
        cx={CXOfPhaseIcon}
        cy={cy}
        rx={38.5}
        ry={38.5}
      />
      <title>{phase.longTitle}</title>
      <g>
        <circle
          style={{
            fill: malachite,
            fillOpacity: 1,
            strokeWidth: 12.5,
            strokeMiterlimit: 4,
          }}
          cx={CXOfPhaseIcon + 0.2}
          cy={cy + 0.2}
          r={4}
        />
        <circle
          style={{
            fill: malachite,
            strokeWidth: 12.5,
            strokeMiterlimit: 4,
          }}
          cx={CXOfPhaseIcon + 16.2}
          cy={cy + 0.2}
          r={4}
        />
        <circle
          style={{
            fill: malachite,
            strokeWidth: 12.5,
            strokeMiterlimit: 4,
          }}
          cx={CXOfPhaseIcon - 15.8}
          cy={cy + 0.2}
          r={4}
        />
      </g>
    </svg>
  )
}
