import cx from 'classnames';
import React, { FC } from 'react';
import IconHoverable from '@u4i/common/IconHoverable';
import menuIconHovered from '@u4i/assets/svg/menuIconHovered.svg';
import menuIconNonHovered from '@u4i/assets/svg/menuIconNonHovered.svg';

import './_mobile-menu-trigger.scss';

interface IProps {
  keepActive: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MobileMenuTrigger: FC<IProps> = (props: IProps) => {
  return (
    <button
      className={cx({
        'main-layout__mobile-menu-trigger': true,
        'main-layout__mobile-menu-trigger--keep-active': props.keepActive,
      })}
      onClick={props.onClick}
      type="button"
    >
      <IconHoverable
        alt="Open user menu"
        iconHovered = {menuIconHovered}
        iconNonHovered = {menuIconNonHovered}
        isActive={props.keepActive}
      />
    </button>
  );
}

export default MobileMenuTrigger;
