import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { IRootStore } from '@u4i/state/RootStore';
import { IApi, IPaginatedItems } from '@u4i/state/ServicesInterfaces';
import { TablePaginationConfig } from 'antd/es/table';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { IProvider, IProviderPost } from '../interfaces';
import { Notification, NotificationEnum } from '@u4i/modules/Admin/common/Notifications/Notification';
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';
import moment from 'moment';

export class AdminProvidersStore {
  private apiService: IApi;

  public apiConfig: AntDTableFilters<IProvider> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {},
  };

  public provideryId: IProvider | undefined;
  public providerById: IProvider | undefined;
  public fetching: boolean = true;
  public tableData: Array<IProvider> = [];
  public pagination: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      apiConfig: observable,
      createProvider: action.bound,
      getProviderById: action.bound,
      getAllProviders: action.bound,
      providerById: observable,
      fetching: observable,
      resetData: action.bound,
      tableData: observable,
      pagination: observable,
      onTableChange: action.bound,
  });
    const { apiService } = rootStore;
    this.apiService = apiService;
  }
  
  async onTableChange(
    pagination: AntDPagination, 
    filters: { [columnName in keyof IProvider]?: IProvider[columnName] }, 
    sorter: AntDSorter<IProvider>
  ) {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};

    Object.keys(filters).forEach((key: string) => {
      if (filters[key] !== null) {
        if (key === 'createdAt' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['createdAtFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['createdAtTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });
    
    if (sorter.field) {
      orderBy[sorter.field === 'createdAt' ? 'created_at' : sorter.field] = sortDirection;
    }

    this.apiConfig = {
      ...this.apiConfig,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.pagination = {
      ...pagination,
      current
    }

    await this.getAllProviders(this.apiConfig);

    this.apiConfig = {
      ...this.apiConfig,
      filters: {}
    };
  }

  async getAllProviders(config: AntDTableFilters<IProvider>) {
    this.fetching = true;
    this.apiConfig = config;

    try {
      const ProvidersListPaginated: IPaginatedItems<IProvider> = await this.apiService.admin.providers.fetchProviderData(this.apiConfig);

      const { items, totalItems } = ProvidersListPaginated;
      this.pagination = {
        ...this.pagination,
        pageSize: this.apiConfig.limit,
        total: totalItems
      };

      runInAction(() => (this.tableData = items));

    } catch (error) {
      Notification(NotificationEnum.Error, "Fetch Providers", error)

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetching = false;
    }
  }

  async createProvider(model: IProviderPost) {
    try {
      await this.apiService.admin.providers.createProvider(model).then(res => {
        Notification(NotificationEnum.Success, "Create Provider", `Provider \"${model.name}\" is added.`);
        let providerId = res?.id;
        AppNavigator.push(appRoutes.adminProviderEdit, {providerId: providerId} );
      })
    }

    catch (error) {
      const createProviderError = error.response.data?.validationErrors?.image || error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Create Provider", createProviderError);
    }
  }

  async getProviderById(id: string) {
    this.fetching = true;

    try {
      this.providerById = await this.apiService.admin.providers.fetchProviderById(id);
    }
    catch (error) {
      throw (error);
    }
    finally {
      this.fetching = false;
    }
  }


  async updateProvider(id: string, model: IProviderPost) {
    try {
      await this.apiService.admin.providers.updateProvider(id, model).then(res => {
 
      Notification(NotificationEnum.Success, "Update Provider", `Provider \"${model.name}\" is updated.`);
      });
    }
    catch (error) {
      const updateProviderError = error.response.data?.validationErrors?.image || error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Update Provider", updateProviderError);
    }
  }

  async removeProviderImageLogo(providerId: string) {
    try {
      await this.apiService.admin.providers.removeProviderImageLogo(providerId).then(res => {
        if(res) {
          this.providerById!.url = undefined;
        }
        Notification(NotificationEnum.Success, "Remove Provider Image", `Provider image was removed successfully from database.`);
      });
    }

    catch (error) {
      const removeLogoImageError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Remove Provider Image", removeLogoImageError);
    }
  }

  resetData() {
    this.providerById = undefined;
    this.fetching = true;
  }
}