/**
* @dev_date June 2022
* @desc This component handles store for Checkout 
* @author Denis Shtupa denis.shtupa@u4i.io
*/

import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { PaymentTypesEnum } from '@u4i/common/enums/PaymentTypesEnum';
import { ICourse } from '@u4i/modules/CourseDetailPage/interfaces/CourseInterface';
import { IRootStore } from '@u4i/state/RootStore';
import { IApi } from '@u4i/state/ServicesInterfaces';
import {omit} from 'lodash';
import { action, makeObservable, observable } from 'mobx';

export class CheckoutPurchaseStore {
  private apiService: IApi;
  public isEnrolling: boolean = false;
  public isCourseLoading: boolean = false;
  public course: ICourse;
  public isCoursePurchased: boolean = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      isEnrolling: observable,
      isCourseLoading: observable,
      isCoursePurchased: observable,
      course: observable,
      enrollCourse: action.bound,
      loadCourse: action.bound,
      startPayment: action.bound,
      resetState: action.bound
    });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  enrollCourse = async (courseId: string, courseSlug: string) => {
    this.isEnrolling = true;
    try {
      await this.apiService.skills.enrollCourse(courseId).then(res => {
        AppNavigator.push(appRoutes.courseDetailPage, {skillSlug: courseSlug});
      })
    } catch (error) {
        
    } finally {
      this.isEnrolling = false;
    }
  }

  loadCourse = async (courseSlug: string) => {
    this.isCourseLoading = true;
    try {
      await this.apiService.skills.getCourseData(courseSlug).then((res: ICourse) => {
        if(res) {
          this.course = res;
        }
      });
    } catch (error) {
        
    } finally {
      this.isCourseLoading = false;
    }
  }

  startPayment = async (courseId: string, courseSlug: string, values: any) => {
    let additionalTerms: any[] = [];
    if (values.additionalTos) {
      Object.keys(values.additionalTos).forEach((key) => {
        let additionalTerm = { accepted: values.additionalTos[key] === true, id: key };
        additionalTerms.push(additionalTerm);
      });
    }

    let finalValues = omit(values, 'additionalTos');
    let data = { skill_id: courseId, paymentType: PaymentTypesEnum.Invoice };

    Object.assign(data, finalValues);

    try {
      const response = await this.apiService.skills.purchaseCourse(data).then(res => {
        if(res.id) {
          this.isCoursePurchased = true;
        }
        AppNavigator.push(appRoutes.courseDetailPage, {skillSlug: courseSlug});
      });

      return response;
    } catch (error) {
        
    }
  }

  resetState = () => {
    this.isCourseLoading = false;
    this.isEnrolling = false;
    this.isCoursePurchased = false;
  }

}
