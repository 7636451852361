const GREYSCALE_MAX = 255;
const HSP_BLUE_WEIGHT = 0.114;
const HSP_GREEN_WEIGHT = 0.587;
const HSP_RED_WEIGHT = 0.299;

class ImageProcessor {
  image;

  constructor(image) {
    this.image = image;
  }

  static createImageBitmap(blob) {
    return new Promise((resolve) => {
      const img = document.createElement('img');

      img.addEventListener('load', function imageLoadHandler() {
        resolve(this);
      });

      img.src = URL.createObjectURL(blob);
    });
  }

  static loadImageFromUrl(imageUrl) {
    return new Promise((resolve) => {
      const request = new XMLHttpRequest();

      request.addEventListener('load', () => {
        resolve(request.response);
      });

      request.open('GET', imageUrl);
      request.responseType = 'blob';
      request.send();
    });
  }

  async getAveragePerceivedImageBrightness() {
    const imageDataDescriptor = await this.getImageData();
    const imageData = imageDataDescriptor.data;

    let totalBrightness = 0;

    for (let pointer = 0; pointer < imageData.length; pointer += 4) {
      const redWeight = (imageData[pointer] ** 2) * HSP_RED_WEIGHT;
      const greenWeight = (imageData[pointer + 1] ** 2) * HSP_GREEN_WEIGHT;
      const blueWeight = (imageData[pointer + 2] ** 2) * HSP_BLUE_WEIGHT;

      totalBrightness += Math.sqrt(redWeight + greenWeight + blueWeight);
    }

    const imageSize = imageDataDescriptor.width * imageDataDescriptor.height;
    const averageBrightness = Math.floor(totalBrightness / imageSize);
    const averageBrightnessPercentage = averageBrightness / GREYSCALE_MAX;

    return parseFloat(averageBrightnessPercentage.toFixed(2));
  }

  async getImageData() {
    const imageBitmap = await ImageProcessor.createImageBitmap(this.image);

    const canvas: any = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(imageBitmap, 0, 0);

    return ctx.getImageData(0, 0, canvas.width, canvas.height);
  }

  getImageURL() {
    return URL.createObjectURL(this.image);
  }
}

export default ImageProcessor;
