import {AxiosInstance} from 'axios';
import {AdminApi} from './apiModules/AdminApi';
import {DigitalLabApi} from './apiModules/DigitalLabApi';
import {DiscussApi} from './apiModules/DiscussApi';
import {ExercisesApi} from './apiModules/ExercisesApi';
import {GradingApi} from './apiModules/GradingApi';
import {IApi} from '../ServicesInterfaces';
import {JupyterLabApi} from './apiModules/JupyterLabApi';
import {LandingPagesApi} from './apiModules/LandingPagesApi';
import {NotificationsApi} from './apiModules/NotificationsApi';
import {OnboardingApi} from './apiModules/OnboardingApi';
import {SettingsApi} from './apiModules/SettingsApi';
import {SkillsApi} from './apiModules/SkillsApi';
import {UserApi} from './apiModules/UserApi';
import { RedeemPageApi } from './apiModules/RedeemPageApi';
import { XAPIApi } from './apiModules/XAPIApi';

class ApiService implements IApi {
  private apiClient: AxiosInstance;
  admin: AdminApi;
  digitalLab: DigitalLabApi;
  discuss: DiscussApi;
  exercises: ExercisesApi;
  grading: GradingApi;
  jupyterLab: JupyterLabApi;
  landingPages: LandingPagesApi;
  notifications: NotificationsApi;
  onboarding: OnboardingApi;
  redeemPage: RedeemPageApi;
  settings: SettingsApi;
  skills: SkillsApi;
  user: UserApi;
  xapiapi: XAPIApi;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;

    this.admin = new AdminApi(this.apiClient);
    this.digitalLab = new DigitalLabApi(this.apiClient);
    this.discuss = new DiscussApi(this.apiClient);
    this.exercises = new ExercisesApi(this.apiClient);
    this.grading = new GradingApi(this.apiClient);
    this.jupyterLab = new JupyterLabApi(this.apiClient);
    this.landingPages = new LandingPagesApi(this.apiClient);
    this.notifications = new NotificationsApi(this.apiClient);
    this.onboarding = new OnboardingApi(this.apiClient);
    this.redeemPage = new RedeemPageApi(this.apiClient);
    this.settings = new SettingsApi(this.apiClient);
    this.skills = new SkillsApi(this.apiClient);
    this.user = new UserApi(this.apiClient);
    this.xapiapi = new XAPIApi(this.apiClient);
  }
}

export default ApiService;
