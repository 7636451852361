import { IProgramModule } from "@u4i/modules/Admin/modules/Programs/interfaces";
import Parser from "@u4i/utils/Parser";

export const AdminProgramModulesParser = new Parser<IProgramModule>(p => ({
  id: p.id,
  duration: p.duration,
  resource_url: p.resource_url,
  icon: p.icon,
  internal_id: p.internal_id,
  module_type: p.module_type,
  progress: p.progress,
  order: p.order,
  resource_type: p.resource_type,
  resource_id: p.resource_id,
  title: p.title,
  required: p.required
}));
