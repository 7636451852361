import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import AnswerLike from './AnswerLike';
import intlMessages from './intlMessages';
import DiscussLikeEntity from '@u4i/state/entities/DiscussLikeEntity';
import { Modal } from 'antd';

interface IProps {
  isVisible: boolean
  likes: DiscussLikeEntity[]
  handleModalClose: () => void
}

export const AnswerLikesModal: FC<IProps> = (props: IProps) => {
  const {
    isVisible,
    likes,
    handleModalClose
  } = props;

  return (
    <Modal
      centered
      mask={true}
      maskClosable={true}
      footer={null}
      onCancel={handleModalClose}
      open={isVisible}
      title={<h3 style={{textAlign:'center'}}><FormattedMessage {...intlMessages.modalTitle} /></h3>}
      style={{minWidth: '15%'}}
    >
      <ul className="list-unstyled">
        {likes?.map((likeEntity: DiscussLikeEntity) => (
          <li key={likeEntity.id}>
            <AnswerLike entity={likeEntity} />
          </li>
        ))}
      </ul>
    </Modal>
  )
}
