import {AxiosInstance} from 'axios';

import {IScormProgress, ScormProgressParser} from '@u4i/parsers/ScormProgressParser';
import {ICourse, ICourseList} from '@u4i/modules/CourseDetailPage/interfaces/CourseInterface';
import { SKILL_DETAIL_PAGE_ITEMS_PER_PAGE } from '@u4i/constantSettings';

export class SkillsApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  enrollCourse = async (courseId: string):  Promise<any> => {
    const response = await this.apiClient.post(`/my-skills/enroll`, { skill_id: courseId });

    return response.data;
  }

  enrollProgramsCourse = async (courseSlug: string, programId: string):  Promise<any> => {
    const response = await this.apiClient.post(`/my-skills/enroll-from-program`, { skill_slug: courseSlug, program_id: programId });
    return response.data;
  }

  fetchScormProgress = async (skillId: string): Promise<IScormProgress> => {
    const response = await this.apiClient.get(`/v1/skills/${skillId}/scorm-progress`);

    return ScormProgressParser.parse(response.data);
  }

  getCourseData = async (slugOrCourseId: string): Promise<ICourse> =>{
    const response = await this.apiClient.get(`/v1/skills/${slugOrCourseId}`);

    return response.data;
  }

  getChapterData = async (courseSlug: string, page: number): Promise<any> => {
    const response = await this.apiClient.get(`/v1/skills/${courseSlug}/chapters`, {
      params: {
        limit: SKILL_DETAIL_PAGE_ITEMS_PER_PAGE,
        offset: (page - 1) * SKILL_DETAIL_PAGE_ITEMS_PER_PAGE,
      },
    });
    let totalItems: any = response.headers['x-total-count'];

    const data = {
      data: response.data,
      totalItems: totalItems,
      totalPages: Math.max(Math.ceil(totalItems / SKILL_DETAIL_PAGE_ITEMS_PER_PAGE), 1)
    }
    return data;
  }

  enrollFreeCourse = async (slugOrCourseId: string): Promise<any> => {
    const response = await this.apiClient.post('my-skills/enroll', {skill_id: slugOrCourseId});

    return response.data;
  }

  recordCourseVisit = async (courseSlug: string): Promise<any> => {
    const response = await this.apiClient.post(`/v1/skills/${courseSlug}/update-last-accessed-timestamp`)

    return response.data;
  }

  purchaseCourse = async (model: any): Promise<any> => {
    const response = await this.apiClient.post(`/skills/purchase`, model);

    return response.data;
  }

  getListCoursesSimple = async (): Promise<ICourseList> => {
    const response = await this.apiClient.get(`/admin/skills/list-simple`);

    return response.data;
  }
}
