import React from 'react';
import {withRouter} from 'react-router-dom';
import { RouteComponentProps } from '@reach/router';

interface IPropTypes extends RouteComponentProps {
  match?: any
}

// TODO: handle typing in a better way
export const remountOnRouteParamChange = (...paramNames: Array<string>) => {
  return <T extends React.ComponentType>(InnerComponent: T): T => {
    class WrappedComponent extends React.Component<IPropTypes> {
      render() {
        const paramsValues = paramNames.map(param => this.props.match.params[param]);
        const keyString = paramsValues.join(';');

        return <InnerComponent {...this.props as any} key={keyString} />;
      }
    }

    return withRouter(WrappedComponent as any) as any;
  };
}
