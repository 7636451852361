import React from "react";
import {
  PlayCircleFilled,
  FileFilled,
  UpOutlined,
  DownOutlined,
  EyeFilled,
  CloseCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Collapse, Row, Col, Typography, Divider, notification } from "antd";
import { sliceText } from "@u4i/utils/helpers";
import ChapterItem from "./ChapterItem";
import { FormattedMessage, injectIntl } from "react-intl";
import { fullPageApi } from "../../config";
import intlMessages from "./intlMessages";
import { mainBlue } from "@u4i/styles/styles-variables";

const ChapterList = (props: any) => {
  const handleTopicClick = (chapter, selectedTopic, chapterIndex) => {
    if (props.activeChapterId === chapter.id) {
      let topicsList: any = [];

      props.chapters
        .map((chapter) => chapter.topics)
        .forEach((arr) => {
          arr.forEach((element) => {
            topicsList.push(element);
          });
        });

      const topicData = topicsList.find(
        (topic) => topic.id === selectedTopic.id
      );

      const targetTopic = props.topics.find(
        (entry) => entry.slug === topicData.slug
      );

      if (targetTopic) {
        const firstElementId = targetTopic.elementsIds[0] || targetTopic.id;
        fullPageApi.moveSectionTo(firstElementId);
      }
    }

    if (props.activeChapterId !== chapter.id) {
      if (!canAccessChapter(chapter, chapterIndex))
        return props.handleChapterClick(chapter);

      return showNotification();
    }
  };

  const handleSummeryClick = (chapter) => {
    if (props.activeChapterId === chapter.id)
      fullPageApi.moveSectionTo("summary");
  };

  const renderAccordian = (accordian) => {
    if (accordian.isActive)
      return (
        <UpOutlined
          style={{
            fontSize: 15,
          }}
        />
      );
    return (
      <DownOutlined
        style={{
          fontSize: 15,
        }}
      />
    );
  };

  const viewedTopicsCount = (chapter) => {   
    return chapter.topics.filter((item) => {
      return item.viewed;
    }).length;
  };

  const previousTopicViewCount = (chapterIndex) => {
    return props.chapters[chapterIndex - 1].topics.filter((item) => {
      return item.viewed;
    }).length;
  };

  const checkChapterCompletion = (chapter) => {
    if (viewedTopicsCount(chapter) >= chapter.topics.length / 2) return true;
    return false;
  };

  const canAccessChapter = (chapter, chapterIndex) => {
    if (chapterIndex === 0) return false;
    if (!props.hasMandatoryChapters) return false;
    if (chapter.id === props.activeChapterId) return false;
    if (viewedTopicsCount(chapter) >= chapter.topics.length / 2) return false;
    if (chapter.previousViewedStatus) return false;
    if (
      props.chapters[chapterIndex - 1].topics.length ===
      previousTopicViewCount(chapterIndex) &&
      chapterIndex !== 0 &&
      !props.activeChapterData.hasMandatoryChallenges
    )
      return false;
    return true;
  };

  const handleClick = (chapter) => {
    props.handleChapterClick(chapter);
  };


  const showNotification = () => {
    notification.info({
      message: props.intl.formatMessage(intlMessages.warningMessage),
      placement: "topRight",
      closeIcon: ( <CloseCircleFilled  style={{ color: 'white', fontSize: 24 }}/> ),
      duration: 4,
      key: "chapter_not",
      style: { width: 600, background: mainBlue, marginTop: 50 },
      className: 'welcome_back_popup',
      icon: ( <InfoCircleFilled style={{ color: 'white' }}/> )
    });
  };


  return (
    <Collapse
      defaultActiveKey={[props.activeChapterId]}
      ghost
      expandIconPosition="end"
      accordion
      expandIcon={renderAccordian}
      style={{
        borderBlockStart: 'none'
      }}
    >
      {props.chapters.map((chapter, index) => {
        return (
          <>
            <Collapse.Panel
              header={
                <ChapterItem
                  id={chapter.id}
                  isActive={chapter.id === props.activeChapterId}
                  hasMandatoryChapters={props.hasMandatoryChapters}
                  chapterIndex={index}
                  chapter={chapter}
                  activeChapterData={props.activeChapterData}
                  chapters={props.chapters}
                  onClick={() => handleClick(chapter)}
                  viewedTopicsCount={() => viewedTopicsCount(chapter)}
                  previousTopicViewCount={() => previousTopicViewCount(index)}
                  checkChapterCompletion={() => checkChapterCompletion(chapter)}
                  canAccessChapter={() => canAccessChapter(chapter, index)}
                  showNotification={showNotification}
                />
              }
              key={chapter.id}
            >
              {chapter.topics.map((topic) => {
                return (
                  <>
                    <Row
                      className="mb-16"
                      onClick={() => handleTopicClick(chapter, topic, index)}
                      style={{
                        cursor: "pointer",
                      }}
                      key={topic.id}
                    >
                      <Col md={4}>
                        {props.nowViewedTopicId == topic.id ? (
                          <EyeFilled
                            className="ml-8 sub_icon_font_calcualator"
                            style={{
                              fontSize: 20,
                              color: "#005a92",
                            }}
                          />
                        ) : (
                          <PlayCircleFilled
                            className="ml-8 sub_icon_font_calcualator"
                            style={{
                              fontSize: 20,
                              color: topic.viewed ? "#87d068" : "#005a92",
                            }}
                          />
                        )}
                      </Col>
                      <Col md={20}>
                        <Typography.Text
                          style={{
                            color: props.nowViewedTopicId == topic.id ? "#005a92" : "black",
                          }}
                          className="sub_title_font_calcualator"
                        >
                          {sliceText(topic.title, 30)}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </>
                );
              })}
              {props.showSummary && chapter.id === props.activeChapterId && (
                <Row
                  className="mb-16"
                  onClick={() => handleSummeryClick(chapter)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Col md={4}>
                    <div className="sub_icon_circle">
                      <FileFilled
                        className="sub_icon_font_calcualator_summary"
                        style={{
                          fontSize: 10,
                          color: "white",
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={20}>
                    <Typography.Text className="title_font_calcualator">
                      <FormattedMessage {...intlMessages.summaryLabel} />
                    </Typography.Text>
                  </Col>
                </Row>
              )}
            </Collapse.Panel>
            <Divider
              style={{
                borderColor: "#F0F0F0",
                margin: 0
              }}
            />
          </>
        );
      })}
    </Collapse>
  );
};

export default injectIntl(ChapterList);
