import {defineMessages} from 'react-intl';

export default defineMessages({
  lowerPosition: {
    defaultMessage: 'You are in the lower',
    id: 'LandingPage.CompareMeSection.RelativePositionSlider.LowerPosition',
  },
  ofUsers: {
    defaultMessage: 'of users in your group',
    id: 'LandingPage.CompareMeSection.RelativePositionSlider.OfUsers',
  },
  topPosition: {
    defaultMessage: 'You are in the TOP',
    id: 'LandingPage.CompareMeSection.RelativePositionSlider.TopPosition',
  },
});
