import React, {FC, useEffect} from 'react';
import intlMessages from './intlMessages';
import { RouteComponentProps } from '@reach/router';
import { IRootStore } from '@u4i/state/RootStore';
import { inject, observer } from 'mobx-react';
import { Alert } from '@u4i/common/Alert';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import AppNavigator from '@u4i/common/AppNavigator';
import { AlertStatusEnum } from '@u4i/common/enums/AlertStatusEnum';
import CircularProgress from '@u4i/common/CircularProgress';
import './_register-confirm-page.scss';

interface IProps extends RouteComponentProps, WrappedComponentProps {
  match: any
  rootStore: IRootStore
}

export const RegisterConfirmPage: FC<IProps| any> = inject("rootStore")(
  observer((props: any) => {
    const {
      fetching,
      invalidTokenError,
      isTokenValid,
      confirmRegistration,
      fetchPageData,
      resetState
    } = props.rootStore.registerConfirmPageStore;

    const {
      successRedirectionPathname,
      isLoginAndPurchase
    } = props.rootStore.userStore;

    useEffect(() => {
      fetchData();
      return () => {
        resetState();
      }
    }, [])

    useEffect(() => {
      if(isTokenValid) {
        handleRegister();
      }
    }, [isTokenValid])
    
    const fetchData = () => {
      if (props.match.params.token) {
        fetchPageData(props.match.params.token);
      }
    }

    const handleRegister = () => {
      confirmRegistration(props.match.params.token).then(res => {
        if(successRedirectionPathname && isLoginAndPurchase) {
          AppNavigator.directPush(successRedirectionPathname);
        }
      });
    };
 
    if (invalidTokenError) {
      return (
        <div className="register-confirm-page__error-wrapper">
          <Alert
            heading={<FormattedMessage {...intlMessages.errorHeading} />}
            message={<FormattedMessage {...intlMessages.errorMessage} />}
            type={AlertStatusEnum.ERROR}
          />
        </div>
      );
    }

    if (fetching) {
      return <CircularProgress />;
    }

    return(
      <></>
    )
  })
)
