import is from 'is_js';

class Location {
  hash = null;
  pathname = null;
  search = null;

  constructor({hash = null, pathname, search = null}) {
    if (!is.null(hash) && !is.string(hash)) {
      throw new Error('If hash is present it must be a string');
    }

    if (!is.string(pathname)) {
      throw new Error('Pathname must be a string');
    }

    if (!is.null(search) && !is.string(search)) {
      throw new Error('If search is present it must be a string');
    }

    this.hash = hash;
    this.pathname = pathname;
    this.search = search;
  }
}

export default Location;
