import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import {observer} from 'mobx-react';
import {BaseFilter} from '../../filters/BaseFilter';
import {DataColumn} from '../../DataColumn';
import {FunctionalColumn} from '../../FunctionalColumn';

import './_table-header-cell.scss';

interface IPropTypes {
  column: DataColumn<any> | FunctionalColumn<any>;
  currentSortDirection: 'ASC' | 'DESC';
  currentSortedColumnId?: string | number | symbol;
  filters: Array<BaseFilter<any>>;
  onChangeSorting: (columnId: string) => void;
  reloadingInProgress: boolean;
  showFilters: boolean;
  sortable: boolean;
}

export const TableHeaderCell = observer(class TableHeaderCell extends React.Component<IPropTypes> {
  changeSorting = () => {
    this.props.onChangeSorting(this.props.column.id);
  };

  render() {
    const {column} = this.props;

    return (
      <th className="data-table__table-header-cell" key={column.id} style={{width: column.width}}>
        <div
          className={cx({
            'data-table__table-header-cell__content': true,
            'data-table__table-header-cell__content--center': column.centerContent,
          })}
        >
          <button
            className={cx({
              'data-table__table-header-cell__label': true,
              'data-table__table-header-cell__label--sortable': this.props.sortable,
            })}
            disabled={this.props.reloadingInProgress || !this.props.sortable}
            onClick={this.changeSorting}
            type="button"
          >
            {column.label}
    
            {(column instanceof DataColumn && column.sortable) &&
              <div className="data-table__table-header-cell__sorting">
                {this.props.currentSortedColumnId === column.id ?
                  <React.Fragment>
                    {this.props.currentSortDirection === 'ASC' ?
                      <FontAwesome name="sort-up" />
                      :
                      <FontAwesome name="sort-down" />
                    }
                  </React.Fragment>
                  :
                  <FontAwesome className="data-table__table-header-cell__unsorted-icon" name="sort" />
                }
              </div>
            }
          </button>
    
          {this.props.showFilters &&
            <div className="data-table__table-header-cell__filter-area">
              {this.props.filters.map(filter => filter.render())}
            </div>
          }
        </div>
      </th>
    );
  }
});
