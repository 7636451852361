import {defineMessages} from 'react-intl';

export default defineMessages({
  forgotPassword: {
    defaultMessage: 'Forgot password?',
    id: 'Modals.Login.ForgotPassword'
  },
  modalTitle: {
    defaultMessage: 'Login',
    id: 'Modals.Login.ModalTitle'
  },
  modalTitlePurchase: {
    defaultMessage: 'Login and purchase course',
    id: 'Modals.Login.ModalTitlePurchase'
  },
  noAccount: {
    defaultMessage: 'Don\'t have an account yet?',
    id: 'Modals.Login.NoAccount'
  },
  registerHere: {
    defaultMessage: 'Register here',
    id: 'Modals.Login.RegisterHere'
  }
});
