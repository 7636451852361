import { IRootStore } from "@u4i/state/RootStore";
import { IApi, IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { TablePaginationConfig } from 'antd/lib/table';
import { action, makeObservable, observable, runInAction } from "mobx";
import { ICustomerIntegration, ILTIConfigurationsPost, ILTIConfigurationsPut } from "../interfaces";
import { IAdminUserGroup } from "@u4i/parsers/admin/AdminUserGroupParser";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";
import { AntDPagination, AntDSorter, AntDTableFilters, DEFAULT_PAGE_SIZE } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import moment from "moment";

export class AdminCustomerIntegrationStore {
  private apiService: IApi;

  customerIntegrationById: ICustomerIntegration | undefined;
  tableData: Array<ICustomerIntegration> = [];
  apiConfig: AntDTableFilters<ICustomerIntegration> = {
    current: 0,
    filters: {},
    limit: 10,
    offset: 0,
    orderBy: {},
  };

  pagination: TablePaginationConfig = {
    current: 1,
    showSizeChanger: true,
  };

  fetching: boolean = true;
  deletingCustomerIntegration: boolean = false;
  deletingCustomerIntegrationError?: string;
  userGroups: Array<IAdminUserGroup>;

  validationErrors?: {
    name?: string;
    type?: string;
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      apiConfig: observable,
      customerIntegrationById: observable,
      deleteCustomerIntegration: action.bound,
      deletingCustomerIntegration: observable,
      deletingCustomerIntegrationError: observable,
      fetching: observable,
      getCustomerIntegrations: action.bound,
      getCustomerIntegrationById: action.bound,
      loadUserGroups: action.bound,
      onTableChange: action.bound,
      pagination: observable,
      tableData: observable,
      userGroups: observable,
      validationErrors: observable
    });

    const { apiService } = rootStore;
    this.apiService = apiService;
  }

  getCustomerIntegrations = async ( config: AntDTableFilters<ICustomerIntegration>) => {
    this.fetching = true;
    this.apiConfig = config;

    try {
      const customerIntegrationListPaginated: IPaginatedItems<ICustomerIntegration> = await this.apiService.admin.customerIntegration.fetchCustomerIntegrationData(this.apiConfig);

      const { items, totalItems } = customerIntegrationListPaginated;
      this.pagination = {
        ...this.pagination,
        pageSize: this.apiConfig.limit,
        total: totalItems
      };

      runInAction(() => (this.tableData = items));

    } catch (error) {
      Notification(NotificationEnum.Error, "Customer integration fetch", error)

      runInAction(() => {
        throw error;
      });
    } finally {
      this.fetching = false;
    }
  }

  onTableChange = async (
    pagination: AntDPagination, 
    filters: { [columnName in keyof ICustomerIntegration]?: ICustomerIntegration[columnName] }, 
    sorter: AntDSorter<ICustomerIntegration>
  ) => {
    const sortDirection: "ASC" | "DESC" = sorter.order === "ascend" ? "ASC" : "DESC";
    const { current, pageSize } = pagination;
    const setFilters = {};
    const orderBy = {};
    
    Object.keys(filters).forEach((key: string) => {
      if (filters[key] !== null) {
        if (key == 'createdAt' && filters[key]?.length) {
          const createdAtDates = filters[key];

          if(createdAtDates) {
            setFilters['creationDateFrom'] = moment(createdAtDates[0][0]).format('YYYY-MM-DDTHH:mm:ss');
            setFilters['creationDateTo'] = moment(createdAtDates[0][1]).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else {
          setFilters[key] = filters[key][0];
        }
      }
    });

    if (sorter.field) {
      orderBy[sorter.field === 'createdAt' ? 'creationDate' : sorter.field] = sortDirection;
    }

    this.apiConfig = {
      ...this.apiConfig,
      limit: pageSize,
      filters: setFilters,
      offset: (current - 1) * DEFAULT_PAGE_SIZE,
      orderBy
    };

    this.pagination = {
      ...pagination,
      current
    }

    await this.getCustomerIntegrations(this.apiConfig);

    this.apiConfig = {
      ...this.apiConfig,
      filters: {}
    };
  }

  async getCustomerIntegrationById(id: string) {
    this.fetching = true;

    try {
      this.customerIntegrationById = await this.apiService.admin.customerIntegration.fetchCustomerIntegrationById(id);
    }
    catch (error) {
      throw (error);
    }
    finally {
      this.fetching = false;
    }
  }

  async deleteCustomerIntegration(record: ICustomerIntegration) {
    this.deletingCustomerIntegration = true;
    const isOneItemOnPage: boolean = this.tableData.length === 1;
    
    try {
      await this.apiService.admin.customerIntegration.deleteCustomerIntegration(record.id);

      runInAction(() => {
        if (isOneItemOnPage) {
          this.apiConfig = {
            ...this.apiConfig,
            current: 1,
            offset: 0
          }
        };

        this.getCustomerIntegrations(this.apiConfig);

        Notification(NotificationEnum.Success, "Customer integration delete", `Customer integration \"${record.name}\" deleted.`);
      })
    }

    catch(error) {
      this.deletingCustomerIntegrationError = error.response.data.errorMessage || error.response.data.message;
      Notification(NotificationEnum.Error, "Customer integration delete", this.deletingCustomerIntegrationError);
    }

    finally {
      this.deletingCustomerIntegration = false;
    }
  }

  async loadUserGroups(config=this.apiConfig) {
    this.fetching = true;

    try {
      const userGroups: IPaginatedItems<IAdminUserGroup> = await this.apiService.admin.userGroups.fetchUserGroupsData(config);

      runInAction(() => {
        const sortedUserGroupsList: IAdminUserGroup[] = userGroups.items.sort((previousItem: IAdminUserGroup, currentItem: IAdminUserGroup) => {
          if (previousItem.name.toLowerCase() < currentItem.name.toLowerCase()) return -1;
          if (previousItem.name.toLowerCase() > currentItem.name.toLowerCase()) return 1;
          return 0;
        });

        this.userGroups = sortedUserGroupsList;
      });
    }
    catch(error) {
      runInAction(() => {
        throw(error);
      });
    }
    finally{
      this.fetching = false;
    }
  }

  async createCustomerIntegration(model: ILTIConfigurationsPost) {
    this.validationErrors = undefined;

    try {
      await this.apiService.admin.customerIntegration.createCustomerIntegration(model).then(res => {
        let customerIntegrationId = res?.id;
        runInAction(() => {
          AppNavigator.push(appRoutes.adminCustomerIntegrationEdit, {customerIntId: customerIntegrationId} );
          Notification(NotificationEnum.Success, "Customer integration create", `Customer integration \"${model.name}\" created.`);
        });
      })
    }

    catch (error) {
      const createCustomerIntegration = error.response.data.errorMessage || error.response.data.message;

      runInAction(() => {
        this.messageHandler(error?.response?.data?.errorMessage);

        if(error?.response?.data?.errorMessage.startsWith('Group')) {
          if(this.userGroups.length > 0) {
            this.manipulateErrorMessageGroups(this.userGroups, error?.response?.data?.errorMessage);
          } else {
            this.loadUserGroups().then((res) => {
              this.manipulateErrorMessageGroups(this.userGroups, error?.response?.data?.errorMessage);
            })
          }
        } else {
          Notification(NotificationEnum.Error, "Customer integration", createCustomerIntegration);
        }
      });
    }
  }

  async updateCustomerIntegration(id: string, model: ILTIConfigurationsPut) {
    this.validationErrors = undefined;

    try {
      await this.apiService.admin.customerIntegration.updateCustomerIntegration(id, model)

      runInAction(() => {
        Notification(NotificationEnum.Success, "Customer integration update", `Customer integration \"${model.name}\" updated.`);
      });
    }

    catch (error) {
      const updateCustomerIntegration = error.response.data.errorMessage || error.response.data.message;

      runInAction(() => {
        this.messageHandler(error?.response?.data?.errorMessage);

        if(error?.response?.data?.errorMessage.startsWith('Group')) {
          if(this.userGroups.length > 0) {
            this.manipulateErrorMessageGroups(this.userGroups, error?.response?.data?.errorMessage);
          } else {
            this.loadUserGroups().then((res) => {
              this.manipulateErrorMessageGroups(this.userGroups, error?.response?.data?.errorMessage);
            })
          }
        } else {
          Notification(NotificationEnum.Error, "Customer integration", updateCustomerIntegration);
        }
      });
    }
  }

  messageHandler(errorMessage: any) {
    if(errorMessage.startsWith('Consumer')) {
      this.validationErrors = errorMessage;
      return;
    }

    if(errorMessage.startsWith('Email')) {
      this.validationErrors = errorMessage;
      return;
    }

    if(errorMessage.startsWith('Group')) {
      this.validationErrors = errorMessage;
      return;
    }
  }

  manipulateErrorMessageGroups(userGroups: IAdminUserGroup[], message: any) {
    let startIndex = message.indexOf('"');
    let endIndex = message.lastIndexOf('"');
    let groupId =  message.slice(startIndex + 1, endIndex);
    let groupName = userGroups.find(ug => ug.id == groupId)?.name;
    let messageToShow = `Group "${groupName}" has already been taken.`;
    Notification(NotificationEnum.Error, "Customer integration", messageToShow);
  }

  resetData() {
    this.customerIntegrationById = undefined;
    this.fetching = true;
    this.userGroups = [];
    this.validationErrors = undefined;
  }

}
