import {defineMessages} from 'react-intl';

export default defineMessages({
  anonymousPosting: {
    defaultMessage: 'Anonymous',
    id: 'Forms.DiscussComment.Anonymity.AnonymousPosting',
  },
  buttonLabelOff: {
    defaultMessage: 'Off',
    id: 'FormSection.DiscussComment.Anonymity.ButtonLabelOff',
  },
  buttonLabelOn: {
    defaultMessage: 'On',
    id: 'FormSection.DiscussComment.Anonymity.ButtonLabelOn',
  },
  contentErrorsMinLength: {
    defaultMessage: 'Minimum {limit} characters',
    id: 'Forms.DiscussComment.Content.Errors.MinLength',
  },
  contentErrorsPresence: {
    defaultMessage: 'Comment cannot be blank',
    id: 'Forms.DiscussComment.Content.Errors.Presence',
  },
  contentLabel: {
    defaultMessage: 'Your comment',
    id: 'Forms.DiscussComment.Content.ContentLabel',
  },
  submitTitle: {
    defaultMessage: 'Submit',
    id: 'Forms.DiscussComment.SubmitTitle',
  },
});
