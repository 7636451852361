import { useState, useEffect } from 'react';
import api from '../api';
import axios from 'axios';

interface IClientAPI {
    baseUrl?: string,
    url?: string,
    body?: any,
    queryParams?: any,
    method?: string,
    componentDidMount?: boolean,
    setState?: boolean,
    setLoading?: boolean,
    rebuildState?: boolean,
    headers?: any,
    onSuccess?: (data?: any) => void,
    onError?: (error?: any) => void,
}

interface IClientAPIResponse {
    data: any,
    error: any,
    isLoading: boolean,
    totalCount: number,
    fetchData: (args?: IClientAPI) => void,
    postData: (args?: IClientAPI) => void,
    updateData: (args?: IClientAPI) => void,
    deleteData: (args?: IClientAPI) => void
};


const useClientApi = (config: IClientAPI = {}): IClientAPIResponse => {

    const {
        url,
        baseUrl,
        queryParams,
        method = 'GET',
        componentDidMount = true,
        setState = true,
        setLoading = true,
        rebuildState = false,
        onSuccess,
        onError
    } = config;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (method === 'GET' && componentDidMount && url)
            fetchData(config);
    }, [url]);

    async function fetchData(args: IClientAPI = config) {
        try {
            setIsLoading(true);

            const params = new URLSearchParams(args.queryParams);

            let response;

            if (baseUrl) {
                response = await axios.get(`${url}?${params}`);
            } else {
                response = await api.get(`${url}?${params}`);
            }

            if (args.rebuildState)
                setData(null);
            if (response.headers["x-total-count"])
                setTotalCount(parseInt(response.headers["x-total-count"], 10));
            if (setState)
                setData(response.data);
            if (args.onSuccess)
                args.onSuccess(response.data)
        } catch (error) {
            if (args.onError)
                args.onError(error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    async function postData(args: IClientAPI = config) {
        try {
            if (args.setLoading)
                setIsLoading(true);

            let response;

            if (args.baseUrl) {
                response = await axios.post(`${args.url}`, args.body);
            } else {
                response = await api.post(`${args.url}`, args.body, {
                    ...args.headers
                });
            }

            if (args.onSuccess)
                args.onSuccess(response.data);
        } catch (error) {
            if (args.onError)
              args.onError(error);
        } finally {
            if (args.setLoading)
                setIsLoading(false);
        }
    }

    async function updateData(args: IClientAPI = config) {
        try {
            if (args.setLoading)
                setIsLoading(true);
            const response = await api.put(`${args.url}`, args.body);
            if (args.onSuccess)
                args.onSuccess();
        } catch (error) {
            if (args.onError)
                args.onError(error);
        } finally {
            if (args.setLoading)
                setIsLoading(false);
        }
    }

    async function deleteData(args: IClientAPI = config) {
        try {
            if (args.setLoading)
                setIsLoading(true);
            const response = await api.delete(`${args.url}`);
            if (args.onSuccess)
                args.onSuccess();
        } catch (error) {
            if (args.onError)
                args.onError(error);
        } finally {
            if (args.setLoading)
                setIsLoading(false);
        }
    }

    return { data, error, isLoading, totalCount, fetchData, postData, updateData, deleteData };
}

export default useClientApi;
