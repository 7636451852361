import React, { Component } from 'react';
import {inject} from 'mobx-react';
import {IReactionDisposer, reaction} from 'mobx';
import {withRouter} from 'react-router-dom';
import { RouteComponentProps } from "@reach/router";

import AppNavigator, {appRoutes} from '@u4i/common/AppNavigator';
import SettingsStore from '@u4i/state/SettingsStore';
import UserStore from '@u4i/state/UserStore';
import { IRootStore } from '@u4i/state/RootStore';

interface IPropTypes extends RouteComponentProps {
  rootStore: IRootStore;
}

export const Homepage = inject('rootStore')(withRouter(class Homepage extends Component<IPropTypes | any> {
    private settingsStore: SettingsStore;
    private userStore: UserStore;
    private reactionDisposer: IReactionDisposer;

    constructor(props) {
      super(props);

      const {settingsStore, userStore} = props.rootStore;

      this.settingsStore = settingsStore;
      this.userStore = userStore;
    }

    componentDidMount() {
      if (this.userStore.isLoading || this.settingsStore.fetching) {
        this.reactionDisposer = reaction(
          () => !this.settingsStore.fetching && !this.userStore.isLoading,
          this.clearRedirect,
        );
      } else {
        this.redirect();
      }
    }

    clearRedirect(isLoadingFinished: boolean): void {
      if (isLoadingFinished) {
        this.reactionDisposer();

        this.redirect();
      }
    }

    redirect() {
      const search = this.props.location?.search;

      if (this.userStore.isAuthorized) {
        AppNavigator.push(appRoutes.home);
      } else {
        const {settings} = this.settingsStore;

        if (settings && settings.platform.aboutWordpressUrl) {
          window.location.href = search ? `${settings.platform.aboutWordpressUrl}${search}` : settings.platform.aboutWordpressUrl;
        }
      }
    }

    render() {
      return null;
    }
  }));
