import Parser from '@u4i/utils/Parser';

interface IDlLabs{
    id: string;
    disable: boolean;
    title: string;
}

export const AdminDlLabs = new Parser<IDlLabs>(lab => ({
  id: lab.id,
  disable: !lab.isEnabled,
  title: lab.title,
  restTimeDuration: lab.maintenanceDuration != null ? Math.round(lab.maintenanceDuration/60) : 0,
  userSessionDuration: Math.round(lab.userSessionDuration/60),
}));
