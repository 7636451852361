import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import {EXTERNAL_BASE_URL} from '@u4i/constantSettings';
import intlMessages from './intlMessages';
import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';
import './_footer-page-links.scss';

interface IPropTypes {
  currentLocale: LanguageEnum
}

export const FooterPageLinks: FC<IPropTypes> = (props) => {
  const isEnglish: boolean = props.currentLocale === LanguageEnum.EN_US;

  return (
    <ul className="footer__links list-unstyled">
      <li className="footer__links-entry">
        <a
          className="footer__links-link"
          href={isEnglish ? `${EXTERNAL_BASE_URL}/en` : `${EXTERNAL_BASE_URL}`}
        >
          <FormattedMessage {...intlMessages.linksAbout} />
        </a>
      </li>
    </ul>
  );
}

