import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {inject} from 'mobx-react';
import {Link} from 'react-router-dom';
import intlMessages from './intlMessages';
import { appRoutes } from '@u4i/common/AppNavigator';
import { RouteComponentProps } from '@reach/router';
import { IRootStore } from '@u4i/state/RootStore';
import ResetPasswordForm from '@u4i/common/forms/ResetPasswordForm/ResetPasswordForm';
import './_reset-password-page.scss';

interface IProps extends RouteComponentProps {
  match: {
    params: {
      guid: string, token: string 
    }
  }
  rootStore: IRootStore
}

const ResetPasswordPage: FC<IProps> = (props: IProps) => {
  const HOME_ROUTE = appRoutes.home.parse();

  const handleFormSubmission = (data) => {

     return  props.rootStore.userStore.resetUserPassword(
        props.match.params.guid,
        props.match.params.token,
        data.newPassword,
        data.newPasswordConfirm
      );
  
  }
  
  return (
    <div className="reset-password-page">
      <div className="reset-password-page__box">
        <h2 className="reset-password-page__page-title">
          <FormattedMessage {...intlMessages.pageTitle} />
        </h2>

        <div className="reset-password-page__form">
          <ResetPasswordForm
            handleSubmit={handleFormSubmission}
          />
        </div>

        <Link className="reset-password-page__back-button" to={HOME_ROUTE}>
          <FormattedMessage {...intlMessages.backButtonLabel} />
        </Link>
      </div>
    </div>
  )
}

export default inject('rootStore')(ResetPasswordPage);
