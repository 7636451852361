import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import './_discuss-page-back-button.scss';

interface IProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DiscussPageBackButton: FC<IProps> = props => (
  <button className="discuss-page__back-button" onClick={props.onClick} type="button">
    <FontAwesome className="discuss-page__back-button__icon" name="arrow-left" />

    <FormattedMessage {...intlMessages.buttonLabel} />
  </button>
);
