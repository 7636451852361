import {defineMessages} from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Sorry',
    id: 'Routes.RequireRole.Heading',
  },
  message: {
    defaultMessage: 'You are not allowed to access this page',
    id: 'Routes.RequireRole.Message',
  },
});
