import React, { FC } from 'react';

import {SummariesListItem} from './SummariesListItem';

import './_summaries-list.scss';

interface IPropTypes {
  entries: Array<{
    id: string
    text: string
  }>
}

export const SummariesList: FC<IPropTypes> = (props: IPropTypes) => (
  <ul className="list-unstyled player__summaries-list">
    {props.entries.map(entry => (
      <li className="player__summaries-list__item" key={entry.id}>
        <SummariesListItem>
          {entry.text}
        </SummariesListItem>
      </li>
    ))}
  </ul>
);
