import React, { FC } from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import imageDe from '@u4i/assets/images/image-de.jpg';
import imageEn from '@u4i/assets/images/image-en.jpg';
import intlMessages from './intlMessages';
import './_certificate-section.scss';

const imagesMap = new Map([
  ['de-DE', imageDe],
  ['en-US', imageEn]
]);

interface IProps extends WrappedComponentProps {
  language: any
}

const CertificateSection: FC<IProps> = (props: IProps) => {
  const targetImage = imagesMap.get(props.intl.locale);

  return (
    <div className="container">
      <div className="course-detail-page__certificate-section">
        <img
          alt="Certificate"
          className="course-detail-page__certificate-section__image"
          src={targetImage}
        />

        <div className="course-detail-page__certificate-section__text">
          <h3 className="course-detail-page__certificate-section__heading">
            <FormattedMessage {...intlMessages.heading} />
          </h3>

          <p className="course-detail-page__certificate-section__description">
            <FormattedMessage {...intlMessages.description} />
          </p>
        </div>
      </div>
    </div>
  );
}

const WrappedComponent = injectIntl(CertificateSection);

export {WrappedComponent as CertificateSection};
