import cx from 'classnames';
import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { IRootStore } from '@u4i/state/RootStore';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { CouponRedeemForm } from './components/CouponRedeemForm/CouponRedeemForm';
import { SuccessInfo } from './components/SuccessInfo/SuccessInfo';
import './_redeem-page.scss';

interface IProps extends RouteComponentProps {
  rootStore: IRootStore
}

export const RedeemPage: FC<IProps> = inject("rootStore")(
  observer((props: IProps) => {
    const {
      additionalTerms,
      couponCode,
      couponSubmittedSuccessfully,
      successMessage,
      successCouponCode,
      validatingCoupon,
      checkCouponCode,
      joinUserGroupByCode,
      resetState
    } = props.rootStore.redeemPageStore;

    const handleGoToHomeClick = () => {
      AppNavigator.push(appRoutes.home);
      resetState();
    };

    return(
      <div className="redeem-page">
        <h2
          className={cx({
            'redeem-page__title': true,
            'redeem-page__title--hidden': couponSubmittedSuccessfully
          })}
        >
          <FormattedMessage {...intlMessages.pageTitle} />
        </h2>

        {couponSubmittedSuccessfully ?
          <SuccessInfo
            message={successMessage}
            onGoToHomeClick={handleGoToHomeClick}
            onRedeemAnotherClick={resetState}
          />
          :
          <CouponRedeemForm
            additionalTerms={additionalTerms}
            checkCouponCode={(value) => checkCouponCode(value)}
            couponCode={couponCode}
            handleSubmit={(val) => joinUserGroupByCode(val)}
            successCouponCode={successCouponCode}
            validatingCoupon={validatingCoupon}
          />
        }
      </div>
    )
  })
)
