import React from "react";
import cx from "classnames";
import { Row, Col, Typography, notification } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, LockFilled, PlayCircleFilled, CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import { fullPageApi } from "../../config";
import intlMessages from "./intlMessages";
import { mainBlue } from "@u4i/styles/styles-variables";

const Footer = (props) => {

  const isChallangeCompleated = () => {
    if (!props.hasMandatoryChapters) return true;
    if (props.activeChapterData.hasMandatoryChallenges && props.activeChapterData.viewedStatus)
      return true;
    if (props.activeChapterData.hasMandatoryChallenges && !props.activeChapterData.viewedStatus)
      return false;
    if (props.chapters.find(chapter => chapter.id === props.activeChapterData.id).topics.some(topic => !topic.viewed))
      return false;
    return true;
  };


  const showNotification = () => {
    notification.info({
      message:  props.intl.formatMessage(intlMessages.warningText),
      placement: "topRight",
      closeIcon: ( <CloseCircleFilled  style={{ color: 'white', fontSize: 24 }}/> ),
      duration: 4,
      key: "chapter_not",
      style: { width: 600, background: mainBlue, marginTop: 50 },
      className: 'welcome_back_popup',
      icon: ( <InfoCircleFilled style={{ color: 'white' }}/> )
    });
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        background: "black",
        padding: 10,
        height: "70px",
        width: "100%",
        position: 'fixed',
        bottom: 0,
        zIndex: 9999
      }}
    >
      <Col></Col>
      <Col
        className={cx({
          "mr-16": true,
          header_transition_open: props.isMenuActive,
          header_transition_close: !props.isMenuActive,
        })}
        style={{
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {props.isLastElement && !props.isActiveChapterLast ? (
          <span
            onClick={() => {
              if (isChallangeCompleated()) return props.onNextChapterClick();

              return showNotification();
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <Row align="middle" className="fadein">
              {!isChallangeCompleated() ? (
                <span className="circle_icon">
                  {" "}
                  <LockFilled
                    style={{
                      color: "rgb(0, 132, 215)",
                    }}
                  />
                </span>
              ) : (
                <>
                  <PlayCircleFilled
                    style={{
                      fontSize: 25,
                      color: "#87d068",
                    }}
                    className="icon_font_calcualator"
                  />
                </>
              )}
              <Typography.Title
                level={4}
                style={{
                  color: "#fff",
                  marginBottom: 0,
                  marginTop: 0
                }}
                className="ml-8 title_font_calcualator"
              >
                <FormattedMessage {...intlMessages.buttonText} />{" "}
              </Typography.Title>{" "}
            </Row>
          </span>
        ) : (
          <span>
            {!props.isFirstElement && (
              <ArrowUpOutlined
                onClick={() => fullPageApi.moveSectionUp()}
                style={{
                  fontSize: 25,
                  color: "#fff",
                }}
                className="mr-16 fadein player__on-screen-navigation__button icon_font_calcualator"
              />
            )}
            <ArrowDownOutlined
              onClick={() => {
                if (!props.isLastElement) {
                  fullPageApi.moveSectionDown();
                }
                return;
              }}
              style={{
                fontSize: 25,
                color: !props.isLastElement ? "#fff" : "grey",
              }}
              className="fadein player__on-screen-navigation__button icon_font_calcualator"
            />
          </span>
        )}
      </Col>
    </Row>
  );
};

export default injectIntl(Footer);
