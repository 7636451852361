import cx from 'classnames';
import React, { FC } from 'react';

import './_title-heading.scss';

interface IPropTypes {
  backgroundBrightness: number
  headingRef: React.RefObject<HTMLHeadingElement>
  isVisible: boolean
  subtitle: string
  title: string
}

export const TitleHeading: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <h2
      className={cx({
        'landing-page__title-heading': true,
        'landing-page__title-heading--dark': props.backgroundBrightness > 0.6,
        'landing-page__title-heading--light': props.backgroundBrightness < 0.4,
        'landing-page__title-heading--neutral': props.backgroundBrightness >= 0.4 && props.backgroundBrightness <= 0.6,
        'landing-page__title-heading--visible': props.isVisible,
      })}
      ref={props.headingRef}
    >
      <div>
        {props.title}

        <small className="landing-page__title-heading__subheading">
          {props.subtitle}
        </small>
      </div>
    </h2>
  );
};
