import {AxiosInstance} from 'axios';
import {IAdminGroupSimple} from '@u4i/parsers/admin/AdminGroupParser';


export class AdminGroupsApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async getGroupListSimple(): Promise<IAdminGroupSimple> {
    const response = await this.apiClient.get(`/admin/groups/list-simple`);

    return response.data;
  }
}
