import React, { FC } from 'react';

import './_partner-info-entry.scss';
import {ArrowRightOutlined} from "@ant-design/icons"


interface IPropTypes {
  beforeActionText?: string
  buttonAction: string
  buttonText: string
  description: string
  title: string
}

export const PartnerInfoEntry: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <article className='common-card-wrapper article-wrapper'>
      <h4 className="landing-page__partner-info-entry__title">
        {props.title}
      </h4>

      <p className="landing-page__partner-info-entry__description">
        {props.description}
      </p>

      <div className="landing-page__partner-info-entry__action">
        {props.beforeActionText &&
          <p className="landing-page__partner-info-entry__action-text">
            {props.beforeActionText}
          </p>
        }

        <a
          className="landing-page__partner-info-entry__button"
          href={props.buttonAction}
          target="_blank"
        >
          <ArrowRightOutlined className="mr-8"/>{props.buttonText}
        </a>
      </div>
    </article>
  );
};
