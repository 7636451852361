import {defineMessages} from 'react-intl';

export default defineMessages({
  summary: {
    defaultMessage: 'Summary',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.Summary'
  },
  loading: {
    defaultMessage: 'Loading...',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.Loading'
  },
  retake: {
    defaultMessage: 'Retake',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.Retake',
  },
  start: {
    defaultMessage: 'Start',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.Start',
  },
  watchAgain: {
    defaultMessage: 'Watch again',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.WatchAgain',
  },
  buttonLabel: {
    defaultMessage: 'Resume watching',
    id: 'CourseDetailPage.RecentChapter.ButtonLabel',
  },
});
