import React from "react";
import { observer } from "mobx-react";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { BasicInfoWrapper } from "../common/BasicInfoWrapper";
import { ChapterSection } from "./ChapterSection";
import { HorizontalSlider } from "../common/HorizontalSlider";
import { NoItemsMessage } from "../common/NoItemsMessage";
import CommonCard from "@u4i/common/Cards/CommonCard";
import { IChapter } from "@u4i/parsers/ChapterParserNew";

interface IPropTypes {
  sectionModel: ChapterSection;
}

export const VisualComponent = observer(
  class VisualComponent extends React.Component<IPropTypes> {
    handleChapterCardPlayClick = (chapterSlug: string) => {
      AppNavigator.push(appRoutes.playerChapter, { chapterSlug });
    };

    render() {
      return (
        <BasicInfoWrapper
          isLoading={this.props.sectionModel.fetching}
          itemsCountLabel={this.props.sectionModel.items.length}
          sectionModel={this.props.sectionModel}
        >
          <div className="container">
            {this.props.sectionModel.items.length > 0 ? (
              <HorizontalSlider>
                {this.props.sectionModel.items.map((chapter: IChapter) => (
                  <CommonCard
                    title={chapter.title}
                    image={chapter.preview.url}
                    description={chapter.description}
                    language={chapter.language}
                    duration={chapter.duration}
                    key={chapter.id}
                    onPlayClick={this.handleChapterCardPlayClick}
                    providers={chapter.providers}
                    slug={chapter.slug}
                    percentage={null}
                  />
                ))}
              </HorizontalSlider>
            ) : (
              <NoItemsMessage />
            )}
          </div>
        </BasicInfoWrapper>
      );
    }
  }
);
