import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Checkbox, Form, Select, Typography } from "antd";
import CircularProgress from "../../CircularProgress";
import intlMessages from "./intlMessages";
import CustomInputField from "../components/TextField/CustomInputField";
import SubmitButton from "../components/SubmitButton/SubmitButton";
import { observer } from "mobx-react";
import cx from "classnames";
import { lowerCaseRegEx, numberRegEx, specialCharacterRegEx, upperCaseRegEx } from "../components/RegexValidations";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import { EXTERNAL_BASE_URL } from "@u4i/constantSettings";
import { IRootStore } from "@u4i/state/RootStore";
import { ICountry } from "@u4i/modules/CourseDetailPage/interfaces/CourseInterface";
import countryParser from "@u4i/parsers/CountryParser";
import axios from "axios";
import api from "@u4i/utils/api";
import { FilterOptions } from "@u4i/common/Interfaces/FilterOptionsInterface";
import { guardsmanRed, mainBlue } from "@u4i/styles/styles-variables";
import "./_registration-form.scss";
import { values } from "mobx";

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography;

interface IPropTypes extends WrappedComponentProps {
  rootStore: IRootStore
  onSubmit: (data: any) => Promise<void>
}

const RegistrationForm: FC<IPropTypes> = (props: IPropTypes) => {
  const {
    languageStore,
    userStore
  } = props.rootStore;

  const [dataPrivacyStatementValue, setDataPrivacyStatement] = useState(false);

  const isEnglish: boolean = languageStore.currentLocale === LanguageEnum.EN_US;
  const { formatMessage } = props.intl;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeInput, setActiveInput] = useState<boolean>(false);
  const [hasError, setError] = useState<boolean>(false);
  const [countriesConfigs, setCountriesConfigs] = useState<any>({
    countries: [],
    countriesRequestCancelSource: null,
    countriesLoading: true
  });

  const collaborationCodeInfo: string = formatMessage(intlMessages.collaborationCodeInfo);
  const collaborationCodeLabel: string = formatMessage(intlMessages.collaborationCodeLabel);
  const emailLabel: string = formatMessage(intlMessages.emailLabel);
  const emailPatternError: string = formatMessage(intlMessages.emailErrorsPattern);
  const emailPresenceError: string = formatMessage(intlMessages.emailErrorsPresence);
  const emailMaxLengthError: string = formatMessage(intlMessages.emailErrorsMaxLength, { limit: 45 });
  const firstnameLabel: string = formatMessage(intlMessages.firstnameLabel);
  const firstnamePresenceError: string = formatMessage(intlMessages.firstnameErrorsPresence);
  const firstnameMaxLengthError: string = formatMessage(intlMessages.firstnameErrorsMaxLength, { limit: 30 });
  const lastnameLabel: string = formatMessage(intlMessages.lastnameLabel);
  const lastnamePresenceError: string = formatMessage(intlMessages.lastnameErrorsPresence);
  const lastnameMaxLengthError: string = formatMessage(intlMessages.lastnameErrorsMaxLength, { limit: 30 });
  const registerSubmitLabel: string = formatMessage(intlMessages.submitTitle);
  const newsletterLabel: string = formatMessage(intlMessages.newsletterLabel);
  const newsletterLabelExtra: string = formatMessage(intlMessages.newsletterLabelExtra);
  const dataPrivacyStatement: string = formatMessage(intlMessages.dataPrivacyStatement);
  const passwordLabel: string = formatMessage(intlMessages.passwordLabel);
  const passwordConfirmLabel: string = formatMessage(intlMessages.passwordConfirmLabel);
  const passwordConfirmPresenceError: string = formatMessage(intlMessages.passwordConfirmErrorsPresence);
  const passwordConfirmRepeatError: string = formatMessage(intlMessages.passwordConfirmErrorsRepeat);
  const passwordLowercasePresentError: string = formatMessage(intlMessages.passwordErrorsLowercasePresent);
  const passwordMaxLengthError: string = formatMessage(intlMessages.passwordErrorsMaxLength, { limit: 255 });
  const passwordMinLengthError: string = formatMessage(intlMessages.passwordErrorsMinLength, { limit: 10 });
  const passwordNumberPresentError: string = formatMessage(intlMessages.passwordErrorsNumberPresent);
  const passwordPresenceError: string = formatMessage(intlMessages.passwordErrorsPresence);
  const passwordSpecialCharacterPresentError: string = formatMessage(intlMessages.passwordErrorsSpecialCharacterPresent);
  const passwordUppercasePresentError: string = formatMessage(intlMessages.passwordErrorsUppercasePresent);
  const termsPresenceError: string = formatMessage(intlMessages.termsErrorsPresence);
  const termsPrivacyPolicyLink: string = formatMessage(intlMessages.termsPrivacyPolicyLink);
  const termLabel: string = formatMessage(intlMessages.termsLabel);
  const termsAnd: string = formatMessage(intlMessages.termsAnd);
  const termsDisclaimerLink: string = formatMessage(intlMessages.termsDisclaimerLink)
  const termsDisclaimerEnd: string = formatMessage(intlMessages.termsDisclaimerEnd);
  const streetLabel: string = formatMessage(intlMessages.streetLabel);
  const streetPresenceError: string = formatMessage(intlMessages.streetErrorsPresence);
  const postalCodeLabel: string = formatMessage(intlMessages.postalCodeLabel);
  const postalCodePresenceError: string = formatMessage(intlMessages.postalCodeErrorsPresence);
  const postalCodeMaxLengthError: string = formatMessage(intlMessages.postalCodeErrorsMaxLength, { limit: 5 });
  const cityLabel: string = formatMessage(intlMessages.cityLabel);
  const cityPresenceError: string = formatMessage(intlMessages.cityErrorsPresence);
  const countryLabel: string = formatMessage(intlMessages.countryLabel);
  const countryPresenceError: string = formatMessage(intlMessages.countryErrorsPresence);
  const purchaseCourseViaLabel: string = formatMessage(intlMessages.purchaseCourseLabel);
  const purchaseMessage: string = formatMessage(intlMessages.purchaseMessage);

  useEffect(() => {
    loadCountries();

    return () => {
      countriesConfigs.countriesRequestCancelSource?.cancel();
    }
  }, []);

  const loadCountries = () => {
    const countriesRequestCancelSource = axios.CancelToken.source();

    setCountriesConfigs({ countriesRequestCancelSource })

    api.get('countries', {
      cancelToken: countriesRequestCancelSource.token
    }).then((response) => {
      const parsedData = response.data.map(entry => countryParser.parse(entry));

      const selectFieldOptions: ICountry = parsedData.map(entry => ({
        label: entry.name,
        value: entry.id
      }));

      setCountriesConfigs({
        countries: selectFieldOptions,
        countriesLoading: false,
        countriesRequestCancelSource: null
      });
    });
  }

  const handleSubmit = (values: any) => {
    props.rootStore.redeemPageStore.updateCouponCodeError(undefined);
    const config = {
      collaboration_code: "collaborationCode",
      email: "email",
      firstname: "firstname",
      lastname: "lastname"
    };

    setLoading(true);

    props.onSubmit(values).then(() => {
      setLoading(false);
      form.resetFields();
    }).catch((error) => {
      setLoading(false);
      const { validationErrors } = error.response.data;
      form.setFields(
        Object.keys(validationErrors).map((key) => {
          return {
            name: config[key],
            errors: [validationErrors[key]]
          };
        })
      );
    });
  };

  const filterOption = (input: string, option: FilterOptions) => {
    return option!.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
  }

  const handleDataPrivacyText = (value) => {
    setDataPrivacyStatement(prev => !prev);
  }

  const isEmptyValue: boolean = form.getFieldValue('country') == "" || form.getFieldValue('country') === undefined ? true : false;

  setTimeout(() => {
    if (form.getFieldError('country').length > 0) {
      return setError(true);
    }
    return setError(false);
  }, 100);


  const termsLabel: JSX.Element = (
    <span>
      {termLabel} &nbsp;
      <a
        className="registration-completion-form__link"
        href={isEnglish ? `${EXTERNAL_BASE_URL}/en/privacy-policy` : `${EXTERNAL_BASE_URL}/de/datenschutz`}
      >
        {termsPrivacyPolicyLink}
      </a>{" "}
      &nbsp; {termsAnd} &nbsp;
      <a
        className="registration-completion-form__link"
        href={isEnglish ? `${EXTERNAL_BASE_URL}/en/disclaimer` : `${EXTERNAL_BASE_URL}/de/haftungsausschluss`}
      >
        {termsDisclaimerLink}
      </a>{" "}
      &nbsp;{termsDisclaimerEnd}
    </span>
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="custom_form_fields">
      <Form onFinish={handleSubmit} form={form} requiredMark={'optional'}>
        <div className="registration-form__field-group">
          <div className="registration-form__field">
            <Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: firstnamePresenceError
                },
                {
                  max: 30,
                  message: firstnameMaxLengthError
                }
              ]}
            >
              <CustomInputField
                form={form}
                label={firstnameLabel}
                name="firstname"
                type="text"
              />
            </Item>
          </div>
          <div className="registration-form__field">
            <Item
              name="lastname"
              rules={[
                {
                  required: true,
                  message: lastnamePresenceError
                },
                {
                  max: 30,
                  message: lastnameMaxLengthError
                }
              ]}
            >
              <CustomInputField
                form={form}
                label={lastnameLabel}
                name="lastname"
                type="text"
              />
            </Item>
          </div>
        </div>

        <Item
          name="email"
          rules={[
            {
              type: "email",
              message: emailPatternError
            },
            {
              required: true,
              message: emailPresenceError
            },
            {
              max: 45,
              message: emailMaxLengthError
            }
          ]}
        >
          <CustomInputField
            form={form}
            label={emailLabel}
            name="email"
            type="email"
          />
        </Item>

        <div className="registration-completion-form__field-group">
          <div className="registration-completion-form__field">
            <Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: passwordPresenceError
                },
                {
                  min: 10,
                  message: passwordMinLengthError
                },
                {
                  max: 255,
                  message: passwordMaxLengthError
                },
                {
                  message: passwordLowercasePresentError,
                  pattern: lowerCaseRegEx
                },
                {
                  message: passwordUppercasePresentError,
                  pattern: upperCaseRegEx
                },
                {
                  message: passwordNumberPresentError,
                  pattern: numberRegEx
                },
                {
                  message: passwordSpecialCharacterPresentError,
                  pattern: specialCharacterRegEx
                }
              ]}
            >
              <CustomInputField
                form={form}
                label={passwordLabel}
                name="newPassword"
                noMarginBottom={true}
                type="password"
              />
            </Item>
          </div>

          <div className="registration-completion-form__field">
            <Item
              name="newPasswordConfirm"
              rules={[
                { required: true, message: passwordConfirmPresenceError },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      value?.trim() != "" &&
                      getFieldValue("newPassword") != value
                    ) {
                      return Promise.reject(
                        new Error(passwordConfirmRepeatError)
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <CustomInputField
                form={form}
                label={passwordConfirmLabel}
                name="newPasswordConfirm"
                noMarginBottom={true}
                type="password"
              />
            </Item>
          </div>

          {!userStore?.isLoginAndPurchase &&
            <div>
              <p className="registration-form__coupon-info">
                {collaborationCodeInfo}
              </p>

              <Item name="collaborationCode">
                <CustomInputField
                  form={form}
                  label={collaborationCodeLabel}
                  name="collaborationCode"
                  type="text"
                />
              </Item>
            </div>
          }
        </div>

        {userStore?.isLoginAndPurchase &&
          <>
            <p className="purchase__summary-content__message">
              {purchaseMessage}
            </p>
            <div style={{ textAlign: 'center', paddingRight: 30, paddingBottom: 20 }}>
              <Title level={5}> {purchaseCourseViaLabel}</Title>
            </div>

            <Item
              name='addressLine1'
              rules={[
                {
                  message: streetPresenceError,
                  required: true,
                  whitespace: true
                }
              ]}
            >
              <CustomInputField
                form={form}
                label={streetLabel}
                name="addressLine1"
                type="text"
              />
            </Item>

            <Item
              name="zipCode"
              rules={[
                {
                  message: postalCodePresenceError,
                  required: true,
                  whitespace: true
                },
                {
                  max: 5,
                  message: postalCodeMaxLengthError
                }
              ]}
            >
              <CustomInputField
                form={form}
                label={postalCodeLabel}
                name="zipCode"
                noMarginBottom={true}
                type="text"
              />
            </Item>

            <Item
              name="city"
              rules={[
                {
                  message: cityPresenceError,
                  required: true,
                  whitespace: true
                }
              ]}
            >
              <CustomInputField
                form={form}
                label={cityLabel}
                name="city"
                noMarginBottom={true}
                type="text"
              />
            </Item>

            {countriesConfigs.countries?.length > 0 &&
              <Item
                label={<span className="country-field__label" style={{ color: (!isEmptyValue || activeInput) ? mainBlue : hasError ? guardsmanRed : 'rgba(0, 0, 0, 0.26)' }}>{countryLabel}</span>}
                labelCol={{ span: 24 }}
                name="country"
                style={{ marginBottom: 20 }}
                className={cx({
                  "country-field--dirty": !isEmptyValue,
                  "country-field--focused": activeInput
                })}
                rules={[
                  {
                    message: countryPresenceError,
                    required: true
                  }
                ]}
              >
                <Select
                  allowClear
                  filterOption={filterOption}
                  loading={countriesConfigs.countriesLoading}
                  onBlur={() => setActiveInput(false)}
                  onFocus={() => setActiveInput(true)}
                  placeholder={countryLabel}
                  showSearch
                >
                  {countriesConfigs.countries.map((country: any) => (
                    <Option key={country.value} value={country.value}>{country.label}</Option>
                  ))}
                </Select>
              </Item>
            }
          </>
        }

        <Item
          className="disable-invert"
          name="terms"
          rules={[
            {
              validator: async (_, value) => {
                if (!value || value == false) {
                  return Promise.reject(termsPresenceError);
                }
                return Promise.resolve();
              }
            }
          ]}
          style={{ marginBottom: 10 }}
          valuePropName="checked"
        >
          <Checkbox>{termsLabel}</Checkbox>
        </Item>

        <Item
          className="disable-invert"
          name="newsletter"
          valuePropName="checked"
        >
          <Checkbox onChange={handleDataPrivacyText}>
            {newsletterLabel} <a
              className="registration-completion-form__link"
              href={isEnglish ? `${EXTERNAL_BASE_URL}/en/privacy-policy` : `${EXTERNAL_BASE_URL}/de/datenschutz`}
            >
              {dataPrivacyStatement}
            </a>.
            <br />
            <br />

            {dataPrivacyStatementValue && <><FormattedMessage {...intlMessages.newsletterLabelExtra} values={{ br: <br />, b: (chunks) => <strong >{chunks}</strong> }} /> <a
              className="registration-completion-form__link"
              href={isEnglish ? `${EXTERNAL_BASE_URL}/en/privacy-policy` : `${EXTERNAL_BASE_URL}/de/datenschutz`}
            >
              {termsPrivacyPolicyLink}
            </a>.</>}
          </Checkbox>
        </Item>

        <SubmitButton disabled={loading}>
          {registerSubmitLabel}
        </SubmitButton>
      </Form>
    </div>
  );
};

export default injectIntl(observer(RegistrationForm));
