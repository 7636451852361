/**
 * @author Pradeep Gill pradeep.gill@u4i.io
 */
import React from "react";
import { Select, Row, Col } from "antd";
import { SelectValue } from "antd/lib/select";
import { debounce } from "lodash";

import { AvailableLabs } from "./interface";

const { Option } = Select;

interface IPropTypes {
  availableLabs: AvailableLabs[];
  selectedLabId: SelectValue;
  onChangeLab: (labId: SelectValue) => void;
  filterDLBySearchValue: (labId: SelectValue) => void;
  onBlurDLSelectBox: () => void;
  showSelectAllOption?: boolean;
}

export const LabSelectBox: React.FunctionComponent<IPropTypes> = (props) => {
  const {
    availableLabs,
    selectedLabId,
    showSelectAllOption,
    onChangeLab,
    filterDLBySearchValue,
    onBlurDLSelectBox,
  } = props;

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Select
            filterOption={false}
            style={{ width: "100%" }}
            placeholder="Select Lab"
            showSearch={true}
            allowClear={false}
            onChange={(val) => onChangeLab(val)}
            value={selectedLabId}
            onSearch={debounce(
              (value: string) => filterDLBySearchValue(value),
              400
            )}
            onBlur={onBlurDLSelectBox}
          >
            {/* {showSelectAllOption && (
              <Option key="select_all" value="select_all">
                Select all Digital lab
              </Option>
            )} */}
            {availableLabs.map((lab: AvailableLabs) => (
              <Option key={lab.id} value={lab.id} disabled={lab.disable}>
                {lab.title}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </>
  );
};
