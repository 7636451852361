import { LanguageEnum } from '@u4i/common/enums/LanguageEnum';
import React from 'react';

import {tabsList} from './tabsList';
import {TabsListItem} from './TabsListItem';

import './_tabs.scss';

interface IPropTypes {
  currentLocale: LanguageEnum
  locationRoot: string
  tabs: {
    discover?: string
    home?: string
  }
}

const Tabs: React.FC<IPropTypes> = (props) => {
  const enabledTabsIds: string[] = Object.keys(props.tabs);
  const visibleTabs = tabsList
    .filter(tab => enabledTabsIds.includes(tab.id))
    .map(tab => {
      if (tab.route) {
        return {
          ...tab,
          route: props.tabs[tab.id],
        }
      } else {
        return tab;
      }
    });

  const activeTab = visibleTabs.find(tab => tab.route && tab.route === props.locationRoot);
  const activeTabId = activeTab ? activeTab.id : undefined;

  return (
    <div className="main-layout__tabs">
      <ul className="main-layout__tabs__list list-unstyled">
        {visibleTabs.map((tab, index) => (
          <TabsListItem
            activeTabId={activeTabId}
            currentLocale={props.currentLocale}
            tab={tab}
            key={index}
          />
        ))}
      </ul>
    </div>
  );
}

export default Tabs;
