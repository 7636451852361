import React, { useState } from "react";
import { StarFilled, SmileOutlined } from "@ant-design/icons";
import { notification, Result, Row, Typography } from "antd";
const { Text } = Typography;

const QuickFeedBack = (props) => {
  const [stars, setStars] = useState([
    {
      component: (
        <StarFilled
          style={{
            fontSize: " 22px",
            padding: "5px",
            cursor: "pointer"
          }}
        />
      ),
      class: ""
    },
    {
      component: (
        <StarFilled
          style={{
            fontSize: " 22px",
            padding: "5px",
            cursor: "pointer"
          }}
        />
      ),
      class: ""
    },
    {
      component: (
        <StarFilled
          style={{
            fontSize: " 22px",
            padding: "5px",
            cursor: "pointer"
          }}
        />
      ),
      class: ""
    },
    {
      component: (
        <StarFilled
          style={{
            fontSize: " 22px",
            padding: "5px",
            cursor: "pointer"
          }}
        />
      ),
      class: ""
    },
    {
      component: (
        <StarFilled
          style={{
            fontSize: " 22px",
            padding: "5px",
            cursor: "pointer"
          }}
        />
      ),
      class: ""
    }
  ]);
  const [step, setStep] = useState<number>(1);

  const handleRateCourseChapter = (rate) => {
    props
      .rateCourseByChapter(props.skillID, {
        chapter_id: props.activeChapterData.id,
        rating: rate,
      })
      .then(() => {
        setStep(2);
        setTimeout(() => {
          notification.destroy("feedback");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {step === 2 && (
        <Result
          icon={<SmileOutlined />}
          title={props.feedBackText}
        />
      )}
      {step === 1 && (
        <>
          <Row
            justify="space-around"
            style={{
              margin: "20px auto 0px auto",
              width: "75%"
            }}
          >
            {stars.map((star, index) => {
              return (
                <span
                  key={index}
                  className={star.class}
                  onClick={() => handleRateCourseChapter(index + 1)}
                  onMouseEnter={() => {
                    setStars(
                      stars.map((star, j) => {
                        if (j <= index) {
                          star.class = "feedback_star";
                        }
                        return star;
                      })
                    );
                  }}
                  onMouseLeave={() => {
                    setStars(
                      stars.map((star, j) => {
                        star.class = "";
                        return star;
                      })
                    );
                  }}
                >
                  {star.component}
                </span>
              );
            })}
          </Row>
          <Row
            justify="space-between"
            style={{
              margin: "0px auto 0px auto",
              width: "85%"
            }}
          >
            <Text type="secondary">{props.unlikely}</Text>
            <Text type="secondary">{props.likely}</Text>
          </Row>
          <Row justify="center" className="mt-16">
            {props.showFullFeedBack && (
              <Text
                type="secondary"
                style={{
                  textAlign: "center",
                }}
              >
                {props.clickLinkText}{" "}
                <u
                  style={{ color: "#1890ff", cursor: "pointer" }}
                  onClick={props.startFullFeedBack}
                >
                  link
                </u>
              </Text>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default QuickFeedBack;
