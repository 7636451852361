import React, { FC } from 'react';
import {TableOfContentsListItem} from './TableOfContentsListItem';

import './_table-of-contents-list.scss';

interface IPropTypes {
  contents: Array<{
    id: string
    text: string
  }>
}

export const TableOfContentsList: FC<IPropTypes> = (props: IPropTypes) => (
  <ul className="list-unstyled player__table-of-contents-list">
    {props.contents.map(entry => (
      <li className="player__table-of-contents-list__item" key={entry.id}>
        <TableOfContentsListItem>
          {entry.text}
        </TableOfContentsListItem>
      </li>
    ))}
  </ul>
);
