import moment from "moment";
import { action, observable, runInAction, makeObservable } from "mobx";
import { IApi } from "@u4i/state/ServicesInterfaces";
import { IDigitalLab } from "@u4i/parsers/DigitalLabParser";
import { IRootStore } from "@u4i/state/RootStore";
import UserStore from "@u4i/state/UserStore";
import { CalendarEvent, ILabs } from "@u4i/modules/DigitalLab/components/Booking/interface";
import { XAPIVerbs } from "@u4i/common/enums/XApiEnum";
import { postStatement } from "@u4i/common/TraxLrs";

export class DigitalLabStore {
  private apiService: IApi;
  private labId?: string;
  private userStore: UserStore;

  closingLab = false;
  fetching = true;
  isFullscreen = false;
  isLabClosed = false;
  isLabFinishedLoading = false;
  isLabSubmitted = false;
  labData?: IDigitalLab;
  labError = false;
  errorMessageDescription: string;
  labSubmitError = false;
  submittingLab = false;
  showSubmissionStatusWindow = false;
  currentLabEndTime: Date;
  sessionCountDown: string = '';
  isLabSessionEndsInFiveMin: boolean = false;
  isLabSessionEndsNotificationsShown: boolean = false;
  labHasScheduler:boolean = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      closingLab: observable,
      fetching: observable,
      isFullscreen: observable,
      isLabClosed: observable,
      isLabFinishedLoading: observable,
      isLabSubmitted: observable,
      labData: observable,
      labError: observable,
      errorMessageDescription: observable,
      labSubmitError: observable,
      submittingLab: observable,
      showSubmissionStatusWindow: observable,
      sessionCountDown: observable,
      isLabSessionEndsInFiveMin: observable,
      labHasScheduler: observable,

      changeFullscreenStatus: action.bound,
      closeLab: action.bound,
      init: action.bound,
      loadLabData: action.bound,
      expireSession: action.bound,
      reset: action.bound,
      setFetching: action.bound,
      submitLab: action.bound,
      validateUserForUsingDL: action.bound,
      closeLabSessionEndNotification: action.bound,
    });

    const { apiService, userStore } = rootStore;

    this.apiService = apiService;
    this.userStore = userStore;
  }

  changeFullscreenStatus(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
  }

  async closeLab() {
    if (this.labId && this.labData) {
      this.closingLab = true;

      try {
        await this.apiService.digitalLab.expireSession(
          this.labId,
          this.labData.challengeAttemptId,
          false
        );
      } catch (error) {
      } finally {
        runInAction(() => {
          this.closingLab = false;
          this.isLabClosed = true;
        });
      }
    }
  }

  async init(labId: string) {
    this.labId = labId;
    const isUserValidForLab = await this.validateUserForUsingDL(this.labId);

    if (isUserValidForLab) {
      await this.loadLabData(labId);
    } else {
      this.labError = true;
      this.errorMessageDescription = "Sorry no right now!";
    }
  }

  async loadLabData(labId: string) {
    if (labId) {
      this.fetching = true;

      try {
        const labDataResponse = await this.apiService.digitalLab.fetchStreamingUrl(labId);

        runInAction(() => {
          this.labData = labDataResponse;
          this.labSessionCounter();
          postStatement(XAPIVerbs.launched, 'challenge-attempt', this.labData.challengeAttemptId, this.labData.title);
        });
      } catch (error) {
        runInAction(() => {
          this.labError = true;
          this.errorMessageDescription = error?.response?.data?.errorMessage;
        });
      } finally {
        runInAction(() => {
          this.fetching = false;
          this.isLabFinishedLoading = true;
        });
      }
    }
  }

  async expireSession() {
    if (this.labId && this.labData) {
      try {
        const resp = await this.apiService.digitalLab.expireSession(
          this.labId,
          this.labData.challengeAttemptId,
          true
        );
      } catch (error) {
        runInAction(() => {
          this.labSubmitError = true;
          this.showSubmissionStatusWindow = false;
        });
      }
    }
  }

  reset() {
    this.labId = undefined;
    this.fetching = true;
    this.isLabClosed = false;
    this.isLabFinishedLoading = false;
    this.isLabSubmitted = false;
    this.labData = undefined;
    this.labError = false;
    this.labSubmitError = false;
    this.errorMessageDescription = '';
    this.submittingLab = false;
    this.showSubmissionStatusWindow = false;
  }

  setFetching() {
    this.fetching = true;
  }
  
  async submitLab() {
    if (this.labId && this.labData) {
      this.submittingLab = true;
      this.showSubmissionStatusWindow = true;

      try {

        await this.apiService.digitalLab.expireSession(this.labId, this.labData.challengeAttemptId, true);
      } catch (error) {
        runInAction(() => {
          this.labSubmitError = true;
          this.showSubmissionStatusWindow = false;
        });
      } finally {
        runInAction(() => {
          this.submittingLab = false;
          this.isLabSubmitted = true;
          postStatement(XAPIVerbs.experienced, 'challenge-attempt', this.labData?.challengeAttemptId, this.labData?.title);
          postStatement(XAPIVerbs.submitted, 'challenge-attempt', this.labData?.challengeAttemptId, this.labData?.title);
        });
      }
    }
  }

  async validateUserForUsingDL(labId: string) {
    let validUserForLab: boolean = false;
    const courseId = window.sessionStorage.getItem("courseId") ? (window.sessionStorage.getItem("courseId") as string) : "";

    const labInfo: ILabs = await this.apiService.digitalLab.getDigitalLabInfo(labId);
    this.labHasScheduler = labInfo.hasScheduler;
    if (!labInfo.hasScheduler) {
      validUserForLab = true;
      return validUserForLab;
    }

    let labBookedSlotForToday: CalendarEvent[] = await this.apiService.digitalLab.fetchLabBookingsById(labId);
    let gapSubtraction8hours: string = moment(new Date()).subtract(8, 'hours').utc().format();
    let nowDate: string = moment(new Date()).utc().format();

    labBookedSlotForToday = labBookedSlotForToday.filter(x => x.end > new Date(gapSubtraction8hours) || (nowDate < moment(x.end).utc().format() && nowDate > moment(x.start).utc().format()));

    const totalSlotTime: number = labInfo.restTimeDuration + labInfo.userSessionDuration;
    const nowTime: Date = new Date();
    const checkLabFreeSlot = moment(new Date()).add(totalSlotTime, "minutes");

    let notMineSlotsSubstracted: string = moment(new Date()).subtract(totalSlotTime, 'minutes').format();

    labBookedSlotForToday = labBookedSlotForToday.filter(x => x.isMine == true || (x.isMine == false && x.end > new Date(notMineSlotsSubstracted)));

    if (!labBookedSlotForToday.length) {
      const utcEventObj = {
        start: moment.utc(nowTime).format(),
        end: moment.utc(checkLabFreeSlot).format(),
        title: labInfo.title,
      };
      const bookSlotResponse = await this.apiService.digitalLab.bookLabSlot(labId, utcEventObj);
      if (bookSlotResponse) {
        this.currentLabEndTime = new Date(
          moment(utcEventObj.end).subtract(labInfo.restTimeDuration, "minutes").valueOf()
        );
      }
      return true;
    }

    let userBookedSlotsForToday: any[] = await this.apiService.digitalLab.userLabBookedSlots(labId, this.userStore.userData.id);

    userBookedSlotsForToday = userBookedSlotsForToday.filter(x => moment(x.slotEnd).utc().format() > gapSubtraction8hours || (nowDate < moment(x.slotEnd).utc().format() && nowDate > moment(x.slotStart).utc().format()));

    for (let i = 0; i < labBookedSlotForToday.length; i++) {
      for (let k = 0; k < userBookedSlotsForToday.length; k++) {
        const localUserStartTime = new Date(
          userBookedSlotsForToday[k].slotStart
        );

        const localUserEndTime = new Date(
          moment(userBookedSlotsForToday[k].slotEnd).subtract(labInfo.restTimeDuration, "minutes").valueOf()
        );

        const isBetweenTheStartAndEndTime = moment(nowTime).isBetween(localUserStartTime, localUserEndTime);

        if (userBookedSlotsForToday[k].isMine && isBetweenTheStartAndEndTime) {
          validUserForLab = true;
          this.currentLabEndTime = localUserEndTime;
          return validUserForLab;
        }
      }

      const isNextSlotAvailableForBooking: number = moment(labBookedSlotForToday[i].start).diff(checkLabFreeSlot, "minutes");

      if (!validUserForLab && isNextSlotAvailableForBooking >= totalSlotTime) {
        const utcEventObj = {
          start: moment.utc(nowTime).format(),
          end: moment.utc(checkLabFreeSlot).format(),
          title: labInfo.title,
        };
        const bookSlotResponse = await this.apiService.digitalLab.bookLabSlot(labId,utcEventObj);

        if (!bookSlotResponse.errorMessage) {
          validUserForLab = true;
          this.currentLabEndTime = new Date(
            moment(utcEventObj.end)
              .subtract(labInfo.restTimeDuration, "minutes")
              .valueOf()
          );
          return validUserForLab;
        } else {
          return validUserForLab;
        }
      }
    }

    return validUserForLab;
  }

  labSessionCounter() {
    const endTimer = new Date(this.currentLabEndTime).getTime();

    if (this.labHasScheduler && moment(this.currentLabEndTime).isAfter(moment())) {
      let timeInterval = setInterval(() => {
        const nowTime = new Date().getTime();

        const timeDifference = endTimer - nowTime;
        if (timeDifference <= 0) {
          this.closeLab();
          clearInterval(timeInterval);
          return false;
        }

        let hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes =
          Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)) >= 10
            ? Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
            : `0${Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
              )}`;
        let seconds =
          Math.floor((timeDifference % (1000 * 60)) / 1000) >= 10
            ? Math.floor((timeDifference % (1000 * 60)) / 1000)
            : `0${Math.floor((timeDifference % (1000 * 60)) / 1000)}`;

        this.sessionCountDown = `${hours}:${minutes}:${seconds}`;

        if (
          !this.isLabSessionEndsNotificationsShown &&
          timeDifference <= 300000 &&
          timeDifference > 0
        ) {
          this.isLabSessionEndsInFiveMin = true;
          this.isLabSessionEndsNotificationsShown = true;
        }
      }, 1000);
    }
  }

  closeLabSessionEndNotification() {
    this.isLabSessionEndsInFiveMin = false;
  }
}
