import {action, computed, observable, runInAction,makeObservable} from 'mobx';
import DiscussAnswerStatus from '@u4i/common/enums/DiscussAnswerStatus';
import {IApi, ILogger} from '@u4i/state/ServicesInterfaces';
import {IDiscussAnswer} from '@u4i/parsers/DiscussAnswerParser';
import {IRootStore} from '@u4i/state/RootStore';
import DiscussCommentEntity from './DiscussCommentEntity';
import DiscussLikeEntity from './DiscussLikeEntity';
import IEntity from './IEntity';
import { IDiscussComment } from '@u4i/parsers/DiscussCommentParser';
import { IDiscussLike } from '@u4i/parsers/DiscussLikeParser';

class DiscussAnswerEntity implements IEntity<IDiscussAnswer> {
  private apiService: IApi;
  private logger: ILogger;
  private rootStore: IRootStore;
  anonymously: boolean;
  comments: Array<DiscussCommentEntity> = [];
  commentCount: number;
  content: string;
  createdAt: string;
  id: string;
  isLiked: boolean;
  likeCount: number;
  likes: Array<DiscussLikeEntity> = [];
  status: DiscussAnswerStatus;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    profileImage: string;
  };

  constructor(answerData: IDiscussAnswer, rootStore: IRootStore) {
    makeObservable(this, {
      anonymously: observable,
      comments: observable,
      commentCount: observable,
      content: observable,
      createdAt: observable,
      id: observable,
      isLiked: observable,
      likeCount: observable,
      likes: observable,
      status: observable,
      user: observable,
      serializedData: computed,
      applyDataChanges: action.bound,
      loadComments: action.bound,
      loadLikes: action.bound,
      submitComment: action.bound,
      toggleLike: action.bound,
      update: action.bound
    });

    const {apiService, loggerFactory} = rootStore;

    this.apiService = apiService;
    this.logger = loggerFactory.createLogger('DiscussAnswerEntity');
    this.rootStore = rootStore;

    this.applyDataChanges(answerData);
  }

  get serializedData(): IDiscussAnswer {
    const {anonymously, commentCount, content, createdAt, id, isLiked, likeCount, status, user} = this;
    return {anonymously, commentCount, content, createdAt, id, isLiked, likeCount, status, user};
  }

  applyDataChanges = (dataChanges: Partial<IDiscussAnswer>) => {
    Object.assign(this, dataChanges);
  }

  loadComments = async () => {
    const comments: IDiscussComment[] = await this.apiService.discuss.fetchComments(this.id);
    const commentsEntities: DiscussCommentEntity[] = comments.map(comment => new DiscussCommentEntity(comment, this.rootStore));

    runInAction(() => {
      this.comments = commentsEntities;
    });

    this.logger.info('comments loaded');
  }

  loadLikes = async () => {
    const likes: IDiscussLike[] = await this.apiService.discuss.fetchLikes(this.id);
    const likesEntities: DiscussLikeEntity[] = likes.map(data => new DiscussLikeEntity(data));

    runInAction(() => {
      this.likes = likesEntities;
    });

    this.logger.info('likes loaded');
  }

  submitComment = async (values: {anonymity: boolean, content: string}) => {
    this.commentCount += 1;

    try {
      const comment: IDiscussComment = await this.apiService.discuss.submitComment(this.id, values);
      const commentEntity: DiscussCommentEntity = new DiscussCommentEntity(comment, this.rootStore);

      runInAction(() => {
        this.comments.push(commentEntity);
      });

      this.logger.info('comment submitted');
    } catch (error) {
      runInAction(() => {
        this.commentCount -= 1;
      });

      this.logger.error(error);

      throw error;
    }
  }

  toggleLike = async () => {
    const likeCountModifier = this.isLiked ? -1 : 1;

    this.isLiked = !this.isLiked;
    this.likeCount += likeCountModifier;

    try {
      await this.apiService.discuss.toggleAnswerLike(this.id);

      this.logger.info('like toggled');
    } catch (error) {
      runInAction(() => {
        this.likeCount -= likeCountModifier;
        this.isLiked = !this.isLiked;
      });

      this.logger.error(error);

      throw error;
    }
  }

  update = async (partialDataChanges: Partial<IDiscussAnswer>) => {
    const discussAnswerData: IDiscussAnswer = this.serializedData;
    this.applyDataChanges(partialDataChanges);

    try {
      const updatedAnswer: IDiscussAnswer = await this.apiService.discuss.updateAnswer(this.serializedData);

      this.applyDataChanges(updatedAnswer);
    } catch (error) {
      this.applyDataChanges(discussAnswerData);

      throw error;
    }
  }
}

export default DiscussAnswerEntity;
