import {defineMessages} from 'react-intl';

export default defineMessages({
  aboutUs: {
    defaultMessage: 'About Us',
    id: 'MainLayout.Navbar.Tabs.AboutUs',
  },
  discover: {
    defaultMessage: 'Discover',
    id: 'MainLayout.Navbar.Tabs.Discover',
  },
});
