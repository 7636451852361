import PropTypes from "prop-types";
import React, { Component } from "react";
import { v4 as uuid } from "uuid";
import { debounce } from "lodash";
import QQQVideoPlayer from "@u4i/common/QQQVideoPlayer";
import PlayerVideoProviderEnum from "@u4i/common/enums/PlayerVideoProviderEnum";
import { PLAYER_AUTOPLAY_VISIBILITY_THRESHOLD } from "@u4i/constantSettings";
import YoutubePlayer from "@u4i/common/YoutubePlayer";
import VisibilityPercentageContext from "@u4i/modules/VirtualExperience/components/VisibilityPercentage/VisibilityPercentageContext";
import { XAPIVerbs } from "@u4i/common/enums/XApiEnum";
import { postStatement } from "@u4i/common/TraxLrs";
import "./_video-element.scss";

class VideoElement extends Component<any> {
  static propTypes = {
    provider: PropTypes.oneOf([
      PlayerVideoProviderEnum.QQQ,
      PlayerVideoProviderEnum.YOUTUBE,
    ]).isRequired,
    url: PropTypes.string.isRequired,
    visibilityPercentage: PropTypes.number.isRequired,
    videoId: PropTypes.string.isRequired,
    elementId: PropTypes.string,
    title: PropTypes.string
  };

  playerRef = React.createRef();

  state = {
    videoLoaded: false,
  };

  constructor(props) {
    super(props);
    // @ts-ignore
    this.containerId = `video-player-${uuid()}`;
    this.checkAutoplay = debounce(this.checkAutoplay, 300, { trailing: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const autoplayStop =
      nextProps.visibilityPercentage < PLAYER_AUTOPLAY_VISIBILITY_THRESHOLD &&
      this.props.visibilityPercentage >= PLAYER_AUTOPLAY_VISIBILITY_THRESHOLD;

    return autoplayStop || nextState !== this.state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.visibilityPercentage !== prevProps.visibilityPercentage) {
      this.checkAutoplay();
    }
  }

  componentWillUnmount() {
    console.log("video element unmounted");
  }

  sendPlayedStatement = () => {
    postStatement(XAPIVerbs.played, 'element', this.props.elementId, this.props.title);
  }

  sendPausedStatement = () => {
    postStatement(XAPIVerbs.paused, 'element', this.props.elementId, this.props.title);
  }

  sendWatchedStatement = () => {
    postStatement(XAPIVerbs.watched, 'element', this.props.elementId, this.props.title);
  }

  get videoId() {
    const paths = this.props.url.split("/");
    const videoId = paths[paths.length - 1];

    return videoId;
  }

  get playerElement() {
    switch (this.props.provider) {
      case PlayerVideoProviderEnum.QQQ:
        return (
          <QQQVideoPlayer
            onReady={this.enableAutoplayCheck}
             // @ts-ignore
            containerId={this.containerId}            
            playedStatement={this.sendPlayedStatement}
            pausedStatement={this.sendPausedStatement}
            completedStatement={this.sendWatchedStatement}
            dataId={this.videoId}
            ref={this.playerRef}
            hasExtraElements={this.props.hasExtraElements}
          />
        );

      case PlayerVideoProviderEnum.YOUTUBE:
        return (
          <YoutubePlayer
            onReady={this.enableAutoplayCheck}
             // @ts-ignore
            ref={this.playerRef}
            hasExtraElements={this.props.hasExtraElements}
            elementId={this.props.elementId}
            title={this.props.title}
            url={this.props.url}
            videoId={this.props.videoId}
          />
        );

      default:
        return null;
    }
  }

  checkAutoplay = () => {
    if (!this.state.videoLoaded) {
      return;
    }

    const playerElement: any = this.playerRef.current;

    if (this.props.visibilityPercentage < PLAYER_AUTOPLAY_VISIBILITY_THRESHOLD) {
      switch (this.props.provider) {
        case PlayerVideoProviderEnum.YOUTUBE:
          playerElement.pausePlayback();
          break;

        case PlayerVideoProviderEnum.QQQ:
          playerElement.pause();
          break;

        default:
      }
    }
  };

  enableAutoplayCheck = () => {
    this.setState({ videoLoaded: true }, this.checkAutoplay);
  };

  render() {
    return <div className="player__video-element">{this.playerElement}</div>;
  }
}
export default (props) => (
  <VisibilityPercentageContext.Consumer>
    {(visibilityPercentage) => (
      <VideoElement {...props} visibilityPercentage={visibilityPercentage} />
    )}
  </VisibilityPercentageContext.Consumer>
);
