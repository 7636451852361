import {action, observable,makeObservable} from 'mobx';

import {IDiscussLike} from '@u4i/parsers/DiscussLikeParser';
import IEntity from './IEntity';

class DiscussLikeEntity implements IEntity<IDiscussLike> {
  createdAt: string;
  id: string;
  user: {
    firstName: string;
    id: string;
    lastName: string;
    profileImage: string;
  };

  constructor(likeData: IDiscussLike) {
    makeObservable(this, {
      createdAt: observable,
      id: observable,
      user: observable,
      applyDataChanges: action.bound
    });

    Object.assign(this, likeData);
  }

  get serializedData(): IDiscussLike {
    const {createdAt, id, user} = this;
    return {createdAt, id, user};
  }

  applyDataChanges(dataChanges: Partial<IDiscussLike>) {
    Object.assign(this, dataChanges);
  }
}

export default DiscussLikeEntity;
