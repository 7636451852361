import React, { Fragment } from 'react';
import {action, observable,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ISkillBasic} from '@u4i/parsers/SkillParserBasic';
import {ItemSwitch} from './components/ItemSwitch';
import {SkillList} from '../SkillList';
import intlMessages from './intlMessages';
import { ICurriculum } from '@u4i/common/Interfaces/CurriculumInterface';
import './_curriculum-mobile.scss';

interface IPropTypes {
  curriculumData: ICurriculum;
  onSkillCardClick: (skill: ISkillBasic) => void;
}

export const CurriculumMobile = observer(class CurriculumMobile extends React.Component<IPropTypes> {
  private isOpen = false;
  private selectedItem = this.props.curriculumData.rows[0];

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<CurriculumMobile, "isOpen" | "selectedItem">(this, {
      isOpen: observable,
      selectedItem: observable,
      handleItemClick: action.bound,
      handleSwitchClick: action.bound
    });
  }

  get activeItemsCount() {
    return this.props.curriculumData.rows.length;
  }

  get activeRow() {
    if (this.selectedItem) {
      return this.props.curriculumData.rows.find(row => row.id === this.selectedItem.id);
    }

    return null;
  }

  handleItemClick(itemId: string) {
    const newSelectedItem = this.props.curriculumData.rows.find(row => row.id === itemId);

    if (newSelectedItem) {
      this.isOpen = !this.isOpen;
      this.selectedItem = newSelectedItem;
    }
  }

  handleSwitchClick() {
    this.isOpen = !this.isOpen;
  }

  render() {
    return (
      <div className="landing-page__curriculum-mobile">
        <div className="landing-page__curriculum-mobile__switch-wrap">
          <h4 className="landing-page__curriculum-mobile__switch-text">
            <FormattedMessage {...intlMessages.selectModule} />

            <span className="landing-page__curriculum-mobile__item-count">
              {` (${this.activeItemsCount})`}
            </span>
            :
          </h4>

          <ItemSwitch
            activeItems={this.props.curriculumData.rows}
            onItemClick={this.handleItemClick}
            onSwitchClick={this.handleSwitchClick}
            isOpen={this.isOpen}
            selectedItem={this.selectedItem}
          />
        </div>

        <div className="landing-page__curriculum-mobile__table-wrap">
          {this.activeRow &&
            <table className="landing-page__curriculum-mobile__table">
              <tbody>
                {this.activeRow.items.map((columnItems, columnIndex) => (
                  <Fragment key={columnIndex}>
                    <tr>
                      <th>{this.props.curriculumData.columns[columnIndex]}</th>
                    </tr>

                    <tr>
                      <td>
                        <SkillList
                          flexibleWidth
                          items={columnItems}
                          onSkillCardClick={this.props.onSkillCardClick}
                        />
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    );
  }
});

export default CurriculumMobile;
