import {defineMessages} from 'react-intl';

export default defineMessages({
  criterions: {
    defaultMessage: 'Criterions:',
    id: 'ReactiveForms.Forms.AttemptGradingForm.GradingGuidelines.Criterions',
  },
  modelAnswer: {
    defaultMessage: 'Example solution:',
    id: 'ReactiveForms.Forms.AttemptGradingForm.GradingGuidelines.ModelAnswer',
  },
});
