import React, { FC } from 'react';
import {Link} from 'react-router-dom';
import logoImage from '@u4i/assets/images/logoImage.png';
import './_logo.scss';

interface IPropTypes {
  groupImage?: string;
  groupName?: string;
  useLink: boolean;
}

const Logo: FC<IPropTypes> = (props) => {
  const componentContent = (
    <div className="main-layout__logo__image-wrapper">
      <img
        alt={props.groupImage ? props.groupName : 'University4Industry'}
        className="main-layout__logo__image"
        src={props.groupImage || logoImage}
      />
    </div>
  );

  return props.useLink ? (
    <Link className="main-layout__logo" to="/">
      {componentContent}
    </Link>
  ) : (
    <div className="main-layout__logo">
      {componentContent}
    </div>
  );
}

export default Logo;
