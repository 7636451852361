import React, { useContext, useState } from "react";
import { OnboardingTour } from "@u4i/common/OnboardingTour";
import PlayerMenu from "./PlayerMenu";
import PlayerContent from "./PlayerContent";
import LoadingScreen from "./components/LoadingScreen";
import withPlayerDataFetcher from "./PlayerState/withPlayerDataFetcher";
import withPlayerRouter from "./PlayerState/withPlayerRouter";
import ErrorAlert from "./components/ErrorAlert";
import PlayerProvider, { PlayerContext } from "./PlayerState/playerContext";
import "./_index.scss";

interface IProps {
  elements: Array<any>;
  topics: Array<any>;
  exitPlayer: () => void;
  onChapterNavigation: () => void;
}

const Player = withPlayerDataFetcher(
  withPlayerRouter((props: IProps) => {
    const [isMenuActive, setMenuActive] = useState(false);
    const playerState: any = useContext(PlayerContext);

    return (
      <div className="virtual_experience" id="player">
        {playerState.errorCode ? (
          <div className="error_container">
            <div className="player_error">
              <ErrorAlert code={playerState.errorCode} />
            </div>
          </div>
        ) : (
          <>
            {playerState.fetching ? (
              <LoadingScreen />
            ) : (
              <>
                <PlayerMenu
                  isMenuActive={isMenuActive}
                  topics={props.topics}
                  onChapterNavigation={props.onChapterNavigation}
                />
                <PlayerContent
                  isMenuActive={isMenuActive}
                  setMenuActive={setMenuActive}
                  elements={props.elements}
                  onChapterNavigation={props.onChapterNavigation}
                  exitPlayer={props.exitPlayer}
                />
                <OnboardingTour onboardingSequence="player" />
              </>
            )}
          </>
        )}
      </div>
    );
  })
);

export default (props) => {
  return (
    <PlayerProvider>
      <Player {...props} />
    </PlayerProvider>
  );
};
