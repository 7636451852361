import {defineMessages} from 'react-intl';

export default defineMessages({
  errorHeading: {
    defaultMessage: 'Oops!',
    id: 'RegisterConfirmPage.ErrorHeading',
  },
  errorMessage: {
    defaultMessage: 'This registration link has either expired or is invalid',
    id: 'RegisterConfirmPage.ErrorMessage',
  }
});
