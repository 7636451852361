import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import intlMessages from './intlMessages';
import FormatDate from '@u4i/common/FormatDate';

import './_attempt-evaluation-data.scss';

interface IPropTypes {
  date: string;
  groupName: string;
  userName: string;
}

export const AttemptEvaluationData: FC<IPropTypes> = (props) => (
  <div className="customer-grading__attempt-evaluation-data">
    <h4 className="customer-grading__attempt-evaluationdata__heading">
      <FormattedMessage {...intlMessages.attemptInfo} />
    </h4>

    <p className="customer-grading__attempt-evaluation-data__user-data">
      <FontAwesome className="customer-grading__attempt-evaluation-data__icon" name="user" />

      <FormattedMessage {...intlMessages.user} values={{userName: props.userName}}/>

      <span className="customer-grading__attempt-evaluation-data__user-group">{props.groupName}</span>
    </p>
    <p className="customer-grading__attempt-evaluation-data__date">
      <FontAwesome className="far customer-grading__attempt-evaluation-data__icon" name="calendar-alt" />

      <FormattedMessage {...intlMessages.date} />

      <FormatDate longMonth={true} source={props.date} />
    </p>
  </div>
);
