import {defineMessages} from 'react-intl';

export default defineMessages({
  goBack: {
    defaultMessage: 'Go back',
    id: 'JupyterLab.SubmitWindow.GoBack',
  },
  successMessage: {
    defaultMessage: 'Submit successful!',
    id: 'JupyterLab.SubmitWindow.SuccessMessage',
  },
});
