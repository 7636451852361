import React, { FC, useState } from 'react';
import useClientApi from '@u4i/utils/hooks/useClientApi';
import { FormattedMessage } from 'react-intl';
import intlMessages from './intlMessages';
import TestQuestionnaire from './TestQuestionnaire/TestQuestionnaire';
import { Result } from 'antd';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import { useParams } from 'react-router-dom';
import { ChallengeProgressStatusEnum } from '@u4i/common/enums/ChallengeProgressStatusEnum';
import MCQTestFeedback from './MCQTestFeedback/MCQTestFeedback';
import { ITestEvaluation, ITestSubmission } from '@u4i/utils/interfaces';
import { XAPIVerbs } from '@u4i/common/enums/XApiEnum';
import { postStatement } from '@u4i/common/TraxLrs';
import './_mcq-test-element.scss';

interface IProps {
  challenge: any
  elementId: string
  title: string
  onMCQTestChallengeEvaluation: (challengeId: string, status: ChallengeProgressStatusEnum) => void
}

const MCQTestElement: FC<IProps> = (props: IProps) => {
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [testData, setTestData] = useState<ITestEvaluation>();
  const [evaluationData, setEvaluationData] = useState<ITestSubmission>();
  const params: { chapterSlug: string } = useParams();

  const startTest = () => {
    fetchData({
      onSuccess: (res: ITestEvaluation) => {
        setTestData(res);
        setIsStarted(true);
        setIsBlocked(res.is_blocked);
      },
      onError: (err) => {
        if (err?.response?.status == 401) {
          AppNavigator.replace(appRoutes.login, null, null, {
            successRecirectionPathname: `/player/chapter/${params?.chapterSlug}`
          });
        }
      }
    });
  }

  const { fetchData, data } = useClientApi({
    url: `/challenges/elements/${props.elementId}/mcq-tests/${props.challenge?.mcqTestId}`,
    componentDidMount: false
  });

  const { postData } = useClientApi({
    url: `/challenges/elements/${props.elementId}/mcq-tests/${props.challenge?.mcqTestId}/user-answers-submission`,
    componentDidMount: false
  });

  const evaluateTest = (answers: any) => {
    postData({
      url: `/challenges/elements/${props.elementId}/mcq-tests/${props.challenge?.mcqTestId}/user-answers-submission`,
      body: answers,
      setLoading: false,
      onSuccess: (data: ITestSubmission) => {
        postStatement(XAPIVerbs.experienced, 'element', props.elementId, props.title);
        setIsFinished(true);
        setEvaluationData(data);
        props.onMCQTestChallengeEvaluation(props.elementId, data.passed ? ChallengeProgressStatusEnum.COMPLETED : ChallengeProgressStatusEnum.OPEN_TRY_AGAIN);
      }
    })
  }

  if (isBlocked) {
    return (
      <Result
        status="warning"
        className='mcq-result-sections'
        title={<FormattedMessage {...intlMessages.warningTitle} />}
        subTitle={<FormattedMessage {...intlMessages.warningDescription} />}
      />
    )
  }

  if (isFinished && evaluationData) {
    if (evaluationData?.passed) {
      return (
        <div style={{
          position: 'relative',
          height: "100%"
        }}>
          <Result
            status="success"
            className='mcq-result-sections'
            title={<FormattedMessage {...intlMessages.successTitle} />}
            subTitle={<FormattedMessage {...intlMessages.successDescription} values={{ percentage: evaluationData?.pass_rate }} />}
          />

          <MCQTestFeedback
            questions={evaluationData.questions}
          />
        </div>
      )
    } else {
      return (
        <div style={{
          position: 'relative',
          height: "100%"
        }}>
          <Result
            status="error"
            className='mcq-result-sections'
            title={<FormattedMessage {...intlMessages.failedTitle} />}
            subTitle={<FormattedMessage {...intlMessages.failedDescription} values={{ percentage: evaluationData?.pass_rate }} />}
          />

          <MCQTestFeedback
            questions={evaluationData.questions}
          />
        </div>
      )
    }
  }

  return (
    <>
      {!isStarted ?
        <div
          className="player__external-challenge-element"
          style={{ fontSize: 20 }}
        >
          <h3 className="player__external-challenge-element__title">
            {props.title}
          </h3>

          {props.challenge.description && (
            <p className="player__external-challenge-element__description">
              {props.challenge.description}
            </p>
          )}

          <button
            className="player__action-block__button"
            onClick={startTest}
            type="button"
          >
            <FormattedMessage {...intlMessages.start} />
          </button>
        </div>
        :
        testData?.questions &&
        <TestQuestionnaire
          test={testData}
          onTestFinish={evaluateTest}
        />
      }
    </>
  );
}

export default MCQTestElement;
