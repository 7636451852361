import cx from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import intlMessages from "./intlMessages";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { mainBlue } from '@u4i/styles/styles-variables';

import './_attempt-edit-evaluation-confirm-overlay.scss';

interface IPropTypes {
  handleClosePopup: () => void
  handleSubmitPopup: () => void
  showConfirmationPopup: boolean
}

export const AttemptEditEvaluationConfirmOverlay: FC<IPropTypes> = (props: IPropTypes) => {

  return (
    <div
      className={cx({
        'customer-grading__attempt-edit-evaluation-confirm-overlay': true,
        'customer-grading__attempt-edit-evaluation-confirm-overlay--visible': props.showConfirmationPopup,
      })}
    >

      <div
        className={cx({
          'customer-grading__attempt-edit-evaluation-confirm-overlay__success': true,
          'customer-grading__attempt-edit-evaluation-confirm-overlay__success--visible': props.showConfirmationPopup,
        })}
      >
        <InfoOutlined style={{color: mainBlue, height: 66, width: 66}} />

        <div className="customer-grading__attempt-edit-evaluation-confirm-overlay__message">
          <FormattedMessage {...intlMessages.confirmationMessage} />
        </div>

        <div>
          <button
            className="customer-grading__attempt-edit-evaluation-confirm-overlay__button"
            onClick={props.handleSubmitPopup}
            type="button"
          >
            <FormattedMessage {...intlMessages.ok} />
          </button>

          <button
            className="customer-grading__attempt-edit-evaluation-confirm-overlay__button"
            onClick={props.handleClosePopup}
            type="button"
          >
            <FormattedMessage {...intlMessages.close} />
          </button>
        </div>
      </div>
    </div>
  )
};
