import React, { FC } from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "@reach/router";
import SettingsStore from "@u4i/state/SettingsStore";
import UserStore from "@u4i/state/UserStore";
import { LanguageStore } from "@u4i/state/LanguageStore";
import ControlsToggle from "./components/ControlsToggle";
import Logo from "./components/Logo";
import Tabs from "./components/Tabs";
import UserMenu from "./components/UserMenu";

import "./_navbar-base.scss";

interface IProps extends RouteComponentProps {
  controlsVisibleMobile: boolean
  languageStore: LanguageStore
  settingsStore: SettingsStore
  showUserMenu: boolean
  useLinkInLogo: boolean
  userStore: UserStore
  handleControlsMobileClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const NavbarBase: FC<IProps> = (props: IProps) => {
  const {
    controlsVisibleMobile,
    languageStore,
    location,
    settingsStore,
    showUserMenu,
    useLinkInLogo,
    userStore,
    handleControlsMobileClick
  } = props;

  const locationParts: string[] | undefined = location?.pathname?.split("/");
  const tabs: any = userStore.userData.tabs || (settingsStore.settings && settingsStore.settings.tabEntries) || {};

  return (
    <div className="main-layout__navbar__base">
      <Logo
        groupImage={userStore.userData.groupLogoImage}
        groupName={userStore.userData.groupName}
        useLink={useLinkInLogo}
      />

      {!userStore.isLoading &&
        !settingsStore.fetching && (
          <div className="main-layout__navbar__base-wrap">
            <Tabs
              currentLocale={languageStore.currentLocale}
              locationRoot={locationParts ? locationParts[1] || locationParts[0] : ""}
              tabs={tabs}
            />
          </div>
        )}

      <div className="main-layout__navbar__authorization">
        {showUserMenu && userStore.isAuthorized && (
          <UserMenu userData={userStore.userData} />
        )}

        {!userStore.isAuthorized && (
          <ControlsToggle
            onClick={handleControlsMobileClick}
            isToggledOn={controlsVisibleMobile}
          />
        )}
      </div>
    </div>
  );
}

export default inject("rootStore")(observer((NavbarBase)));
