import NotificationsPageStore from "@u4i/modules/NotificationsPage/state/NotificationsPageStore";
import { INotification } from "@u4i/parsers/NotificationParser";
import { IRootStore } from "@u4i/state/RootStore";
import { CheckOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Empty,
  Pagination,
  Row,
  Skeleton,
  Tabs,
  Typography,
} from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import dompurify from "dompurify";
import intlMessages from "./intlMessages";
import moment from "moment";

const { Title, Paragraph } = Typography;

interface IPropTypes {
  changePage: Function;
  fetching: boolean;
  fetchNotifications: Function;
  markAllAsSeen: Function;
  markingInProgress: boolean;
  markingInfoVisibility: boolean;
  notifications: Array<INotification>;
  page: number;
  rootStore: IRootStore;
  totalPages: number;
}

export const NotificationContent = inject("rootStore")(
  observer(
    class NotificationsPage extends Component<any> {
      private pageStore: NotificationsPageStore;

      constructor(props: IPropTypes) {
        super(props);

        this.pageStore = this.props.rootStore.notificationsPageStore;
      }

      componentDidMount() {
        this.pageStore.loadNotifications();
      }

      formatDate(date) {
        const parsedDate = moment(date, "YYYY-MM-DD HH:mm:ss");
        const secondsPastDate: number = moment().diff(parsedDate, "seconds");
        return moment.duration(secondsPastDate, "seconds").humanize();
      }

      render() {
        return (
        
          <div
            style={{
              width: 570,
              padding: 16,
            }}
          >
            <Row justify="space-between" align="middle" className="mb-16">
              <Title
                level={4}
                style={{
                  marginBottom: 0,
                }}
                className="notification-tab-header"
              >
                {" "}
                <FormattedMessage {...intlMessages.pageTitle} />
              </Title>

              <Button
                type="link"
                size="large"
                onClick={this.pageStore.markAllAsSeen}
                disabled={
                  this.pageStore.fetching ||
                  this.pageStore.markingInProgress ||
                  this.pageStore.markingInfoVisibility
                }
                icon={<CheckOutlined className="mr-8" />}
              >
                <FormattedMessage {...intlMessages.buttonText} />
              </Button>
            </Row>

            <Row>
              <Tabs
                defaultActiveKey="general"
                items={[
                  {
                    label: (
                      <Row
                      style={{
                        fontSize: 16,
                      }}
                      align="middle"
                    >
                      <Paragraph className="notification-tab-label">
                        <FormattedMessage {...intlMessages.generalText} />
                      </Paragraph>
                    </Row>
                    ),
                    key: 'general',
                    children: (
                      <>
                        {this.pageStore.fetching && (
                          <>
                            <Skeleton active avatar />
                            <Skeleton active avatar />
                            <Skeleton active avatar />
                          </>
                        )}

                        {this.pageStore.notifications.length > 0 && !this.pageStore.fetching && (
                          <>
                            {this.pageStore.notifications.map(
                              (notification, index) => (
                                <>
                                  {" "}
                                  <Badge.Ribbon
                                    text={
                                      <FormattedMessage
                                        {...intlMessages.newText}
                                      />
                                    }
                                    style={{
                                      display: !notification.isNew
                                        ? "none"
                                        : "block",
                                    }}
                                  >
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                      onClick={() => {
                                        notification.isNew &&
                                          this.pageStore.markNotificationAsSeen(
                                            notification.id
                                          );
                                      }}
                                    >
                                      <Row>
                                        <Col md={4}>
                                          <Avatar
                                            size={54}
                                            src={notification.senderAvatar}
                                          />
                                        </Col>
                                        <Col md={20}>
                                          <Typography.Title
                                            level={5}
                                            style={{
                                              marginBottom: 0,
                                            }}
                                            className="disable-invert"
                                          >
                                            {notification.title}
                                          </Typography.Title>
                                          <Typography.Text
                                            type="secondary"
                                            className="disable-invert"
                                          >
                                            {this.formatDate(notification.date)}
                                          </Typography.Text>
                                        </Col>
                                        <Col md={20} offset="4" className="pt-8">
                                          <div className="notification-description disable-invert">
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: dompurify.sanitize(
                                                  notification.content
                                                ),
                                              }}
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </a>
                                  </Badge.Ribbon>
                                  {this.pageStore.notifications.length - 1 !==
                                    index && (
                                    <Divider
                                      style={{
                                        margin: "13px 0px",
                                      }}
                                    />
                                  )}
                                </>
                              )
                            )}
                          </>
                        )}

                        {this.pageStore.notifications.length == 0 && !this.pageStore.fetching && (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <FormattedMessage
                                {...intlMessages.notification}
                              />
                            }
                            className="disable-invert"
                          />
                        )}
                      </>
                    )
                  }
                ]}
                tabBarExtraContent={
                  <>
                    {" "}
                    <Pagination
                      current={this.pageStore.page}
                      showLessItems={true}
                      total={this.pageStore.totalPages * 4}
                      pageSize={4}
                      className="disable-invert"
                      onChange={this.pageStore.changePage}
                      showSizeChanger={false}
                    />
                  </>
                }
              >
              </Tabs>
            </Row>
          </div>
        );
      }
    }
  )
);
