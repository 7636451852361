import {AxiosInstance} from 'axios';
import { IProvider, IProviderPost } from "@u4i/modules/Admin/modules/Providers/interfaces";
import { IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import qs from 'qs';
import { AdminProviderParser } from "@u4i/parsers/admin/AdminProviderParser";
import { AntDTableFilters } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';

export class AdminProvidersApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  // Add provider
  async createProvider(model: IProviderPost | any) : Promise<boolean>  {

    const formData = new FormData();
    formData.append('name', model.name);

    if(model.logo_image != undefined ) {
       formData.append('url', model.logo_image[0].originFileObj as Blob);
    }

    const response = await this.apiClient.post(`/admin/providers`, formData);
    return response.data;
  }

  // Get provider by id
  async fetchProviderById(id: string): Promise<IProvider> {
    const response = await this.apiClient.get(`/admin/providers/${id}`);

    return response.data;
  }

  // Update provider
  async updateProvider(id: string, model: IProviderPost | any) : Promise<boolean> {
    
    const formData = new FormData();
    formData.append('name', model.name);

    if(model.logo_image != undefined && model.logo_image.length > 0) {
       formData.append('url', model.logo_image[0].originFileObj as Blob);
    }

    const response = await this.apiClient.post(`/admin/providers/${id}`, formData);
    return response.data;
  }

   // Remove provider image
  async removeProviderImageLogo(providerId: string): Promise<boolean> {
    const response = await this.apiClient.delete(`/admin/providers/${providerId}/delete-logo`);
    return response.data;
  }

  
  // Returns providers list
  async fetchProviderData(config: AntDTableFilters<IProvider>): Promise<IPaginatedItems<IProvider>> {
    const response = await this.apiClient.get("/admin/providers", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.orderBy,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });

    const items = AdminProviderParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items, totalItems };
  }
}
