import { IProgram } from "@u4i/modules/Admin/modules/Programs/interfaces";
import Parser from "@u4i/utils/Parser";
import { AdminProgramPhasesParser } from "./AdminProgramPhasesParser";

export const AdminProgramParser = new Parser<IProgram>(p => ({
  backgroundImage: p.background_image,
  createdAt: p.created_at,
  description: p.description,
  id: p.id,
  internalDescription: p.internal_description,
  lastViewedPhaseId: p.lastViewedPhaseId,
  phases: AdminProgramPhasesParser.parseArray(p.phases),
  status: p.status,
  title: p.title,
  updatedAt: p.updated_at,
}));
