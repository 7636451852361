import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-calendar';
import { Input } from 'antd';
import { inject } from 'mobx-react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootStore } from '@u4i/state/RootStore';
import intlMessages from './intlMessages';
import './_picker.scss';

interface IProps extends WrappedComponentProps {
  rootStore?: IRootStore
  value: { startDate: string | null; endDate: string | null; }
  onValueChange: (values: { startDate?: string; endDate?: string; }) => void;
}

export const Picker: FC<IProps | any> = inject('rootStore')(injectIntl((props: IProps | any) => {
  const formattedStartDate: string = moment(props.value.startDate).format('DD-MMM-YYYY');
  const formattedEndDate: string = moment(props.value.endDate).format('DD-MMM-YYYY');

  const [startDate, setStartDate] = useState<any>(formattedStartDate && formattedStartDate !== 'Invalid date' ? formattedStartDate : null);
  const [endDate, setEndDate] = useState<any>(formattedEndDate && formattedEndDate !== 'Invalid date' ? formattedEndDate : null);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const {languageStore} = props.rootStore!;
  const {formatMessage} = props.intl;

  const selectRangeLabel: string = formatMessage(intlMessages.selectRangeLabel);

  const onChange = (dates: any) => {
    let startDate: any = null;
    let endDate: any = null;

    if(dates) {
      const date = moment(dates[0]);
      startDate = date.format('DD-MMM-YYYY');
      setStartDate(startDate);
    }
    if(dates) {
      const date = moment(dates[1]);
      endDate = date.format('DD-MMM-YYYY');
      setEndDate(endDate);
    }

    props.onValueChange({endDate: endDate, startDate: startDate});
    setTimeout(() => {
      setShowCalendar(false);
    }, 22);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const calendarRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const handleChange = (e: any) => {
    if(e.target.value == "") {
      setStartDate(null);
      setEndDate(null);
      props.onValueChange({endDate: null, startDate: null});
    }
  }

  return (
    <div className="data-table__date-range-filter-picker">
      <Input 
        value={`${startDate && endDate ? startDate + "   -   " + endDate : selectRangeLabel}`}
        onClick={toggleCalendar}
        onChange={handleChange}
        allowClear
      />
      {showCalendar && (
        <div ref={calendarRef} style={{minWidth: 290, position: 'absolute'}}>
          <Calendar
            selectRange={true}
            onChange={onChange}
            locale={languageStore.currentLocale}
          />
        </div>
      )}
    </div>
  );
}))
