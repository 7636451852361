import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import LanguageFlag from "@u4i/common/LanguageFlag/LanguageFlag";
import cx from "classnames";
import React from "react";
import "./_attempt-language-label.scss";

interface IPropTypes {
  language: LanguageEnum;
}

const labelMap: Map<LanguageEnum, React.ReactNode> = new Map([
  [LanguageEnum.DE_DE, <LanguageFlag customStyling={true} language={LanguageEnum.DE_DE} title="DE"/>],
  [LanguageEnum.EN_US, <LanguageFlag customStyling={true} language={LanguageEnum.EN_US} title="EN"/>],
  [LanguageEnum.CS_CZ, <LanguageFlag customStyling={true} language={LanguageEnum.CS_CZ} title="CZ"/>],
  [LanguageEnum.KO_KR, <LanguageFlag customStyling={true} language={LanguageEnum.KO_KR} title="KR"/>],
  [LanguageEnum.JA_JP, <LanguageFlag customStyling={true} language={LanguageEnum.JA_JP} title="JP"/>],
  [LanguageEnum.TH_TH, <LanguageFlag customStyling={true} language={LanguageEnum.TH_TH} title="TH"/>],
  [LanguageEnum.ZH_CN, <LanguageFlag customStyling={true} language={LanguageEnum.ZH_CN} title="CN"/>],
]);

const AttemptLanguageLabel: React.FC<IPropTypes> = (props) => {
  return (
    <div
      className={cx({
        "customer-grading__attempt-language-label": true
      })}
    >
      {labelMap.get(props.language)}
    </div>
  );
};

export default AttemptLanguageLabel;
