import {defineMessages} from 'react-intl';

export default defineMessages({
  retake: {
    defaultMessage: 'Retake',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.Retake',
  },
  start: {
    defaultMessage: 'Start',
    id: 'CourseDetailPage.ChaptersSection.ChaptersList.ChapterCard.StartButton.Start',
  },
  buttonLabel: {
    defaultMessage: 'Resume watching',
    id: 'CourseDetailPage.RecentChapter.ButtonLabel',
  },
  completed: {
    defaultMessage: 'Completed',
    id: 'Modals.CurriculumSkill.StatusLabel.Completed',
  },
});
