import Parser from '@u4i/utils/Parser';

export interface IScormProgress {
  maxScore: number;
  minScore: number;
  rawScore: number;
}

export const ScormProgressParser = new Parser<IScormProgress>(scormProgress => ({
  maxScore: Object.keys(scormProgress).length,
  minScore: 0,
  rawScore: Object.values(scormProgress).reduce((accumulator, value) => accumulator + (value ? 1 : 0), 0),
}));
