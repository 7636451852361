import {defineMessages} from 'react-intl';

export default defineMessages({
  anonymousPosting: {
    defaultMessage: 'Anonymous',
    id: 'Forms.DiscussAnswer.Anonymity.AnonymousPosting',
  },
  answerErrorsMinLength: {
    defaultMessage: 'Minimum {limit} characters',
    id: 'Forms.DiscussAnswer.Answer.Errors.MinLength',
  },
  answerErrorsPresence: {
    defaultMessage: 'Answer cannot be blank',
    id: 'Forms.DiscussAnswer.Answer.Errors.Presence',
  },
  answerPlaceholder: {
    defaultMessage: 'Your answer',
    id: 'Forms.DiscussAnswer.Answer.AnswerPlaceholder',
  },
  buttonLabelOff: {
    defaultMessage: 'Off',
    id: 'FormSection.DiscussAnswer.Anonymity.ButtonLabelOff',
  },
  buttonLabelOn: {
    defaultMessage: 'On',
    id: 'FormSection.DiscussAnswer.Anonymity.ButtonLabelOn',
  },
  overlayButtonLabel: {
    defaultMessage: 'Submit another answer',
    id: 'Forms.DiscussAnswer.OverlayButtonLabel',
  },
  overlayHeading: {
    defaultMessage: 'Thank you for submitting your answer',
    id: 'Forms.DiscussAnswer.OverlayHeading',
  },
  overlayMessage: {
    defaultMessage: 'Your answer will be reviewed and you will receive feedback on the status'
      + ' usually within 1 workday.',
    id: 'Forms.DiscussAnswer.OverlayMessage',
  },
  submitTitle: {
    defaultMessage: 'Submit',
    id: 'Forms.DiscussAnswer.SubmitTitle',
  },
});
