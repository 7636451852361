import {defineMessages} from 'react-intl';

export default defineMessages({
  description: {
    defaultMessage: 'When you have successfully completed this course, you will receive a'
      + ' Proof of Qualification. University4Industry measures your performance in this course'
      + ' not by your “consumption” of videos and text, but instead by assessing your'
      + ' newly acquired knowledge and competencies in various'
      + ' challenges (e.g., multiple choice tests, case examples).',
    id: 'CourseDetailPage.CertificateSection.Description',
  },
  heading: {
    defaultMessage: 'Proof of Qualification',
    id: 'CourseDetailPage.CertificateSection.Heading',
  },
});
