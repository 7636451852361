import {defineMessages} from 'react-intl';

export default defineMessages({
  pagesDisclaimer: {
    defaultMessage: 'Disclaimer',
    id: 'Footer.FooterInfoLinks.Pages.Disclaimer',
  },
  pagesImpressum: {
    defaultMessage: 'Impressum',
    id: 'Footer.FooterInfoLinks.Pages.Impressum',
  },
  pagesPrivacy: {
    defaultMessage: 'Privacy Policy',
    id: 'Footer.FooterInfoLinks.Pages.Privacy',
  },
});
