import {defineMessages} from 'react-intl';

export default defineMessages({
  hello: {
    defaultMessage: "Hello! My name is Karl and I'm the AI tutor from University4Industry. How can I assist you today?",
    id: 'ChatBot.Hello'
  },
  title: {
    defaultMessage: "Chat Support",
    id: 'ChatBot.Title'
  },
  placeholder: {
    defaultMessage: "Type your message here...",
    id: 'ChatBot.Placeholder'
  },
  liked: {
    defaultMessage: "Please could you give a little more information about why you liked this answer?",
    id: 'ChatBot.Liked'
  },
  disliked: {
    defaultMessage: "Please could you give a little more information about why you disliked this answer?",
    id: 'ChatBot.DisLiked'
  }
});
