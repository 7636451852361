import React, { useState, useEffect, useRef, FC } from "react";
import { Layout, Input, Button, Typography, Space } from "antd";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";

import "./ChatBotQuiz.css";
import {EXTERNAL_CHATBOT_BASE_URL} from "@u4i/constantSettings";

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

interface ChatMessageBody {topic:string,type:string}

type ChatMessage = {
  chatbotData: ChatMessageBody;
};

const ChatBotQuiz: FC<ChatMessage> = ({ chatbotData }) => {
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([
    { sender: "bot", text: "Fetching a question..." },
  ]);
  const [questionData, setQuestionData] = useState({
    chatbotQuestion: "",
    topic: "",
  });
  const [loading, setLoading] = useState(false);
  const [displayedText, setDisplayedText] = useState("");
  const textIndex = useRef(0);
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchQuestions = async (data: ChatMessageBody) => {
      const fetchMessage = "Fetching question...";
      setChat([{ sender: "bot", text: fetchMessage }]);
      startTextAnimation(fetchMessage);
      try {
        const topic = data.topic != undefined
          ? data.topic : "Machine Learning";
        const type = data.type != undefined
          ? data.type : "Open Question";
        const response = await axios.get(
          EXTERNAL_CHATBOT_BASE_URL+`/invokequiz?topic=${topic}&quiz_type=${type}`
        );
        const question = response.data;
        setQuestionData({ chatbotQuestion: question, topic: data.topic });
        setChat([{ sender: "bot", text: question }]);
        startTextAnimation(question);
      } catch (error) {
        console.error("Error fetching questions:", error);
        const errorMsg = "Error fetching question. Please try again later.";
        setChat([{ sender: "bot", text: errorMsg }]);
        startTextAnimation(errorMsg);
      }
    };
    if (chatbotData && !hasFetched.current) {
      fetchQuestions(chatbotData);
      hasFetched.current = true;
    }
  }, [chatbotData]);

  const startTextAnimation = (text: string) => {
    if (text && text.length > 0) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      textIndex.current = 0;
      setDisplayedText("");

      intervalId.current = setInterval(() => {
        setDisplayedText((prev) => prev + text[textIndex.current]);
        textIndex.current++;
        if (textIndex.current >= text.length) {
          clearInterval(intervalId.current!);
        }
      }, 20); // Adjust the interval for faster animation
    }
  };

  const handleSend = async () => {
    if (message.trim()) {
      const submitMessage = "Submitting answer...";
      setChat([{ sender: "bot", text: submitMessage }]);
      startTextAnimation(submitMessage);
      setLoading(true);
      const topic = questionData.topic != undefined
        ? questionData.topic : "Machine Learning";
      try {
        const response = await axios.post(
          EXTERNAL_CHATBOT_BASE_URL+"/answerquiz",
          {
            answer: message,
            question: questionData.chatbotQuestion,
            topic: topic,
            student_id: "student_1",
            user_group: "ug1",
          }
        );
        const botResponse = response.data || "Your answer has been recorded.";
        setChat([{ sender: "bot", text: botResponse }]);
        startTextAnimation(botResponse);
      } catch (error) {
        console.log("error", error);
        const errorMsg =
          "Failed to submit your answer. Please try again later.";
        setChat([{ sender: "bot", text: errorMsg }]);
        startTextAnimation(errorMsg);
      }

      setMessage("");
      setLoading(false);
    }
  };

  const formatText = (input: string) => {
    let formatted = input.replace(/^\n+/, ""); // Remove leading newline characters
    formatted = formatted.replace(/\\n/g, "\n");

    const paragraphs = formatted.split("\n\n").map((paragraph, pIndex) => (
      <p key={pIndex}>
        {paragraph.split("\n").map((line, lIndex) => {
          const boldMatches = line.match(/\*\*(.*?)\*\*/g);
          if (boldMatches) {
            return (
              <React.Fragment key={lIndex}>
                {line
                  .split(/\*\*(.*?)\*\*/)
                  .map((segment, sIndex) =>
                    boldMatches.includes(`**${segment}**`) ? (
                      <b key={sIndex}>{segment}</b>
                    ) : (
                      segment
                    )
                  )}
                <br />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={lIndex}>
              {line}
              <br />
            </React.Fragment>
          );
        })}
      </p>
    ));

    return paragraphs;
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Content
        style={{
          padding: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space
          direction="vertical"
          style={{ width: "100%", maxWidth: "600px" }}
        >
          {chat.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.sender}`}>
              <Text>
                {msg.sender === "bot" && index === chat.length - 1
                  ? formatText(displayedText)
                  : formatText(msg.text)}
              </Text>
            </div>
          ))}
        </Space>
      </Content>

      <div
        style={{
          width: "60%",
          display: "flex",
          margin: "0 auto",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "180px",
        }}
      >
        <TextArea
          rows={1}
          value={message}
          style={{ width: "100%" }}
          onChange={(e) => setMessage(e.target.value)}
          className="custom-textarea"
          placeholder="Type your message here..."
          autoSize={{ minRows: 1, maxRows: 6 }}
        />
        <Button
          size="large"
          type="primary"
          onClick={handleSend}
          shape="circle"
          icon={<SendOutlined />}
          disabled={loading}
        />
      </div>
    </Layout>
  );
};

export default ChatBotQuiz;
