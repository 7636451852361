import cx from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TitleBackground from "./components/TitleBackground";
import TitleHeading from "./components/TitleHeading";
import "./_title-element.scss";
import ImageProcessor from "@u4i/common/ImageProcessor";
import { PlayerContext } from "@u4i/modules/VirtualExperience/PlayerState/playerContext";
import { withCalculatedFontSize } from "@u4i/modules/VirtualExperience/components/withCalculatedFontSize";

class TitleElement extends Component<any> {

  static contextType = PlayerContext;

  constructor(props) {
    super(props);
  }

  state = {
    backgroundBrightness: null,
    backgroundImage: null,
  };

  componentDidMount() {
    this.setupNewBackgroundImage();
  }


  setupNewBackgroundImage = async () => {
    const imageUrl = `${this.context.activeChapterData?.preview?.url}?x-cors-fix=s3`;
    const image = await ImageProcessor.loadImageFromUrl(imageUrl);

    const processor = new ImageProcessor(image);
    const brightness = await processor.getAveragePerceivedImageBrightness();

    this.setState({
      backgroundBrightness: brightness,
      backgroundImage: processor.getImageURL(),
    });
  };

  render() {
    const { backgroundBrightness } = this.state;

    return (
      <div
        className={cx({
          "player__title-element": true,
          "player__title-element--active": true,
        })}
        style={{ fontSize: this.props.calculatedFontSize }}
      >
        <TitleBackground backgroundImage={this.state.backgroundImage} />

        <TitleHeading
          isVisible={backgroundBrightness !== null}
          title={this.context.activeChapterData.title}
        />
      </div>
    );
  }
}

export default withCalculatedFontSize(TitleElement);
