interface AnyObject {
  [key: string]: any;
}

class Parser<TargetInterface> {
  parsingFunction: (data: AnyObject) => TargetInterface;

  constructor(parsingFunction: (data: AnyObject) => TargetInterface) {
    this.parsingFunction = parsingFunction;
  }

  parse(item: AnyObject): TargetInterface {
    return this.parsingFunction(item);
  }

  parseArray(itemsArray: Array<AnyObject>): Array<TargetInterface> {
    return itemsArray.map(item => this.parsingFunction(item));
  }
}

export default Parser;
