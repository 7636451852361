import moment from "moment";
import React, { Fragment } from "react";
import {injectIntl, WrappedComponentProps} from 'react-intl';

interface IPropTypes extends WrappedComponentProps{
  longMonth?: boolean;
  onlyDate?: boolean;
  showSeconds?: boolean;
  source: string;
  userOverview?: boolean;
}

const defaultProps: Partial<IPropTypes> = {
  longMonth: false,
  onlyDate: false,
  showSeconds: false,
  userOverview: false,
};

const FormatDate: React.FC<IPropTypes> = (props) => {
  const onlyDate: string = 'D MMM YYYY';
  const longMonth: string = "D MMMM YYYY, HH:mm";
  const longMonthWithSeconds: string = "D MMMM YYYY, HH:mm";
  const shortMonth: string = "D MMM YYYY, HH:mm";
  const shortMonthWithSeconds: string = "D MMM YYYY, HH:mm";
  const sourceFormat: string = "YYYY-MM-DD HH:mm";

  const targetFormat = (): string => {
    if (props.onlyDate) {
      return onlyDate;
    } else if (!props.longMonth && !props.showSeconds) {
      return shortMonth;
    } else if (props.longMonth && !props.showSeconds) {
      return longMonth;
    } else if (!props.longMonth && props.showSeconds) {
      return shortMonthWithSeconds;
    } else if (props.longMonth && props.showSeconds) {
      return longMonthWithSeconds;
    } else {
      return shortMonth;
    }
  };

  const formattedDate = () => {
    let formattedDate: string = moment(props.source, sourceFormat)
      .format(targetFormat());

    if (formattedDate === "Invalid date" && props.userOverview) {
      return "Not logged in yet";
    } else {
      return formattedDate;
    }
  };

  return (
    <Fragment>
      {formattedDate()}
    </Fragment>
  );};

FormatDate.defaultProps = defaultProps;

export default injectIntl(FormatDate);

