import React, {Component} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import url from 'url';
import {FormattedMessage} from 'react-intl';
import {inject} from 'mobx-react';
import CircularProgress from '@u4i/common/CircularProgress';
import intlMessages from './intlMessages';
import AppNavigator, { appRoutes } from '@u4i/common/AppNavigator';
import './_instant-authorization.scss';

const InstantAuthorization = inject('rootStore')(class InstantAuthorization extends Component<any> {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        loginToken: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.performInstantAuthorization();
  }

  performInstantAuthorization = async () => {
    const parsedQuery:any = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

    try {
      await this.props.rootStore.userStore.instantLogin(this.props.match.params.loginToken);
    
      if (parsedQuery.redirectPathname) {
        const parsedExitUrl = url.parse(parsedQuery.redirectPathname);

        if (parsedExitUrl.protocol) {
          window.location.replace(parsedExitUrl.href);
        } else {
          AppNavigator.directReplace(parsedExitUrl.path);
        }
      } else {
        AppNavigator.replace(appRoutes.home);
      }
    } catch (error) {
      AppNavigator.replace(appRoutes.home);
    }
  };

  render() {
    return (
      <div className="container">
        <h2 className="instant-authorization__heading">
          <FormattedMessage {...intlMessages.heading} />
        </h2>
        <CircularProgress />
      </div>
    );
  }
});

export default InstantAuthorization;
