import React, { FC } from "react";
import { ISkillBasic } from "@u4i/parsers/SkillParserBasic";
import { IColumnRepresentation } from "../../columnSizingAlgorithm";
import { CURRICULUM_ROW_LABEL_COLUMN_WIDTH } from "@u4i/constantSettings";
import { SkillList } from "../SkillList";
import { ICurriculum } from "@u4i/common/Interfaces/CurriculumInterface";
import "./_curriculum-desktop.scss";

interface IPropTypes {
  columnRepresentations: Array<IColumnRepresentation>;
  curriculumData: ICurriculum;
  leftoverWidth: number;
  onSkillCardClick: (skill: ISkillBasic) => void;
}

export const CurriculumDesktop: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <table className="landing-page__curriculum__table">
      <thead>
        <tr>
          <th style={{ width: CURRICULUM_ROW_LABEL_COLUMN_WIDTH }} />

          {props.curriculumData.columns.map((columnLabel, columnIndex) => (
            <th
              className="landing-page__curriculum__column-label"
              key={columnIndex}
              style={{
                width: `${props.columnRepresentations[columnIndex].width}`,
              }}
            >
              <div className="landing-page__curriculum__column-label-inner">
                {columnLabel}
              </div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {props.curriculumData.rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className="landing-page__curriculum__row-label">
              <div className="landing-page__curriculum__row-label_box">{row.label}</div>
            </td>

            {row.items.map((columnItems, columnIndex) => (
              <td key={columnIndex}>
                <SkillList
                  items={columnItems}
                  onSkillCardClick={props.onSkillCardClick}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
