import Parser from '@u4i/utils/Parser';

export interface IPartnerInfo {
  beforeActionText: string;
  buttonAction: string;
  buttonText: string;
  description: string;
  title: string;
}

export const PartnerInfoParser = new Parser<IPartnerInfo>(partnerInfo => ({
  beforeActionText: partnerInfo.beforeActionText,
  buttonAction: partnerInfo.buttonAction,
  buttonText: partnerInfo.buttonText,
  description: partnerInfo.description,
  title: partnerInfo.title,
}));
