import cx from 'classnames';
import React from 'react';
import {observer} from 'mobx-react';
import DiscussCommentEntity from '@u4i/state/entities/DiscussCommentEntity';
import {DiscussCommentStore} from './state/DiscussCommentStore';
import { CommentEditableContent } from './components/CommentEditableContent/CommentEditableContent';
import './_discuss-comment.scss';
import { CommentHeader } from './components/CommentHeader/CommentHeader';

interface IPropTypes {
  entity: DiscussCommentEntity;
  formId: string;
  isAvatarMinimized?: boolean;
  showAnonymitySettingsSwitch: boolean;
}

export const DiscussComment = observer(class DiscussComment extends React.Component<IPropTypes> {
  uiStore: DiscussCommentStore;

  constructor(props: IPropTypes) {
    super(props);

    this.uiStore = new DiscussCommentStore(this.props.entity);
  }

  render() {
    return (
      <div className="discuss__discuss-comment">
        <div
          className={cx({
            'discuss__discuss-comment__avatar': true,
            'discuss__discuss-comment__avatar--small': this.props.isAvatarMinimized,
          })}
        >
          <img
            alt="User avatar"
            className="discuss__discuss-comment__avatar-image"
            src={this.props.entity.user.profileImage}
          />
        </div>

        <div className="discuss__discuss-comment__main-content">
          <CommentHeader
            anonymously={this.props.entity.anonymously}
            authorFirstname={this.props.entity.user.firstName}
            authorLastname={this.props.entity.user.lastName}
            creationDate={this.props.entity.createdAt}
          />
    
          <CommentEditableContent
            anonymity={this.props.entity.anonymously}
            content={this.props.entity.content}
            editModeEnabled={this.uiStore.editionModeEnabled}
            formId={this.props.formId}
            isLiked={this.props.entity.isLiked}
            likes={this.props.entity.likeCount}
            onEditionCommit={this.uiStore.commitEdition}
            onEditClick={this.uiStore.toggleEditionMode}
            onEditionCancel={this.uiStore.toggleEditionMode}
            onLikesClick={this.uiStore.toggleLike}
            showAnonymitySettingsSwitch={this.props.showAnonymitySettingsSwitch}
            showEditAction={this.props.entity.isOwn}
            togglingLike={this.uiStore.togglingLike}
            updatingContent={this.uiStore.updatingContent}
            updatingContentError={this.uiStore.updatingContentError}
          />
        </div>
      </div>
    );
  }
});
