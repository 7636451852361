import React, { FC } from "react";
import { Modal, Button } from "antd";

interface IProps {
  isBookingLoader: boolean
  isDeleteConfirmationVisible: boolean
  deleteSlot: () => void
  hideBookingConformationModal: () => void
}

// ToDO Important:- This component need to be refactor to make a re-usable modal component

export const DeleteConfirmation: FC<IProps> = (props: IProps) => {
  const {
    isBookingLoader,
    isDeleteConfirmationVisible,
    deleteSlot,
    hideBookingConformationModal
  } = props;

  return (
    <Modal
      footer={[
        <Button key="cancel" onClick={hideBookingConformationModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" danger loading={isBookingLoader} onClick={deleteSlot}>
          Delete Slot
        </Button>
      ]}
      onCancel={hideBookingConformationModal}
      open={isDeleteConfirmationVisible}
      title="Delete"
    >
      Are you sure you want to delete slot?
    </Modal>
  );
};
