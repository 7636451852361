import { Card, Col, notification, Row } from "antd";
import React from "react";
import ChapterInfo from "./ChapterInfo";
import moment from "moment";
import ChapterThumbnail from "./ChapterThumbnail";
import ChapterButtons from "./ChapterButtons";
import intlMessages from "./ChapterInfo/intlMessages";
import { injectIntl } from "react-intl";
import cx from "classnames";
import { CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { mainBlue } from "@u4i/styles/styles-variables";
import "./_index.scss";

const ChapterCard = ({
  data,
  isSkillBought,
  onStartClick,
  skillHasChallenges,
  isDisabled,
  hasMandatoryChapters,
  intl,
  showButtonResume,
  className,
}: any) => {
  const {
    challenges_completed,
    challenges_submitted,
    description,
    has_challenges,
    has_mandatory_challenges,
    pdf_summary_url,
    preview,
    providers,
    show_download_pdf_button,
    title,
    viewed,
    viewedStatus,
    duration,
  } = data;

  const { formatMessage } = intl;

  const roundedDurationInMinutes: number = Math.round(
    moment.duration(duration, "seconds").asMinutes()
  );

  const handleStartClick = () => {
    onStartClick(data.id);
  };

  const warning = formatMessage(intlMessages.warningMessage);

  const showNotification = () => {
    notification.info({
      message: warning,
      placement: "topRight",
      closeIcon: ( <CloseCircleFilled  style={{ color: 'white', fontSize: 24 }}/> ),
      duration: 4,
      key: "chapter_not",
      style: { width: 600, background: mainBlue, marginTop: 50 },
      className: 'welcome_back_popup',
      icon: ( <InfoCircleFilled style={{ color: 'white' }}/> )
    });
  };

  const skillViewed = () => {
    if (has_mandatory_challenges) return challenges_completed;

    if(!hasMandatoryChapters) return viewed;
    return viewedStatus;
  };
  
  return (
    <Card
      style={{
        minHeight: 120,
      }}
      className={cx({
        "common-card-wrapper chapter-card": true,
        "chapter-disable": isDisabled,
        [className]: true,
      })}
    >
      <Row>
        <Col md={5}>
          <ChapterThumbnail
            url={preview.url}
            challengesCompleted={challenges_completed}
            isSkillBought={isSkillBought}
            hasChallenges={has_challenges}
            viewed={skillViewed()}
          />
        </Col>
        <Col md={13} className="pl-16 pr-16">
          <ChapterInfo
            challengesCompleted={challenges_completed}
            challengesSubmitted={challenges_submitted}
            hasChallenges={has_challenges}
            hasMandatoryChallenges={has_mandatory_challenges}
            isSkillBought={isSkillBought}
            skillHasChallenges={skillHasChallenges}
            title={title}
            viewed={skillViewed()}
            durationInMinutes={roundedDurationInMinutes}
            providers={providers}
            description={description}
            showButtonResume={showButtonResume}
          />
        </Col>
        {isSkillBought && (
          <Col md={6}>
            <Row
              justify="center"
              align="middle"
              style={{
                height: "100%",
              }}
            >
              <ChapterButtons
                chapterTitle={title}
                pdfDownloadUrl={pdf_summary_url!}
                challengesCompleted={challenges_completed}
                showDownloadPdfButton={show_download_pdf_button}
                viewed={skillViewed()}
                showButtonResume={showButtonResume}
                onClick={() => {
                  if (isDisabled) return showNotification();

                  return handleStartClick();
                }}
              />
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default injectIntl(ChapterCard);
