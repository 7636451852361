import {ILogger, ILoggerFactory} from '../ServicesInterfaces';
import { guardsmanRed, mainBlue, orange, white } from '@u4i/styles/styles-variables';

const enum LoggingMode {
  DEV = 'DEV',
  PROD = 'PROD',
}

const enum ConsoleMethod {
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warn',
}

class Logger implements ILogger {
  private forceLogging: boolean;
  private loggingMode: LoggingMode;
  private moduleName: string;

  constructor(loggingMode: LoggingMode, moduleName: string, forceLogging = false) {
    this.forceLogging = forceLogging;
    this.loggingMode = loggingMode;
    this.moduleName = moduleName;
  }

  error(error: Error) {
    this.invokeConsoleLog(ConsoleMethod.ERROR, '', guardsmanRed, false, error);
  }

  info(message: string) {
    this.invokeConsoleLog(ConsoleMethod.INFO, message, mainBlue);
  }

  private invokeConsoleLog(
    method: ConsoleMethod,
    message: string,
    colorCode: string,
    devOnly: boolean = true,
    error?: Error,
  ) {
    if (!this.forceLogging && devOnly && this.loggingMode === LoggingMode.PROD) { return; }

    const consoleArguments: Array<string | null | Error> = [
      `%c[${this.moduleName}]%c >>${message ? ` ${message}` : ''}`,
      `background: ${colorCode}; border-radius: 2px; color: ${white}; padding: 2px 4px;`,
      null,
    ];

    if (method === ConsoleMethod.ERROR && error) {
      consoleArguments.push(error);
    }

    console[method](...consoleArguments);
  }

  warn(message: string) {
    this.invokeConsoleLog(ConsoleMethod.INFO, message, orange);
  }
}

class LoggerFactory implements ILoggerFactory {
  private loggingMode: LoggingMode;

  constructor() {
    switch (process.env.NODE_ENV) {
      case 'development':
        this.loggingMode = LoggingMode.DEV;
        break;

      case 'production':
        this.loggingMode = LoggingMode.PROD;
        break;

      default:
        throw new Error('Invalid NODE_ENV');
    }
  }

  createLogger(moduleName: string, forceLogging?: boolean): ILogger {
    return new Logger(this.loggingMode, moduleName, forceLogging);
  }
}

export default LoggerFactory;
