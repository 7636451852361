import {AxiosInstance} from 'axios';
import qs from 'qs';

import {DigitalLabParser, IDigitalLab, DigitalLabUserBookingParser, Labs, UserLabSlots} from '@u4i/parsers/DigitalLabParser';
import {CalendarEvent, ILabs} from '@u4i/modules/DigitalLab/components/Booking/interface';

export class DigitalLabApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async expireSession(labId: string, challengeAttemptId: string, submitChallengeAttempt: boolean): Promise<void> {
    await this.apiClient.post(`/v2/digital-labs/${labId}/expire-session`, {
      challenge_attempt_id: challengeAttemptId,
      submit_challenge_attempt: submitChallengeAttempt,
    });
  }

  async fetchStreamingUrl(labId: string): Promise<IDigitalLab> {
    const response = await this.apiClient.get(`/v2/digital-labs/${labId}`);

    return DigitalLabParser.parse(response.data);
  }

  async getAllLabsList(courseId: string): Promise<any[]> {
    const url: string = courseId ? `/v2/digital-labs/scheduler/list?courseId=${courseId}` : `/v2/digital-labs/scheduler/list`
    const response = await this.apiClient.get(url);
    return Labs.parseArray(response.data);
  }

  async fetchLabBookingsById(labId: string, filter: {from: string, to: string}): Promise<CalendarEvent[]> {
    const params = {};
    if(filter && filter.from !== '' && filter.to !== ''){
      params['filters[from]'] = filter.from;
      params['filters[to]'] = filter.to;
    }

    const response = await this.apiClient.get(`/v2/digital-labs/${labId}/users/time-slots`, {
      params: params,
      paramsSerializer: params => qs.stringify(params)
    });
    return DigitalLabUserBookingParser.parseArray(response.data);
  }

  fetchLabBookingsIdenticalFleetStack = async (labId: string):  Promise<CalendarEvent[]> => {
    const response = await this.apiClient.get(`/v2/digital-labs/${labId}/users/fleet-stack-time-slots`);

    return DigitalLabUserBookingParser.parseArray(response.data);
  }

  async bookLabSlot(labId: string, slotInfo): Promise<CalendarEvent> {
    const response = await this.apiClient.post(`/v2/digital-labs/${labId}/time-slots`, slotInfo);
    return DigitalLabUserBookingParser.parse(response.data);
  }

  async userLabBookedSlots(labId: string, userId: string): Promise<any[]> {
    const response = await this.apiClient.get(`/v2/digital-labs/${labId}/users/${userId}/time-slots`);
    return UserLabSlots.parseArray(response.data);
  }

  async getDigitalLabInfo(labId: string): Promise<ILabs> {
    const response = await this.apiClient.get(`/v2/digital-labs/${labId}/scheduler`);
    return Labs.parse(response.data);
  }

  async deleteBoolingSlot(slotId: string): Promise<boolean> {
    const response =  await this.apiClient.delete(`/v2/digital-labs/time-slots/${slotId}`);
    return response.data;
  }

  async getAllSlotsOfParticularUser(userId: string): Promise<any[]> {
    const response = await this.apiClient.get(`/v2/digital-labs/users/${userId}/time-slots`);
    return DigitalLabUserBookingParser.parseArray(response.data);
  }
}
