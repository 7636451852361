import React from 'react';

import {CompareMeTabIdEnum} from '../../CompareMeSection';
import {Tab} from './components/Tab';
import {TabButton} from './components/TabButton';

import './_tabs.scss';

interface IPropTypes {
  children: Array<React.ReactNode>;
  currentTabId: CompareMeTabIdEnum;
  onTabChange: (newTabId: CompareMeTabIdEnum) => void;
}

export class Tabs extends React.Component<IPropTypes> {
  static Tab = Tab;

  render() {
    return (
      <div>
        <ul className="list-unstyled landing-page__tabs__list">
          {this.props.children.map(tab => tab && (
            <li className="landing-page__tabs__list-item" key={(tab as any).props.id}>
              <TabButton
                isActive={this.props.currentTabId === (tab as any).props.id}
                onClick={this.props.onTabChange}
                tabId={(tab as any).props.id}
              >
                {(tab as any).props.title}
              </TabButton>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};
