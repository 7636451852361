import { AntDTableFilters } from "@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces";
import { IProgram, IProgramModulePost, IProgramPhase, IProgramPhasePost, IProgramPhasePut, IProgramPost, IProgramPut, IResource, ProgramStatusEnum } from "@u4i/modules/Admin/modules/Programs/interfaces";
import { AdminProgramParser } from "@u4i/parsers/admin/AdminProgramParser";
import { IPaginatedItems } from "@u4i/state/ServicesInterfaces";
import { AxiosInstance } from "axios";
import qs from 'qs';

export class AdminProgramsApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  // Returns programs list
  fetchProgramData = async (config: AntDTableFilters<IProgram>): Promise<IPaginatedItems<IProgram>> => {
    const response = await this.apiClient.get("/admin/programs", {
      params: {
        filters: config.filters,
        limit: config.limit,
        offset: config.offset,
        orderBy: config.orderBy,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });

    const items = AdminProgramParser.parseArray(response.data);
    const totalItems = parseInt(response.headers["x-total-count"], 10);

    return { items, totalItems };
  }

  // Add program
  createProgram = async (model: IProgramPost | any): Promise<boolean> => {
    let formData = new FormData();

    if(model.background_image != undefined) {
      formData.append('background_image', model.background_image[0].originFileObj as Blob);
    }

    formData.append('description', model.description ? model.description : '');
    formData.append('internal_description', model.internal_description ? model.internal_description : '');
    formData.append('title', model.title);
    formData.append('status', model.status);

    const response = await this.apiClient.post(`/admin/programs`, formData);

    return response.data;
  }

  // Delete program
  deleteProgram = async (id: string | any): Promise<boolean | any> => {
    const response = await this.apiClient.delete(`/admin/programs/${id}`);

    return response.data;
  }

  // Get program by id
  fetchProgramById = async (id: string): Promise<IProgram> => {
    const response = await this.apiClient.get(`/admin/programs/${id}`);

    return AdminProgramParser.parse(response.data);
  }

  // Update program
  updateProgram = async (id: string, model: IProgramPut | any): Promise<boolean> => {
    const response = await this.apiClient.put(`/admin/programs/${id}`, model);

    return response.data;
  }

  // Enroll user to program to keep track of user progress on a specific program
  enrollUserToProgram = async (programId: string): Promise<any> => {
    const response = await this.apiClient.post(`/user-programs/enroll`, {program_id: programId});

    return response.data;
  }

  // Set program background
  updateProgramBackground = async (programId: string, background: any): Promise<boolean> => {
    let formData = new FormData();
    formData.append('background_image',  background.originFileObj as Blob);
    const response = await this.apiClient.post(`/admin/programs/${programId}/change-background`, formData);

    return response.data;
  }

  // Remove program background
  removeProgramBackground = async (programId: string): Promise<boolean> => {
    const response = await this.apiClient.post(`/admin/programs/${programId}/remove-background`);

    return response.data;
  }

  // Set program status
  updateProgramStatus = async (programId: string, status: ProgramStatusEnum): Promise<boolean> => {
    const response = await this.apiClient.put(`/admin/programs/${programId}/set-status`, { status: status });

    return response.data;
  }

  // Add phase to program
  createPhase = async (programId: string, phase: IProgramPhasePost): Promise<IProgramPhase> => {
    const response = await this.apiClient.post(`/admin/programs/${programId}/phases`, phase);

    return response.data;
  }

  // Update phase
  updatePhase = async (phaseId: string, phase: IProgramPhasePut): Promise<IProgramPhase> => {
    const response = await this.apiClient.put(`/admin/phases/${phaseId}`, phase);

    return response.data;
  }

  // Delete phase
  deletePhase = async (phaseId: string): Promise<boolean> => {
    const response = await this.apiClient.delete(`/admin/phases/${phaseId}`);

    return response.data;
  }

  // Returns resource list
  getResourcesByType = async (resourceType: string): Promise<IResource[]> => {
    const response = await this.apiClient.get(`/admin/phase-modules/${resourceType}`);

    return response.data;
  }

  // Add module to phase
  addModuleToPhase = async (phaseId: string, module: IProgramModulePost): Promise<IProgramModulePost> => {
    const response = await this.apiClient.post(`/admin/phases/${phaseId}/phase-modules`, module);

    return response.data;
  }

  // Update module
  updateModule = async (phaseId: string, moduleId: string, module: IProgramModulePost): Promise<IProgramModulePost> => {
    const response = await this.apiClient.put(`/admin/phases/${phaseId}/phase-modules/${moduleId}`, module);

    return response.data;
  }

  // Delete module
  deleteModule = async (phaseId: string, moduleId: string): Promise<boolean> => {
    const response = await this.apiClient.delete(`/admin/phases/${phaseId}/phase-modules/${moduleId}`);

    return response.data;
  }

}
