import Parser from '@u4i/utils/Parser';

export interface IProgramUI {
  backgroundImage: string
  description: string
  id: string
  internalDescription: number
  lastViewedPhaseId: string
  title: string
}

export const ProgramUIParser = new Parser<IProgramUI>(program => ({
  backgroundImage: program.background_image,
  description: program.description,
  id: program.id,
  internalDescription: program.internal_description,
  lastViewedPhaseId: program.lastViewedPhaseId,
  title: program.title
}));
