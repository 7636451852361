import cx from 'classnames';
import React, { FC } from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import {AnimatedNumber} from '@u4i/common/AnimatedNumber';;
import {CompareMeTabIdEnum} from '../../CompareMeSection';

import intlMessages from './intlMessages';
import './_statistics-circles.scss';

interface IPropTypes {
  accentColor: string
  currentUser: number
  groupAverage: number
  groupTop: number
  tabId: CompareMeTabIdEnum
}

export const StatisticsCircles: FC<IPropTypes> = (props: IPropTypes) => {
  let pickedSuffix: MessageDescriptor;

  switch (props.tabId) {
    case CompareMeTabIdEnum.CHALLENGES:
      pickedSuffix = intlMessages.challengesSuffix;
      break;
    
    default:
    case CompareMeTabIdEnum.SKILLS:
      pickedSuffix = intlMessages.skillsSuffix;
      break;
  }

  return (
    <div className="landing-page__statistics-circles">
      <p
        className={cx({
          'landing-page__statistics-circles__circle': true,
          'landing-page__statistics-circles__circle--top': true,
        })}
        style={{borderColor: props.accentColor}}
      >
        <strong
          className={cx({
            'landing-page__statistics-circles__emphasis': true,
            'landing-page__statistics-circles__current-count': true,
          })}
          style={{color: props.accentColor}}
        >
          <AnimatedNumber durationMilliseconds={500} targetValue={props.currentUser} />
        </strong>
  
        <strong className="landing-page__statistics-circles__emphasis">
          <FormattedMessage {...pickedSuffix} values={{count: props.currentUser}} />
        </strong>
      </p>
  
      <p
        className={cx({
          'landing-page__statistics-circles__circle': true,
          'landing-page__statistics-circles__circle--left': true,
        })}
      >
        <span className="landing-page__statistics-circles__text">
          <FormattedMessage {...intlMessages.average} />
          :
        </span>
  
        <strong
          className="landing-page__statistics-circles__emphasis"
          style={{color: props.accentColor}}
        >
          <AnimatedNumber durationMilliseconds={500} targetValue={props.groupAverage} />
        </strong>
        
        <span className="landing-page__statistics-circles__text">
          <FormattedMessage {...pickedSuffix} values={{count: props.groupAverage}} />
        </span>
      </p>
  
      <p
        className={cx({
          'landing-page__statistics-circles__circle': true,
          'landing-page__statistics-circles__circle--right': true,
        })}
      >
        <span className="landing-page__statistics-circles__text">
          <FormattedMessage {...intlMessages.top} />
          :
        </span>
  
        <strong
          className="landing-page__statistics-circles__emphasis"
          style={{color: props.accentColor}}
        >
          <AnimatedNumber durationMilliseconds={500} targetValue={props.groupTop} />
        </strong>
  
        <span className="landing-page__statistics-circles__text">
          <FormattedMessage {...pickedSuffix} values={{count: props.groupTop}} />
        </span>
      </p>
    </div>
  );
};
