import {defineMessages} from 'react-intl';

export default defineMessages({
  comments: {
    defaultMessage: 'Comments',
    id: 'Discuss.SortingButtons.Comments',
  },
  date: {
    defaultMessage: 'Date',
    id: 'Discuss.SortingButtons.Date',
  },
  likes: {
    defaultMessage: 'Likes',
    id: 'Discuss.SortingButtons.Likes',
  },
});
