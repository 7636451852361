import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';

import './_table-of-contents-list-item.scss';

interface IPropTypes {
  children: string
}

export const TableOfContentsListItem: FC<IPropTypes> = (props: IPropTypes) => (
  <div className="player__table-of-contents-list-item">
    <div className="player__table-of-contents-list-item__icon">
      <FontAwesome name="lightbulb" />
    </div>

    <p className="player__table-of-contents-list-item__text">
      {props.children}
    </p>
  </div>
);
