import {defineMessages} from 'react-intl';

export default defineMessages({
  startNow: {
    defaultMessage: 'Start Now',
    id: 'CourseDetailPage.Overview.StartNow',
  },
  completed: {
    defaultMessage: 'Completed',
    id: 'Modals.CurriculumSkill.StatusLabel.Completed',
  },
  courseCompleted: {
    id: "CourseDetailPage.Overview.CourseCompleted",
    defaultMessage:
      "Course Completed",
  },
});
