import cx from 'classnames';
import React, { FC } from 'react';
import IconHoverable from '@u4i/common/IconHoverable';
import controlsIconHovered from '@u4i/assets/svg/controlsIconHovered.svg';
import controlsIconNonHovered from '@u4i/assets/svg/controlsIconNonHovered.svg';

import './_controls-toggle.scss';

interface IPropTypes {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isToggledOn: boolean
}

const ControlsToggle: FC<IPropTypes> = (props: IPropTypes) => {
  return (
    <button
      className={cx({
        'main-layout__controls-toggle': true,
        'main-layout__controls-toggle--on': props.isToggledOn,
      })}
      onClick={props.onClick}
      type="button"
    >
      <IconHoverable
        alt='open button'
        iconHovered = {controlsIconHovered}
        iconNonHovered = {controlsIconNonHovered}
        isActive={props.isToggledOn}
      />
    </button>
  );
}

export default ControlsToggle;
