import React from "react";
import { action, observable, makeObservable } from "mobx";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { LanguageEnum } from "@u4i/common/enums/LanguageEnum";
import intlMessages from "./intlMessages";
import { Select } from "antd";
import "./_language-switch.scss";

const availableLanguages = [
  {
    localeId: LanguageEnum.DE_DE,
    label: <FormattedMessage {...intlMessages.languageGerman} />,
  },
  {
    localeId: LanguageEnum.EN_US,
    label: <FormattedMessage {...intlMessages.languageEnglish} />,
  },
  {
    localeId: LanguageEnum.CS_CZ,
    label: <FormattedMessage {...intlMessages.languageCzech} />,
  },
  {
    localeId: LanguageEnum.KO_KR,
    label: <FormattedMessage {...intlMessages.languageKorean} />,
  },
  {
    localeId: LanguageEnum.JA_JP,
    label: <FormattedMessage {...intlMessages.languageJapanese} />,
  },
  {
    localeId: LanguageEnum.TH_TH,
    label: <FormattedMessage {...intlMessages.languageThai} />,
  },
  {
    localeId: LanguageEnum.ZH_CN,
    label: <FormattedMessage {...intlMessages.languageChinese} />,
  },
  {
    localeId: null,
    label: <FormattedMessage {...intlMessages.allLanguages} />,
  },
];

interface IPropTypes {
  activeItemsLanguage: LanguageEnum | null;
  onLanguageItemClick: (newItemsLanguage: LanguageEnum | null) => void;
}

export const LanguageSwitch = observer(
  class LanguageSwitch extends React.Component<IPropTypes> {
    private isOpen = false;

    constructor(props: IPropTypes) {
      super(props);

      makeObservable<LanguageSwitch, "isOpen">(this, {
        isOpen: observable,
        handleItemClick: action.bound,
        toggleIsOpen: action.bound,
      });
    }

    handleItemClick(localeId) {
      this.isOpen = false;
      if (localeId === undefined || localeId === "all-languages")
        localeId = null;
      this.props.onLanguageItemClick(localeId);
    }

    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    }

    render() {
      const activeLanguage = availableLanguages.find(
        (language) => language.localeId === this.props.activeItemsLanguage
      );

      return (
        <div style={{
          position: 'relative'
        }}>
          <Select
            getPopupContainer={(): any => document.getElementById('area')}
            defaultValue={
              activeLanguage?.localeId
                ? activeLanguage?.localeId
                : "all-languages"
            }
            style={{ width: 160 }}
            onChange={this.handleItemClick}
          >
            {availableLanguages.map((entry) => {
              return (
                <Select.Option
                  key={entry.localeId}
                  value={entry.localeId ? entry.localeId : "all-languages"}
                >
                  {entry.label}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      );
    }
  }
);
