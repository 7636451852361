import cx from 'classnames';
import React, { FC } from 'react';
import SubmitIcon from '@material-ui/icons/Send';
import {FormattedMessage} from 'react-intl';
import flaskIcon from '@u4i/assets/svg/flask.svg';
import {ActionButton} from './components/ActionButton';
import {CloseLabButton} from './components/CloseLabButton';
import {FullscreenButton} from './components/FullscreenButton';

import intlMessages from './intlMessages';
import { silver } from '@u4i/styles/styles-variables';
import './_control-bar.scss';

interface IPropTypes {
  isVisible: boolean
  labName: string
  showExpandScreenButton: boolean
  sessionCountDown: string
  onCloseLabClick: () => void
  onFullscreenClick: () => void
  onSubmitClick: () => void
}

export const ControlBar: FC<IPropTypes> = (props: IPropTypes) => (
  <div
    className={cx({
      'digital-lab__control-bar': true,
      'digital-lab__control-bar--visible': props.isVisible,
    })}
  >
    <div className="digital-lab__control-bar__info-block">
      <img alt="" className="digital-lab__control-bar__lab-icon" src={flaskIcon} />

      <h3 className="digital-lab__control-bar__lab-name">
        {props.labName} ( ACTIVE )
      </h3>
    </div>

    <ul className="digital-lab__control-bar__controls list-unstyled">
      <li className="digital-lab__control-bar__controls-entry">
        <ActionButton
          confirmationRequired
          icon={<SubmitIcon style={{color: silver}} />}
          label={<FormattedMessage {...intlMessages.submit} />}
          onClick={props.onSubmitClick}
        />
      </li>

      {props.sessionCountDown && props.sessionCountDown.length && <li className="digital-lab__control-bar__lab-countDown">
        <b> Lab Session End in {props.sessionCountDown}</b>
      </li>}
      <li className="digital-lab__control-bar__controls-entry digital-lab__control-bar__controls-entry-buttons">
        <FullscreenButton
          onFullscreenClick={props.onFullscreenClick}
          showExpandScreenButton={props.showExpandScreenButton}
        />

        <CloseLabButton
          onCloseLabClick={props.onCloseLabClick}
        />
      </li>
    </ul>
  </div>
);
