import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';
import DiscussAnswerStatus from '@u4i/common/enums/DiscussAnswerStatus';
import intlMessages from './intlMessages';
import './_answer-status.scss';

const statusIconNameMap: Map<DiscussAnswerStatus, string> = new Map([
  [DiscussAnswerStatus.ARCHIVED, 'archive'],
  [DiscussAnswerStatus.FAILED, 'times'],
  [DiscussAnswerStatus.PASSED, 'check'],
  [DiscussAnswerStatus.PENDING, 'hourglass-half'],
  [DiscussAnswerStatus.SUBMITTED, 'hourglass-half'],
]);

const statusNameMap: Map<DiscussAnswerStatus, JSX.Element> = new Map([
  [DiscussAnswerStatus.ARCHIVED, <FormattedMessage {...intlMessages.archived} />],
  [DiscussAnswerStatus.FAILED, <FormattedMessage {...intlMessages.failed} />],
  [DiscussAnswerStatus.PASSED, <FormattedMessage {...intlMessages.passed} />],
  [DiscussAnswerStatus.PENDING, <FormattedMessage {...intlMessages.pending} />],
  [DiscussAnswerStatus.SUBMITTED, <FormattedMessage {...intlMessages.pending} />],
]);

interface IProps {
  status: DiscussAnswerStatus;
}

export const AnswerStatus: FC<IProps> = (props: IProps) => (
  <div className="discuss-page__answer-status">
    <div
      className={cx({
        'discuss-page__answer-status__circle': true,
        'discuss-page__answer-status__circle--approved': props.status === DiscussAnswerStatus.PASSED,
        'discuss-page__answer-status__circle--archived': props.status === DiscussAnswerStatus.ARCHIVED,
        'discuss-page__answer-status__circle--pending': props.status === DiscussAnswerStatus.PENDING || props.status === DiscussAnswerStatus.SUBMITTED,
        'discuss-page__answer-status__circle--rejected': props.status === DiscussAnswerStatus.FAILED,
      })}
    >
      <FontAwesome
        className="discuss-page__answer-status__icon"
        name={statusIconNameMap.get(props.status)}
      />
    </div>

    <p className="discuss-page__answer-status__text">
      {statusNameMap.get(props.status)}
    </p>
  </div>
);
