import Hammer from 'react-hammerjs';
import React, {FC, useState} from 'react';
import {chunk} from 'lodash';
import { IProvider } from '@u4i/modules/CourseDetailPage/interfaces/ChapterInterface';
import { SliderNavigation } from './SliderNavigation/SliderNavigation';

import './_provider-slider.scss';

interface IProps {
  items: IProvider[]
}

export const ProviderSlider: FC<IProps> = (props: IProps) => {
  const { items } = props;
  const ITEMS_PER_PAGE = 2;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pages = Math.ceil(items.length / ITEMS_PER_PAGE);

  const changePage = (modifier: number) => {
    const newPage: number = currentPage + modifier;
    setCurrentPage(newPage);
  };

  const handleSwipe = (event) => {
    if (event.overallVelocityX < -0.6 && currentPage < pages) {
      changePage(1);
    } else if (event.overallVelocityX > 0.6 && currentPage > 1) {
      changePage(-1);
    }
  };

  const childrenChunks = chunk(items, ITEMS_PER_PAGE);

  return (
    <div className="list-unstyled course-detail-page__provider-slider">
      <Hammer onSwipe={(ev) => handleSwipe(ev)} options={{direction: 'DIRECTION_HORIZONTAL'}}>
        <ul className="list-unstyled course-detail-page__provider-slider__surface">
          {childrenChunks.map((pageChunk, index) => (
            <li
              className="list-unstyled course-detail-page__provider-slider__page-wrapper"
              key={index}
              style={{
                transform: `translate3d(${(currentPage - 1) * (-100)}%, 0, 0)`,
              }}
            >
              <ul className="list-unstyled course-detail-page__provider-slider__page">
                {pageChunk.map(item => (
                  <li
                    className="course-detail-page__provider-slider__item"
                    key={item.id}
                  >
                    <img
                      alt={item.name}
                      className="course-detail-page__provider-slider__image"
                      src={item.url}
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Hammer>

      {pages > 1 &&
        <SliderNavigation
          currentPage={currentPage}
          onDotClick={(newPage) => setCurrentPage(newPage)}
          pages={pages}
        />
      }
    </div>
  )
}
