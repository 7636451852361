import React, { FC } from 'react';
import UserStore from '@u4i/state/UserStore';

import './_impersonation-panel.scss';

interface IProps {
  userStore: UserStore
}

const ImpersonationPanel: FC<IProps> = (props: IProps) => {
  const {
    userData,
    cancelImpersonation
  } = props.userStore;
  
  return (
    <div className="main-layout__impersonation-panel">
      You are now impersonating&nbsp;
      <strong>{userData.fullName}</strong>

      <button
        className="main-layout__impersonation-panel__switch-button"
        onClick={cancelImpersonation}
        type="button"
      >
        Switch back
      </button>
    </div>
  );
};

export default ImpersonationPanel;
