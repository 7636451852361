import Parser from '@u4i/utils/Parser';

export interface ISettings {
  platform: {
    aboutWordpressUrl: string | null;
  };
  player: {
    tableOfContentsExcludedProviderId: string;
  };
  tabEntries: {
    about: string;
    discover: string;
  };
}

const settingsParser = new Parser<ISettings>(settings => ({
  platform: {
    aboutWordpressUrl: settings.platform.aboutWordpressUrl,
  },
  player: {
    tableOfContentsExcludedProviderId: settings.player.tableOfContentsExcludedProviderId,
  },
  tabEntries: {
    about: settings.tabEntries.about,
    discover: settings.tabEntries.discover,
  },
}));

export default settingsParser;
