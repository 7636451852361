import cx from 'classnames';
import React from 'react';

import './_terminal-frame.scss';

interface IPropTypes {
  challengeAttemptId: string;
  isVisible: boolean;
  labSubmitted: boolean;
  logoutUrl?: string;
}

export class TerminalFrame extends React.Component<IPropTypes> {
  jupyterLabIframeRef = React.createRef<HTMLIFrameElement>();

  componentDidUpdate() {
    if (this.jupyterLabIframeRef.current && this.props.logoutUrl) {
      this.jupyterLabIframeRef.current.src= this.props.logoutUrl;
    }
  }

  render() {
    return (
      <div
        className={cx({
          'jupyter-lab__terminal-frame': true,
          'jupyter-lab__terminal-frame--visible': this.props.isVisible,
        })}
      >
        {this.props.isVisible &&
          <iframe
            className="jupyter-lab__terminal-frame__iframe"
            ref={this.jupyterLabIframeRef}
            src={`${window.location.origin}/lti/launch/jupyter-lab/${this.props.challengeAttemptId}`}
            title="Terminal"
          />
        }
      </div>
    );
  }
}
