import React from "react";
import ReactDom from "react-dom";
import { observable, makeObservable, action, runInAction } from "mobx";
import Calendar from "@event-calendar/core";
import TimeGrid from "@event-calendar/time-grid";
import moment, { Moment } from "moment";
import { SelectValue } from "antd/lib/select";
import { DeleteFilled } from "@ant-design/icons";
import { OptionGroupData } from "react-select";
import { IApi } from "@u4i/state/ServicesInterfaces";
import { IRootStore } from "@u4i/state/RootStore";
import { BOOKED_EVENT_HEIGHT, DEFAULT_BOOKED_EVENT } from "@u4i/constantSettings";
import { CalendarEvent, ILabs, SlotValidation } from "@u4i/modules/DigitalLab/components/Booking/interface";
import { Notification, NotificationEnum } from "@u4i/modules/Admin/common/Notifications/Notification";

let adminCalendarInstance: any;
export class AdminDigitalLabSchedulingStore {
  private apiService: IApi;

  labs: ILabs[] = [];
  cloneLabs: ILabs[] = [];
  calendarLoader: boolean = false;
  isEventDeleteClick: boolean = false;
  isBookingConfirmationVisible: boolean = false;
  isBookingLoader: boolean = false;
  isDeleteConfirmationVisible: boolean = false;
  calendarEvents: CalendarEvent[] = [];
  selectedLabRestTime: number;
  selectedLabUserSessionTime: number;
  eventStartTime: Moment | undefined;
  eventEndTime: Moment | undefined;
  deleteSlotId: string;
  bookingSlotValidation: SlotValidation = {
    selectedLabId: "",
    selctedUserId: "",
    selectUserFirstLastName: "",
  };
  currentWeek = {
    from: "",
    to: "",
  };
  userList: any[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      calendarEvents: observable,
      labs: observable,
      calendarLoader: observable,
      bookingSlotValidation: observable,
      isBookingConfirmationVisible: observable,
      isBookingLoader: observable,
      isDeleteConfirmationVisible: observable,
      userList: observable,
      initCalendar: action.bound,
      onChangeLab: action.bound,
      getSelectedUsersIds: action.bound,
      hideBookingConformationModal: action.bound,
      bookSlot: action.bound,
      onClearUserValue: action.bound,
      deleteSlot: action.bound,
      getCalendarEvent: action.bound,
      filterDLBySearchValue: action.bound,
      onBlurDLSelectBox: action.bound,
      addSelectAllOption: action.bound,
    });

    const { apiService } = rootStore;

    this.apiService = apiService;
  }

  async initCalendar() {
    this.labs = await this.apiService.admin.adminChallenges.getDigitalLabList();

    if (this.labs.length) {
      this.bookingSlotValidation.selectedLabId = this.labs[0].id;
      this.selectedLabRestTime = this.labs[0].restTimeDuration;
      this.selectedLabUserSessionTime = this.labs[0].userSessionDuration;
    }

    this.cloneLabs = [...this.labs];
    adminCalendarInstance = new Calendar({
      target: document.getElementById("adminCalendarWrapper"),
      props: {
        plugins: [TimeGrid],
        options: {
          view: "timeGridWeek",
          height: "700px",
          slotDuration: "00:30:00",
          scrollTime: "08:00:00",
          firstDay: 1,
          eventSources: [
            {
              events: async (data) => {
                return this.calendarEvents;
              },
            },
          ],
          dateClick: (dateClickInfo) => {
            this.calendarSlotClicked(dateClickInfo);
          },
          eventClick: async (eventClickInfo) => {
            this.isEventDeleteClick = true;
            if (eventClickInfo.event.display === "auto") {
              let btn = eventClickInfo.el
                .querySelector('[data-button="deleteButton"]')?.getElementsByTagName("path")[0];

              let nodeData = null;
              nodeData = eventClickInfo.jsEvent.target
                ?.querySelector("span")
                ?.getElementsByTagName("path")[0];
              if (!nodeData) {
                nodeData = eventClickInfo.jsEvent.target;
              }

              if (nodeData === btn) {
                this.isDeleteConfirmationVisible = true;
                this.deleteSlotId = eventClickInfo.event.id;
              }
            } else {
              this.calendarSlotClicked(eventClickInfo);
            }
          },
          eventContent: (info) => {
            return this.eventTemplate(info);
          },
          dayHeaderFormat: (date: Date) => {
            const dateNumber = moment(date).date();
            const month = moment(date).month();
            const weekDay = moment(date).format("ddd");
            return `${weekDay} ${dateNumber} - ${month + 1}`;
          },
          slotLabelFormat: (date) => {
            return moment(date).format("HH:mm");
          },
          datesSet: async (info) => {
            this.currentWeek.from = moment(info.startStr).format("YYYY-MM-DD");
            this.currentWeek.to = moment(info.endStr).format("YYYY-MM-DD");
            await this.getCalendarEvent();
          },
        },
      },
    });
  }

  eventTemplate(eventInfo) {
    const labRestTime =
      eventInfo.event.extendedProps.labResetDuration ||
      this.selectedLabRestTime;

    const eventStartTime = moment(eventInfo.event.start);
    const eventEndTime = moment(eventInfo.event.end).subtract(
      labRestTime,
      "minutes"
    );

    const timeDifference = moment(eventEndTime).diff(eventStartTime, "minutes");

    const displayStartTime = eventStartTime.format("HH:mm");
    const displayEndTime = eventEndTime.format("HH:mm");

    const labResetClass = eventInfo.event.extendedProps.isMine
      ? "maintenanceLab"
      : "maintenanceLabDisable";

    const restTimeHeight = Math.round(
      (BOOKED_EVENT_HEIGHT / DEFAULT_BOOKED_EVENT) *
        eventInfo.event.extendedProps.labResetDuration
    );

    const eventTitle = eventInfo.event.title;

    const eventId = eventInfo.event.extendedProps.id;

    return this.getEventDisplayData(
      displayStartTime,
      displayEndTime,
      eventTitle,
      labResetClass,
      restTimeHeight,
      timeDifference,
      eventId
    );
  }

  getEventDisplayData = (
    displayStartTime: string,
    displayEndTime: string,
    title: string,
    labResetClass: string,
    restTimeHeight: number,
    timeDifference: number,
    eventId: string
  ) => {
    const deleteButtonIcon = (
      <span
        data-button="deleteButton"
        style={{
          padding: "1px",
          position: "absolute",
          right: "4px",
          top: "4px",
          zIndex: 1,
        }}
      >
        <DeleteFilled
          style={{
            fontSize: "18px",
          }}
        />
      </span>
    );
    setTimeout(() => {
      if (document.getElementById(eventId) !== null) {
        ReactDom.render(deleteButtonIcon, document.getElementById(eventId));
      }
    });
    return `<div id="${eventId}"></div><div class="ec-event-time">${displayStartTime} - ${displayEndTime}</div>
    <div class="ec-event-title">${title}</div>
    <div class="${labResetClass}" style="height:${restTimeHeight}px"></div>`;
  };

  calendarSlotClicked(slotData) {
    if (!this.isEventDeleteClick) {
      this.eventStartTime = moment(slotData.date);

      const slotDuration =
        this.selectedLabUserSessionTime + this.selectedLabRestTime;

      this.eventEndTime = moment(slotData.date).add(slotDuration, "minutes");
      if (
        this.bookingSlotValidation.selectedLabId == "" ||
        this.bookingSlotValidation.selectedLabId == "select_all"
      ) {
        Notification(NotificationEnum.Error, "Booking", "Please select a Lab to book a slot");
        return;
      }

      if (
        !this.bookingSlotValidation.selctedUserId ||
        this.bookingSlotValidation.selctedUserId == "" ||
        this.bookingSlotValidation.selctedUserId == "select_all"
      ) {
        Notification(NotificationEnum.Error, "Booking", "Please select a User to book a slot");
        return;
      }

      if (!this.eventStartTime || !this.eventEndTime) {
        Notification(NotificationEnum.Error, "Booking", `Booking time is required`);
        return;
      }

      if (moment(this.eventStartTime).isBefore(new Date())) {
        Notification(NotificationEnum.Error, "Booking", "Slot cannot be booked in past date or time");
        return;
      }

      if (moment(this.eventStartTime).isAfter(this.eventEndTime)) {
        Notification(NotificationEnum.Error, "Booking", `Booking end time should be after starting time`);
        return;
      }

      this.isBookingConfirmationVisible = true;
    }

    if (this.isEventDeleteClick) {
      setTimeout(() => {
        this.isEventDeleteClick = false;
      }, 10);
    }
  }

  async onChangeLab(id: SelectValue) {
    this.bookingSlotValidation.selectedLabId = id;
    const selectedLab = this.labs.filter((lab) => lab.id == id);
    if (id && id !== "select_all") {
      this.selectedLabRestTime = selectedLab[0].restTimeDuration;
      this.selectedLabUserSessionTime = selectedLab[0].userSessionDuration;
    } else {
      this.selectedLabRestTime = -1;
    }
    await this.getCalendarEvent();
  }

  async getSelectedUsersIds(value: string, options: OptionGroupData) {
    this.bookingSlotValidation.selectUserFirstLastName =
      options.label as string;
    this.bookingSlotValidation.selctedUserId = options.id;
    await this.getCalendarEvent();
  }

  async onClearUserValue() {
    this.bookingSlotValidation.selctedUserId = undefined;
    await this.getCalendarEvent();
  }

  async getCalendarEvent() {
    if (this.bookingSlotValidation.selectedLabId == undefined) {
      return false;
    }

    this.calendarEvents = [];
    this.calendarLoader = true;
    try {
      const slots = await this.apiService.admin.adminChallenges.getBookedSlots(
        this.bookingSlotValidation.selectedLabId as string,
        this.bookingSlotValidation.selctedUserId as string,
        this.currentWeek
      );
      if (slots.length === 0) {
        runInAction(() => {
          this.calendarEvents = [];
        });
        return;
      }
      slots.forEach((slot) => {
        const slotObj = {
          id: slot.slotId,
          resourceId: slot.slotId,
          start: new Date(slot.slotStart),
          end: new Date(slot.slotEnd),
          title: slot.userFirstName
            ? `${slot.userFirstName} ${slot.userLastName}`
            : `${this.bookingSlotValidation.selectUserFirstLastName}`,
          editable: slot.editable,
          color: "slot",
          isMine: slot.isMine,
          extendedProps: {
            isMine: slot.isMine,
            labResetDuration:
              this.selectedLabRestTime != -1
                ? this.selectedLabRestTime
                : Math.round(slot.resourceMaintenanceDuration / 60),
            id: slot.slotId,
          },
          errorMessage: "",
        };
        adminCalendarInstance.addEvent(slotObj);
        this.calendarEvents.push(slotObj);
      });
    } catch (error) {
      Notification(NotificationEnum.Error, "Booking", `${error}`);
    } finally {
      adminCalendarInstance.refetchEvents();
      this.calendarLoader = false;
    }
  }

  async bookSlot() {
    this.isBookingLoader = true;
    const selectedLab = this.labs.filter(
      (lab) => lab.id == this.bookingSlotValidation.selectedLabId
    );

    const eventObj = {
      id: "",
      resourceId: selectedLab[0].id,
      start: new Date(
        moment(this.eventStartTime).local().format("YYYY-MM-DD HH:mm:ss")
      ),
      end: new Date(
        moment(this.eventEndTime).local().format("YYYY-MM-DD HH:mm:ss")
      ),
      title: `${selectedLab[0].title}`,
      editable: true,
      color: "",
      isMine: true,
      extendedProps: {
        isMine: true,
        labResetDuration: this.selectedLabRestTime,
        id: "",
      },
      errorMessage: undefined,
    };

    const utcEventObj = {
      start: moment.utc(eventObj.start).format(),
      end: moment.utc(eventObj.end).format(),
      title: eventObj.title,
      userId: this.bookingSlotValidation.selctedUserId,
    };

    try {
      const bookSlotResponse =
        await this.apiService.admin.adminChallenges.adminBookSlotForUser(
          eventObj.resourceId,
          utcEventObj
        );

      if (!bookSlotResponse.errorMessage) {
        const newEvents = [...this.calendarEvents];
        eventObj.id = bookSlotResponse.slotId;
        eventObj.extendedProps.id = bookSlotResponse.slotId;
        newEvents.push(eventObj);
        this.calendarEvents = newEvents;
        adminCalendarInstance.addEvent(eventObj);

        this.eventStartTime = undefined;
        this.eventEndTime = undefined;
      } else {
        Notification(NotificationEnum.Error, "Booking", `${bookSlotResponse.errorMessage}`);
      }
    } catch (err) {
      Notification(NotificationEnum.Error, "Booking", `${err}`);
    }
    this.isBookingConfirmationVisible = false;
    this.isBookingLoader = false;
  }

  async filterDLBySearchValue(val: SelectValue) {
    let stringVal = val as string;
    if (stringVal.length) {
      this.labs = this.cloneLabs.filter((lab: ILabs) => {
        let labTitle = lab.title.toLocaleLowerCase();
        if (labTitle.includes(stringVal.toLocaleLowerCase())) {
          return true;
        }
      });
    } else {
      this.labs = this.cloneLabs;
    }
  }

  async onBlurDLSelectBox() {
    this.labs = this.cloneLabs;
  }

  async deleteSlot() {
    this.isBookingLoader = true;
    try {
      const response =
        await this.apiService.admin.adminChallenges.deleteBoolingSlot(
          this.deleteSlotId
        );
      if (response) {
        adminCalendarInstance.removeEventById(this.deleteSlotId);
      } else {
        Notification(NotificationEnum.Error, "Booking", `${response}`);
      }
      this.isDeleteConfirmationVisible = false;
      this.isBookingLoader = false;
      this.deleteSlotId = "";
    } catch (err) {
      Notification(NotificationEnum.Error, "Booking", `${err}`);
    }
  }

  async hideBookingConformationModal() {
    this.isBookingConfirmationVisible = false;
    this.isDeleteConfirmationVisible = false;
  }

  async addSelectAllOption(normalizedUsersList) {
    const optionObj = {
      id: "select_all",
      label: "All users",
      value: "select_all",
    };

    const transformedList =  normalizedUsersList.map(item=>{

      const formatedValue = item.value.split(' ')

      return {
        id:item.id, 
        label:formatedValue.slice(0,2).join(' ') + ` (${formatedValue[2]})`,
        value:item.value
      }
    });

    this.userList = [...transformedList];
    this.userList.splice(0, 0, optionObj);
  }
}
