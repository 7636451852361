import { notification } from "antd";
import { getRandomInt } from "@u4i/common/utils";
import { antDError, antDInfo, antDSuccess, antDWarn, green, mainBlue, orange, red } from "@u4i/styles/styles-variables";
import { ReactNode } from "react";
import { ArgsProps } from "antd/lib/notification/interface";

export enum NotificationEnum {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning"
}

interface NotificationStyle {
  backgroundColor: string
  borderColor: string
  type: NotificationEnum
}

const notificationStyles: NotificationStyle[] = [
  { type: NotificationEnum.Success, borderColor: green, backgroundColor: antDSuccess },
  { type: NotificationEnum.Error, borderColor: red, backgroundColor: antDError },
  { type: NotificationEnum.Info, borderColor: mainBlue, backgroundColor: antDInfo },
  { type: NotificationEnum.Warning, borderColor: orange, backgroundColor: antDWarn }
]

export const Notification = (
  type: NotificationEnum,
  message: string | ReactNode = "Notification",
  description: string | ReactNode = "",
  key: string = getRandomInt(1, 1000).toString()
) => {
  const argsProps: ArgsProps = {
    description: description,
    duration: 4,
    key: key,
    message: message,
    placement: "topRight",
    style: {
      backgroundColor: notificationStyles.find(el => el.type == type)?.backgroundColor,
      border: `1px solid ${notificationStyles.find(el => el.type == type)?.borderColor}`,
      borderRadius: 9,
      marginTop: 50
    }
  };

  notification[type](argsProps);
};
