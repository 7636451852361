import React from "react";
import { FormattedMessage } from "react-intl";
import intlMessages from "./intlMessages";
import { Row, Col, Typography, Divider } from "antd";
import { CommentOutlined } from "@ant-design/icons";

interface IProps {
  onFeedbackSurveyStart: () => void;
}
const FeedbackSurveyItem = (props: IProps) => {
  return (
    <>
      <Row
        gutter={8}
        style={{
          flexWrap: "nowrap",
          cursor: "pointer",
        }}
        className="chapter_list_item"
        onClick={props.onFeedbackSurveyStart}
      >
        <Col>
          <CommentOutlined
            style={{
              fontSize: 25,
            }}
            className="icon_font_calcualator"
          />
        </Col>
        <Col>
          <Typography.Title
            level={5}
            style={{
              marginBottom: 0,
              marginLeft: 4,
              marginTop:0
            }}
            className="title_font_calcualator"
          >
            <FormattedMessage {...intlMessages.title} />
          </Typography.Title>
        </Col>
      </Row>
      <Divider
        style={{
          borderColor: "#F0F0F0",
          margin: 0,
        }}
      />
    </>
  );
};

export default FeedbackSurveyItem;
