import qs from 'qs';
import {AxiosInstance} from 'axios';

import {AdminUserCourseParser} from '@u4i/parsers/admin/AdminUserCourseParser';
import {AdminUserCourse, Course, AssignUserCourseIds, UserChallengesInfo} from '@u4i/modules/Admin/modules/UserCourses/interfaces';
import { IPaginatedItems } from '@u4i/state/ServicesInterfaces';
import {AdminUsersParser, IAdminUser} from '@u4i/parsers/admin/AdminUsersParser';
import { downloadAutomatically } from '@u4i/common/utils';
import { AntDTableFilters } from '@u4i/modules/Admin/common/Interfaces/TablePagination.interfaces';

export class AdminUserCourseApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async fetchUserCourseData(config: AntDTableFilters<AdminUserCourse>): Promise<IPaginatedItems<AdminUserCourse>> {
    const response = await this.apiClient.get('/admin/user-skills', {
      params: {
        limit: config.limit,
        offset: config.offset,
        filters: config.filters,
        orderBy: config.orderBy,
      },
      paramsSerializer: params => qs.stringify(params),
    });

    const items = AdminUserCourseParser.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items, totalItems};
  }

  async fetchUserCourseById(id: string): Promise<AdminUserCourse> {
    const response = await this.apiClient.get(`/admin/user-skills/${id}`);
    return response.data;
  }

  async unassingUserCourse(id: string): Promise<boolean> {
    const response = await this.apiClient.put(`/admin/user-skills/${id}/unassign-skill`);
    return response.data;
  }

  async fetchCoursesList(): Promise<Course[]> {
    const response = await this.apiClient.get(`/admin/skills`);
    return response.data;
  }

  async assignCourses(data: AssignUserCourseIds): Promise<boolean> {
    const response = await this.apiClient.put(`/admin/user-skills/assign-skill`, data);
    return response.data;
  }

  async deleteProofOfQualification(userCourseId: string): Promise<boolean> {
    const response = await this.apiClient.delete(`/admin/user-skills/${userCourseId}/proof-of-qualification`);
    return response.data;
  }

  async generateProofOfQualification(userCourseId: string): Promise<boolean> {
    const response = await this.apiClient.post(`/admin/user-skills/${userCourseId}/proof-of-qualification`);
    return response.data;
  }

  async challengesInfo(userCourseId: string): Promise<UserChallengesInfo[]> {
    const response = await this.apiClient.get(`/admin/user-skills/${userCourseId}/challenges/extended`);
    return response.data;
  }

  async downloadPdfFile(userCourseId: string, fileName: string, apiEndPoint: string): Promise<boolean> {
    const response = await this.apiClient.get(`/admin/user-skills/${userCourseId}/proof-of-qualification/${apiEndPoint}`, {responseType: 'arraybuffer'});

    return downloadAutomatically(response.data, fileName, "application/pdf");
  }

  async fetchUsersByKeyword(keyword: string): Promise<IPaginatedItems<IAdminUser>> {
    const response = await this.apiClient.get(`/admin/users/search?keywords=${keyword}`);

    const items = AdminUsersParser.parseArray(response.data);
    const totalItems = parseInt(response.headers['x-total-count'], 10);

    return {items, totalItems};
  }

}
