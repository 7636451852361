import React from "react";
import { CheckCircleFilled, LockFilled } from "@ant-design/icons";
import { Row, Col, Typography } from "antd";
import ChapterStatistics from "../../ChapterStatistics";
import { sliceText } from "@u4i/utils/helpers";
import { PlayerContext } from "@u4i/modules/VirtualExperience/PlayerState/playerContext";

const ChapterItem = (props) => {
  const playerState: any = React.useContext(PlayerContext);

  return (
    <Row
      onClick={(event) => {
        event.stopPropagation();

        if (!props.canAccessChapter()) return props.onClick();

        return props.showNotification();
      }}
      gutter={8}
      style={{
        flexWrap: "nowrap",
      }}
    >
      <Col>
        {props.checkChapterCompletion() ? (
          <CheckCircleFilled
            style={{
              color: "#87d068",
              fontSize: 30,
            }}
            className="icon_font_calcualator"
          />
        ) : !props.canAccessChapter() ? (
          <span className="circle_icon"></span>
        ) : (
          <>
            <span className="circle_icon">
              {" "}
              <LockFilled
                style={{
                  color: "rgb(0, 132, 215)",
                }}
              />
            </span>
          </>
        )}
      </Col>
      <Col>
        <Typography.Title
          level={5}
          style={{
            marginBottom: 0,
            marginTop:0
          }}
          className="title_font_calcualator"
        >
          {sliceText(props.chapter.title, 50)}
        </Typography.Title>
        <Typography.Text type="secondary">
          <ChapterStatistics
            challengesCompleted={playerState.chapterChallengesCompletedSelector(
              props.chapter.id
            )}
            challengesInGrading={playerState.chapterChallengesInGradingSelector(
              props.chapter.id
            )}
            topicsCount={props.chapter.topics.length}
            totalChallenges={
              playerState.chapterChallengesSelector(props.chapter.id).length
            }
            viewedTopicsCount={props.viewedTopicsCount()}
          />
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default ChapterItem;
