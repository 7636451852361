import cx from 'classnames';
import React, { FC } from 'react';
import './_grading-submit-button.scss';

interface IPropTypes {
  children: React.ReactNode;
  disabled?: boolean;
  inline?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  submitting?: boolean;
  type: 'failed' | 'passed';
}

export const GradingSubmitButton: FC<IPropTypes> = (props) => (
  <button
    className={cx({
      'reactive-forms__grading-submit-button': true,
      'reactive-forms__grading-submit-button--failed': props.type === 'failed',
      'reactive-forms__grading-submit-button--inline': props.inline !== undefined ? props.inline : false,
      'reactive-forms__grading-submit-button--passed': props.type === 'passed',
    })}
    disabled={props.disabled !== undefined ? props.disabled : false}
    onClick={props.onClick}
    type="submit"
  >
    <div
      className={cx({
        'reactive-forms__grading-submit-button__spinner': true,
        'reactive-forms__grading-submit-button__spinner--visible': props.submitting !== undefined ?
          props.submitting : false,
      })}
    />

    <div
      className={cx({
        'reactive-forms__grading-submit-button__content-wrapper': true,
        'reactive-forms__grading-submit-button__content-wrapper--hidden': props.submitting !== undefined ?
          props.submitting : false,
      })}
    >
      {props.children}
    </div>
  </button>
);
