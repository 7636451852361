import cx from 'classnames';
import FontAwesome from 'react-fontawesome';
import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import './_playback-rate-dropdown-entry.scss';

interface IProps {
  isActive: boolean
  onClick: (value: any) => any
  value: number
}

const PlaybackRateDropdownEntry: FC<IProps> = (props: IProps) => {
  const {
    isActive,
    value,
    onClick
  } = props;

  const handleClick = () => {
    onClick(value);
  };
  
  return (
    <li className="youtube-player__playback-rate-dropdown-entry">
      <button
        className="youtube-player__playback-rate-dropdown-entry__button"
        onClick={handleClick}
      >
        <FontAwesome
          className={cx({
            'youtube-player__playback-rate-dropdown-entry__icon': true,
            'youtube-player__playback-rate-dropdown-entry__icon--visible': isActive,
          })}
          name="check"
        />

        {value === 1 ?
          <FormattedMessage {...intlMessages.normalSpeed} />
          :
          value
        }
      </button>
    </li>
  );

}

export default PlaybackRateDropdownEntry;
