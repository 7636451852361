import React from "react";
import ChallengeListItem from "./ChallengeListItem";
import { IChallengePlayer, IChapterPlayer } from "../../PlayerState/player-interface";

interface IPropTypes {
  activeChallengeId: string;
  challenges: Array<IChallengePlayer>;
  chapters: Array<IChapterPlayer>;
  hasMandatoryChapters: boolean;
  isExpanded: boolean;
  onChallengeClick: any;
}

const ChallengeList = (props: IPropTypes) => (
  <>
    {props.challenges.map((challenge: IChallengePlayer, index: number) => (
      <ChallengeListItem
        challengeId={challenge.challengeId}
        challengeName={challenge.challengeName}
        challengeStatus={challenge.challengeStatus}
        chapterId={challenge.chapterId}
        isExpanded={props.isExpanded}
        isNowViewed={challenge.challengeId === props.activeChallengeId}
        onClick={props.onChallengeClick}
        topicId={challenge.topicId}
        chapters={props.chapters}
        hasMandatoryChapters={props.hasMandatoryChapters}
        key={index}
      />

    ))}
  </>
);

export default ChallengeList;
