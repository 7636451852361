import {action, computed, observable, runInAction,makeObservable} from 'mobx';

import UserStore from '@u4i/state/UserStore';
import {IApi} from '@u4i/state/ServicesInterfaces';
import {IRootStore} from '@u4i/state/RootStore';
import {ISection} from '@u4i/parsers/LandingPageParser';
import {ISkillBasic} from '@u4i/parsers/SkillParserBasic';
import {MySkillsFiltersEnum} from '@u4i/common/enums/MySkillsFiltersEnum';
import {BaseSection} from '../BaseSection';
import {LandingPageStore} from '../../LandingPageStore';
import {VisualComponent} from './VisualComponent';

export class MySkillsSection extends BaseSection {
  private apiService: IApi;
  private landingPageStore: LandingPageStore;
  private userStore: UserStore
  activeFilter = MySkillsFiltersEnum.ALL;
  fetching = true;
  items: Array<ISkillBasic> = [];

  constructor(rootStore: IRootStore, sectionData: ISection) {
    super(sectionData, VisualComponent);

    makeObservable(this, {
      activeFilter: observable,
      fetching: observable,
      items: observable,
      completedSkillsCount: computed,
      filteredItems: computed,
      isVisible: computed,
      unfinishedSkillsCount: computed,
      changeFilter: action.bound,
      reloadContent: action.bound
    });

    const {apiService, landingPageStore, userStore} = rootStore;
    this.apiService = apiService;
    this.landingPageStore = landingPageStore;
    this.userStore = userStore;

    this.reloadContent();
  }

  get completedSkillsCount(): number {
    return this.items.reduce((accumulator, skill) => accumulator + (skill.isCompleted ? 1 : 0), 0);
  }

  get filteredItems(): Array<ISkillBasic> {
    return this.items.filter((skill) => {
      switch (this.activeFilter) {
        case MySkillsFiltersEnum.ALL:
          return true;
        
        case MySkillsFiltersEnum.COMPLETED:
          return skill.isCompleted;

        case MySkillsFiltersEnum.UNFINISHED:
          return !skill.isCompleted;
      }
    });
  }

  get isVisible(): boolean {
    return !this.landingPageStore.isPrivateModeEnabled && this.userStore.isAuthorized;
  }

  get unfinishedSkillsCount(): number {
    return this.items.reduce((accumulator, skill) => accumulator + (skill.isCompleted ? 0 : 1), 0);
  }

  changeFilter(newFilter: MySkillsFiltersEnum) {
    this.activeFilter = newFilter;
  }

  async reloadContent(): Promise<void> {
    this.fetching = true;

    const items = await this.apiService.landingPages.fetchSectionItems<ISkillBasic>(
      this.id,
      this.type,
      this.landingPageStore.previewModeEnabled,
      this.landingPageStore.itemsLanguage,
    );

    runInAction(() => {
      this.fetching = false;
      this.items = items;
    });
  }
}
