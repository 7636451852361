import React, { Fragment } from "react";
import { observer } from "mobx-react";
import AppNavigator, { appRoutes } from "@u4i/common/AppNavigator";
import { ISkillBasic } from "@u4i/parsers/SkillParserBasic";
import { BasicInfoWrapper } from "../common/BasicInfoWrapper";
import { FilterButtons } from "./FilterButtons";
import { HorizontalSlider } from "../common/HorizontalSlider";
import { MySkillsSection } from "./MySkillsSection";
import { NoItemsMessage } from "../common/NoItemsMessage";
import CommonCard from "@u4i/common/Cards/CommonCard";

interface IPropTypes {
  sectionModel: MySkillsSection;
}

export const VisualComponent = observer(
  class VisualComponent extends React.Component<IPropTypes> {
    handleSkillCardClick = (skill: ISkillBasic) => {
      AppNavigator.push(appRoutes.courseDetailPage, { skillSlug: skill.slug });
    };

    render() {
      const { sectionModel } = this.props;

      return (
        <BasicInfoWrapper
          isLoading={sectionModel.fetching}
          itemsCountLabel={sectionModel.filteredItems.length}
          sectionModel={sectionModel}
        >
          <div className="container">
            {sectionModel.items.length > 0 ? (
              <Fragment>
                <FilterButtons
                  onFilterSkills={sectionModel.changeFilter}
                  skillsCompletedPresent={sectionModel.completedSkillsCount > 0}
                  skillsUnfinishedPresent={
                    sectionModel.unfinishedSkillsCount > 0
                  }
                />
                <HorizontalSlider>
                  {this.props.sectionModel.filteredItems.map((skill: ISkillBasic) => (
                    <CommonCard
                      title={skill.title}
                      duration={skill.duration}
                      image={skill.image}
                      language={skill.language}
                      onCardClick={() => this.handleSkillCardClick(skill)}
                      key={skill.id}
                      progress={skill.skillProgressPercentage}
                      isCompleted={skill.isCompleted}
                      numberOfChallenges={skill.numberOfChallenges}
                      numberOfCompletedChallenges={skill.numberOfCompletedChallenges}
                      isSkilCard
                    />
                  ))}
                </HorizontalSlider>
              </Fragment>
            ) : (
              <NoItemsMessage />
            )}
          </div>
        </BasicInfoWrapper>
      );
    }
  }
);
