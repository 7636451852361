import {defineMessages} from 'react-intl';

export default defineMessages({
  average: {
    defaultMessage: 'Group avg.',
    id: 'LandingPage.CompareMeSection.CurriculumTab.CurriculumProgressChart.Average',
  },
  count: {
    defaultMessage: 'completed',
    id: 'LandingPage.CompareMeSection.CurriculumTab.CurriculumProgressChart.Count',
  },
  top: {
    defaultMessage: 'TOP user',
    id: 'LandingPage.CompareMeSection.CurriculumTab.CurriculumProgressChart.Top',
  },
});
