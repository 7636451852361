import React from 'react';

import { BaseFilter } from '../BaseFilter';

import './_text-filter.scss';

export class TextFilter extends BaseFilter<string> {
  private outputFilterName: string;

  constructor(
    attachedToColumn: string,
    outputFilterName: string,
    defaultValue?: string,
  ) {
    super(attachedToColumn, defaultValue ? { [outputFilterName]: defaultValue } : {});

    this.outputFilterName = outputFilterName;
  }

  private handleValueChange = (event) => {
    event.preventDefault();

    this.updateValues({ [this.outputFilterName]: event.target.value });
  };

  render() {
    return (
      <input
        className="data-table__text-filter"
        key={this.id}
        onChange={this.handleValueChange}
        type="text"
        value={this.currentValues[this.outputFilterName] || ''}
      />
    );
  }
}
