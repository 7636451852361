import ArrowIcon from "@material-ui/icons/PlayArrow";
import { lightGray } from "@u4i/styles/styles-variables";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import intlMessages from "./intlMessages";
import "./_back-button.scss";

interface IProps {
  showButtonOnly?: boolean
  onClick: () => void
}

export const BackButton: FC<IProps> = (props: IProps) => {
  const {showButtonOnly, onClick} = props;

  if (showButtonOnly) {
    return (
      <button
        className="course-detail-page__back-button__action"
        onClick={onClick}
      >
        <ArrowIcon
          className="course-detail-page__back-button__arrow"
          style={{ color: lightGray, height: 16, width: 16 }}
        />

        <FormattedMessage {...intlMessages.label} />
      </button>
    );
  }

  return (
    <div className="container">
      <div className="course-detail-page__back-button">
        <button
          className="course-detail-page__back-button__action"
          onClick={onClick}
        >
          <ArrowIcon
            className="course-detail-page__back-button__arrow"
            style={{ color: lightGray, height: 16, width: 16 }}
          />

          <FormattedMessage {...intlMessages.label} />
        </button>
      </div>
    </div>
  );
}