import LowVolumeIcon from '@material-ui/icons/VolumeDown';
import MutedIcon from '@material-ui/icons/VolumeOff';
import NormalVolumeIcon from '@material-ui/icons/VolumeUp';
import NoVolumeIcon from '@material-ui/icons/VolumeMute';
import React, {Component} from 'react';
import VolumeBar from './VolumeBar';
import { white } from '@u4i/styles/styles-variables';

import './_volume-controls.scss';

interface IProps {
  isMuted: boolean
  onVolumePercentageChange: () => any
  onVolumeToggle: () => any
  volumePercentage: number
}

class VolumeControls extends Component<IProps> {
  get volumeIcon() {
    if (this.props.isMuted) {
      return <MutedIcon style={{color: white}} />;
    }

    if (this.props.volumePercentage === 0) {
      return (
        <NoVolumeIcon
          className="youtube-player__volume-controls__icon-no-volume"
          style={{color: white}}
        />
      );
    } else if (this.props.volumePercentage < 50) {
      return (
        <LowVolumeIcon
          className="youtube-player__volume-controls__icon-low-volume"
          style={{color: white}}
        />
      );
    }

    return <NormalVolumeIcon style={{color: white}} />;
  }

  render() {
    const {volumePercentage, onVolumeToggle, onVolumePercentageChange} = this.props;
    return (
      <div className="youtube-player__volume-controls">
        <button
          className="youtube-player__volume-controls__button"
          onClick={onVolumeToggle}
        >
          {this.volumeIcon}
        </button>

        <VolumeBar
          onVolumePercentageChange={onVolumePercentageChange}
          volumePercentage={volumePercentage}
        />
      </div>
    );
  }
}

export default VolumeControls;
