import {defineMessages} from 'react-intl';

export default defineMessages({
  errorHeading401: {
    defaultMessage: 'Sorry',
    id: 'CourseDetailPage.ErrorMessage.ErrorHeading401',
  },
  errorMessage401: {
    defaultMessage: 'Please log-in first if you want to view this page!',
    id: 'CourseDetailPage.ErrorMessage.ErrorMessage401',
  },
  errorHeading403: {
    defaultMessage: 'Sorry',
    id: 'CourseDetailPage.ErrorMessage.ErrorHeading403',
  },
  errorMessage403: {
    defaultMessage: 'You’re not authorized to access this page. If you think you should be authorized, please contact {mail}.',
    id: 'CourseDetailPage.ErrorMessage.ErrorMessage403',
  },
  errorHeading404: {
    defaultMessage: 'Oops!',
    id: 'CourseDetailPage.ErrorMessage.ErrorHeading404',
  },
  errorMessage404: {
    defaultMessage: 'The page you are looking for does not exist. The link might be outdated or temporarily unavailable. Try searching again or go to U4I’s home page.',
    id: 'CourseDetailPage.ErrorMessage.ErrorMessage404',
  },
});
