export const reactiveFormsValidators = {
  email: message => (value): string | undefined =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? message : undefined),

  maxLength: (maxLength, message) => (value): string | undefined =>
    (value && value.length > maxLength ? message : undefined),

  minLength: (minLength, message) => (value): string | undefined =>
    (value && value.length < minLength ? message : undefined),

  maxNumberValue: (maxValue, message) => (value): string | undefined =>
    (value !== undefined ? (value > maxValue ? message : undefined) : undefined),

  minNumberValue: (minValue, message) => (value): string | undefined =>
    (value !== undefined ? (value < minValue ? message : undefined) : undefined),

  repeat: (connectedField, message) => (value, allValues): string | undefined =>
    (allValues[connectedField] && allValues[connectedField] !== value ? message : undefined),

  required: message => (value): string | undefined => (value ? undefined : message),

  requiredFiles: message => (value): string | undefined =>
    ((Array.isArray(value.slice()) && value.length > 0) ? undefined : message),

  requiredWithStringTrim: message => function (value): string | undefined {
    if (!value) {
      return message;
    }

    if (typeof value === 'string') {
      if (value.trim().length === 0) {
        return message;
      }
    }

    return undefined;
  },
};
