import React, { FC } from 'react';
import { opacity7 } from '../../constants/Constants';

interface IProps {
  direction: string
  length: number
  strokeColor: string
  startX: number
  startY: number
}

export const PathLine: FC<IProps> = (props: IProps) => {
  const {
    direction,
    length,
    startX,
    startY,
    strokeColor
  } = props;

  const moduleStrokeWidth: number = 4;
  const phaseStrokeWidth: number = 5.5;

  return (
    <svg>
      <g>
        <path
          d={`M${startX} ${startY}${direction}${length}`}
          stroke={strokeColor}
          strokeWidth={direction == 'h' ? phaseStrokeWidth : moduleStrokeWidth}
          strokeOpacity={opacity7}
        ></path>
      </g>
    </svg>
  )
}
