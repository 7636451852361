import cx from 'classnames';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { FC } from 'react';
import {FormattedMessage} from 'react-intl';

import intlMessages from './intlMessages';
import { green } from '@u4i/styles/styles-variables';
import './_notification-window.scss';

interface IPropTypes {
  isVisible: boolean
  onCloseWindow: () => void
}

export const NotificationWindow: FC<IPropTypes> = (props: IPropTypes) => (
  <div
    className={cx({
      'jupyter-lab__notification-window': true,
      'jupyter-lab__notification-window--visible': props.isVisible,
    })}
  >
    <div className="jupyter-lab__notification-window__box">
      <div className="jupyter-lab__notification-window__content">
        <ErrorOutlineIcon style={{color: green, height: 52, width: 52}} />

        <p className="jupyter-lab__notification-window__message">
          <FormattedMessage {...intlMessages.message} />
        </p>

        <button
          className="jupyter-lab__notification-window__button"
          onClick={props.onCloseWindow}
          type="button"
        >
          <FormattedMessage {...intlMessages.closeWindow} />
        </button>
      </div>
    </div>
  </div>
);
