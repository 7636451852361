import is from 'is_js';
import React, { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import screenfull, {Screenfull} from 'screenfull';
import {action, computed, observable, reaction,makeObservable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import { RouteComponentProps } from "@reach/router";

import AppNavigator from '@u4i/common/AppNavigator';
import CircularProgress from '@u4i/common/CircularProgress';
import IRootStore from '@u4i/state/RootStore';
import {Alert} from '@u4i/common/Alert';
import {AlertStatusEnum} from '@u4i/common/enums/AlertStatusEnum';
import {IStorage} from '@u4i/state/ServicesInterfaces';
import {ControlBar} from './components/ControlBar';
import {JupyterLabStore} from './state/JupyterLabStore';
import {LabIntro} from './components/LabIntro';
import {NotificationWindow} from './components/NotificationWindow';
import {SubmitWindow} from './components/SubmitWindow';
import {TerminalFrame} from './components/TerminalFrame';

import intlMessages from './intlMessages';
import './_jupyter-lab.scss';

interface IPropTypes extends RouteComponentProps {
  match: any;
  rootStore: IRootStore;
}

const backgroundInactiveClass = 'jupyter-labs-page--background-inactive';
const jupyterLabsPageClass = 'jupyter-labs-page';

export const JupyterLab = inject('rootStore')(observer(class JupyterLab extends Component<IPropTypes> {
  isIE = is.ie();
  isSafari = is.safari();
  private readonly IS_MOBILE = is.mobile();
  private jupyterLabRef = React.createRef<HTMLDivElement>();
  private jupyterLabStore: JupyterLabStore;
  private storageService: IStorage;
  private introDidAnimate = false;
  private isNotificationWindowVisible = false;
  private isTerminalFrameHidden = false;
  private finishedLoadingDisposer;
  private labClosedDisposer;
  showWindow: boolean;
  sizeObserver: any = null;

  constructor(props: IPropTypes) {
    super(props);

    makeObservable<JupyterLab, "introDidAnimate" | "isNotificationWindowVisible" | "isTerminalFrameHidden">(this, {
      introDidAnimate: observable,
      isNotificationWindowVisible: observable,
      isTerminalFrameHidden: observable,
      showWindow: observable,
      sizeObserver: observable,
      labId: computed,
      handleBodyResize: action.bound,
      handleCloseNotificationWindow: action.bound,
      handleFullscreenChange: action.bound,
      handleLabAfterLoading: action.bound,
      handleOpenNotificationWindow: action.bound,
      handleSubmitClick: action.bound,
      markIntroAnimationFinished: action.bound,
      labData: computed
    });

    const {jupyterLabStore, storageService} = this.props.rootStore;

    this.jupyterLabStore = jupyterLabStore;
    this.storageService = storageService;

    this.labClosedDisposer = reaction(() => this.jupyterLabStore.isLabClosed, this.handleGoBack);
    this.finishedLoadingDisposer = reaction(() => this.jupyterLabStore.isLabFinishedLoading, this.handleLabAfterLoading);
  }

  componentDidMount() {
    document.body.classList.add(jupyterLabsPageClass);

    if (this.IS_MOBILE || this.isIE || this.isSafari) {
      return;
    }

    this.jupyterLabStore.init(this.labId);

    if (screenfull.isEnabled) {
      screenfull.on('change', this.handleFullscreenChange);
    }

    this.sizeObserver = new ResizeObserver(this.handleBodyResize);
    this.sizeObserver.observe(document.body);
  }

  componentWillUnmount() {
    this.storageService.showJupyterLabNotification.set(false);
    document.body.classList.remove(backgroundInactiveClass);
    document.body.classList.remove(jupyterLabsPageClass);

    if (this.IS_MOBILE) {
      return;
    }

    if (screenfull.isEnabled) {
      screenfull.off('change', this.handleFullscreenChange);
    }

    this.jupyterLabStore.reset();
    this.sizeObserver.disconnect();
    this.finishedLoadingDisposer();
    this.labClosedDisposer();
  }

  get labId() {
    return this.props.match.params.labId;
  }

  handleBodyResize() {
    if(this.jupyterLabRef.current && this.jupyterLabRef.current.parentNode) {
      const parentClientRect = (this.jupyterLabRef.current.parentNode as HTMLElement).getBoundingClientRect();

      this.jupyterLabRef.current.style.height = `${parentClientRect.height}px`;
    }
  }

  handleCloseNotificationWindow() {
    this.isNotificationWindowVisible = false;

    if (this.showWindow) {
      this.storageService.showJupyterLabNotification.set(false);
      this.showWindow = false;
    }

    document.body.classList.remove(backgroundInactiveClass);
  }

  handleFullscreenChange() {
    this.jupyterLabStore.changeFullscreenStatus((screenfull as Screenfull).isFullscreen);
  }

  handleFullscreenClick = () => {
    if (screenfull.isEnabled) {
      if (this.jupyterLabStore.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request(this.jupyterLabRef.current as Element | undefined);
      }
    } else {
      alert('Your browser doesn\'t support fullscreen mode');
    }
  };

  handleGoBack = () => {
    const previousLocations = AppNavigator.allPreviousLocations.filter(location => {
      return location.pathname.indexOf('/jupyter-lab') !== 0;
    });
    const {pathname, search} = previousLocations[0];

    AppNavigator.directPush(pathname + search);
  };

  handleLabAfterLoading() {
    let showNotificationWidow = this.storageService.showJupyterLabNotification.get();

    if (!this.labId || this.jupyterLabStore.labError || this.jupyterLabStore.labEnabledError) {
      this.storageService.showJupyterLabNotification.set(false);
      showNotificationWidow = false;
    }

    if (showNotificationWidow === null) {
      this.storageService.showJupyterLabNotification.set(true);
      this.showWindow = true;
      this.handleOpenNotificationWindow();
    } else {
      this.showWindow = showNotificationWidow;
    }
  }

  handleOpenNotificationWindow() {
    this.isNotificationWindowVisible = true;

    document.body.classList.add(backgroundInactiveClass);
  }

  handleSubmitClick() {
    if (!document.body.classList.contains(backgroundInactiveClass)) {
      document.body.classList.add(backgroundInactiveClass);
    }

    this.isTerminalFrameHidden = true;

    this.jupyterLabStore.submitLab();
  }

  markIntroAnimationFinished() {
    this.introDidAnimate = true;

    this.jupyterLabStore.setFetching();
  }

  get labData() {
    return this.jupyterLabStore.labData;
  }

  render() {
    if (this.IS_MOBILE) {
      return (
        <div className="jupyter-lab__error-wrapper">
          <Alert
            heading={<FormattedMessage {...intlMessages.mobileHeading} />}
            message={<FormattedMessage {...intlMessages.mobileMessage} />}
            type={AlertStatusEnum.WARNING}
          />
        </div>
      );
    }

    if (!this.labId || this.jupyterLabStore.labError || this.jupyterLabStore.labEnabledError) {
      return (
        <div className="jupyter-lab__error-wrapper">
          <Alert
            heading={<FormattedMessage {...intlMessages.errorHeading} />}
            message={<FormattedMessage {...intlMessages.errorMessage} />}
            type={AlertStatusEnum.ERROR}
          />
        </div>
      );
    }

    if (this.jupyterLabStore.labSubmitError) {
      return (
        <div className="jupyter-lab__error-wrapper">
          <Alert
            actionButtonLabel={<FormattedMessage {...intlMessages.goBack} />}
            heading={<FormattedMessage {...intlMessages.errorHeading} />}
            message={<FormattedMessage {...intlMessages.submitErrorMessage} />}
            onActionButtonClick={this.handleGoBack}
            showActionButton={true}
            type={AlertStatusEnum.ERROR}
          />
        </div>
      );
    }

    if (this.isIE || this.isSafari) {
      return  (
        <div className="jupyter-lab__message-wrapper">
          <Alert
            heading={<FormattedMessage {...intlMessages.browserHeading} />}
            message={<FormattedMessage {...intlMessages.browserMessage} />}
            type={AlertStatusEnum.WARNING}
          />
        </div>
      );
    }

    return (
      <div className="jupyter-lab" ref={this.jupyterLabRef}>
        {!this.isTerminalFrameHidden &&
          <LabIntro
            isVisible={this.jupyterLabStore.fetching}
            onLoadingTransitionEnd={this.markIntroAnimationFinished}
          />
        }

        {this.labData &&
          <div className="jupyter-lab__header">
            <ControlBar
              isVisible={this.introDidAnimate}
              labName={this.labData.title}
              onCloseLabClick={this.jupyterLabStore.closeLab}
              onFullscreenClick={this.handleFullscreenClick}
              onSubmitClick={this.handleSubmitClick}
              showExpandScreenButton={!this.jupyterLabStore.isFullscreen}
            />
          </div>
        }

        {(this.labData && !this.isTerminalFrameHidden) &&
          <div className="jupyter-lab__main-content">
            <TerminalFrame
              challengeAttemptId={this.labData.chalengeAttemptId}
              isVisible={this.introDidAnimate}
              labSubmitted={this.jupyterLabStore.labSubmitted}
              logoutUrl={this.jupyterLabStore.logoutUrl}
            />
          </div>
        }

        {(this.jupyterLabStore.closingLab || this.jupyterLabStore.submittingLab) &&
          <div className="jupyter-lab__close-window">
            <CircularProgress />
          </div>
        }

        <SubmitWindow
          isVisible={this.jupyterLabStore.showSubmissionStatusWindow}
          loadingInProgress={this.jupyterLabStore.submittingLab}
          onGoBackClick={this.handleGoBack}
        />

        <NotificationWindow
          isVisible={this.isNotificationWindowVisible}
          onCloseWindow={this.handleCloseNotificationWindow}
        />
      </div>
    );
  }
}));
