import { AxiosInstance } from "axios";
import qs from 'qs';

import { AdminDlLabs } from "@u4i/parsers/admin/AdminChallengesParser";

export class AdminChallengesApi {
  private apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  async getDigitalLabList(): Promise<any> {
    const response = await this.apiClient.get(
      `/admin/digital-labs/scheduler/list`
    );
    return AdminDlLabs.parseArray(response.data);
  }

  async getBookedSlots(labId: string, userId: string, filter: {from: string, to: string}): Promise<any> {

    const params = {
      'filters[from]': filter.from,
      'filters[to]': filter.to
    }

    if(labId === 'select_all' && userId === 'select_all' || labId === 'select_all' &&  (userId === '' || userId == undefined)){
      const response = await this.apiClient.get(`/admin/digital-labs/users/time-slots`, {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      });
      return response.data;
    } else if(labId !== 'select_all' && userId === 'select_all' || labId !== 'select_all' &&  (userId === '' || userId == undefined)){
      const response = await this.apiClient.get(`/admin/digital-labs/${labId}/users/time-slots`, {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      });
      return response.data;
    } else if(labId === 'select_all' &&  (userId !== 'select_all' || userId !== undefined)){
      const response = await this.apiClient.get(`/admin/digital-labs/users/${userId}/time-slots`, {
        params: params,
        paramsSerializer: params => qs.stringify(params)
      });
      return response.data;
    } else {
      const response = await this.apiClient.get(
        `/admin/digital-labs/${labId}/users/${userId}/time-slots`, {
          params: params,
          paramsSerializer: params => qs.stringify(params)
        }
      );
      return response.data;
    }
  }

  async adminBookSlotForUser(labId: string, slot: string): Promise<any> {
    const response = await this.apiClient.post(
      `/admin/digital-labs/${labId}/time-slots`,
      slot
    );
    return response.data;
  }

  async deleteBoolingSlot(slotId: string): Promise<boolean> {
    const response = await this.apiClient.delete(
      `admin/digital-labs/time-slots/${slotId}`
    );
    return response.data;
  }
}
